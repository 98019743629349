import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE 
import AuthContext from "../../store/authContext";
import FullPageExportContext from "../../store/fullPageExportContext";
import {
  setSelectedAdsFromDropdown,
  setSelectedBrandsFromDropdown,
  setSelectedCampaignsFromDropdown,
} from "../../store/tvBrandsCampaignsSlice";

// COMPONENTS
import axios from "../../components/helpers/axios";
import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";
import Card from "../../components/ui/Card";
import Slider from "../../components/ui/Slider";
import SectionDefinationsBadge from "../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import CompareTvBrands from "../../components/ui/CompareTvBrands";
import Dropdown from "../../components/ui/dropdown/Dropdown";

// MAP
import IndiaMap from "../../components/ui/map/IndiaMap";


// LOADERS
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../components/ui/charts/BarChart";
import Datatable from "../../components/ui/tables/Datatable";
// import TreeMap from "../../components/ui/charts/TreeMap";
// import HeatMap from "../../components/ui/charts/HeatMap";
import PieofaPieChart from "../../components/ui/charts/PieofaPieChart";
import CombinedBarLineMultipleAxesChart from "../../components/ui/charts/CombinedBarLineMultipleAxesChart";
// import LineChart from "../../components/ui/charts/LineChart";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";

// UITLS
import ErrorBoundary from "../../utils/ErrorBoundary";
import TextFormatter from "../../utils/TextFormatter";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import { IAEvent_Platform_Visit } from "../../utils/IAEvents";

// CONSTANTS
// import { BRAND_ID } from "../../constants/constants";
import {
  BlueColorStart500,
  IND_CENTER_POINTS,
  IND_NORTH_POINTS,
  IND_SOUTH_POINTS,
  PieOfPieSeriesColorSet,
  PieOfPieSubSeriesColorSet,
  PurpleColorStart500,
  UK_CENTER_POINTS,
  UK_NORTH_POINTS,
  UK_SOUTH_POINTS,
  US_CENTER_POINTS,
  US_NORTH_POINTS,
  US_SOUTH_POINTS
} from "../../constants/constants";
import { formatDateDisplay } from "../../components/ui/Datepicker";
import NewUSMap from "../../components/ui/map/NewUSMap";
import NewUKMap from "../../components/ui/map/NewUKMap";
import CombinedBarLineChart from "../../components/ui/charts/CombinedBarLineChart";
import PartitionedCombinedBarLineMultipleAxesChart from "../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import ExpandIcon from "../../assets/icons/ExpandIcon";
import Modal from "../../components/ui/Modal";

const TV = () => {

  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence"
  let pageSubModule = "TV"

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setExportPageName,
    exportSummary,
    setexportSummary,
    setIsExportDisabled
  } = useContext(FullPageExportContext);

  const userData = useSelector((state) => state.userData.user);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)


  // SELECTED MODULE
  const selectedBrandIds = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedBrands
  );
  const selectedCampaignIds = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedCampaigns
  );
  const selectedAdNames = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedAds
  );

  // BRANDS DATA MIN MAX DATES
  const brandMinMaxDataDates = useSelector((state) => state.moduleData.module);

  // TO GET THE MIN AND MAX DATE OF TV DATA AVAILABLE
  let minDatesArr = []
  let maxDatesArr = []
  let minDate = "";
  let maxDate = "";

  if (brandMinMaxDataDates.length !== 0 && brandMinMaxDataDates["mi"] !== undefined && brandMinMaxDataDates["mi"]["tv"] !== undefined && brandMinMaxDataDates["mi"]["tv"]["tv"] !== undefined) {
    let tvData = Object.values(brandMinMaxDataDates["mi"]["tv"]["tv"])

    tvData.map((brand) => (
      minDatesArr.push(new Date(brand.min_date)),
      maxDatesArr.push(new Date(brand.max_date))
    ))

    minDate = new Date(Math.min(...minDatesArr))
    maxDate = new Date(Math.max(...maxDatesArr))
  }


  const [selectedBrandPlatform, setSelectedBrandPlatform] = useState("all_brands");

  // INDIA MAP
  const [MapData, setMapData] = useState([]);
  const [isMapLoading, setMapIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [MapError, setMapError] = useState(null);

  let DEFAULT_CENTER_POINT = userData.country_code === "US" ? US_CENTER_POINTS : userData.country_code === "UK" ? UK_CENTER_POINTS : IND_CENTER_POINTS;
  let DEFAULT_NORTH_POINT = userData.country_code === "US" ? US_NORTH_POINTS : userData.country_code === "UK" ? UK_NORTH_POINTS : IND_NORTH_POINTS;
  let DEFAULT_SOUTH_POINT = userData.country_code === "US" ? US_SOUTH_POINTS : userData.country_code === "UK" ? UK_SOUTH_POINTS : IND_SOUTH_POINTS;

  const [centerPoint, setCenterPoint] = useState(userData.country_code === "US" ? US_CENTER_POINTS : userData.country_code === "UK" ? UK_CENTER_POINTS : IND_CENTER_POINTS);
  const [northPoint, setNorthPoint] = useState(userData.country_code === "US" ? US_NORTH_POINTS : userData.country_code === "UK" ? UK_NORTH_POINTS : IND_NORTH_POINTS);
  const [southPoint, setSouthPoint] = useState(userData.country_code === "US" ? US_SOUTH_POINTS : userData.country_code === "UK" ? UK_SOUTH_POINTS : IND_SOUTH_POINTS);

  let zoomLevel = 2.5;
  const [mapLevel, setMapLevel] = useState(0);
  const [mapBreadCrumbList, setMapBreadCrumbList] = useState([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"]);
  const [mapZone, setMapZone] = useState(null);
  const [mapState, setMapState] = useState(null);
  const [mapMarket, setMapMarket] = useState(null);
  const [mapStateCode, setMapStateCode] = useState(null);
  const [placeDropdown, setPlaceDropdown] = useState(null);

  const placeOptions = [
    {
      label: "HSM",
      value: "hsm",
    },
    {
      label: "NON-HSM",
      value: "non_hsm",
    },
  ];

  const placeSelectionHandler = (e) => {
    if (e === null) {
      setPlaceDropdown("");
      setMapMarket();
    } else {
      setPlaceDropdown(e);
      setMapMarket(e.value);
    }
  };

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PERFORMANCE METRIC CARDS
  const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
  const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] =
    useState(false);
  const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] =
    useState(false);
  const [performanceMetricCardserror, setPerformanceMetricCardsError] =
    useState(null);

  // PERFORMANCE TREND
  const [perfTrend, setPerfTrend] = useState([]);
  const [performanceDropdownsData, setPerformanceDropdownsData] = useState([]);
  const [perfTrendDataLabels, setPerfTrendDataLabels] = useState([]);
  const [isPerfTrendLoading, setPerfTrendIsLoading] = useState(false);
  const [isPerfTrendLoaded, setPerfTrendIsLoaded] = useState(false);
  const [perfTrenderror, setPerfTrendError] = useState(null);

  // PERFORMANCE TREND DROPDOWN OPTIONS
  const performanceOptions = [
    {
      label: "Sales Value",
      value: "sales_value",
    },
    {
      label: "Sales Volume",
      value: "sales_volume",
    },
  ];

  const [performanceDropdown, setPerformanceDropdown] = useState(
    performanceOptions[0]
  );

  const performanceSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceDropdown("");
    } else {
      setPerformanceDropdown(e);
    }
  };
  // CHANGE CAMPAIGN PERFORMANCE DATA AS PER THE DROPDOWN VALUE
  const fetchPerformanceSelectionHandler = () => {
    if (performanceDropdownsData.length !== 0) {
      if (performanceDropdown.value === "sales_value") {
        setPerfTrend(performanceDropdownsData.length === 0 ? [] : performanceDropdownsData.sales_value.data);
        setPerfTrendDataLabels(
          performanceDropdownsData.length === 0 ? [] :
            performanceDropdownsData.sales_value.Dimensions[0].linechartLabels === undefined || performanceDropdownsData.sales_value.Dimensions[0].linechartLabels.length === 0 ? [] :
              performanceDropdownsData.sales_value.Dimensions[0].barchartLabels === undefined || performanceDropdownsData.sales_value.Dimensions[0].barchartLabels.length === 0 ? [] :
                performanceDropdownsData.sales_value.Dimensions
        );
      } else if (performanceDropdown.value === "sales_volume") {
        setPerfTrend(performanceDropdownsData.length === 0 ? [] : performanceDropdownsData.sales_volume.data);
        setPerfTrendDataLabels(
          performanceDropdownsData.length === 0 ? [] :
            performanceDropdownsData.sales_volume.Dimensions[0].linechartLabels === undefined || performanceDropdownsData.sales_volume.Dimensions[0].linechartLabels.length === 0 ? [] :
              performanceDropdownsData.sales_volume.Dimensions[0].barchartLabels === undefined || performanceDropdownsData.sales_volume.Dimensions[0].barchartLabels.length === 0 ? [] :
                performanceDropdownsData.sales_volume.Dimensions);
      } else {
        setPerfTrend([]);
        setPerfTrendDataLabels([]);
      }
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchPerformanceSelectionHandler();
  }, [performanceDropdown]);

  // TOP GENRES AND GRPS
  const [TopGenreData, setTopGenreData] = useState([]);
  const [TopGenreDataLabels, setTopGenreDataLabels] = useState([]);
  const [isTopGenreLoading, setTopGenreIsLoading] = useState(false);
  const [isTopGenreLoaded, setTopGenreIsLoaded] = useState(false);
  const [TopGenreError, setTopGenreError] = useState(null);


  // AD DURATION
  const [AdDurationData, setAdDurationData] = useState([]);
  const [AdDurationDataLabels, setAdDurationDataLabels] = useState([]);
  const [isAdDurationLoading, setAdDurationIsLoading] = useState(false);
  const [isAdDurationLoaded, setAdDurationIsLoaded] = useState(false);
  const [AdDurationError, setAdDurationError] = useState(null);

  // BRAND WISE GRP AND SPENDS 
  const [simpleTreemapData, setSimpleTreemapData] = useState([]);
  const [isSimpleTreemapDataLoading, setSimpleTreemapDataIsLoading] = useState(false);
  const [isSimpleTreemapDataLoaded, setSimpleTreemapDataIsLoaded] = useState(false);
  const [simpleTreemapDataError, setSimpleTreemapDataError] = useState(null);

  let treemapLevel = 1;
  const [treemapBrand, setTreemapBrand] = useState("");
  const [treemapBrandId, setTreemapBrandId] = useState("");
  const [treemapBrandCategory, setTreemapBrandCategory] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    zone: mapZone,
    state_code: mapStateCode,
    market: mapMarket,
    brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
    campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
    ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
    level: 1,
  });


  // AD POSITION WISE GRP
  const [adPosition, setAdPosition] = useState([]);
  const [adPositionDataLabels, setAdPositionDataLabels] = useState([]);
  const [isAdPositionLoading, setAdPositionIsLoading] = useState(false);
  const [isAdPositionLoaded, setAdPositionIsLoaded] = useState(false);
  const [adPositionerror, setAdPositionError] = useState(null);


  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Creative Name", field: "creative" },
    { name: "GRP", field: "grp" },
    { name: "Spends", field: "spends" },
    { name: "CPM", field: "cpm" },
    { name: "Impressions", field: "impressions" },
    { name: "CPRP", field: "cprp" },
    { name: "Prime Time Share", field: "prime_time" },
    { name: "HSM Share", field: "hsm_share" },
  ];

  const US_campaignSummaryTableHeaders = [
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Creative Name", field: "creative" },
    { name: "GRP", field: "grp" },
    { name: "Spends", field: "spends" },
    { name: "CPM", field: "cpm" },
    { name: "Impressions", field: "impressions" },
    { name: "CPRP", field: "cprp" },
    { name: "Prime Time Share", field: "prime_time" }
  ];



  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);


  // // TOTAL INTERACTIONS
  // const [brandwiseMarket, setBrandwiseMarket] = useState([]);
  // const [brandwiseMarketLabels, setBrandwiseMarketLabels] = useState([]);
  // const [isbrandwiseMarketLoading, setBrandwiseMarketIsLoading] =
  //   useState(false);
  // const [isbrandwiseMarketLoaded, setBrandwiseMarketIsLoaded] = useState(false);
  // const [brandwiseMarketError, setBrandwiseMarketError] = useState(null);

  // // TIMESLOT
  // const [TimeSlotData, setTimeSlotData] = useState([]);
  // const [TimeSlotDataLabels, setTimeSlotDataLabels] = useState([]);
  // const [isTimeSlotLoading, setTimeSlotIsLoading] = useState(false);
  // const [isTimeSlotLoaded, setTimeSlotIsLoaded] = useState(false);
  // const [TimeSlotError, setTimeSlotError] = useState(null);

  // // BRAND WISE GRP AND SPENDS
  // const [brandWise, setBrandWise] = useState([]);
  // const [isBrandWiseLoading, setBrandWiseIsLoading] = useState(false);
  // const [isBrandWiseLoaded, setBrandWiseIsLoaded] = useState(false);
  // const [brandWiseerror, setBrandWiseError] = useState(null);

  // // AUDIENCE ACTIVITY
  // const [audienceActivity, setAudienceActivity] = useState([]);
  // const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
  //   useState([]);
  // const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
  //   useState([]);
  // const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
  //   useState(false);
  // const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
  //   useState(false);
  // const [audienceActivityerror, setAudienceActivityError] = useState(null);


  // RELOAD PAGE WHEN PLATFORM IS CHANGE
  useEffect(() => {
    fetchPerformanceMetricCardsHandler();
    fetchTopGenreHandler();
    fetchAdPositionHandler();
    // fetchBrandwiseMarketHandler();
    // fetchAudienceActivityTrendHandler();
    // fetchTimeSlotHandler();
    fetchAdDurationHandler();
    fetchPerfTrendChartHandler();
    setPerformanceDropdown(performanceOptions[0]);
    fetchCampaignSummaryHandler();
    setTreemapParams({
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      zone: mapZone,
      state_code: mapStateCode,
      market: mapMarket,
      brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
      campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
      ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      level: 1,
    });
  }, [
    selectedBrandPlatform,
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    selectedBrandIds,
    selectedCampaignIds,
    selectedAdNames,
  ]);


  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchSampleTreemapHandler();
  }, [treemapParams]);

  useEffect(() => {
    fetchMetricCardsHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
  ]);

  useEffect(() => {
    fetchMapDataHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    selectedBrandIds,
    selectedCampaignIds,
    selectedAdNames,
  ]);

  useEffect(() => {
    fetchTopPostsCompareHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    // selectedBrandIds,
    // selectedCampaignIds,
    // selectedAdNames
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INDIA MAP

  // TO FETCH METRIC CARDS FROM API
  const fetchMapDataHandler = async () => {
    setMapIsLoading(true);
    setIsMapLoaded(false);
    setMapError(null);

    // let market = null;
    // if (placeDropdown !== undefined) {
    //   market = placeDropdown.value;
    // }
    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-map/", config);
      setMapData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setMapIsLoading(false);
      setIsMapLoaded(true);
      setMapError(null);
    } catch (error) {
      setMapIsLoading(false);
      setIsMapLoaded(false);
      setMapError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isMapLoaded && !isMapLoading) {
    if (NoDataAvailableChecker(MapData)) {
      IndiaMapChart = <Wrapper><NoDataAvailableLoader chartType="indiaChartType" /></Wrapper>;
    } else {

      if (userData.country_code === "US") {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <NewUSMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["grp", "sales", "spends"]}
                toolTipLabels={["GRP", "Sales Volume", "Spends"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else if (userData.country_code === "UK") {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <NewUKMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["grp", "sales", "spends"]}
                toolTipLabels={["GRP", "Sales Volume", "Spends"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <IndiaMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["grp", "sales", "spends"]}
                toolTipLabels={["GRP", "Sales Volume", "Spends"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (MapError) {
    IndiaMapChart = (
      <Wrapper><ServerErrorsLoader chartType="indiaChartType" error={MapError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMapLoading) {
    IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;
  }

  function mapBreadcrumbsHandler(index, value) {
    if (index === 0) {
      let a = [userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"];
      setMapBreadCrumbList(a);
      setMapLevel(0);
      setMapZone(null);
      setMapState();
      setMapStateCode();
      setCenterPoint(DEFAULT_CENTER_POINT);
      setNorthPoint(DEFAULT_NORTH_POINT);
      setSouthPoint(DEFAULT_SOUTH_POINT);
    }
    if (index === 1) {
      let a = [userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value];
      setMapBreadCrumbList(a);
      setMapLevel(1);
      setMapState();
      setMapStateCode();
    }
  }

  async function handleIndiaMapChange(value) {
    setMapLevel(value[0] + 1);
    setCenterPoint(value[1]);
    setNorthPoint(value[2]);
    setSouthPoint(value[3]);
    setMapZone(value[4]);
    if (value[0] === 1) {
      setMapState(value[5]);
      setMapStateCode(value[6]);
      setMapBreadCrumbList([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value[4], value[5]]);
    } else {
      setMapBreadCrumbList([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value[4]]);
    }
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/brand-metric/", config);

      setMetricCards(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper><Loader loaderType="cardLoader" variantType="compare" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_margin_bottom">
            <div className="card_wrapper">
              <ErrorBoundary>
                <Slider
                  sliderWrapperClass={"tab_card_slider"}
                  // showSliderHeader={false}
                  sliderTitle={"Brand Performance Index"}
                >
                  {metricCards.map((data, index) => (
                    <SwiperSlide key={index} onClick={() => activePlatform(data)}>
                      <Card
                        variant="compare"
                        showPreviousCompare={true}
                        key={index}
                        cardClass={
                          selectedBrandIds.length === 0
                            ? selectedBrandPlatform === data.brand_id
                              ? "tabCard active"
                              : "tabCard"
                            : selectedBrandIds.includes(data.brand_id) === true
                              ? "tabCard active"
                              : "tabCard"
                        }
                        cardIconLink={data.brand_logo}
                        cardTitle={data.cardTitle}
                        currentValue={data.currentValue}
                        previousValue={data.previousValue}
                        second_currentValue={data.second_currentValue}
                        second_previousValue={data.second_previousValue}
                        rowOneTitle={data.rowOneTitle}
                        rowTwoTitle={data.rowTwoTitle}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </ErrorBoundary>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = <Wrapper><ServerErrorsLoader error={metricCardserror} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Wrapper><Loader loaderType="tvLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE METRIC CARDS

  // TO FETCH PERFORMANCE METRIC CARDS FROM API
  const fetchPerformanceMetricCardsHandler = async () => {
    setPerformanceMetricCardsIsLoading(true);
    setPerformanceMetricCardsIsLoaded(false);
    setPerformanceMetricCardsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/performance-metric/", config);
      setPerformanceMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(true);
      setPerformanceMetricCardsError(null);
    } catch (error) {
      setPerformanceMetricCards([]);
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(false);
      setPerformanceMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceMetricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Performance Metrics
          </h2></div>

        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader
            loaderType="cardLoader"
          />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
    if (NoDataAvailableChecker(performanceMetricCards)) {
      performanceMetricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Performance Metrics
              </h2></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      performanceMetricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Performance Metrics"}>
              {performanceMetricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="tv"
                    platform="tv_overall"
                    section="tv_overall"
                    key={index}
                    variant={"chartCard"}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    chartType={"Line"}
                    chartData={
                      NoDataAvailableChecker(data.subData)
                        ? []
                        : NoDataAvailableChecker(data.subData.data)
                          ? []
                          : data.subData.data
                    }
                    chartDataLabels={
                      NoDataAvailableChecker(data.subData.dimension)
                        ? []
                        : NoDataAvailableChecker(data.subData.dimension)
                          ? []
                          : data.subData.dimension
                    }
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceMetricCardserror) {
    performanceMetricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Performance Metrics
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={performanceMetricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceMetricCardsLoading) {
    performanceMetricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Performance Metrics
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader
              loaderType="cardLoader"
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE TREND

  const [isPerfTrendOpen, setIsPerfTrendOpen] = useState(false)

  let defaultPerfTrendDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPerfTrendDisabledValues = []
  } else {
    defaultPerfTrendDisabledValues = ["Sales Volume"]
  }

  const [perfTrendDisabledValues, setPerfTrendDisabledValues] = useState(defaultPerfTrendDisabledValues)

  // TO FETCH DATA FROM API
  const fetchPerfTrendChartHandler = async () => {
    setPerfTrendIsLoading(true);
    setPerfTrendIsLoaded(false);
    setPerfTrendError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/performance-trend/", config);

      setPerfTrend(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.sales_value === undefined || res.data.sales_value.length === 0 ? [] :
            res.data.sales_value.data === undefined || res.data.sales_value.data.length === 0 ? [] :
              res.data.sales_value.data
      );
      setPerfTrendDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.sales_value === undefined || res.data.sales_value.length === 0 ? [] :
            res.data.sales_value.Dimensions === undefined || res.data.sales_value.Dimensions.length === 0 ? [] :
              res.data.sales_value.Dimensions[0].linechartLabels === undefined || res.data.sales_value.Dimensions[0].linechartLabels.length === 0 ? [] :
                res.data.sales_value.Dimensions[0].barchartLabels === undefined || res.data.sales_value.Dimensions[0].barchartLabels.length === 0 ? [] :
                  res.data.sales_value.Dimensions
      );
      setPerformanceDropdownsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setPerfTrendIsLoading(false);
      setPerfTrendIsLoaded(true);
      setPerfTrendError(null);
    } catch (error) {
      setPerfTrend([]);
      setPerfTrendDataLabels([]);
      setPerfTrendIsLoading(false);
      setPerfTrendIsLoaded(false);
      setPerfTrendError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let perfTrendChart = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  let perfTrendChart2 = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );


  // IF DATA IS LOADED
  if (isPerfTrendLoaded && !isPerfTrendLoading) {
    if (
      NoDataAvailableChecker(perfTrend) ||
      NoDataAvailableChecker(perfTrendDataLabels)
    ) {
      perfTrendChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxesBarLineChartType" />
        </Wrapper>
      );
      perfTrendChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxesBarLineChartType" />
        </Wrapper>
      );

    } else {
      if (isMultiLineCluster) {
        perfTrendChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_facebook_current_period_1"
                chartData={perfTrend}
                chartLabels={perfTrendDataLabels}
                showLegend={true}
                disabledLegendArray={perfTrendDisabledValues}
                setDisabledValues={setPerfTrendDisabledValues}

              />
            </ErrorBoundary>
          </Wrapper>
        );
        perfTrendChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_facebook_current_period_2"
                chartData={perfTrend}
                chartLabels={perfTrendDataLabels}
                showLegend={true}
                disabledLegendArray={perfTrendDisabledValues}
                setDisabledValues={setPerfTrendDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else {
        <Wrapper>
          <ErrorBoundary chartType="multiAxesBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_facebook_current_period"
              chartClass="section_card_chart"
              showSecondY={false}
              showBullets={false}
              chartData={perfTrend}
              chartLabels={perfTrendDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (perfTrenderror) {
    perfTrendChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="multiAxesBarLineChartType"
          error={perfTrenderror}
        />
      </Wrapper>
    );
    perfTrendChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="multiAxesBarLineChartType"
          error={perfTrenderror}
        />
      </Wrapper>
    );

  }

  // IF DATA LOADING
  if (isPerfTrendLoading) {
    perfTrendChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
    perfTrendChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP GENRES AND GRPS

  // TO FETCH METRIC CARDS FROM API
  const fetchTopGenreHandler = async () => {
    setTopGenreIsLoading(true);
    setTopGenreIsLoaded(false);
    setTopGenreError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/top-genre/", config);
      setTopGenreData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.grp === undefined || res.data.data.grp.length === 0 ? [] :
              res.data.data.grp
      );
      setTopGenreDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label === undefined || res.data.label.length === 0 ? [] :
            res.data.label.grp === undefined || res.data.label.grp.length === 0 ? [] :
              res.data.label.grp
      );
      setTopGenreIsLoading(false);
      setTopGenreIsLoaded(true);
      setTopGenreError(null);
    } catch (error) {
      setTopGenreData([]);
      setTopGenreDataLabels([]);
      setTopGenreIsLoading(false);
      setTopGenreIsLoaded(false);
      setTopGenreError(error.code);
    }
  };

  let topGenreBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isTopGenreLoaded && !isTopGenreLoading) {
    if (NoDataAvailableChecker(TopGenreData) || NoDataAvailableChecker(TopGenreDataLabels)) {
      topGenreBarChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
    } else {
      topGenreBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="rpc"
              chartClass="section_card_chart"
              chartData={TopGenreData}
              chartLabels={TopGenreDataLabels}
              chartColorSet={BlueColorStart500}
              showHalfVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (TopGenreError) {
    topGenreBarChart = (
      <Wrapper><ServerErrorsLoader chartType="barChartType" error={TopGenreError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopGenreLoading) {
    topGenreBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD DURATION WISE

  // TO FETCH METRIC CARDS FROM API
  const fetchAdDurationHandler = async () => {
    setAdDurationIsLoading(true);
    setAdDurationIsLoaded(false);
    setAdDurationError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/duration-wise-ad/", config);

      setAdDurationData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.grp === undefined || res.data.data.grp.length === 0 ? [] :
              res.data.data.grp
      );
      setAdDurationDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label === undefined || res.data.label.length === 0 ? [] :
            res.data.label.grp === undefined || res.data.label.grp.length === 0 ? [] :
              res.data.label.grp
      );
      setAdDurationIsLoading(false);
      setAdDurationIsLoaded(true);
      setAdDurationError(null);
    } catch (error) {
      setAdDurationData([]);
      setAdDurationDataLabels([]);
      setAdDurationIsLoading(false);
      setAdDurationIsLoaded(false);
      setAdDurationError(error.code);
    }
  };

  let adDurationBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isAdDurationLoaded && !isAdDurationLoading) {
    if (NoDataAvailableChecker(AdDurationData) || NoDataAvailableChecker(AdDurationDataLabels)) {
      adDurationBarChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
    } else {
      adDurationBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="adDuration"
              chartClass="section_card_chart"
              chartData={AdDurationData}
              chartLabels={AdDurationDataLabels}
              chartColorSet={PurpleColorStart500}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (AdDurationError) {
    adDurationBarChart = (
      <Wrapper><ServerErrorsLoader chartType="barChartType" error={AdDurationError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdDurationLoading) {
    adDurationBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE GRP AND SPENDS
  const chartlevelHandler = (data) => {
    if (data.level === 1) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: data.brand_id,
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
        level: 2,
      });
      setTreemapBrandId(data.brand_id);
      setTreemapBrand(data.brand_name);
    } else if (data.level === 2) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: data.brand_id,
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
        level: 3,
        category: data.category,
      });
      setTreemapBrandCategory(data.category);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchSampleTreemapHandler = async () => {
    setSimpleTreemapDataIsLoading(true);
    setSimpleTreemapDataIsLoaded(false);
    setSimpleTreemapDataError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/brand-grp-spend/", config);

      setSimpleTreemapData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setSimpleTreemapDataIsLoading(false);
      setSimpleTreemapDataIsLoaded(true);
      setSimpleTreemapDataError(null);
    } catch (error) {
      setSimpleTreemapData([]);
      setSimpleTreemapDataIsLoading(false);
      setSimpleTreemapDataIsLoaded(false);
      setSimpleTreemapDataError(error.code);
    }
  };

  let sampleTreemapChart = <Wrapper><Loader loaderType="treeChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isSimpleTreemapDataLoaded && !isSimpleTreemapDataLoading) {
    if (
      NoDataAvailableChecker(simpleTreemapData)
    ) {
      sampleTreemapChart = <Wrapper><NoDataAvailableLoader chartType="treeChartType" /></Wrapper>;
    } else {
      if (treemapLevel > 3) {
        sampleTreemapChart = (
          <Wrapper><NoDataAvailableLoader chartType="treeChartType" /></Wrapper>
        );
      } else {
        sampleTreemapChart = (
          <Wrapper>
            <ErrorBoundary chartType="treeChartType">
              <SimpleTreeMap
                chartId="simpleTreemap"
                chartClass="section_card_chart"
                chartData={simpleTreemapData}
                levelHandler={chartlevelHandler}
                colorChangeParameter={"growth_percentage"}
                chartLabels={[
                  {
                    name: "name",
                    value: "value",
                    toolTipvalue1: "grp",
                    toolTipvalue1Label: "GRPs",
                    toolTipvalue2: "spend",
                    toolTipvalue2Label: "Spends",
                  },
                ]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (simpleTreemapDataError) {
    sampleTreemapChart = <Wrapper><ServerErrorsLoader error={simpleTreemapDataError} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isSimpleTreemapDataLoading) {
    sampleTreemapChart = <Wrapper><Loader loaderType="treeChartLoader" /></Wrapper>;
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD POSITION WISE GRP

  // TO FETCH DATA FROM API
  const fetchAdPositionHandler = async () => {
    setAdPositionIsLoading(true);
    setAdPositionIsLoaded(false);
    setAdPositionError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/position-wise-grp/", config);
      setAdPosition(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setAdPositionDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label === undefined || res.data.label.length === 0 ? [] :
            res.data.label
      );
      setAdPositionIsLoading(false);
      setAdPositionIsLoaded(true);
      setAdPositionError(null);
    } catch (error) {
      setAdPosition([]);
      setAdPositionDataLabels([]);
      setAdPositionIsLoading(false);
      setAdPositionIsLoaded(false);
      setAdPositionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let adPositionPie = <Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isAdPositionLoaded && !isAdPositionLoading) {
    if (NoDataAvailableChecker(adPosition)) {
      adPositionPie = <Wrapper><NoDataAvailableLoader chartType="pieChartType" /></Wrapper>;
    } else {
      adPositionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={adPosition}
              chartLabels={adPositionDataLabels}
              chartSeriesColorSet={PieOfPieSeriesColorSet}
              chartSubSeriesColorSet={PieOfPieSubSeriesColorSet}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adPositionerror) {
    adPositionPie = (
      <Wrapper><ServerErrorsLoader chartType="pieChartType" error={adPositionerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdPositionLoading) {
    adPositionPie = <Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id: selectedBrandIds.length === 0 || selectedBrandIds === undefined ? selectedBrandPlatform : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
        ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/campaign-summary/", config);

      if (userData.country_code !== "IN") {
        res.data.forEach((data) => {
          delete data.hsm_share
        })
      }

      setCampaignSummary(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummary([])
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Ad/ Campaign Summary
              <SectionDefinationsBadge
                title={"Ad / Campaign Summary"}
                module="tv"
                platform="overall"
                section="overall"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>);

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
      campaignSummaryTable = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Ad/ Campaign Summary
                  <SectionDefinationsBadge
                    title={"Ad / Campaign Summary"}
                    module="tv"
                    platform="overall"
                    section="overall"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>)
    } else {
      campaignSummaryTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={"Ad / Campaign Summary"}
              tablePageSubModule="tv"
              tablePagePlatform="overall"
              tablePagePrimaryTab="overall"
              tableHeader={userData.country_code !== "IN" ? US_campaignSummaryTableHeaders : campaignSummaryTableHeaders}
              tableData={campaignSummary}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Ads / Campaigns"}
              tableID="campaign_summary_table"
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"campaign_summary_table"}
                  sectionName="TvCampaignSummary_Channel Summary Table"
                  sectionData={campaignSummary}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Ad/ Campaign Summary
                <SectionDefinationsBadge
                  title={"Ad / Campaign Summary"}
                  module="tv"
                  platform="overall"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader error={campaignSummaryerror} chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Ad/ Campaign Summary
                <SectionDefinationsBadge
                  title={"Ad / Campaign Summary"}
                  module="tv"
                  platform="overall"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/compare-brands/", config);
      setTopPostsCompare(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat = (
    <Wrapper>

      <div className="grid col_span_4 section_card_header">
        <h2 className="section_card_title">Top Brands</h2>
      </div>
      <div className="grid col_span_4">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let topPostsCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (NoDataAvailableChecker(topPostsCompare)) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Top Brands</h2>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareTvBrands
              mapZone={mapZone}
              mapStateCode={mapStateCode}
              mapMarket={mapMarket}
              products={topPostsCompare}
              isPaid={true}
              Title={topPostsCompareHeaderTitle}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_card_header">
            <h2 className="section_card_title">Top Brands</h2>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topPostsCompareerror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_card_header">
            <h2 className="section_card_title">Top Brands</h2>
          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOTAL INTERACTIONS

  // // TO FETCH DATA FROM API
  // const fetchBrandwiseMarketHandler = async () => {
  //   setBrandwiseMarketIsLoading(true);
  //   setBrandwiseMarketIsLoaded(false);
  //   setBrandwiseMarketError(null);
  //   //comment first brandid and uncomment the second one with zone and all
  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       post_type: PostType,
  //       /*  zone: mapZone,
  //        state_code: mapStateCode,
  //        market: mapMarket,
  //        brand_id:
  //          selectedBrandIds.length === 0
  //            ? selectedBrandPlatform
  //            : selectedBrandIds.join(","), 
  //        campaign_id: selectedCampaignIds.join(","),
  //        ad_name: selectedAdNames.join(","),*/
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "social/linkedin/post/overall/interaction-line-chart/",
  //       config
  //     );

  //     setBrandwiseMarket(
  //       res.data === undefined || res.data.length === 0 ? [] : 
  //       res.data.data === undefined  || res.data.data.length === 0 ? [] : 
  //       res.data.data
  //       );
  //     setBrandwiseMarketLabels(
  //       res.data === undefined || res.data.length === 0  ? [] : 
  //       res.data.label_list === undefined  || res.data.label_list.length === 0 ? [] : 
  //       res.data.label_list
  //       );
  //     setBrandwiseMarketIsLoading(false);
  //     setBrandwiseMarketIsLoaded(true);
  //     setBrandwiseMarketError(null);
  //   } catch (error) {
  //     setBrandwiseMarket([]);
  //     setBrandwiseMarketLabels([]);
  //     setBrandwiseMarketIsLoading(false);
  //     setBrandwiseMarketIsLoaded(false);
  //     setBrandwiseMarketError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let brandwiseMarketLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // // IF DATA IS LOADED
  // if (isbrandwiseMarketLoaded && !isbrandwiseMarketLoading) {
  //   if (NoDataAvailableChecker(brandwiseMarket)) {
  //     brandwiseMarketLineChart = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
  //   } else {
  //     brandwiseMarketLineChart = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <LineChart
  //             chartId="brandwise_market"
  //             chartClass="section_card_chart"
  //             chartData={brandwiseMarket}
  //             chartLabels={brandwiseMarketLabels}
  //             showLabels={true}
  //             showLegend={true}
  //             flatLegends={true}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (brandwiseMarketError) {
  //   brandwiseMarketLineChart = (
  //     <Wrapper><ServerErrorsLoader error={brandwiseMarketError} /></Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isbrandwiseMarketLoading) {
  //   brandwiseMarketLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  // }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TIMESLOT GRP

  // TO FETCH METRIC CARDS FROM API
  // const fetchTimeSlotHandler = async () => {
  //   setTimeSlotIsLoading(true);
  //   setTimeSlotIsLoaded(false);
  //   setTimeSlotError(null);

  //   const config = {
  //     params: {
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //       market: mapMarket,
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrandPlatform
  //           : selectedBrandIds.join(","),
  //       campaign_id: selectedCampaignIds.join(","),
  //       ad_name: selectedAdNames.join(","),
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("ri/time-slot-grp/", config);

  //     setTimeSlotData(
  //       res.data === undefined || res.data.length === 0 ? [] : 
  //       res.data.data === undefined || res.data.data.length === 0 ? [] : 
  //       res.data.data.grp === undefined || res.data.data.grp.length === 0 ? [] : 
  //       res.data.data.grp
  //       );
  //     setTimeSlotDataLabels(
  //       res.data === undefined || res.data.length === 0? [] : 
  //       res.data.label === undefined || res.data.label.length === 0 ? [] : 
  //       res.data.label.grp === undefined || res.data.label.grp.length === 0 ? [] : 
  //       res.data.label.grp
  //       );
  //     setTimeSlotIsLoading(false);
  //     setTimeSlotIsLoaded(true);
  //     setTimeSlotError(null);
  //   } catch (error) {
  //     setTimeSlotData([]);
  //     setTimeSlotDataLabels([]);
  //     setTimeSlotIsLoading(false);
  //     setTimeSlotIsLoaded(false);
  //     setTimeSlotError(error.code);
  //   }
  // };

  // let timeSlotBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;

  // // IF DATA IS AVAILABLE
  // if (isTimeSlotLoaded && !isTimeSlotLoading) {
  //   if (NoDataAvailableChecker(TimeSlotData) || NoDataAvailableChecker(TimeSlotDataLabels)) {
  //     timeSlotBarChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
  //   } else {
  //     timeSlotBarChart = (
  //       <Wrapper>
  //       <ErrorBoundary chartType="barChartType">
  //         <BarChart
  //           showHalfVerticalLabels={true}
  //           chartId="timeSlot"
  //           chartClass="section_card_chart"
  //           chartData={TimeSlotData}
  //           chartLabels={TimeSlotDataLabels}
  //           chartColorSet={YellowColorStart500}
  //           minimumGridDistance={0}
  //         />
  //       </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (TimeSlotError) {
  //   timeSlotBarChart = (
  //     <Wrapper><ServerErrorsLoader chartType="barChartType" error={TimeSlotError} /></Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isTimeSlotLoading) {
  //   timeSlotBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE GRP AND SPENDS
  // const fetchBrandWiseHandler = async () => {
  //   setBrandWiseIsLoading(true);
  //   setBrandWiseIsLoaded(false);
  //   setBrandWiseError(null);

  //   const config = {
  //     params: {
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       level: 1,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //       market: mapMarket,
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrandPlatform
  //           : selectedBrandIds.join(","),
  //       campaign_id: selectedCampaignIds.join(","),
  //       ad_name: selectedAdNames.join(","),
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("ri/brand-grp-spend/", config);
  //     setBrandWise( res.data === undefined || res.data.length === 0 ? [] : res.data);
  //     setBrandWiseIsLoading(false);
  //     setBrandWiseIsLoaded(true);
  //     setBrandWiseError(null);
  //   } catch (error) {
  //     setBrandWise([]);
  //     setBrandWiseIsLoading(false);
  //     setBrandWiseIsLoaded(false);
  //     setBrandWiseError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let brandWiseSection = <Wrapper><Loader loaderType="treeChartLoader" /></Wrapper>;

  // // IF DATA IS LOADED
  // if (isBrandWiseLoaded && !isBrandWiseLoading) {
  //   if (NoDataAvailableChecker(brandWise)) {
  //     brandWiseSection = <Wrapper><NoDataAvailableLoader chartType="treeChartType" /></Wrapper>;
  //   } else {
  //     brandWiseSection = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="treeChartType">
  //           <TreeMap
  //             chartData={brandWise}
  //             chartId="treemapchart"
  //             chartClass="section_card_chart"
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (brandWiseerror) {
  //   brandWiseSection = (
  //     <Wrapper><ServerErrorsLoader chartType="treeChartType" error={brandWiseerror} /></Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isBrandWiseLoading) {
  //   brandWiseSection = <Wrapper><Loader loaderType="treeChartLoader" /></Wrapper>;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TIME SLOT WISE

  // const fetchAudienceActivityTrendHandler = async () => {
  //   setAudienceActivityIsLoading(true);
  //   setAudienceActivityIsLoaded(false);
  //   setAudienceActivityError(null);

  //   const config = {
  //     params: {
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //       market: mapMarket,
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrandPlatform
  //           : selectedBrandIds.join(","),
  //       campaign_id: selectedCampaignIds.join(","),
  //       ad_name: selectedAdNames.join(","),
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("ri/time-slot-heatmap/", config);
  //     setAudienceActivity(
  //       res.data === undefined || res.data.length === 0 ? [] : 
  //       res.data.data === undefined  || res.data.data.length === 0? [] : 
  //       res.data.data
  //       );
  //     setAudienceActivityXaxisLabels(
  //       res.data === undefined || res.data.length === 0 ? [] : 
  //       res.data.xaxis_labels === undefined || res.data.xaxis_labels.length === 0 ? [] : 
  //       res.data.xaxis_labels
  //     );
  //     setAudienceActivityYaxisLabels(
  //       res.data === undefined || res.data.length === 0 ? [] : 
  //       res.data.yaxis_labels === undefined || res.data.yaxis_labels.length === 0 ? [] : 
  //       res.data.yaxis_labels
  //     );
  //     setAudienceActivityIsLoading(false);
  //     setAudienceActivityIsLoaded(true);
  //     setAudienceActivityError(null);
  //   } catch (error) {
  //     setAudienceActivity([]);
  //     setAudienceActivityXaxisLabels([]);
  //     setAudienceActivityYaxisLabels([]);
  //     setAudienceActivityIsLoading(false);
  //     setAudienceActivityIsLoaded(false);
  //     setAudienceActivityError(error.code);
  //   }
  // };

  // // AUDIENCE DISTRIBUTION: MALE
  // let audienceActivityTrend = <Wrapper><Loader loaderType="heatChartLoader" /></Wrapper>;

  // // IF DATA IS AVAILABLE
  // if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
  //   if (NoDataAvailableChecker(audienceActivity)) {
  //     audienceActivityTrend = (
  //       <Wrapper><NoDataAvailableLoader chartType="heatChartType" /></Wrapper>
  //     );
  //   } else {
  //     audienceActivityTrend = (
  //       <Wrapper>
  //       <ErrorBoundary chartType="heatChartType">
  //         <HeatMap
  //           chartId="audiencechart"
  //           chartClass="section_card_chart"
  //           chartData={audienceActivity}
  //           chartXaxisLabels={audienceActivityXaxisLabels}
  //           chartYaxisLabels={audienceActivityYaxisLabels}
  //           showLegend={true}
  //           showLabels={true}
  //           chartColorSet={HeatMapGreenColorSet}
  //         />
  //       </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (audienceActivityerror) {
  //   audienceActivityTrend = (
  //     <Wrapper>
  //       <ServerErrorsLoader
  //         chartType="heatChartType"
  //         error={audienceActivityerror}
  //       />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isAudienceActivityLoading) {
  //   audienceActivityTrend = <Wrapper><Loader loaderType="heatChartLoader" /></Wrapper>;
  // }



  const activePlatform = (platform) => {
    if (selectedBrandPlatform !== platform.brand_id) {
      setSelectedBrandPlatform(platform.brand_id);
      dispatch(setSelectedBrandsFromDropdown([]));
      dispatch(setSelectedCampaignsFromDropdown([]));
      dispatch(setSelectedAdsFromDropdown([]));
    } else {
      dispatch(setSelectedBrandsFromDropdown([]));
      dispatch(setSelectedCampaignsFromDropdown([]));
      dispatch(setSelectedAdsFromDropdown([]));
    }
    IAEvent_Platform_Visit("Retail Intelligence", "TV", platform);
  };

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    Summary: exportSummary,
    [`TVIndiaChart_Map_${placeDropdown === null || placeDropdown === undefined || placeDropdown === "" ? " " : placeDropdown.label}`]: MapData.features ? MapData.features : [],
    "TVcards_Brand Performance": metricCards,
    "PieofPie_Performance Metrics": performanceMetricCards,
    "Performance Trend": combineCSV(perfTrend),
    "TopGenreTv_Top Genre and GRPs": TopGenreData,
    "AdDuration_AD Duration Wise GRPs": AdDurationData,
    "TVBrandWiseTreemap_Brand Wise GRP and Spends": simpleTreemapData,
    "PieofPie_Ad Position Wise GRP": adPosition,
    "TvCampaignSummary_Ad Campaign Summary": campaignSummary,
    "TvCompare_Top Brands": topPostsCompare,
  };
  useEffect(() => {
    setExportPageName("TV");
    setFullPageExport(allData);
  }, [
    MapData,
    metricCards,
    performanceMetricCards,
    perfTrend,
    TopGenreData,
    // TimeSlotData,
    AdDurationData,
    simpleTreemapData,
    // audienceActivity,
    adPosition,
    campaignSummary,
    topPostsCompare,
  ]);

  useEffect(() => {
    if (
      isMetricCardsLoaded &&
      isMapLoaded &&
      isPerformanceMetricCardsLoaded &&
      isPerfTrendLoaded &&
      isTopGenreLoaded &&
      isAdDurationLoaded &&
      isSimpleTreemapDataLoaded &&
      isAdPositionLoaded &&
      isCampaignSummaryLoaded &&
      isTopPostsCompareLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMetricCardsLoaded,
    isMapLoaded,
    isPerformanceMetricCardsLoaded,
    isPerfTrendLoaded,
    isTopGenreLoaded,
    isAdDurationLoaded,
    isSimpleTreemapDataLoaded,
    isAdPositionLoaded,
    isCampaignSummaryLoaded,
    isTopPostsCompareLoaded
  ])


  useEffect(() => {
    setexportSummary([
      selectedDates,
      {
        "Brand ID": selectedBrandIds.join(","),
        "Campaign Ads": selectedCampaignIds.join(","),
        "AD Names": selectedAdNames.join(","),
        "Market": placeDropdown === null || placeDropdown === undefined || placeDropdown === "" ? " " : placeDropdown.label
      },
    ]);
  }, [selectedDates, selectedBrandIds, selectedCampaignIds, selectedAdNames, placeDropdown]);

  return (
    <Wrapper>
      <div className="grid drawer_grid">
        <div
          className="left_drawer col_span_1 fixed-left-grid mapRightWrapper"
          id="india_map"
        >
          <div className="section_card">
            <div className="mapHeaderWrapper w_100">
              <div className="grid col_span_4">


                <div className="section_card_header mapHeader">
                  <div className="section_info">

                    <h2 className="section_card_title card_map_title">
                      {userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Map"}
                      module="tv"
                      platform="overall"
                      section="overall"
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    {(userData.country_code !== "US" && userData.country_code !== "UK") &&
                      <Wrapper>
                        <Dropdown
                          ismulti={false}
                          isClearable={true}
                          placeholder={"Select Market"}
                          options={placeOptions}
                          className="form_dropdown section_dropdown small-dropdown"
                          value={placeDropdown}
                          selectedOptions={placeDropdown}
                          setStatedropdown={placeSelectionHandler}
                        />
                      </Wrapper>
                    }
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}

                      sectionId={"india_map"}
                      sectionName={`TVIndiaChart_Map_${placeDropdown === null || placeDropdown === undefined || placeDropdown === "" ? " " : placeDropdown.label}`}
                      sectionData={MapData.features}
                    // sectionChart={"TVIndiaChart"}
                    />
                  </div>
                </div>




              </div>
              <div className="grid col_span_4">
                <div className="section_card_header map_breadcrumb_header">
                  {mapBreadCrumbList.map((item, index) => (
                    <div
                      onClick={() => {
                        mapBreadcrumbsHandler(index, item);
                      }}
                      key={index}
                    >
                      {index === 0 ? " " : "/ "}
                      <TextFormatter string={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="section_card_body mapBoxContainer">
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_map_wrapper">
                      {IndiaMapChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mapBoxContainer">
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle icz-rilist-bullet1"></div>{" "}
                    North
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet2"></div>{" "}
                    West
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet3"></div>{" "}
                    South
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet4"></div>{" "}
                    East
                  </li>
                </ul>
              </div>
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle circleGreen"></div> Growth
                  </li>
                  <li>
                    <div className="legend-circle circleRed"></div> Degrowth
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="right_side col_span_3 relative-right-grid">
          <Wrapper>
            <div className="content">
              <div className="content_section">
                {selectedBrandIds.length !== 0 && (
                  <div className="header_badges_wrapper">
                    <div className="badges_list_wrapper">
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedBrandIds.length} Brands Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedCampaignIds.length} Campaigns Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedAdNames.length} Ads Selected
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <PageHeader pageTitle={"TV Overview"} />
                <div className="page_content">
                  <div className="dashboard_home">
                    {metricCardsSection}

                    {isMetricCardsLoaded && (
                      <Wrapper>
                        {performanceMetricCardsSection}
                        <div
                          id="Performance_Trend_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <div className="section_info">
                                {" "}
                                <h2 className="section_card_title">
                                  Performance Trend
                                </h2>{" "}
                                <SectionDefinationsBadge
                                  title={"Performance Trend"}
                                  module="tv"
                                  platform="overall"
                                  section="overall"
                                />
                              </div>
                              <Modal
                                open={isPerfTrendOpen}
                                setOpen={setIsPerfTrendOpen}
                                dropdown={
                                  <Dropdown
                                    ismulti={false}
                                    placeholder={"Select"}
                                    options={performanceOptions}
                                    className="form_dropdown section_dropdown ml_20"
                                    value={performanceDropdown}
                                    selectedOptions={performanceDropdown}
                                    setStatedropdown={
                                      performanceSelectionHandler
                                    }
                                  />
                                }
                                exportDropdown={
                                  <ExportDropdown
                                    sectionId={
                                      "social_facebook_current_period_2"
                                    }
                                    sectionName={"Performance Trend"}
                                    sectionData={combineCSV(perfTrend)}
                                  />
                                }
                                parentId={"Performance_Trend_Section"}
                                title={`Performance Trend`}
                                chart={perfTrendChart2}
                              />
                              <div data-html2canvas-ignore={true}>
                                {isMultiLineCluster && (
                                  <button
                                    onClick={() => setIsPerfTrendOpen(true)}
                                    className="icon_btn btn-small-secondary expand_btn"
                                  >
                                    <ExpandIcon />
                                  </button>
                                )}
                                <Dropdown
                                  ismulti={false}
                                  placeholder={"Select"}
                                  options={performanceOptions}
                                  className="form_dropdown section_dropdown ml_20"
                                  value={performanceDropdown}
                                  selectedOptions={performanceDropdown}
                                  setStatedropdown={performanceSelectionHandler}
                                />
                                <ExportDropdown
                                  sectionId={"Performance_Trend_Section"}
                                  sectionName={"Performance Trend"}
                                  sectionData={combineCSV(perfTrend)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {perfTrendChart}
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* <div id="brand_wise_market_share_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Brand Wise Market Share
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"brand_wise_market_share_section"}
                                  sectionName={"Brand Wise Market Share"}
                                  sectionData={brandwiseMarket}
                                  sectionDataHeaders={['Date']}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {brandwiseMarketLineChart}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">Donut</h2>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            {pieValues.map((pieValue, index) => (
                              <div className="grid col_span_2 section_card">
                                <div className="section_card_body">
                                  <div className="section_card_chart_wrapper">
                                    <DonutPieChart
                                      key={index}
                                      chartClass="section_card_chart"
                                      chartId={index}
                                      showLegend={true}
                                      showLabels={true}
                                      showVerticalLabels={true}
                                      chartData={pieValue.events}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>*/}
                        <div
                          id="top_genra_grp_section"
                          className="grid grid_cols_2 grid_margin_bottom"
                        >
                          <div className="grid col_span_1 section_card">
                            <div className="section_card_header">
                              <div className="section_info">   <h2 className="section_card_title">
                                Top Genre and GRPs
                              </h2>  <SectionDefinationsBadge
                                  title={"Top Genre & GRP"}
                                  module="tv"
                                  platform="overall"
                                  section="overall"
                                />
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}

                                  sectionId={"top_genra_grp_section"}
                                  sectionName={"TopGenreTv_Top Genre and GRPs"}
                                  sectionData={TopGenreData}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {topGenreBarChart}
                              </div>
                            </div>
                          </div>
                          {/* <div id="time_slot_grp_section" className="grid col_span_2 section_card">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Time Slot & GRP
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"time_slot_grp_section"}
                                  sectionName={"Time Slot & GRP"}
                                  sectionData={TimeSlotData}
                                  sectionDataHeaders={[["Time"]]}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {timeSlotBarChart}
                              </div>
                            </div>
                          </div> */}
                          <div
                            id="ad_duration_wise_grp_section"
                            className="grid col_span_1 section_card"
                          >
                            <div className="section_card_header">
                              <div className="section_info">   <h2 className="section_card_title">
                                AD Duration Wise GRP
                              </h2> <SectionDefinationsBadge
                                  title={"Ad duration wise GRP"}
                                  module="tv"
                                  platform="overall"
                                  section="overall"
                                />
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}

                                  sectionId={"ad_duration_wise_grp_section"}
                                  sectionName={"AD Duration Wise GRP"}
                                  sectionData={AdDurationData}
                                // sectionDataHeaders={[["Ad Duration"]]}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {adDurationBarChart}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="Brand_Wise_GRP_and_Spends_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header p_b_0 ">
                              <div className="flex column align_start">
                                <div className="section_info">    <h2 className="section_card_title">
                                  Brand Wise GRP and Spends
                                </h2> <SectionDefinationsBadge
                                    title={"Brand wise GRP & Spends"}
                                    module="tv"
                                    platform="overall"
                                    section="overall"
                                  />
                                </div>
                                {Object.keys(simpleTreemapData).length !==
                                  0 && (
                                    <div className="treemap_breadcrumb">
                                      <span
                                        onClick={() => {
                                          setTreemapParams({
                                            start_date: selectedDates.startDate,
                                            end_date: selectedDates.endDate,
                                            previous_start_date:
                                              selectedDates.prevStartDate,
                                            previous_end_date:
                                              selectedDates.prevEndDate,
                                            zone: mapZone,
                                            state_code: mapStateCode,
                                            market: mapMarket,
                                            brand_id: "all_brands",
                                            campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
                                            ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
                                            level: 1,
                                          });
                                          setTreemapBrand("");
                                          setTreemapBrandCategory("");
                                        }}
                                      >
                                        Home
                                      </span>
                                      {treemapBrand && (
                                        <span
                                          onClick={() => {
                                            setTreemapParams({
                                              start_date: selectedDates.startDate,
                                              end_date: selectedDates.endDate,
                                              previous_start_date:
                                                selectedDates.prevStartDate,
                                              previous_end_date:
                                                selectedDates.prevEndDate,
                                              zone: mapZone,
                                              state_code: mapStateCode,
                                              market: mapMarket,
                                              brand_id: treemapBrandId,
                                              campaign_id: selectedCampaignIds.length !== 0 || selectedCampaignIds === undefined ? selectedCampaignIds.join(",") : null,
                                              ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
                                              level: 2,
                                            });
                                            setTreemapBrand("");
                                            setTreemapBrandCategory("");
                                          }}
                                        >
                                          {" "}
                                          /{" "}
                                          <TextFormatter string={treemapBrand} />
                                        </span>
                                      )}

                                      {treemapBrandCategory && (
                                        <span>
                                          {" "}
                                          /{" "}
                                          <TextFormatter
                                            string={treemapBrandCategory}
                                          />{" "}
                                        </span>
                                      )}
                                    </div>
                                  )}
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}

                                  sectionId={
                                    "Brand_Wise_GRP_and_Spends_Section"
                                  }
                                  sectionName="TVBrandWiseTreemap_Brand Wise GRP and Spends"
                                  sectionData={simpleTreemapData}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper flex column">
                                {/* {brandWiseSection} */}
                                {sampleTreemapChart}
                              </div>
                              {Object.keys(simpleTreemapData).length !== 0 && (
                                <div className="w_100">
                                  <ul className="icz-rilist-inline">
                                    <li>
                                      <div className="legend-circle circleGreen"></div>{" "}
                                      Growth
                                    </li>
                                    <li>
                                      <div className="legend-circle circleRed"></div>{" "}
                                      Degrowth
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div id="Time_Slot_Wise_GRP_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid grid_cols_1">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Time Slot Wise GRP
                              </h2>

                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"Time_Slot_Wise_GRP_Section"}
                                  sectionName={"Time Slot Wise GRP"}
                                  sectionData={audienceActivity}
                                  sectionDataHeaders={[
                                    ["value", "Time", "Day", "Day of Week"],
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid_cols_1">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {audienceActivityTrend}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div
                          id="Ad_Position_Wise_GRP_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <div className="section_info">    <h2 className="section_card_title">
                                Ad Position Wise GRP
                              </h2>  <SectionDefinationsBadge
                                  title={"Ad position wise GRP"}
                                  module="tv"
                                  platform="overall"
                                  section="overall"
                                />
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}

                                  sectionId={"Ad_Position_Wise_GRP_Section"}
                                  sectionName={"PieofPie_Ad Position Wise GRP"}
                                  sectionData={adPosition}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4 section_card_body">
                            <div className="section_card_chart_wrapper p_t_0 p_b_0">
                              {adPositionPie}
                            </div>
                          </div>
                        </div>

                        {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                            <div className="section_info">    <h2 className="section_card_title">
                                Ad/ Campaign Summary
                                </h2> <SectionDefinationsBadge
                                  title={"Ad / Campaign Summary"}
                                  module="tv"
                                  platform="overall"
                                  section="overall"
                                />
                             </div>
                            </div>
                          </div>
                          <div className="grid col_span_4 section_card_body">
                            <div className="section_card_body"> */}
                        <div className="grid_margin_bottom">
                          {campaignSummaryTable}
                        </div>
                        {/* </div>
                          </div>
                        </div> */}

                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {topPostsCompareFeat}
                          </div>
                        </div>

                        {brandMinMaxDataDates !== undefined &&
                          <Wrapper>
                            <div className="page_footer">
                              TV data available from {" " + formatDateDisplay(minDate) + " "} to  {" " + formatDateDisplay(maxDate)}
                            </div>
                          </Wrapper>
                        }

                      </Wrapper>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </Wrapper>
  );
};
export default TV;
