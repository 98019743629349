import React, { useEffect, useState } from 'react'
import IndiaMapImg from '../../../../../assets/images/demoStorescore/IndiaMap.png'
import BranchScoreImg from '../../../../../assets/images/demoStorescore/BranchPerfIndex.png'
import ScoreTrendImg from '../../../../../assets/images/demoStorescore/ScoreTrend.png'

import ProductAssortLvl1Img from '../../../../../assets/images/demoStorescore/ProductAssortLvl1.png'
import ProductAssortLvl2Img from '../../../../../assets/images/demoStorescore/ProductAssortLvl2.png'
import ProductAssortLvl3Img from '../../../../../assets/images/demoStorescore/ProductAssortLvl3.png'
import CustomerSegmentationImg from '../../../../../assets/images/demoStorescore/CustomerSegmentation.png'
import BranchPerfOverCh1Img from '../../../../../assets/images/demoStorescore/BranchPerfOverCh1.png'
import BranchPerfOverCh2Img from '../../../../../assets/images/demoStorescore/BranchPerfOverCh2.png'
import BranchPerfImg from '../../../../../assets/images/demoStorescore/BranchPerf.png';

import RevenueOneImg from '../../../../../assets/images/demoStorescore/RevenueTab/RevenueOne.png';
import RevenueTwoImg from '../../../../../assets/images/demoStorescore/RevenueTab/RevenueTwo.png';
import RevenueThreeImg from '../../../../../assets/images/demoStorescore/RevenueTab/RevenueThree.png';
import RevenueFourImg from '../../../../../assets/images/demoStorescore/RevenueTab/RevenueFour.png';

import Customer1Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer1.png';
import Customer2Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer2.png';
import Customer3Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer3.png';
import Customer4Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer4.png';
import Customer5Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer5.png';
import Customer6Img from '../../../../../assets/images/demoStorescore/CustomerTab/Customer6.png';

import Presence1Img from '../../../../../assets/images/demoStorescore/PresenceTab/Presence1.png'
import Presence2Img from '../../../../../assets/images/demoStorescore/PresenceTab/Presence2.png'
import Presence3Img from '../../../../../assets/images/demoStorescore/PresenceTab/Presence3.png'

import Category1img from '../../../../../assets/images/demoStorescore/CategoryTab/Category1.png'
import Category2img from '../../../../../assets/images/demoStorescore/CategoryTab/Category2.png'
import Category3img from '../../../../../assets/images/demoStorescore/CategoryTab/Category3.png'
import Category4img from '../../../../../assets/images/demoStorescore/CategoryTab/Category4.png'

import TopMover1Img from '../../../../../assets/images/demoStorescore/Top5Movers/TopMover1.png'
import TopMover2Img from '../../../../../assets/images/demoStorescore/Top5Movers/TopMover2.png'
import TopMover3Img from '../../../../../assets/images/demoStorescore/Top5Movers/TopMover3.png'
import TopMover4Img from '../../../../../assets/images/demoStorescore/Top5Movers/TopMover4.png'
import TopMover5Img from '../../../../../assets/images/demoStorescore/Top5Movers/TopMover5.png'

import BottomMover1Img from '../../../../../assets/images/demoStorescore/Bottom5Movers/BottomMover1.png'
import BottomMover2Img from '../../../../../assets/images/demoStorescore/Bottom5Movers/BottomMover2.png'
import BottomMover3Img from '../../../../../assets/images/demoStorescore/Bottom5Movers/BottomMover3.png'
import BottomMover4Img from '../../../../../assets/images/demoStorescore/Bottom5Movers/BottomMover4.png'
import BottomMover5Img from '../../../../../assets/images/demoStorescore/Bottom5Movers/BottomMover5.png'

import BranchParams1Img from '../../../../../assets/images/demoStorescore/BranchParam/BranchParam1.png'
import BranchParams2Img from '../../../../../assets/images/demoStorescore/BranchParam/BranchParam2.png'
import BranchParams3Img from '../../../../../assets/images/demoStorescore/BranchParam/BranchParam3.png'
import BranchParams4Img from '../../../../../assets/images/demoStorescore/BranchParam/BranchParam4.png'
import BranchParams5Img from '../../../../../assets/images/demoStorescore/BranchParam/BranchParam5.png'

import OtherParams1Img from '../../../../../assets/images/demoStorescore/OtherParams/OtherParams1.png'
import OtherParams2Img from '../../../../../assets/images/demoStorescore/OtherParams/OtherParams2.png'
import OtherParams3Img from '../../../../../assets/images/demoStorescore/OtherParams/OtherParams3.png'

import IconDownload from '../../../../assets/images/icons/downloadIcon'
import Dropdown from '../../../../../components/ui/dropdown/Dropdown'
import PageHeader from '../../../../../components/layout/pageHeader'
import Tab from '../../../../../components/ui/Tab'
import IconUserGroup from '../../../../assets/images/icons/usergroupIcon'
import IconTag from '../../../../assets/images/icons/tagIcon'
import IconStore from '../../../../assets/images/icons/storeIcon'
import IconWebsite from '../../../../assets/images/icons/websiteIcon'
import { SwiperSlide } from 'swiper/react'
import Slider from '../../../../../components/ui/Slider'
import Wrapper from '../../../../../components/helpers/Wrapper'
import './RiStoreScore.scss'
import RiCategoryIcon from '../../../../assets/images/icons/RiCategoryIcon'
import TopMoversIcon from '../../../../assets/images/icons/TopMoversIcon'
import BottomMoversIcon from '../../../../assets/images/icons/BottomMoversIcon'


export default function RiStoreScore() {

  const [selectedTab, setSelectedTab] = useState('Revenue')


  const [selectedMoversTab, setSelectedMoversTab] = useState('Top 5 Movers')


  const RevenueTabImgs = [RevenueOneImg, RevenueTwoImg, RevenueThreeImg, RevenueFourImg];
  const CustomerTabImgs = [Customer1Img, Customer2Img, Customer3Img, Customer4Img, Customer5Img, Customer6Img];
  const PresenceTabImgs = [Presence1Img, Presence2Img, Presence3Img];

  const CategoryTabImgs = [Category1img, Category2img, Category3img, Category4img];

  const TopMoverTabImg = [TopMover1Img, TopMover2Img, TopMover3Img, TopMover4Img, TopMover5Img,];
  const BottomMoverTabImg = [BottomMover1Img, BottomMover2Img, BottomMover3Img, BottomMover4Img, BottomMover5Img,];


  const BranchParamsImgTab = [BranchParams1Img, BranchParams2Img, BranchParams3Img, BranchParams4Img, BranchParams5Img]
  const OtherParamsImgTab = [OtherParams1Img, OtherParams2Img, OtherParams3Img]


  const TreeMapImgTabs = [ProductAssortLvl1Img, ProductAssortLvl2Img, ProductAssortLvl3Img]

  const [treemapImg, setTreemapImg] = useState(0);


  return (
    <div className="grid drawer_grid ri_summary_bsImport icz-rimapsection riStoreScore">
      <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper" id="india_map">
        <div id="indiaMap" className="section_card">
          <div className="mapHeaderWrapper w_100">
            <div className="grid col_span_4">

              <div className="section_card_header mapHeader icz-rimapsection ">
                <div className="section_info">
                  <h2 className="section_card_title card_map_title">
                    India
                  </h2>
                </div>
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    selectedOptions={{ label: 'Filters', value: 'filter' }}
                    options={[{ label: 'Filters', value: 'filter' }]}
                  />
                  <div className="icz-ribtnwrapper">
                    <button className="icz-rithemebtn borderRadClr">
                      <IconDownload className="icz-riicon" /> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section_card_body mapBoxContainer">
            <div className="grid grid_cols_4 grid_margin_bottom">
              <div className="grid col_span_4">
                <div className="section_card_body">
                  <div className="section_card_map_wrapper">
                    <img src={IndiaMapImg} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer" style={{ padding: "0" }}>
        <div className="content">
          <div className="content_section">
            <PageHeader pageTitle={"Branch Performance Index"} />
            <div className='page_content'>
              <div className='dashboard_home'>


                <div id="branch_score" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Branch Score
                        </h2>
                      </div>
                      <div className="icz-ribtnwrapper">
                        <button className="icz-rithemebtn borderRadClr">
                          <IconDownload className="icz-riicon" /> </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_4">
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        <img src={BranchScoreImg} alt='BranchScoreImg' />
                      </div>
                    </div>
                  </div>
                </div>


                <div id="branch_score" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Score Trend
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_4">
                    <div className="section_card_body">
                      <div className="p_20">
                        <img src={ScoreTrendImg} alt='ScoreTrendImg' />
                      </div>
                    </div>
                  </div>
                </div>


                <div className=' grid_margin_bottom'>
                  <Tab
                    varient={"primary"}
                    tabData={['Revenue', 'Customer', 'Presence', 'Category']}
                    activeTab={'Revenue'}
                    handleCallback={(event) => setSelectedTab(event)}
                    icon={
                      [<IconTag className="icz-tabicon valign_baseline" />,
                      <IconUserGroup className="icz-tabicon" />,
                      <IconStore className="icz-tabicon" />,
                      <RiCategoryIcon className="icz-tabicon valign_baseline" />
                      ]}
                  />

                  <div className="grid grid_margin_bottom">
                    <div className="card_wrapper">
                      <div className='static-card-wrapper'>

                        {selectedTab === 'Revenue' ?
                          (<Wrapper >
                            <Slider
                              sliderWrapperClass={"tab_card_slider"}
                              showSliderHeader={false}
                              showNavigation={false}
                              sliderTitle={"Summary"}
                              cardSpace={0}
                            >
                              {RevenueTabImgs.map((data, i) => (
                                <SwiperSlide
                                  key={i}
                                >
                                  <div className='static-img-size' key={i}>
                                    <img src={data} alt={i} />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Slider>
                          </Wrapper>) :

                          selectedTab === 'Customer' ?
                            <Wrapper >
                              <Slider
                                sliderWrapperClass={"tab_card_slider"}
                                showSliderHeader={false}
                                showNavigation={false}
                                sliderTitle={"Summary"}
                                cardSpace={0}
                              >
                                {CustomerTabImgs.map((data, i) => (
                                  <SwiperSlide
                                    key={i}
                                  >
                                    <div className='static-img-size' key={i}>
                                      <img src={data} alt={i} />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Slider>
                            </Wrapper>
                            :

                            selectedTab === 'Presence' ?
                              <Wrapper >
                                <Slider
                                  sliderWrapperClass={"tab_card_slider"}
                                  showSliderHeader={false}
                                  showNavigation={false}
                                  sliderTitle={"Summary"}
                                  cardSpace={0}
                                >
                                  {PresenceTabImgs.map((data, i) => (
                                    <SwiperSlide
                                      key={i}
                                    >
                                      <div className='static-img-size' key={i}>
                                        <img src={data} alt={i} />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Slider>
                              </Wrapper>
                              :

                              selectedTab === 'Category' ?
                                <Wrapper >
                                  <Slider
                                    sliderWrapperClass={"tab_card_slider"}
                                    showSliderHeader={false}
                                    showNavigation={false}
                                    sliderTitle={"Summary"}
                                    cardSpace={0}
                                  >
                                    {CategoryTabImgs.map((data, i) => (
                                      <SwiperSlide
                                        key={i}
                                      >
                                        <div className='static-img-size' key={i}>
                                          <img src={data} alt={i} />
                                        </div>
                                      </SwiperSlide>
                                    ))}
                                  </Slider>
                                </Wrapper>
                                :
                                ''}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="branch_score" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Branch Performance Overview
                        </h2>
                      </div>
                      <div className="icz-ribtnwrapper">
                        <button className="icz-rithemebtn borderRadClr">
                          <IconDownload className="icz-riicon" /> </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid_cols_4 pb_20">
                    <div className='grid col_span_2'>
                      <div className="section_card_body">
                        <div className="section_card_chart_wrapper">
                          <img src={BranchPerfOverCh1Img} alt='BranchPerfOverCh1Img' />
                        </div>
                      </div>
                    </div>
                    <div className='grid col_span_2'>
                      <div className="section_card_body">
                        <div className="section_card_chart_wrapper">
                          <img src={BranchPerfOverCh2Img} alt='BranchPerfOverCh2Img' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className=' '>
                  <Tab
                    varient={"primary"}
                    tabData={['Top 5 Movers', "Bottom 5 Movers"]}
                    activeTab={'Top 5 Movers'}
                    handleCallback={(event) => setSelectedMoversTab(event)}
                    icon={[
                      <TopMoversIcon className="icz-tabicon fillIcon valign_baseline" />,
                      <BottomMoversIcon className="icz-tabicon fillIcon valign_baseline" />
                    ]}
                  />

                  <div className="grid">
                    <div className="card_wrapper">
                      <div className='static-card-wrapper'>
                        {selectedMoversTab === 'Top 5 Movers' ? (
                          <Wrapper >
                            <Slider
                              sliderWrapperClass={"tab_card_slider"}
                              showSliderHeader={false}
                              showNavigation={false}
                              sliderTitle={"Summary"}
                              cardSpace={0}
                              breakpoints={
                                {
                                  640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                  },
                                  1024: {
                                    slidesPerView: 3,
                                  },
                                }
                              }
                            >
                              {TopMoverTabImg.map((data, i) => (
                                <SwiperSlide
                                  key={i}
                                >
                                  <div className='static-img-size' key={i}>
                                    <img src={data} alt={i} />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Slider>
                          </Wrapper>

                        ) : selectedMoversTab === 'Bottom 5 Movers' ?

                          <Wrapper >
                            <Slider
                              cardSpace={0}
                              sliderWrapperClass={"tab_card_slider"}
                              showSliderHeader={false}
                              showNavigation={false}
                              sliderTitle={"Summary"}
                              breakpoints={
                                {
                                  640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                  },
                                  1024: {
                                    slidesPerView: 3,
                                  },
                                }
                              }
                            >
                              {BottomMoverTabImg.map((data, i) => (
                                <SwiperSlide
                                  key={i}
                                >
                                  <div className='static-img-size' key={i}>
                                    <img src={data} alt={i} />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Slider>
                          </Wrapper>


                          : ''}

                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid_cols_4  removeRowSpace">
                  <div className="grid col_span_4 section_header">
                    <div className="section_info">
                      <h2 className="section_card_title h_40">Branch Parameters</h2>
                    </div>
                  </div>
                  <div className="grid col_span_4 ">
                    <div className="card_wrapper">
                      <div className='static-card-wrapper'>
                        <Wrapper >
                          <Slider
                            cardSpace={0}
                            sliderWrapperClass={"tab_card_slider"}
                            showSliderHeader={false}
                            showNavigation={false}
                            sliderTitle={"Summary"}
                            breakpoints={
                              {
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                },
                              }
                            }
                          >
                            {BranchParamsImgTab.map((data, i) => (

                              <SwiperSlide key={i}>

                                <div className='static-img-size' key={i}>
                                  <img src={data} alt={i} />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Slider>
                        </Wrapper>
                      </div>
                    </div>

                  </div>
                </div>


                <div className="grid grid_cols_4  removeRowSpace">
                  <div className="grid col_span_4 section_header">
                    <div className="section_info">
                      <h2 className="section_card_title h_40">Other Parameters</h2>
                    </div>
                  </div>
                  <div className="grid col_span_4 ">
                    <div className="card_wrapper">
                      <div className='static-card-wrapper'>
                        <Wrapper >
                          <Slider
                            cardSpace={0}
                            sliderWrapperClass={"tab_card_slider"}
                            showSliderHeader={false}
                            showNavigation={false}
                            sliderTitle={"Summary"}
                            breakpoints={
                              {
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                },
                              }
                            }
                          >
                            {OtherParamsImgTab.map((data, i) => (

                              <SwiperSlide key={i}>

                                <div className='static-img-size' key={i}>
                                  <img src={data} alt={i} />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Slider>
                        </Wrapper>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="branch_score" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Branch Performance
                        </h2>
                      </div>
                      <div data-html2canvas-ignore={true}>
                        <Dropdown
                          selectedOptions={{ label: 'No. of Customers', value: 'no_of_customer' }}
                          options={[
                            { label: 'No. of Customers', value: 'No. of Customers' },
                            { label: 'Avg. Revenue Per Transaction', value: 'Avg. Revenue Per Transaction' },
                            { label: 'No. of Transaction', value: 'No. of Transaction' },
                            { label: 'Transaction Type', value: 'Transaction Type' },
                            { label: 'KPI Achievement', value: 'KPI Achievement' },
                            { label: 'Net Margin %', value: 'Net Margin %' },
                            { label: 'Product vs Service %', value: 'Product vs Service %' },
                            { label: 'Conversion Rate %', value: 'Conversion Rate %' },
                            { label: 'Customer Repeat %', value: 'Customer Repeat %' },
                          ]}
                          setStatedropdown={(e) => ''}
                        />
                        <div className="icz-ribtnwrapper">
                          <button className="icz-rithemebtn borderRadClr">
                            <IconDownload className="icz-riicon" /> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_4">
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        <img src={BranchPerfImg} alt='BranchPerfImg' />
                      </div>
                    </div>
                  </div>
                </div>



                <div id="branch_score" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="flex column align_start">

                        <div className="section_info">
                          <h2 className="section_card_title">
                            Product Assortment
                          </h2>
                        </div>
                        <div className="treemap_breadcrumb">
                          <span onClick={() => setTreemapImg(0)}>Home</span>
                          {(treemapImg === 1 || treemapImg === 2) && <span onClick={() => setTreemapImg(1)} > / Cards</span>}
                          {treemapImg === 2 && <span onClick={() => setTreemapImg(2)}> / Credit Cards</span>}
                        </div>
                      </div>

                      <div className="icz-ribtnwrapper">
                        <button className="icz-rithemebtn borderRadClr">
                          <IconDownload className="icz-riicon" /> </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_4">
                    <div className="section_card_body ">
                      <div className="section_card_chart_wrapper">
                        {treemapImg === 0 && (<img src={ProductAssortLvl1Img} alt='ProductAssortLvl1Img' className='cursor_ptr' onClick={() => setTreemapImg(1)} />)}
                        {treemapImg === 1 && (<img src={ProductAssortLvl2Img} alt='ProductAssortLvl1Img' className='cursor_ptr' onClick={() => setTreemapImg(2)} />)}
                        {treemapImg === 2 && (<img src={ProductAssortLvl3Img} alt='ProductAssortLvl1Img' className='cursor_ptr' />)}

                        <div className="w-100">
                          <div className="icz-ridatabullet">
                            <ul className="icz-ridatalist-inline">
                              <li> <b> Area = Revenue</b></li>
                              <li><div className="icz-ridatalist-bullet5" /> High Target Achieved %</li>
                              <li><div className="icz-ridatalist-bullet2" style={{ background: '#db7823' }} /> Med Target Achieved %</li>
                              <li><div className="icz-ridatalist-bullet6" /> Low Target Achieved %</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div id="branch_score" className="grid grid_cols_4 fullpage_section_card section_card">
                  <div className="grid col_span_4">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Customer Segmentation
                        </h2>
                      </div>
                      <div className="icz-ribtnwrapper">
                        <button className="icz-rithemebtn borderRadClr">
                          <IconDownload className="icz-riicon" /> </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_4">
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        <img src={CustomerSegmentationImg} alt='CustomerSegmentationImg' />
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  )
}
