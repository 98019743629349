import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class CollapsibleForceDirectedTreeChart extends Component {

    componentDidMount() {
        let info = this.props

        let chart_id = info['chart_id'];
        let data = JSON.parse(info['graph-data']);

        let chart = am4core.create(chart_id, am4plugins_forceDirected.ForceDirectedTree);
        let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

        networkSeries.dataFields.linkWith = "linkWith";
        networkSeries.dataFields.name = "name";
        networkSeries.dataFields.id = "name";
        networkSeries.dataFields.value = "value";
        networkSeries.dataFields.children = "children";

        networkSeries.nodes.template.tooltipText = "{name}";
        networkSeries.tooltip.getFillFromObject = false;
        networkSeries.tooltip.background.fill = am4core.color("#213345");
        networkSeries.tooltip.background.stroke = am4core.color("#213345");
        networkSeries.tooltip.label.fill = am4core.color("#ffffff");
        networkSeries.tooltip.label.fontSize = 12;

        networkSeries.nodes.template.fillOpacity = 1;

        networkSeries.nodes.template.label.text = "{name}"
        networkSeries.fontSize = 8;
        networkSeries.maxLevels = 3;
        networkSeries.maxRadius = am4core.percent(6);
        networkSeries.manyBodyStrength = -16;
        networkSeries.nodes.template.label.hideOversized = true;
        networkSeries.nodes.template.label.truncate = true;

        networkSeries.data = data;
        chart.fontFamily = "Ubuntu"; //TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12; //TO CHANGE FONT SIZE OF CHART LABELS

        var colorSet = new am4core.ColorSet();
        colorSet.list = ["#11A1FD", "#FF9931", "#5A75F9", "#07C180", "#8ca0ff", "#4CC3FD", "#039461", "#8298FB", "#3CD795", "#FFB866"].map(function (color) {
          return new am4core.color(color);
        });
        networkSeries.colors = colorSet;

    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
        )
    }
}
