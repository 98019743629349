import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Card from "../../../components/ui/Card";
import Slider from "../../../components/ui/Slider";

// PAGES
import FacebookCompare from "./FacebookCompare";
import InstagramCompare from "./InstagramCompare";
import TwitterCompare from "./TwitterCompare";

// ASSETS
import FacebookIcon from "../../../assets/images/social/facebook.png";
import InstagramIcon from "../../../assets/images/social/instagram.png";
import TwitterIcon from "../../../assets/images/social/twitter.png";

// UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { IAEvent_Platform_Visit } from "../../../utils/IAEvents";

const Compare = () => {
  const authCtx = useContext(AuthContext);
  const { exportSummary, setexportSummary } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

  const [selectedPlatform, setSelectedPlatform] = useState("Facebook");

  // NO OF COMPETITORS ON FACEBOOK
  const [facebookCompetitorsList, setFacebookCompetitorsList] = useState([]);

  // NO OF COMPETITORS ON INSTAGRAM
  const [instagramCompetitorsList, setinstagramCompetitorsList] = useState([]);

  // NO OF COMPETITORS ON TWITTER
  const [twitterCompetitorsList, settwitterCompetitorsList] = useState([]);

  const PlatformCardData = [
    {
      cardTitle: "Facebook",
      cardIcon: FacebookIcon,
      variant: "compare",
      showPreviousCompare: false,
      showSecondRowCompare: false,
      hoverDetails: "No of Competitors",
      currentValue: facebookCompetitorsList.length,
      rowOneTitle: "No of Competitors",
      numberVariant: "number",
    },
    {
      cardTitle: "Instagram",
      cardIcon: InstagramIcon,
      variant: "compare",
      showPreviousCompare: false,
      showSecondRowCompare: false,
      hoverDetails: "No of Competitors",
      currentValue: instagramCompetitorsList.length,
      rowOneTitle: "No of Competitors",
      numberVariant: "number",
    },
    {
      cardTitle: "Twitter",
      cardIcon: TwitterIcon,
      variant: "compare",
      showPreviousCompare: false,
      showSecondRowCompare: false,
      hoverDetails: "No of Competitors",
      currentValue: twitterCompetitorsList.length,
      rowOneTitle: "No of Competitors",
      numberVariant: "number",
    },
  ];

  // TO SET PLATFORM ACTIVE ON CLICK
  const activePlatform = (platform) => {
    if (selectedPlatform == "") {
      setSelectedPlatform(platform);
    } else {
      setSelectedPlatform(platform);
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Compare", platform)
  };

  // TO FETCH FACEBOOK COMPETITORS DATA FROM API
  const fetchFacebookCompetitorsListHandler = async () => {

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/compare/fb-compare-content/",
        config
      );

      // FOR GETTING THE UNIQUE COMPETITORS LIST
      let resFacebookContentList =
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.facebook_content_list === undefined || res.data.facebook_content_list.length === 0 ? [] :
            res.data.facebook_content_list

      let competitors_list = [];
      resFacebookContentList.map((competitor) => {
        try {
          let a = competitor.competitor_name;
          competitors_list.push(a);
        } catch (e) { }
      });
      let uniqueCompetitorsList = [...new Set(competitors_list)];

      setFacebookCompetitorsList(uniqueCompetitorsList === undefined || uniqueCompetitorsList.length === 0 ? [] : uniqueCompetitorsList);
    } catch (error) {
      setFacebookCompetitorsList([]);
    }
  };

  // TO FETCH FACEBOOK COMPETITORS DATA FROM API
  const fetchInstagramCompetitorsListHandler = async () => {
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/compare/insta-compare-content/",
        config
      );

      let resInstagramContentList =
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.instagram_content_list === undefined || res.data.instagram_content_list.length === 0 ? [] :
            res.data.instagram_content_list

      let data_list = [];
      resInstagramContentList.map((competitor) => {
        try {
          let a = competitor.competitor_name;
          data_list.push(a);
        } catch (e) { }
      });

      let uniqueList = [...new Set(data_list)];

      setinstagramCompetitorsList(uniqueList === undefined || uniqueList.length === 0 ? [] : uniqueList);
    } catch (error) {
      setinstagramCompetitorsList([]);
    }
  };

  // TO FETCH FACEBOOK COMPETITORS DATA FROM API
  const fetchTwitterCompetitorsListHandler = async () => {
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/compare/twitter-compare-content/",
        config
      );

      let resTwitterContentList =
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.twitter_content_list === undefined || res.data.twitter_content_list.length === 0 ? [] :
            res.data.twitter_content_list

      let data_list = [];
      resTwitterContentList.map((competitor) => {
        try {
          let a = competitor.handle_name;
          data_list.push(a);
        } catch (e) { }
      });

      let uniqueList = [...new Set(data_list)];

      settwitterCompetitorsList(uniqueList === undefined || uniqueList.length === 0 ? [] : uniqueList);
    } catch (error) {
      settwitterCompetitorsList([]);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let platformCardsSection = <NoDataAvailableLoader />

  // TO FETCH DATA FROM API ON FIRS LOAD
  useEffect(() => {
    fetchFacebookCompetitorsListHandler();
    fetchInstagramCompetitorsListHandler();
    fetchTwitterCompetitorsListHandler();
  }, [BRAND_ID, selectedDates]);

  useEffect(() => {
    setexportSummary([
      selectedDates,
      ...selectedBrands,
    ]);

  }, [
    selectedDates,
    selectedBrands,
  ]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Compare"} />
          <div className="page_content">
            <div className="dashboard_home">
              <div className="grid">
                <div className="card_wrapper">
                  <Wrapper>
                    <ErrorBoundary>
                      <Slider
                        sliderWrapperClass={"tab_card_slider"}
                        showSliderHeader={false}
                        showNavigation={false}
                        sliderTitle={"Summary"}
                      >
                        {PlatformCardData.map((data, index) => (
                          <SwiperSlide
                            onClick={() => activePlatform(data.cardTitle)}
                            key={index}
                          >
                            <Card
                              key={index}
                              cardClass={
                                selectedPlatform === data.cardTitle
                                  ? "tabCard active"
                                  : "tabCard "
                              }
                              variant={data.variant}
                              showPreviousCompare={data.showPreviousCompare}
                              showSecondRowCompare={data.showSecondRowCompare}
                              cardTitle={data.cardTitle}
                              cardIcon={data.cardIcon}
                              hoverDetails={data.hoverDetails}
                              currentValue={data.currentValue}
                              rowOneTitle={data.rowOneTitle}
                              numberVariant={data.numberVariant}
                              forCountry={data.forCountry}
                            />
                          </SwiperSlide>
                        ))}
                      </Slider>
                    </ErrorBoundary>
                  </Wrapper>
                </div>
              </div>
              {selectedPlatform === "Facebook" && <FacebookCompare topCardData={{ Summary: exportSummary, "Platform Card": PlatformCardData }} />}
              {selectedPlatform === "Instagram" && <InstagramCompare topCardData={{ Summary: exportSummary, "Platform Card": PlatformCardData }} />}
              {selectedPlatform === "Twitter" && <TwitterCompare topCardData={{ Summary: exportSummary, "Platform Card": PlatformCardData }} />}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Compare;
