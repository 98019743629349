import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl';
import './map.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiaWNvZ3oiLCJhIjoiY2w4bXcyMXpqMGdsMTNvdGhiMnQ3ejFkMCJ9.-SsLsOIq-P8laHo8YTzsFQ'

export default class CityMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 95,
            lat: 37,
            zoom: 0.3
        };
        this.mapContainerr = React.createRef();

    }
    componentDidMount() {
        const geojson = {
            'type': 'FeatureCollection',
            'children': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [55.296249, 25.276987]
                    },
                    'properties': {
                        'title': 'Dubai',
                        'subtitle': '25',
                        'description': 'PTAT',
                        'subtitle2': '90%',
                        'description2': 'PTAT Share',
                        'marker-color': '#db7823',
                    }
                },
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [-0.118092, 51.509865]
                    },
                    'properties': {
                        'title': 'London',
                        'subtitle': '35',
                        'description': 'PTAT',
                        'subtitle2': '40%',
                        'description2': 'PTAT Share',
                        'marker-color': '#d51f30',
                    }
                },
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [72.877426, 19.076090]
                    },
                    'properties': {
                        'title': 'Mumbai',
                        'subtitle': '39',
                        'description': 'PTAT',
                        'subtitle2': '90%',
                        'description2': 'PTAT Share',
                        'marker-color': '#038673',
                    }
                },
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [77.216721, 28.644800]
                    },
                    'properties': {
                        'title': 'Delhi',
                        'subtitle': '34%',
                        'description': 'PTAT',
                        'subtitle2': '60%',
                        'description2': 'PTAT Share',
                        'marker-color': '#db7823',
                    }
                },
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [88.363892, 22.572645]
                    },
                    'properties': {
                        'title': 'Kolkata',
                        'subtitle': '23',
                        'description': 'PTAT',
                        'subtitle2': '40%',
                        'description2': 'PTAT Share',
                        'marker-color': '#db7823',
                    },

                },
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [72.8777, 19.0760]
                    },
                    'properties': {
                        'title': 'Mumbai',
                        'subtitle': '25',
                        'description': 'PTAT',
                        'subtitle2': '30%',
                        'description2': 'PTAT Share',
                        'marker-color': '#038673',
                    }
                },


            ]
        };

        const { lng, lat, zoom } = this.state;

        const map2 = new mapboxgl.Map({
            container: 'map2',
            style: 'mapbox://styles/icogz/cldb89h3t002001rtf7qx6wfa',
            center: [lng, lat],
            zoom: this.state.zoom,
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            attributionControl: true,
        });

        // add markers to map
        for (const feature of geojson.features) {

            const marker = new mapboxgl.Marker()
                .setLngLat(feature.geometry.coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(
                    `<div>
            <h3>${feature.properties.title}</h3>
            <p class="mapbox-content-highlight">${feature.properties.subtitle}</p>
            <p>${feature.properties.description}</p>
            <hr/>
            <p class="mapbox-content-highlight">${feature.properties.subtitle2}</p>
            <p>${feature.properties.description2}</p>
            </div>`
                ))
                .addTo(map2);

        }

        map2.addControl(
            new mapboxgl.NavigationControl(), 'top-right');

    }
    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class} />
            </div>
        )
    }
}
