import Wrapper from "../../../helpers/wrapper";
import ExportDropdown from "../../../../../components/ui/dropdown/ExportDropdown";
import './export.scss'

const ChartExport = (props) => {
  const export_href = props.export_href;
  const downloadName = props.downloadName;
  const chartId = props.ChartId;
  const chartData = props.export_data

  return (
    <Wrapper>
      <ExportDropdown
        sectionId={chartId}
        sectionName={downloadName}
        sectionData={chartData}
      />
    </Wrapper>
  );
};

export default ChartExport;
