import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//  STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import Datatable from "../../../../components/ui/tables/Datatable";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

const SocialInstagramAudience = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Instagram"
  let pagePrimaryTab = "Audience"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Followers", field: "followers" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Followers", field: "followers" },
    { name: "Change", field: "percentage_growth" },
  ];

  // AUDIENCE ACTIVITY
  const [audienceActivity, setAudienceActivity] = useState([]);
  const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
    useState([]);
  const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
    useState([]);
  const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
    useState(false);
  const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
    useState(false);
  const [audienceActivityerror, setAudienceActivityError] = useState(null);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchAudienceActivityTrendHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/audience-distribution/",
        config
      );
      setMaleCount(res.data === undefined || res.data.length === 0 ? [] : res.data.male_followers_list === undefined || res.data.male_followers_list.length === 0 ? [] :
        res.data.male_followers_list
      );
      setMaleCountLabels(res.data === undefined || res.data.length === 0 ? [] : res.data.male_dimension === undefined || res.data.male_dimension.length === 0 ? [] :
        res.data.male_dimension
      );
      setTotalMaleCount(
        res.data === undefined || res.data.length === 0 ? [] : res.data.total_male_count === undefined || res.data.total_male_count.length === 0 ? [] :
          res.data.total_male_count
      );

      setFemaleCount(
        res.data === undefined || res.data.length === 0 ? [] : res.data.female_followers_list === undefined || res.data.female_followers_list.length === 0 ? [] :
          res.data.female_followers_list
      );
      setFemaleCountLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.female_dimension === undefined || res.data.female_dimension.length === 0 ? [] :
          res.data.female_dimension
      );
      setTotalFemaleCount(
        res.data === undefined || res.data.length === 0 ? [] : res.data.total_female_count === undefined || res.data.total_female_count.length === 0 ? [] :
          res.data.total_female_count
      );

      setUnknownCount(
        res.data === undefined || res.data.length === 0 ? [] : res.data.unknown_followers_list === undefined || res.data.unknown_followers_list.length === 0 ? [] :
          res.data.unknown_followers_list
      );
      setUnknownCountLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.unkown_dimension === undefined || res.data.unkown_dimension.length === 0 ? [] : res.data.unkown_dimension
      );
      setTotalUnknownCount(
        res.data === undefined || res.data.length === 0 ? [] : res.data.unknown_count === undefined || res.data.unknown_count.length === 0 ? [] :
          res.data.unknown_count
      );

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
  let maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper> <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_male"
            chartClass="section_card_chart"
            chartData={maleCount}
            chartLabels={maleCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary></Wrapper>
      );

    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper><ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let femaleAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );

    }
    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} /></Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper> <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
    femaleAudienceDistributionIocn = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      unknownAudienceDistribution = (
        <Wrapper>  <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_unknown"
            chartClass="section_card_chart"
            chartData={unknownCount}
            chartLabels={unknownCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary></Wrapper>
      );
    }
    if (NoDataAvailableChecker(totalUnknownCountPercentage)) {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }

  }
  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={unknownCounterror} /></Wrapper>
    );
    unknownAudienceDistributionIocn = (
      <Wrapper>  <ServerErrorsLoader
        chartType="genderChartType"
        error={unknownCounterror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend-country/",
        config
      );

      setCountryData(res.data === undefined || res.data.length === 0 ? [] : res.data);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country
              <SectionDefinationsBadge
                title="By Country"
                module="social"
                platform={pagePlatform}
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData)) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country
                  <SectionDefinationsBadge
                    title="By Country"
                    module="social"
                    platform={pagePlatform}
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isHalfSection={true}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tablePageSubModule={pageSubModule}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend-city",
        config
      );

      setCityData(res.data === undefined || res.data.length === 0 ? [] : res.data);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCountryData([]);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City
              <SectionDefinationsBadge
                title="By City"
                module="social"
                platform={pagePlatform}
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (NoDataAvailableChecker(cityData)) {
      cityMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City
                  <SectionDefinationsBadge
                    title="By City"
                    module="social"
                    platform={pagePlatform}
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={"By City"}
              isHalfSection={true}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tablePageSubModule={pageModule}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityIsLoading(true);
    setAudienceActivityIsLoaded(false);
    setAudienceActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend/",
        config
      );

      setAudienceActivity(
        res.data === undefined || res.data.length === 0 ? [] : res.data.social_activity_trend === undefined || res.data.social_activity_trend.length === 0 ? [] :
          res.data.social_activity_trend
      );
      setAudienceActivityXaxisLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.x_label === undefined || res.data.x_label.length === 0 ? [] :
          res.data.x_label
      );
      setAudienceActivityYaxisLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.y_label === undefined || res.data.y_label.length === 0 ? [] :
          res.data.y_label
      );
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(true);
      setAudienceActivityError(null);
    } catch (error) {
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(false);
      setAudienceActivityError(error.code);
      setAudienceActivity([]);
      setAudienceActivityXaxisLabels([]);
      setAudienceActivityYaxisLabels([]);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceActivityTrend = <Wrapper> <Loader loaderType="heatChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
    if (
      NoDataAvailableChecker(audienceActivity) ||
      NoDataAvailableChecker(audienceActivityXaxisLabels) ||
      NoDataAvailableChecker(audienceActivityYaxisLabels)
    ) {
      audienceActivityTrend = (
        <Wrapper><NoDataAvailableLoader chartType="heatChartType" /></Wrapper>
      );
    } else {
      audienceActivityTrend = (
        <Wrapper> <ErrorBoundary chartType="tableChartType">
          <HeatMap
            chartId="audience_activity_trend"
            chartClass="section_card_chart"
            chartData={audienceActivity}
            chartXaxisLabels={audienceActivityXaxisLabels}
            chartYaxisLabels={audienceActivityYaxisLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary></Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityerror) {
    audienceActivityTrend = (
      <Wrapper> <ServerErrorsLoader
        chartType="tableChartType"
        error={audienceActivityerror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityLoading) {
    audienceActivityTrend = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  }

  var maleFemaleDataCSV = {
    "Total Count": [
      {
        Male: totalMaleCount,
        Female: totalFemaleCount,
        Unknown: totalUnknownCount,
      },
    ],
    "AgeBarChartSocial_Audience Distribution Male": maleCount,
    "AgeBarChartSocial_Audience Distribution Female": femaleCount,
    "AgeBarChartSocial_Audience Distribution Unknown": unknownCount,
  };

  var cityCountryData = {
    "SocialIGCity_City": NoDataAvailableChecker(cityData) ? [] : cityData,
    "SocialIGCountry_Country": NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Heatmap_Audience Activity Trend": audienceActivity,
  };
  useEffect(() => {
    setFullPageExport(allData);

  }, [
    totalMaleCount,
    maleCount,
    femaleCount,
    cityData,
    countryData,
    audienceActivity,
  ]);


  useEffect(() => {
    if (
      isMaleCountLoaded &&
      isFemaleCountLoaded &&
      isCityDataLoaded &&
      isMaleCountLoaded &&
      isAudienceActivityLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMaleCountLoaded,
    isFemaleCountLoaded,
    isCityDataLoaded,
    isMaleCountLoaded,
    isAudienceActivityLoaded
  ])

  return (
    <Wrapper>
      <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">Audience Distribution
            </h2> <SectionDefinationsBadge
                title="Audience Distribution Followers"
                module="social"
                platform="instagram"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_3">
          <div className="grid col_span_1 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 card_border_right flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Unknown</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {unknownAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {unknownAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            {/* <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="Audience Distribution Country"
                  module="social"
                  platform="instagram"
                  section="audience"
                />
              </h2>
            </div> */}
            {/* <div className="grid grid_cols_1 section_card_body"> */}
            {countryMap}
            {/* </div> */}
          </div>
          <div className="grid col_span_2 flex column">
            {/* <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="Audience Distribution City"
                  module="social"
                  platform="instagram"
                  section="audience"
                />
              </h2>
            </div> */}
            {/* <div className="grid grid_cols_1 section_card_body"> */}
            {cityMap}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div
        id="audience_activity_trend_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Audience Activity Trend
            </h2>  <SectionDefinationsBadge
                title="Audience Activity Trend"
                module="social"
                platform="instagram"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_activity_trend_section"}
                sectionName={"Heatmap_Audience Activity Trend"}
                sectionData={audienceActivity}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrend}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialInstagramAudience;
