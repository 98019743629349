const CustomerIcon = (props) => {
    return (
        <svg className={props.className} width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.024 28.582V25.57C25.0241 24.7811 24.8688 23.9999 24.567 23.2711C24.2652 22.5422 23.8227 21.8799 23.2649 21.3221C22.707 20.7643 22.0448 20.3218 21.3159 20.02C20.5871 19.7181 19.8059 19.5629 19.017 19.563H7.00699C6.2181 19.5629 5.43692 19.7181 4.70805 20.02C3.97919 20.3218 3.31694 20.7643 2.75911 21.3221C2.20128 21.8799 1.75882 22.5422 1.45699 23.2711C1.15515 23.9999 0.999869 24.7811 1 25.57V28.582" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.0208 7.55095C19.0208 8.73902 18.6685 9.90041 18.0085 10.8883C17.3484 11.8761 16.4103 12.646 15.3126 13.1007C14.215 13.5553 13.0072 13.6743 11.8419 13.4425C10.6767 13.2107 9.60634 12.6386 8.76624 11.7985C7.92615 10.9584 7.35404 9.8881 7.12226 8.72285C6.89048 7.55761 7.00943 6.3498 7.46409 5.25217C7.91875 4.15453 8.68868 3.21637 9.67652 2.55631C10.6644 1.89625 11.8258 1.54395 13.0138 1.54395C13.8027 1.54381 14.5839 1.6991 15.3128 2.00093C16.0416 2.30277 16.7039 2.74523 17.2617 3.30306C17.8195 3.86089 18.262 4.52315 18.5639 5.25201C18.8657 5.98087 19.021 6.76206 19.0208 7.55095Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M34.0358 28.577V25.569C34.0321 24.24 33.5879 22.9497 32.7725 21.9002C31.9571 20.8506 30.8167 20.1011 29.5298 19.769" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.5259 1.73877C24.8167 2.07088 25.9604 2.82277 26.777 3.87611C27.5936 4.92945 28.0368 6.22444 28.0368 7.55727C28.0368 8.8901 27.5936 10.1851 26.777 11.2384C25.9604 12.2918 24.8167 13.0437 23.5259 13.3758" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default CustomerIcon;
