const TextFormatter = ({ string }) => {

    if (string === undefined || string === null || string.length === 0 || string === " ") {
        string = "default"
    }

    // ADD THE WORDS NEED TO REPLACE
    const wordsToReplace = ["na", "tv", "grps", "grp", "acos", "vtr", "cpm", "cpe", "cpc", "ctr", "cpl", "cpi", "cr", "cprp", "hsm share", "ntb units", "consideration t2b", "spont (spontaneous awareness)", "tom (top of mind awareness)", "ott", "dv 360", "youtube"];

    // ADD THE REPLACEMENT OF THE WORDS IN THE SAME SEQUENCE FROM THE WORDS TO REPLACE ARRAY
    const replacementWords = ["N/A", "TV", "GRPs", "GRP", "ACOS", "VTR", "CPM", "CPE", "CPC", "CTR", "CPL", "CPI", "CR", "CPRP", "HSM Share", "NTB Units", "Consideration T2B", "SPONT (Spontaneous Awareness)", "TOM (Top Of Mind Awareness)", "OTT", "DV 360", "YouTube"];
    // Create a regular expression pattern for word boundaries
    const wordBoundaryRegex = new RegExp(`\\b(${wordsToReplace.join('|')})\\b`, 'gi');

    // Replace the words using a custom function
    const replacedString = string.replace(wordBoundaryRegex, (match) => {
        const index = wordsToReplace.indexOf(match.toLowerCase());
        const replacementWord = replacementWords[index];

        if (replacementWord.length > 22) {
            return replacementWord.substring(0, 20) + "...";
        } else {
            return replacementWord;
        }
    });

    return replacedString
}
export default TextFormatter;