const StoreHealthIcon = (props) => {
    return (
        <svg className={props.className} width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.35205 13.6729V29.4999" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M35.9688 13.6729V29.4999" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M5.96523 4.16992L2.53219 10.4385H38.518L35.435 4.16992H5.96523ZM5.07651 2.66992H36.3689L40.9273 11.9385H0.000488281L5.07651 2.66992Z" fill="none" />
            <path d="M38.1689 29.75H3.16895C2.47859 29.75 1.91895 30.3096 1.91895 31V33C1.91895 33.6904 2.47859 34.25 3.16895 34.25H38.1689C38.8593 34.25 39.4189 33.6904 39.4189 33V31C39.4189 30.3096 38.8593 29.75 38.1689 29.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M36.1689 0.75H5.16895C4.47859 0.75 3.91895 1.30964 3.91895 2C3.91895 2.69036 4.47859 3.25 5.16895 3.25H36.1689C36.8593 3.25 37.4189 2.69036 37.4189 2C37.4189 1.30964 36.8593 0.75 36.1689 0.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M13.966 3.14893L9.479 11.2809" stroke="#486F88" strokeWidth="1.5" />
            <path d="M27.3579 3.14893L31.8449 11.2809" stroke="#486F88" strokeWidth="1.5" />
            <path d="M20.6602 3.38818V11.0422" stroke="#486F88" strokeWidth="1.5" />
            <path d="M25.5799 16.9112C25.3005 16.6317 24.9689 16.41 24.6039 16.2588C24.2388 16.1075 23.8476 16.0297 23.4525 16.0297C23.0573 16.0297 22.6661 16.1075 22.3011 16.2588C21.936 16.41 21.6044 16.6317 21.325 16.9112L20.7455 17.4907L20.1656 16.9112C19.8877 16.6254 19.5558 16.3976 19.1891 16.2412C18.8224 16.0848 18.4283 16.0029 18.0297 16.0001C17.6311 15.9973 17.2358 16.0737 16.867 16.225C16.4982 16.3763 16.1631 16.5993 15.8812 16.8812C15.5993 17.1631 15.3763 17.4982 15.225 17.867C15.0737 18.2358 14.9973 18.6311 15.0001 19.0297C15.0029 19.4283 15.0848 19.8224 15.2412 20.1891C15.3976 20.5558 15.6254 20.8877 15.9112 21.1656L16.4907 21.7437L20.7455 26L25 21.7437L25.5795 21.1642C25.859 20.8849 26.0807 20.5532 26.2319 20.1882C26.3832 19.8232 26.461 19.4319 26.461 19.0368C26.461 18.6417 26.3832 18.2504 26.2319 17.8854C26.0807 17.5204 25.859 17.1887 25.5795 16.9094L25.5799 16.9112Z" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default StoreHealthIcon;
