import React from 'react'

export default function TopMoversIcon(props) {
  return (
    <svg className={props.className} width="35" height="20" viewBox="0 0 20 20" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.059 2.00703C15.282 2.00703 14.552 2.00703 13.823 2.00703C13.5578 2.01671 13.2996 1.92063 13.1052 1.73994C12.9108 1.55925 12.7962 1.30874 12.7865 1.04353C12.7768 0.778309 12.8729 0.52011 13.0536 0.325729C13.2343 0.131347 13.4848 0.0167063 13.75 0.00702588C15.35 -0.000307454 16.9473 -0.000307454 18.542 0.00702588C18.7919 0.0128082 19.0301 0.114256 19.2074 0.290453C19.3847 0.46665 19.4876 0.704164 19.495 0.954026C19.5003 2.54869 19.5003 4.14336 19.495 5.73803C19.495 6.00324 19.3896 6.2576 19.2021 6.44513C19.0146 6.63267 18.7602 6.73803 18.495 6.73803C18.2298 6.73803 17.9754 6.63267 17.7879 6.44513C17.6003 6.2576 17.495 6.00324 17.495 5.73803C17.488 5.03803 17.495 4.34503 17.495 3.64903C17.4985 3.58085 17.4822 3.51311 17.448 3.45403C17.397 3.51503 17.348 3.58103 17.295 3.63703C15.295 5.63169 13.2977 7.62603 11.303 9.62003C11.2062 9.75228 11.0797 9.85986 10.9335 9.93401C10.7874 10.0082 10.6259 10.0468 10.462 10.0468C10.2981 10.0468 10.1366 10.0082 9.99044 9.93401C9.84431 9.85986 9.71774 9.75228 9.62099 9.62003C8.93799 8.93603 8.25999 8.26403 7.58999 7.58403C7.47699 7.46903 7.41799 7.46503 7.29999 7.58403C5.50399 9.38403 3.70399 11.184 1.89999 12.984C1.76976 13.1444 1.59548 13.2631 1.39861 13.3257C1.20174 13.3882 0.990878 13.3918 0.791988 13.336C0.634714 13.2904 0.491055 13.2068 0.373634 13.0927C0.256212 12.9785 0.168621 12.8373 0.118556 12.6814C0.0684915 12.5254 0.0574854 12.3596 0.086505 12.1984C0.115525 12.0373 0.183682 11.8857 0.284988 11.757C0.347351 11.6815 0.414135 11.6097 0.484988 11.542L6.61299 5.42103C6.70994 5.29121 6.83594 5.1859 6.98089 5.11352C7.12584 5.04113 7.28572 5.00368 7.44775 5.00417C7.60977 5.00465 7.76942 5.04306 7.91394 5.11631C8.05845 5.18957 8.18382 5.29564 8.27999 5.42603C8.96599 6.11003 9.65099 6.79469 10.335 7.48003C10.413 7.55903 10.459 7.60203 10.564 7.49703C12.38 5.67703 14.201 3.86203 16.059 2.00703Z" fill="#486F88" />
      <path d="M9.78893 16.996H1.08893C0.866894 17.0056 0.648108 16.9401 0.467925 16.81C0.287741 16.6799 0.156685 16.4928 0.0959321 16.279C0.0541007 16.0768 0.0840323 15.8664 0.180579 15.6839C0.277125 15.5014 0.434251 15.3582 0.624932 15.279C0.762361 15.2135 0.912662 15.1793 1.06493 15.179H18.5149C18.6411 15.1685 18.7682 15.1844 18.8879 15.2258C19.0076 15.2671 19.1173 15.333 19.2101 15.4192C19.3029 15.5054 19.3766 15.6101 19.4267 15.7264C19.4767 15.8428 19.5019 15.9683 19.5006 16.0949C19.4994 16.2216 19.4717 16.3465 19.4194 16.4619C19.367 16.5772 19.2912 16.6803 19.1967 16.7647C19.1023 16.849 18.9912 16.9127 18.8707 16.9517C18.7502 16.9907 18.6229 17.0041 18.4969 16.991C15.5956 16.991 12.6956 16.991 9.79693 16.991" fill="#486F88" />
    </svg>
  )
}
