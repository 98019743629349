const IconStore = (props) => {
    return (
        <svg className={props.className} width="40" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.352 22.673V38.5" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M42.969 22.673V38.5" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M29.169 24H17.169C16.6167 24 16.169 24.4477 16.169 25V33C16.169 33.5523 16.6167 34 17.169 34H29.169C29.7213 34 30.169 33.5523 30.169 33V25C30.169 24.4477 29.7213 24 29.169 24Z" />
            <path d="M29.169 24.75H17.169C17.0309 24.75 16.919 24.8619 16.919 25V33C16.919 33.1381 17.0309 33.25 17.169 33.25H29.169C29.3071 33.25 29.419 33.1381 29.419 33V25C29.419 24.8619 29.3071 24.75 29.169 24.75Z" strokeWidth="1.5" />
            <path d="M33.169 24H39.169C39.4342 24 39.6886 24.1054 39.8761 24.2929C40.0636 24.4804 40.169 24.7348 40.169 25V39H32.169V25C32.169 24.7348 32.2744 24.4804 32.4619 24.2929C32.6494 24.1054 32.9038 24 33.169 24V24Z"/>
            <path d="M33.169 24.75H39.169C39.2353 24.75 39.2989 24.7763 39.3458 24.8232C39.3927 24.8701 39.419 24.9337 39.419 25V37.5C39.419 37.6989 39.34 37.8897 39.1993 38.0303C39.0587 38.171 38.8679 38.25 38.669 38.25H33.669C33.4701 38.25 33.2793 38.171 33.1387 38.0303C32.998 37.8897 32.919 37.6989 32.919 37.5V25C32.919 24.9337 32.9453 24.8701 32.9922 24.8232C33.0391 24.7763 33.1027 24.75 33.169 24.75V24.75Z" strokeWidth="1.5" />
            <path d="M12.965 13.17L9.53195 19.4386H45.5178L42.4348 13.17H12.965ZM12.0763 11.67H43.3687L47.9271 20.9386H7.00024L12.0763 11.67Z" />
            <path d="M45.169 38.75H10.169C9.47865 38.75 8.91901 39.3096 8.91901 40V42C8.91901 42.6904 9.47865 43.25 10.169 43.25H45.169C45.8594 43.25 46.419 42.6904 46.419 42V40C46.419 39.3096 45.8594 38.75 45.169 38.75Z" strokeWidth="1.5" />
            <path d="M43.169 9.75H12.169C11.4787 9.75 10.919 10.3096 10.919 11C10.919 11.6904 11.4787 12.25 12.169 12.25H43.169C43.8594 12.25 44.419 11.6904 44.419 11C44.419 10.3096 43.8594 9.75 43.169 9.75Z" strokeWidth="1.5" />
            <path d="M20.966 12.149L16.479 20.281" strokeWidth="1.5" />
            <path d="M34.358 12.149L38.845 20.281" strokeWidth="1.5" />
            <path d="M27.66 12.388V20.042" strokeWidth="1.5" />
        </svg>
    )
}

export default IconStore;