import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";

// PAGES
import CampaignDv360Overall, { CampaignDv360OverallCoke } from "./CampaignDv360Overall";
import CampaignDv360Video, { CampaignDv360VideoCoke } from "./CampaignDv360Video";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID, IDFC_COMPANY_ID, OLX_AUTOS_COMPANY_ID } from "../../../../constants/constants";

const CampaignDv360 = ({ platformData }) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

    // USER DATA
    const userData = useSelector((state) => state.userData.user);
    let companyID = userData?.company?.id;

    let tabData = [];

    if (companyID === IDFC_COMPANY_ID || companyID === OLX_AUTOS_COMPANY_ID) {
        tabData = ["Overall"];
    } else {
        tabData = ["Overall", "Video"];
    }
    let CampaignDvOverall = [];
    if (companyID === COKE_COMPANY_ID) {
        CampaignDvOverall = <CampaignDv360OverallCoke topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
       /*  CampaignDvOverall = <CampaignDv360Overall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
 */    }
    else {
        CampaignDvOverall = <CampaignDv360Overall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    }
    let CampaignDvVideoOverall = [];
    if (companyID === COKE_COMPANY_ID) {
        /* CampaignDvVideoOverall = <CampaignDv360Video topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
        */
        CampaignDvVideoOverall = <CampaignDv360VideoCoke topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    }
    else {
        CampaignDvVideoOverall = <CampaignDv360Video topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    }
    const [selectedTab, setSelectedTab] = useState(tabData[0]);

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Dv360", event)
    };

    useEffect(() => {
        setExportPageName("Campaign DV 360 " + selectedTab)
    }, [selectedTab])

    // let campaignDv360Overall;

    // if (companyID === HALEON_COMPANY_ID) {
    //     campaignDv360Overall = <HaleonCampaignDV360Overall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    // } else {
    //     campaignDv360Overall = <DefaultCampaignDV360Overall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    // }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)}
                />
            </div>
            {selectedTab.toLowerCase() === "overall" && CampaignDvOverall}
            {selectedTab.toLowerCase() === "video" && CampaignDvVideoOverall}
        </Wrapper>
    )
}

export default CampaignDv360;