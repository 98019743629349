import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useEffect, useMemo, useState } from "react";
import ArrowDown from "../../../assets/icons/ArrowDown";
import ArrowUp from "../../../assets/icons/ArrowUp";
import IconDeGrowth from "../../../assets/icons/DegrowthIcon";
import IconGrowth from "../../../assets/icons/GrowthIcon";
import IconNeutral from "../../../assets/icons/NeutralIcon";
import SortDownArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortUpArrowIcon from "../../../assets/icons/SortUpArrowIcon";
import {
    DefaultFlag,
    DefaultFlagIcon,
    DefaultFlagImage,
    DefaultPostImage,
    defaultSocialIconsUrls,
} from "../../../constants/constants";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NumberSeparator from "../../../utils/NumberSeparator";
import TextFormatter from "../../../utils/TextFormatter";

import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import SomethingWentWrong from "../loaders/SomethingWentWrong";
import SectionDefinationsBadge from "../SectionDefinationsBadge";
import CreatableSelect from 'react-select/creatable';
import { useSelector } from "react-redux";
import axios from "../../helpers/axios";
import { useContext } from "react";
import AuthContext from "../../../store/authContext";
import { el, id } from "date-fns/locale";
import moment from "moment";
import { sumTotals } from "../../../utils/Utils";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";


const PaginatedDatatable = ({
    tableTitle,
    tableHeader,
    tableData,
    tableLength,
    tableDropdown,
    searchPlaceHolder,
    searchSingleColumnNumberRow,
    searchSingleRowPlaceholder,
    searchSingleColumnNumber,
    hrefTitle,
    tableExportDropdown,
    tableID,
    tablePlatform,
    isCountryFlagVisible,
    isDynamicFlagVisible,
    checkbox,
    onCheckboxClickHandler, // ADMIN CAMPAIGN CREATE BUCKET
    clearSelectedItems, // ADMIN CAMPAIGN CREATE BUCKET
    tablePageSubModule,
    tablePagePlatform,
    tablePagePrimaryTab,
    isHalfSection,
    hideBoxShadow,
    showGrowthDegrowth,
    showTotal,
    isCreatableDropdown,
    tableConfigs,
    setTableConfigs,
    tablePaginationData,
    showTotalRow,
    showFixedSpend,
    removeImgExtensions
}) => {
    if (isCountryFlagVisible === undefined) {
        isCountryFlagVisible = false;
    } else {
        isCountryFlagVisible = isCountryFlagVisible;
    }

    if (removeImgExtensions === undefined) {
        removeImgExtensions = false
    }

    // PERCENTAGE METRICS ARRAY
    const stringHeaderTitles = [
        "Hashtag",
        "Channel Name",
        "Landing Page",
        "Source",
        "Category",
        "Categories",
        "Event Action",
        "Product Name",
        "City Name",
        "Source Type",
        "Name",
        "Platform",
        "Objective",
        "Campaign Name",
        "Zone",
        "State",
        "Creative Name",
        "Full Name",
        "Profile",
        "Age",
        "Gender",
        "Device",
        "Link",
        "Country",
        "City",
        "Campaign Bucket",
        "Start Date",
        "End Date",
        "Brands",
        "Line Item Name",
        "Markets",
        "Brand Name",
        "Region",
        "Medium",
        "Publishing Medium",
        "Ad Name",
        "Geography",
        "Week/Month",
        "VG Group",
        "Date",
        'Keyword',
        "Ad Group Name",
        'Ad ID',
        'Search Term',
        'Landing Page',
        'Campaign Group Name',
        'Ad group Name',
        'Keyword Name',
        'Post ID',
        'Creative ID',
        'Creative',
        'Line Item',
        'Bucket Name',
        'Ad Set Name',
    ];


    const IDFields = ['id']
    const [formattedTableHeaders, setFormattedTableHeaders] = useState([])

    useEffect(() => {

        var hasIDs = tableHeader.some((x) => IDFields.includes(x.field));

        if (hasIDs) {

            const dataArray = tableHeader;
            const indexOfId = dataArray.findIndex(item => IDFields.includes(item.field));

            if (indexOfId !== -1) {
                const idObject = dataArray.splice(indexOfId, 1)[0];
                dataArray.push(idObject);
            }

            setFormattedTableHeaders(dataArray)
        } else {
            setFormattedTableHeaders(tableHeader)
        }

    }, [tableHeader])


    const authCtx = useContext(AuthContext);

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date);

    const [fixedBottomRow, setFixedBottomRow] = useState([])

    // PERCENTAGE METRICS ARRAY
    const capsDataList = ["Tv"];

    const growthIconMetrics = [
        "Sales Vol. Change",
        "Sales Val. Change",
        "Change",
        // "Ad Name"
    ];


    const HeaderTitleAlignmentSetter = (headerTitle) => {
        if (stringHeaderTitles.includes(headerTitle)) {
            return true;
        } else {
            return false;
        }
    };

    let dataLength = 0;

    if (tableData.length === 0 || typeof tableData !== "array") {
        dataLength = 0;
    } else {
        dataLength = tableData.length;
    }

    let tempHref;

    if (hrefTitle === undefined) {
        tempHref = "Visit Link";
    } else {
        tempHref = hrefTitle;
    }

    const DefaultSortFieldHandler = () => {
        if (
            tableData.length !== 0 ||
            tableData === undefined
        ) {
            for (
                let tableDataIndex = 0;
                tableDataIndex < tableData.length;
                tableDataIndex++
            ) {
                for (
                    let dataObjectIndex = 0;
                    dataObjectIndex < Object.keys(tableData[tableDataIndex]).length;
                    dataObjectIndex++
                ) {
                    if (
                        typeof Object.values(tableData[tableDataIndex])[dataObjectIndex] ===
                        "number" &&
                        Object.values(tableData[tableDataIndex])[dataObjectIndex] !== 0
                    ) {
                        return Object.keys(tableData[tableDataIndex])[dataObjectIndex];
                    }
                }
            }
        } else {
            return "";
        }
    };

    const [totalItems, setTotalItems] = useState(tablePaginationData?.total_records ?? dataLength);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);

    const [searchValue, setSearchValue] = useState(null)
    const [searchTerm, setSeachTerm] = useState(null);
    const [searchSingleColumnData, setSearchSingleColumnData] = useState("");

    const [sortingField, setSortingField] = useState(tableConfigs?.order_by ?? DefaultSortFieldHandler);

    const [sortingOrder, setSortingOrder] = useState(tableConfigs?.order_type ?? "asc");

    const handleTableConfigs = (data) => {
        if (tableConfigs !== undefined && setTableConfigs !== undefined) {
            if (tableConfigs.page_num !== data) {
                setTableConfigs({ ...tableConfigs, page_num: data })
            }
        }
    }

    useEffect(() => {

        if (tableConfigs !== undefined && setTableConfigs !== undefined) {
            if (searchTerm !== null) {
                const delayDebounceFn = setTimeout(() => {
                    // setTableConfigs({ ...tableConfigs, search_value: searchTerm, page_num: 1 })
                }, 1500)
                return () => clearTimeout(delayDebounceFn)
            }
        }

    }, [searchTerm])


    const SearchHandler = () => {
        if (tableConfigs !== undefined && setTableConfigs !== undefined) {
            if (searchTerm !== null) {
                setTableConfigs({ ...tableConfigs, search_value: searchTerm, page_num: 1 })
            }
        }
    }


    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const onSortingChange = (field) => {
        let order = "";
        if (field === sortingField && sortingOrder === "asc") {
            order = "desc";
        } else {
            order = "asc";
        }

        if (tableConfigs !== undefined && setTableConfigs !== undefined) {
            setTableConfigs({ ...tableConfigs, order_by: field, order_type: order })
        }
        setSortingField(field);
        setSortingOrder(order);
    };

    function tableRowFilter(data, searchTerm) {
        let sampleArr = Object.keys(data);
        for (let k = 0; k < sampleArr.length; k++) {
            if (
                data[Object.keys(data)[k]]
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ) {
                return true;
            }
        }
    }

    function tableRowFilter2(data, searchSingleColumnData) {
        // let sampleArr = Object.keys(data);
        if (
            // data[Object.keys(data)[k]] === "Platform" &&
            data[Object.keys(data)[searchSingleColumnNumber]] //searchSingleColumnNumber is column number from the api
                .toString()
                .toLowerCase()
                .includes(searchSingleColumnData.toLowerCase())
        ) {
            return true;
        }
    }

    const filteredTableData = useMemo(() => {
        let unfilreredTableData = tableData;
        if (searchTerm) {
            unfilreredTableData = unfilreredTableData.filter((data) =>
                tableRowFilter(data, searchTerm)
            );
        }
        if (searchSingleColumnData) {
            unfilreredTableData = unfilreredTableData.filter((data) =>
                tableRowFilter2(data, searchSingleColumnData)
            );
        }

        //SORTING


        if (sortingField && tableConfigs === undefined) {
            const reversed = sortingOrder === 'asc' ? 1 : -1;
            unfilreredTableData = unfilreredTableData.sort((a, b) => {
                if (isNumber(a[sortingField]) && isNumber(b[sortingField])) {
                    if (sortingOrder === 'asc') {
                        return b[sortingField] - a[sortingField];
                    } else {
                        return a[sortingField] - b[sortingField];
                    }
                } else {
                    return a[sortingField] === undefined || b[sortingField] === undefined
                        ? reversed * 0
                        : reversed * a[sortingField].localeCompare(b[sortingField]);
                }
            });
        }

        if (tableConfigs === undefined) {
            setTotalItems(unfilreredTableData.length);
        }
        return unfilreredTableData.slice(
            (currentPage - 1) * tableLength,
            (currentPage - 1) * tableLength + tableLength
        );


    }, [
        tableHeader,
        tableData,
        currentPage,
        searchTerm,
        sortingField,
        sortingOrder,
        searchSingleColumnNumberRow,
        searchSingleColumnData,
        searchSingleColumnNumber,
    ]);

    useEffect(() => {
        if (tableConfigs === undefined) {
            if (totalItems > 0 && tableLength > 0) {
                setTotalPages(Math.ceil(totalItems / tableLength));
            }
        }
    }, [totalItems, tableLength]);

    useEffect(() => {
        if (tablePaginationData !== undefined) {
            setTotalPages(tablePaginationData.total_pages)
            setCurrentPage(tablePaginationData.page_number)
        }

    }, [tablePaginationData])



    useEffect(() => {
        if (tableConfigs === undefined) {
            checkedItems.length === 0 && setCurrentPage(1);
        }

        if (!NoDataAvailableChecker(tableData)) {
            var tempValues = sumTotals(tableData)
            // setFixedBottomRow([tempValues])
        }

    }, [tableData, tableHeader]);


    // CREATABLE DROPDOWN

    const [isCreatableDropdownLoading, setIsCreatableDropdownLoading] = useState(false);
    const [creatableDropdownValue, setCreatableDropdownValue] = useState(null);
    const [creatableDropdownOptions, setCreatableDropdownOptions] = useState([]);
    const [creatableDropdownNewOption, setCreatableDropdownNewOption] = useState([]);
    const [creatableDropdownNewOptionName, setCreatableDropdownNewOptionName] = useState(null);
    const [creatableDropdownAllData, setCreatableDropdownAllData] = useState([])

    useEffect(() => {
        const fetchDropdownOptionsHandler = async () => {
            setIsCreatableDropdownLoading(true);

            const config = {
                params: {
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {
                const res = await axios.get("performance/google/campaign-tag/", config);
                setCreatableDropdownOptions(
                    res.data === undefined || res.data.length === 0 ? [] :
                        res.data.map((el) => ({ label: el.name, value: el.name.toLowerCase().replace(/\W/g, ''), isFixed: true, isDisabled: false }))
                );
                setCreatableDropdownAllData(
                    res.data === undefined || res.data.length === 0 ? [] : res.data
                )

                setIsCreatableDropdownLoading(false);
            } catch (error) {
                setCreatableDropdownOptions([]);
                setCreatableDropdownAllData([])
                setIsCreatableDropdownLoading(false);
            }
        };

        fetchDropdownOptionsHandler()
    }, [authCtx, selectedDates])

    const handleCreate = (inputValue, el) => {
        var tempArr = el.bucket.split(',')[0] === '' ? [] : el.bucket.split(',').map((i) => ({ label: i, value: i.toLowerCase().replace(/\W/g, ''), isFixed: true }))



        setIsCreatableDropdownLoading(true);
        setTimeout(() => {
            const newOption = createableDropdownOption(inputValue);
            setIsCreatableDropdownLoading(false);
            setCreatableDropdownOptions((prev) => [...prev, newOption]);
            setCreatableDropdownNewOptionName(inputValue)
            // setCreatableDropdownValue(newOption);
            setCreatableDropdownValue([...tempArr, { label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, ''), isFixed: true }])
        }, 1000);
    };

    const createableDropdownOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
        isFixed: true
    });

    const styles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: "none" } : base;
        }
    };

    useEffect(() => {

        const CreateBucketHandler = async () => {
            const data = {
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                name: creatableDropdownNewOptionName,
                items: creatableDropdownNewOption

            }
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            }


            try {
                await axios.post("performance/google/campaign-tag/", data, { headers })

                window.location.reload();

            } catch (error) {


            }
        };
        (creatableDropdownNewOption.length !== 0 && creatableDropdownNewOptionName !== null) && CreateBucketHandler()
    }, [creatableDropdownNewOption, creatableDropdownNewOptionName])


    const [editCampaignBucket, setEditCampaignBucket] = useState([])
    const [editCampaignBucketSingleElement, setEditCampaignBucketSingleElement] = useState([])

    const [editCampaignBucketIdName, setEditCampaignBucketIdName] = useState(null)


    // TO GENERATE CATEGORY TAGS
    const generateTags = (data, el) => {
        let temp = [];
        var str_array = data === null ? null : data.split(",");
        if (str_array !== null) {
            for (var i = 0; i < str_array.length; i++) {
                str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
                temp.push(str_array[i]);
            }
        }


        const HandleChange = (newValue) => {



            const unique = tableData.filter(
                (obj, index) =>
                    tableData.findIndex((item) => (item.name === el.name && item.start_date === el.start_date && item.end_date === el.end_date)) === index
            );


            var newUnique = unique.map(({ bucket, ...rest }) => {
                var temp = bucket.split(',');
                temp.push(newValue[newValue.length - 1].label);
                temp.join(",")


                return ({
                    bucket: temp.join(","), ...rest
                })

            })


            tableData.push(...newUnique)

            setEditCampaignBucketSingleElement(el)

            var editBucket = newValue[newValue.length - 1]
            var filteredItem = creatableDropdownAllData.filter((item) => item.name === editBucket.label ? item : null)


            var updatedDate = el;
            updatedDate.start_date = el.start_date === null || el.start_date === "" ? "" : moment(el.start_date).format("DD MMM yyyy");
            updatedDate.end_date = el.end_date === null || el.end_date === "" ? "" : moment(el.end_date).format("DD MMM yyyy");


            var existingItems = filteredItem.map((x) => {
                var fooBar = x.items;

                var newMappedArr = fooBar.map(({ start_date, end_date, ...rest }) => ({
                    start_date: start_date === null || start_date === "" ? "" : moment(start_date).format("DD MMM yyyy"),
                    end_date: end_date === null || end_date === "" ? "" : moment(end_date).format("DD MMM yyyy"),
                    ...rest
                }))

                return [...newMappedArr, updatedDate]

            })

            setEditCampaignBucket(existingItems);


            setEditCampaignBucketIdName(filteredItem.map((x) => ({ id: x.id, name: x.name })))




            var tempArr = el.bucket.split(',').map((i) => i);

            if (newValue.filter((item) => tempArr.includes(item.label))) {
                setCreatableDropdownValue(newValue)
            } else {
                setCreatableDropdownValue([...tempArr, ...newValue])
            }
        }

        if (isCreatableDropdown) {
            var creatableDropdownOptionsCheck = creatableDropdownOptions === undefined || creatableDropdownOptions === null || creatableDropdownOptions.length === 0 ? [] : creatableDropdownOptions
            var vals = el.bucket.split(',').length === 0 || el.bucket.split(',')[0] === '' ? creatableDropdownOptionsCheck : el.bucket.split(',')
            var filterDisabledOptions = creatableDropdownOptions === undefined || creatableDropdownOptions === null || creatableDropdownOptions.length === 0 ? [] : creatableDropdownOptions.map((item) => {


                if (vals.includes(item.label)) {
                    return {
                        ...item,
                        isDisabled: true
                    }

                } else {
                    return item
                }
            })
        }



        let metricCardsSection = (
            <Wrapper>
                <ErrorBoundary>
                    {temp.map((data, index) => (
                        <div key={index} className="badge_wrapper">
                            <button type="button" className="button_badge">
                                {data}
                            </button>
                        </div>
                    ))}

                    {isCreatableDropdown && el && <div id={el.platform_campaign_name + el.platform + el.start_date + el.end_date} style={{ display: 'none' }} className="camp_admin_dd">
                        <CreatableSelect
                            isClearable={false}
                            isMulti
                            options={filterDisabledOptions}
                            isDisabled={isCreatableDropdownLoading}
                            isLoading={isCreatableDropdownLoading}
                            onChange={(newValue) => HandleChange(newValue, el)}
                            onCreateOption={(item) => handleCreate(item, el)}
                            // value={creatableDropdownValue === null ? el.bucket.split(',').length === 0 || el.bucket.split(',')[0] === '' ? null : (el.bucket.split(',').map((i) => ({ label: i, value: i.toLowerCase().replace(/\W/g, ''), isFixed: true , }))) : creatableDropdownValue}
                            formatCreateLabel={(el) => 'Create New Bucket "' + el + '"'}
                            value={creatableDropdownValue}
                            styles={styles}
                        />
                    </div>}


                    {el && <button className="button_badge" id={el.platform_campaign_name + el.platform + el.start_date + el.end_date + "_btn"} onClick={(e) => showRow(e, el)}>+</button>}

                </ErrorBoundary>
            </Wrapper>
        );

        return metricCardsSection;
    };



    useEffect(() => {
        const EditBucketHandler = async () => {
            setIsCreatableDropdownLoading(true)
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            }

            var bucketName = editCampaignBucketIdName.map(x => x.name);
            var bucketId = editCampaignBucketIdName.map(x => x.id)


            const data = {
                name: bucketName[0],
                items: editCampaignBucket[0],
            };

            try {
                await axios.patch(
                    "performance/google/campaign-tag/" + bucketId[0], data, { headers })


                window.location.reload();
                // setIsCreatableDropdownLoading(false)

            } catch (error) {
                setIsCreatableDropdownLoading(false)

            }
        };

        (editCampaignBucketSingleElement !== null && editCampaignBucket.length !== 0) && EditBucketHandler();
    }, [editCampaignBucket, editCampaignBucketSingleElement])

    const showRow = (e, el) => {

        setCreatableDropdownNewOption([el])
        setCreatableDropdownValue(null)
        tableData.map((item) => {
            var foo = document.getElementById(item.platform_campaign_name + item.platform + item.start_date + item.end_date);
            var btn = document.getElementById(item.platform_campaign_name + item.platform + item.start_date + item.end_date + "_btn");

            if (foo === null || foo === undefined) {

            } else {
                foo.style.display = "none"
                btn.style.display = 'block'
            }

        })

        document.getElementById(el.platform_campaign_name + el.platform + el.start_date + el.end_date).style.display = "block";
        document.getElementById(el.platform_campaign_name + el.platform + el.start_date + el.end_date + "_btn").style.display = "none"
    }

    // SELECTED CAMPAIGN IN BUCKET
    const [checkedItems, setCheckedItems] = useState([]);

    const onCheckboxClick = (tableRow) => {
        if (checkedItems.some((a) => a === tableRow)) {
            checkedItems.splice(
                checkedItems.findIndex((b) => b === tableRow),
                1
            );
            setCheckedItems([...checkedItems]);
        } else {
            checkedItems.push(tableRow);
            setCheckedItems([...checkedItems]);
        }
        onCheckboxClickHandler(tableRow);
    };

    const flagIconUrls = DefaultFlagImage;
    function handleImage(data) {
        var temp;

        for (let i = 0; i < flagIconUrls.length; i++) {
            if (data === flagIconUrls[i].title) {
                temp = flagIconUrls[i].iconUrl;
                break;
            } else {
                temp = DefaultFlagIcon;
            }
        }

        return temp;
    }

    // Calculate the total of number type columns
    function calculateTotalOfNumberColumns(data) {
        // Object to store the totals of number columns
        const numberColumnsTotal = {};

        // Initialize all columns with "" (empty)
        data.forEach((row) => {
            for (const key in row) {
                numberColumnsTotal[key] = "";
            }
        });

        // Iterate through each row of the data
        data.forEach((row) => {
            // Iterate through each column of the row
            for (const key in row) {
                // Check if the value is a number
                if (typeof row[key] === "number") {
                    // If the column is "" (empty), initialize it to the current value
                    if (numberColumnsTotal[key] === "") {
                        numberColumnsTotal[key] = row[key];
                    }
                    // If the column has been encountered before, add the current value to the total
                    else {
                        numberColumnsTotal[key] += row[key];
                    }
                }
            }
        });

        return numberColumnsTotal;
    }

    // Calculate the total of number type columns
    const totalNumberColumns = calculateTotalOfNumberColumns(filteredTableData);
    var checkTable = tableConfigs === undefined ? filteredTableData : tableData;

    let DataTable;

    try {
        DataTable = (
            <Wrapper>
                <table id={tableID} className="table table_striped table_bordered">
                    <thead>
                        <tr>
                            {formattedTableHeaders.map((header) => (
                                <th
                                    key={header.name}
                                    onClick={() => (
                                        header.sortable === undefined &&
                                        onSortingChange(header.field),
                                        setCurrentPage(1)
                                    )}
                                >
                                    <div
                                        className={
                                            HeaderTitleAlignmentSetter(header.name) === true
                                                ? "th_wrapper"
                                                : "th_wrapper flex justify_end"
                                        }
                                    >
                                        <div className="th_title">{header.name}</div>
                                        {(header.sortable === undefined && tableConfigs === undefined) ? (
                                            <div className="icon_wrapper">
                                                <ArrowUp
                                                    className={
                                                        sortingField &&
                                                            sortingField === header.field &&
                                                            sortingOrder === "asc"
                                                            ? "icon active"
                                                            : "icon"
                                                    }
                                                />
                                                <ArrowDown
                                                    className={
                                                        sortingField &&
                                                            sortingField === header.field &&
                                                            sortingOrder === "desc"
                                                            ? "icon active"
                                                            : "icon"
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div className="icon_wrapper">
                                                <ArrowUp
                                                    className={
                                                        sortingField &&
                                                            sortingField === header.field &&
                                                            sortingOrder === "desc"
                                                            ? "icon active"
                                                            : "icon"
                                                    }
                                                />
                                                <ArrowDown
                                                    className={
                                                        sortingField &&
                                                            sortingField === header.field &&
                                                            sortingOrder === "asc"
                                                            ? "icon active"
                                                            : "icon"
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {checkTable.map((tableRow, tableRowIndex) =>
                            checkbox === true ? (
                                // TO ADD CHECKBOX IN THE FIRST COLUMN OF THE ROW

                                <tr
                                    onClick={() => onCheckboxClick(tableRow)}
                                    className={
                                        checkedItems.some((a) => a === tableRow) === true
                                            ? "active"
                                            : "noactive"
                                    }
                                    key={tableRowIndex}
                                >
                                    {formattedTableHeaders.map((tableTD, tableTDIndex) =>
                                        tableRow[tableTD.field] === undefined ? (
                                            <td key={tableTDIndex}>0</td>
                                        ) : tableRow[tableTD.field] === "" ||
                                            tableRow[tableTD.field] === "NA" ||
                                            tableRow[tableTD.field] === null ||
                                            tableRow[tableTD.field] === " " ? (
                                            <td
                                                className="no_data_align"
                                                data-label={tableTD.name}
                                                key={tableTDIndex}
                                            >
                                                N/A
                                            </td>
                                        ) : tableTDIndex === 0 ? (
                                            <td
                                                key={tableTDIndex}
                                                data-label={tableTD.name}
                                                className={
                                                    isNumber(tableRow[tableTD.field]) === true
                                                        ? "td_checkbox number_align"
                                                        : "td_checkbox text_align"
                                                }
                                            >
                                                {/* <input
                          className="input_checkbox"
                          type="checkbox"
                          id={tableRow.platform_campaign_id}
                          name={tableRow.platform_campaign_id}
                          value={tableRow}
                          onChange={() => onCheckboxClick(tableRow)}
                          defaultChecked={checkedItems.some((a) => a === tableRow) === true ? true : false}
                        /> */}
                                                {isNumber(tableRow[tableTD.field]) === true ? (
                                                    tableTD.name === "Viral Grade" ? (
                                                        <ErrorBoundary>
                                                            <div className="cell_badge_wrapper">
                                                                <NumberSeparator
                                                                    fixedSpend={showFixedSpend}
                                                                    platform={tablePlatform}
                                                                    title={tableTD.name}
                                                                    number={tableRow[tableTD.field]}
                                                                />
                                                                {tableRow[tableTD.field] > 0 && (
                                                                    <div className="badge_wrapper">
                                                                        {tableRow[tableTD.field] < 1000 ? (
                                                                            <div className="badge positive">A</div>
                                                                        ) : (
                                                                            <div className="badge negative">B</div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </ErrorBoundary>
                                                    ) : showGrowthDegrowth ? (
                                                        <ErrorBoundary>
                                                            <div className="cell_badge_wrapper hello_469">
                                                                <NumberSeparator
                                                                    fixedSpend={showFixedSpend}
                                                                    platform={tablePlatform}
                                                                    title={tableTD.name}
                                                                    number={tableRow[tableTD.field]}
                                                                />
                                                                {tableRow[tableTD.field] !== 0 ? (
                                                                    <div className="badge_wrapper">
                                                                        {tableRow[tableTD.field] > 0 ? (
                                                                            <div className="icon">
                                                                                <IconGrowth />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="icon">
                                                                                <IconDeGrowth />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="badge_wrapper">
                                                                        {
                                                                            <div className="icon">
                                                                                <IconNeutral />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </ErrorBoundary>
                                                    ) : // TO SHOW GROWTH ICONS
                                                        growthIconMetrics.includes(tableTD.name) ? (
                                                            <ErrorBoundary>
                                                                <div className="cell_badge_wrapper">
                                                                    <NumberSeparator
                                                                        fixedSpend={showFixedSpend}
                                                                        platform={tablePlatform}
                                                                        title={tableTD.name}
                                                                        number={tableRow[tableTD.field]}
                                                                    />
                                                                    {tableRow[tableTD.field] !== 0 ? (
                                                                        <div className="badge_wrapper">
                                                                            {tableRow[tableTD.field] > 0 ? (
                                                                                <div className="icon">
                                                                                    <IconGrowth />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="icon">
                                                                                    <IconDeGrowth />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="badge_wrapper">
                                                                            {
                                                                                <div className="icon">
                                                                                    <IconNeutral />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : (
                                                            <ErrorBoundary>
                                                                <NumberSeparator
                                                                    fixedSpend={showFixedSpend}
                                                                    platform={tablePlatform}
                                                                    title={tableTD.name}
                                                                    number={tableRow[tableTD.field]}
                                                                />
                                                            </ErrorBoundary>
                                                        )
                                                ) : // TO MAKE URL LINK CLICKABLE
                                                    tableRow[tableTD.field].match(
                                                        /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                                                    ) !== null &&
                                                        tableRow[tableTD.field].match(
                                                            /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                        ) === null &&
                                                        tableRow[tableTD.field].includes("ggpht") ===
                                                        false ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    '<a target="_blank" href=' +
                                                                    tableRow[tableTD.field] +
                                                                    ">" +
                                                                    tempHref +
                                                                    "</a>",
                                                            }}
                                                        />
                                                    ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                                                        tableTD.name !== "Campaign Bucket" &&
                                                            tableRow[tableTD.field].length > 50 &&
                                                            tableRow[tableTD.field].match(
                                                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                            ) == null &&
                                                            tableRow[tableTD.field].includes("ggpht") ===
                                                            false ? (
                                                            <ErrorBoundary>
                                                                <div title={tableRow[tableTD.field]}>
                                                                    <TextFormatter
                                                                        string={
                                                                            tableRow[tableTD.field].substring(0, 40) +
                                                                            "..."
                                                                        }
                                                                    />
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : // TO SHOW IMAGE IN A COLUMN
                                                            tableRow[tableTD.field].match(
                                                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                            ) != null ||
                                                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            '<img style="width:50px" src=' +
                                                                            tableRow[tableTD.field] +
                                                                            ">",
                                                                    }}
                                                                />
                                                            ) : tableTD.name === "Categories" ||
                                                                tableTD.name === "Campaign Bucket" ? (
                                                                <div className="badges_list_wrapper">
                                                                    {generateTags(tableRow[tableTD.field])}
                                                                </div>
                                                            ) : defaultSocialIconsUrls.find((element) => {
                                                                if (
                                                                    element.title.toLowerCase() ===
                                                                    tableTD.name.toLowerCase()
                                                                ) {
                                                                    return true;
                                                                }
                                                            }) ? (
                                                                <div className="badges_list_wrapper">
                                                                    {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                                                </div>
                                                            ) : // TO SHOW COUNTRY FLAG
                                                                isCountryFlagVisible === true ? (
                                                                    <Wrapper>
                                                                        <div className="country_flag_section">
                                                                            <div className="country_flag_wrapper">
                                                                                {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                                                                <img
                                                                                    className="flag_img2"
                                                                                    src={handleImage(tableRow[tableTD.field])}
                                                                                />
                                                                            </div>
                                                                            <ErrorBoundary>
                                                                                <TextFormatter
                                                                                    string={tableRow[tableTD.field]}
                                                                                />
                                                                            </ErrorBoundary>
                                                                        </div>
                                                                    </Wrapper>
                                                                ) : // TO SHOW CITY FLAG
                                                                    isDynamicFlagVisible === true ? (
                                                                        <Wrapper>
                                                                            <div className="country_flag_section">
                                                                                <div className="country_flag_wrapper">
                                                                                    <img
                                                                                        className="flag_img"
                                                                                        src={DefaultFlagIcon}
                                                                                    />
                                                                                    <span className="cityabb">
                                                                                        <TextFormatter
                                                                                            string={
                                                                                                tableRow[tableTD.field].match(
                                                                                                    /[^\u0000-\u007F]+/
                                                                                                )
                                                                                                    ? tableRow[tableTD.field]
                                                                                                        .match(/[^\u0000-\u007F]+/)
                                                                                                        .join("")
                                                                                                        .substring(0, 2)
                                                                                                    : tableRow[tableTD.field].indexOf(
                                                                                                        " "
                                                                                                    ) >= 0 &&
                                                                                                        tableRow[tableTD.field].indexOf(",") >
                                                                                                        0
                                                                                                        ? tableRow[tableTD.field].substring(
                                                                                                            0,
                                                                                                            2
                                                                                                        )
                                                                                                        : tableRow[tableTD.field].indexOf(
                                                                                                            " "
                                                                                                        ) >= 0 &&
                                                                                                            tableRow[tableTD.field].indexOf(",") <
                                                                                                            0
                                                                                                            ? tableRow[tableTD.field]
                                                                                                                .match(/\b\w/g)
                                                                                                                .join("")
                                                                                                                .substring(0, 2) ||
                                                                                                            tableRow[tableTD.field]
                                                                                                                .match(/[^\u0000-\u007F]+/)
                                                                                                                .join("")
                                                                                                                .substring(0, 2)
                                                                                                            : tableRow[tableTD.field].substring(
                                                                                                                0,
                                                                                                                2
                                                                                                            )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <ErrorBoundary>
                                                                                    <TextFormatter
                                                                                        string={tableRow[tableTD.field]}
                                                                                    />
                                                                                </ErrorBoundary>
                                                                            </div>
                                                                        </Wrapper>
                                                                    ) : (
                                                                        <ErrorBoundary>
                                                                            <TextFormatter string={tableRow[tableTD.field]} />
                                                                        </ErrorBoundary>
                                                                    )}
                                            </td>
                                        ) : (
                                            <td
                                                key={tableTDIndex}
                                                data-label={tableTD.name}
                                                title={tableRow[tableTD.field]}
                                                className={
                                                    isNumber(tableRow[tableTD.field]) === true
                                                        ? "number_align"
                                                        : "text_align"
                                                }
                                            >
                                                {isNumber(tableRow[tableTD.field]) === true ? (
                                                    tableTD.name === "Viral Grade" ? (
                                                        <ErrorBoundary>
                                                            <div className="cell_badge_wrapper">
                                                                <NumberSeparator
                                                                    fixedSpend={showFixedSpend}
                                                                    platform={tablePlatform}
                                                                    title={tableTD.name}
                                                                    number={tableRow[tableTD.field]}
                                                                />
                                                                {tableRow[tableTD.field] > 0 && (
                                                                    <div className="badge_wrapper">
                                                                        {tableRow[tableTD.field] < 1000 ? (
                                                                            <div className="badge positive">A</div>
                                                                        ) : (
                                                                            <div className="badge negative">B</div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </ErrorBoundary>
                                                    ) : // TO SHOW GROWTH ICONS
                                                        growthIconMetrics.includes(tableTD.name) ? (
                                                            <ErrorBoundary>
                                                                <div className="cell_badge_wrapper">
                                                                    <NumberSeparator
                                                                        fixedSpend={showFixedSpend}
                                                                        platform={tablePlatform}
                                                                        title={tableTD.name}
                                                                        number={tableRow[tableTD.field]}
                                                                    />
                                                                    {tableRow[tableTD.field] !== 0 ? (
                                                                        <div className="badge_wrapper">
                                                                            {tableRow[tableTD.field] > 0 ? (
                                                                                <div className="icon">
                                                                                    <IconGrowth />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="icon">
                                                                                    <IconDeGrowth />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="badge_wrapper">
                                                                            {
                                                                                <div className="icon">
                                                                                    <IconNeutral />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : (
                                                            <ErrorBoundary>
                                                                <NumberSeparator
                                                                    fixedSpend={showFixedSpend}
                                                                    platform={tablePlatform}
                                                                    title={tableTD.name}
                                                                    number={tableRow[tableTD.field]}
                                                                />
                                                            </ErrorBoundary>
                                                        )
                                                ) : // TO MAKE URL LINK CLICKABLE
                                                    tableRow[tableTD.field].match(
                                                        /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                                                    ) !== null &&
                                                        tableRow[tableTD.field].match(
                                                            /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                        ) === null &&
                                                        tableRow[tableTD.field].includes("ggpht") ===
                                                        false ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    '<a target="_blank" href=' +
                                                                    tableRow[tableTD.field] +
                                                                    ">" +
                                                                    tempHref +
                                                                    "</a>",
                                                            }}
                                                        />
                                                    ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                                                        tableTD.name !== "Campaign Bucket" &&
                                                            tableRow[tableTD.field].length > 50 &&
                                                            tableRow[tableTD.field].match(
                                                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                            ) == null &&
                                                            tableRow[tableTD.field].includes("ggpht") ===
                                                            false ? (
                                                            <ErrorBoundary>
                                                                <div title={tableRow[tableTD.field]}>
                                                                    <TextFormatter
                                                                        string={
                                                                            tableRow[tableTD.field].substring(0, 50) +
                                                                            "..."
                                                                        }
                                                                    />
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : // TO SHOW IMAGE IN A COLUMN
                                                            tableRow[tableTD.field].match(
                                                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                            ) != null ||
                                                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            '<img style="width:50px" src=' +
                                                                            tableRow[tableTD.field] +
                                                                            ">",
                                                                    }}
                                                                />
                                                            ) : tableTD.name === "Categories" ||
                                                                tableTD.name === "Campaign Bucket" ? (
                                                                <div className="badges_list_wrapper">
                                                                    {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                                                </div>
                                                            ) : defaultSocialIconsUrls.find((element) => {
                                                                if (
                                                                    element.title.toLowerCase() ===
                                                                    tableTD.name.toLowerCase()
                                                                ) {
                                                                    return true;
                                                                }
                                                            }) ? (
                                                                <div className="badges_list_wrapper">
                                                                    {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                                                </div>
                                                            ) : // TO SHOW COUNTRY FLAG
                                                                isCountryFlagVisible === true ? (
                                                                    <Wrapper>
                                                                        <div className="country_flag_section">
                                                                            <div className="country_flag_wrapper">
                                                                                {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                                                                <img
                                                                                    className="flag_img2"
                                                                                    src={handleImage(tableRow[tableTD.field])}
                                                                                />
                                                                            </div>
                                                                            <ErrorBoundary>
                                                                                <TextFormatter
                                                                                    string={tableRow[tableTD.field]}
                                                                                />
                                                                            </ErrorBoundary>
                                                                        </div>
                                                                    </Wrapper>
                                                                ) : // TO SHOW CITY FLAG
                                                                    isDynamicFlagVisible === true ? (
                                                                        <Wrapper>
                                                                            <div className="country_flag_section">
                                                                                <div className="country_flag_wrapper">
                                                                                    <img
                                                                                        className="flag_img"
                                                                                        src={DefaultFlagIcon}
                                                                                    />
                                                                                    <span className="cityabb">
                                                                                        <TextFormatter
                                                                                            string={
                                                                                                tableRow[tableTD.field].match(
                                                                                                    /[^\u0000-\u007F]+/
                                                                                                )
                                                                                                    ? tableRow[tableTD.field]
                                                                                                        .match(/[^\u0000-\u007F]+/)
                                                                                                        .join("")
                                                                                                        .substring(0, 2)
                                                                                                    : tableRow[tableTD.field].indexOf(
                                                                                                        " "
                                                                                                    ) >= 0 &&
                                                                                                        tableRow[tableTD.field].indexOf(",") >
                                                                                                        0
                                                                                                        ? tableRow[tableTD.field].substring(
                                                                                                            0,
                                                                                                            2
                                                                                                        )
                                                                                                        : tableRow[tableTD.field].indexOf(
                                                                                                            " "
                                                                                                        ) >= 0 &&
                                                                                                            tableRow[tableTD.field].indexOf(",") <
                                                                                                            0
                                                                                                            ? tableRow[tableTD.field]
                                                                                                                .match(/\b\w/g)
                                                                                                                .join("")
                                                                                                                .substring(0, 2)
                                                                                                            : tableRow[tableTD.field].substring(
                                                                                                                0,
                                                                                                                2
                                                                                                            )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <ErrorBoundary>
                                                                                    <TextFormatter
                                                                                        string={tableRow[tableTD.field]}
                                                                                    />
                                                                                </ErrorBoundary>
                                                                            </div>
                                                                        </Wrapper>
                                                                    ) : (
                                                                        <ErrorBoundary>
                                                                            <TextFormatter string={tableRow[tableTD.field]} />
                                                                        </ErrorBoundary>
                                                                    )}
                                            </td>
                                        )
                                    )}
                                </tr>
                            ) : (
                                <tr key={tableRowIndex}>
                                    {formattedTableHeaders.map((tableTD, tableTDIndex) =>
                                        tableRow[tableTD.field] === undefined ? (
                                            <td key={tableTDIndex}>0</td>
                                        ) : tableRow[tableTD.field] === "" ||
                                            tableRow[tableTD.field] === "NA" ||
                                            tableRow[tableTD.field] === null ||
                                            tableRow[tableTD.field] === " " ? (
                                            <td
                                                className="no_data_align 895"
                                                data-label={tableTD.name}
                                                key={tableTDIndex}
                                            >
                                                {isCreatableDropdown && tableTD.name === "Campaign Bucket" ? generateTags(null, tableRow) : "N/A"}
                                            </td>
                                        ) :
                                            (
                                                <td
                                                    key={tableTDIndex}
                                                    data-label={tableTD.name}
                                                    title={tableRow[tableTD.field]}
                                                    className={
                                                        isNumber(tableRow[tableTD.field]) === true
                                                            ? "number_align"
                                                            : "text_align"
                                                    }
                                                >
                                                    {isNumber(tableRow[tableTD.field]) === true ? (
                                                        tableTD.name === "Viral Grade" ? (
                                                            <ErrorBoundary>
                                                                <div className="cell_badge_wrapper">
                                                                    <NumberSeparator
                                                                        fixedSpend={showFixedSpend}
                                                                        platform={tablePlatform}
                                                                        title={tableTD.name}
                                                                        number={tableRow[tableTD.field]}
                                                                    />
                                                                    {tableRow[tableTD.field] > 0 && (
                                                                        <div className="badge_wrapper">
                                                                            {tableRow[tableTD.field] < 1000 ? (
                                                                                <div className="badge positive">A</div>
                                                                            ) : (
                                                                                <div className="badge negative">B</div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : showGrowthDegrowth ? (
                                                            <ErrorBoundary>
                                                                <div className="cell_badge_wrapper hellooo_904">
                                                                    <NumberSeparator
                                                                        fixedSpend={showFixedSpend}
                                                                        platform={tablePlatform}
                                                                        title={tableTD.name}
                                                                        number={Math.abs(tableRow[tableTD.field])}
                                                                    />
                                                                    {tableRow[tableTD.field] !== 0 ? (
                                                                        <div className="badge_wrapper">
                                                                            {tableRow[tableTD.field] > 0 ? (
                                                                                <div className="icon">
                                                                                    <IconGrowth className="fs_12" />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="icon">
                                                                                    <IconDeGrowth className="fs_12" />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="badge_wrapper">
                                                                            {
                                                                                <div className="icon">
                                                                                    <IconNeutral className="fs_12" />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </ErrorBoundary>
                                                        ) : // TO SHOW GROWTH ICONS
                                                            growthIconMetrics.includes(tableTD.name) ? (
                                                                <ErrorBoundary>
                                                                    <div className="cell_badge_wrapper">
                                                                        <NumberSeparator
                                                                            fixedSpend={showFixedSpend}
                                                                            platform={tablePlatform}
                                                                            title={tableTD.name}
                                                                            number={tableRow[tableTD.field]}
                                                                        />
                                                                        {tableRow[tableTD.field] !== 0 ? (
                                                                            <div className="badge_wrapper">
                                                                                {tableRow[tableTD.field] > 0 ? (
                                                                                    <div className="icon">
                                                                                        <IconGrowth />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="icon">
                                                                                        <IconDeGrowth />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="badge_wrapper">
                                                                                {
                                                                                    <div className="icon">
                                                                                        <IconNeutral />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </ErrorBoundary>
                                                            ) : (
                                                                // regular number is here
                                                                <ErrorBoundary>
                                                                    {typeof tableRow[tableTD.field] === "number" ?
                                                                        <NumberSeparator
                                                                            fixedSpend={showFixedSpend}
                                                                            platform={tablePlatform}
                                                                            title={tableTD.name}
                                                                            number={tableRow[tableTD.field]}
                                                                        />
                                                                        : tableRow[tableTD.field]}
                                                                </ErrorBoundary>
                                                            )
                                                    ) : // TO MAKE URL LINK CLICKABLE
                                                        tableRow[tableTD.field].match(
                                                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                                                        ) !== null &&
                                                            tableRow[tableTD.field].match(
                                                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                            ) === null &&
                                                            tableRow[tableTD.field].includes("ggpht") ===
                                                            false ? (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        '<a target="_blank" href=' +
                                                                        tableRow[tableTD.field] +
                                                                        ">" +
                                                                        tempHref +
                                                                        "</a>",
                                                                }}
                                                            />
                                                        ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                                                            tableTD.name !== "Campaign Bucket" &&
                                                                tableRow[tableTD.field].length > 50 &&
                                                                tableRow[tableTD.field].match(
                                                                    /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                                ) == null &&
                                                                tableRow[tableTD.field].includes("ggpht") ===
                                                                false ? (
                                                                <ErrorBoundary>
                                                                    <div title={tableRow[tableTD.field]}>
                                                                        <TextFormatter
                                                                            string={
                                                                                tableRow[tableTD.field].substring(0, 50) +
                                                                                "..."
                                                                            }
                                                                        />
                                                                    </div>
                                                                </ErrorBoundary>
                                                            ) : // TO SHOW IMAGE NAME AS A TEXT IN A COLUMN
                                                                removeImgExtensions === true ? (
                                                                    <ErrorBoundary>
                                                                        <div title={tableRow[tableTD.field]}>
                                                                            <TextFormatter
                                                                                string={tableRow[tableTD.field]}
                                                                            />
                                                                        </div>
                                                                    </ErrorBoundary>
                                                                ) :
                                                                    tableRow[tableTD.field].match(
                                                                        /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                                                    ) != null ||
                                                                        tableRow[tableTD.field].includes("ggpht") === true ? (
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    '<img style="width:50px" src=' +
                                                                                    tableRow[tableTD.field] +
                                                                                    ">",
                                                                            }}
                                                                        />
                                                                    ) : tableTD.name === "Categories" ||
                                                                        tableTD.name === "Campaign Bucket" ? (
                                                                        <div className="badges_list_wrapper">
                                                                            {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                                                        </div>
                                                                    ) : // TO SHOW LOADER FOR SINGLE CELL IF DATA IS NOT LOADED FOR SINGLE CELL
                                                                        tableRow[tableTD.field] === "loading" ? (
                                                                            <div className="table_cell_skeleton_wrapper">
                                                                                <div className="table_cell_skeleton"></div>
                                                                            </div>
                                                                        ) : // TO SHOW LOADER FOR SINGLE CELL IF SOMETHING WENT WRONG FROM API FOR SINGLE CELL
                                                                            tableRow[tableTD.field] === "error" ? (
                                                                                <div className="error_cell_wrapper">
                                                                                    <div className="info_wrapper">
                                                                                        {/* <p className="info1">Data Unavailable</p> */}
                                                                                        <p>Data Unavailable</p>
                                                                                    </div>
                                                                                </div>
                                                                            ) : defaultSocialIconsUrls.find((element) => {
                                                                                if (
                                                                                    element.title.toLowerCase() ===
                                                                                    tableTD.name.toLowerCase()
                                                                                ) {
                                                                                    return true;
                                                                                }
                                                                            }) ? (
                                                                                <div className="badges_list_wrapper">
                                                                                    {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                                                                </div>
                                                                            ) : // TO SHOW COUNTRY FLAG
                                                                                isCountryFlagVisible === true ? (
                                                                                    <Wrapper>
                                                                                        <div className="country_flag_section">
                                                                                            <div className="country_flag_wrapper">
                                                                                                <img
                                                                                                    className="flag_img"
                                                                                                    src={handleImage(tableRow[tableTD.field])}
                                                                                                />
                                                                                            </div>
                                                                                            <ErrorBoundary>
                                                                                                <TextFormatter
                                                                                                    string={tableRow[tableTD.field]}
                                                                                                />
                                                                                            </ErrorBoundary>
                                                                                        </div>
                                                                                    </Wrapper>
                                                                                ) : // TO SHOW CITY FLAG
                                                                                    isDynamicFlagVisible === true ? (
                                                                                        <Wrapper>
                                                                                            <div className="country_flag_section">
                                                                                                <div className="country_flag_wrapper">
                                                                                                    <img
                                                                                                        className="flag_img"
                                                                                                        src={DefaultFlagIcon}
                                                                                                    />
                                                                                                    <span className="cityabb">
                                                                                                        <TextFormatter
                                                                                                            string={
                                                                                                                tableRow[tableTD.field].match(
                                                                                                                    /[^\u0000-\u007F]+/
                                                                                                                )
                                                                                                                    ? tableRow[tableTD.field]
                                                                                                                        .match(/[^\u0000-\u007F]+/)
                                                                                                                        .join("")
                                                                                                                        .substring(0, 2)
                                                                                                                    : tableRow[tableTD.field].indexOf(
                                                                                                                        " "
                                                                                                                    ) >= 0 &&
                                                                                                                        tableRow[tableTD.field].indexOf(",") >
                                                                                                                        0
                                                                                                                        ? tableRow[tableTD.field].substring(
                                                                                                                            0,
                                                                                                                            2
                                                                                                                        )
                                                                                                                        : tableRow[tableTD.field].indexOf(
                                                                                                                            " "
                                                                                                                        ) >= 0 &&
                                                                                                                            tableRow[tableTD.field].indexOf(",") <
                                                                                                                            0
                                                                                                                            ? tableRow[tableTD.field]
                                                                                                                                .match(/\b\w/g)
                                                                                                                                .join("")
                                                                                                                                .substring(0, 2)
                                                                                                                            : tableRow[tableTD.field].substring(
                                                                                                                                0,
                                                                                                                                2
                                                                                                                            )
                                                                                                            }
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <ErrorBoundary>
                                                                                                    <TextFormatter
                                                                                                        string={tableRow[tableTD.field]}
                                                                                                    />
                                                                                                </ErrorBoundary>
                                                                                            </div>
                                                                                        </Wrapper>
                                                                                    ) : tableTD.name === "Geography" ? (
                                                                                        <ErrorBoundary>
                                                                                            {tableRow[tableTD.field]}
                                                                                        </ErrorBoundary>
                                                                                    ) : (
                                                                                        <ErrorBoundary>
                                                                                            <TextFormatter string={tableRow[tableTD.field]} />
                                                                                        </ErrorBoundary>
                                                                                    )}
                                                </td>
                                            )
                                    )}
                                </tr>
                            )
                        )}

                        {(!NoDataAvailableChecker(showTotalRow) || showTotalRow === false) && showTotalRow.map((tableRow, tableRowIndex) =>
                        (
                            <tr className="bottomRow_divider">
                                {
                                    formattedTableHeaders.map((tableTD, tableTDIndex) => (
                                        (typeof tableRow[tableTD.field] !== 'number' ?
                                            (tableTDIndex === 0 ?
                                                <td> <b>Total</b></td>

                                                : <td className={"text_align"} title={tableRow[tableTD.field]}>

                                                    {tableRow[tableTD.field]}
                                                </td>)
                                            :
                                            <td className={"number_align totalRow_number"} title={tableRow[tableTD.field]}>
                                                {tableTD.name === 'End Date' ? 'N/A' :
                                                    <ErrorBoundary>
                                                        <NumberSeparator fixedSpend={showFixedSpend} title={tableTD.name} number={tableRow[tableTD.field]} />
                                                    </ErrorBoundary>
                                                }
                                            </td>)
                                    ))
                                }
                            </tr>
                        ))
                        }

                    </tbody>
                    {showTotal && (
                        <tfoot>
                            <tr>
                                {Object.entries(totalNumberColumns).map(([key, value], index) => {
                                    const className =
                                        typeof value === "number"
                                            ? "number_align"
                                            : "no_data_align";
                                    return (
                                        <td key={key} data-key={key} className={className}>
                                            {index === 0 && (value.length === 0) ? 'Total' : null}
                                            {typeof value === "number" ? (
                                                <NumberSeparator
                                                    fixedSpend={showFixedSpend}
                                                    platform={tablePlatform}
                                                    title={key}
                                                    number={value}
                                                    footerNumber={true}
                                                />
                                            ) : (
                                                value
                                            )}
                                        </td>
                                    );
                                })}


                            </tr>
                        </tfoot>
                    )}
                </table>
            </Wrapper >
        );
    } catch (error) {
        DataTable = (
            <Wrapper>
                <SomethingWentWrong />
            </Wrapper>
        );
    }

    useEffect(() => {
        clearSelectedItems === true && setCheckedItems([]);
    }, [clearSelectedItems]);

    return (
        <div
            className={`grid grid_cols_4 fullpage_section_card ${isHalfSection ? "" : "section_card"
                } ${hideBoxShadow ? "no_boxshadow" : ""} `}
        >
            <div className="grid col_span_4">
                <div className="section_card_body">
                    <Wrapper>
                        <div className="section_card_header">
                            <div className="section_info">
                                <h2 className="section_card_title"> {tableTitle}</h2>
                                {tableTitle &&
                                    tablePageSubModule &&
                                    tablePagePlatform &&
                                    tablePagePrimaryTab && (
                                        <SectionDefinationsBadge
                                            title={tableTitle.split(":")[0]}
                                            module={tablePageSubModule}
                                            platform={tablePagePlatform}
                                            section={tablePagePrimaryTab}
                                        />
                                    )}
                            </div>
                            <div className="filters_wrapper">
                                <div data-html2canvas-ignore={true}>
                                    <div className="filter">
                                        {
                                            tablePaginationData === undefined ? (
                                                <input
                                                    className="form_control input"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={searchPlaceHolder}
                                                    defaultValue={tableConfigs?.search_value ?? ''}
                                                    onChange={(event) => {
                                                        setSeachTerm(event.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                />
                                            ) : (
                                                <div className="flex filter-searchbar-input flex-align-center">
                                                    {(searchTerm !== null || tableConfigs.search_value !== null) && <div className="filter-searchbar-clear" onClick={() => {
                                                        if (tableConfigs.search_value === null) {
                                                            document.getElementById('searchField').value = ''
                                                            setSeachTerm(null)
                                                        } else {
                                                            setTableConfigs({ ...tableConfigs, search_value: null, page_num: 1 })
                                                        }
                                                    }}>Clear Search</div>}
                                                    <input
                                                        id="searchField"
                                                        className="form_control input"
                                                        type="text"
                                                        value={searchValue}
                                                        autoComplete="off"
                                                        placeholder={searchPlaceHolder}
                                                        defaultValue={tableConfigs?.search_value ?? ''}
                                                        onChange={(event) => {
                                                            setSeachTerm(event.target.value);
                                                            setCurrentPage(1);
                                                        }}
                                                        style={{
                                                            "height": "39px"
                                                        }}
                                                    />
                                                    <Button handleClick={() => SearchHandler()} className="secondary_btn">
                                                        Search
                                                    </Button>
                                                </div>
                                            )
                                        }


                                    </div>
                                    {tableDropdown && (
                                        <div className="filter" style={{ "width": "250px" }}>
                                            {tableDropdown}
                                        </div>
                                    )}
                                    {tableExportDropdown && (
                                        <div className="filter-export">{tableExportDropdown}</div>
                                    )}
                                </div>

                                {searchSingleColumnNumberRow === true && (
                                    <div className="filter filter2">
                                        <input
                                            className="form_control input"
                                            type="text"
                                            autoComplete="off"
                                            placeholder={searchSingleRowPlaceholder}
                                            onChange={(event) => {
                                                setSearchSingleColumnData(event.target.value);
                                                setCurrentPage(1);
                                            }}
                                            value={searchSingleColumnData}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="table_wrapper">
                            {checkTable.length === 0 ? (
                                <NoDataAvailableLoader />
                            ) : (
                                DataTable
                            )}
                        </div>
                        {tableData.length !== 0 && (
                            <div className="grid grid_cols_4 footer">
                                <div className="grid col_span_2 info_text">
                                    {totalItems > tableLength ? (
                                        currentPage === totalPages ? (
                                            <Wrapper>
                                                Showing {totalItems} of {totalItems} entries
                                            </Wrapper>
                                        ) :

                                            <Wrapper>
                                                Showing {tablePaginationData?.record_end ?? (currentPage * tableLength)} of {totalItems} {" "}
                                                entries
                                            </Wrapper>

                                    ) : (
                                        <Wrapper>
                                            Showing {totalItems} of {totalItems} entries
                                        </Wrapper>
                                    )}
                                </div>

                                {totalItems > tableLength && (
                                    <div className="grid col_span_2">
                                        <div className="pagination grid col_span_2 flex justify_end">
                                            <div className="btn_wrapper">
                                                <Button
                                                    handleClick={() => {
                                                        setCurrentPage(1)
                                                        handleTableConfigs(1)
                                                    }}
                                                    className="secondary_btn"
                                                    disabled={currentPage === 1 ? true : false}
                                                >
                                                    1
                                                </Button>
                                            </div>
                                            <div className="btn_wrapper">
                                                <Button
                                                    handleClick={() => {
                                                        setCurrentPage(currentPage - 2)
                                                        handleTableConfigs(currentPage - 2)
                                                    }}
                                                    className="secondary_btn"
                                                    disabled={currentPage - 2 < 1 ? true : false}
                                                >
                                                    Prev
                                                </Button>
                                            </div>
                                            {currentPage - 1 >= 1 && (
                                                <div className="btn_wrapper">
                                                    <Button
                                                        handleClick={() => {
                                                            setCurrentPage(currentPage - 1)
                                                            handleTableConfigs(currentPage - 1)
                                                        }}
                                                        className="secondary_btn"
                                                    >
                                                        {currentPage - 1}
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="btn_wrapper">
                                                <Button
                                                    handleClick={() => {
                                                        setCurrentPage(currentPage)
                                                        handleTableConfigs(currentPage)
                                                    }}
                                                    className="primary_btn"
                                                >
                                                    {currentPage}
                                                </Button>
                                            </div>
                                            {currentPage + 1 > totalPages ? (
                                                ""
                                            ) : (
                                                <div className="btn_wrapper">
                                                    <Button
                                                        handleClick={() => {
                                                            setCurrentPage(currentPage + 1)
                                                            handleTableConfigs(currentPage + 1)
                                                        }}
                                                        className="secondary_btn"
                                                    >
                                                        {currentPage + 1}
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="btn_wrapper">
                                                <Button
                                                    handleClick={() => {
                                                        setCurrentPage(currentPage + 2)
                                                        handleTableConfigs(currentPage + 2)
                                                    }}
                                                    className="secondary_btn"
                                                    disabled={totalPages < currentPage + 2}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                            <div className="btn_wrapper">
                                                <Button
                                                    handleClick={() => {
                                                        setCurrentPage(totalPages)
                                                        handleTableConfigs(totalPages)
                                                    }}
                                                    className="secondary_btn"
                                                    disabled={totalPages === currentPage}
                                                >
                                                    {totalPages}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Wrapper>
                </div>
            </div>
        </div>
    );
};

PaginatedDatatable.propTypes = {
    tableTitle: PropTypes.string,
    tableHeader: PropTypes.array,
    tableData: PropTypes.array,
    tableLength: PropTypes.number,
    tableDropdown: PropTypes.any,
    searchPlaceHolder: PropTypes.string,
    searchSingleColumnNumberRow: PropTypes.bool,
    searchSingleColumnNumber: PropTypes.number,
    hrefTitle: PropTypes.string,
    tableExportDropdown: PropTypes.any,
    tableID: PropTypes.string,
    tablePlatform: PropTypes.string,
    checkbox: PropTypes.bool,
    onCheckboxClickHandler: PropTypes.func,
    isDynamicFlagVisible: PropTypes.bool,
    showGrowthDegrowth: PropTypes.bool,
    showTotal: PropTypes.bool,
    isCreatableDropdown: PropTypes.bool,
    setTableConfigs: PropTypes.func,
    tablePaginationData: PropTypes.object,
    showTotalRow: PropTypes.any
};

export default PaginatedDatatable;
