import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Datatable from "../../../../components/ui/tables/Datatable";
import Slider from "../../../../components/ui/Slider";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";


const WebsiteChannelWiseTraffic = ({ viewId }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Website"
  let pagePlatform = "Traffic"
  let pagePrimaryTab = "Channelwise Traffic"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, exportSummary, setIsExportDisabled } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [apiData, setApiData] = useState([]);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [apiDataError, setApiDataError] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableDataLoaded, setTableDataaLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDatataError] = useState();

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRICS CARDS SLIDER

  const fetchMetricsListHandler = async () => {
    setApiDataLoaded(false);
    setApiDataLoading(true);
    setApiDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-overall-channel/", config);

      setApiData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError();
    } catch (error) {
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(error.code);
    }
  };

  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 ">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Channel Wise Traffic
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          {viewId === undefined ? (
            <NoDataAvailableLoader chartType="cardChartType" />
          ) : viewId.length === 0 ? (
            <NoDataAvailableLoader chartType="cardChartType" />
          ) : (
            <Loader loaderType="cardLoader" />
          )}
        </div>
      </div>
    </Wrapper>
  )

  // IF DATA IS LOADED
  if (apiDataLoaded && !apiDataLoading) {
    if (NoDataAvailableChecker(apiData)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 ">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Channel Wise Traffic
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      )
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary>
            {apiData.map((data, index) => (
              <Slider sliderTitle={data.title}>
                {data.sub_data.map((item, pos) => (
                  <SwiperSlide key={index}>
                    <Card
                      module="website"
                      platform="traffic"
                      section="channel_wise_traffic"
                      cardTitle={item.label}
                      currentValue={item.currentValue}
                      rowOneTitle={"Previous"}
                      previousValue={item.previousValue}
                      numberVariant={"number"}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            ))}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 ">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Channel Wise Traffic
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={apiDataError} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 ">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Channel Wise Traffic
            </h2></div>


          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="cardChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="cardChartType" />
            ) : (
              <Loader loaderType="cardLoader" />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }

  // TO FETCH TABLE DATA FROM API
  const fetchTableMetricsListHandler = async () => {
    setTableDataaLoaded(false);
    setTableDataLoading(true);
    setTableDatataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-channel-data-table/", config);

      setTableData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setTableHeaders(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label === undefined || res.data.label.length === 0 ? [] :
            res.data.label
      )
      setTableDataaLoaded(true);
      setTableDataLoading(false);
      setTableDatataError();
    } catch (error) {
      setTableData([]);
      setTableDataaLoaded(true);
      setTableDataLoading(false);
      setTableDatataError(error.code);
    }
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="tableChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="tableChartType" />
        ) :
        (
          fetchMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="tableChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="tableChartType" />
        ) :
        (
          fetchTableMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable =
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="traffic"
              section="channel"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : (
              <Loader loaderType="smallTableLoader" />
            )}

          </div>
        </div>
      </div>
    </Wrapper>

  if (tableDataLoaded && !tableDataLoading) {
    if (NoDataAvailableChecker(tableData) || NoDataAvailableChecker(tableHeaders)) {
      summaryTable = <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="traffic"
                section="channel"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <NoDataAvailableLoader chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>
    } else {
      summaryTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={"Summary"}
              tablePageSubModule="website"
              tablePagePlatform="traffic"
              tablePagePrimaryTab="channel"
              tableHeader={tableHeaders}
              tableData={tableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Channels"}
              tableID="summary_table"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId="summary_table"
                  sectionName="Summary Table"
                  sectionData={tableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable =
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="traffic"
                section="channel"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable =
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="traffic"
                section="channel"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              {viewId === undefined ? (
                <NoDataAvailableLoader chartType="tableChartType" />
              ) : viewId.length === 0 ? (
                <NoDataAvailableLoader chartType="tableChartType" />
              ) : (
                <Loader loaderType="smallTableLoader" />
              )}

            </div>
          </div>
        </div>
      </Wrapper>
  }

  var allData = { "Summary": exportSummary, "WebsiteCards_Channel Traffic Wise": apiData, 'Summary Table': tableData }

  useEffect(() => {
    setExportPageName('Website Traffic - Channel Wise Traffic')
    setFullPageExport(allData);
  }, [apiData, tableData])

  useEffect(() => {
    if (apiDataLoaded && tableDataLoaded) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [apiDataLoaded, tableDataLoaded])



  return (
    <Wrapper>
      {metricCardsSection}
      {/* <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
        <div className="section_info"> 
          <h2 className="section_card_title">Summary
            </h2> <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="traffic"
              section="channel"
            />
         </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body"> */}
      {summaryTable}
      {/* </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

export default WebsiteChannelWiseTraffic;
