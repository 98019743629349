import React, { Component } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../helpers/wrapper";
import Col from "react-bootstrap/esm/Col";
import { Carousel } from "../../../shared/components/carousel/carousel";
import { ChartCard } from "../../../shared/components/cards/chartcard/chartcard";
import { RiCouponCard } from "../../../shared/components/cards/ricouponcard/ricouponcard";
import { Dropdown } from "../../../shared/components/dropdown/dropdown";
import SolidGaugeChart from "../../../shared/components/charts/SolidGaugeChart";
import Linegraph_sparkline from "../../../shared/components/charts/Linegraph_sparkline";
import IconWebsite from "../../../../assets/images/icons/websiteIcon";
import MultiLineMultiAxesChart from "../../../shared/components/charts/MultiLineMultiAxesChart";
import RiTreegraph from "../../../shared/components/charts/RiTreegraph";
import RadarChart from "../../../shared/components/charts/RadarChart";
import RiCategoryHBarChart from "../../../shared/components/charts/RiCategoryHBarChart";
import RiStoreBubbleChart from "../../../shared/components/charts/RiStoreBubbleChart";
import IconPdf from "../../../../assets/images/icons/pdfIcon";
import RiHeatMap from "../../../shared/components/charts/RiHeatMap";
import RiFilter from "../../../layout/ri-pageheader/ri-filter";
import StickyLogo from "../../../../assets/images/icons/icon.png";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/esm/Image";
import RiMapFilter from "../ri-mapfilter/RiMapFilter";
import IndiaMap from "../../../shared/components/map/indiamap/indiamap"
import { ProgressBar } from 'react-bootstrap';
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import { Breadcrumb } from "react-bootstrap";

import "../../../shared/components/loader/loader.scss";
import "../../../layout/pageheader/pageheader.scss";

import { API_URL } from "../../../helpers/constant";

import { Loader } from '../../../shared/components/loader/loader';
import ChartExport from "../../../shared/components/export/ChartExport";
import UKMap from "../../../shared/components/map/ukmap/ukmap";

import BRAND1 from "../../../../assets/images/brands/brand1.png";
import BRAND2 from "../../../../assets/images/brands/brand2.png";
import BRAND3 from "../../../../assets/images/brands/brand3.png";
import BRAND4 from "../../../../assets/images/brands/brand4.png";
import BRAND5 from "../../../../assets/images/brands/brand5.png";
import PageHeader from "../../../../../components/layout/pageHeader";
import USMap from "../../../shared/components/map/usmap/usmap";


export class BusinessOverview extends Component {
  listener = null;
  constructor(props) {
    super(props);

    this.state = {
      dropdown_options: [
        { value: "markdown", label: "Markdown" },
        // { value: "nsv", label: "NSV" },
        //{ value: "units-sold", label: "Units Sold" },
        { value: "mix", label: "Mix%" },
        { value: "nm", label: "NM%" },
        { value: "asp", label: "ASP" },
        { value: "sell-thru", label: "Sell Thru" },
      ],
      performance_dropdown_value: "markdown",

      brandsDropdownOptions: [
        {
          value: "pulsar",
          label: "Pulsar",
          icon: (
            <img
              src={BRAND1}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "platina",
          label: "Platina",
          icon: (
            <img
              src={BRAND2}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "ct",
          label: "CT",
          icon: (
            <img
              src={BRAND3}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "avenger",
          label: "Avenger",
          icon: (
            <img
              src={BRAND4}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "dominar",
          label: "Dominar",
          icon: (
            <img
              src={BRAND5}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        }
      ],

      selectedBrandDropdownOption: "",

      asp_dropdown_options: [
        { value: "asp", label: "ASP" },
        { value: "nsv", label: "NSV" },
        { value: "ats", label: "ATS" },
        { value: "bills", label: "Bills" },
        // { value: "mixtostore", label: "Mix To Store" },
        { value: "nm", label: "NM%" },
        { value: "carpet_area", label: "Carpet Area" },
        { value: "sell_through", label: "Sell Thru Rate%" },
        { value: "recovery", label: "Recovery" },
        // { value: "margin", label: "Margin" },
        // { value: "mbq", label: "MBQ%" }
      ],
      units_dropdown_options: [
        { value: "asp", label: "ASP" },
        { value: "nsv", label: "NSV" },
        { value: "ats", label: "ATS" },
        { value: "bills", label: "Bills" },
        // { value: "mixtostore", label: "Mix To Store" }, 
        { value: "nm", label: "NM%" },
        { value: "carpet_area", label: "Carpet Area" },
        { value: "sell_through", label: "Sell Thru Rate%" },
        { value: "recovery", label: "Recovery" },
        // { value: "margin", label: "Margin" },
        // { value: "mbq", label: "MBQ%" },
      ],
      markdown_dropdown_options: [
        { value: "promo", label: "Promo" },
        { value: "nonpromo", label: "Non Promo" },
      ],
      markdownasp_dropdown_options: [
        { "value": "markdown", "label": "Markdown" },
        { "value": "asp", "label": "Asp" }],
      category_markdown_filteroptions: [
        { value: "promo", label: "Promo" },
        { value: "nonpromo", label: "Non Promo" },
      ],
      markdownopt_dropdown_options: [
        { value: "busi_category", label: "Business Category" },
        { value: "category", label: "Category" },
        { value: "season_new", label: "Season" },
        { value: "stock_type_new", label: "Stock Type" },
        { value: "busi_sub_dept", label: "Business Sub Department" },
        { value: "gender", label: "Gender" },
        { value: "product", label: "Product" },
        { value: "brand_new", label: "Brands" },
        { value: "color_description", label: "Colour" },
        { value: "size_description", label: "Size" },

      ],
      performancetrend: [
        { month: "27 Oct", value1: 38, value2: 8, value3: 18 },
        { month: "28 OCT", value1: 45, value2: 6, value3: 13 },
        { month: "29 Oct", value1: 38, value2: 14, value3: 18 },
        { month: "30 Oct", value1: 18, value2: 20, value3: 24 },
        { month: "Oct 31", value1: 15, value2: 20, value3: 32 },
        { month: "Nov 1", value1: 9, value2: 29, value3: 35 },
        { month: "Nov 2", value1: 10, value2: 31, value3: 39 },
        { month: "Nov 3", value1: 20, value2: 28, value3: 44 },
        { month: "Nov 4", value1: 23, value2: 30, value3: 48 },
        { month: "Nov 5", value1: 37, value2: 20, value3: 33 },
        { month: "Nov 6", value1: 40, value2: 16, value3: 28 },
        { month: "Nov 7", value1: 34, value2: 11, value3: 18 },
        { month: "Nov 8", value1: 50, value2: 16, value3: 27 },
      ],
      business: [],
      sale: [],
      customer: [],
      product: [],
      linesparkgraph_sales: [],
      linesparkgraph_overall: [],
      linesparkgraph_product: [],
      linesparkgraph_customer: [],
      customerpersona: [
        { category: "Bargain Hunters", value: 60 },
        { category: "Potential Loyalists", value: 38 },
        { category: "Active Loyalists", value: 44 },
        { category: "Hibernating", value: 65 },
        { category: "Need Attention", value: 72 },
        { category: "Lost", value: 78 },
        { category: "Big Wallets", value: 86 },
      ],
      categoriesData: [
        { category: "Bottomwear", value: "20" },
        { category: "Kidswear", value: "40" },
        { category: "Shoes", value: "70" },
        { category: "Topwear", value: "50" },
        { category: "Denims", value: "30" },
      ],
      brandData: [
        { category: "Adidas", value: "20" },
        { category: "Puma", value: "40" },
        { category: "Zara", value: "70" },
        { category: "Buffalo", value: "50" },
        { category: "Levi's", value: "30" },
      ],
      storedata: [],
      markdownData: [
        { rate: "20", category: "Dresses", value: 2990 },
        { rate: "30", category: "Dresses", value: 2520 },
        { rate: "40", category: "Dresses", value: 3530 },
        { rate: "50", category: "Dresses", value: 2230 },
        { rate: "60", category: "Dresses", value: 2325 },
        { rate: "70", category: "Dresses", value: 3309 },
        { rate: "80", category: "Dresses", value: 5657 },
        { rate: "20", category: "Trousers", value: 3346 },
        { rate: "30", category: "Trousers", value: 2725 },
        { rate: "40", category: "Trousers", value: 3052 },
        { rate: "50", category: "Trousers", value: 3876 },
        { rate: "60", category: "Trousers", value: 4453 },
        { rate: "70", category: "Trousers", value: 3972 },
        { rate: "80", category: "Trousers", value: 4644 },
        { rate: "20", category: "Footwear", value: 4468 },
        { rate: "30", category: "Footwear", value: 3306 },
        { rate: "40", category: "Footwear", value: 3906 },
        { rate: "50", category: "Footwear", value: 4413 },
        { rate: "60", category: "Footwear", value: 4726 },
        { rate: "70", category: "Footwear", value: 4584 },
        { rate: "80", category: "Footwear", value: 5717 },
        { rate: "20", category: "Sports", value: 8145 },
        { rate: "30", category: "Sports", value: 7177 },
        { rate: "40", category: "Sports", value: 5657 },
        { rate: "50", category: "Sports", value: 6802 },
        { rate: "60", category: "Sports", value: 8159 },
        { rate: "70", category: "Sports", value: 8449 },
        { rate: "80", category: "Sports", value: 9453 },
        { rate: "20", category: "Luggage", value: 3689 },
        { rate: "30", category: "Luggage", value: 3081 },
        { rate: "40", category: "Luggage", value: 6525 },
        { rate: "50", category: "Luggage", value: 6228 },
        { rate: "60", category: "Luggage", value: 6917 },
        { rate: "70", category: "Luggage", value: 6568 },
        { rate: "80", category: "Luggage", value: 6405 },
        { rate: "20", category: "Accessories", value: 4022 },
        { rate: "30", category: "Accessories", value: 3063 },
        { rate: "40", category: "Accessories", value: 3638 },
        { rate: "50", category: "Accessories", value: 3968 },
        { rate: "60", category: "Accessories", value: 4070 },
        { rate: "70", category: "Accessories", value: 4019 },
        { rate: "80", category: "Accessories", value: 4548 },
        { rate: "20", category: "Bottomwear", value: 3503 },
        { rate: "30", category: "Bottomwear", value: 2842 },
        { rate: "40", category: "Bottomwear", value: 2808 },
        { rate: "50", category: "Bottomwear", value: 3063 },
        { rate: "60", category: "Bottomwear", value: 3081 },
        { rate: "70", category: "Bottomwear", value: 2745 },
        { rate: "80", category: "Bottomwear", value: 2527 },
        { rate: "20", category: "Cosmetics", value: 3503 },
        { rate: "30", category: "Cosmetics", value: 2842 },
        { rate: "40", category: "Cosmetics", value: 2808 },
        { rate: "50", category: "Cosmetics", value: 2399 },
        { rate: "60", category: "Cosmetics", value: 2842 },
        { rate: "70", category: "Cosmetics", value: 2825 },
        { rate: "80", category: "Cosmetics", value: 2527 },
        { rate: "20", category: "Ethnic", value: 3503 },
        { rate: "30", category: "Ethnic", value: 2842 },
        { rate: "40", category: "Ethnic", value: 2808 },
        { rate: "50", category: "Ethnic", value: 4726 },
        { rate: "60", category: "Ethnic", value: 3052 },
        { rate: "70", category: "Ethnic", value: 4019 },
        { rate: "80", category: "Ethnic", value: 2527 },
        { rate: "20", category: "Denims", value: 2990 },
        { rate: "30", category: "Denims", value: 2520 },
        { rate: "40", category: "Denims", value: 2334 },
        { rate: "50", category: "Denims", value: 3025 },
        { rate: "60", category: "Denims", value: 4431 },
        { rate: "70", category: "Denims", value: 3081 },
        { rate: "80", category: "Denims", value: 4468 },
        { rate: "20", category: "Blazers", value: 3346 },
        { rate: "30", category: "Blazers", value: 2725 },
        { rate: "40", category: "Blazers", value: 3052 },
        { rate: "50", category: "Blazers", value: 3876 },
        { rate: "60", category: "Blazers", value: 4453 },
        { rate: "70", category: "Blazers", value: 3972 },
        { rate: "80", category: "Blazers", value: 4644 },
        { rate: "20", category: "T-shirts", value: 4468 },
        { rate: "30", category: "T-shirts", value: 3306 },
        { rate: "40", category: "T-shirts", value: 3906 },
        { rate: "50", category: "T-shirts", value: 4413 },
        { rate: "60", category: "T-shirts", value: 4726 },
        { rate: "70", category: "T-shirts", value: 4584 },
        { rate: "80", category: "T-shirts", value: 5717 },
        { rate: "20", category: "Shirts", value: 8145 },
        { rate: "30", category: "Shirts", value: 7177 },
        { rate: "40", category: "Shirts", value: 5657 },
        { rate: "50", category: "Shirts", value: 6802 },
        { rate: "60", category: "Shirts", value: 8159 },
        { rate: "70", category: "Shirts", value: 8449 },
        { rate: "80", category: "Shirts", value: 9453 },
        { rate: "20", category: "Shoes", value: 3689 },
        { rate: "30", category: "Shoes", value: 3081 },
        { rate: "40", category: "Shoes", value: 6525 },
        { rate: "50", category: "Shoes", value: 6228 },
        { rate: "60", category: "Shoes", value: 6917 },
        { rate: "70", category: "Shoes", value: 6568 },
        { rate: "80", category: "Shoes", value: 6405 },
        { rate: "20", category: "Watches", value: 4022 },
        { rate: "30", category: "Watches", value: 3063 },
        { rate: "40", category: "Watches", value: 3638 },
        { rate: "50", category: "Watches", value: 3968 },
        { rate: "60", category: "Watches", value: 4070 },
        { rate: "70", category: "Watches", value: 4019 },
        { rate: "80", category: "Watches", value: 4548 },
        { rate: "20", category: "Watches", value: 4022 },
        { rate: "30", category: "Watches", value: 3063 },
        { rate: "40", category: "Watches", value: 3638 },
        { rate: "50", category: "Watches", value: 3968 },
        { rate: "60", category: "Watches", value: 4070 },
        { rate: "70", category: "Watches", value: 4019 },
        { rate: "80", category: "Watches", value: 4548 },
        { rate: "20", category: "Flipflop", value: 2990 },
        { rate: "30", category: "Flipflop", value: 2520 },
        { rate: "40", category: "Flipflop", value: 2334 },
        { rate: "50", category: "Flipflop", value: 3876 },
        { rate: "60", category: "Flipflop", value: 2725 },
        { rate: "70", category: "Flipflop", value: 3063 },
        { rate: "80", category: "Flipflop", value: 6820 },
      ],
      treegraph: '[{"name":"PB","children":[{"name":"PB1","value":350},{"name":"PB2","value":400}]},{"name":"FLFL","children":[{"name":"FLFL1","value":350},{"name":"FLFL2","value":400}]},{"name":"Footwear","children":[{"name":"Men","value":350},{"name":"Women","value":400}]},{"name":"Sports","children":[{"name":"Men","value":350},{"name":"Women","value":200}]},{"name":"Luggage & Bagpacks","children":[{"name":"Luggage","value":350},{"name":"Bagpacks","value":150}]},{"name":"Accessaries","children":[{"name":"Men","value":350},{"name":"Women","value":180}]}]',
      is_score_trend_loaded: false,
      is_score_graph_loaded: false,
      is_performance_multiline_loaded: false,
      is_coupon_initiatives_loaded: false,
      is_mark_down_optimization: false,
      is_promo_non_promo: false,
      is_promo_non_promo_new: false,
      asp_dropdown_value: "asp",
      units_dropdown_value: "unit_sold",
      is_getfilter_loaded: false,
      datepicker_date: "",
      is_scroll: false,
      static_start_date: "",
      first_start_date: props.start_date,
      first_end_date: props.end_date,
      second_start_date: props.previous_start_date,
      second_end_date: props.previous_end_date,
      isDatePickerOpen: false,
      is_store_deep_bubble_chart_loaded: false,
      is_category_deep_dive_loaded: false,
      is_businessscore_loaded: false,
      is_businessscoreTrend_loaded: false,
      score_nsv: "",
      score_nsv_cp: "",
      score_nsv_cy: "",
      score_rgm: "",
      score_tax: "",
      score_bills: "",
      businessCategoryList: "",
      categoryList: "",
      seasonList: "",
      stockTypeList: "",
      businessSubDepartmentList: "",
      genderList: "",
      productList: "",
      brandsList: "",
      colourList: "",
      sizeList: "",
      Deep_Dive_level: 0,
      Deep_Dive_Name: [],
      global_filters: [],
      markdown_dropdown: "",
      category_markdown_filter: "",
      asp_markdown_filter: "",

      // MAP
      map_zone: '',
      map_state: '',
      map_city: '',
      map_store_code: "",
      map_store_name: '',

      // MAP FILTER
      map_store_category: '',
      map_store_type: '',
      map_store_size: '',

      mapBreadcrumbList: [],
      mapBreadcrumbsNorthPoint: '',
      mapBreadcrumbsSouthPoint: '',
      mapBreadcrumbsCenterPoint: '',

      data: "",
      MapMarker: "",
      North_point: [72.6416015625, 37.71859032558816],
      South_point: [93.1640625, 5.7908968128719565],
      map_level: 0,
      center_point: [0,
        0],

      graph_pointer_business: "",
      graph_pointer_product: "",
      graph_pointer_sale: "",
      graph_pointer_customer: "",
      is_India_map_loaded: false,
      category_dropdown_value: "nsv",
      rangetype: "nsv_ach",
      //map filter
      map_filter_category_list: [
        { name: 'Metro', checked: false },
        { name: 'Non-Metro', checked: false },
      ],
      map_filter_storetype_list: [
        { name: 'New', checked: false },
        { name: 'LTL', checked: false },
        { name: 'Mature', checked: false },
      ],
      map_filter_storesize_list: [],
      //global filter
      global_filter_business_category: [],
      global_filter_category: [],
      global_filter_season: [],
      global_filter_stock: [],
      global_filter_busi_sub_dept: [],
      global_filter_gender: [],
      global_filter_product: [],
      global_filter_brand: [],
      global_filter_color_description: [],
      global_filter_size_description: [],

    };
  }

  setSelectedSubNav = (tab) => {
    this.setState({ selectedSubNav: tab });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        first_start_date: nextProps.start_date,
        first_end_date: nextProps.end_date,
        second_start_date: nextProps.previous_start_date,
        second_end_date: nextProps.previous_end_date,
      });
    }
  }

  componentDidMount() {
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_coupon_initiatives_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_score_trend_loaded = false;
    this.state.is_store_deep_bubble_chart_loaded = false;
    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;
    this.state.is_get_global_filters = false;

    this.getGlobalFilters();
    this.getScoreTrendMatriceCard();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.getPerformanceTrendMultilineChart();
    this.getCouponInitiatives();
    this.getMarkDownOptimizationChart();
    this.getPromoNonPromoChart();
    this.getStoreDeepDriveBubbleChart();
    // //this.getCategoryNSVDeepDiveChart();
    this.getCategoryDeepDive();
    this.getBusinessScore();
    // var varCountry = this.getUrlCountryParameter('country');
    // if (varCountry != "") {
    //   localStorage.setItem("country", varCountry);
    // } else {
    //   varCountry = localStorage.getItem('country');
    //   if (varCountry == null || varCountry == "") {
    //     varCountry = "IN"
    //   }
    // }
    this.state.country = this.props.country;
    this.getMapdetails();

    this.forceUpdate();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date ||
      prevProps.previous_start_date !== this.props.previous_start_date ||
      prevProps.previous_end_date !== this.props.previous_end_date
    ) {
      this.state.is_category_deep_dive_loaded = false;
      this.state.is_coupon_initiatives_loaded = false;
      this.state.is_mark_down_optimization = false;
      this.state.is_performance_multiline_loaded = false;
      this.state.is_promo_non_promo = false;
      this.state.is_score_graph_loaded = false;
      this.state.is_score_trend_loaded = false;
      this.state.is_store_deep_bubble_chart_loaded = false;
      this.state.is_businessscore_loaded = false;
      this.state.is_businessscoreTrend_loaded = false;
      this.state.is_get_global_filters = false;

      this.getGlobalFilters();
      this.getScoreTrendMatriceCard();
      this.getSparkLineMatriceCard();
      this.getSparkLineScoreTrendCard();
      this.getPerformanceTrendMultilineChart();
      this.getCouponInitiatives();
      this.getMarkDownOptimizationChart();
      this.getPromoNonPromoChart();
      this.getStoreDeepDriveBubbleChart();
      this.getCategoryDeepDive();
      this.getBusinessScore();

      this.getMapdetails();
    }
  }

  getUrlCountryParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if ((sParameterName[0]).toLowerCase() === sParam.toLowerCase()) {
        return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return "";
  }


  getGlobalFilters() {
    let result = [];
    const url = API_URL + "/api/ri/get_global_filters/";

    fetch(url, {
      method: "POST",
      body: "",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {

          let global_filters = result1;

          let global_filter_business_category = [];
          let varbusiness_category = global_filters.business_category;
          for (let i = 0; i < varbusiness_category.length; i++) {
            let a = {
              name: varbusiness_category[i],
              checked: false,
            };
            global_filter_business_category.push(a)
          }

          let global_filter_category = [];
          let varglobal_filter_category = global_filters.category;
          for (let i = 0; i < varglobal_filter_category.length; i++) {
            let a = {
              name: varglobal_filter_category[i],
              checked: false,
            };
            global_filter_category.push(a)
          }

          let global_filter_season = [];
          let varglobal_filter_season = global_filters.season;
          for (let i = 0; i < varglobal_filter_season.length; i++) {
            let a = {
              name: varglobal_filter_season[i],
              checked: false,
            };
            global_filter_season.push(a)
          }

          let global_filter_stock = [];
          let varglobal_filter_stock = global_filters.stock;
          for (let i = 0; i < varglobal_filter_stock.length; i++) {
            let a = {
              name: varglobal_filter_stock[i],
              checked: false,
            };
            global_filter_stock.push(a)
          }

          let global_filter_busi_sub_dept = [];
          let varglobal_filter_busi_sub_dept = global_filters.busi_sub_dept;
          for (let i = 0; i < varglobal_filter_busi_sub_dept.length; i++) {
            let a = {
              name: varglobal_filter_busi_sub_dept[i],
              checked: false,
            };
            global_filter_busi_sub_dept.push(a)
          }

          let global_filter_gender = [];
          let varglobal_filter_gender = global_filters.gender;
          for (let i = 0; i < varglobal_filter_gender.length; i++) {
            let a = {
              name: varglobal_filter_gender[i],
              checked: false,
            };
            global_filter_gender.push(a)
          }

          let global_filter_product = [];
          let varglobal_filter_product = global_filters.product;
          for (let i = 0; i < varglobal_filter_product.length; i++) {
            let a = {
              name: varglobal_filter_product[i],
              checked: false,
            };
            global_filter_product.push(a)
          }

          let global_filter_brand = [];
          let varglobal_filter_brand = global_filters.brand;
          for (let i = 0; i < varglobal_filter_brand.length; i++) {
            let a = {
              name: varglobal_filter_brand[i],
              checked: false,
            };
            global_filter_brand.push(a)
          }

          let global_filter_color_description = [];
          let varglobal_filter_color_description = global_filters.color_description;
          for (let i = 0; i < varglobal_filter_color_description.length; i++) {
            let a = {
              name: varglobal_filter_color_description[i],
              checked: false,
            };
            global_filter_color_description.push(a)
          }

          let global_filter_size_description = [];
          let varglobal_filter_size_description = global_filters.size_description;
          for (let i = 0; i < varglobal_filter_size_description.length; i++) {
            let a = {
              name: varglobal_filter_size_description[i],
              checked: false,
            };
            global_filter_size_description.push(a)
          }

          this.setState({
            global_filters: global_filters,
            global_filter_business_category: global_filter_business_category,
            global_filter_category: global_filter_category,
            global_filter_season: global_filter_season,
            global_filter_stock: global_filter_stock,
            global_filter_busi_sub_dept: global_filter_busi_sub_dept,
            global_filter_gender: global_filter_gender,
            global_filter_product: global_filter_product,
            global_filter_brand: global_filter_brand,
            global_filter_color_description: global_filter_color_description,
            global_filter_size_description: global_filter_size_description,
            is_get_global_filters: true
          });
        },
        (error) => { }
      );

  }
  //map new 

  getMapdetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    // let previous_start_date = this.state.second_start_date;
    // let previous_end_date = this.state.second_end_date;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;
    let country = this.state.country != null ? (this.state.country).toUpperCase() : "IN";

    const url = API_URL + "/api/ri/zones/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size +
        "&country=" + country,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true
          });
        },
        (error) => { }
      );

  }

  getIndiaMapCityDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;


    const url = API_URL + "/api/ri/cities/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 1
          });
        },
        (error) => { }
      );

  }


  getIndiaMapStoresDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/stores/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 2
          });
        },
        (error) => { }
      );

  }
  //eod map new


  // BUSINESS SCORE FILTER
  getBusinessScore() {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;

    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;


    // const data = {
    //   start_date: "2022-01-20",
    //   end_date: "2022-01-22",
    // };
    const url = API_URL + "/api/ri/get_business-score/";

    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&gender=" + genderList +
        "&season_new=" + seasonList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&storecode=" + storecode +
        "&map_city=" + map_city +
        "&state=" + state +
        "&storename=" + storename +
        "&store_type=" + store_type +
        "&city_type=" + category +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let businessNew = result.business;
        let customerNew = result.customer;
        let productNew = result.product;
        let saleNew = result.sale;
        let graph_pointer_business = Math.round(result.graph_pointer.business);
        let graph_pointer_product = Math.round(result.graph_pointer.product);
        let graph_pointer_sale = Math.round(result.graph_pointer.sales);
        let graph_pointer_customer = Math.round(result.graph_pointer.customer);




        this.setState({
          is_businessscore_loaded: true,
          business: businessNew,
          customer: customerNew,
          product: productNew,
          sale: saleNew,
          graph_pointer_business: graph_pointer_business,
          graph_pointer_product: graph_pointer_product,
          graph_pointer_sale: graph_pointer_sale,
          graph_pointer_customer: graph_pointer_customer,
        });

      });
  }


  // CATEGPRY NSV DEEP DIVE CHART
  getCategoryDeepDive() {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    // NSV DEEP DIVES
    let Deep_Dive_level = this.state.Deep_Dive_level;
    let Deep_Dive_Name = this.state.Deep_Dive_Name;

    const url = API_URL + "/api/ri/get_category_deep_dive/";

    fetch(url, {
      method: 'POST',
      body:

        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +

        "&level=" + Deep_Dive_level +
        "&name=" + Deep_Dive_Name +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result) => {
        let category_nsv_deep_dives_list = result;



        this.setState({
          is_category_deep_dive_loaded: true,
          category_deep_dive_graph: category_nsv_deep_dives_list,



        });
      });
  }
  // MARKDOWN OPTIMIZATIN CHART LEVEL WALA
  getMarkDownOptimizationChart() {


    // DATE PICKER
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;


    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    // Markdown Filetr
    // Markdown Filetr
    let dropdownfilter = this.state.category_markdown_filter
    let asp_filter = this.state.asp_markdown_filter
    //let dropdownfilter = this.state.markdown_dropdown;

    const url = API_URL + "/api/ri/mark-down-optimization/";
    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size +
        "&dropdownfilter=" + dropdownfilter,

      // =================


      //+'&store_type='+store_type + '&map_zone='+map_zone +'&map_city='+map_city+'&storecode='+storecode),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let mark_down_optimization = result["mark_down_optimization_list"];

        let optimation_list = [];

        // markdownopt_dropdown_options: [
        //   { value: "business_category", label: "Business Category" },
        //   { value: "category", label: "Category" },
        //   { value: "season", label: "Season" },
        //   { value: "stock_type", label: "Stock Type" },
        //   { value: "business_sub", label: "Business Sub Department" },
        //   { value: "gender", label: "Gender" },
        //   { value: "product", label: "Product" },
        //   { value: "brands", label: "Brands" },
        //   { value: "colour", label: "Colour" },
        //   { value: "size", label: "Size" },

        // ],

        mark_down_optimization.map((info, index) => {
          // { rate: "20", category: "Dresses", value: 2990 },

          try {

            let business_cate = {
              "rate": Math.round((info['markdown'])),
              "category": info["busi_category"],
              "value": info["qty"],
            };
            optimation_list.push(business_cate);







          } catch (e) { }
        });

        this.setState({
          is_mark_down_optimization: true,
          optimization_graph: optimation_list,
        });
      });
  }
  // ==========================================================================
  // PROMO NON PROMO CHART 
  getPromoNonPromoChart() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;


    const url = API_URL + "/api/ri/promo-non-promo/";

    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList,
      //+
      // "&map_zone=" + map_zone +
      //   "&map_city=" + map_city +
      //   "&storecode=" + storecode +
      //   "&storename=" + storename +
      //   "&state=" + state +
      //   "&city_type=" + category +
      //   "&store_type=" + store_type +
      //   "&store_size=" + store_size,
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let promoNon_promo_view_list = result["promoNon_promo_view_list"];
        let optimation_list = [];

        promoNon_promo_view_list.map((info, index) => {
          try {
            let a = {
              rate: Math.round(info['markdown']),
              category: info["busi_category_1"],
              value: info["unit_sold"],
            };
            optimation_list.push(a);
          } catch (e) { }
        });

        this.setState({
          is_promo_non_promo: true,
          promo_non_promo_graph: optimation_list,
        });
      });
  }
  // COUPON INITIATIVE 
  getCouponInitiatives() {

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;


    const url = API_URL + "/api/ri/coupon-initiatives/";

    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let target_count =
          result["coupon_initiatives_list"]["0"]["target_count"];
        let redemption_count =
          result["coupon_initiatives_list"]["0"]["redemption_count"];
        let sales_from_redeemers =
          result["coupon_initiatives_list"]["0"]["sales_from_redeemers"];
        let comm_cost = result["coupon_initiatives_list"]["0"]["comm_cost"];
        let discount_cost =
          result["coupon_initiatives_list"]["0"]["discount_cost"];
        let increamental_biz =
          result["coupon_initiatives_list"]["0"]["increamental_biz"];

        this.setState({
          is_coupon_initiatives_loaded: true,
          target_count: target_count,
          redemption_count: redemption_count,
          sales_from_redeemers: sales_from_redeemers,
          comm_cost: comm_cost,
          discount_cost: discount_cost,
          increamental_biz: increamental_biz,
        });
      });
  }


  // MATRIE CARD 
  getScoreTrendMatriceCard() {
    //main function 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/score-martice-card/";


    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {

        let current_recovery;
        let current_bills_value = result["current_score_trends_list"]["0"]["bills"];
        let current_ats_value = result['current_score_trends_list']['0']['ats']


        let current_net_sale_value =
          result["current_score_trends_list"]["0"]["nsv"]


        let current_unit_sold =
          result["current_score_trends_list"]["0"]["units_sold"];

        let current_nsv_ach =
          Math.round(result["current_score_trends_list"]["0"]["nsv_ach"]);

        let current_net_margin =
          result["current_score_trends_list"]["0"]["net_margin"];
        let current_asp =
          result["current_score_trends_list"]["0"]["asp"];
        let current_sell_through =
          result["current_score_trends_list"]["0"]["sell_through"];

        let current_markdown =
          result["current_score_trends_list"]["0"]["markdown"];

        let current_bills =
          result["current_score_trends_list"]["0"]["bills"];
        let current_avg_ticket_size =
          result["current_score_trends_list"]["0"]["ats"];






        let last_bills_value = result['last_score_trends_list']['0']['bills'];
        let last_ats_value = result['last_score_trends_list']['0']['ats'];
        let last_net_sale_value = result["last_score_trends_list"]["0"]["nsv"];

        let last_unit_sold =
          result["last_score_trends_list"]["0"]["units_sold"];
        let last_nsv_ach =
          result["last_score_trends_list"]["0"]["nsv_ach"];
        let last_net_margin =
          result["last_score_trends_list"]["0"]["net_margin"];
        let last_asp =
          result["last_score_trends_list"]["0"]["asp"];

        let last_sell_through =
          result["last_score_trends_list"]["0"]["sell_through"];
        let last_markdown =
          result["last_score_trends_list"]["0"]["markdown"];
        let last_bills =
          result["last_score_trends_list"]["0"]["bills"];
        let last_avg_ticket_size =
          result["last_score_trends_list"]["0"]["ats"];
        let last_recovery =
          result["last_score_trends_list"]["0"]["recovery"];
        current_recovery = ((current_net_sale_value / last_net_sale_value) * 100).toFixed(2)







        this.setState({
          is_score_trend_loaded: true,
          current_net_sale_value: current_net_sale_value,
          last_net_sale_value: last_net_sale_value,
          last_nsv_ach: last_nsv_ach,
          current_nsv_ach: current_nsv_ach,
          last_net_margin: last_net_margin,
          current_net_margin: current_net_margin,
          current_unit_sold: current_unit_sold,
          last_unit_sold: last_unit_sold,
          last_asp: last_asp,
          current_asp: current_asp,
          current_sell_through: current_sell_through,
          last_sell_through: last_sell_through,
          current_markdown: current_markdown,
          last_markdown: last_markdown,
          last_bills_value: last_bills_value,
          current_bills_value: current_bills_value,
          current_avg_ticket_size: current_avg_ticket_size,
          last_avg_ticket_size: last_avg_ticket_size,
          current_recovery: current_recovery,
          last_recovery: last_recovery,
          current_ats_value: current_ats_value,
          last_ats_value: last_ats_value,


        });
      });
  }

  //Scrore treand  
  getSparkLineMatriceCard() {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/score-martice-card/";

    fetch(url, {
      method: "POST",
      body:

        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,


      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let score_trends_matrices_list = result["score_trends_sparkline_list"];



        let net_sale_value_list = [];
        let nsv_ach_list = [];
        let net_margin_list = [];
        let unit_sold_list = [];
        let avg_selling_price_list = [];
        let sell_through_list = [];
        let markdown_list = [];
        let recovery_percentagelist = [];
        let bills_list = [];
        let ats_list = [];

        score_trends_matrices_list.map((info, index) => {
          try {
            let bills = {
              date: info['day'],
              value: info['bills'],
            };
            bills_list.push(bills)

            let ats = {
              date: info['day'],
              value: info['ats'],
            }
            ats_list.push(ats)

            let nvs = {
              date: info["day"],
              value: info["nsv"],
            };
            net_sale_value_list.push(nvs);


            let nvs_ach = {
              date: info["day"],
              value: info["nsv_ach"],
            };
            nsv_ach_list.push(nvs_ach);

            let net_margin = {
              date: info["day"],
              value: parseFloat(info["net_margin"]).toFixed(2),
            };
            net_margin_list.push(net_margin);
            let unit_sold = {
              date: info["day"],
              value: info["units_sold"],
            };
            unit_sold_list.push(unit_sold);

            let avg_selling_price = {
              date: info["day"],
              value: info["asp"],
            };
            avg_selling_price_list.push(avg_selling_price);

            let sell_through = {
              date: info["day"],
              value: info["sell_through"],
            };
            sell_through_list.push(sell_through);

            let markdown = {
              date: info["day"],
              value: info["markdown"].toFixed(2),
            };
            markdown_list.push(markdown);

            let recover_percentage = {
              date: info["day"],
              value: info["recovery"],
            };

            recovery_percentagelist.push(recover_percentage);
          } catch (e) { }
        });

        this.setState({
          is_score_graph_loaded: true,
          net_sale_value_graph: net_sale_value_list,
          nsv_ach_graph: nsv_ach_list,
          bills_graph: bills_list,
          ats_graph: ats_list,
          net_margin_graph: net_margin_list,
          unit_sold_graph: unit_sold_list,
          avg_selling_price_graph: avg_selling_price_list,
          sell_through_graph: sell_through_list,
          markdown_graph: markdown_list,
          recovery_percentagegraph: recovery_percentagelist,
        });
      });
  }

  // MATRIC SPARK LINE MATRIC SPARK LINE 
  getSparkLineScoreTrendCard() {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/get_score_trend/";

    fetch(url, {
      method: "POST",
      body:

        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,


      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let sales_data_sparkline_list = result["sales_data_sparkline"];
        let product_data_sparkline_list = result["product_data_sparkline"];
        let customer_data_sparkline_list = result["customer_data_sparkline"];
        let overall_data_sparkline_list = result["overall_data_sparkline"];

        let sales_data_list = [];
        let product_data_list = [];
        let customer_data_list = [];
        let overall_data_list = [];

        sales_data_sparkline_list.map((info, index) => {
          try {
            let sales_data = {
              date: info['day'],
              value: info['sales_score'],
            };
            sales_data_list.push(sales_data)
          } catch (e) { }
        });

        product_data_sparkline_list.map((info, index) => {
          try {
            let product_data = {
              date: info['day'],
              value: info['product_score'],
            };
            product_data_list.push(product_data)
          } catch (e) { }
        });

        customer_data_sparkline_list.map((info, index) => {
          try {
            let customer_data = {
              date: info['day'],
              value: info['customer_score'],
            };
            customer_data_list.push(customer_data)
          } catch (e) { }
        });

        overall_data_sparkline_list.map((info, index) => {
          try {
            let overall_data = {
              date: info['day'],
              value: info['overall_score'],
            };
            overall_data_list.push(overall_data)
          } catch (e) { }
        });


        this.setState({
          is_businessscoreTrend_loaded: true,
          linesparkgraph_sales: sales_data_list,
          linesparkgraph_product: product_data_list,
          linesparkgraph_customer: customer_data_list,
          linesparkgraph_overall: overall_data_list
        });
      });
  }

  // PERFORMANCE MULTILINE CHART (DATA NOT VISIBLE)

  getPerformanceTrendMultilineChart(childData) {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;


    // const data = {
    //   filterType: childData,
    // };
    const url = API_URL + "/api/ri/peroformance-trend/";

    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let performance_trend_matrices_list = result["performance_trend"];

        let performance_value_list = [];
        let tempChildData = "";

        performance_trend_matrices_list.map((info, index) => {
          try {
            if (childData == "mix") {
              if (index == 1) tempChildData = "Mix%";
              let p = {
                month: info["day"],
                value1: info["nsv"],
                value2: info["qty"],
                value3: (info["mix"]).toFixed(2) + '%',
              };
              performance_value_list.push(p);

            } else if (childData == "nm") {
              if (index == 1) tempChildData = "Net Margin";
              let p = {
                month: info["day"],
                value1: info["nsv"],
                value2: info["qty"],
                value3: (info["netmargin"] * 100).toFixed(2) + '%',
              };
              performance_value_list.push(p);
            } else if (childData == "asp") {
              if (index == 1) tempChildData = "ASP";
              let p = {
                month: info["day"],
                value1: info["nsv"],
                value2: info["qty"],
                value3: info["asp"],
              };
              performance_value_list.push(p);
            } else if (childData == "sell-thru") {
              if (index == 1) tempChildData = "Sell Through";
              let p = {
                month: info["day"],
                value1: info["nsv"],
                value2: info["qty"],
                value3: (info["sell_through"] * 100).toFixed(2) + "%",
              };
              performance_value_list.push(p);
            } else {
              if (index == 1) tempChildData = "Markdown";
              let p = {
                month: info["day"],
                value1: info["nsv"],
                value2: info["qty"],
                value3: (info["markdown"]).toFixed(2) + "%",
              };
              performance_value_list.push(p);

            }
          } catch (e) { }
        });

        this.setState({
          is_performance_multiline_loaded: true,
          performancetrend: performance_value_list,
          thirdMetricsName: tempChildData,
        });
      });
  }

  getStoreDeepDriveBubbleChart() {
    // DATE PICKER 
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    // DROP DWON FILTER
    let asp_dropdown_value = this.state.asp_dropdown_value;
    let unit_dropdownn_value = this.state.units_dropdown_value;

    // CATEGORY FILTER
    let businessCategoryList = this.state.businessCategoryList;
    let categoryList = this.state.categoryList;
    let seasonList = this.state.seasonList;
    let stockTypeList = this.state.stockTypeList;
    let businessSubDepartmentList = this.state.businessSubDepartmentList;
    let genderList = this.state.genderList;
    let brandsList = this.state.brandsList;
    let productList = this.state.productList;
    let colourList = this.state.colourList;
    let sizeList = this.state.sizeList;
    //
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;
    const url = API_URL + "/api/ri/store-deep-dives/";

    fetch(url, {
      method: "POST",
      body:
        'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +

        "&busi_category=" + businessCategoryList +
        "&busi_sub_dept=" + businessSubDepartmentList +
        "&category=" + categoryList +
        "&season_new=" + seasonList +
        "&gender=" + genderList +
        "&product=" + productList +
        "&brand_new=" + brandsList +
        "&stock_type_new=" + stockTypeList +
        "&color_description=" + colourList +
        "&size_description=" + sizeList +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let store_attribution_bubble_list = result['store_attribution_list'];



        let store_attribution_bubble_chart_list = [];

        store_attribution_bubble_list.map((info, index) => {

          try {
            let ytemp = "y" + index.toString();
            let xtemp = "x" + index.toString();
            let valuetemp = "value" + index.toString();
            var b = "y" + (0).toString();

            let storeColor = "";
            storeColor =
              (info['nsvach']) * 100 < 70
                ? "#d51f30"
                : (info['nsvach']) * 100 < 94
                  ? "#db7823"
                  : "#038673";


            if (info['store_type'] == 'NEW') {
              let a = {
                name: info['store_name'],
                y2: (info[asp_dropdown_value]),
                x2: info[unit_dropdownn_value],
                value2: (info['nsv_mix']) + "",
                color: storeColor,
              };

              store_attribution_bubble_chart_list.push(a);
            } else if (info["store_type"] == "MATURE") {
              let a = {
                name: info['store_name'],
                y: (info[asp_dropdown_value]),
                x: info[unit_dropdownn_value],
                value1: (info['nsv_mix']) + "",
                color: storeColor,
              };
              store_attribution_bubble_chart_list.push(a);
            } else {
              let a = {
                name: info['store_name'],
                y3: (info[asp_dropdown_value]),
                x3: info[unit_dropdownn_value],
                value3: (info['nsv_mix']) + "",
                color: storeColor,
              };
              store_attribution_bubble_chart_list.push(a);
            }

          } catch (e) { }
        });



        this.setState({
          is_store_deep_bubble_chart_loaded: true,
          storedata: store_attribution_bubble_chart_list,
        });
      });
  }

  //Rupesh Deep Dive code
  handleNSVdeepDiveCallback = (childData) => {
    let DeepDiveBreadCrumb = this.state.Deep_Dive_Name;

    DeepDiveBreadCrumb.push(childData.replace('&', '~andtoreplace~'));
    this.state.Deep_Dive_Name = DeepDiveBreadCrumb;
    this.state.Deep_Dive_level = DeepDiveBreadCrumb.length;


    if (DeepDiveBreadCrumb.length <= 9) {
      this.state.is_category_deep_dive_loaded = false;

      this.getCategoryDeepDive();
      this.forceUpdate();
    } else {
      alert('Maximum level reached');
    }
  }

  //Rupesh Deep Dive code
  handleNSVdeepDiveCallback2 = (childData) => {
    let DeepDiveBreadCrumb = this.state.Deep_Dive_Name;

    var removed = DeepDiveBreadCrumb.splice(childData);

    this.state.Deep_Dive_Name = DeepDiveBreadCrumb;
    this.state.Deep_Dive_level = DeepDiveBreadCrumb.length;

    if (DeepDiveBreadCrumb.length <= 9) {
      this.state.is_category_deep_dive_loaded = false;

      this.getCategoryDeepDive();
      this.forceUpdate();
    } else {
      alert('Maximum level reached');
    }
  }
  // STORE DEEP DIVES
  shandleCallback = (childData) => {
    this.state.asp_dropdown_value = childData;
    this.setState({ is_store_deep_bubble_chart_loaded: false });
    this.getStoreDeepDriveBubbleChart();
  };
  // STORE DEEP DIVES
  dhandleCallback = (childData) => {
    this.state.units_dropdown_value = childData;
    this.setState({ is_store_deep_bubble_chart_loaded: false });

    this.getStoreDeepDriveBubbleChart();
  };
  // PERFORMANCE TREND MULTI LINE CHART
  mhandleCallback = (childData) => {
    this.state.performance_dropdown_value = childData;
    this.setState({ is_performance_multiline_loaded: false });
    //alert(childData)
    this.getPerformanceTrendMultilineChart(childData);
  };

  // MARKDOWN OPTIMIZATION
  markdownhanldecallback = (childData) => {
    this.state.is_mark_down_optimization = childData;
    this.setState({ is_mark_down_optimization: false });
    this.state.category_markdown_filter = childData;

    //alert(childData)
    this.getMarkDownOptimizationChart();
  };


  // DATE PICKER HANDLE CALL BACK 
  handleCallback = (childData) => {
    this.state.first_start_date = childData[0];
    this.state.first_end_date = childData[1];
    this.state.second_start_date = childData[2];
    this.state.second_end_date = childData[3];

    this.state.is_score_trend_loaded = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_store_deep_bubble_chart_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;

    this.getBusinessScore();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.getScoreTrendMatriceCard();
    this.getPerformanceTrendMultilineChart();
    this.getCategoryDeepDive();
    this.getStoreDeepDriveBubbleChart();
    this.getMarkDownOptimizationChart();
    this.getPromoNonPromoChart();

    this.forceUpdate();
    this.handleCallback2();
  };
  // FILTER HANLDE CALL BACK
  filterhandlerCallback = (childData) => {
    // let lstBusinessCategory = [];
    // let listCategory = [];
    // let listSeason = [];
    // let listStockType = [];
    // let listBusinessSubDepartment = [];
    // let listGender = [];
    // let listProduct = [];
    // let listBrands = [];
    // let listcolour = [];
    // let listsize = [];

    // Object.keys(childData[0]).forEach(key => {
    //   if (childData[0][key] == true) {
    //     lstBusinessCategory.push(encodeURI(key));
    //   }
    // });
    // Object.keys(childData[1]).forEach(key => {
    //   if (childData[1][key] == true) {
    //     listCategory.push(key);
    //   }
    // });
    // Object.keys(childData[2]).forEach(key => {
    //   if (childData[2][key] == true) {
    //     listSeason.push(key);
    //   }
    // });
    // Object.keys(childData[3]).forEach(key => {
    //   if (childData[3][key] == true) {
    //     listStockType.push(key);
    //   }
    // });
    // Object.keys(childData[4]).forEach(key => {
    //   if (childData[4][key] == true) {
    //     listBusinessSubDepartment.push(key);
    //   }
    // });
    // Object.keys(childData[5]).forEach(key => {
    //   if (childData[5][key] == true) {
    //     listGender.push(key);
    //   }
    // });
    // Object.keys(childData[6]).forEach(key => {
    //   if (childData[6][key] == true) {
    //     listProduct.push(key);
    //   }
    // });
    // Object.keys(childData[7]).forEach(key => {
    //   if (childData[7][key] == true) {
    //     listBrands.push(key);
    //   }
    // });
    // Object.keys(childData[8]).forEach(key => {
    //   if (childData[8][key] == true) {
    //     listcolour.push(key);
    //   }
    // });
    // Object.keys(childData[9]).forEach(key => {
    //   if (childData[9][key] == true) {
    //     listsize.push(key);
    //   }
    // });


    this.state.businessCategoryList = childData[0];
    this.state.categoryList = childData[1];
    this.state.seasonList = childData[2];
    this.state.stockTypeList = childData[3];
    this.state.businessSubDepartmentList = childData[4];
    this.state.genderList = childData[5];
    this.state.productList = childData[6];
    this.state.brandsList = childData[7];
    this.state.colourList = childData[8];
    this.state.sizeList = childData[9];



    this.state.is_score_trend_loaded = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_store_deep_bubble_chart_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;

    /// Call The Function 
    this.getBusinessScore();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.getScoreTrendMatriceCard();
    this.getPerformanceTrendMultilineChart();
    this.getCategoryDeepDive();
    this.getStoreDeepDriveBubbleChart();
    this.getMarkDownOptimizationChart();
    this.getPromoNonPromoChart();
  }


  handleCallback2 = () => {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  };

  handleScroll = () => {
    if (window.pageYOffset > 75) {
      if (!this.state.is_scroll) {
        this.setState({ is_scroll: true });
      }
    } else {
      if (this.state.is_scroll) {
        this.setState({ is_scroll: false });
      }
    }
  };

  handleMapCallback = (childData) => {


    // this.state.map_zone = childData[0].zone;
    // this.state.map_city = childData[0].city ;
    // this.state.storecode = childData[0].storecode;
    // //this.state.storename = childData[0].storename;
    // this.state.state = childData[0].state;


    // this.state.is_marketing_metrices_loaded = false;
    this.state.is_customer_metrices_loaded = false;
    // this.state.is_member_metrics_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    // this.state.is_sales_graph_loaded = false;
    // this.state.is_customer_graph_loaded = false;
    // this.state.is_marketing_graph_loaded = false;
    // this.state.is_member_graph_loaded = false;
    // this.state.is_bubble_chart_loaded = false;

    // this.getMarketingMetrices();
    this.getCustomerMetrices();
    // this.getMemberMetrics();
    this.getSalesMetrices();
    // this.getPromoBubbleChart();
    // this.getMemberlinesparkgraph();
    // this.getMarketinglinesparkgraph();
    this.forceUpdate();

  };
  // MAP FILTER HADLE CALL BACK
  handleRiMapFilterCallback = (childData) => {

    let storeCategory = [];
    let storeType = [];
    let storeSize = [];

    // Object.keys(childData[0]).forEach(key => {
    //   if (childData[0][key] == true) {
    //     storeCategory.push(key);
    //   }
    // });
    // Object.keys(childData[1]).forEach(key => {
    //   if (childData[1][key] == true) {
    //     storeType.push(key);
    //   }
    // });
    // Object.keys(childData[2]).forEach(key => {
    //   if (childData[2][key] == true) {
    //     storeSize.push(key);
    //   }
    // });
    storeCategory.push(childData[0]);
    storeType.push(childData[1]);
    storeSize.push(childData[2]);

    this.state.map_store_category = storeCategory;
    this.state.map_store_type = storeType;
    this.state.map_store_size = storeSize;

    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getMapdetails();

    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();

    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
    }

    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_coupon_initiatives_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_score_trend_loaded = false;


    this.getCategoryDeepDive();
    this.getCouponInitiatives();
    this.getMarkDownOptimizationChart();
    this.getPerformanceTrendMultilineChart();
    this.getPromoNonPromoChart();
    this.getBusinessScore();
    this.getStoreDeepDriveBubbleChart();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.state.map_filter_storesize_list = childData[3];
    this.forceUpdate();

  };

  handleRISalesSummaryIndiaMapCallBack = (childData) => {

    this.state.North_point = childData[0];
    this.state.South_point = childData[1];
    this.state.center_point = childData[2];
    this.state.map_zone = childData[3];
    this.state.map_city = childData[4];
    this.state.map_store_code = childData[5];
    this.state.map_store_name = childData[6];


    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone];
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city];
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }

    this.state.mapBreadcrumbsCenterPoint = this.state.center_point;
    this.state.mapBreadcrumbsNorthPoint = this.state.North_point;
    this.state.mapBreadcrumbsSouthPoint = this.state.South_point;

    this.state.is_score_trend_loaded = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_store_deep_bubble_chart_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;

    /// Call The Function 
    this.getBusinessScore();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.getScoreTrendMatriceCard();
    this.getPerformanceTrendMultilineChart();
    this.getCategoryDeepDive();
    this.getStoreDeepDriveBubbleChart();
    this.getMarkDownOptimizationChart();
    this.getPromoNonPromoChart();
    this.forceUpdate();
  };

  mapBreadcrumbs = (evt, index) => {

    this.state.mapBreadcrumbList.splice(index);

    // level 0
    // all zone
    if (index == 1) {
      this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_store_category = '';
      this.state.map_store_type = '';
      this.state.map_store_size = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;
      this.getMapdetails();
    }
    // level 1
    //city level
    if (index == 2) {
      // this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();

    }
    // level 3
    //Store level
    if (index == 3) {
      // this.state.map_zone = '';
      //this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();

    }

    this.state.is_businessscore_loaded = false;
    this.state.is_businessscoreTrend_loaded = false;
    this.state.is_category_deep_dive_loaded = false;
    this.state.is_coupon_initiatives_loaded = false;
    this.state.is_mark_down_optimization = false;
    this.state.is_performance_multiline_loaded = false;
    this.state.is_promo_non_promo = false;
    this.state.is_score_graph_loaded = false;
    this.state.is_score_trend_loaded = false;


    this.getCategoryDeepDive();
    this.getCouponInitiatives();
    this.getMarkDownOptimizationChart();
    this.getPerformanceTrendMultilineChart();
    this.getPromoNonPromoChart();
    this.getBusinessScore();
    this.getStoreDeepDriveBubbleChart();
    this.getSparkLineMatriceCard();
    this.getSparkLineScoreTrendCard();
    this.forceUpdate();

  }


  // BRAND DROPDOWN
  brandDropdownSelectionHandler = (e) => {
    if (e === null) {
      this.state.selectedBrandDropdownOption = "";
    } else {
      this.state.selectedBrandDropdownOption = e;
    }
    this.forceUpdate();
  };

  render() {
    const progress1 = 65; const progress2 = 25; const progress3 = 10;
    const CarouselSettings = {
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };

    return (
      <Wrapper>
        <div className="grid grid_cols_4 drawer_grid ri_summary_bsImport">
          <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
            <div className="icz-rimapsection">
              {/* {this.state.is_India_map_loaded == true ? */}
              <Wrapper>
                <Col lg={12} className="icz-rimapsectionheader">
                  <Wrapper>
                    <Wrapper>
                      <Col lg={4} className="icz-rimaptitle">
                        {this.state.country != null ? (this.state.country.toUpperCase() == "UK" ? "UK" : this.state.country.toUpperCase() == "US" ? "US" : "India") : "India"}
                      </Col>
                      <Col lg={8} className="icz-rimapfilter">
                        <RiMapFilter
                          parentCallback={this.handleRiMapFilterCallback}
                          map_filter_category_list={this.state.map_filter_category_list}
                          map_filter_storetype_list={this.state.map_filter_storetype_list}
                          map_filter_storesize_list={this.state.map_filter_storesize_list} />


                        <div className="icz-ribtnwrapper">
                          <button className="icz-rithemebtn">
                            <IconDownload className="icz-riicon" /> </button>
                        </div>
                      </Col>
                    </Wrapper>
                    <div className="breadcrumb-no-padding">
                      <Breadcrumb>
                        {this.state.mapBreadcrumbList.map((item, mapBreadcrumbListIndex) => (
                          <Breadcrumb.Item onClick={() => { this.mapBreadcrumbs(item, mapBreadcrumbListIndex + 1) }} key={mapBreadcrumbListIndex}>{item}</Breadcrumb.Item>
                        ))}
                      </Breadcrumb>
                    </div>
                  </Wrapper>
                </Col>

                <Col lg={12} className="icz-rimapcontainer">
                  {/* {this.state.is_India_map_loaded == true ?
                    <IndiaMap data={this.state.MapMarker}
                      parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                      North_point={this.state.North_point}
                      South_point={this.state.South_point}
                      Center_point={this.state.center_point}
                      map_level={this.state.map_level}
                      rangetype={this.state.rangetype}
                    />
                    : <Loader />} */}
                  {this.state.is_India_map_loaded == true ?
                    <>
                      {(() => {
                        switch (this.state.country) {
                          case "india": return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                          case "UK": return <UKMap
                            data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          ></UKMap>;
                          case "US": return <USMap
                            data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          ></USMap>;
                          default: return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                        }
                      })()}
                    </>
                    : <Loader />}
                </Col>

                <div className="icz-rimapfooter">
                  <Col lg={12}>
                    <ul className="icz-rilist-inline">
                      <li><div className="icz-rilist-bullet1" /> North</li>
                      <li><div className="icz-rilist-bullet2" /> West</li>
                      <li><div className="icz-rilist-bullet3" /> South</li>
                      <li><div className="icz-rilist-bullet4" /> East</li>
                      <li><div className="icz-rilist-bullet5" /> APTS</li>
                    </ul>
                  </Col>
                  <Col lg={12} className="icz-rimapprogressbar">
                    <h4 className="icz-riprogresstitle">NSV Achievement Percentage</h4>
                    <ProgressBar>
                      <ProgressBar className="range1" variant="range1" now={progress1} key={1} label="0-69" />
                      <ProgressBar className="range2" variant="range2" now={progress2} key={2} label="70-94" />
                      <ProgressBar className="range3" variant="range3" now={progress3} key={3} label="95+" />
                    </ProgressBar>
                  </Col>
                </div>
              </Wrapper>
              {/* : <Loader />} */}
            </div>
          </div>
          <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer" style={{ padding: "0" }}>
            <div className="content">
              <div className="content_section">
                <PageHeader pageTitle={"Business Overview"} />
                <div className="page_content">
                  <div className="grid grid_cols_1 dashboard_home">
                    <div className="grid grid_cols_1">
                      {this.state.is_businessscore_loaded == true && this.state.is_businessscore_loaded ?
                        <div className="icz-sectioncardwrapper w-100">
                          <div className="icz-sectioncard">
                            <div className="icz-ridatadetails d-flex">
                              <Col sm={12} lg={4} className="align-items-start">
                                <Col lg={12}>
                                  <div className="icz-riscorecontainer">
                                    <h3 className="icz-riscoredata">{this.state.graph_pointer_business}</h3>
                                    <p>
                                      Business
                                      <br />
                                      Score
                                    </p>
                                  </div>
                                  <SolidGaugeChart
                                    chart_id="business_score"
                                    chart_class="icz-sectionchart icz-ribscorechart"
                                    graph-data={this.state.business}
                                  />
                                </Col>
                              </Col>
                              <Col sm={12} lg={8} className="d-flex align-items-start icz-risalessection">
                                <Col lg={4} sm={12} className="icz-risalecontainer">
                                  <h4 className="icz-ri-txtblue">{this.state.graph_pointer_sale}</h4>
                                  <SolidGaugeChart
                                    chart_id="sale_score"
                                    chart_class="icz-sectionsmallchart"
                                    graph-data={this.state.sale}
                                  />
                                  <h5>Sale</h5>
                                  <p className="icz-risalesdata">
                                    Comprises of Bill size & value parameters, profit &
                                    growth.
                                  </p>
                                </Col>
                                <Col lg={4} sm={12} className="icz-risalecontainer">
                                  <h4 className="icz-ri-txtblue">{this.state.graph_pointer_customer}</h4>
                                  <SolidGaugeChart
                                    chart_id="customer_score"
                                    chart_class="icz-sectionsmallchart"
                                    graph-data={this.state.customer}
                                  />
                                  <h5>Customer</h5>
                                  <p className="icz-risalesdata">
                                    Comprises of customer frequency, retention, LTV, coupons
                                    usage & engagement form loyalty members.
                                  </p>
                                </Col>
                                <Col lg={4} sm={12} className="icz-risalecontainer">
                                  <h4 className="icz-ri-txtblue">{this.state.graph_pointer_product}</h4>
                                  <SolidGaugeChart
                                    chart_id="product_score"
                                    chart_class="icz-sectionsmallchart"
                                    graph-data={this.state.product}
                                  />
                                  <h5>Product</h5>
                                  <p className="icz-risalesdata">
                                    Comprises of sales linearity along with value contribution
                                    across sub-categories.
                                  </p>
                                </Col>
                              </Col>
                            </div>
                            <div>
                              <Col lg={12} className="icz-ridatabullet">
                                <ul className="icz-ridatalist-inline">
                                  <li><div className="icz-ridatalist-bullet4" /> Store</li>
                                  <li><div className="icz-ridatalist-bullet3" /> City</li>
                                  <li><div className="icz-ridatalist-bullet2" /> Zone</li>
                                  <li><div className="icz-ridatalist-bullet1" /> Overall</li>
                                </ul>
                              </Col>
                            </div>
                          </div>
                        </div>
                        :
                        <Loader />
                      }
                    </div>

                    <div className="grid grid_cols_1">
                      {this.state.is_businessscoreTrend_loaded == true && this.state.is_businessscoreTrend_loaded ?
                        <div className="icz-sectioncardwrapper w-100">
                          <div className="icz-sectioncard d-flex flex-wrap">
                            <div className="icz-cardheader">
                              <Col className="icz-cardtitle">Score Trend</Col>
                            </div>
                            <Col lg={12} className="icz-riscorecardwrapper">
                              <Col lg={3} sm={12} className="icz-riscorecard">
                                <div className="icz-riscorecardheader">
                                  <div className="icz-titlewrapper">
                                    <h3 className="icz-title">Overall</h3>
                                  </div>
                                </div>
                                <div className="icz-riscorecardbody">
                                  <Col lg={12} className="icz-riscoredetailscontainer">
                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">83</div>
                                        <div className="icz-risubtitle">High</div>
                                        <div className="icz-riweektitle">WK 42</div>
                                      </div>
                                    </Col>

                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">56</div>
                                        <div className="icz-risubtitle">Low</div>
                                        <div className="icz-riweektitle">WK 37</div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={12}>
                                    <Linegraph_sparkline
                                      card_id="icz-overall"
                                      card_class="icz-ricardchart"
                                      graph-data={this.state.linesparkgraph_overall}
                                      isnumber={'number'}
                                    />
                                  </Col>
                                </div>
                              </Col>

                              <Col lg={3} sm={12} className="icz-riscorecard">
                                <div className="icz-riscorecardheader">
                                  <div className="icz-titlewrapper">
                                    <h3 className="icz-title">Sale</h3>
                                  </div>
                                </div>
                                <div className="icz-riscorecardbody">
                                  <Col lg={12} className="icz-riscoredetailscontainer">
                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">83</div>
                                        <div className="icz-risubtitle">High</div>
                                        <div className="icz-riweektitle">WK 42</div>
                                      </div>
                                    </Col>

                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">56</div>
                                        <div className="icz-risubtitle">Low</div>
                                        <div className="icz-riweektitle">WK 37</div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={12}>
                                    <Linegraph_sparkline
                                      card_id="icz-sale"
                                      card_class="icz-ricardchart"
                                      graph-data={this.state.linesparkgraph_sales}
                                      isnumber={'number'}
                                    />
                                  </Col>
                                </div>
                              </Col>

                              <Col lg={3} sm={12} className="icz-riscorecard">
                                <div className="icz-riscorecardheader">
                                  <div className="icz-titlewrapper">
                                    <h3 className="icz-title">Customer</h3>
                                  </div>
                                </div>
                                <div className="icz-riscorecardbody">
                                  <Col lg={12} className="icz-riscoredetailscontainer">
                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">83</div>
                                        <div className="icz-risubtitle">High</div>
                                        <div className="icz-riweektitle">WK 42</div>
                                      </div>
                                    </Col>

                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">56</div>
                                        <div className="icz-risubtitle">Low</div>
                                        <div className="icz-riweektitle">WK 37</div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={12}>
                                    <Linegraph_sparkline
                                      card_id="icz-customer"
                                      card_class="icz-ricardchart"
                                      graph-data={this.state.linesparkgraph_customer}
                                      isnumber={'number'}
                                    />
                                  </Col>
                                </div>
                              </Col>

                              <Col lg={3} sm={12} className="icz-riscorecard">
                                <div className="icz-riscorecardheader">
                                  <div className="icz-titlewrapper">
                                    <h3 className="icz-title">Product</h3>
                                  </div>
                                </div>
                                <div className="icz-riscorecardbody">
                                  <Col lg={12} className="icz-riscoredetailscontainer">
                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">83</div>
                                        <div className="icz-risubtitle">High</div>
                                        <div className="icz-riweektitle">WK 42</div>
                                      </div>
                                    </Col>

                                    <Col lg={6} className="icz-riscorecarddetails">
                                      <div className="icz-titlewrapper">
                                        <div className="icz-riscore">56</div>
                                        <div className="icz-risubtitle">Low</div>
                                        <div className="icz-riweektitle">WK 37</div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={12}>
                                    <Linegraph_sparkline
                                      card_id="icz-product"
                                      card_class="icz-ricardchart"
                                      graph-data={this.state.linesparkgraph_product}
                                      isnumber={'number'}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </div>
                        </div>
                        :
                        <Loader />
                      }
                    </div>

                    <div className="grid grid_cols_1">
                      <div className="grid col_span_4 icz-carouselcontainer">
                        {this.state.is_score_trend_loaded == true && this.state.is_score_graph_loaded ?
                          <Carousel
                            Settings={CarouselSettings}
                            class={"icz-cardcontainer"}
                            SliderCards={[
                              <ChartCard
                                index="1"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="NSV"
                                RowOneCurrentValue={this.state.current_net_sale_value}
                                RowOnePreviousValue={this.state.last_net_sale_value}
                                RowOneTitle="overall"
                                IsNumber={'money'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-nsv"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.net_sale_value_graph}
                                    isnumber={'money'}
                                  />
                                }
                              />,

                              <ChartCard
                                index="2"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="NSV Ach%"
                                RowOneCurrentValue={
                                  parseFloat(
                                    this.state.current_nsv_ach
                                  ).toFixed(2)
                                }
                                RowOnePreviousValue={
                                  parseFloat(
                                    this.state.last_nsv_ach
                                  ).toFixed(2)
                                }
                                RowOneTitle="overall"
                                IsNumber={'percentage'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-arc"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.nsv_ach_graph}
                                    isnumber={'decimal_percentage'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="3"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Net Margin"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_net_margin
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_net_margin
                                ).toFixed(2)}
                                RowOneTitle="overall"
                                IsNumber={'percentage'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-netmargin"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.net_margin_graph}
                                    isnumber={'decimal_percentage'}
                                  />
                                }
                              />,

                              <ChartCard
                                index="4"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Bills"
                                RowOneCurrentValue={this.state.current_bills_value}
                                RowOnePreviousValue={this.state.last_bills_value}
                                RowOneTitle="overall"
                                IsNumber={'number'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-bills"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.bills_graph}
                                    isnumber={'number'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="ATS"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_ats_value
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_ats_value
                                ).toFixed(2)}
                                RowOneTitle="overall"
                                IsNumber={'money'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-ats"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.ats_graph}
                                    isnumber={'money'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Units Sold"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_unit_sold
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_unit_sold
                                ).toFixed(2)}
                                RowOneTitle="overall"
                                IsNumber={'number'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-units"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.unit_sold_graph}
                                    isnumber={'number'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="ASP"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_asp
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_asp
                                ).toFixed(2)}
                                RowOneTitle="overall"
                                IsNumber={'money'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-asp"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.avg_selling_price_graph}
                                    isnumber={'money'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Sell Through%"
                                RowOneCurrentValue={
                                  parseFloat(
                                    this.state.current_sell_through
                                  ).toFixed(2)
                                }
                                RowOnePreviousValue={
                                  parseFloat(
                                    this.state.last_sell_through
                                  ).toFixed(2)
                                }
                                RowOneTitle="overall"
                                IsNumber={'percentage'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-sellthrough"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.sell_through_graph}
                                    isnumber={'decimal_percentage_sell_through'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Markdown%"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_markdown
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_markdown
                                ).toFixed(2)}
                                RowOneTitle="overall"
                                IsNumber={'percentage'}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-markdown"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.markdown_graph}
                                    isnumber={'decimal_percentage'}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Recovery%"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_recovery
                                ).toFixed(2)}
                                IsNumber={'percentage'}
                              />,
                            ]}
                          ></Carousel>
                          :
                          <Loader />
                        }
                      </div>
                    </div>

                    <div className="grid grid_cols_1">
                      <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard d-flex flex-row flex-wrap">
                          <Col sm={12} className="icz-cardheader flex-wrap">
                            <Col className="icz-cardtitle">Performance Trend</Col>
                            <Col className="icz-cardfilter flex-row flex-wrap">
                              <Dropdown
                                dropdown_options={this.state.dropdown_options}
                                dropdown_placeholder="Markdown"
                                parentCallback={this.mhandleCallback}
                              />
                              <ChartExport ChartId='performance_trend' exportName="Performance Trend"
                                export_href={this.state.export_href} downloadName="Performance-Trend.csv"
                                export_data={this.state.performancetrend}
                              />
                            </Col>
                          </Col>
                          {this.state.is_performance_multiline_loaded == true ?
                            <Col lg={12} className="">
                              <MultiLineMultiAxesChart
                                chart_id="performance_trend"
                                chart_class="icz-sectionchart"
                                graph-data={this.state.performancetrend}
                                metric_1={"NSV"}
                                metric_2={"Units Sold"}
                                metric_3={this.state.thirdMetricsName}
                                isnumber={'money'}
                              />

                            </Col>
                            :
                            <Loader />
                          }
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_1">
                      <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">Category NSV Deep-dive</Col>
                            <Col className="icz-cardfilter flex-row flex-wrap">
                              <ChartExport ChartId='icz-treemap' exportName="Category NSV Deep-dive"
                                export_href={this.state.export_href} downloadName="Category-NSV-Deep-dive.csv"
                                export_data={this.state.category_deep_dive_graph}
                              />
                            </Col>
                          </Col>
                          {this.state.is_category_deep_dive_loaded == true ?
                            <>
                              <div className="">
                                <RiTreegraph card_id="icz-treemap" card_class="icz-sectionchart"
                                  graph-data={this.state.category_deep_dive_graph}
                                  parentCallback={this.handleNSVdeepDiveCallback}
                                  Level_Counter={this.state.Deep_Dive_level}
                                  parentCallback2={this.handleNSVdeepDiveCallback2}
                                  Deep_Dive_BreadCrumb={this.state.Deep_Dive_Name}
                                  dropdownValue={this.state.category_dropdown_value}
                                  isnumber={'money'}
                                />


                              </div>
                              <div>
                                <Col lg={12} className="icz-ridatabullet">
                                  <ul className="icz-ridatalist-inline">
                                    <li>Area = NSV</li>
                                    <li>
                                      <div className="icz-ridatalist-bullet5" /> High ASP
                                    </li>
                                    <li>
                                      <div className="icz-ridatalist-bullet2" /> Med ASP
                                    </li>
                                    <li>
                                      <div className="icz-ridatalist-bullet6" /> Low ASP
                                    </li>
                                  </ul>
                                </Col>
                              </div>
                            </>
                            :
                            <Loader />
                          }
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_1">
                      <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                          <Col lg={12} className="icz-cardheader flex-wrap" style={{ "justify-content": "space-between" }}>
                            <div class="icz-cardtitle" style={{ width: "25%" }}>Store Deep-dives</div>
                            <div className="icz-cardfilter flex-row flex-wrap" style={{ width: "75%" }}>
                              <Dropdown
                                dropdown_options={this.state.asp_dropdown_options}
                                dropdown_placeholder="ASP"
                                parentCallback={this.shandleCallback}
                              />
                              <Dropdown
                                dropdown_options={this.state.units_dropdown_options}
                                dropdown_placeholder="Units Sold"
                                parentCallback={this.dhandleCallback}
                              />
                              <ChartExport ChartId='store-data' exportName="Store-Deep-dives"
                                export_href={this.state.export_href} downloadName="Store-Deep-dives.csv"
                                export_data={this.state.storedata}
                              />
                            </div>
                          </Col>
                          {this.state.is_store_deep_bubble_chart_loaded == true ?
                            <>
                              <Col lg={12} className="">
                                <RiStoreBubbleChart
                                  chart_id="store-data"
                                  chart_class="icz-sectionchart"
                                  Xtitle={this.state.asp_dropdown_value.replace('_', ' ').toUpperCase()}
                                  Ytitle={this.state.units_dropdown_value.replace('_', ' ').toUpperCase()}
                                  metric_1={"Mature Store"}
                                  metric_2={"New Store"}
                                  metric_3={"LTL Store"}
                                  graph-data={this.state.storedata}
                                  isnumber={'money'}
                                />
                              </Col>
                              <div className="">
                                <Col lg={12} className="d-flex">
                                  <Col lg={6} className="icz-ridatabullet">
                                    <ul className="icz-ridatalist-inline">
                                      <li>
                                        <div className="icz-ridatalist-bullet5" /> High NSV%
                                      </li>
                                      <li>
                                        <div className="icz-ridatalist-bullet2" /> Med NSV%
                                      </li>
                                      <li>
                                        <div className="icz-ridatalist-bullet6" /> Low NSV%
                                      </li>
                                    </ul>
                                  </Col>
                                  <Col lg={6} className="icz-ridatabullet">
                                    <ul className="icz-ridatalist-inline">
                                      <li>
                                        <div className="icz-ridatalist-bullet7" /> New Store
                                      </li>
                                      <li>
                                        <div className="icz-ridatalist-bullet8" /> Mature
                                        Store
                                      </li>
                                      <li>
                                        <div className="icz-ridatalist-bullet9" /> LTL Store
                                      </li>
                                    </ul>
                                  </Col>
                                </Col>
                              </div>
                            </>
                            :
                            <Loader />
                          }
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_1">
                      <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">
                              Markdown Optimization
                            </Col>
                            <Col className="icz-cardfilter flex-row flex-wrap">
                              <Dropdown
                                dropdown_options={this.state.markdownopt_dropdown_options}
                                dropdown_placeholder="All"
                                parentCallback={this.markdownhanldecallback}
                              />
                              <ChartExport ChartId='markdown-optimization' exportName="Markdown Optimization"
                                export_href={this.state.export_href} downloadName="Markdown-Optimization.csv"
                                export_data={this.state.optimization_graph}
                              />
                            </Col>
                          </Col>

                          <div className="">
                            {this.state.is_mark_down_optimization == true ?
                              <RiHeatMap
                                chart_id="markdown-optimization"
                                chart_class="icz-sectionchart"
                                graph-data={this.state.optimization_graph}
                              />
                              :
                              <Loader />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.is_promo_non_promo_new == true ?
                      <div className="grid grid_cols_1">
                        <div className="icz-sectioncardwrapper w-100">
                          <div className="icz-sectioncard">
                            <Col sm={12} className="icz-cardheader">
                              <Col className="icz-cardtitle">
                                Promo vs Non Promo Markdown Optimization
                              </Col>
                              <Col className="icz-cardfilter flex-row flex-wrap">
                                <Dropdown
                                  dropdown_options={this.state.category_markdown_filteroptions}
                                  dropdown_placeholder="Promo"
                                  parentCallback={this.handleCallback}
                                />
                              </Col>
                            </Col>
                            <div className="">

                              <RiHeatMap
                                chart_id="promo_markdown-optimization"
                                chart_class="icz-sectionchart"
                                graph-data={this.state.promo_non_promo_graph}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      ''
                    }

                    <div className="grid grid_cols_1" id="coupon_initiative">
                      <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                          <Col lg={12} className="icz-cardheader  flex-wrap">
                            <Col className="icz-cardtitle">
                              Coupon Initiatives
                            </Col>
                            <Col className="icz-cardfilter flex-row flex-wrap">
                              <ChartExport ChartId='coupon_initiative' exportName="Coupon Initiatives"
                                export_href={this.state.export_href} downloadName="Coupon Initiatives.csv"
                                export_data={[
                                  {
                                    label: "Targeted Counts",
                                    value: this.state.target_count
                                  },
                                  {
                                    label: "Redemption Counts",
                                    value: this.state.redemption_count
                                  },
                                  {
                                    label: "Response Rate %",
                                    value: "0.20%"
                                  },
                                  {
                                    label: "Sale from Redeemers",
                                    value: this.state.sales_from_redeemers
                                  },
                                  {
                                    label: "ATS",
                                    value: "2500"
                                  },
                                  {
                                    label: "Biz Contriution %",
                                    value: "2%"
                                  },
                                  {
                                    label: "Bill contribution %",
                                    value: "2%"
                                  },
                                  {
                                    label: "Comm cost",
                                    value: this.state.comm_cost
                                  },
                                  {
                                    label: "Discount cost",
                                    value: this.state.discount_cost
                                  },
                                  {
                                    label: "Incremental biz",
                                    value: this.state.increamental_biz
                                  },
                                ]}
                              />
                            </Col>
                          </Col>
                          <div className="grid grid_cols_1">
                            <div className="grid col_span_4  icz-carouselcontainer">
                              {this.state.is_coupon_initiatives_loaded == true ?
                                <Carousel
                                  Settings={CarouselSettings}
                                  class={"icz-cardcontainer"}
                                  SliderCards={[
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.target_count}
                                      CardSubTitle="Targeted Counts"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.redemption_count}
                                      CardSubTitle="Redemption Count"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue="0.20%"
                                      CardSubTitle="Response Rate %"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.sales_from_redeemers}
                                      CardSubTitle="Sale from Redeemers"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue="2500"
                                      CardSubTitle="ATS"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue="2%"
                                      CardSubTitle="Biz Contriution %"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue="2%"
                                      CardSubTitle="Bill contribution %"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.comm_cost}
                                      CardSubTitle="Comm cost"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.discount_cost}
                                      CardSubTitle="Discount cost"
                                    />,
                                    <RiCouponCard
                                      index="1"
                                      CardClass="icz-ricouponcardwrapper"
                                      RowOneCurrentValue={this.state.increamental_biz}
                                      CardSubTitle="Incremental biz"
                                    />,
                                  ]}
                                ></Carousel>
                                :
                                <Loader />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_4 icz-row">
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard">
                          <div className="icz-cardheader">
                            <div className="icz-cardtitle">
                              Customer Persona for Categories
                            </div>
                            <div className="icz-cardfilter flex-row flex-wrap">
                              <ChartExport ChartId='customer-persona' exportName="Customer Persona for Categories"
                                export_href={this.state.export_href} downloadName="Customer-Persona-for-Categories.csv"
                              />
                            </div>
                          </div>
                          <div className="">
                            <RadarChart
                              chart_id="customer-persona"
                              chart_class="icz-ricategorychart"
                              graph-data={this.state.customerpersona}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard">
                          <div className="icz-cardheader">
                            <div className="icz-cardtitle">Active Loyalists</div>
                          </div>
                          <div className="">
                            <div>
                              <h6 className="icz-categorytitle">Prefered Categories</h6>
                              <RiCategoryHBarChart
                                chart_id="categories"
                                chart_class="icz-categorysectionchart"
                                Ytitle={"NSV"}
                                graph-data={this.state.categoriesData}
                                bar_color={"#11A1FD"}
                              />
                            </div>
                            <div>
                              <h6 className="icz-categorytitle">Prefered Brands</h6>
                              <RiCategoryHBarChart
                                chart_id="brands"
                                chart_class="icz-categorysectionchart"
                                Ytitle={"NSV"}
                                graph-data={this.state.brandData}
                                bar_color={"#11A1FD"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Wrapper>
    );
  }
}

function ReduxStateToProps(state) {
  return {
    start_date: state.date.startDate,
    end_date: state.date.endDate,
    previous_start_date: state.date.prevStartDate,
    previous_end_date: state.date.prevEndDate,
    country: state.userData.user.country_code
  };
}

export default connect(ReduxStateToProps)(BusinessOverview)