import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import { COKE_COMPANY_ID } from "../../../../constants/constants";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import { DataFormatters } from "../../../../utils/Dataformatters";

const SocialInstagramPage = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Instagram"
  let pagePrimaryTab = "Page"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const userData = useSelector((state) => state.userData.user);

  let companyID = userData?.company?.id;

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PROFILE VIEWS
  const [profileViews, setProfileViews] = useState([]);
  const [profileViewsLabels, setProfileViewsLabels] = useState([]);
  const [isProfileViewsLoading, setProfileViewsIsLoading] = useState(false);
  const [isProfileViewsLoaded, setProfileViewsIsLoaded] = useState(false);
  const [profileViewsError, setProfileViewsError] = useState(null);

  // FOLLOWERS GROWTH
  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [followersGrowthLabels, setFollowersGrowthLabels] = useState([]);
  const [isFollowersGrowthLoading, setFollowersGrowthIsLoading] = useState(false);
  const [isFollowersGrowthLoaded, setFollowersGrowthIsLoaded] = useState(false);
  const [followersGrowthError, setFollowersGrowthError] = useState(null);


  // PAGE REACH 
  const [pageReach, setPageReach] = useState([])
  const [pageReachLabels, setPageReachLabels] = useState([]);
  const [isPageReachLoading, setPageReachIsLoading] = useState(false);
  const [isPageReachLoaded, setPageReachIsLoaded] = useState(false);
  const [pageReacherror, setPageReachError] = useState(null);

  // DROPDOWN OPTIONS
  const pageReachOptions = [
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
  ];

  // DROPDOWN STATE
  const [pageReachDropdownValue, setPageReachDropdownValue] = useState(pageReachOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageReachSelectionHandler = (e) => {
    if (e === null) {
      setPageReachDropdownValue("");
    } else {
      setPageReachDropdownValue(e);
    }

  };



  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchProfileViewsHandler();
    fetchFollowersGrowthHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/metrics-card/",
        config
      );
      setMetricCards(res.data === undefined || res.data.length === 0 ? [] : res.data.data === undefined || res.data.data.length === 0 ? [] :
        res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>

      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="instagram"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS
  const [isPageViewsOpen, setIsPageViewsOpen] = useState(false)

  let defaultPostImpressionsDisabledvalues = [];

  if (companyID === COKE_COMPANY_ID) {
    defaultPostImpressionsDisabledvalues = []
  } else {
    defaultPostImpressionsDisabledvalues = ["Previous"]
  }

  const [pageViewsDisabledvalues, setPageViewsDisabledvalues] = useState(defaultPostImpressionsDisabledvalues)


  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchProfileViewsHandler = async () => {
    setProfileViewsIsLoading(true);
    setProfileViewsIsLoaded(false);
    setProfileViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/profile-views/",
        config
      );

      setProfileViews(res.data === undefined || res.data.length === 0 ? [] : res.data.data === undefined || res.data.data.length === 0 ? [] : res.data.data);
      setProfileViewsLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
          res.data.dimension);
      setProfileViewsIsLoading(false);
      setProfileViewsIsLoaded(true);
      setProfileViewsError(null);
    } catch (error) {
      setProfileViews([]);
      setProfileViewsLabels([]);
      setProfileViewsIsLoading(false);
      setProfileViewsIsLoaded(false);
      setProfileViewsError(error.code);
    }
  };

  let profileViewsLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  let profileViewsLineChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isProfileViewsLoaded && !isProfileViewsLoading) {
    if (
      NoDataAvailableChecker(profileViews) ||
      NoDataAvailableChecker(profileViewsLabels)
    ) {
      profileViewsLineChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      profileViewsLineChart2 = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        profileViewsLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="profile_views"
                chartClass="section_card_chart"
                chartData={profileViews}
                chartLabels={profileViewsLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={pageViewsDisabledvalues}
                setDisabledValues={setPageViewsDisabledvalues}
              />
            </ErrorBoundary></Wrapper>
        );

        profileViewsLineChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="profile_views_2"
                chartClass="section_card_chart"
                chartData={profileViews}
                chartLabels={profileViewsLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={pageViewsDisabledvalues}
                setDisabledValues={setPageViewsDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        profileViewsLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="profile_views"
                chartClass="section_card_chart"
                chartData={profileViews}
                chartLabels={profileViewsLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary></Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (profileViewsError) {
    profileViewsLineChart = (
      <Wrapper><ServerErrorsLoader chartType="lineChartType" error={profileViewsError} /></Wrapper>
    );
    profileViewsLineChart2 = (
      <Wrapper><ServerErrorsLoader chartType="lineChartType" error={profileViewsError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isProfileViewsLoading) {
    profileViewsLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    profileViewsLineChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE GROWTH

  const [isFollowerGrowthOpen, setIsFollowerGrowthOpen] = useState(false)

  let defaultFollowerGrowthDisabledvalues = [];

  if (companyID === COKE_COMPANY_ID) {
    defaultFollowerGrowthDisabledvalues = []
  } else {
    defaultFollowerGrowthDisabledvalues = ["Previous"]
  }

  const [followerGrowthDisabledvalues, setFollowerGrowthDisabledvalues] = useState(defaultFollowerGrowthDisabledvalues)


  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchFollowersGrowthHandler = async () => {
    setFollowersGrowthIsLoading(true);
    setFollowersGrowthIsLoaded(false);
    setFollowersGrowthError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/followers-growth/",
        config
      );

      setFollowersGrowth(
        res.data === undefined || res.data.length === 0 ? [] : res.data.data === undefined || res.data.data.length === 0 ? [] :
          res.data.data);
      setFollowersGrowthLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
          res.data.dimension);
      setFollowersGrowthIsLoading(false);
      setFollowersGrowthIsLoaded(true);
      setFollowersGrowthError(null);
    } catch (error) {
      setFollowersGrowth([]);
      setFollowersGrowthLabels([]);
      setFollowersGrowthIsLoading(false);
      setFollowersGrowthIsLoaded(false);
      setFollowersGrowthError(error.code);
    }
  };

  let followersGrowthLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  let followersGrowthLineChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isFollowersGrowthLoaded && !isFollowersGrowthLoading) {
    if (
      NoDataAvailableChecker(followersGrowth) ||
      NoDataAvailableChecker(followersGrowthLabels)
    ) {
      followersGrowthLineChart = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      followersGrowthLineChart2 = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        followersGrowthLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="followers_growth"
                chartClass="section_card_chart"
                chartData={followersGrowth}
                chartLabels={followersGrowthLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={followerGrowthDisabledvalues}
                setDisabledValues={setFollowerGrowthDisabledvalues}
              />
            </ErrorBoundary></Wrapper>
        );

        followersGrowthLineChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="followers_growth_2"
                chartClass="section_card_chart"
                chartData={followersGrowth}
                chartLabels={followersGrowthLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={followerGrowthDisabledvalues}
                setDisabledValues={setFollowerGrowthDisabledvalues}
              />
            </ErrorBoundary></Wrapper>
        );

      } else {
        followersGrowthLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="followers_growth"
                chartClass="section_card_chart"
                chartData={followersGrowth}
                chartLabels={followersGrowthLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary></Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (followersGrowthError) {
    followersGrowthLineChart = (
      <Wrapper><ServerErrorsLoader
        chartType="lineChartType"
        error={followersGrowthError}
      /></Wrapper>
    );

    followersGrowthLineChart2 = (
      <Wrapper><ServerErrorsLoader
        chartType="lineChartType"
        error={followersGrowthError}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFollowersGrowthLoading) {
    followersGrowthLineChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
    followersGrowthLineChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE REACH
  const [isPageReachOpen, setIsPageReachOpen] = useState(false)

  let defaultPageReachDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPageReachDisabledvalues = []
  } else {
    defaultPageReachDisabledvalues = []
  }

  const [pageReachDisabledvalues, setPageReachDisabledvalues] = useState(defaultPageReachDisabledvalues)

  // TO FETCH PAGE Reach DATA FROM API

  useEffect(() => {
    const fetchPageReachHandler = async () => {
      setPageReachIsLoading(true);
      setPageReachIsLoaded(false);
      setPageReachError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          time_period: pageReachDropdownValue?.value ?? null
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("social/instagram/organic/lifetime-reach/", config);

        const allData = res?.data?.data ?? [];
        allData.sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year;
          }
          return a[pageReachDropdownValue.value] - b[pageReachDropdownValue.value];
        });


        var monthData = allData.map(({ month, year, profile_reach }) => ({
          month: `${month + '/' + year}`,
          reach: profile_reach
        }))

        var weekData = allData.map(({ week, year, profile_reach }) => ({
          week: `${year + " Week " + week}`,
          reach: profile_reach
        }))


        const dataLabel = pageReachDropdownValue.label === 'Monthly' ? 'month' : 'week';
        const unwantedValues = ['week', 'month', 'quarter', 'year', 'label']
        const dropdownArray = []

        let formattedData = DataFormatters("linechart", pageReachDropdownValue.label === 'Monthly' ? monthData : weekData, dataLabel, dropdownArray, unwantedValues)


        setPageReach(formattedData?.data ?? []);
        setPageReachLabels(formattedData?.dimension ?? []);
        setPageReachIsLoading(false);
        setPageReachIsLoaded(true);
        setPageReachError(null);
      } catch (error) {
        setPageReach([]);
        setPageReachLabels([]);
        setPageReachIsLoading(false);
        setPageReachIsLoaded(false);
        setPageReachError(error);
      }
    };

    fetchPageReachHandler()
  }, [BRAND_ID, selectedDates, pageReachDropdownValue])

  let pageReachLineChart = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>
  let pageReachLineChart2 = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isPageReachLoaded && !isPageReachLoading) {
    if (
      NoDataAvailableChecker(pageReach) ||
      NoDataAvailableChecker(pageReachLabels)
    ) {
      pageReachLineChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      pageReachLineChart2 = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      if (isMultiLineCluster) {

        pageReachLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_reach"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageReachDisabledvalues}
              setDisabledValues={setPageReachDisabledvalues}
            />
          </ErrorBoundary>
        );

        pageReachLineChart2 = (
          <ErrorBoundary chartType="lineChartType">
            <PartitionedLineChart
              chartId="social_facebook_page_reach_2"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageReachDisabledvalues}
              setDisabledValues={setPageReachDisabledvalues}
            />
          </ErrorBoundary>
        );

      } else {
        pageReachLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_Reach"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageReacherror) {
    pageReachLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
      </Wrapper>
    );

    pageReachLineChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageReachLoading) {
    pageReachLineChart = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
    pageReachLineChart2 = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>

  }

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Cards": metricCards,
    "SocialIGPage_Profile Views": profileViews,
    "SocialIGPage_Followers Growth": followersGrowth,
    [`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`]: pageReach
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [metricCards, profileViews, followersGrowth, pageReach]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (profileViewsError !== null ? true : isProfileViewsLoaded && (isProfileViewsLoading === false)) &&
      (pageReacherror !== null ? true : isPageReachLoaded && (isPageReachLoading === false)) &&
      (followersGrowthError !== null ? true : isFollowersGrowthLoaded && (isFollowersGrowthLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    profileViewsError, isProfileViewsLoaded, isProfileViewsLoading,
    pageReacherror, isPageReachLoaded, isPageReachLoading,
    followersGrowthError, isFollowersGrowthLoaded, isFollowersGrowthLoading,
  ])




  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="profile_views_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Profile Views</h2>
              <SectionDefinationsBadge
                title="Profile Views"
                module="social"
                platform="instagram"
                section="page"
              />
            </div>
            <Modal
              open={isPageViewsOpen}
              setOpen={setIsPageViewsOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"profile_views_2"}
                  sectionName={"SocialIGPage_Profile Views"}
                  sectionData={profileViews}
                />}
              parentId={"profile_views_section"}
              title={`Profile Views`}
              chart={profileViewsLineChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPageViewsOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"profile_views_section"}
                sectionName={"SocialIGPage_Profile Views"}
                sectionData={profileViews}
              />
            </div>
          </div>

          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {profileViewsLineChart}
            </div>
          </div>
        </div>
        <div
          id="followers_growt_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Followers Growth</h2>
              <SectionDefinationsBadge
                title="Followers Growth"
                module="social"
                platform="instagram"
                section="page"
              />
            </div>

            <Modal
              open={isFollowerGrowthOpen}
              setOpen={setIsFollowerGrowthOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"followers_growth_2"}
                  sectionName={"SocialIGPage_Followers Growth"}
                  sectionData={followersGrowth}
                />}
              parentId={"followers_growt_section"}
              title={`Followers Growth`}
              chart={followersGrowthLineChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsFollowerGrowthOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                sectionId={"followers_growt_section"}
                sectionName={"SocialIGPage_Followers Growth"}
                sectionData={followersGrowth}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {followersGrowthLineChart}
            </div>
          </div>
        </div>


      </div>
      <div className="grid grid_cols_4 ">
        <div id="page_Reach_section" className="grid col_span_4 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Page Reach
              </h2>
              <SectionDefinationsBadge
                title="Page Reach"
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <Modal
              open={isPageReachOpen}
              setOpen={setIsPageReachOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"social_facebook_page_reach_2"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`}
                  sectionData={pageReach}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={pageReachOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={pageReachDropdownValue}
                  selectedOptions={pageReachDropdownValue}
                  setStatedropdown={pageReachSelectionHandler}
                />
              }
              parentId={"page_Reach_section"}
              title={`Page Reach`}
              chart={pageReachLineChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPageReachOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={pageReachOptions}
                className="form_dropdown section_dropdown ml_20"
                value={pageReachDropdownValue}
                selectedOptions={pageReachDropdownValue}
                setStatedropdown={pageReachSelectionHandler}
              />
              <ExportDropdown
                sectionId={"page_Reach_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`}
                sectionData={pageReach}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageReachLineChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialInstagramPage;
