import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Col from "react-bootstrap/esm/Col";
import IconDownArrow from '../../../../assets/images/icons/downArrow'
import { API_URL } from '../../../helpers/constant';

export default class RiMapFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // category_list: ['Metro', 'Non-Metro'],
            // storetype: ['New', 'LTL', 'Mature'],
            storesize: ["11000-23667", "23668-47334", "47335-60000"],//['11000', '23811', '60000'],
            showMessage: false,
            category_list_Checked: {},
            storetype_list_Checked: {},
            storesize_list_Checked: [],
            map_filter_category_list: this.props.map_filter_category_list,
            map_filter_storetype_list: this.props.map_filter_storetype_list,
            map_filter_storesize_list: this.props.map_filter_storesize_list,
            storeSizeArray: [],
            // map_filter_storesize_list:[]
        }
    }

    componentDidMount() {
        if (this.state.map_filter_storesize_list.length == 0) {
            this.getStoreSize();
        }

        this.forceUpdate();
    }

    getStoreSize() {
        const url = API_URL + "/api/ri/storesize/";

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        })
            .then((res) => res.json())
            .then((result) => {

                let map_filter_storesize_list = [];

                for (let i = 0; i < result.length; i++) {
                    let a = {
                        min: result[i]['min'],
                        max: result[i]['max'],
                        display: result[i]['display'],
                        name: result[i]['name'],
                        checked: false,
                    };
                    map_filter_storesize_list.push(a)
                }
                this.setState({
                    map_filter_storesize_list: map_filter_storesize_list,
                    is_map_filter_storesize_list_loaded: true,
                })
            },
                (error) => { })
    }

    _showMessage = (bool) => {
        this.setState({
            showMessage: bool
        });
    }

    // onClickRiMapFilter = () => {
    //     const e = this.state.category_list_Checked;
    //     const f = this.state.storetype_list_Checked;
    //     const g = this.state.storesize_list_Checked;
    //     this.props.parentCallback([e, f, g]);
    //     this.onClickCloseMapFilter();

    // }
    onClickRiMapFilter = () => {
        const e = this.state.map_filter_category_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name;
        });

        const f = this.state.map_filter_storetype_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name;
        });

        let arr = [];
        const x = this.state.map_filter_storesize_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            arr.push(i.min);
            arr.push(i.max);
            return i;
        });
        if (x.length > 0) {
            let minStoreSize = Math.min.apply(Math, arr);
            let maxStoreSize = Math.max.apply(Math, arr);
            let selectedStoreSize = [];
            selectedStoreSize.push(minStoreSize);
            selectedStoreSize.push(maxStoreSize);

            let state = this.state;
            state.storesize_list_Checked = selectedStoreSize;
            this.setState(state);
        }
        const g = this.state.storesize_list_Checked;

        this.props.parentCallback([e, f, g, this.state.map_filter_storesize_list]);
        this.onClickCloseMapFilter();


    }

    onClickCloseMapFilter = () => {
        this.setState({
            showMessage: false
        });
    }

    // clearMapFilter_old = () => {
    //     document.querySelectorAll(".icz-rifiltercheckbox").forEach(element => {
    //         if (element.checked === true) {
    //             element.checked = false;
    //         } else {
    //             element.checked = false;
    //         }
    //     })

    //     this.state.category_list_Checked = {};
    //     this.state.storetype_list_Checked = {};
    //     this.state.storesize_list_Checked = [];

    //     // this.onClickRiMapFilter();
    //     this.onClickCloseMapFilter();
    // }

    clearMapFilter = () => {
        this.state.map_filter_category_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.map_filter_storetype_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.map_filter_storesize_list.map(function (i) {
            i.checked = false;
            return i;
        });

        this.onClickCloseMapFilter();
    }

    // handleCategoryCheckbox_old = event => {
    //     let state = this.state;
    //     state.category_list_Checked[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }

    handleCategoryCheckbox = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.map_filter_category_list[id].checked = event.target.checked;
        this.setState(state);
    }

    // handleStoreTypeCheckbox_old = event => {
    //     let state = this.state;
    //     state.storetype_list_Checked[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }

    handleStoreTypeCheckbox = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.map_filter_storetype_list[id].checked = event.target.checked;
        this.setState(state);
    }

    // handleStoreSizeCheckbox_old = event => {

    //     this.state.storeSizeArray.push(event.target.min);
    //     this.state.storeSizeArray.push(event.target.max);

    //     let minStoreSize = Math.min.apply(Math, this.state.storeSizeArray);
    //     let maxStoreSize = Math.max.apply(Math, this.state.storeSizeArray);

    //     let selectedStoreSize = [];

    //     selectedStoreSize.push(minStoreSize);
    //     selectedStoreSize.push(maxStoreSize);

    //     let state = this.state;
    //     state.storesize_list_Checked = selectedStoreSize;
    //     this.setState(state);

    // }

    handleStoreSizeCheckbox = event => {
        if (event.target.checked == true) {
            const id = event.target.getAttribute("dataid")

            this.state.storeSizeArray.push(event.target.min);
            this.state.storeSizeArray.push(event.target.max);

            let minStoreSize = Math.min.apply(Math, this.state.storeSizeArray);
            let maxStoreSize = Math.max.apply(Math, this.state.storeSizeArray);

            let selectedStoreSize = [];

            selectedStoreSize.push(minStoreSize);
            selectedStoreSize.push(maxStoreSize);

            let state = this.state;
            state.storesize_list_Checked = selectedStoreSize;
            state.map_filter_storesize_list[id].checked = event.target.checked;
            this.setState(state);

        }
        else if (event.target.checked == false) {
            const id = event.target.getAttribute("dataid")


            this.state.storeSizeArray = this.state.storeSizeArray.filter(item => item !== event.target.min)
            this.state.storeSizeArray = this.state.storeSizeArray.filter(item => item !== event.target.max)

            let selectedStoreSize = [];
            let minStoreSize = 0;
            let maxStoreSize = 0;
            if (this.state.storeSizeArray.length != 0) {
                minStoreSize = Math.min.apply(Math, this.state.storeSizeArray);
                maxStoreSize = Math.max.apply(Math, this.state.storeSizeArray);

                selectedStoreSize.push(minStoreSize);
                selectedStoreSize.push(maxStoreSize);
            }

            let state = this.state;
            state.storesize_list_Checked = selectedStoreSize;
            state.map_filter_storesize_list[id].checked = event.target.checked;
            this.setState(state);
        }

    }

    render() {
        return (
            <div>
                <button className="icz-rimapbtnfilter" onClick={this._showMessage.bind(null, true)}>Filter <IconDownArrow className="icz-btnfiltericon" /></button>
                {this.state.showMessage && (
                    <div className="icz-rimapfiltercontainer">
                        <div className="icz-rimapfilterdatacontainer">
                            <div className="icz-rimapfilterdata">
                                <h5>Category</h5>
                                {this.state.map_filter_category_list.map((item, index) => (
                                    <lable key={index}
                                        className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox"
                                            type="checkbox"
                                            value={item.name}
                                            onChange={this.handleCategoryCheckbox}
                                            checked={item.checked}
                                            dataid={index}
                                        /> {item.name}
                                    </lable>
                                ))}
                            </div>
                            {/* <div className="icz-rimapfilterdata">
                                <h5>Category</h5>
                                {this.state.category_list.map(item => (
                                    <lable key={item} className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox" type="checkbox" value={item} onChange={this.handleCategoryCheckbox} /> {item}</lable>
                                ))}
                            </div> */}

                            <div className="icz-rimapfilterdata">
                                <h5>Store Type</h5>
                                {/* {this.state.storetype.map(item => (
                                    <lable key={item} className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox" type="checkbox" value={item} onChange={this.handleStoreTypeCheckbox} /> {item}</lable>
                                ))} */}

                                {this.state.map_filter_storetype_list.map((item, index) => (
                                    <lable key={index}
                                        className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox"
                                            type="checkbox"
                                            value={item.name}
                                            onChange={this.handleStoreTypeCheckbox}
                                            checked={item.checked}
                                            dataid={index}
                                        /> {item.name}
                                    </lable>
                                ))}
                            </div>

                            <div className="icz-rimapfilterdata">
                                <h5>Store Size</h5>
                                {/* {this.state.map_filter_storesize_list.map(item => (
                                    <lable key={item} className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox" type="checkbox"
                                            value={item.name}
                                            min={item.min}
                                            max={item.max}
                                            onChange={this.handleStoreSizeCheckbox}
                                            checked={item.checked}
                                        /> {item.name}
                                    </lable>
                                ))} */}

                                {this.state.map_filter_storesize_list.map((item, index) => (
                                    <lable key={index}
                                        className="icz-rimapfilterlabel">
                                        <input className="icz-rifiltercheckbox"
                                            type="checkbox"
                                            value={item.name}
                                            min={item.min}
                                            max={item.max}
                                            onChange={this.handleStoreSizeCheckbox}
                                            checked={item.checked}
                                            dataid={index}
                                        /> {item.name}
                                    </lable>
                                ))}
                            </div>
                        </div>
                        <div className='icz-row d-flex justify-content-between'>
                            <div className="icz-btnwrapper">
                                <button className="icz-deletebtn" onClick={this.onClickCloseMapFilter}>Close</button>
                            </div>
                            <div className='icz-row d-flex justify-content-end'>
                                <div className="icz-btnwrapper">
                                    <button className="icz-btnsecondary" onClick={() => this.clearMapFilter()}>Clear</button>
                                </div>
                                <div className="icz-btnwrapper">
                                    <button className="icz-themebtn" onClick={() => this.onClickRiMapFilter()}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        );
    }
}
