import { useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORE
import AuthContext from "../../store/authContext";

// COMPONENTS
import Wrapper from "../../components/helpers/Wrapper";
import Slider from "../../components/ui/Slider";
import Button from "../../components/ui/Button";
import ComparePostCard from "../../components/ui/ComparePostCard";
import axios from "../helpers/axios";
import ExportDropdown from "./dropdown/ExportDropdown";
import SectionDefinationsBadge from "./SectionDefinationsBadge";

// CHARTS
import DonutPieChart from "../../components/ui/charts/DonutPieChart";

// LOADERS
import NoDataAvailableLoader from "./loaders/NoDataAvailable";
import ServerErrorsLoader from "./loaders/ServerErrosLoader";
import Loader from "./loaders/Loader";

// UTILS
import NumberFormatter from "../../utils/NumberFormatter";
import ErrorBoundary from "../../utils/ErrorBoundary";
// import NumberSeparator from "../../utils/NumberSeparator";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";

// CONSTANTS
import {
  DefaultPostImage,
  DonutChartColorSet,
  DonutChartColorSet2,
  DonutChartColorSet3,
} from "../../constants/constants";


const CompareTvBrands = ({
  products,
  Title,
  isPaid,
  isSales,
  social_platform,
  start_date,
  end_date,
  hasExportDropdown,
  exportHeaders,
  mapZone,
  mapStateCode,
  mapMarket,
  isTVHaleon
}) => {
  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence"
  let pageSubModule = "Tv"

  const [productsList, setProductsList] = useState(products);
  // let top_5_products = productsList.slice(0, 5);

  const [isComapreBtnDisabled, setIsComapreBtnDisabled] = useState(true);

  // const [selectedProducts, setSelectedProducts] = useState([]);

  const [productsListTitle, setProductsListTitle] = useState("top_5");

  const [selectedProductsToCompare, setSelectedProductsToCompare] = useState(
    []
  );
  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // const [noOfElements, setNoOfElements] = useState(4);

  const [postIdentity, setPostIdentity] = useState([]);

  const [paidData, setPaidData] = useState([]);

  // const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  // const [viewMoreClicked, setViewMoreClicked] = useState("");

  // const [agePIEItemList, setAgePieItemList] = useState([]);
  // const [is_agegroup_loaded, isagegroupLoaded] = useState(false);

  // const [genderPIEItemList, setGenderPieItemList] = useState([]);
  // const [is_gender_loaded, isGenderLoaded] = useState(false);

  // const [devicePIEItemList, setDevicePieItemList] = useState([]);
  // const [is_device_loaded, isDeviceLoaded] = useState(false);

  const [gprsGenre, setGprsGenre] = useState([]);
  const [gprsGenreLoading, setGprsGenreLoading] = useState(false);
  const [gprsGenreLoaded, setGprsGenreLoaded] = useState(false);
  const [gprsGenreError, setGprsGenreError] = useState(null);

  const [gprsTime, setGprsTime] = useState([]);
  const [gprsTimeLoading, setGprsTimeLoading] = useState(false);
  const [gprsTimeLoaded, setGprsTimeLoaded] = useState(false);
  const [gprsTimeError, setGprsTimeError] = useState(null);

  const [gprsPnp, setGprsPnp] = useState([]);
  const [gprsPnpLoading, setGprsPnpLoading] = useState(false);
  const [gprsPnpLoaded, setGprsPnpLoaded] = useState(false);
  const [gprsPnpError, setGprsPnpError] = useState(null);

  const [gprsPremiumNonPremium, setGprsPremiumNonPremium] = useState([]);
  const [gprsPremiumNonPremiumLoading, setGprsPremiumNonPremiumLoading] = useState(false);
  const [gprsPremiumNonPremiumLoaded, setGprsPremiumNonPremiumLoaded] = useState(false);
  const [gprsPremiumNonPremiumError, setGprsPremiumNonPremiumError] = useState(null);

  const [gprsAdDuration, setGprsAdDuration] = useState([]);
  const [gprsAdDurationLoading, setGprsAdDurationLoading] = useState(false);
  const [gprsAdDurationLoaded, setGprsAdDurationLoaded] = useState(false);
  const [gprsAdDurationError, setGprsAdDurationError] = useState(null);

  const [programWiseGrp, setProgramWiseGrp] = useState([]);
  const [programWiseGrpLoading, setProgramWiseGrpLoading] = useState(false);
  const [programWiseGrpLoaded, setProgramWiseGrpLoaded] = useState(false);
  const [programWiseGrpError, setProgramWiseGrpError] = useState(null);


  const scollToRef = useRef();


  let filtered = [];
  let newSelectedProductsToCompare = [];

  // TO HANDLE THE CHECKBOX SELECTION
  const checkboxEventHandler = (childData, event) => {
    filtered = productsList.filter((a) => a.isChecked === true);

    if (postIdentity.includes(childData.id)) {
      let index = postIdentity.indexOf(childData.id);
      postIdentity.splice(index, 1);
    } else {
      postIdentity.push(childData.id);
    }

    setPostIdentity([...postIdentity]);

    if (
      paidData.some(
        (paidData) =>
          paidData.is_company === childData.is_company &&
          paidData.id === childData.brand_id &&
          paidData.name === childData.brand_name
      )
    ) {
      paidData.splice(
        paidData.findIndex((a) => a.id === childData.brand_id), 1
      );
      setPaidData([...paidData]);
    } else {
      paidData.push({
        is_company: childData.is_company,
        id: childData.brand_id,
        name: childData.brand_name
      });
      setPaidData([...paidData]);
    }

    if (filtered.length <= 2 || event.target.checked == false) {
      event.target.checked === true
        ? (childData.isChecked = true)
        : (childData.isChecked = false);

      if (filtered.length > 1 && event.target.checked == true) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = true);
        });
      } else if (filtered.length >= 3 && event.target.checked == false) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = false);
        });
      }
      setProductsList([...productsList]);
    }

    // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
    if (filtered.length > 0 && event.target.checked == true) {
      setIsComapreBtnDisabled(false);
    } else if (filtered.length <= 2 && event.target.checked == false) {
      setIsComapreBtnDisabled(true);
    }
  };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompare = () => {
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
    fetchGprsGenreHandler();
    fetchGprsTimeHandler();
    fetchGprsPnpHandler();
    fetchGprsPremiumNonPremiumHandler();
    fetchGprsAdDurationHandler();
    isTVHaleon && fetchProgramWiseGrpHandler();
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 500);
  };

  // TO CLOSE THE COMPARE SECTION
  const closeCompare = () => {
    setSelectedProductsToCompare((selectedProductsToCompare.length = 0));
    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setPaidData([]);
    setProductsList([...productsList]);
  };

  // TO AUTO ADD AND AUTO REMOVE PRODUCTS FROM COMPARE
  useEffect(() => {
    if (selectedProductsToCompare.length > 0) {
      MainCompare();
    }
  }, [productsList]);


  const fetchGprsGenreHandler = async () => {
    setGprsGenreLoading(true);
    setGprsGenreLoaded(false);
    setGprsGenreError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-genre/", config);
      setGprsGenre(res.data);
      setGprsGenreLoading(false);
      setGprsGenreLoaded(true);
      setGprsGenreError(null);
    } catch (error) {
      setGprsGenre([]);
      setGprsGenreLoading(false);
      setGprsGenreLoaded(false);
      setGprsGenreError(error.code);
    }
  };

  let gprsGenreChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsGenreLoaded && !gprsGenreLoading) {
    if (NoDataAvailableChecker(gprsGenre)) {
      gprsGenreChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader chartType="donutChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsGenreChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsGenre).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                {/*  <ErrorBoundary> */}
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ?

                      <NoDataAvailableLoader chartType="donutChartType" />
                      :
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie1" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet}
                      />
                    }
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
                {/* </ErrorBoundary> */}
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsGenreError) {
    gprsGenreChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsGenreError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsGenreLoading) {
    gprsGenreChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS TIME

  const fetchGprsTimeHandler = async () => {
    setGprsTimeLoading(true);
    setGprsTimeLoaded(false);
    setGprsTimeError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-time/", config);

      setGprsTime(res.data);
      setGprsTimeLoading(false);
      setGprsTimeLoaded(true);
      setGprsTimeError(null);
    } catch (error) {
      setGprsTime([]);
      setGprsTimeLoading(false);
      setGprsTimeLoaded(false);
      setGprsTimeError(error.code);
    }
  };

  let gprsTimeChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsTimeLoaded && !gprsTimeLoading) {
    if (NoDataAvailableChecker(gprsTime)) {
      gprsTimeChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader chartType="donutChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsTimeChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsTime).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ?

                      <NoDataAvailableLoader chartType="donutChartType" />
                      :
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie2" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet2}
                      />
                    }
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsTimeError) {
    gprsTimeChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader chartType="donutChartType" error={gprsTimeError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsTimeLoading) {
    gprsTimeChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }


  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS TIME
  const fetchGprsPnpHandler = async () => {
    setGprsPnpLoading(true);
    setGprsPnpLoaded(false);
    setGprsPnpError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-time/", config);

      setGprsPnp(res.data);
      setGprsPnpLoading(false);
      setGprsPnpLoaded(true);
      setGprsPnpError(null);
    } catch (error) {
      setGprsPnp([]);
      setGprsPnpLoading(false);
      setGprsPnpLoaded(false);
      setGprsPnpError(error.code);
    }
  };

  let gprsPnpChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsPnpLoaded && !gprsPnpLoading) {
    if (NoDataAvailableChecker(gprsPnp)) {
      gprsPnpChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsPnpChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsPnp).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet3}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsPnpError) {
    gprsPnpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsPnpError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsPnpLoading) {
    gprsPnpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }


  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS PREMIUM NON PREMIUM
  const fetchGprsPremiumNonPremiumHandler = async () => {
    setGprsPremiumNonPremiumLoading(true);
    setGprsPremiumNonPremiumLoaded(false);
    setGprsPremiumNonPremiumError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-premium-non-premium/", config);

      setGprsPremiumNonPremium(res.data);
      setGprsPremiumNonPremiumLoading(false);
      setGprsPremiumNonPremiumLoaded(true);
      setGprsPremiumNonPremiumError(null);
    } catch (error) {
      setGprsPremiumNonPremium([]);
      setGprsPremiumNonPremiumLoading(false);
      setGprsPremiumNonPremiumLoaded(false);
      setGprsPremiumNonPremiumError(error.code);
    }
  };

  let gprsPremiumNonPremiumChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsPremiumNonPremiumLoaded && !gprsPremiumNonPremiumLoading) {
    if (NoDataAvailableChecker(gprsPremiumNonPremium)) {
      gprsPremiumNonPremiumChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsPremiumNonPremiumChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsPremiumNonPremium).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet3}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsPremiumNonPremiumError) {
    gprsPremiumNonPremiumChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsPremiumNonPremiumError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsPremiumNonPremiumLoading) {
    gprsPremiumNonPremiumChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS AD DURATION
  const fetchGprsAdDurationHandler = async () => {
    setGprsAdDurationLoading(true);
    setGprsAdDurationLoaded(false);
    setGprsAdDurationError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-ad-duration/", config);

      setGprsAdDuration(res.data);
      setGprsAdDurationLoading(false);
      setGprsAdDurationLoaded(true);
      setGprsAdDurationError(null);
    } catch (error) {
      setGprsAdDuration([]);
      setGprsAdDurationLoading(false);
      setGprsAdDurationLoaded(false);
      setGprsAdDurationError(error.code);
    }
  };

  let gprsAdDurationChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsAdDurationLoaded && !gprsAdDurationLoading) {
    if (NoDataAvailableChecker(gprsAdDuration)) {
      gprsAdDurationChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsAdDurationChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsAdDuration).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsAdDurationError) {
    gprsAdDurationChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsAdDurationError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsAdDurationLoading) {
    gprsAdDurationChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_1">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }


  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // PROGRAM WISE GRPS
  const fetchProgramWiseGrpHandler = async () => {
    setProgramWiseGrpLoading(true);
    setProgramWiseGrpLoaded(false);
    setProgramWiseGrpError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-ad-duration/", config);

      setProgramWiseGrp(res.data);
      setProgramWiseGrpLoading(false);
      setProgramWiseGrpLoaded(true);
      setProgramWiseGrpError(null);
    } catch (error) {
      setProgramWiseGrp([]);
      setProgramWiseGrpLoading(false);
      setProgramWiseGrpLoaded(false);
      setProgramWiseGrpError(error.code);
    }
  };

  let programWiseGrpChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (programWiseGrpLoaded && !programWiseGrpLoading) {
    if (NoDataAvailableChecker(programWiseGrp)) {
      programWiseGrpChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      programWiseGrpChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(programWiseGrp).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data === [] || data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (programWiseGrpError) {
    programWiseGrpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={programWiseGrpError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (programWiseGrpLoading) {
    programWiseGrpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_1">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  const exportProductsData = products.length > 0 ? products.map(a => ({ ...a })) : [];
  const exportSelectedProductsData = selectedProductsToCompare.length > 0 ? selectedProductsToCompare.map(a => ({ ...a })) : [];

  return (
    <Wrapper>
      <div id={Title} className="grid grid_cols_1 section_card_header">
        {!isSales &&
          <h2 className="grid col_span_2 section_card_title">
            {productsListTitle === "top_5" && "Top Brands "}
          </h2>
        }
        <div className="grid col_span_2 flex justify_end">
          <div
            className="btn_wrapper width_fit_content flex"
            style={{ width: "150px" }}
          >
            <Button
              handleClick={() => MainCompare()}
              disabled={isComapreBtnDisabled}
              className="primary_btn"
              type="button"
            >
              Compare
            </Button>
            {hasExportDropdown && (
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  sectionId={Title}
                  sectionName={(isTVHaleon ? "TvHaleonCompare_Top " : "TvCompare_Top ") + Title}
                  sectionData={exportProductsData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {productsList.length > 0 ? (
        <Wrapper>
          {productsListTitle === "top_5" && (
            <div className="card_wrapper grid_padding">
              <Slider
                sliderTitle={""}
                slidestoshow={3}
                breakpoints={{
                  0: {
                    slidesPerView: "auto",
                  },
                  426: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 3,
                  },
                  2100: {
                    slidesPerView: 3,
                  },
                }}
              >
                {productsList.map((post, index) => (
                  <SwiperSlide key={index}>
                    <ComparePostCard
                      postData={post}
                      checkboxEventHandler={checkboxEventHandler}
                      // viewMoreOnclickHandler={viewMoreOnclickHandler}
                      tvHaleon={true}

                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          )}

          {/* FOR SELECTED POSTS TO COMPARE */}
          {selectedProductsToCompare.length > 1 && (
            <Wrapper>
              <div ref={scollToRef} className="grid grid_cols_1 grid_padding grid_margin_bottom compare_sec">
                <div className="section_card fullpage_section_card grid bgBorderTransparent">
                  <div className="section_card_header">
                    <div className="section_info">    <h2 className="section_card_title">Compare Results
                    </h2>
                      {!isTVHaleon && <SectionDefinationsBadge
                        title={"Compare Table"}
                        module="tv"
                        platform="overall"
                        section="overall"
                      />}
                    </div>
                    <div className="btn_wrapper flex" style={{ width: "150px" }}>
                      <Button
                        handleClick={() => closeCompare()}
                        className="secondary_btn"
                        type="button"
                      >
                        Close
                      </Button>

                      {hasExportDropdown && (
                        <div data-html2canvas-ignore={true}>
                          <ExportDropdown
                            moduleName={pageModule}
                            subModuleName={pageSubModule}
                            sectionId={Title}
                            sectionName={(isTVHaleon ? "TvHaleonCompare_Top " : "TvCompare_Top ") + Title}
                            sectionData={exportSelectedProductsData}

                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="section_card_body brand-comp-table">
                    <div className="section_card_table_wrapper">
                      <div className="grid grid_cols_3  grid_margin_bottom">
                        {selectedProductsToCompare.map((product, index) => (
                          <div key={index} className="grid grid_cols_1 card_border_right">
                            <div className="table_wrapper">
                              <table className="table table_bordered wrapped-table">
                                <tbody>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="tableCard_wrap">
                                        <img
                                          src={
                                            product.brand_logo === "" ||
                                              product.brand_logo === undefined ||
                                              product.brand_logo === null
                                              ? DefaultPostImage
                                              : product.brand_logo
                                          }
                                          alt="img"
                                          onError={(e) =>
                                            (e.target.src = DefaultPostImage)
                                          }
                                          className="tableCard_img"
                                        />

                                      </div>
                                    </td>
                                  </tr>
                                  {product.metrics_data.map((metric, index) => (
                                    <tr key={index}>
                                      <td className="">
                                        <span className="bold">{metric.label}</span>
                                      </td>
                                      <td className="align_right value_td">

                                        <ErrorBoundary>
                                          {/*   <NumberSeparator
                                          number={metric.value}
                                          title={metric.label}
                                        /> */}

                                          <NumberFormatter
                                            title={metric.label}
                                            number={metric.value}

                                          />
                                        </ErrorBoundary>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}



                        {/*  <table className="table table_bordered">
                          <tbody>
                            <tr>
                              <td className="col_md_10">
                                <em className="bold">Image</em>
                              </td>
                              {selectedProductsToCompare.map((el, index) => (
                                <td
                                  className="col_md_2 align_right value_td"
                                  key={index}
                                >
                                  <img
                                    src={
                                      // el.image.match(
                                      //   "https://scontent.fbom23-1.fna.fbcdn.net/"
                                      // ) ||
                                      el.brand_logo === "" ||
                                        el.brand_logo === undefined ||
                                        el.brand_logo === null
                                        ? DefaultPostImage
                                        : el.brand_logo
                                    }
                                    onError={(e) => (e.target.src = DefaultPostImage)}
                                  />
                                </td>
                              ))}
                            </tr>
                            {Object.keys(
                              selectedProductsToCompare[0]["metrics_data"]
                            ).map((el, index) => (
                              <tr key={index}>
                                <td className="col-md-10">
                                  <em className="bold">
                                    {
                                      selectedProductsToCompare[0][
                                      "metrics_data"
                                      ][el]["label"]
                                    }
                                  </em>
                                </td>
                                {selectedProductsToCompare.map((info, wer) => (
                                  <td
                                    key={wer}
                                    className="col-md-2 align_right value_td"
                                  >
                                    <em>
                                      <ErrorBoundary>
                                        <NumberSeparator
                                          number={
                                            info["metrics_data"][el]["value"]
                                          }
                                          title={info["metrics_data"][el]["label"]}
                                        />
                                      </ErrorBoundary>
                                    </em>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isPaid && (
                <div className="grid grid_cols_1 grid_padding grid_margin_bottom card_border_top">
                  <div className=" compareCard_barchart grid_margin_bottom ">
                    <div className="section_info">  <h2 className="section_card_title grid_margin_bottom">
                      TV Genre with High GRPs
                    </h2>  <SectionDefinationsBadge
                        title={"Tv Genre with High GRP"}
                        module="tv"
                        platform="overall"
                        section="overall"
                      />
                    </div>
                    {gprsGenreChart}
                  </div>

                  <div className=" compareCard_genderSplit grid_margin_bottom card_border_top">
                    <div className="section_info">   <h2 className="section_card_title grid_margin_bottom">
                      GRPs by PT/ Non PT
                    </h2> <SectionDefinationsBadge
                        title={"GRP's by PT/Non PT"}
                        module="tv"
                        platform="overall"
                        section="overall"
                      />
                    </div>
                    {gprsTimeChart}
                  </div>
                  <div className=" compareCard_genderSplit grid_margin_bottom card_border_top">
                    <h2 className="section_card_title grid_margin_bottom">
                      Premium & Non Premium  GRPs

                    </h2>
                    {gprsPremiumNonPremiumChart}
                  </div>
                  <div className="compareCard_genderSplit grid_margin_bottom card_border_top">
                    <h2 className="section_card_title grid_margin_bottom">
                      Ad Duration Wise GRPs
                    </h2>
                    {gprsAdDurationChart}
                  </div>
                  {isTVHaleon && <div className="compareCard_genderSplit grid_margin_bottom card_border_top">
                    <h2 className="section_card_title grid_margin_bottom">
                      Program Wise GRPs
                    </h2>
                    {programWiseGrpChart}
                  </div>}
                </div>
              )}
            </Wrapper>
          )}
        </Wrapper>
      ) : (
        <div className="grid-cols-1">
          <div className="grid grid-cols-1 table-content">
            <div className="flex justify_center">
              <h4>No Data Available</h4>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CompareTvBrands;
