import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as  am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);


export default class Sunbrust_chart extends Component {


  componentDidMount() {
    let info = this.props

    // let card_name = info['name'];
    let card_id = info['card_id'];
    let data = info['graph-data'];

    let chart = am4core.create(card_id, am4plugins_sunburst.Sunburst);
    chart.padding(0, 0, 0, 0);
    chart.radius = am4core.percent(98);

    
    var colorSet = new am4core.ColorSet();
    colorSet.list = ["#db7823","#ff9931", "#ffb866",  "#06a57d", "#07c180", "#3cd795", "#4259d4", "#5a75f9", "#8298fb", "#0c7dd9", "#11a1fd", "#4cc3fd"].map(function (color) {
      return new am4core.color(color);
    });
    chart.colors = colorSet;

    chart.data = data;
    // chart.colors.step = 12;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(20);

    chart.legend = new am4charts.Legend();

    chart.legend.labels.template.maxWidth = 150;

    chart.legend.paddingBottom = 0;
    chart.legend.paddingTop = 0;
    chart.legend.paddingLeft = 0;
    chart.legend.paddingRight = 0;
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontFamily = "Ubuntu";
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
    chart.legend.valueLabels.template.disabled = true; //value label hide

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";


    let level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
    level0SeriesTemplate.hiddenInLegend = false;
    chart.seriesTemplates.setKey("0", level0SeriesTemplate)

    // this makes labels to be hidden if they don't fit
    level0SeriesTemplate.labels.template.truncate = true;
    level0SeriesTemplate.labels.template.hideOversized = true;
    level0SeriesTemplate.showOnInit = false;
    level0SeriesTemplate.usePercentHack = false;

    level0SeriesTemplate.radius = am4core.percent(100);
    level0SeriesTemplate.innerRadius = am4core.percent(-10);
    level0SeriesTemplate.alignLabels = false;
    level0SeriesTemplate.labels.template.text = "";
    level0SeriesTemplate.tooltip.getFillFromObject = false;
    level0SeriesTemplate.tooltip.background.fill = am4core.color("#213345");
    level0SeriesTemplate.tooltip.background.stroke = am4core.color("#213345");
    level0SeriesTemplate.tooltip.label.fill = am4core.color("#ffffff");
    level0SeriesTemplate.tooltip.label.fontSize = 12;
   

    let selectedState = level0SeriesTemplate.states.create("selected");
    selectedState.properties.opacity = 0.7;
    level0SeriesTemplate.defaultState.properties.radius = am4core.percent(100);

    let currentlySelected;

    level0SeriesTemplate.slices.template.events.on("over", function (event) {
      if (event.target.dataItem.sunburstDataItem.children) {
        event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      }
    })

    level0SeriesTemplate.slices.template.events.on("hit", function (event) {
      zoomOutButton.show();
      let hitSlice = event.target;

      if (hitSlice.dataItem.sunburstDataItem.children) {

        let series = event.target.dataItem.component;

        if (!series.dummyData) {
          // series.tooltip.disabled = true;
          hitSlice.dataItem.label.radius = (hitSlice.radius - hitSlice.pixelInnerRadius) - 7;
          hitSlice.dataItem.label.bent = true;
          hitSlice.dataItem.label.rotation = -180;

          currentlySelected = hitSlice;
          series.dummyData = true;
          series.setState("selected");
          hitSlice.dataItem.sunburstDataItem.series.show();
          series.slices.each(function (slice) {
            if (slice != event.target) {
              slice.dataItem.hide();
            }
          })
        }
        else {
          drillUp(hitSlice);
        }
      }
    })


    level0SeriesTemplate.labels.template.adapter.add("rotation", function (rotation, target) {
      target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
      target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);
      return rotation;
    })

    let level1SeriesTemplate = level0SeriesTemplate.clone();
    level1SeriesTemplate.hidden = true;
    level1SeriesTemplate.innerRadius = am4core.percent(10);
    chart.seriesTemplates.setKey("1", level1SeriesTemplate)
    level1SeriesTemplate.fillOpacity = 0.75;


    level1SeriesTemplate  = chart.seriesTemplates.create("1");
    level1SeriesTemplate.slices.template.fillOpacity = 0.75;
    level1SeriesTemplate.hiddenInLegend = true;


    let level2SeriesTemplate = level0SeriesTemplate.clone();
    level2SeriesTemplate.hidden = true;
    level2SeriesTemplate.innerRadius = am4core.percent(20);
    chart.seriesTemplates.setKey("2", level2SeriesTemplate)

    let zoomOutButton = chart.seriesContainer.createChild(am4core.ZoomOutButton);
    zoomOutButton.visible = false;
    zoomOutButton.horizontalCenter = "middle";
    zoomOutButton.verticalCenter = "middle";
    zoomOutButton.events.on("hit", function () {
      drillUp(currentlySelected)
    })


    function drillUp(slice) {
      collapse(slice);
      let series = slice.dataItem.component;
      // series.tooltip.disabled = false;
      series.dummyData = false;
      series.setState("default");

      series.slices.each(function (slice) {
        if (slice != Event.target) {
          slice.dataItem.show();
        }
      })

      if (series.parentDataItem.seriesDataItem) {
        currentlySelected = series.parentDataItem.seriesDataItem.slice;
      }
      else {
        zoomOutButton.hide();
      }
    }

    function collapse(slice) {

      slice.dataItem.label.bent = false;
      slice.dataItem.label.radius = 10;


      if (slice.dataItem.sunburstDataItem.children) {
        slice.dataItem.sunburstDataItem.children.each(function (child) {
          child.seriesDataItem.component.setState("hidden");
          collapse(child.seriesDataItem.slice);
        })
      }
    }

    

  }
  render() {
    let card_class = this.props.card_class;
    let card_id = this.props.card_id;
    return (
      <div>
        <div id={card_id} className={card_class}></div>
      </div>
    )
  }
}



