import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart500, DonutChartColorSet, USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";

am5.addLicense("AM5C258896422");

const MultiAxesBarChart = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showBullets,
  onBulletsClick,
  chartColorSet,
  showVerticalLabels,
  minGridDistance
}) => {

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);

    let minimumGridDistanceValue = null

    if (minGridDistance === undefined) {
      minimumGridDistanceValue = null
    } else {
      minimumGridDistanceValue = minGridDistance
    }

    let colorSet = DonutChartColorSet;

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    const convertPrefixes = (name) => {
      const tonnesMetrics = ["Monthly Sales Vol."];
      const percentMetrics = ["Monthly Sales Vol. % Change", "TV Spends % Change"];


      if (percentMetrics.includes(name)) {
        return "%"
      } else if (tonnesMetrics.includes(name)) {
        return " T"
      } else {
        return ""
      }
    }

    const addCurrencySymbols = (data) => {
      const currencyMetrics = ["TV Spends"];
      if (currencyMetrics.includes(data)) {
        return country.currencySymbol
      }
      else {
        return ""
      }
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    var data = chartData

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: minimumGridDistanceValue,
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    })

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    })

    {
      showVerticalLabels &&
        xRenderer.labels.template.setAll({
          rotation: -45,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15,
        });
    }

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400"
    });

    xAxis.data.setAll(data);

    function createSeries(name, field, childLabel, childValue, index) {
      var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: (index % 2) != 0 ? false : true,
        stroke: am5.color(colorSet[index]),
        strokeWidth: 0.3,
        strokeOpacity: 1,
      });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // logarithmic: true,
          treatZeroAs: 0.000001,
          extraMax: 0.1,
          maxPrecision: 0,
          // tooltipNumberFormat: "#.00",
          renderer: yRenderer,
          min: 0,
          extraMax: 0.04,
          strictMinMaxSelection: true,

        })
      );

      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: am5.color(colorSet[index]),
        fontWeight: "400",
      });

      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      }

      // to format numbers
      root.numberFormatter.setAll({
        numberFormat: "#.##a",

        // Group only into M (millions), and B (billions)
        bigNumberPrefixes: [
          { number: 1e3, suffix: "K" },
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],

        // Do not use small number prefixes at all
        smallNumberPrefixes: [],
      });

      // Add legend
      var legend = chart.children.push(
        am5.Legend.new(root, {
          // background: am5.Rectangle.new(root, {
          //     fill: am5.color(0xff5599),
          //     fillOpacity: 0.2
          // }), // to add the backgrounD to the legends
          position: "relative", // options: "absolute" / "relative"
          // width: am5.percent(100),
          // height: null,
          x: am5.percent(50),
          centerX: am5.percent(50),
          // clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
          marginTop: 15,
          // marginBottom: 15,
          layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
          layout: am5.GridLayout.new(root, {
            maxColumns: 10,
            fixedWidthGrid: true,
          }),
          verticalScrollbar: am5.Scrollbar.new(root, {
            orientation: "vertical",
          }),
        })
      );

      legend.markers.template.setAll({
        width: 13,
        height: 13,
      });

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: "400",
        fill: "#213345",
      });

      legend.labels.template.setAll({
        // maxWidth: 150,
        //centerY: 0, // if we want labels to be top-aligned
        oversizedBehavior: "wrap",
      });

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "label",
          childLabel: childLabel,
          childValue: childValue,
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
        })
      );

      series.columns.template.setAll({
        // tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        // tooltipY: 0
        cornerRadiusTL: 7,
        cornerRadiusTR: 7,
        // width: 10,
      });

      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text) {
        chart.series.each(function (series) {

          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + addCurrencySymbols(series.get("name")) + "{" + series.get("valueYField") + "}" + convertPrefixes(series.get("name")) + "[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[]●[/] " + " [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]" + addCurrencySymbols(series.get("childLabel")) + "{" + series.get("childValue") + "}" + convertPrefixes(series.get("childLabel")) + "[/]";
          }
        });
        return text;
      });

      series.data.setAll(data);
      series.appear(1000);

      chart.series.values.length === chartLabels.length && legend.data.setAll(chart.series.values);
    }


    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        label.childLabel,
        label.childValue,
        index
      );
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.zoomOutButton.set("forceHidden", true);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

MultiAxesBarChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showBullets: PropTypes.bool,
  onBulletsClick: PropTypes.func,
  chartColorSet: PropTypes.array,
  showVerticalLabels: PropTypes.bool,
  minGridDistance: PropTypes.number
};

export default MultiAxesBarChart;
