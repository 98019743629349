const IconCategory = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_719" data-name="Group 719" transform="translate(-6717 -326.284)">
                <path id="Rectangle_244" data-name="Rectangle 244" d="M2,1.5a.5.5,0,0,0-.5.5V24a.5.5,0,0,0,.5.5H24a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H24a2,2,0,0,1,2,2V24a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="translate(6717 326.284)" fill="#486f88" />
                <path id="Rectangle_245" data-name="Rectangle 245" d="M2,1.5a.5.5,0,0,0-.5.5V24a.5.5,0,0,0,.5.5H24a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H24a2,2,0,0,1,2,2V24a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="translate(6745 326.284)" fill="#486f88" />
                <path id="Rectangle_247" data-name="Rectangle 247" d="M2,1.5a.5.5,0,0,0-.5.5V24a.5.5,0,0,0,.5.5H24a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H24a2,2,0,0,1,2,2V24a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="translate(6717 354.284)" fill="#486f88" />
                <path id="Rectangle_248" data-name="Rectangle 248" d="M2,1.5a.5.5,0,0,0-.5.5V24a.5.5,0,0,0,.5.5H24a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H24a2,2,0,0,1,2,2V24a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z" transform="translate(6745 354.284)" fill="#486f88" />
            </g>
        </svg>
    )
}

export default IconCategory;