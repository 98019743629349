import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

export default class Converstion_funnel extends Component {
    componentDidMount() {

        let info = this.props

        let card_id = info['card_id'];
        let data = info['graph-data'];

        // var data = [];
        // var items = [
        // 	'ALL_VISITS', 'ADD_TO_CART', 'CHECKOUT_WITH_CART_ADDITION', 
        // 	'CHECKOUT_WITHOUT_CART_ADDITION', 'TRANSACTION', 'TRANSACTION_WITHOUT_CHECKOUT'
        // ];

        // var session_count = 0; 
        // var visit_count = 0; 
        // var transaction_count = 0; 
        // var cart_count = 0; 
        // var checkout_count = 0;

        // for (let i in data_list) {  
        //     var key_name = data_list[i]['name'];
        //     if (key_name == "ADD_TO_CART" ) {
        //         cart_count += data_list[i]['value'];
        //     } else if (key_name == "ALL_VISITS") {
        //         visit_count += data_list[i]['value'];
        //     } else if (key_name == "TRANSACTION" || key_name == "TRANSACTION_WITHOUT_CHECKOUT") {
        //         transaction_count += data_list[i]['value'];
        //     } else if (key_name == "CHECKOUT_WITH_CART_ADDITION" || key_name == "CHECKOUT_WITHOUT_CART_ADDITION") {
        //         checkout_count += data_list[i]['value'];
        //     }

        //     // 1. visit = session
        //     // 2. cart = Sessions with add to cart
        //     // 3. checkout_count = Sessions with checkout
        //     // 4. transaction_count = Session with Transactions
        // } 

        // data = [{
        //         'name': "Sessions",
        //         'value': visit_count
        //     },{
        //         'name': "Sessions with add to cart",
        //         'value': cart_count
        //     },{
        //         'name': "Sessions with checkout",
        //         'value': checkout_count
        //     },	{
        //         'name': "Session with Transactions",
        //         'value': transaction_count
        //     }];








        let chart = am4core.create(card_id, am4charts.SlicedChart);
        chart.hiddenState.properties.opacity = 0;

        chart.data = data;
        let series = chart.series.push(new am4charts.FunnelSeries());
        series.colors.step = 2;
        series.dataFields.value = "value";
        series.dataFields.category = "name";
        series.alignLabels = false;
        series.labels.template.disabled = true;
        series.orientation = "horizontal";
        series.bottomRatio = 1;


        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.stroke = am4core.color("#213345");
        series.tooltip.label.fill = am4core.color("#ffffff");
        series.tooltip.label.fontSize = 12;


        chart.legend = new am4charts.Legend();

        chart.legend.labels.template.maxWidth = 150;

        chart.legend.paddingBottom = 0;
        chart.legend.paddingTop = 0;
        chart.legend.paddingLeft = 0;
        chart.legend.paddingRight = 0;
        chart.legend.labels.template.fontSize = 12;
        // chart.legend.labels.template.fontFamily = "Ubuntu";
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.legend.valueLabels.template.disabled = true; //value label hide

        var colorSet = new am4core.ColorSet();
        colorSet.list = ["#db7823", "#ff9931", "#06a57d", "#07c180", "#4259d4", "#5a75f9", "#0c7dd9", "#11a1fd"].map(function (color) {
            return new am4core.color(color);
        });
        series.colors = colorSet;
    }


    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class} > </div>
            </div>
        )
    }
}
