export const TableHeaders = [
  { name: "Campaign Name", field: "campaignName" },
  { name: "Platform", field: "platform" },
  { name: "Impressions", field: "impressions" },
  { name: "Clicks", field: "clicks" },
  { name: "CTR (%)", field: "ctr" },
  { name: "Spends", field: "spends" },
  { name: "Conversions", field: "conversions" },
  { name: "CR (%)", field: "cr" },
];

export const TableSampleData = [
  {
    campaignName: "Campaign One",
    platform: "Google",
    impressions: 945,
    clicks: 29,
    ctr: 2.5,
    spends: 471,
    conversions: 77,
    cr: 4.15,
  },
  {
    campaignName: "Campaign Two",
    platform: "Facebook",
    impressions: 911,
    clicks: 30,
    ctr: 3.1,
    spends: 446,
    conversions: 59,
    cr: 2.45,
  },
  {
    campaignName: "Campaign Three",
    platform: "Instagram",
    impressions: 943,
    clicks: 25,
    ctr: 2,
    spends: 420,
    conversions: 41,
    cr: 7.25,
  },
  {
    campaignName: "Campaign Four",
    platform: "Twitter",
    impressions: 904,
    clicks: 38,
    ctr: 2.7,
    spends: 477,
    conversions: 63,
    cr: 6.85,
  },
  {
    campaignName: "Campaign Five",
    platform: "Linkedin",
    impressions: 917,
    clicks: 31,
    ctr: 1.9,
    spends: 459,
    conversions: 48,
    cr: 6.55,
  },
];

export const TableHeaders1 = [
  { name: "Campaign Name", field: "campaignName" },
  { name: "Profile", field: "profile_basic_details" },
  { name: "Impressions", field: "impressions" },
  { name: "Clicks", field: "clicks" },
  { name: "CTR (%)", field: "ctr" },
  { name: "Spends", field: "spends" },
  { name: "Conversions", field: "conversions" },
  { name: "CR (%)", field: "cr" },
];

export const TableSampleData1 = [
  {
    campaignName: "Campaign One",
    // platform: "Google",
    profile_details: {
      name: "Jannat",
      username: "jannat@",
      img_url:
        "https://s3-ap-southeast-1.amazonaws.com/terareach/profile_images/jannatzubair29_1620510783.0.JPEG",
    },
    impressions: 945,
    clicks: 29,
    ctr: 2.5,
    spends: 471,
    conversions: 77,
    cr: 4.15,
  },
  // {
  //   campaignName: "Campaign Two",
  //   platform: "Facebook",
  //   impressions: 911,
  //   clicks: 30,
  //   ctr: 3.1,
  //   spends: 446,
  //   conversions: 59,
  //   cr: 2.45,
  // },
  // {
  //   campaignName: "Campaign Three",
  //   platform: "Instagram",
  //   impressions: 943,
  //   clicks: 25,
  //   ctr: 2,
  //   spends: 420,
  //   conversions: 41,
  //   cr: 7.25,
  // },
  // {
  //   campaignName: "Campaign Four",
  //   platform: "Twitter",
  //   impressions: 904,
  //   clicks: 38,
  //   ctr: 2.7,
  //   spends: 477,
  //   conversions: 63,
  //   cr: 6.85,
  // },
  // {
  //   campaignName: "Campaign Five",
  //   platform: "Linkedin",
  //   impressions: 917,
  //   clicks: 31,
  //   ctr: 1.9,
  //   spends: 459,
  //   conversions: 48,
  //   cr: 6.55,
  // },
];


export var CampaignAdminHeaderData = [
  { name: "Campaign Name", field: "platform_campaign_name" },
  { name: "Brand Name", field: "brand_name" },
  { name: "Campaign Bucket", field: "bucket" },
  { name: "Objective", field: "objective" },
  { name: "Platform", field: "platform" },
  { name: "Start Date", field: "start_date" },
  { name: "End Date", field: "end_date" }
]

export var CampaignAdminData = [
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC_CFF~SSD WB Aug22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52593829",
    "start_date": "24 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~NDTV News",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52596916",
    "start_date": "22 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~SSD 27 to 95 Cities Aug to Sep22",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52614067",
    "start_date": "31 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~SSD 98 to 250 Aug to Sep22",
    "bucket": "upto 250 cities",
    "objective": "default",
    "platform_campaign_id": "52641305",
    "start_date": "30 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~SSD Base P55 Aug to Sep",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52607979",
    "start_date": "22 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q4_CP~none_CU~DTC_CFF~27to95Cities Oct22 New",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52725893",
    "start_date": "01 Oct 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q4_CP~none_CU~DTC_CFF~SSD 98to250 Cities Oct22 New",
    "bucket": "upto 250 cities",
    "objective": "default",
    "platform_campaign_id": "52713955",
    "start_date": "01 Oct 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q4_CP~none_CU~DTC_CFF~SSD Base P55 Oct22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52727534",
    "start_date": "01 Oct 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~Na_CU~DTCeCom_CFF~SSD Base Dec22 Final",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52883806",
    "start_date": "01 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~NA_CU~DTCeCom_CFF~SSD Base Nov22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52825439",
    "start_date": "03 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~Na_CU~DTCeCom_CFF~SSD CTV Plan Oct22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52773945",
    "start_date": "07 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~Na_CU~DTCeCom_CFF~SSD WB Nov22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52816369",
    "start_date": "08 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD Karnataka OTT Plan Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52885321",
    "start_date": "18 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD Karnataka YT Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52884757",
    "start_date": "05 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD Metros News plan Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52938753",
    "start_date": "16 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD South Surround Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52946914",
    "start_date": "22 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD WB News Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52936156",
    "start_date": "16 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~None_CU~DTCeCom_CFF~SSD 55+ Audience DV PG Deal",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52754915",
    "start_date": "12 Oct 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~None_CU~DTCeComNoneCom_CFF~SSD 27- 95 YT Cities Nov22",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52826460",
    "start_date": "03 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~none_CU~DTCeComNoneCom_CFF~SSD 98-250 Cities Nov22",
    "bucket": "upto 250 cities",
    "objective": "default",
    "platform_campaign_id": "52807078",
    "start_date": "03 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~none_CU~DTCeComNoneCom_CFF~SSD Karnataka OTT Plan SSD Kar OTT Nov22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52862345",
    "start_date": "19 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~none_CU~DTCeComNoneCom_CFF~SSD Kar nov22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52833528",
    "start_date": "04 Nov 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~22_MK~IN_CP~none_CU~DTCeComNoneCom_CFF~SSD WB + OTT Dec22 YT Final",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52911967",
    "start_date": "07 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~23_MK~IN_CP~na_CU~DTCeComNoneCom_CFF~SSD WB OTT Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52945475",
    "start_date": "17 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~OH-Sensodyne-AO-Digital_PR~SEN_OB~AWA_YR~23_MK~IN_CP~none_CU~DTCeComNoneCom_CFF~SSD Kar NEWS Dec22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52911830",
    "start_date": "05 Dec 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD 95 Cities Aug22_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~95 Cities",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52548579",
    "start_date": "01 Aug 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD 95 Cities July22_MK~IN_PR~SEN_OB~AWA_CTV~CTV_YQ~22Q2_CP~None_CU~DTC_CFF~95 Cities",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52504719",
    "start_date": "01 Jul 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD Base P55 July22_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC_CFF~Base P55",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52473148",
    "start_date": "05 Jul 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD News July to Aug22_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~SSD News July to Aug",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52548775",
    "start_date": "29 Jul 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD South Surround News July22_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC_CFF~SSD South Surround",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52540914",
    "start_date": "23 Jul 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "CN~SSD TN July to Aug22_MK~IN_PR~SEN_OB~AWA_CTV~NCTV_YQ~22Q3_CP~None_CU~DTC_CFF~TN July to Aug22",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52553900",
    "start_date": "29 Jul 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD 95 Cities April22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52282468",
    "start_date": "21 Apr 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD 95 Cities June22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "95 cities,95 cities campaign",
    "objective": "default",
    "platform_campaign_id": "52407630",
    "start_date": "01 Jun 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base April22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52236764",
    "start_date": "01 Apr 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base Dec21_OB~AWA_CTV~NCTV_YQ~21Q4_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "51971184",
    "start_date": "01 Jan 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base Feb22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52078376",
    "start_date": "01 Feb 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base Jan22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52038697",
    "start_date": "04 Jan 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base June22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52387447",
    "start_date": "01 Jun 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base March22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52150667",
    "start_date": "01 Mar 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD Base May22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52332666",
    "start_date": "02 May 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News April22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52240886",
    "start_date": "03 Apr 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News Dec21_OB~AWA_CTV~NCTV_YQ~21Q4_CP~None_CU~DTC",
    "bucket": "News",
    "objective": "default",
    "platform_campaign_id": "52031949",
    "start_date": "01 Jan 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News Feb to March22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52176235",
    "start_date": "04 Mar 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News Jan22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "News",
    "objective": "default",
    "platform_campaign_id": "52076247",
    "start_date": "17 Jan 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News June22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52477010",
    "start_date": "29 Jun 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD News May22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52324546",
    "start_date": "02 May 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD P55 April22_OB~AWA_CTV~NCTV_YQ~22Q2_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52226774",
    "start_date": "01 Apr 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD P55 Jan to Feb22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52082377",
    "start_date": "24 Jan 2022",
    "end_date": "09 Aug 2023"
  },
  {
    "platform": "DV360",
    "brand_name": "SENSODYNE",
    "brand": "5210853e-e3c0-4fe8-917d-9db2f3243e83",
    "platform_campaign_name": "MK~IN_PR~SEN_CN~SSD P55 Jan to Feb22_OB~AWA_CTV~NCTV_YQ~22Q1_CP~None_CU~DTC Old",
    "bucket": "",
    "objective": "default",
    "platform_campaign_id": "52015151",
    "start_date": "19 Jan 2022",
    "end_date": "09 Aug 2023"
  }

]