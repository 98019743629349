import React, { Component } from "react"
import Select from 'react-select';
import axios from 'axios'
import './dropdown.scss';
import Wrapper from "../../../helpers/wrapper";
import Variables from "../../../../../styles/mainStyle.scss";

export class Dropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdown_options: this.props.dropdown_options,
            dropdown_placeholder: this.props.dropdown_placeholder,
            isSearchable: this.props.isSearchable,
            ismulti: this.props.ismulti,
            hideDropdownIndicator: this.props.hideDropdownIndicator === undefined ? false : this.props.hideDropdownIndicator,
            customStyles: {
                control: (base, state) => ({
                    ...base,
                    flex: 1,
                    autosize: false,
                    background: Variables.light,
                    borderRadius: 7,
                    height: 40,
                    borderColor: state.isFocused ? Variables.primary500 : Variables.gray50,
                    boxShadow: state.isFocused ? null : null,
                    textColor: Variables.primary500,
                    "&:hover": {
                        borderColor: state.isFocused
                            ? Variables.primary500
                            : Variables.primary500,
                        cursor: "pointer",
                    },
                }),
                menu: (base) => ({
                    ...base,
                    autosize: false,
                    borderRadius: 7,
                    marginTop: 5,
                    zIndex: 999,
                    // width: 300,
                }),

                menuList: (base) => ({
                    ...base,
                    autosize: false,
                    fontSize: 14,
                }),

                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                        ...styles,
                        autosize: false,
                        backgroundColor: isFocused ? Variables.dropdownBg : null,
                        color: isFocused
                            ? Variables.dropdownOptionFocused
                            : Variables.dropdownOptions,
                    };
                },
            }
        }
    }

    onTrigger = (event) => {
        this.props.parentCallback(event.value);
        return false;
    }



    render() {
        return (
            <Wrapper>
                <div className="dropdown-wrapper">
                    <Select
                        isMulti={this.state.ismulti}
                        placeholder={this.state.dropdown_placeholder}
                        isSearchable={this.state.isSearchable}
                        options={this.state.dropdown_options}
                        onChange={this.onTrigger}
                        icon={this.state.icon}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={this.state.customStyles}
                    />
                </div>
            </Wrapper>
        )
    }
}


