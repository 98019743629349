import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../store/authContext";
import FullPageExportContext from "../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../components/helpers/Wrapper";
import PageHeader from "../components/layout/pageHeader";
import axios from "../components/helpers/axios";
import Card from "../components/ui/Card";
import Slider from "../components/ui/Slider";
import ExportDropdown from "../components/ui/dropdown/ExportDropdown";
import Dropdown from "../components/ui/dropdown/Dropdown";

// LOADERS
import NoDataAvailableLoader from "../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../components/ui/loaders/ServerErrosLoader";
import Loader from "../components/ui/loaders/Loader";

// CHARTS
import LineChart from "../components/ui/charts/LineChart";
import DonutPieChart from "../components/ui/charts/DonutPieChart";
import StackedColumnChart from "../components/ui/charts/StackedColumnChart";
import SankeyChart from "../components/ui/charts/SankeyChart";

// UTILS
import ErrorBoundary from "../utils/ErrorBoundary";
import NumberFormatter from "../utils/NumberFormatter";
import NoDataAvailableChecker from "../utils/NoDataAvailableChecker";

// ASSETS
import { SankeyChartSampleData } from "../data/chartsDataSamples";



import { IAEvent_Dropdown_Visit } from "../utils/IAEvents";

const Dashboard = () => {

  // PAGE CONSTANTS
  let pageModule = "Dashboard"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled, exportSummary } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // LEAD TREND
  const [leadTrendData, setLeadTrendData] = useState([]);
  const [leadTrendDataLabels, setLeadTrendDataLabels] = useState([]);
  const [isLeadTrendLoading, setLeadTrendIsLoading] = useState(false);
  const [isLeadTrendsLoaded, setLeadTrendIsLoaded] = useState(false);
  const [leadTrendError, setLeadTrendError] = useState(null);

  // SPENDS TREND
  const [spendsTrendData, setSpendsTrendData] = useState([]);
  const [spendsTrendDataLabels, setSpendsTrendDataLabels] = useState([]);
  const [isSpendsTrendLoading, setSpendsTrendIsLoading] = useState(false);
  const [isSpendsTrendsLoaded, setSpendsTrendIsLoaded] = useState(false);
  const [spendsTrendError, setSpendsTrendError] = useState(null);

  // LEAD ORIGIN
  const [leadOriginData, setLeadOriginData] = useState([]);
  const [isLeadOriginLoading, setLeadOriginIsLoading] = useState(false);
  const [isLeadOriginsLoaded, setLeadOriginIsLoaded] = useState(false);
  const [leadOriginError, setLeadOriginError] = useState(null);

  // PLATFORM WISE SPEND
  const [platformWiseSpendData, setPlatformWiseSpendData] = useState([]);
  const [platformWiseSpendDataLabels, setPlatformWiseSpendDataLabels] =
    useState([]);
  const [isPlatformWiseSpendLoading, setPlatformWiseSpendIsLoading] =
    useState(false);
  const [isPlatformWiseSpendsLoaded, setPlatformWiseSpendIsLoaded] =
    useState(false);
  const [platformWiseSpendError, setPlatformWiseSpendError] = useState(null);

  // PERFORMANCE BY SOURCE
  const [performanceBySource, setPerformanceBySource] = useState([]);
  const [
    performanceBySourceAllDropdownsData,
    setPerformanceBySourceAllDropdownsData,
  ] = useState([]);
  const [performanceBySourceDataLabels, setPerformanceBySourceDataLabels] =
    useState([]);
  const [
    performanceBySourceAllDropdownsLabels,
    setPerformanceBySourceAllDropdownsLabels,
  ] = useState([]);
  const [isPerformanceBySourceLoading, setPerformanceBySourceIsLoading] =
    useState(false);
  const [isPerformanceBySourceLoaded, setPerformanceBySourceIsLoaded] =
    useState(false);
  const [performanceBySourceerror, setPerformanceBySourceError] =
    useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceBySourceDetails, setPerformanceBySourceDetails] = useState(
    []
  );
  const [
    performanceBySourceDetailsAllDropdownsData,
    setPerformanceBySourceDetailsAllDropdownsData,
  ] = useState([]);

  const [performanceBySourceDetailsTotal, setPerformanceBySourceDetailsTotal] =
    useState([]);
  const [
    performanceBySourceDetailsTotalAllDropdownsData,
    setPerformanceBySourceDetailsTotalAllDropdownsData,
  ] = useState([]);

  // MID SECTION DROPDOWN
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({
    value: "impressions",
    label: "Impressions",
  });

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
    { value: "leads", label: "Leads" },
    { value: "app_install", label: "App Install" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Dashboard", null, null, null, "Platform Wise Metrics", e.label)

  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.clicks
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.clicks
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.clicks
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.clicks
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.conversions
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.conversions
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.conversions
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.conversions
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "engagements") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.engagements
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.engagements
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.engagements
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.engagements
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "impressions") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.impressions
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.impressions
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.impressions
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.impressions
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "app_install") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.app_install
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.app_install
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.app_install
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.app_install
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.spends
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.spends
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.spends
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.spends
            .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "leads") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.leads
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.leads
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.leads
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.leads.platform_total
      );
    } else {
      setPerformanceBySource([]);
      setPerformanceBySourceDataLabels([]);
      setPerformanceBySourceDetails([]);
      setPerformanceBySourceDetailsTotal([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption]);

  // CAMPAIGN OVERVIEW
  const [campaignOverview, setCampaignOverview] = useState([]);
  const [isCampaignOverviewLoading, setCampaignOverviewIsLoading] =
    useState(false);
  const [isCampaignOverviewLoaded, setCampaignOverviewIsLoaded] =
    useState(false);
  const [campaignOverviewerror, setCampaignOverviewError] = useState(null);

  // PLATFORM WISE METRICS
  const [platformWiseMetricsData, setPlatformWiseMetricsData] = useState([]);
  const [platformWiseMetricsDataLabels, setPlatformWiseMetricsDataLabels] =
    useState([]);
  const [isPlatformWiseMetricsLoading, setPlatformWiseMetricsIsLoading] =
    useState(false);
  const [isPlatformWiseMetricsLoaded, setPlatformWiseMetricsIsLoaded] =
    useState(false);
  const [platformWiseMetricsError, setPlatformWiseMetricsError] =
    useState(null);

  // SOCIAL OVERVIEW
  const [socialOverview, setSocialOverview] = useState([]);
  const [isSocialOverviewLoading, setSocialOverviewIsLoading] = useState(false);
  const [isSocialOverviewLoaded, setSocialOverviewIsLoaded] = useState(false);
  const [socialOverviewError, setSocialOverviewError] = useState(null);

  // PLATFORM WISE Engagements
  const [platformWiseEngagementsData, setPlatformWiseEngagementsData] =
    useState([]);
  const [
    platformWiseEngagementsDataLabels,
    setPlatformWiseEngagementsDataLabels,
  ] = useState([]);
  const [
    isPlatformWiseEngagementsLoading,
    setPlatformWiseEngagementsIsLoading,
  ] = useState(false);
  const [isPlatformWiseEngagementsLoaded, setPlatformWiseEngagementsIsLoaded] =
    useState(false);
  const [platformWiseEngagementsError, setPlatformWiseEngagementsError] =
    useState(null);

  // WEBSITE TRAFFIC OVERVIEW
  const [websiteTrafficData, setWebsiteTrafficData] = useState([]);
  const [isWebsiteTrafficDataLoaded, setWebsiteTrafficDataLoaded] =
    useState(false);
  const [isWebsiteTrafficDataLoading, setWebsiteTrafficDataLoading] =
    useState(false);
  const [websiteTrafficDataError, setWebsiteTrafficDataError] = useState();

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchLeadTrendsHandler();
    fetchSpendsTrendsHandler();
    fetchLeadOriginsHandler();
    fetchPaltformWiseSpendsHandler();
    fetchCampaignOverviewHandler();
    fetchPlatformWiseMetricsHandler();
    fetchSocialOverviewHandler();
    fetchPlatformWiseEngagementsHandler();
    fetchWebsiteTrafficOverviewHandler();

    fetchPerformanceBySourceHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // LEAD TRENDS

  // TO FETCH METRIC CARDS FROM API
  const fetchLeadTrendsHandler = async () => {
    setLeadTrendIsLoading(true);
    setLeadTrendIsLoaded(false);
    setLeadTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dashboard/leads/", config);
      setLeadTrendData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setLeadTrendDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setLeadTrendIsLoading(false);
      setLeadTrendIsLoaded(true);
      setLeadTrendError(null);
    } catch (error) {
      setLeadTrendData([]);
      setLeadTrendDataLabels([]);
      setLeadTrendIsLoading(false);
      setLeadTrendIsLoaded(false);
      setLeadTrendError(error.code);
    }
  };

  let leadTrendLineChart =
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (isLeadTrendsLoaded && !isLeadTrendLoading) {
    if (NoDataAvailableChecker(leadTrendData)) {
      leadTrendLineChart =
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
    } else {
      leadTrendLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="leadTrend"
              chartClass="section_card_chart"
              chartData={leadTrendData}
              chartLabels={leadTrendDataLabels}
            />
            {/*  <LineLoader />*/}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (leadOriginError) {
    leadTrendLineChart = (
      <Wrapper>
        <ServerErrorsLoader error={leadOriginError} chartType="lineChartType" />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isLeadTrendLoading) {
    leadTrendLineChart =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // LEAD TRENDS

  // TO FETCH METRIC CARDS FROM API
  const fetchSpendsTrendsHandler = async () => {
    setSpendsTrendIsLoading(true);
    setSpendsTrendIsLoaded(false);
    setSpendsTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dashboard/spends/", config);
      setSpendsTrendData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setSpendsTrendDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setSpendsTrendIsLoading(false);
      setSpendsTrendIsLoaded(true);
      setSpendsTrendError(null);
    } catch (error) {
      setSpendsTrendData([]);
      setSpendsTrendDataLabels([]);
      setSpendsTrendIsLoading(false);
      setSpendsTrendIsLoaded(false);
      setSpendsTrendError(error.code);
    }
  };

  let spendsTrendLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isSpendsTrendsLoaded && !isSpendsTrendLoading) {
    if (NoDataAvailableChecker(spendsTrendData)) {
      spendsTrendLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      spendsTrendLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="spendsTrend"
              chartClass="section_card_chart"
              chartData={spendsTrendData}
              chartLabels={spendsTrendDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (spendsTrendError) {
    spendsTrendLineChart = (
      <Wrapper>
        <ServerErrorsLoader error={spendsTrendError} chartType="lineChartType" />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSpendsTrendLoading) {
    spendsTrendLineChart =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // LEAD ORIGIN

  // TO FETCH METRIC CARDS FROM API
  const fetchLeadOriginsHandler = async () => {
    setLeadOriginIsLoading(true);
    setLeadOriginIsLoaded(false);
    setLeadOriginError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dashboard/platform-wise-leads/",
        config
      );
      setLeadOriginData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setLeadOriginIsLoading(false);
      setLeadOriginIsLoaded(true);
      setLeadOriginError(null);
    } catch (error) {
      setLeadOriginData([]);
      setLeadOriginIsLoading(false);
      setLeadOriginIsLoaded(false);
      setLeadOriginError(error.code);
    }
  };

  let leadOriginPieChart =
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (isLeadOriginsLoaded && !isLeadOriginLoading) {
    if (NoDataAvailableChecker(leadOriginData)) {
      leadOriginPieChart =
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
    } else {
      leadOriginPieChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"leadOrigin"}
              chartClass={"section_card_chart"}
              chartData={leadOriginData}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (leadTrendError) {
    leadOriginPieChart = (
      <Wrapper>
        <ServerErrorsLoader error={leadTrendError} chartType="donutChartType" />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isLeadOriginLoading) {
    leadOriginPieChart =
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLATFORM WISE SPEND

  // TO FETCH METRIC CARDS FROM API
  const fetchPaltformWiseSpendsHandler = async () => {
    setPlatformWiseSpendIsLoading(true);
    setPlatformWiseSpendIsLoaded(false);
    setPlatformWiseSpendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dashboard/platform-wise-spends/",
        config
      );
      setPlatformWiseSpendData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setPlatformWiseSpendDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setPlatformWiseSpendIsLoading(false);
      setPlatformWiseSpendIsLoaded(true);
      setPlatformWiseSpendError(null);
    } catch (error) {
      setPlatformWiseSpendData([]);
      setPlatformWiseSpendDataLabels([]);
      setPlatformWiseSpendIsLoading(false);
      setPlatformWiseSpendIsLoaded(false);
      setPlatformWiseSpendError(error.code);
    }
  };

  let platformWiseSpendLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isPlatformWiseSpendsLoaded && !isPlatformWiseSpendLoading) {
    if (NoDataAvailableChecker(platformWiseSpendData)) {
      platformWiseSpendLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      platformWiseSpendLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="platformWiseSpend"
              chartClass="section_card_chart"
              chartData={platformWiseSpendData}
              chartLabels={platformWiseSpendDataLabels}
              showLegend={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (platformWiseSpendError) {
    platformWiseSpendLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={platformWiseSpendError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlatformWiseSpendLoading) {
    platformWiseSpendLineChart =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OVERVIEW

  // TO FETCH DATA FROM API
  const fetchCampaignOverviewHandler = async () => {
    setCampaignOverviewIsLoading(true);
    setCampaignOverviewIsLoaded(false);
    setCampaignOverviewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/metric-card/", config);

      setCampaignOverview(SankeyChartSampleData);
      setCampaignOverviewIsLoading(false);
      setCampaignOverviewIsLoaded(true);
      setCampaignOverviewError(null);
    } catch (error) {
      setCampaignOverview([]);
      setCampaignOverviewIsLoading(false);
      setCampaignOverviewIsLoaded(false);
      setCampaignOverviewError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignOverviewChart = (
    <Wrapper>
      <Loader loaderType="sankeyChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignOverviewLoaded && !isCampaignOverviewLoading) {
    if (NoDataAvailableChecker(campaignOverview)) {
      campaignOverviewChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="sankeyChartType" />
        </Wrapper>
      );
    } else {
      campaignOverviewChart = (
        <Wrapper>
          <ErrorBoundary chartType="sankeyChartType">
            <SankeyChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={campaignOverview}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignOverviewerror) {
    campaignOverviewChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={campaignOverviewerror}
          chartType="sankeyChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignOverviewLoading) {
    campaignOverviewChart =
      <Wrapper>
        <Loader loaderType="sankeyChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLATFORM WISE METRICS

  // TO FETCH METRIC CARDS FROM API
  const fetchPlatformWiseMetricsHandler = async () => {
    setPlatformWiseMetricsIsLoading(true);
    setPlatformWiseMetricsIsLoaded(false);
    setPlatformWiseMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dashboard/platform-wise-metrics/",
        config
      );
      setPlatformWiseMetricsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setPlatformWiseMetricsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setPlatformWiseMetricsIsLoading(false);
      setPlatformWiseMetricsIsLoaded(true);
      setPlatformWiseMetricsError(null);
    } catch (error) {
      setPlatformWiseMetricsData([]);
      setPlatformWiseMetricsDataLabels([]);
      setPlatformWiseMetricsIsLoading(false);
      setPlatformWiseMetricsIsLoaded(false);
      setPlatformWiseMetricsError(error.code);
    }
  };

  let platformWiseMetricsStackedColumnChart = (
    <Wrapper>
      <Loader loaderType="stackedColumnChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isPlatformWiseMetricsLoaded && !isPlatformWiseMetricsLoading) {
    if (NoDataAvailableChecker(platformWiseMetricsData) || NoDataAvailableChecker(platformWiseMetricsDataLabels)) {
      platformWiseMetricsStackedColumnChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="stackedColumnChartType" />
        </Wrapper>
      );
    } else {
      platformWiseMetricsStackedColumnChart = (
        <Wrapper>
          <ErrorBoundary chartType="stackedColumnChartType">
            <StackedColumnChart
              chartId="platformWiseMetrics"
              chartClass="section_card_chart"
              chartData={platformWiseMetricsData}
              chartLabels={platformWiseMetricsDataLabels}
              showLegend={true}
              showLabels={true}
              tooltipMaxWidth={"auto"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (platformWiseMetricsError) {
    platformWiseMetricsStackedColumnChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={platformWiseMetricsError}
          chartType="stackedColumnChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlatformWiseMetricsLoading) {
    platformWiseMetricsStackedColumnChart =
      <Wrapper>
        <Loader loaderType="stackedColumnChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE

  // TO FETCH DATA FROM API
  const fetchPerformanceBySourceHandler = async () => {
    setPerformanceBySourceIsLoading(true);
    setPerformanceBySourceIsLoaded(false);
    setPerformanceBySourceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/metric-by-date/",
        config
      );

      setPerformanceBySource(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.data === undefined || res.data.graph_metrics.data.length === 0 ? [] :
              res.data.graph_metrics.data.impressions === undefined || res.data.graph_metrics.data.impressions.length === 0 ? [] :
                res.data.graph_metrics.data.impressions
      );
      setPerformanceBySourceDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.dimension === undefined || res.data.graph_metrics.dimension.length === 0 ? [] :
              res.data.graph_metrics.dimension.impressions === undefined || res.data.graph_metrics.dimension.impressions.length === 0 ? [] :
                res.data.graph_metrics.dimension.impressions
      );
      setPerformanceBySourceDetails(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.side_metrics === undefined || res.data.side_metrics.length === 0 ? [] :
            res.data.side_metrics.impressions === undefined || res.data.side_metrics.impressions.length === 0 ? [] :
              res.data.side_metrics.impressions
      );

      setPerformanceBySourceAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.data === undefined || res.data.graph_metrics.data.length === 0 ? [] :
              res.data.graph_metrics.data
      );

      setPerformanceBySourceAllDropdownsLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.dimension === undefined || res.data.graph_metrics.dimension.length === 0 ? [] :
              res.data.graph_metrics.dimension
      );
      setPerformanceBySourceDetailsAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.side_metrics === undefined || res.data.side_metrics.length === 0 ? [] :
            res.data.side_metrics
      );
      setPerformanceBySourceDetailsTotal(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.side_metrics_total === undefined || res.data.side_metrics_total.length === 0 ? [] :
            res.data.side_metrics_total.impressions === undefined || res.data.side_metrics_total.impressions.length === 0 ? [] :
              res.data.side_metrics_total.impressions.platform_total === undefined || res.data.side_metrics_total.impressions.platform_total.length === 0 ? [] :
                res.data.side_metrics_total.impressions.platform_total
      );
      setPerformanceBySourceDetailsTotalAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.side_metrics_total === undefined || res.data.side_metrics_total.length === 0 ? [] :
            res.data.side_metrics_total
      );
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(true);
      setPerformanceBySourceError(null);
    } catch (error) {
      setPerformanceBySourceAllDropdownsData([]);
      setPerformanceBySourceAllDropdownsLabels([]);
      setPerformanceBySourceDetailsAllDropdownsData([]);
      setPerformanceBySourceDetailsTotalAllDropdownsData([]);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(false);
      setPerformanceBySourceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceChart = (
    <Wrapper>
      <Loader chartType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (
      NoDataAvailableChecker(performanceBySource) ||
      NoDataAvailableChecker(performanceBySourceAllDropdownsLabels)
    ) {
      performanceBySourceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performanceBySource}
              chartLabels={performanceBySourceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceChart =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE DETAILS

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceDetailsData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (NoDataAvailableChecker(performanceBySourceDetails)) {
      performanceBySourceDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceDetailsData = (
        <Wrapper>
          <div className="main_wrapper">
            <div className="body">
              {performanceBySourceDetails && performanceBySourceDetails.map(
                (details, postImpressionIndex) => (
                  <Wrapper key={postImpressionIndex}>
                    <div className="row">
                      <div className="title_wrapper">
                        {details.label}
                      </div>
                      <div className="value_wrapper">
                        <ErrorBoundary>
                          <NumberFormatter number={details.value} />
                        </ErrorBoundary>
                        {details.metric &&
                          <div className="metrics_wrapper">
                            <div className="title_wrapper">
                              {details.metric[0].label + " : "}
                            </div>
                            <div className="value_wrapper">
                              <ErrorBoundary>
                                <NumberFormatter
                                  number={details.metric[0].value}
                                />
                              </ErrorBoundary>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Wrapper>
                ))}
            </div>
            <div className="footer">
              <div className="title_wrapper">
                Total
              </div>
              <div className="value_wrapper">
                <NumberFormatter number={performanceBySourceDetailsTotal}
                />
              </div>
            </div>
          </div>


          {/* <table className="table table_striped table_bordered">
            <tbody>
              {performanceBySourceDetails.map(
                (details, postImpressionIndex) => {
                  return (
                    <tr key={postImpressionIndex}>
                      <td>{details.label}</td>
                      <td className="bold">
                        <ErrorBoundary>
                          <NumberFormatter number={details.value} />
                        </ErrorBoundary>
                      </td>
                      <td className="span">
                        <div className="subtitle">
                          <div>
                            {details.metric.length === 0
                              ? "Label"
                              : details.metric[0].label}
                            :{" "}
                            <ErrorBoundary>
                              <NumberFormatter
                                number={
                                  details.metric.length === 0
                                    ? 0
                                    : details.metric[0].value
                                }
                              />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td colSpan="2" className="bold">
                  Total
                </td>
                <td colSpan="2" className="bold flex justify_center">
                  <ErrorBoundary>
                    <NumberFormatter
                      number={
                        performanceBySourceDetailsTotal.length === 0
                          ? 0
                          : performanceBySourceDetailsTotal
                      }
                    />
                  </ErrorBoundary>
                </td>
              </tr>
            </tbody>
          </table> */}
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceDetailsData =
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // SOCIAL OVERVIEW

  // TO FETCH METRIC CARDS FROM API
  const fetchSocialOverviewHandler = async () => {
    setSocialOverviewIsLoading(true);
    setSocialOverviewIsLoaded(false);
    setSocialOverviewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/campaign-metric-card/", config);
      setSocialOverview(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data
      );
      setSocialOverviewIsLoading(false);
      setSocialOverviewIsLoaded(true);
      setSocialOverviewError(null);
    } catch (error) {
      setSocialOverview([]);
      setSocialOverviewIsLoading(false);
      setSocialOverviewIsLoaded(false);
      setSocialOverviewError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let socialOverviewSlider = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Campaign Summary
          </h2></div>

        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isSocialOverviewLoaded && !isSocialOverviewLoading) {
    if (NoDataAvailableChecker(socialOverview)) {
      socialOverviewSlider = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Campaign Summary
              </h2></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      socialOverviewSlider = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Campaign Summary"}>
              {socialOverview.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    variant={"compare"}
                    cardTitle={data.cardTitle}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={data.rowOneTitle}
                    second_currentValue={data.second_currentValue}
                    second_previousValue={data.second_previousValue}
                    rowTwoTitle={data.rowTwoTitle}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (socialOverviewError) {
    socialOverviewSlider = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Campaign Summary
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              error={socialOverviewError}
              chartType="cardChartType"
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSocialOverviewLoading) {
    socialOverviewSlider = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Campaign Summary
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLATFORM WISE ENGAGEMENTS

  // TO FETCH METRIC CARDS FROM API
  const fetchPlatformWiseEngagementsHandler = async () => {
    setPlatformWiseEngagementsIsLoading(true);
    setPlatformWiseEngagementsIsLoaded(false);
    setPlatformWiseEngagementsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dashboard/platform-wise-graph/",
        config
      );
      setPlatformWiseEngagementsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setPlatformWiseEngagementsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setPlatformWiseEngagementsIsLoading(false);
      setPlatformWiseEngagementsIsLoaded(true);
      setPlatformWiseEngagementsError(null);
    } catch (error) {
      setPlatformWiseEngagementsData([]);
      setPlatformWiseEngagementsDataLabels([]);
      setPlatformWiseEngagementsIsLoading(false);
      setPlatformWiseEngagementsIsLoaded(false);
      setPlatformWiseEngagementsError(error.code);
    }
  };

  let platformWiseEngagementsLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isPlatformWiseEngagementsLoaded && !isPlatformWiseEngagementsLoading) {
    if (
      NoDataAvailableChecker(platformWiseEngagementsData) || NoDataAvailableChecker(platformWiseEngagementsDataLabels)
    ) {
      platformWiseEngagementsLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      platformWiseEngagementsLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="platformWiseEngagements"
              chartClass="section_card_chart"
              chartData={platformWiseEngagementsData}
              chartLabels={platformWiseEngagementsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (platformWiseEngagementsError) {
    platformWiseEngagementsLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={platformWiseEngagementsError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlatformWiseEngagementsLoading) {
    platformWiseEngagementsLineChart =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // WEBSITE TRAFFIC OVERVIEW

  // TO FETCH DATA FROM API
  const fetchWebsiteTrafficOverviewHandler = async () => {
    setWebsiteTrafficDataLoaded(false);
    setWebsiteTrafficDataLoading(true);
    setWebsiteTrafficDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-metrics-overview/", config);

      setWebsiteTrafficData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metrics === undefined || res.data.metrics.length === 0 ? [] :
            res.data.metrics
      );
      setWebsiteTrafficDataLoaded(true);
      setWebsiteTrafficDataLoading(false);
      setWebsiteTrafficDataError();
    } catch (error) {
      setWebsiteTrafficData([]);
      setWebsiteTrafficDataLoaded(true);
      setWebsiteTrafficDataLoading(false);
      setWebsiteTrafficDataError(error.code);
    }
  };

  let websiteTrafficOverviewSlider = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Website Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isWebsiteTrafficDataLoaded && !isWebsiteTrafficDataLoading) {
    if (NoDataAvailableChecker(spendsTrendData)) {
      websiteTrafficOverviewSlider = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Website Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      websiteTrafficOverviewSlider = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Website Summary"}>
              {websiteTrafficData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    rowOneTitle={"Previous"}
                    previousValue={data.previousValue}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (websiteTrafficDataError) {
    websiteTrafficOverviewSlider = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Website Summary
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={websiteTrafficDataError}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isWebsiteTrafficDataLoading) {
    websiteTrafficOverviewSlider = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Website Summary
            </h2></div>


          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  var performanceSourceCSV = {
    [`CampPerfSourceDynLabels_Platform Wise Metrics_${selectedMidSectionDropdownOption.label}`]: performanceBySource,
    // ["Platform Wise Metrics Details"]: performanceBySourceDetails,
  };

  var allData = {
    // "Summary": exportSummary,
    "Lead trend": leadTrendData,
    "Spends Trend": spendsTrendData,
    "DashboardLeadOrigin_Lead Origin": leadOriginData,
    "Platform Wise Spend": platformWiseSpendData,
    "PlatformCards_Campaign Summary": socialOverview,
    "Campaign OverView": campaignOverview,
    // "Platform Wise Metric": performanceBySource,
    ...performanceSourceCSV,
    "Platform Wise Engagement": platformWiseEngagementsData,
    "Website Traffic Overview": websiteTrafficData,
  };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    leadTrendData,
    spendsTrendData,
    leadOriginData,
    platformWiseSpendData,
    campaignOverview,
    performanceBySource,
    socialOverview,
    platformWiseEngagementsData,
    websiteTrafficData,
  ]);

  useEffect(() => {
    if (
      isLeadTrendsLoaded &&
      isSpendsTrendsLoaded &&
      isLeadOriginsLoaded &&
      isPlatformWiseSpendsLoaded &&
      isCampaignOverviewLoaded &&
      isPerformanceBySourceLoaded &&
      isSocialOverviewLoaded &&
      isPlatformWiseEngagementsLoaded &&
      websiteTrafficData
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isLeadTrendsLoaded,
    isSpendsTrendsLoaded,
    isLeadOriginsLoaded,
    isPlatformWiseSpendsLoaded,
    isCampaignOverviewLoaded,
    isPerformanceBySourceLoaded,
    isSocialOverviewLoaded,
    isPlatformWiseEngagementsLoaded,
    websiteTrafficData
  ])


  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Dashboard"} />
          <div className="page_content">
            <div className="dashboard_home">
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div id="lead_trend" className="grid col_span_2 section_card">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Lead Trend</h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"lead_trend"}
                        sectionName={"Lead Trend"}
                        sectionData={leadTrendData}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {leadTrendLineChart}
                    </div>
                  </div>
                </div>
                <div id="spends_trend" className="grid col_span_2 section_card">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Spends Trend</h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"spends_trend"}
                        sectionName={"Spends Trend"}
                        sectionData={spendsTrendData}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {spendsTrendLineChart}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div id="lead_origin" className="grid col_span_2 section_card">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Lead Origin</h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"lead_origin"}
                        sectionName={"DashboardLeadOrigin_Lead Origin"}
                        sectionData={leadOriginData}

                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {leadOriginPieChart}
                    </div>
                  </div>
                </div>
                <div
                  id="platform_wise_spend"
                  className="grid col_span_2 section_card"
                >
                  <div className="section_card_header">
                    <h2 className="section_card_title">Platform wise Spend</h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"platform_wise_spend"}
                        sectionName={"Platform wise Spend"}
                        sectionData={platformWiseSpendData}

                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {platformWiseSpendLineChart}
                    </div>
                  </div>
                </div>
              </div>
              {socialOverviewSlider}
              <div
                id="campaign_overview_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Campaign Overview</h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"campaign_overview_section"}
                        sectionName={"Campaign Overview"}
                        sectionData={campaignOverview}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {campaignOverviewChart}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="platform_wise_metrics"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <h2 className="section_card_title">
                      Platform Wise Metrics
                    </h2>

                    <div data-html2canvas-ignore={true}>
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={midSectionDropdonwOptions}
                        className="form_dropdown section_dropdown"
                        value={selectedMidSectionDropdownOption}
                        selectedOptions={selectedMidSectionDropdownOption}
                        setStatedropdown={midSectionDropdownSelectionHandler}
                      />
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"platform_wise_metrics"}
                        sectionName={"Platform Wise Metrics"}
                        sectionData={performanceSourceCSV}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="grid col_span_3">
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        {performanceBySourceChart}
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_1">
                    <div className="section_card_body">
                      <div className="chart_details_wrapper">
                        {performanceBySourceDetailsData}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {platformWiseMetricsStackedColumnChart}
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                id="platform_wise_engagements"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <h2 className="section_card_title">
                      Platform Wise Engagements
                    </h2>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}

                        sectionId={"platform_wise_engagements"}
                        sectionName={"Platform Wise Engagements"}
                        sectionData={platformWiseEngagementsData}

                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {platformWiseEngagementsLineChart}
                    </div>
                  </div>
                </div>
              </div>
              {websiteTrafficOverviewSlider}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Dashboard;
