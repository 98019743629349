import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart, BlueColorStart500, PurpleColorStart500 } from "../../../constants/constants";

import { USER_COUNTRY } from "../../../constants/constants";
import { CountryCode } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";
am5.addLicense("AM5C258896422");

const MultiBarChart = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showVerticalLabels,
  showHalfVerticalLabels,
  showShortVerticalLabels,
  chartColorSet,
  minimumGridDistance,
  percentageTooltip,
  disabledLegendArray,
  setDisabledValues,
}) => {

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);

    // TO CHANGE COLOR OF THE CHART
    if (chartColorSet === undefined) {
      var colorSet = BlueColorStart500
    } else {
      var colorSet = chartColorSet
    }

    /* let colorSet = [0x11a1fd, 0xff9931, 0x5a75f9, 0x86a873]; */

    if (showLegend === undefined) {
      showLegend = true;
    }



    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    if (!disabledLegendArray) {
      root.setThemes([am5themes_Animated.new(root)])
    }

    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // root.dateFormatter.setAll({
    //   dateFormat: "yyyy-MM-dd",
    //   dateFields: ["valueX"]
    // });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // The data
    var data = chartData;
    let xRenderer;
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    {
      minimumGridDistance === undefined ?
        xRenderer = am5xy.AxisRendererX.new(root, {
          stroke: am5.color(0x757575),
          strokeWidth: 0.3,
          strokeOpacity: 1,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minorGridEnabled: true
        })
        :
        xRenderer = am5xy.AxisRendererX.new(root, {
          minGridDistance: minimumGridDistance,
          stroke: am5.color(0x757575),
          strokeWidth: 0.3,
          strokeOpacity: 1,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minorGridEnabled: true
        });
    }

    {
      showVerticalLabels &&
        xRenderer.labels.template.setAll({
          rotation: -90,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15,
        });
    }
    {
      showHalfVerticalLabels &&
        xRenderer.labels.template.setAll({
          rotation: -45,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15,
        });
    }
    {
      showShortVerticalLabels &&
        xRenderer.labels.template.setAll({
          rotation: -90,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15,
          maxWidth: 50,
          maxHeight: 50
        });
    }

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",

        // to start and end the line from the start and end
        startLocation: 0.2,
        endLocation: 0.8,

        renderer: xRenderer,
      })
    );

    // to change the date format from Feb 01 to 01/02
    // xAxis.get("dateFormats")["day"] = "dd/MM";

    // to show and hide x-axis labels
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400"
      });
    }

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // logarithmic: true,
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",


        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0x757575),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
        min: 0,
        extraMax: 0.04,
        strictMinMaxSelection: true,
      })
    );

    // to show and hide y-axis labels
    if (showLabels === false) {
      yAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
      });
    }

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // width: am5.percent(100),
        // height: am5.percent(20),
        x: am5.percent(50),
        centerX: am5.percent(50),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        marginTop: 15,
        // marginBottom: 15,
        useDefaultMarker: true,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
            maxColumns: 10,
            fixedWidthGrid: true,
          } : {
            maxColumns: 15,
          }),



        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
            orientation: "vertical",
          } : {}),
        }),
      })
    );
    if (disabledLegendArray === undefined || disabledLegendArray === null) {

    } else {
      legend.markers.template.setup = function (marker) {
        var check = am5.Graphics.new(root, {
          width: 10,
          height: 10,
          layer: 50,
          fill: am5.color(0xffffff),
          draw: function (display) {
            display.drawRect(4, 4, 6, 6)
          }
        });

        check.states.create("disabled", {
          fillOpacity: 1
        });

        marker.children.push(check);
      }

      legend.markers.template.setAll({
        width: 14,
        height: 14,

      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
      });
    }

    if (disabledLegendArray === undefined || disabledLegendArray === null) {

      legend.markers.template.setAll({
        width: 13,
        height: 13,
      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });
    }




    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    if (disabledLegendArray) {
      legend.itemContainers.template.events.on('click', function (ev) {

        var temp = ev.target.dataItem.dataContext;



        chart.series.values.filter((i, index) => {

          if (temp.get('name') === i._settings.name) {

            if (i.get('visible')) {
              setDisabledValues(prev => {
                var tempArr = [...prev, temp.get('name')]
                var fooBar = [...new Set(tempArr)]
                return fooBar;
              })

            } else {
              setDisabledValues(prev => {
                var tempArrRmv = prev.filter((i) => i !== temp.get('name'))
                return tempArrRmv
              })
            }

          }
        })
        root.dateFormatter.setAll({
          dateFormat: "yyyy-MM-dd",
          dateFields: ["label"]
        });
        // chart.series.data.processor = am5.DataProcessor.new(root, {
        //     dateFields: ["label"],
        //     dateFormat: "yyyy-MM-dd",
        // });
      })
    }

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name, field, childLabel, childValue, childLabel2, childValue2, index) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "label",
          childLabel: childLabel,
          childValue: childValue,
          childLabel2: childLabel2,
          childValue2: childValue2,
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
          stroke2: am5.color(colorSet[5]),
        })
      );

      series.columns.template.setAll({
        // tooltipText: "{name}, {categoryX}:{valueY}",
        // width: am5.percent(90),
        // tooltipY: 0
        cornerRadiusTL: 7,
        cornerRadiusTR: 7,
        width: 10,
      });

      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {

          var tooltipDataItem = series?.get("tooltipDataItem");
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

          /*   if (series.get("childLabel") !== undefined) {
              text += "\n[" + series.get("stroke2").toString() + "]●[/] " + "[bold]" + series.get("childLabel2") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel2"), tooltipDataItem?.dataContext[series.get('childValue2')], country) + "[/]" +
                "\n[]●[/] " + " [bold width:100px]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
            } */
          if (series.get("childLabel") !== undefined) {
            text += "\n[" + series.get("stroke2").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]"
          }

          if (series.get("childLabel2") !== undefined) {
            text += "\n[" + series.get("stroke2").toString() + "]●[/] " + "[bold]" + series.get("childLabel2") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel2"), tooltipDataItem?.dataContext[series.get('childValue2')], country) + "[/]"
          }
        });
        return text;
      });

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set(
      //   "scrollbarX",
      //   am5.Scrollbar.new(root, {
      //     orientation: "horizontal",
      //   })
      // );

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);

      // TO SHOW TEXT ON BAR
      // series.bullets.push(function () {
      //     return am5.Bullet.new(root, {
      //         locationY: 0,
      //         sprite: am5.Label.new(root, {
      //             text: "{valueY}",
      //             fill: root.interfaceColors.get("alternativeText"),
      //             centerY: 0,
      //             centerX: am5.p50,
      //             populateText: true
      //         })
      //     });
      // });

      // Add legends
      if (showLegend === true) {
        legend.data.push(series);
      }
    }

    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        label.childLabel,
        label.childValue,
        label.childLabel2,
        label.childValue2,
        index
      );
    });

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    // }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.zoomOutButton.set("forceHidden", true);
    chart.appear(1000, 100);

    if (disabledLegendArray !== undefined || disabledLegendArray !== null || disabledLegendArray.length !== 0) {

      function toggle(item) {
        if (item === undefined || item === null) return
        var storedItem = item;
        chart.series.values.filter((i, index) => {
          if (storedItem.includes(i._settings.name)) {
            var series = chart.series.getIndex(index);
            toggleSeries(series);
          }
        })
      }

      function toggleSeries(series) {
        if (series.get("visible")) {
          root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
          });
          series.data.processor = am5.DataProcessor.new(root, {
            dateFields: ["label"],
            dateFormat: "yyyy-MM-dd",
          });
          series.hide();
        }
        else {
          root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
          });
          series.data.processor = am5.DataProcessor.new(root, {
            dateFields: ["label"],
            dateFormat: "yyyy-MM-dd",
          });
          series.show();
        }
      }

      setTimeout(() => {
        toggle(disabledLegendArray);
      }, 1)
    }
    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

MultiBarChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  showVerticalLabels: PropTypes.bool,
  showHalfVerticalLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showShortVerticalLabels: PropTypes.bool,
  chartColorSet: PropTypes.array,
  minimumGridDistance: PropTypes.any,
  percentageTooltip: PropTypes.bool,

  disabledLegendArray: PropTypes.array
};

export default MultiBarChart;
