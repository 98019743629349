import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { IsNumeric } from '../../utilities/firstLetterCaps';



am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");


export default class Linegraph_sparkline extends Component {
  componentDidMount() {
    let info = this.props

    let card_id = info['card_id'];
    let data = info['graph-data'];
    let chart = am4core.create(card_id, am4charts.XYChart);

    // Add data
    for (let i = 0; i < data.length; ++i) {
      if (<IsNumeric data={data[i]['value']} />) {
        data[i]['value'] = parseFloat(data[i]['value'])
      }
    }

    chart.data = data;
    if (this.props.debug)

      chart.colors.list = [
        am4core.color("#11A1FD"),
        am4core.color("#b3e1fe"),
      ];

    // FOR FORMATTING LARGE NUMBERS


    if (this.props.isnumber == 'money') {
      chart.numberFormatter.numberFormat = "#.##a";
      chart.numberFormatter.bigNumberPrefixes = [
        { "number": 1e+3, "suffix": "K" },
        { "number": 1e+5, "suffix": "L" },
        { "number": 1e+7, "suffix": "Cr" }
      ];
    } else if (this.props.isnumber == 'percentage') {
      chart.numberFormatter.numberFormat = "#.##%"
    } else if (this.props.isnumber == 'decimal_percentage') {
      // chart.numberFormatter.numberFormat = "%"

      // chart.numberFormatter.bigNumberPrefixes = [
      //   { "suffix": "%" },
      // ];
    }
    else if (this.props.isnumber == 'decimal_percentage_sell_through') {
      chart.numberFormatter.numberFormat = "#a%"
    } else {
      chart.numberFormatter.numberFormat = "#.##a";
      chart.numberFormatter.bigNumberPrefixes = [
        { "number": 1e+3, "suffix": "K" },
        { "number": 1e+6, "suffix": "M" },
        { "number": 1e+9, "suffix": "G" }
      ];
    }





    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 0;
    dateAxis.renderer.grid.template.strokeWidth = 0;
    // dateAxis.startLocation = -0.1;
    // dateAxis.endLocation = -0.1;


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    dateAxis.renderer.grid.template.disabled = true; //vline
    valueAxis.renderer.grid.template.disabled = true; //hline
    dateAxis.renderer.labels.template.fontSize = 0;
    valueAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;

    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // Remove padding
    chart.paddingBottom = 0;
    chart.paddingTop = 0;
    chart.paddingLeft = -4;
    chart.paddingRight = -2;

    dateAxis.renderer.inside = true;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.strokeWidth = 1;
    series.fillOpacity = 0.2;
    // series.tooltipText = "{dateX}: {valueY}";
    // series.tooltipText = `{dateX}
    // ----
    // [bold]{valueY}[/]`;

    if (this.props.isnumber == 'decimal_percentage') {
      var tooltipHTML =
        `
        <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
          <div class="text-left d-flex flex-column justify-start" style="width: 80px; padding:10px">
            <span style="font-size: 13px; color: #fff; font-weight: 400">{valueY}%</span>
            <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{dateX}</span>
          </div>
        </div>  
        `;
    } else {
      var tooltipHTML =
        `
        <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
          <div class="text-left d-flex flex-column justify-start" style="width: 80px; padding:10px">
            <span style="font-size: 13px; color: #fff; font-weight: 400">{valueY}</span>
            <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{dateX}</span>
          </div>
        </div>  
        `;
    }



    // Tooltip 
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#213345");
    series.tooltip.background.stroke = am4core.color("#213345");
    series.tooltip.background.cornerRadius = 5;
    series.tooltip.label.minWidth = 80;
    series.tooltip.label.padding(0, 0, 0, 0);
    series.tooltip.label.fontSize = 12;
    series.tooltip.position = "pointer";
    // series.tooltip.pointerOrientation = "vertical";
    series.tooltipHTML = tooltipHTML;
    var shadow = series.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");


    series.tensionX = 0.77;
    chart.dateFormatter.dateFormat = { month: "long", day: "numeric" };
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#213345");
    series.tooltip.background.stroke = am4core.color("#213345");
    series.tooltip.label.fill = am4core.color("#ffffff");
    series.tooltip.label.fontSize = 12;

    series.stroke = am4core.color("#11A1FD");
    series.fill = am4core.color("#11A1FD");

    let fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    series.segments.template.fillModifier = fillModifier;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";
    chart.cursor.behavior = "zoomY";
    chart.cursor.lineX.disabled = true;
  }


  render() {

    let card_class = this.props.card_class;
    let card_id = this.props.card_id;

    return (
      <div id={card_id} className={card_class}></div>
    )
  }
}
