const IconClose = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_534" data-name="Group 534" transform="translate(-501 -714)">
               <g id="Group_459" data-name="Group 459" transform="translate(-3.5 -5.5)">
                    <line id="Line_109" data-name="Line 109" y1="28.118" transform="translate(541.383 736.5) rotate(45)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_110" data-name="Line 110" x2="28" transform="translate(521.5 736.5) rotate(45)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </g>
            </g>
        </svg>
    )
}

export default IconClose;