import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";

// PAGES 
import CampaignInstagramOverall, { CampaignInstagramOverallCoke } from "./CampaignInstagramOverall";
import CampaignInstagramVideo from "./CampaignInstagramVideo";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID, IDFC_COMPANY_ID, OLX_AUTOS_COMPANY_ID } from "../../../../constants/constants";

const CampaignInstagram = ({ platformData }) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

    // USER DATA
    const userData = useSelector((state) => state.userData.user);
    let companyID = userData?.company?.id;

    let tabData = ["Overall"];

    // if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID || companyID === IDFC_COMPANY_ID || companyID === OLX_AUTOS_COMPANY_ID) {
    // tabData = ["Overall"];
    // } else {
    //     tabData = ["Overall", "Video"];
    // }

    let InstagramOverall = [];
    if (companyID === COKE_COMPANY_ID) {
        InstagramOverall = <CampaignInstagramOverallCoke topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    }
    else {
        InstagramOverall = <CampaignInstagramOverall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />
    }
    const [selectedTab, setSelectedTab] = useState(tabData[0]);

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Instagram", event)
    };


    useEffect(() => {
        setExportPageName("Campaign Instagram " + selectedTab)
    }, [selectedTab])


    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)}
                />
            </div>
            {selectedTab.toLowerCase() === "overall" && InstagramOverall}
            {selectedTab.toLowerCase() === "video" && <CampaignInstagramVideo topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />}
        </Wrapper>
    )
}

export default CampaignInstagram;