const TrafficIcon = (props) => {
    return (
        <svg className={props.className} width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.579 15.3338C28.3282 14.2792 27.8547 13.2905 27.1903 12.434C26.526 11.5775 25.686 10.873 24.727 10.3678C24.7857 10.3439 24.8417 10.3137 24.894 10.2778C25.552 9.68358 26.0493 8.93277 26.3396 8.09504C26.63 7.25732 26.7041 6.35982 26.555 5.48582C26.3321 4.09681 25.5684 2.85252 24.4308 2.02501C23.2931 1.1975 21.8741 0.854071 20.484 1.06982C19.4876 1.19223 18.5496 1.60596 17.7873 2.25916C17.0251 2.91236 16.4726 3.77598 16.199 4.74182C15.872 5.69062 15.8312 6.71459 16.0815 7.68641C16.3319 8.65824 16.8623 9.53505 17.607 10.2078C17.787 10.3878 17.753 10.4298 17.563 10.5408C15.9828 11.4414 14.7864 12.8885 14.199 14.6098C14.167 14.7018 14.191 14.8298 14.049 14.8828C13.4529 14.2247 12.75 13.6719 11.97 13.2478C13.0388 12.316 13.6937 10.9977 13.7905 9.58304C13.8874 8.16836 13.4183 6.77314 12.4865 5.70432C11.5546 4.6355 10.2364 3.98062 8.82169 3.88376C7.40701 3.78689 6.01179 4.25598 4.94297 5.18782C4.43062 5.62184 4.00901 6.15276 3.70235 6.75012C3.39569 7.34748 3.21001 7.99952 3.15597 8.66882C3.06725 9.52167 3.18678 10.3833 3.5043 11.1798C3.82182 11.9763 4.32785 12.6839 4.97897 13.2418C4.91297 13.2858 4.87897 13.3118 4.83697 13.3368C3.64415 13.9878 2.65444 14.9558 1.97716 16.1339C1.29987 17.3121 0.961346 18.6544 0.998972 20.0128C0.998972 22.5278 0.998972 23.7298 0.998972 26.2448C0.964665 26.5697 1.00037 26.8982 1.10368 27.2082C1.20699 27.5181 1.37552 27.8023 1.5979 28.0417C1.82029 28.281 2.09137 28.4699 2.39291 28.5957C2.69444 28.7215 3.01942 28.7812 3.34597 28.7708C6.10997 28.7708 7.72097 28.7538 10.485 28.7708C10.7139 28.7793 10.942 28.7382 11.1536 28.6504C11.3652 28.5625 11.5554 28.43 11.711 28.2618C14.144 25.8138 16.591 23.3798 19.025 20.9318C19.225 20.7318 19.325 20.6958 19.541 20.9198C20.33 21.7408 21.141 22.5368 21.951 23.3398C22.048 23.4437 22.156 23.5369 22.273 23.6178C22.507 23.7643 22.7876 23.8175 23.0589 23.7667C23.3303 23.716 23.5727 23.565 23.738 23.3438C27.488 19.5948 33.138 13.9438 36.886 10.1928" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M33.2622 13.939L38.0762 9.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.2648 9.12194H38.0731V13.9303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TrafficIcon;
