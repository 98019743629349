import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class RiCustomerHeatMap extends Component {
    componentDidMount() {

        let info = this.props;
        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.maskBullets = false;

        chart.data = data;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS

        chart.colors.list = [
            am4core.color("#038673"),
        ];

        chart.numberFormatter.numberFormat = "#.#a";
        chart.numberFormatter.bigNumberPrefixes = [
            { "number": 1e+3, "suffix": "K" },
            { "number": 1e+5, "suffix": "L" }
        ];

        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "rate";
        yAxis.dataFields.category = "category";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;
        xAxis.renderer.labels.template.rotation = 0;
        xAxis.renderer.labels.template.fill = "#757575";
        xAxis.renderer.opposite = true;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 10;
        yAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS


        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "rate";
        series.dataFields.categoryY = "category";
        series.dataFields.value = "value";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;

        let bgColor = new am4core.InterfaceColorSet().getFor("background");

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        columnTemplate.stroke = bgColor;
        columnTemplate.tooltipText = "{category}: {value.workingValue.formatNumber('#.#a')}";
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.stroke = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.fontSize = 12;
        // series.tooltip.position = "pointer";
        // series.tooltip.pointerOrientation = "vertical";
        // var shadow = series.tooltip.background.filters.getIndex(0);
        // shadow.dx = 3;
        // shadow.dy = 10;
        // shadow.blur = 10;
        // shadow.color = am4core.color("#14567829");

        series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
        });

        // heat legend
        let heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.height = am4core.percent(10);
        heatLegend.padding(10, 0, 0, 0)
        heatLegend.series = series;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 0;
        heatLegend.valueAxis.renderer.minGridDistance = 30;
        heatLegend.orientation = 'horizontal'

        // heat legend behavior
        series.columns.template.events.on("over", function (event) {
            handleHover(event.target);
        })

        series.columns.template.events.on("hit", function (event) {
            handleHover(event.target);
        })

        function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        series.columns.template.events.on("out", function (event) {
            heatLegend.valueAxis.hideTooltip();
        })

    }
    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class} ></div>
        )
    }
}
