import React from "react"
import Wrapper from "../../../helpers/wrapper"

import "../../../shared/components/loader/loader.scss";

export const NoDataAvailable = () => {
    return (
        <Wrapper>
            <div className="icz-sectionchart d-flex justify-content-center align-items-center">
                <div className='icz-cardtitle'>
                    No Data Available
                </div>
            </div>
        </Wrapper>
    )
}
