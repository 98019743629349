import React from 'react'

export default function RiCategoryIcon(props) {
  return (
    <svg className={props.className} width="30" height="25" viewBox="0 0 20 20" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 0.75H2.5C1.80964 0.75 1.25 1.30964 1.25 2V6C1.25 6.69036 1.80964 7.25 2.5 7.25H6.5C7.19036 7.25 7.75 6.69036 7.75 6V2C7.75 1.30964 7.19036 0.75 6.5 0.75Z" stroke-width="1.5" />
      <path d="M6.5 9.75H2.5C1.80964 9.75 1.25 10.3096 1.25 11V15C1.25 15.6904 1.80964 16.25 2.5 16.25H6.5C7.19036 16.25 7.75 15.6904 7.75 15V11C7.75 10.3096 7.19036 9.75 6.5 9.75Z" stroke-width="1.5" />
      <path d="M16.75 13C16.75 11.2051 15.2949 9.75 13.5 9.75C11.7051 9.75 10.25 11.2051 10.25 13C10.25 14.7949 11.7051 16.25 13.5 16.25C15.2949 16.25 16.75 14.7949 16.75 13Z" stroke-width="1.5" />
      <path d="M15.5 0.75H11.5C10.8096 0.75 10.25 1.30964 10.25 2V6C10.25 6.69036 10.8096 7.25 11.5 7.25H15.5C16.1904 7.25 16.75 6.69036 16.75 6V2C16.75 1.30964 16.1904 0.75 15.5 0.75Z" stroke-width="1.5" />
    </svg>)
}
