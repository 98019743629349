import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl';
import { connect } from "react-redux";
import './map.scss';
import 'mapbox-gl/dist/mapbox-gl.css'
import { DEMO_UK_USER_ID, DEMO_US_USER_ID } from '../../../../../../constants/constants';

// mapboxgl.accessToken = 'pk.eyJ1IjoiaWNvZ3oiLCJhIjoiY2w4bXcyMXpqMGdsMTNvdGhiMnQ3ejFkMCJ9.-SsLsOIq-P8laHo8YTzsFQ'

export class ECOMIndiaMap extends Component {
    constructor(props) {
        super(props);

        this.state = {

            first_start_date: props.start_date,
            first_end_date: props.end_date,
            second_start_date: props.previous_start_date,
            second_end_date: props.previous_end_date,
            userData: props.userData,

            ind_lng: 78.9629,
            ind_lat: 20.5937,

            usa_lng: -107.483783,
            usa_lat: 66.932091,

            uk_lng: -3.4360,
            uk_lat: 55.3781,

            zoom: 2.8,
            india_data: {
                MapMarker: [
                    {
                        zone_id: "6",
                        center: "75.7885, 23.1765",
                        north_corner: "23.195342, 75.764998",
                        south_corner: "23.122721, 75.831854",
                        city: "UJJAIN",
                        zone: "WEST",
                        country: "IN",
                        nsv: 100,
                        target_nsv: "1730632.42",
                        nsv_achieved: "81.74",
                        ats: "2773.79",
                        score: "30",
                        nsv_mix: "0.48"
                    },
                    {
                        zone_id: "11",
                        center: "88.3953, 26.7271",
                        north_corner: "88.383707, 26.757838",
                        south_corner: "88.459336, 26.661382",
                        city: "SILIGURI",
                        zone: "EAST",
                        country: "IN",
                        nsv: 50,
                        target_nsv: "3268149.21",
                        nsv_achieved: "62.48",
                        ats: "2839.8",
                        score: "38",
                        nsv_mix: "0.7"
                    },
                    {
                        zone_id: "45",
                        center: "77.5946, 12.9716",
                        north_corner: "77.383346, 13.113602",
                        south_corner: "77.866436, 12.856547",
                        city: "BENGALURU",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 1500,
                        target_nsv: "43630787.5",
                        nsv_achieved: "50.11",
                        ats: "3259.26",
                        score: "34",
                        nsv_mix: "7.45"
                    },
                    {
                        zone_id: "28",
                        center: "75.3704, 11.8745",
                        north_corner: "75.362395, 11.877311",
                        south_corner: "75.377931, 11.859084",
                        city: "KANNUR",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 60,
                        target_nsv: "1853703.86",
                        nsv_achieved: "69.76",
                        ats: "3093.46",
                        score: "38",
                        nsv_mix: "0.44"
                    },
                    {
                        zone_id: "18",
                        center: "77.2090, 28.6139",
                        north_corner: "77.116756, 28.668884",
                        south_corner: "77.310390, 28.535051",
                        city: "NEW DELHI",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 1700,
                        target_nsv: "7511633.01",
                        nsv_achieved: "75.95",
                        ats: "3338.34",
                        score: "38",
                        nsv_mix: "1.94"
                    },
                    {
                        zone_id: "47",
                        center: "86.9661, 23.6889",
                        north_corner: "86.895052, 23.709909",
                        south_corner: "87.042681, 23.680672",
                        city: "ASANSOL",
                        zone: "EAST",
                        country: "IN",
                        nsv: 70,
                        target_nsv: "2339635.21",
                        nsv_achieved: "73.57",
                        ats: "2719.06",
                        score: "32",
                        nsv_mix: "0.59"
                    },
                    {
                        zone_id: "23",
                        center: "74.8560, 12.9141",
                        north_corner: "74.796442, 12.977187",
                        south_corner: "74.901561, 12.882369",
                        city: "MANGALOORU",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 80,
                        target_nsv: "2856870.44",
                        nsv_achieved: "60.42",
                        ats: "3002.04",
                        score: "35",
                        nsv_mix: "0.59"
                    },
                    {
                        zone_id: "15",
                        center: "81.6296, 21.2514",
                        north_corner: "81.601831, 21.312556",
                        south_corner: "81.698693, 21.191992",
                        city: "RAIPUR",
                        zone: "EAST",
                        country: "IN",
                        nsv: 100,
                        target_nsv: "2952877.26",
                        nsv_achieved: "66.35",
                        ats: "3009.78",
                        score: "37",
                        nsv_mix: "0.67"
                    },
                    {
                        zone_id: "26",
                        center: "76.6141, 8.8932",
                        north_corner: "76.539618, 8.931636",
                        south_corner: "76.645672, 8.882802",
                        city: "KOLLAM",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 89,
                        target_nsv: "2341613.75",
                        nsv_achieved: "59.1",
                        ats: "3641.74",
                        score: "33",
                        nsv_mix: "0.47"
                    },
                    {
                        zone_id: "51",
                        center: "81.8463, 25.4358",
                        north_corner: "81.675046, 25.494603",
                        south_corner: "81.915343, 25.351352",
                        city: "ALLAHABAD",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 58,
                        target_nsv: "3022295.93",
                        nsv_achieved: "65.05",
                        ats: "3067.14",
                        score: "33",
                        nsv_mix: "0.67"
                    },
                    {
                        zone_id: "46",
                        center: "75.3433, 19.8762",
                        north_corner: "75.273956, 19.897848",
                        south_corner: "75.366653, 19.834238",
                        city: "AURANGABAD",
                        zone: "WEST",
                        country: "IN",
                        nsv: 2200,
                        target_nsv: "4014940.71",
                        nsv_achieved: "78.02",
                        ats: "2639.03",
                        score: "40",
                        nsv_mix: "1.07"
                    },
                    {
                        zone_id: "12",
                        center: "78.1460, 11.6643",
                        north_corner: "78.058662, 11.720484",
                        south_corner: "78.191390, 11.633614",
                        city: "SALEM",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 200,
                        target_nsv: "2108893.45",
                        nsv_achieved: "62.73",
                        ats: "3324.06",
                        score: "35",
                        nsv_mix: "0.45"
                    },
                    {
                        zone_id: "20",
                        center: "76.6394, 12.2958",
                        north_corner: "76.565649, 12.343171",
                        south_corner: "76.705730, 12.263877",
                        city: "MYSORE",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 650,
                        target_nsv: "3804451.85",
                        nsv_achieved: "68.44",
                        ats: "3426.02",
                        score: "43",
                        nsv_mix: "0.89"
                    },
                    {
                        zone_id: "31",
                        center: "79.9864, 23.1815",
                        north_corner: "79.957957, 23.237089",
                        south_corner: "80.016090, 23.145237",
                        city: "JABALPUR",
                        zone: "WEST",
                        country: "IN",
                        nsv: 254,
                        target_nsv: "3141979.43",
                        nsv_achieved: "70.57",
                        ats: "2921.41",
                        score: "39",
                        nsv_mix: "0.76"
                    },
                    {
                        zone_id: "40",
                        center: "85.8830, 20.4625",
                        north_corner: "85.807000, 20.495431",
                        south_corner: "85.956908, 20.440966",
                        city: "CUTTACK",
                        zone: "EAST",
                        country: "IN",
                        nsv: 652,
                        target_nsv: "3643369.53",
                        nsv_achieved: "55.96",
                        ats: "2471.09",
                        score: "35",
                        nsv_mix: "0.69"
                    },
                    {
                        zone_id: "19",
                        center: "73.7898, 19.9975",
                        north_corner: "73.734126, 20.051916",
                        south_corner: "73.879701, 19.933164",
                        city: "NASHIK",
                        zone: "WEST",
                        country: "IN",
                        nsv: 488,
                        target_nsv: "4194410.3",
                        nsv_achieved: "57.78",
                        ats: "2704.89",
                        score: "41",
                        nsv_mix: "0.83"
                    },
                    {
                        zone_id: "10",
                        center: "72.8311, 21.1702",
                        north_corner: "72.784036, 21.252989",
                        south_corner: "72.918271, 21.074013",
                        city: "SURAT",
                        zone: "WEST",
                        country: "IN",
                        nsv: 51,
                        target_nsv: "5122099.42",
                        nsv_achieved: "90.61",
                        ats: "3047.41",
                        score: "38",
                        nsv_mix: "1.58"
                    },
                    {
                        zone_id: "3",
                        center: "83.2185, 17.6868",
                        north_corner: "83.198247, 17.827434",
                        south_corner: "83.259265, 17.643648",
                        city: "VIZAG",
                        zone: "APTS",
                        country: "IN",
                        nsv: 74,
                        target_nsv: "4030151.51",
                        nsv_achieved: "44.67",
                        ats: "3186.55",
                        score: "34",
                        nsv_mix: "0.61"
                    },
                    {
                        zone_id: "13",
                        center: "85.3096, 23.3441",
                        north_corner: "85.207533, 23.423559",
                        south_corner: "85.392598, 23.303332",
                        city: "RANCHI",
                        zone: "EAST",
                        country: "IN",
                        nsv: 89,
                        target_nsv: "3553411.94",
                        nsv_achieved: "63.85",
                        ats: "2479.73",
                        score: "34",
                        nsv_mix: "0.77"
                    },
                    {
                        zone_id: "17",
                        center: "85.1376, 25.5941",
                        north_corner: "85.020651, 25.637800",
                        south_corner: "85.260223, 25.562368",
                        city: "PATNA",
                        zone: "EAST",
                        country: "IN",
                        nsv: 45,
                        target_nsv: "8030489.59",
                        nsv_achieved: "66.38",
                        ats: "2784.91",
                        score: "40",
                        nsv_mix: "1.82"
                    },
                    {
                        zone_id: "24",
                        center: "80.9462, 26.8467",
                        north_corner: "80.884822, 26.930685",
                        south_corner: "81.009651, 26.790436",
                        city: "LUCKNOW",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 65,
                        target_nsv: "4103352.43",
                        nsv_achieved: "62.72",
                        ats: "2821.99",
                        score: "47",
                        nsv_mix: "0.88"
                    },
                    {
                        zone_id: "33",
                        center: "78.4867, 17.3850",
                        north_corner: "78.192385, 17.571947",
                        south_corner: "78.626586, 17.310253",
                        city: "HYDERABAD",
                        zone: "APTS",
                        country: "IN",
                        nsv: 85,
                        target_nsv: "53171997.89",
                        nsv_achieved: "55.14",
                        ats: "3320.21",
                        score: "40",
                        nsv_mix: "10"
                    },
                    {
                        zone_id: "2",
                        center: "76.8173, 30.6425",
                        north_corner: "76.813230, 30.666165",
                        south_corner: "76.847219, 30.641946",
                        city: "ZIRAKHPUR",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 54,
                        target_nsv: "3885389.23",
                        nsv_achieved: "71.46",
                        ats: "2669.63",
                        score: "40",
                        nsv_mix: "0.95"
                    },
                    {
                        zone_id: "30",
                        center: "75.7873, 26.9124",
                        north_corner: "75.708148, 26.979416",
                        south_corner: "75.894915, 26.806111",
                        city: "JAIPUR",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 32,
                        target_nsv: "4971356.63",
                        nsv_achieved: "71.95",
                        ats: "3613.01",
                        score: "44",
                        nsv_mix: "1.22"
                    },
                    {
                        zone_id: "35",
                        center: "91.7362, 26.1445",
                        north_corner: "91.670300, 26.202198",
                        south_corner: "91.852747, 26.125305",
                        city: "GUWAHATI",
                        zone: "EAST",
                        country: "IN",
                        nsv: 35,
                        target_nsv: "5654936.36",
                        nsv_achieved: "49.21",
                        ats: "2833.69",
                        score: "40",
                        nsv_mix: "0.95"
                    },
                    {
                        zone_id: "34",
                        center: "75.1240, 15.3647",
                        north_corner: "75.047347, 15.402928",
                        south_corner: "75.176436, 15.333408",
                        city: "HUBBALI",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 96,
                        target_nsv: "3280168.2",
                        nsv_achieved: "82.89",
                        ats: "3275.93",
                        score: "34",
                        nsv_mix: "0.93"
                    },
                    {
                        zone_id: "49",
                        center: "74.8723, 31.6340",
                        north_corner: "74.833038, 31.677069",
                        south_corner: "74.949553, 31.602938",
                        city: "AMRITSAR",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 41,
                        target_nsv: "2547156.82",
                        nsv_achieved: "68",
                        ats: "2863.04",
                        score: "35",
                        nsv_mix: "0.59"
                    },
                    {
                        zone_id: "48",
                        center: "72.9289, 22.5645",
                        north_corner: "72.914521, 22.571379",
                        south_corner: "72.996575, 22.526354",
                        city: "ANAND",
                        zone: "WEST",
                        country: "IN",
                        nsv: 63,
                        target_nsv: "2372595.19",
                        nsv_achieved: "98.44",
                        ats: "3424.62",
                        score: "41",
                        nsv_mix: "0.8"
                    },
                    {
                        zone_id: "53",
                        center: "78.0081, 27.1767",
                        north_corner: "77.883879, 27.240277",
                        south_corner: "78.080260, 27.148360",
                        city: "AGRA",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 800,
                        target_nsv: "2659364.91",
                        nsv_achieved: "72.59",
                        ats: "2510.19",
                        score: "39",
                        nsv_mix: "0.66"
                    },
                    {
                        zone_id: "43",
                        center: "69.6669, 23.2420",
                        north_corner: "69.653375, 23.271233",
                        south_corner: "69.699567, 23.233568",
                        city: "BHUJ",
                        zone: "WEST",
                        country: "IN",
                        nsv: 65,
                        target_nsv: "2093627.87",
                        nsv_achieved: "61.1",
                        ats: "2704.6",
                        score: "30",
                        nsv_mix: "0.44"
                    },
                    {
                        zone_id: "41",
                        center: "80.2707, 13.0827",
                        north_corner: "80.154603, 13.189012",
                        south_corner: "80.258045, 12.927241",
                        city: "CHENNAI",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 3500,
                        target_nsv: "6935649.17",
                        nsv_achieved: "52.61",
                        ats: "3293.44",
                        score: "31",
                        nsv_mix: "1.24"
                    },
                    {
                        zone_id: "21",
                        center: "72.8777, 19.0760",
                        north_corner: "72.782983, 19.257312",
                        south_corner: "72.938756, 19.014517",
                        city: "MUMBAI",
                        zone: "WEST",
                        country: "IN",
                        nsv: 5000,
                        target_nsv: "49556045.77",
                        nsv_achieved: "69.59",
                        ats: "2979.74",
                        score: "42",
                        nsv_mix: "11.76"
                    },
                    {
                        zone_id: "27",
                        center: "88.3639, 22.5726",
                        north_corner: "88.458589, 22.993330",
                        south_corner: "88.443637, 22.467182",
                        city: "KOLKATA",
                        zone: "EAST",
                        country: "IN",
                        nsv: 3200,
                        target_nsv: "34035547.04",
                        nsv_achieved: "57.8",
                        ats: "2959.73",
                        score: "38",
                        nsv_mix: "6.71"
                    },
                    {
                        zone_id: "4",
                        center: "80.6480, 16.5062",
                        north_corner: "80.568078, 16.553657",
                        south_corner: "80.718962, 16.474920",
                        city: "VIJAYAWADA",
                        zone: "APTS",
                        country: "IN",
                        nsv: 100,
                        target_nsv: "5070190.57",
                        nsv_achieved: "43.44",
                        ats: "3646.32",
                        score: "38",
                        nsv_mix: "0.75"
                    },
                    {
                        zone_id: "16",
                        center: "73.8567, 18.5204",
                        north_corner: "73.753311, 18.580924",
                        south_corner: "73.956120, 18.451389",
                        city: "PUNE",
                        zone: "WEST",
                        country: "IN",
                        nsv: 459,
                        target_nsv: "24103709.13",
                        nsv_achieved: "59.61",
                        ats: "2621.27",
                        score: "36",
                        nsv_mix: "4.9"
                    },
                    {
                        zone_id: "39",
                        center: "78.0322, 30.3165",
                        north_corner: "78.020221, 30.373702",
                        south_corner: "78.087990, 30.279693",
                        city: "DEHRADUN",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 120,
                        target_nsv: "2507966.21",
                        nsv_achieved: "58.07",
                        ats: "2537.34",
                        score: "34",
                        nsv_mix: "0.5"
                    },
                    {
                        zone_id: "37",
                        center: "88.6138, 27.3314",
                        north_corner: "88.593064, 27.375400",
                        south_corner: "88.618828, 27.323850",
                        city: "GANGTOK",
                        zone: "EAST",
                        country: "IN",
                        nsv: 96,
                        target_nsv: "1694536.04",
                        nsv_achieved: "43.83",
                        ats: "2652.5",
                        score: "27",
                        nsv_mix: "0.25"
                    },
                    {
                        zone_id: "32",
                        center: "75.8577, 22.7196",
                        north_corner: "75.798606, 22.792296",
                        south_corner: "75.920366, 22.657624",
                        city: "INDORE",
                        zone: "WEST",
                        country: "IN",
                        nsv: 63,
                        target_nsv: "6988414.86",
                        nsv_achieved: "63.31",
                        ats: "3085.46",
                        score: "36",
                        nsv_mix: "1.51"
                    },
                    {
                        zone_id: "5",
                        center: "73.1812, 22.3072",
                        north_corner: "73.078316, 22.410741",
                        south_corner: "73.231520, 22.242783",
                        city: "VADODARA",
                        zone: "WEST",
                        country: "IN",
                        nsv: 60,
                        target_nsv: "3166495.06",
                        nsv_achieved: "72.8",
                        ats: "3260.71",
                        score: "35",
                        nsv_mix: "0.79"
                    },
                    {
                        zone_id: "7",
                        center: "76.9366, 8.5241",
                        north_corner: "8.564778, 76.842708",
                        south_corner: "8.466799, 77.003544",
                        city: "TRIVANDRUM",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 52,
                        target_nsv: "3900609.28",
                        nsv_achieved: "36.98",
                        ats: "2752.48",
                        score: "38",
                        nsv_mix: "0.49"
                    },
                    {
                        zone_id: "1",
                        center: "80.3319, 26.4499",
                        north_corner: "80.227820, 26.535600",
                        south_corner: "80.448685, 26.353820",
                        city: "KANPUR",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 96,
                        target_nsv: "2963490.27",
                        nsv_achieved: "78.16",
                        ats: "2873.95",
                        score: "34",
                        nsv_mix: "0.79"
                    },
                    {
                        zone_id: "14",
                        center: "70.8022, 22.3039",
                        north_corner: "70.744732, 22.344620",
                        south_corner: "70.947718, 22.237121",
                        city: "RAJKOT",
                        zone: "WEST",
                        country: "IN",
                        nsv: 321,
                        target_nsv: "2653436.11",
                        nsv_achieved: "79.46",
                        ats: "3389.57",
                        score: "44",
                        nsv_mix: "0.72"
                    },
                    {
                        zone_id: "29",
                        center: "74.8570, 32.7266",
                        north_corner: "74.770143, 32.788333",
                        south_corner: "74.954159, 32.646993",
                        city: "JAMMU",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 50,
                        target_nsv: "1933178.4",
                        nsv_achieved: "77.97",
                        ats: "2978.76",
                        score: "40",
                        nsv_mix: "0.51"
                    },
                    {
                        zone_id: "50",
                        center: "77.7523, 20.9320",
                        north_corner: "80.438601, 16.552839",
                        south_corner: "80.595014, 16.471199",
                        city: "AMARAVATI",
                        zone: "WEST",
                        country: "IN",
                        nsv: 102,
                        target_nsv: "3832564.76",
                        nsv_achieved: "55.73",
                        ats: "2836.35",
                        score: "39",
                        nsv_mix: "0.73"
                    },
                    {
                        zone_id: "42",
                        center: "75.7804, 11.2588",
                        north_corner: "75.735959, 11.337213",
                        south_corner: "75.881733, 11.199490",
                        city: "CALICUT",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 32,
                        target_nsv: "2760131.55",
                        nsv_achieved: "52.01",
                        ats: "2605.56",
                        score: "34",
                        nsv_mix: "0.49"
                    },
                    {
                        zone_id: "25",
                        center: "76.5604, 18.4088",
                        north_corner: "76.523536, 18.426447",
                        south_corner: "76.621898, 18.386542",
                        city: "LATUR",
                        zone: "WEST",
                        country: "IN",
                        nsv: 98,
                        target_nsv: "2521062.64",
                        nsv_achieved: "72.11",
                        ats: "2521.36",
                        score: "36",
                        nsv_mix: "0.62"
                    },
                    {
                        zone_id: "8",
                        center: "79.4192, 13.6288",
                        north_corner: "79.347320, 13.691059",
                        south_corner: "79.507372, 13.605190",
                        city: "TIRUPATI",
                        zone: "APTS",
                        country: "IN",
                        nsv: 85,
                        target_nsv: "2730102.59",
                        nsv_achieved: "66.77",
                        ats: "3369.56",
                        score: "43",
                        nsv_mix: "0.62"
                    },
                    {
                        zone_id: "38",
                        center: "70.1337, 23.0753",
                        north_corner: "70.114228, 23.100265",
                        south_corner: "70.146500, 23.040567",
                        city: "GANDHIDHAM",
                        zone: "WEST",
                        country: "IN",
                        nsv: 40,
                        target_nsv: "1922015.77",
                        nsv_achieved: "81.24",
                        ats: "2642.19",
                        score: "31",
                        nsv_mix: "0.53"
                    },
                    {
                        zone_id: "44",
                        center: "85.8245, 20.2961",
                        north_corner: "85.795600, 20.374311",
                        south_corner: "85.880132, 20.264593",
                        city: "BHUBANESWAR",
                        zone: "EAST",
                        country: "IN",
                        nsv: 200,
                        target_nsv: "7247113.23",
                        nsv_achieved: "52.7",
                        ats: "2924.28",
                        score: "44",
                        nsv_mix: "1.3"
                    },
                    {
                        zone_id: "52",
                        center: "72.5714, 23.0225",
                        north_corner: "72.498337, 23.111038",
                        south_corner: "72.670220, 22.958967",
                        city: "AHMEDABAD",
                        zone: "WEST",
                        country: "IN",
                        nsv: 1300,
                        target_nsv: "20722987.44",
                        nsv_achieved: "69.49",
                        ats: "3131.81",
                        score: "38",
                        nsv_mix: "4.91"
                    },
                    {
                        zone_id: "9",
                        center: "76.2144, 10.5276",
                        north_corner: "76.175268, 10.581163",
                        south_corner: "76.276186, 10.458231",
                        city: "THRISSUR",
                        zone: "SOUTH",
                        country: "IN",
                        nsv: 50,
                        target_nsv: "3516266.04",
                        nsv_achieved: "46.47",
                        ats: "2618.65",
                        score: "42",
                        nsv_mix: "0.56"
                    },
                    {
                        zone_id: "36",
                        center: "77.4538, 28.6692",
                        north_corner: "77.235935, 28.783836",
                        south_corner: "77.523366, 28.639887",
                        city: "GHAZIABAD",
                        zone: "NORTH",
                        country: "IN",
                        nsv: 65,
                        target_nsv: "7488856.29",
                        nsv_achieved: "67.18",
                        ats: "2935.06",
                        score: "41",
                        nsv_mix: "1.71"
                    }
                ],
                North_point: [72.6416015625, 37.71859032558816],
                South_point: [93.1640625, 5.7908968128719565],
                map_level: 0,
                center_point: [0, 0],
            },
            us_data: {
                MapMarker: [
                    {
                        "zone_id": 10,
                        "center": "-120.075650,37.382740",
                        "north_corner": "-127.722134,42.968009",
                        "south_corner": "-112.934537,31.563945",
                        "zone": "WEST",
                        "country": "US",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 11,
                        "center": "-89.203333,40.088777",
                        "north_corner": "-92.894739,43.239389",
                        "south_corner": "-86.528162,36.560602",
                        "zone": "MIDWEST",
                        "country": "US",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 12,
                        "center": "-73.886309,40.706741",
                        "north_corner": "-74.056847,40.949862",
                        "south_corner": "-73.674385,40.501828",
                        "zone": "NORTH EAST",
                        "country": "US",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 13,
                        "center": "-82.150764,28.657638",
                        "north_corner": "-87.973517,31.583979",
                        "south_corner": "-79.206428,24.432963",
                        "zone": "SOUTH EAST",
                        "country": "US",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 14,
                        "center": "-99.146507,31.305198",
                        "north_corner": "-99.146507,31.305198,59",
                        "south_corner": "-93.060082,24.555501",
                        "zone": "SOUTH WEST",
                        "country": "US",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    }
                ],
                // North_point: [49.384358, -66.949895],
                // South_point: [24.396308, -81.950976],
                map_level: 0,
                center_point: [0, 0],
            },
            uk_data: {
                MapMarker: [
                    {
                        "zone_id": 7,
                        "center": "-6.746431566375764, 54.64409815068209",
                        "north_corner": "-7.344360351562499, 55.0469089511379",
                        "south_corner": "-5.6634521484375, 54.242760598962114",
                        "zone": "NORTHERN IRELAND",
                        "country": "UK",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 8,
                        "center": "-2.148010492849207, 53.66997549215798",
                        "north_corner": "-3.40576171875, 54.76267040025495",
                        "south_corner": "1.47216796875, 52.05249047600099",
                        "zone": "ENGLAND",
                        "country": "UK",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 9,
                        "center": "-3.533992, 52.437479",
                        "north_corner": "-4.74609375, 52.802761415419674",
                        "south_corner": "-2.684783935546875, 51.601812771074684",
                        "zone": "WALES",
                        "country": "UK",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    },
                    {
                        "zone_id": 6,
                        "center": "-4.511409523207077,57.121265847406484",
                        "north_corner": "-6.30615234375,58.48220919993359",
                        "south_corner": "-2.6806640625, 56.255557451930585",
                        "zone": "SCOTLAND",
                        "country": "UK",
                        "nsv": 0,
                        "target_nsv": 0,
                        "nsv_achieved": 0,
                        "ats": 0,
                        "score": 0,
                        "nsv_mix": 0
                    }
                ],
                North_point: [60.856020, -3.155580],
                South_point: [49.871159, -6.302534],
                map_level: 0,
                center_point: [0, 0],
            }
        };
        this.mapContainer = React.createRef();
    };


    componentDidMount() {
        const { lng, lat, zoom } = this.state;

        let info = this.state.userData.id === DEMO_US_USER_ID ? this.state.us_data : this.state.userData.id === DEMO_UK_USER_ID ? this.state.uk_data : this.state.india_data;

        const map = new mapboxgl.Map({
            container: 'map',
            // style: 'mapbox://styles/icogz/clatinkb1009s14o6tj43b929', // INDIA MAP
            // style: 'mapbox://styles/icogz/clktbrz1w00tq01pceif28cdf', // US MAP
            // style: 'mapbox://styles/icogz/cldd2wvsp000101p48y76dwxw', // UK MAP
            style: this.state.userData.id === DEMO_US_USER_ID ? 'mapbox://styles/icogz/clktbrz1w00tq01pceif28cdf' : this.state.userData.id === DEMO_UK_USER_ID ? 'mapbox://styles/icogz/cldd2wvsp000101p48y76dwxw' : 'mapbox://styles/icogz/clatinkb1009s14o6tj43b929',
            center: this.state.userData.id === DEMO_US_USER_ID ? [this.state.usa_lng, this.state.usa_lat] : this.state.userData.id === DEMO_UK_USER_ID ? [this.state.uk_lng, this.state.uk_lat] : [this.state.ind_lng, this.state.ind_lat],
            zoom: 1,
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            attributionControl: false,
        });

        // map.fitBounds([[
        //     info.North_point[0], info.North_point[1]
        // ],
        // [
        //     info.South_point[0], info.South_point[1]
        // ]], { duration: 0 });

        map.on('load', () => {
            var markarr = [];
            //Rupesh logic map 
            info.MapMarker.forEach(function (element, index) {

                let center = [0, 0];
                let north = [0, 0];
                let south = [0, 0];
                let store_latitude = 0;
                let store_longitude = 0;
                let India_StoreCode = '';
                let India_StoreName = '';
                if (info.map_level != 2) {
                    center = element.center.split(',');
                    north = element.north_corner.split(',');
                    south = element.south_corner.split(',');
                } else {
                    center = info.Center_point;
                    north = info.North_point;
                    south = info.South_point;
                    store_latitude = element.latitude;
                    store_longitude = element.longitude;
                    India_StoreCode = element.store_code;
                    India_StoreName = element.store_name;

                }

                let India_Zone = element.zone;

                let India_city = '';

                if (info.map_level != 0) {
                    India_city = element.city;
                }

                let nsv_achieved_color = '#eee';
                if (element.nsv >= 3000) {
                    nsv_achieved_color = '#038673';
                } else if (element.nsv >= 1000 && element.nsv <= 2999) {
                    nsv_achieved_color = '#DB7823';
                } else {
                    nsv_achieved_color = '#D51F30';
                }
                let toolTipName = '';
                if (info.map_level == 2) { toolTipName = element.store_name; }
                else if (info.map_level == 1) { toolTipName = element.city; }
                else { toolTipName = element.zone; }

                if (element.nsv >= 10000000) {
                    element.nsv = (element.nsv / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
                }
                if (element.nsv >= 100000) {
                    element.nsv = (element.nsv / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
                }
                if (element.nsv >= 1000) {
                    element.nsv = (element.nsv / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }

                element.nsv_mix = element.nsv_mix + `%`;

                element.nsv_achieved = element.nsv_achieved + `%`;

                if (info.map_level != 2) {
                    markarr['zone' + index] = new mapboxgl.Marker({ color: nsv_achieved_color })
                        .setLngLat([center[0], center[1]])
                        .addTo(map);

                    var popup = new mapboxgl.Popup().setHTML(
                        element.city + ' : ' + element.nsv
                    );

                    markarr['zone' + index].getElement().addEventListener('click', () => {
                        //  markarr['zone'+index].style.visibility='hidden';
                        map.fitBounds([[north[0], north[1]], [south[0], south[1]]]);
                        info.parentCallback([north, south, center, India_Zone, India_city, India_StoreCode, India_StoreName]);
                    });

                    const element123 = markarr['zone' + index].getElement();
                    element123.id = markarr['zone' + index];
                    element123.addEventListener('mouseover', () => popup.addTo(map));
                    element123.addEventListener('mouseleave', () => popup.remove());
                    if (info.isTooltipEnabled === true) {
                        markarr['zone' + index].setPopup(popup);
                    } else {
                        // markarr['zone' + index].setPopup(popup);
                    }

                    markarr['zone' + index].addTo(map);
                }
                else {
                    markarr['zone' + index] = new mapboxgl.Marker({ color: nsv_achieved_color })
                        .setLngLat([store_longitude, store_latitude])
                        .addTo(map);

                    var popup = new mapboxgl.Popup(
                    ).setHTML(
                        'NAME : ' + toolTipName + '</br> NSV : ' + element.nsv + '</br> NSV MIX : ' + element.nsv_mix + '</br> NSV ACHIEVED : ' + element.nsv_achieved
                    );

                    markarr['zone' + index].getElement().addEventListener('click', () => {
                        //  markarr['zone'+index].style.visibility='hidden';
                        map.fitBounds(
                            [
                                [north[0], north[1]], [south[0], south[1]]
                            ]
                        );
                        info.parentCallback([north, south, center, India_Zone, India_city, India_StoreCode, India_StoreName]);

                    });
                    const element123 = markarr['zone' + index].getElement();
                    element123.id = markarr['zone' + index];
                    element123.addEventListener('mouseover', () => popup.addTo(map));
                    element123.addEventListener('mouseleave', () => popup.remove());
                    markarr['zone' + index].setPopup(popup);
                    markarr['zone' + index].addTo(map);
                }

            });

        });


        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    }



    render() {
        let chart_class = 'map-container ' + this.props.chart_class;
        return (
            <div>
                <div id="map" className={chart_class} />
            </div>
        )
    }
}

function ReduxStateToProps(state) {
    return {
        start_date: state.date.startDate,
        end_date: state.date.endDate,
        previous_start_date: state.date.prevStartDate,
        previous_end_date: state.date.prevEndDate,
        country: state.userData.user.country_code,
        userData: state.userData.user,
    };
}

export default connect(ReduxStateToProps)(ECOMIndiaMap)