import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH258896422");

export default class BubbleTextchart extends Component {
    componentDidMount() {
        let info = this.props

        let card_id = info['card_id'];
        let data = info['graph-data'];
        let title1 = info['metric_1'];
        let title2 = info['metric_2'];
        let tooltip_label = info['tooltip_label']


        let chart = am4core.create(card_id, am4charts.XYChart);

        // Add data
        chart.data = data

        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS


        chart.colors.list = [
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
        ];

        chart.dataFields.color = "color";


        // FOR FORMATTING LARGE NUMBERS
        if (this.props.isnumber == 'money') {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+5, "suffix": "L" },
                { "number": 1e+7, "suffix": "Cr" }
            ];
        } else {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+6, "suffix": "M" },
                { "number": 1e+9, "suffix": "G" }
            ];
        }


        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        // xAxis.renderer.ticks.template.disabled = true;
        // xAxis.renderer.axisFills.template.disabled = true;
        // xAxis.renderer.grid.template.location = 0;
        // xAxis.renderer.grid.template.disabled = true; //vline
        xAxis.cursorTooltipEnabled = false;
        xAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        xAxis.renderer.grid.template.disabled = true;

        // xAxis.title.text = "AOV";
        xAxis.title.text = title1;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // yAxis.renderer.ticks.template.disabled = true;
        // yAxis.renderer.axisFills.template.disabled = true;
        // // xAxis.dataFields.category = "category";
        // yAxis.renderer.grid.template.disabled = true; //hline
        yAxis.cursorTooltipEnabled = false;
        yAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        yAxis.renderer.grid.template.disabled = true;

        yAxis.title.text = title2;


        // Create series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "ay";
        series1.dataFields.valueX = "date";
        series1.dataFields.value = "aValue";
        series1.strokeOpacity = 0;
        series1.cursorTooltipEnabled = false;


        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.strokeOpacity = 0.5;
        bullet1.fillOpacity = 0.5;

        // Create a hover state
        var hoverStateCircle = bullet1.states.create("hover");
        hoverStateCircle.properties.fillOpacity = 1;

        bullet1.nonScalingStroke = true;


        bullet1.tooltipText = `Keyword: [bold]{bubbleLabelText}[/]
        ---
        `+ tooltip_label + `: [bold]{aValue}[/]`;

        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#213345");
        series1.tooltip.background.stroke = am4core.color("#213345");
        series1.tooltip.background.cornerRadius = 5;
        series1.tooltip.label.minWidth = 150;
        series1.tooltip.label.padding(10, 10, 10, 10);
        series1.tooltip.label.fontSize = 12;


        // bullet1.tooltip.position = "pointer";
        const bulletLabel = series1.bullets.push(new am4charts.LabelBullet());
        // bulletLabel.label.text = "{bubbleLabelText}";
        bulletLabel.label.fontSize = 10;
        bulletLabel.label.fill = am4core.color("#fff");
        bulletLabel.label.truncate = true;
        bulletLabel.label.wrap = false;
        bulletLabel.label.maxWidth = 100;
        // bulletLabel.label.tooltipText = "{bubbleLabelText}";
        series1.heatRules.push({
            target: bullet1.circle,
            min: 10,
            max: 20,
            property: "radius"
        });


        // Add scrollbars
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";

    }
    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}
