// MODULE CLICK FROM SIDEBAR
// FOR EXAMPLE: MARKETING INTELIGENCE / RETAIL INTELLIGENCE / ECOM
export const IAEvent_Module_Visit = (
    moduleName,
    subModuleName,
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName
        }), "Module Visit")

    return true
}

// BRAND CARD CLICK
// FOR EXAMPLE: FACEBOOK / INSTAGRAM / LINKEDIN / TWITTER
export const IAEvent_Brand_Visit = (
    moduleName,
    subModuleName,
    platformName,
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            brand: platformName === null ? undefined : platformName
        }), "Brand Visit")

    return true
}
// PLATFORM CARD CLICK
// FOR EXAMPLE: FACEBOOK / INSTAGRAM / LINKEDIN / TWITTER
export const IAEvent_Platform_Visit = (
    moduleName,
    subModuleName,
    platformName,
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName
        }), "Platform Visit")

    return true
}

// PRIMARY TAB CLICK
// FOR EXAMPLE: PAGE / AUDIENCE / POST
export const IAEvent_Primary_Tab_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === null ? undefined : primaryTabName
        }), "Primary Tab Visit")

    return true
}

// SECONDARY TAB CLICK
// FOR EXAMPLE: OVERALL / ORGANIC / PAID 
export const IAEvent_Secondary_Tab_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName,
    secondaryTabName
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === null ? undefined : primaryTabName,
            secondaryTab: secondaryTabName === null ? undefined : secondaryTabName
        }), "Secondary Tab Visit")

    return true
}

// SECTION DROPDOWN CLICK
// FOR EXAMPLE: LIKES / UNLIKES
export const IAEvent_Dropdown_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName,
    secondaryTabName,
    dropdownSectionName,
    dropdownValue
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === null ? undefined : primaryTabName,
            secondaryTab: secondaryTabName === null ? undefined : secondaryTabName,
            sectionName: dropdownSectionName === null ? undefined : dropdownSectionName,
            dropdownName: dropdownValue === null ? undefined : dropdownValue
        }), "Dropdown Visit")

    return true
}

// ON HASHTAG CLICK
// FOR EXAMPLE: #HASHTAG
export const IAEvent_Hashtag_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName,
    secondaryTabName,
    hashtag
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === null ? undefined : primaryTabName,
            secondaryTab: secondaryTabName === null ? undefined : secondaryTabName,
            hashtagName: hashtag === null ? undefined : hashtag
        }), "Hashtag Visit")

    return true
}


// EXPORT DROPDOWN CLICK
// FOR EXAMPLE: CSV / JPG / PNG / PDF
export const IAEvent_Export_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName,
    secondaryTabName,
    exportSectionName,
    exportType
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === undefined || moduleName === null ? undefined : moduleName,
            subModule: subModuleName === undefined || subModuleName === null ? undefined : subModuleName,
            platform: platformName === undefined || platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === undefined || primaryTabName === null ? undefined : primaryTabName,
            secondaryTab: secondaryTabName === undefined || secondaryTabName === null ? undefined : secondaryTabName,
            sectionName: exportSectionName === undefined || exportSectionName === null ? undefined : exportSectionName,
            export: exportType === undefined || exportType === null ? undefined : exportType
        }), "Export Visit")

    return true
}

// FOR EXAMPLE: CSV / JPG / PNG / PDF
export const IAEvent_Video_Visit = (
    moduleName,
    subModuleName,
    ThumbnailCardPlatformName,
    platformName,
    VideoThumbnail,
    TabName,
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            ThumbnailCardPlatform: ThumbnailCardPlatformName === null ? undefined : ThumbnailCardPlatformName,
            platform: platformName === null ? undefined : platformName,
            Video: VideoThumbnail === null ? undefined : VideoThumbnail,
            Tab: TabName === null ? undefined : TabName
        }), "Video Visit")

    return true
}


// CHART CLICK
// FOR EXAMPLE: CLICK ON PIE CHART TO CHANGE LINE CHART DATA
export const IAEvent_Chart_Visit = (
    moduleName,
    subModuleName,
    platformName,
    primaryTabName,
    secondaryTabName,
    sectionName
) => {
    window.icogz.trackEvent(
        JSON.stringify({
            name: moduleName === null ? undefined : moduleName,
            subModule: subModuleName === null ? undefined : subModuleName,
            platform: platformName === null ? undefined : platformName,
            primaryTab: primaryTabName === null ? undefined : primaryTabName,
            secondaryTab: secondaryTabName === null ? undefined : secondaryTabName,
            sectionName: sectionName === null ? undefined : sectionName,
        }), "Chart Visit")

    return true
}
