import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";

// STORAGE 
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS 
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Card from "../../../components/ui/Card";
import Slider from "../../../components/ui/Slider";

// PAGES
import SocialFacebook from "./socialFacebook/SocialFacebook.js";
import SocialInstagram from "./socialInstagram/SocialInstagram";
import SocialTwitter from "./socialTwitter/SocialTwitter";
import SocialLinkedin from "./socialLinkedin/SocialLinkedin";

// LOADERS
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { IAEvent_Platform_Visit } from "../../../utils/IAEvents";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";

const Social = () => {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const { setexportSummary } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState(params.platform === undefined ? "" : params.platform);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/metric-card/",
        config
      );
      setMetricCards(res.data === undefined || res.data.length === 0 ? [] : res.data);

      setSelectedPlatform(params.platform === undefined ? res.data[0].cardTitle.toLowerCase() : params.platform)

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = <Wrapper><Loader loaderType="socialLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid">
            <div className="card_wrapper">
              <ErrorBoundary chartType="cardChartType">
                <Slider
                  sliderWrapperClass={"tab_card_slider"}
                  showSliderHeader={false}
                  showNavigation={false}
                  sliderTitle={"Summary"}
                >
                  {metricCards.map((data, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => activePlatform(data.cardTitle)}
                    >
                      <Card
                        key={index}
                        cardClass={
                          selectedPlatform === data.cardTitle.toLowerCase()
                            ? "tabCard active"
                            : "tabCard "
                        }
                        variant={"compare"}
                        cardTitle={data.cardTitle}
                        currentValue={data.currentValue}
                        previousValue={data.previousValue}
                        rowOneTitle={data.rowOneTitle}
                        second_currentValue={data.second_currentValue}
                        second_previousValue={data.second_previousValue}
                        rowTwoTitle={data.rowTwoTitle}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </ErrorBoundary>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection =
      <Wrapper>
        <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <Loader loaderType="socialLoader" />
      </Wrapper>
  }

  // TO SET PLATFORM ACTIVE ON CLICK
  const activePlatform = (platform) => {
    if (selectedPlatform !== platform) {
      setSelectedPlatform(platform.toLowerCase());
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Social", platform)
  };

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler()
  }, [BRAND_ID, selectedDates]);

  useEffect(() => {
    setexportSummary([
      selectedDates,
      ...selectedBrands,
    ]);

  }, [
    selectedDates,
    selectedBrands,
  ]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          {/* <div className="alert_section">
            <div className="alert info_alert">
              Disclaimer: Date range for this module is from 1st Feb to 28th Feb
            </div>
          </div> */}
          <PageHeader pageTitle={"Social"} />
          <div className="page_content">
            <div className="dashboard_home">
              {metricCardsSection}

              {isMetricCardsLoaded && (
                <Wrapper>
                  {selectedPlatform === "facebook" && <SocialFacebook platformData={metricCards} />}
                  {selectedPlatform === "instagram" && <SocialInstagram platformData={metricCards} />}
                  {selectedPlatform === "twitter" && <SocialTwitter platformData={metricCards} />}
                  {selectedPlatform === "linkedin" && <SocialLinkedin platformData={metricCards} />}
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Social;
