import React, { useContext, useEffect, useState } from "react";

import { DateRangePicker, createStaticRanges } from "react-date-range"; // FOR DATEPICKER

import moment from "moment";

import { format, differenceInDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Wrapper from "../helpers/Wrapper";

import CalendarIcon from "../../assets/icons/CalendarIcon";
import Button from "./Button";

import {
  setStartDate,
  setEndDate,
  setPrevStartDate,
  setPrevEndDate,
} from "../../store/dateSlice";
import { useDispatch, useSelector } from "react-redux";

export function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

function DatePicker(props) {
  const dispatch = useDispatch();

  // SELECTED BRANDS FROM DROPDOWN
  const selectedDates = useSelector((state) => state.date);

  // FOR SETTING THE END DATE OF CURRENT
  let current_end_date = new Date();
  current_end_date.setDate(current_end_date.getDate() - 6);

  // FOR SETTING THE START DATE OF PREVIOUS
  let previous_start_date = new Date();
  previous_start_date.setDate(previous_start_date.getDate() - 13);

  // FOR SETTING THE END DATE OF PREVIOUS
  let previous_end_date = new Date();
  previous_end_date.setDate(previous_end_date.getDate() - 7);

  const [state, setState] = useState({
    selection: {
      startDate: selectedDates.startDate === "" ? current_end_date : new Date(selectedDates.startDate),
      endDate: selectedDates.endDate === "" ? new Date() : new Date(selectedDates.endDate),
      key: "selection",
    },
    compare: {
      startDate: selectedDates.prevStartDate === "" ? previous_start_date : new Date(selectedDates.prevStartDate),
      endDate: selectedDates.prevEndDate === "" ? previous_end_date : new Date(selectedDates.prevEndDate),
      key: "compare",
    },
  });


  const applyClickHandler = (state) => {
    props.setOpen(!props.open);

    dispatch(setStartDate(formatDateDisplay(state.selection.startDate)));
    dispatch(setEndDate(formatDateDisplay(state.selection.endDate)));
    dispatch(setPrevStartDate(formatDateDisplay(state.compare.startDate)));
    dispatch(setPrevEndDate(formatDateDisplay(state.compare.endDate)));
  };

  const staticRanges = createStaticRanges([
    // ...defaultStaticRanges,
    {
      label: "Today",
      range: () => ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: moment().subtract(1, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: moment().startOf("week").toDate(),
        endDate: moment().endOf("week").toDate(),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
        endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: moment().subtract(7, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 28 Days",
      range: () => ({
        startDate: moment().subtract(28, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(30, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: moment().subtract(90, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: moment().subtract(12, "months").startOf("month").toDate(),
        endDate: moment().subtract(1, "months").endOf("month").toDate(),
      }),
    },
    {
      label: "Last Calendar Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
    {
      label: "This Year (Jan - Today)",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
  ]);

  return (
    <Wrapper>
      <div
        onClick={() => props.setOpen(!props.open)}
        className="datepicker_wrapper"
      >
        <div className="datepicker_section">
          <div className="datepicker">
            <div className="date_title">
              {formatDateDisplay(state.selection.startDate)}
            </div>
            <div className="date_subtitle">to</div>
            <div className="date_title">
              {formatDateDisplay(state.selection.endDate)}
            </div>
          </div>
          <CalendarIcon className="icon" />
        </div>
      </div>
      {props.open && (
        <div className="datepicker_dropdown_wrapper">
          <DateRangePicker
            className="datepicker_calender"
            editableDateInputs={true}
            onChange={(item) => setState({ ...state, ...item })}
            direction={"vertical"}
            scroll={{ enabled: true }}
            ranges={[state.selection, state.compare]}
            color="#11a1fd"
            rangeColors={["#11a1fd", "#FF9931"]}
            staticRanges={staticRanges}
            maxDate={new Date()}
          // disabledDates={[new Date()]}
          />
          <div className="datepicker_footer">
            <div className="btn_wrapper">
              <Button handleClick={() => props.setOpen(!props.open)} className="secondary_danger_btn">
                Cancel
              </Button>
            </div>
            <div className="btn_wrapper">
              <Button handleClick={() => applyClickHandler(state)} className="primary_btn">
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
export default DatePicker;
