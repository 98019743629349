import React, { Component } from 'react'
import Select from 'react-select';
import './dropdown.scss';
import Variables from "../../../../../styles/mainStyle.scss";

export class DailydataDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdown_options: this.props.dropdown_options,
            dropdown_placeholder: this.props.dropdown_placeholder,
            selected_options: this.props.selected_options,
            dp_value: "",
            customStyles: {
                control: (base, state) => ({
                    ...base,
                    flex: 1,
                    autosize: false,
                    background: Variables.light,
                    borderRadius: 7,
                    height: 40,
                    borderColor: state.isFocused ? Variables.primary500 : Variables.gray50,
                    boxShadow: state.isFocused ? null : null,
                    textColor: Variables.primary500,
                    "&:hover": {
                        borderColor: state.isFocused
                            ? Variables.primary500
                            : Variables.primary500,
                        cursor: "pointer",
                    },
                }),
                menu: (base) => ({
                    ...base,
                    autosize: false,
                    borderRadius: 7,
                    marginTop: 5,
                    zIndex: 999,
                    // width: 300,
                }),

                menuList: (base) => ({
                    ...base,
                    autosize: false,
                    fontSize: 14,
                }),

                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                        ...styles,
                        autosize: false,
                        backgroundColor: isFocused ? Variables.dropdownBg : null,
                        color: isFocused
                            ? Variables.dropdownOptionFocused
                            : Variables.dropdownOptions,
                    };
                },
            },
        }
    }

    onTrigger = (event) => {
        this.props.parentCallback(event.value);
        this.setState({ dp_value: event.value })
        return false;
    }

    render() {
        return (
            <div style={{ width: "200px" }}>
                <Select
                    maxMenuHeight={200}
                    autosize={false}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    placeholder={this.state.dropdown_placeholder}
                    options={this.state.dropdown_options}
                    onChange={this.onTrigger}
                    defaultValue={this.state.selected_options}
                    styles={this.state.customStyles}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                />
            </div>
        )
    }
}
