import PropTypes, { func, object } from "prop-types";

import Wrapper from "../../helpers/Wrapper";
import ExportIcon from "../../../assets/icons/ExportIcon";
import { useRef, useState } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as jsonexport from "jsonexport/dist";
import { IAEvent_Export_Visit } from "../../../utils/IAEvents";
import * as XLSX from "xlsx";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import moment from "moment";
import { headerFormatter } from "../../../constants/headerFormatter";

const ExportDropdown = ({
  tab,
  subTab,
  platform,
  sectionId,
  moduleName,
  sectionData,
  sectionName,
  subModuleName,
  sectionDisabled,
  sectionDataHeaders,
}) => {
  const catMenu = useRef(null);

  function isDate(sDate) {
    var d = moment(sDate);
    return d.isValid();
  }

  function headerHandler(data) {
    if (data.length === 0) return [];
    var temp3 = [];
    var sss = Object.keys(data[0]);
    var sss1 = Object.values(data[0]);

    var ref = []


    // foo:
    // for (let i = 0; i < sss.length; i++) {
    //   loo:
    //   for (let j = 0; j < headerFormatter.length; j++) {

    //     if (sss[i] === headerFormatter[j].label) {
    //       temp3.push(headerFormatter[j].value)

    //       ref.push(headerFormatter[i].label)
    //       continue foo;

    //     } else {
    //       if (!sss[i].includes("_") && !temp3.includes(sss[i]) && /[^a-z]/.test(sss[i])) {

    //         if (ref.includes(sss[i])) {
    //           temp3.push(sss[i])
    //           break;
    //         }
    //       }
    //     }

    //   }
    // }



    outerloop: for (let i = 0; i < sss.length; i++) {

      for (let j = 0; j < headerFormatter.length; j++) {

        if (sss[i] === headerFormatter[j].label) {
          if (sss[i] === "label" && isDate(sss1[i])) {
            temp3.push("Date");
            continue outerloop;
          }
          temp3.push(headerFormatter[j].value);
          continue outerloop;
        } else if ((j === headerFormatter.length - 1) && !sss[i].includes("_") && !temp3.includes(sss[i]) && /[^a-z]/.test(sss[i])) {
          temp3.push(sss[i]);
          continue outerloop;
        } else if ((j === headerFormatter.length - 1)) {
          temp3.push(sss[i])
        }

      }
    }


    return temp3;
  }

  function headerStringHandler(data) {
    if (data === undefined || data === null) return "";
    var str = "";

    for (var i = 0; i < headerFormatter.length; i++) {
      if (data === headerFormatter[i].label) {
        str = headerFormatter[i].value;
        break;
      } else {
        str = data;
      }
    }

    return str;
  }

  function NameReducer(name) {
    if (name.includes("_")) {
      return name.split("_")[1];
    } else {
      return name;
    }
  }

  const HandleXLXSDownload = () => {
    if (sectionData === undefined || sectionData === null) sectionData = [];
    IAEvent_Export_Visit(
      moduleName,
      subModuleName,
      platform,
      tab,
      subTab,
      sectionName,
      "XLXS"
    );

    const workbook = XLSX.utils.book_new();

    if (Array.isArray(sectionData)) {
      var raw_data = sectionData.map(a => ({ ...a }))
      convertToObject(raw_data, sectionName);
    } else {
      var raw_data = JSON.parse(JSON.stringify(sectionData))
      multiValArr(raw_data);
    }

    function convertToObject(data, name) {
      var obj = {};
      obj[name] = data;
      multiValArr(obj);
    }

    function multiValArr(data) {
      const rows = Object.values(data).map((item, i) => {
        let type = Object.keys(data)[i].split("_")[0];
        let extraLabel = Object.keys(data)[i].split("_")[2];


        if (NoDataAvailableChecker(data)) {
          return [];
        } else if (type === "lineChart") {
          return item.map((row) => row);
        } else if (type === "countryChart") {
          return item.map((row) => row);
        } else if (type === "SalesIndiaChart") {
          return item.map((row) => ({
            Zone: row.properties.current.zone,
            "Current Sales Volume": row.properties.current.sales,
            "Previous Sales Volume": row.properties.previous.sales,
            "Current Marketshare": row.properties.current.market_share,
            "Previous Marketshare": row.properties.previous.market_share,
          }));
        } else if (type === "TVIndiaChart") {
          return item.map((row) => ({
            Zone: row.properties.current.zone,
            ["Current Sales Volume " + extraLabel]: row.properties.current.sales,
            ["Previous Sales Volume " + extraLabel]: row.properties.previous.sales,
            ["Current GRP " + extraLabel]: row.properties.current.grp,
            ["Previous GRP " + extraLabel]: row.properties.previous.grp,
            ["Current Spends " + extraLabel]: row.properties.current.spends,
            ["Previous Spends " + extraLabel]: row.properties.previous.spends,
          }));
        }
        else if (type === "TVHaleonIndiaChart") {
          return item.map((row) => ({
            "Market Name": row.properties.current.title,
            ["Current GRP "]: row.properties.current.grp,
            ["Previous GRP "]: row.properties.previous.grp,
            ["Current Spends "]: row.properties.current.spends,
            ["Previous Spends "]: row.properties.previous.spends,
            ["Current Sales Volume "]: row.properties.current.monthly_sales_vol,
            ["Previous Sales Volume "]: row.properties.previous.monthly_sales_vol,
            ["Current SOV % "]: row.properties.current.sov,
            ["Previous SOV % "]: row.properties.previous.sov,
          }));
        }
        else if (type === "MarketIndiaChart") {
          return item.map((row) => ({
            ...(row.properties.current.state ? {
              ["State Name"]: row.properties.current.state
            } : {}),
            ["Market Name"]: row.properties.current.market_name,
            "Current Sales Volume": row.properties.current.sales_volume,
            "Previous Sales Volume": row.properties.previous.sales_volume,
            "Current TV Spends": row.properties.current.tv_spends,
            "Previous TV Spends": row.properties.previous.tv_spends,
            "Current TV GRP": row.properties.current.tv_grp,
            "Previous TV GRP": row.properties.previous.tv_grp,
            "Current TV SOV": row.properties.current.tv_sov,
            "Previous TV SOV": row.properties.previous.tv_sov,
            "Current Digital Spends": row.properties.current.digital_spends,
            "Previous Digital Spends": row.properties.previous.digital_spends,
            "Current Digital Impressions": row.properties.current.digital_impressions,
            "Previous Digital Impressions": row.properties.previous.digital_impressions,
          }));
        }
        else if (type === "IdfcMarketIndiaChart") {
          return item.map((row) => ({
            ["State Name"]: row.properties.current.state,
            "Current Vists": row.properties.current.visits,
            "Previous Vists": row.properties.previous.visits,
            "Current Unique Visitors": row.properties.current.unique_visitors,
            "Previous Unique Visitors": row.properties.previous.unique_visitors,
            "Current TV Spends": row.properties.current.tv_spends,
            "Previous TV Spends": row.properties.previous.tv_spends,
            "Current TV GRP": row.properties.current.tv_grp,
            "Previous TV GRP": row.properties.previous.tv_grp,
            "Current Digital Spends": row.properties.current.digital_spends,
            "Previous Digital Spends": row.properties.previous.digital_spends,
            "Current Digital Impressions": row.properties.current.digital_impressions,
            "Previous Digital Impressions": row.properties.previous.digital_impressions,
          }));
        }
        else if (type === "PieofPie") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.subData[i].label]: row.subData[i].value,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.subData;
            return merged;
          });
        }
        else if (type === "PieofPieDynaLabels") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [headerStringHandler(row.subData[i].label) + extraLabel]: row.subData[i].value,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.subData;
            return merged;
          });
        }

        else if (type === "MarketsPieofPie") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;
            var el = {
              ["Brand Name" + extraLabel]: row.label
            }

            for (var i = 0; i < len; i++) {
              arr.push({
                [headerStringHandler(row.subData[i].label1) + extraLabel]: row.subData[i].value1,
                [headerStringHandler(row.subData[i].label2) + extraLabel]: row.subData[i].value2,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged
          });
        }


        else if (type === "DevicePieofPie") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;
            var el = {
              device: row.label,
              value: row.value,
            };

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.subData[i].label]: row.subData[i].value,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged;
          });
        } else if (type === "DevicePieofPieActiveDropdown") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;
            var el = {
              device: row.label,
              [extraLabel]: row.value,
            };

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.subData[i].label]: row.subData[i].value,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged;
          });
        }

        else if (type === "PieOfPieDynaLabels") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;
            var el = {
              label: row.label,
              value: row.value,
            };

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.subData[i].label + extraLabel]: row.subData[i].value,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged;
          });
        }


        else if (type === "ContentPie") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;
            var el = {
              content_type: row.label,
              value: row.value,
            };

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.subData[i].label]: row.subData[i].value,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged;
          });
        } else if (type === "CampaignObjectivePie") {
          return item.map((row) => {
            var arr = [];
            var len = row.subData?.length;

            var el = {
              platform_name: row.label,
              value: row.value,
            };

            for (var i = 0; i < len; i++) {
              if (row.subData[i].label === "APP_INSTALLS") {
                arr.push({
                  ["APP-INSTALLS"]: row.subData[i].value,
                });
              } else {
                arr.push({
                  [row.subData[i].label]: row.subData[i].value,
                });
              }

            }

            var merged = Object.assign(el, ...arr);
            delete merged.subData;
            return merged;
          });
        } else if (type === "WebsiteCards") {
          return item.map((row) => {
            var arr = [];
            var len = row.sub_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.sub_data[i].label + " Curent Value"]:
                  row.sub_data[i].currentValue,
                [row.sub_data[i].label + " Previous Value"]:
                  row.sub_data[i].previousValue,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.sub_data;
            return merged;
          });
        } else if (type === "WebsiteCardsCountry") {
          return item.map((row) => {
            var arr = [];
            var len = row.sub_data?.length;

            var el = {
              "Country Name": row.title
            }

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.sub_data[i].label + " Curent Value"]:
                  row.sub_data[i].currentValue,
                [row.sub_data[i].label + " Previous Value"]:
                  row.sub_data[i].previousValue,
              });
            }

            var merged = Object.assign(el, ...arr);
            delete merged.sub_data;
            return merged;
          });
        }

        else if (type === "Compare") {
          return item.map((row) => {
            var arr = [];
            var len = row.metrics_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metrics_data[i].label]: row.metrics_data[i].value,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.metrics_data;
            delete merged.id;
            delete merged.image;
            delete merged.post_link;
            delete merged.post_urn;
            delete merged.isChecked;
            delete merged.isDisabled;
            delete merged.brand_id;
            delete merged.super_viral_rank;
            delete merged.hashtag;
            delete merged.source;
            delete merged.image_url;
            delete merged.brand_logo;
            delete merged.time;


            return merged;
          });
        }
        else if (type === "AdminBucketCard") {
          var arr = [];
          item.map((row) => {
            var len = row.items === undefined || row.items === null ? 0 : row.items.length;

            for (var i = 0; i < len; i++) {
              var childLen = Object.keys(row.items[i]).length
              var obj = {}
              for (var j = 0; j < childLen; j++) {

                if (Object.keys(row.items[i])[j] === "start_date") {
                  obj["campaign_start_date"] = Object.values(row.items[i])[j]
                } else if (Object.keys(row.items[i])[j] === "end_date") {
                  obj["campaign_end_date"] = Object.values(row.items[i])[j]
                } else {
                  obj[Object.keys(row.items[i])[j]] = Object.values(row.items[i])[j]
                }
              }

              var merge = Object.assign(obj, row)
              delete merge.id;
              delete merge.platform;
              delete merge.name;
              arr.push(merge);
            }

          });
          var filterArr = arr.map(({ items, ...rest }) => ({ ...rest }))
          return filterArr;
        }

        else if (type === "TvCompare") {
          return item.map((row) => {
            var arr = [];
            var len = row.metrics_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metrics_data[i].label]: row.metrics_data[i].value,
              });
            }

            var el = {
              brand_name: row.brand_name,
              // unique_markets: row.unique_markets,
            };

            var merged = Object.assign(el, ...arr);

            return merged;
          });
        }
        else if (type === "TvHaleonCompare") {
          return item.map((row) => {
            var arr = [];
            var len = row.metrics_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metrics_data[i].label]: row.metrics_data[i].value,
              });
            }

            var el = {
              brand_name: row.brand_name,
              // unique_markets: row.unique_markets,
            };

            var merged = Object.assign(el, ...arr);

            return merged;
          });
        }

        else if (type === "SalesCompareGeo") {
          return item.map((row) => {
            var arr = [];
            var len = row.metrics_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metrics_data[i].label]: row.metrics_data[i].value,
              });
            }
            var el = {
              zone_name: row.zone_name,
              state_name: row.brand_name,
              state_code: row.state_code,
            };

            var merged = Object.assign(el, ...arr);
            delete merged.metrics_data;
            return merged;
          });
        } else if (type === "SalesCompareBrand") {
          return item.map((row) => {
            var arr = [];
            var len = row.metrics_data?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metrics_data[i].label]: row.metrics_data[i].value,
              });
            }

            var el = {
              brand_name: row.brand_name,
            };
            var merged = Object.assign(el, ...arr);
            delete merged.metrics_data;

            return merged;
          });
        } else if (type === "SalesCompareChart") {
          return item.map((row) => row);
        } else if (type === "SalesTreemap") {
          return item.map((row, i) => {
            delete row.level;
            delete row.brand_id;
            delete row.name;
            delete row.country;
            delete row.coalesce;
            return row;
          });
        }
        else if (type === "MarketTreemap") {
          return item.map((row) => ({
            ...(row.level === 2 ? {
              "platform": row.name,
            } : {
              "market": row.name,
            }),
            "market_code": row.market_code,
            ["Sales Vol." + extraLabel]: row.monthly_sales_vol,
            ["Impressions" + extraLabel]: row.impressions,
            ["Spends" + extraLabel]: row.spends,
            ["Growth Percentage" + extraLabel]: row.growth_percentage
          }));
        }
        else if (type === "IdfcMarketTreemap") {
          return item.map((row) => ({
            state: row.name,
            total_visits_with_events: row.cc_success_configurations,
            ["Impressions" + extraLabel]: row.impressions,
            ["Spends" + extraLabel]: row.spends,
            ["Growth Percentage" + extraLabel]: row.growth_percentage
          }));
        }
        else if (type === "TVBrandWiseTreemapHaleon") {
          return item.map((row) => ({
            ...(row.level === 1 ? {
              "brand_name": row.name,
              ["GRP" + extraLabel]: row.grp,
              ["Spends" + extraLabel]: row.spend,
              ["Growth Percentage" + extraLabel]: row.growth_percentage,
            } : row.level === 2 ? {
              "genre": row.name,
              ["GRP" + extraLabel]: row.grp,
              ["Spends" + extraLabel]: row.spend,
              ["Growth Percentage" + extraLabel]: row.growth_percentage,
            } : {
              "channel_name": row.name,
              ["GRP " + extraLabel]: row.grp,
              ["Spends " + extraLabel]: row.spend,
              ["Growth Percentage " + extraLabel]: row.growth_percentage,
            }),
          }));
        }

        else if (type === "TVBrandWiseTreemap") {
          return item.map((row) => {
            delete row.level;
            delete row.brand_id;
            delete row.name;
            delete row.value

            return row;
          });
        }

        else if (type === "PlatformCards") {
          return item.map((row) => ({
            "Platform Name": row.cardTitle,
            [row.rowOneTitle + " Current Value"]: row.currentValue,
            [row.rowOneTitle + " Previous Value"]: row.previousValue,
            [row.rowTwoTitle + " Current Value"]: row.second_currentValue,
            [row.rowTwoTitle + " Previous Value"]: row.second_previousValue,
          }));
        } else if (type === "TopThumbnailCards") {
          return item.map((row) => ({
            platform: row.platform,
            post_description: row.post_description,
            durations: row.durations,
            video_views: row.video_views,
          }));
        } else if (type === "AllThumbnailCards") {
          return item.map((row) => ({
            // platform: row.platform,
            duration: row.duration,
            total_views: row.total_views,
            post_description: row.post_description || row.caption,
          }));
        } else if (type === "SummaryCard") {
          return item.map((row) => ({
            Label: row.label,
            "Current Value": row.currentValue,
            "Previous Value": row.previousValue,
          }));
        }
        else if (type === "SummaryCardDynaLabels") {
          return item.map((row) => ({
            Label: row.label,
            ["Current Value" + (extraLabel === "" ? "" : extraLabel)]: row.currentValue,
            ["Previous Value" + (extraLabel === "" ? "" : extraLabel)]: row.previousValue,
          }));
        }
        else if (type === "VideoSummaryCard") {
          return item.map((row) => ({
            "Video KPI Metric Name": row.label,
            "Current Value": row.currentValue,
            "Previous Value": row.previousValue,
          }));
        } else if (type === "TVcards") {
          return item.map((row) => ({
            "Brand Name": row.cardTitle,
            [row.rowOneTitle + " Current Value"]: row.currentValue,
            [row.rowOneTitle + " Previous Value"]: row.previousValue,
            [row.rowTwoTitle + " Current Value"]: row.second_previousValue,
            [row.rowTwoTitle + " Previous Value"]: row.second_currentValue,
          }));
        } else if (type === "TVHaleoncards") {
          return item.map((row) => ({
            "Brand Name": row.cardTitle,
            [row.rowOneTitle + " Current Value" + extraLabel]: row.currentValue,
            [row.rowOneTitle + " Previous Value" + extraLabel]: row.previousValue,
            [row.rowTwoTitle + " Current Value" + extraLabel]: row.second_previousValue,
            [row.rowTwoTitle + " Previous Value" + extraLabel]: row.second_currentValue,
          }));
        }
        else if (type === "TVMetricCards") {
          return item.map((row) => {
            // Label: row.label,
            // "Current Value": row.currentValue,
            // "Previous Value": row.previousValue,
            var arr = [];
            var len = row.subData?.data;
            var lenDims = row.subData?.dimension;

            for (var j = 0; j < lenDims.length; j++) {
              for (var i = 0; i < len.length; i++) {
                arr.push({
                  Date: len[i].label,
                  [lenDims[j].label]: len[i][lenDims[j].value],
                });
              }
            }

            var merged = Object.assign(row, ...arr);

            return merged;
          });
        } else if (type === "AgeBarChart") {
          return item.map((row) => ({
            "Age-Bucket": row.label,
            "Video Minutes Viewed": row.value,
          }));
        } else if (type === "AgeBarChartCampaign") {
          return item.map((row) => ({
            Age: row.label,
            Value: row.value,
          }));
        } else if (type === "AgeBarChartCampaignDynalabels") {
          return item.map((row) => ({
            "age": row.label,
            [extraLabel]: row.value,
          }));
        }
        else if (type === "SocialTwitterUserClicks") {
          return item.map((row) => ({
            "date": row.label,
            ["Current " + extraLabel]: row.currentValue,
            ["Previous " + extraLabel]: row.previousValue,
          }));
        }
        else if (type === "AgeBarChartSocial") {
          return item.map((row) => ({
            Age: row.label,
            Value: row.age_count,
          }));
        } else if (type === "AgeBarChartSocialTT") {
          return item.map((row) => ({
            "age": row.label,
            [extraLabel]: row[extraLabel],
          }));
        } else if (type === "PositiveSentiment") {
          return item.map((row) => ({
            "Positive Sentiment": row.label,
            "No. of Video": row.value,
          }));
        } else if (type === "NegativeSentiment") {
          return item.map((row) => ({
            "Negative Sentiment": row.label,
            "No. of Video": row.value,
          }));
        } else if (type === "CommentSentiment") {
          return item.map((row) => ({
            Sentiment: row.label,
            "No. of Video": row.value,
          }));
        } else if (type === "SocialMentionSentiment") {
          return item.map((row) => ({
            Sentiment: row.label,
            Value: row.value,
          }));
        } else if (type === "VideoRetention") {
          return item.map((row) => ({
            "Video Duration": row.label,
            ["Viewer Retention - " + extraLabel]: row.value,
          }));
        }
        else if (type === "dynamicBar") {
          return item.map((row) => ({
            ["Label" + extraLabel]: row.label,
            ["Value" + extraLabel]: row.value,
          }));
        }
        else if (type === "dvBar") {
          return item.map((row) => ({
            ["Label" + (extraLabel === undefined ? "" : extraLabel)]: row.label,
            ["Achieved %" + (extraLabel === undefined ? "" : extraLabel)]: row.achieved,
            ["Planned" + (extraLabel === undefined ? "" : extraLabel)]: row.planned,
            ["Delivered" + (extraLabel === undefined ? "" : extraLabel)]: row.delivered,
          }));
        }
        else if (type === "VideoRetentionYoutube") {
          return item.map((row) => ({
            "Video Duration": row.label,
            ["Viewer Retention - " + headerStringHandler(extraLabel)]: row[extraLabel.replace("-", "_")],
          }));
        }

        else if (type === "TopHashtags") {
          return item.map((row) => ({
            Hashtag: row.label,
            Count: row.value,
          }));
        } else if (type === "VideoTraffic") {
          return item.map((row) => ({
            ["Traffic " + extraLabel.split("&")[0]]: row.name,
            ["Video " + extraLabel.split("&")[1]]: row.value,
          }));
        } else if (type === "AdDuration") {
          return item.map((row) => ({
            ad_duration: row.label,
            grps: row.value,
          }));
        } else if (type === "TopGenreTv") {
          return item.map((row) => ({
            genre: row.label,
            grps: row.value,
          }));
        } else if (type === "MonthlyTraffic") {
          return item.map((row) => ({
            Month: row.label,
            ["Sessions" + extraLabel]: row.value1,
            ["New Users" + extraLabel]: row.value2,
          }));
        } else if (type === "TrafficSource") {
          return item.map((row) => ({
            Source: row.label,
            ["Current Value" + extraLabel]: row.value1,
            ["Previous Value" + extraLabel]: row.value2,
          }));
        } else if (type === "SocialFBContentType") {
          return item.map((row) => ({
            content_type: row.label,
            value: row.value,
          }));
        } else if (type === "SocialFBEngagement") {
          return item.map((row) => ({
            engagement_type: row.label,
            value: row.value,
          }));
        } else if (type === "SocialActions") {
          return item.map((row) => ({
            actions: row.label,
            value: row.value,
          }));
        } else if (type === "SocialReaction") {
          return item.map((row) => ({
            reactions: row.label,
            value: row.value,
          }));
        } else if (type === "CampaignObjDonut") {
          return item.map((row) => ({
            Objective: row.label,
            Value: row.value,
          }));
        }
        else if (type === "CampaignTypeDonut") {
          return item.map((row) => ({
            Type: row.label,
            Value: row.value,
          }));
        }
        else if (type === "CampaignPerfSource") {
          return item.map((row) => {
            var arr = [];
            var len = row.metric?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metric[i].label]: row.metric[i].value,
              });
            }

            var el = {
              platform_name: row.label,
              value: row.value,
            };
            var merged = Object.assign(el, ...arr);
            delete merged.metrics_data;

            return merged;
          });
        } else if (type === "CampaignDeviceDist") {
          return item.map((row) => ({
            device: row.label,
            value: row.value,
          }));
        } else if (type === "CampaignDeviceDistDynaLabels") {
          return item.map((row) => ({
            device: row.label,
            [extraLabel]: row.value,
          }));
        } else if (type === "CampPerfSourceDynLabels") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });
          return arr;
        } else if (type === "SocialTweetEngage") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });
          return arr;
        }

        else if (type === "SocialTwitterEngageVsImpression") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else if (key === "tweet_length" || key === "impressions") {
                obj[headerStringHandler(key)] = value;
              } else if (key !== "color") {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });
          return arr;
        }
        else if (type === "NormalTableDynaLabels") {
          return item.map(({ ...rest }) => ({
            ...rest
          }));
        }

        else if (type === "CampaignTableDynaLabels") {
          return item.map(({ name, ...rest }) => ({
            [extraLabel]: name,
            ...rest
          }));
        }
        else if (type === "TestTableDynaLabels") {
          return item.map(({ label, ...rest }) => ({
            [extraLabel]: label,
            ...rest
          }));
        }

        else if (type === "TableDynaLabels") {
          return item.map(el => Object.keys(el).reduce((a, c) => (a[`${headerStringHandler(c)}` + " " + (extraLabel === undefined ? "" : extraLabel)] = el[c], a), {}))
        }

        else if (type === "MarketTableDynaLabels") {
          return item.map(({ monthly_sales_vol, monthy_sales_vol_change, total_monthly_sales_vol_percent, total_spend_percent, ...rest }) => ({
            ...rest,
            ["Monthly Sales Vol." + extraLabel]: monthly_sales_vol,
            ["Monthly Sales Vol. Change %" + extraLabel]: monthy_sales_vol_change,
            ["Total Monthly Sales Vol. %" + extraLabel]: total_monthly_sales_vol_percent,
            ["Total Spend %" + extraLabel]: total_spend_percent,

          }))
        }
        else if (type === "DvVtrTrendAnalysis") {
          return item.map(({ label, ...rest }) => ({
            date: label,
            ...rest
          }));
        } else if (type === "DvTrendAnalysis") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ label, ...rest }) => ({
            date: label,
            ...rest
          }))
        } else if (type === "DvYTAdType") {
          return item.map((row) => ({
            date: row.label,
            [extraLabel.split("&")[0] + " - " + extraLabel.split("&")[1].toUpperCase()]: row[extraLabel.split("&")[1]],
            ["Benchmark 1: Average - " + extraLabel.split("&")[1].toUpperCase()]: row.benchmark1,
            ["Benchmark 2: Campaign Start Date - " + extraLabel.split("&")[1].toUpperCase()]: row.benchmark2,

          }));
        }

        else if (type === "DvVideoTreemap") {
          return item.map((row) => ({
            device_type: row.name,
            value: row.value,
            spends: row.spends,
            complete_views: row.complete_views,
          }));
        }
        else if (type === "DvOverallTreemap") {
          return item.map((row) => ({
            device_type: row.name,
            [extraLabel]: row.value,
          }));
        }
        else if (type === "CampObjectiveDynaLabels") {
          if (extraLabel === "empty") {
            return item.map((row) => row)
          } else {
            var arr = []
            item.map((row) => {
              var obj = {};
              for (const [key, value] of Object.entries(row)) {
                if (key === "label") {
                  obj[key] = value;
                } else {
                  obj[headerStringHandler(key) + " - " + extraLabel] = value;
                }
              }
              arr.push(obj);

            });
            return arr;
          }
        }
        else if (type === "DonutWithActiveDropdown") {
          return item.map((row) => ({
            label: row.label,
            [extraLabel]: row.value,
          }));
        }
        else if (type === "DonutWithNonActiveDropdown") {
          return item.map((row) => ({
            label: row.label,
            ["Value " + extraLabel]: row.value,
          }));
        }
        else if (type === "CityChart") {
          return item.map((row) => ({
            city: row.name,
            value: row.value,
            percentage_growth: row.percentage_growth,
          }));
        }
        else if (type === "CityChartDynaLabels") {
          return item.map((row) => ({
            city: row.name,
            [extraLabel]: row.value,
            percentage_growth: row.percentage_growth,
          }));
        }

        else if (type === "CountryChart") {
          return item.map((row) => ({
            country: row.name,
            value: row.value,
            percentage_growth: row.percentage_growth,
          }));
        }
        else if (type === "CountryChartDynaLabels") {
          return item.map((row) => ({
            country: row.name,
            [extraLabel]: row.value,
            percentage_growth: row.percentage_growth,
          }));
        }
        else if (type === "SocialPageViews") {
          return item.map((row) => ({
            current_date: row.label,
            current_value: row.current_value,
            previous_date: row.previous_date,
            previous_value: row.previous_value,
          }));
        } else if (type === "SocialFBCity") {
          return item.map((row) => ({
            city: row.name,
            likes_users: row.likes_users,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "SocialIGPage") {
          return item.map((row) => ({
            label: row.label,
            currentValue: row.currentValue,
            previousValue: row.previousValue,
            growth: row.currentGrowth,
          }));
        } else if (type === "SocialFBCountry") {
          return item.map((row) => ({
            country: row.name,
            reach: row.reach,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "SocialIGCountry") {
          return item.map((row) => ({
            country: row.name,
            followers: row.followers || row.follower,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "SocialTTCountry") {
          return item.map((row) => ({
            country: row.name,
            [extraLabel]: row.value,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "SocialIGCity") {
          return item.map((row) => ({
            city: row.name,
            followers: row.followers || row.follower,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "SocialIGVideoViews") {
          return item.map((row) => ({
            label: row.label,
            no_of_videos: row.no_of_videos,
            video_views: row.video_views,
          }));
        } else if (type === "SocialLKDPage") {
          return item.map((row) => ({
            device: row.label,
            value: row.value,
          }));
        } else if (type === "SocialLKDIndustry") {
          return item.map((row) => ({
            industry: row.label,
            value: row.followers,
          }));
        } else if (type === "SocialLKDDesignation") {
          return item.map((row) => ({
            designation: row.label,
            value: row.value,
          }));
        } else if (type === "SocialLKDObjectType") {
          return item.map((row) => ({
            object_type: row.label,
            value: row.value,
          }));
        } else if (type === "Heatmap") {
          return item.map((row) => ({
            yaxis_label: row.yaxis_label,
            xaxis_label: row.xaxis_label,
            value: row.value,
          }));
        } else if (type === "DynaLabelHeatmap") {
          return item.map((row) => ({
            yaxis_label: row.yaxis_label,
            xaxis_label: row.xaxis_label,
            ["Value - " + extraLabel]: row.value,
          }));
        }

        else if (type === "TopMentions") {
          return item.map((row) => {
            delete row.profile_image_url;
            delete row.post_link;
            return row;
          });
        } else if (type === "TopFollowers") {
          return item.map((row) => {
            delete row.follower_twitter_user_id;
            delete row.post_link;
            delete row.profile_image_url;
            return row;
          });
        } else if (type === "SocialTTEngagement") {
          return item.map((row) => {
            delete row.color;
            return row;
          });
        } else if (type === "BubbleChartSocial") {
          return item.map((row) => {
            var arr = [];
            var len = row.children?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.children[i].label]: row.children[i].value,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.children;
            return merged;
          });
        } else if (type === "CampaignSource") {
          return item.map((row) => {
            var arr = [];
            var len = row.metric?.length;

            for (var i = 0; i < len; i++) {
              arr.push({
                [row.metric[i].label]: row.metric[i].value,
              });
            }

            var merged = Object.assign(row, ...arr);
            delete merged.metric;
            return merged;
          });
        } else if (type === "VideoCity") {
          return item.map((row) => ({
            city: row.name,
            views_in_minutes: row.views_in_minutes,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "VideoCountry") {
          return item.map((row) => ({
            country: row.name,
            views_in_minutes: row.views_in_minutes,
            percentage_growth: row.percentage_growth,
          }));
        } else if (type === "VideoYTCountry") {
          return item.map((row) => ({
            country: row.name,
            no_of_views: row.no_of_views,
            percentage_growth: row.percentage_growth,
          }));
        }

        else if (type === "VideoUniqueView") {
          return item.map((row) => ({
            date: row.label,
            [headerStringHandler("total_video_views") + " - " + extraLabel]: row.total_video_views,
            [headerStringHandler("total_video_views_unique") + " - " + extraLabel]: row.total_video_views_unique,
            [headerStringHandler("total_video_10s_views") + " - " + extraLabel]: row.total_video_10s_views,
            [headerStringHandler("total_video_10s_views_unique") + " - " + extraLabel]: row.total_video_10s_views_unique,
            [headerStringHandler("total_video_30s_views") + " - " + extraLabel]: row.total_video_30s_views,
            [headerStringHandler("total_video_30s_views_unique") + " - " + extraLabel]: row.total_video_30s_views_unique,
            [headerStringHandler("total_video_complete_views") + " - " + extraLabel]: row.total_video_complete_views,
            [headerStringHandler("total_video_complete_views_unique") + " - " + extraLabel]: row.total_video_complete_views_unique,
          }));
        } else if (type === "VideoRate") {
          return item.map((row) => ({
            label: row.label,
            video_completion_rate: row.video_completion_rate,
            returning_views: row.returning_views,
            audience_retention_rate: row.audience_retention_rate,
            engagement_rate: row.engagement_rate,
          }));
        } else if (type === "VideoRateTwitter") {
          return item.map((row) => ({
            label: row.label,
            total_videos: row.total_videos,
            total_views: row.total_views,
            completion_rate: row.completion_rate,
            engagement_rate: row.engagement_rate,
          }));
        } else if (type === "VideoTweetSentiment") {
          return item.map((row) => ({
            Sentiment: row.label,
            Count: row.value,
          }));
        } else if (type === "VideoRateLinkedin") {
          return item.map((row) => ({
            date: row.label,
            total_views: row.total_views,
            engagement_rate: row.engagement_rate,
            total_no_of_videos: row.total_no_of_videos,
            video_completion_rate: row.video_completion_rate,
          }));
        } else if (type === "DashboardLeadOrigin") {
          return item.map((row) => ({
            platform: row.label,
            lead: row.value
          }));
        } else if (type === "TopPerfMarket") {
          return item.map((row) => {
            delete row.color;
            return row

          })
        }
        else if (type === "TopPerfMarketSummary") {
          return item.map((row) => ({
            ["Market : " + extraLabel]: row.market,
            ["Spends : " + extraLabel]: row.spends,
            ["Monthly Sales Vol. : " + extraLabel]: row.monthly_sales_vol,
            ["Growth : " + extraLabel]: row.growth,
          }))
        }
        else if (type === "TopPerfMarketSummaryIDFC") {
          return item.map((row) => ({
            ["State : " + extraLabel]: row.state_name,
            ["Spends : " + extraLabel]: row.spends,
            ["Impressions : " + extraLabel]: row.impressions,
            ["Visits : " + extraLabel]: row.visits,
            ["Growth : " + extraLabel]: row.growth,
          }))
        }

        else if (type === "SocialTwitterPageLists") {
          return item.map((row) => ({
            ["Followers - " + extraLabel]: row.followers,
            ["Members - " + extraLabel]: row.members
          }))
        }
        else if (type === "DateFirstFormatter") {
          return item.map(({ label, ...rest }) => ({
            date: label,
            ...rest
          }))
        }
        else if (type === "DateFirstActiveDropdownLabels") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ label, ...rest }) => ({
            date: label,
            ...rest
          }))
        }
        else if (type === "GeographyFirstActiveDropdownLabels") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "geography" || key === "state_code") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ geography, state_code, ...rest }) => ({
            geography,
            ...rest
          }))
        }
        else if (type === "BrandWiseFirstActiveDropdownLabels") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "geography" || key === "brand" || key === "month" || key === "grps") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ geography, brand, month, grps, ...rest }) => ({
            geography,
            brand,
            month,
            grps,
            ...rest
          }))
        }

        else if (type === "TestTable") {
          return item.map((row) => ({
            ["Geography" + extraLabel]: row.geography,
            ["Brand " + extraLabel]: row.brand,
            ["Month" + extraLabel]: row.month,
            ["GRPS " + extraLabel]: row.grps,
            ["+1 " + extraLabel]: row.cume_rch_curve_1,
            ["+2 " + extraLabel]: row.cume_rch_curve_2,
            ["+3 " + extraLabel]: row.cume_rch_curve_3,
            ["+4 " + extraLabel]: row.cume_rch_curve_4,
            ["+5 " + extraLabel]: row.cume_rch_curve_5,
          }));
        }





        else if (type === "DateFirstNonActiveDropdownLabels") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label" || key === "date") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " " + (extraLabel === undefined ? "" : extraLabel)] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ label, ...rest }) => ({
            date: label,
            ...rest
          }))
        }
        else if (type === "CampaignTableCokeHaleon") {
          return item.map(({ name, reach, ...rest }) => ({
            [extraLabel]: name,
            reach: reach,
            ...rest,
          }));
        }
        else if (type === "CokeSummaryOverallImpressions") {
          return item.map((row) => ({
            from: row.from,
            to: row.to,
            value: row.value,
          }));
        } else if (type === "CokeSummaryDigitalDetails") {
          return item.map((row) => ({
            label: row.label,
            ["Value " + extraLabel]: row.value,
            // var arr = [];
            // var len = row.metric.length;
            // var el = {
            //   platform: row.label,
            //   [extraLabel]: row.value,
            // };

            // for (var i = 0; i.length < len; i++) {
            //   arr.push({
            //     [row.metric[i].label]: row.metric[i].value,
            //   });
            // }

            // var merged = Object.assign(el, ...arr);
            // delete merged.metric;
            // return merged;
          }));
        }
        else if (type === "CokeSummaryPlannedvsActual") {
          return item.map(({ label, ...rest }) => ({
            platform: label,
            ...rest
          }));
        } else if (type === "CokeSummaryVideoSummary") {
          return item.map(({ label, ...rest }) => ({
            label,
            ...rest
          }));
        }
        else if (type === "CokeSummaryHashtag") {
          return item.map((row) => ({
            ["Hashtag"]: row.label,
            ["Facebook : " + extraLabel]: row.facebook,
            ["Instagram : " + extraLabel]: row.instagram
          }));
        } else if (type === "CokeSummaryPtNonPt") {
          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "label") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " - " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ label, ...rest }) => ({
            label,
            ...rest
          }))
        } else if (type === "DvOverallContentPos") {
          return item.map(({ label, value }) => ({
            "content_position": label,
            [extraLabel]: value
          }));
        }
        else if (type === "DvOverallAppUrl") {
          return item.map(({ label, value }) => ({
            "app_url": label,
            [extraLabel]: value
          }));
        }
        else if (type === "CountryTable") {
          return item.map(({ name, ...rest }) => ({
            country: name,
            ...rest
          }));
        }
        else if (type === "CityTable") {
          return item.map(({ name, ...rest }) => ({
            city: name,
            ...rest
          }));
        }
        else if (type === "StateTable") {
          return item.map((row) => ({
            state: row.name,
            impression: row.impression,
            clicks: row.clicks,
            total_conversions: row.total_conversions,
            spends: row.spends,
            views: row.views,
            complete_views_video: row.complete_views_video,
          }));
        }
        else if (type === "AmazonDonut") {
          return item.map((row) => ({
            "campaign_type": row.label,
            ["No. of Campaign" + extraLabel]: row.value,
          }));
        }
        else if (type === "AmazonCampaignTable") {
          return item.map((row) => ({
            label: row.label,
            ["Value" + extraLabel]: row.value,
          }));
        }
        else if (type === "AmazonCampDeepdive") {
          return item.map((row) => ({
            "name": row.name,
            // ["Value :" + extraLabel]: row.value,
            ["Impression :" + extraLabel]: row.impression,
            ["Sales :" + extraLabel]: row.sales,
            ["Spends :" + extraLabel]: row.spends,
            ["Total Campaign :" + extraLabel]: row.total_campaign,
          }));
        }
        else if (type === "AmazonCampaignBubble") {
          return item.map((row) => ({
            ["Campaign Name"]: row.label,
            ["Campaign Type :" + extraLabel]: row.campaign_type,
            ["Value :" + extraLabel]: row.value,
            ["Impressions :" + extraLabel]: row.impression,
            ["Sales :" + extraLabel]: row.sales,
            ["Spends :" + extraLabel]: row.spends,
          }));
        }

        else if (type === "AmazonTableDynaLabels") {

          var arr = []
          item.map((row) => {
            var obj = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === "xyz") {
                obj[key] = value;
              } else {
                obj[headerStringHandler(key) + " : " + extraLabel] = value;
              }
            }
            arr.push(obj);

          });

          return arr.map(({ ...rest }) => ({
            ...rest
          }))
        }
        else if (type === "AmazonCampaignType") {
          return item.map((row) => ({
            "date": row.label,
            ["ACOS " + extraLabel]: row.acos,
            ["CTR " + extraLabel]: row.ctr,
            ["Spends " + extraLabel]: row.spends,
            ["Sales " + extraLabel]: row.sales,
            ["Impression " + extraLabel]: row.impression,
            ["Clicks " + extraLabel]: row.clicks,
            ["Orders " + extraLabel]: row.orders,
            ["Total Campaigns " + extraLabel]: row.total_campaign,
            ["NTB Units " + extraLabel]: row.ntb_units,
            ["Total New To Purchases Clicks " + extraLabel]: row.total_new_to_brand_purchases_clicks,
          }));
        }

        else if (type === "CountryTableDynaLabels") {
          return item.map((row) => ({
            "country": row.name,
            [extraLabel]: row.value,
            "Change": row.percentage_growth
          }));
        }
        else if (type === "StateTableDynaLabels") {
          return item.map((row) => ({
            "state": row.name,
            [extraLabel]: row.value,
            "Change": row.percentage_growth
          }));
        }
        else if (type === "HaleonCountryTable") {
          return item.map((row) => ({
            "country": row.country,
            "impressions": row.impressions,
            "clicks": row.clicks,
            "conversions": row.conversions,
            "engagements": row.total_engagement,
            "spends": row.spends,
            "engagement_rate": row.engagement_rate,
            "ctr": row.ctr,
            "Interaction Rate": row.interaction_rate,

          }));
        }
        else if (type === "HaleonStateTable") {
          return item.map((row) => ({
            "state": row.region,
            "impressions": row.impressions,
            "clicks": row.clicks,
            "conversions": row.conversions,
            "engagements": row.total_engagement,
            "spends": row.spends,
            "engagement_rate": row.engagement_rate,
            "ctr": row.ctr,
            "Interaction Rate": row.interaction_rate,
          }));
        }
        else if (type === "OrmVolumeTimeDay") {
          return item.map((row) => ({
            "Time of the Day": row.label,
            ["Current Mentions" + extraLabel]: row.currentValue,
            ["Previous Mentions" + extraLabel]: row.previousValue,
          }));
        }
        else if (type === "OrmVolumeDayWeek") {
          return item.map((row) => ({
            "Day of Week": row.label,
            ["Mentions" + extraLabel]: row.mention_count,
            ["Positive  Mentions" + extraLabel]: row.positive_mention_count,
            ["Negative Mentions" + extraLabel]: row.negative_mention_count,
          }));
        }
        else if (type === "OrmVolumeSource") {
          return item.map((row) => ({
            "Platform": row.label,
            ["Current Mentions" + extraLabel]: row.currentValue,
            ["Previous Mentions" + extraLabel]: row.previousValue,
          }));
        }
        else if (type === "OverallConverseTable") {
          return item.map((row) => ({
            "Brand Name": row.label,
            ["Value" + extraLabel]: row.value,
          }));
        }
        else if (type === "OverallConversePackedCirlce") {
          var parentLen = item.children.length;
          var arr = [];
          for (var i = 0; i < parentLen; i++) {
            var childLen = item.children[i].children.length;
            for (var j = 0; j < childLen; j++) {
              var obj = {};
              obj["source"] = item.children[i].label
              obj["brand_name"] = item.children[i].children[j].label;
              obj["value"] = item.children[i].children[j].value;

              arr.push(obj);
            }

          }
          return arr;


        }
        else if (type === "WordCloudFormat") {
          return item.map((row) => ({
            [extraLabel.split("-")[1] === undefined ? extraLabel + " Word" : extraLabel.split("-")[0] + " Word " + extraLabel.split("-")[1]]: row.label,
            [extraLabel.split("-")[1] === undefined ? extraLabel + " Value" : extraLabel.split("-")[0] + " Value " + extraLabel.split("-")[1]]: row.value,
            [extraLabel.split("-")[1] === undefined ? extraLabel + " Sentiment" : extraLabel.split("-")[0] + " Sentiment " + extraLabel.split("-")[1]]: row.sentiment,
          }));
        }
        else if (type === "MarketOverallMedia") {
          return item.map(({ digital_meta_impressions_percent, tv_impressions_percent, digital_dv360_impressions_percent, label, ...rest }) => ({
            date: label,
            ...rest,
            "Digital Meta Impressions %": digital_meta_impressions_percent,
            "TV Impressions %": tv_impressions_percent,
            "Digital DV360 Impressions %": digital_dv360_impressions_percent
          }))
        }
        else if (type === "BrandPerfOverview") {
          return item.map(el => Object.keys(el).reduce((a, c) =>
            c === "label" ?
              (a[`Brand Name` + extraLabel] = el[c], a)

              :
              (a[`${headerStringHandler(c)}` + extraLabel] = el[c], a)

            , {}))


        } else if (type === "TopGenreTvHaleon") {
          return item.map((row) => ({
            ["Genre " + extraLabel]: row.label,
            ["GRPs " + extraLabel]: row.value,
          }));
        } else if (type === "AdDurationWiseTVHaleon") {
          return item.map((row) => ({
            ["AD Duration " + extraLabel]: row.label,
            ["GRPs " + extraLabel]: row.value,
          }));
        } else if (type === "TvHaleonAdPosition") {
          return item.map((row) => ({
            ["Label" + extraLabel]: row.label,
            ["Value" + extraLabel]: row.value,
          }));
        } else if (type === "TVHaleonPtNPTTable") {
          return item.map((row) => ({
            "genre": row.title,
            [row.label1 + extraLabel]: row.value1,
            [row.label2 + extraLabel]: row.value2,
          }));
        } else if (type === "TVHaleonPtNPTChart") {
          return item.map((row) => ({
            "genre": row.label,
            ["PT" + extraLabel]: row.value1,
            ["NPT" + extraLabel]: row.value2,
          }));
        }


        else if (type === "CumuReachTvHaleon") {
          return item.map((row) => ({
            "label": row.label,
            ["Reach " + extraLabel]: row.value,
          }));
        } else if (type === "TvHaleonSummaryTable") {
          return item.map((row) => ({
            "ad_name": row.ad_name,
            "grp": row.grp,
            ["Spends " + extraLabel]: row.spend,
            ["Reach+1 " + extraLabel]: row.reach_1,
            ["Cprp " + extraLabel]: row.cprp,
            ["Prime Time " + extraLabel]: row.prime_time,
            ["Premium Share " + extraLabel]: row.premium_share,
          }));
        }
        else if (type === "TvCampaignSummary") {
          return item.map((row) => ({
            "campaign_name": row.campaign_name,
            "creative": row.creative,
            "grp": row.grp,
            "spends": row.spends,
            "cpm": row.cpm,
            "impressions": row.impressions,
            "cprp": row.cprp,
            "prime_time": row.prime_time,
            "hsm_share": row.hsm_share,
          }));
        }
        else if (type === "DvStateTableCoke") {
          return item.map((row) => ({
            "state": row.state,
            "spends": row.spends,
            "complete_views": row.complete_views,
            "views": row.views,
            "vtr": row.vtr,
            "cpv": row.cpv,
            "cpcv": row.cpcv,
            "impression": row.impression,
          }));
        }
        else if (type === "DvCityTableCoke") {
          return item.map((row) => ({
            "city": row.city,
            "spends": row.spends,
            "complete_views": row.complete_views,
            "views": row.views,
            "vtr": row.vtr,
            "cpv": row.cpv,
            "cpcv": row.cpcv,
            "impression": row.impression,
          }));
        }

        else if (type === "SocialFbPostEngage") {
          return item.map(({ label, comment_count, ...rest }) => ({
            date: label,
            comments: comment_count,
            ...rest
          }));
        }

        else {
          return item.map((row) => row);
        }
      });

      rows.map((row, i) => {
        let worksheet = XLSX.utils.json_to_sheet(row);
        XLSX.utils.book_append_sheet(workbook, worksheet, NameReducer(Object.keys(data)[i]));
        XLSX.utils.sheet_add_aoa(worksheet, [headerHandler(row)], { origin: "A1", });
        worksheet["!cols"] = [{ wpx: 100 }];
      });
    }

    setTimeout(() => setShowDropdownOptions(false), 10);
    XLSX.writeFile(workbook, `${NameReducer(sectionName)}.xlsx`, {
      compression: true,
    });
  };

  // DROPDOWN VISIBILITY STATES
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  const closeOpenMenus = (e) => {
    if (
      catMenu.current &&
      showDropdownOptions &&
      !catMenu.current.contains(e.target)
    ) {
      setShowDropdownOptions(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  // TO SHOW AND HIDE DROPDOWN ON CLICK
  const openExportDropdown = () => {
    if (sectionDisabled === true) {

    } else {
      setShowDropdownOptions(!showDropdownOptions);
    }
  };

  const handleImageDownload = async (type) => {
    IAEvent_Export_Visit(
      moduleName,
      subModuleName,
      platform,
      tab,
      subTab,
      sectionName,
      type
    );
    // let exportSectionName = sectionName;
    let className = "bgTransparent";
    let background = null;

    if (type === "png") {
      className = "bgBorderTransparent";
      background = null;
    } else if (type === "jpg") {
      className = "bgTransparent";
      background = "#ffffff";
    } else {
      className = "bgBorderTransparent";
      background = "#ffffff";
    }


    var getId = document.getElementById(sectionId)
    // var hasHeight = !getId.className.includes("table table_striped table_bordered") ? 0 : getId.getElementsByTagName("img").length === 0 ? 50 : 75;

    var hasHeight = (window.scrollY * 0.025)
    const element = document.getElementById(sectionId),
      canvas = await html2canvas(element, {
        backgroundColor: background,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY + hasHeight,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        onclone: function (clone) {
          clone.getElementById(sectionId).classList.add(className);
        },
        height: document.getElementById(sectionId).clientHeight,
        useCORS: true,
        allowTaint: true,
      }),
      data = type === "png" ? canvas.toDataURL("image/png", 1) : canvas.toDataURL("image/jpg", 1),
      link = document.createElement("a");




    // TO GET THE NAME OF THE SECTION / PAGE
    // let exportSectionName = element.querySelector(".page_header h1").textContent;
    let exportSectionName = NameReducer(sectionName);

    // TO EXPORT AS PDF
    if (type === "pdf") {
      const componentWidth = element.offsetWidth;
      const componentHeight = element.offsetHeight;

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
      });

      pdf.internal.pageSize.width = componentWidth;
      pdf.internal.pageSize.height = componentHeight;

      pdf.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(NameReducer(sectionName) + ".pdf");
    } else {

      link.href = data;
      if (type === "png") {
        let filename = exportSectionName + ".png";
        link.download = filename;
      } else if (type === "jpg") {
        let filename = exportSectionName + ".jpg";
        link.download = filename;
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setShowDropdownOptions(false);
  };

  return (
    <Wrapper>
      <div className="export_dropdown_wrapper" ref={catMenu}>
        <div
          onClick={() => openExportDropdown()}
          data-disabled={sectionDisabled}
          className={
            showDropdownOptions
              ? "export_dropdown_btn_wrapper active"
              : "export_dropdown_btn_wrapper"
          }
        >
          <ExportIcon className={"export_dropdown_btn_icon"} />
        </div>
        {showDropdownOptions && (
          <div className="options_wrapper">
            <div onClick={() => HandleXLXSDownload()} className="tab">
              Download Excel
            </div>
            <div onClick={() => handleImageDownload("png")} className="tab">
              Download PNG
            </div>
            <div onClick={() => handleImageDownload("jpg")} className="tab">
              Download JPG
            </div>
            <div onClick={() => handleImageDownload("pdf")} className="tab">
              Download PDF
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

ExportDropdown.propTypes = {
  sectionId: PropTypes.string,
  sectionName: PropTypes.string,
};

export default ExportDropdown;
