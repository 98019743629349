import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Wrapper from '../../../helpers/wrapper';

import NumberFormatter from '../../../shared/utilities/numberformatter';

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class RiTreegraph1 extends Component {

    componentDidMount() {




        let info = this.props

        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        //let data = info['graph-data'];

        let chart = am4core.create(chart_id, am4charts.TreeMap);
        // chart.exporting.menu = new am4core.ExportMenu();

        chart.fontFamily = "Ubuntu"; //TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12; //TO CHANGE FONT SIZE OF CHART LABELS

        // Add data
        chart.data = data;

        chart.maxLevels = 1;
        chart.colors.step = 1;

        chart.colors.list = [
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red

        ];

        let level0SeriesTemplate = chart.seriesTemplates.create("0");
        level0SeriesTemplate.strokeWidth = 2;

        // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
        // create hover state
        let columnTemplate = level0SeriesTemplate.columns.template;
        let hoverState = columnTemplate.states.create("hover");

        // darken
        hoverState.adapter.add("fill", function (fill, target) {
            if (fill instanceof am4core.Color) {
                return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
            }
            return fill;
        })

        // add logo
        let image = columnTemplate.createChild(am4core.Image);
        let counter = 0;
        image.opacity = 0.15;
        image.align = "center";
        image.valign = "middle";
        image.width = am4core.percent(80);
        image.height = am4core.percent(80);


        // add adapter for href to load correct image
        image.adapter.add("href", function (href, target) {
            target.events.on("hit", function (ev) {
                if (counter == 0 && counter < 4) {
                    info.parentCallback(dataItem.treeMapDataItem.name.toLowerCase());
                    counter++;
                }

                //
            });
            counter = 0;
            let dataItem = target.parent.dataItem;
            if (dataItem) {
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
            }
        });

        let level1 = chart.seriesTemplates.create("0");
        let level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
        level1_bullet.interactionsEnabled = true;
        level1_bullet.locationY = 0.5;
        level1_bullet.locationX = 0.5;
        level1_bullet.label.text = "{name}";
        // level1_bullet.label.html = `
        // <div class="d-flex flex-column justify-content-center" style="width: 150px">
        //     <div class="w-100 d-flex flex-column justify-content-center align-items-center" style="width: 100%">
        //         <span style="font-size: 13px; color: #fff; font-weight: 600">{name}</span>
        //     </div>
        // </div>`;
        level1_bullet.label.contentWidth = 10;
        level1_bullet.label.fill = am4core.color("#fff");
        level1_bullet.background.fill = am4core.color("#000")

        let level2 = chart.seriesTemplates.create("1");
        let level2_bullet = level2.bullets.push(new am4charts.LabelBullet());
        level2_bullet.locationY = 0.5;
        level2_bullet.locationX = 0.5;
        level2_bullet.label.text = "{name}";
        level2_bullet.label.fill = am4core.color("#fff");

        let level3 = chart.seriesTemplates.create("2");
        let level3_bullet = level3.bullets.push(new am4charts.LabelBullet());
        level3_bullet.locationY = 0.5;
        level3_bullet.locationX = 0.5;
        level3_bullet.label.text = "{name}";
        level3_bullet.label.fill = am4core.color("#fff");

        chart.dataFields.value = "nsv";
        chart.dataFields.name = "name";
        chart.dataFields.color = "color";
        chart.dataFields.children = "children";
        chart.navigationBar = new am4charts.NavigationBar();
        chart.padding(-10, 20, 20, 20);
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS

        level1.tooltip.getFillFromObject = false;
        level1.tooltip.background.fill = am4core.color("#213345");
        level1.tooltip.background.stroke = am4core.color("#213345");
        level1.tooltip.label.fill = am4core.color("#ffffff");
        level1.tooltip.label.fontSize = 12;

        // level1.columns.template.tooltipHTML = "<table> <tr> <td>{nsv}</br>NSV</td> <td>&nbsp;</td> <td>{value}</br>ASP</td> </tr> <tr> </tr> </table> <hr> <table> <tr> <td>{units_sold}</br>Units Sold</td> <td>&nbsp;</td> <td>{nsv_mix}</br>NSV Mix</td> </tr> </table> ";

        level1.columns.template.tooltipHTML = `
        <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
            <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
                <span style="font-size: 13px; color: #fff; font-weight: 400">{name}</span>
            </div>
            <div class="text-left d-flex flex-row justify-content-between" style="width: 150px; padding:10px">
                <div class="d-flex flex-column justify-content-start">
                    <span style="font-size: 13px; color: #fff; font-weight: 400">Session</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{value}</span>

                </div>
               
            </div>
            <hr style="margin: 4px 0"/>
            <div class="text-left d-flex flex-row justify-content-between align-items-center" style="width: 150px; padding:10px">
                
                <div class="d-flex flex-column justify-content-start">
                    
                    <span style="font-size: 13px; color: #fff; font-weight: 400">Conversion Rate</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{conversion_rate}%</span>
                </div>
            </div>
        </div>
        </div>  
        `;

        level2.tooltip.getFillFromObject = false;
        level2.tooltip.background.fill = am4core.color("#213345");
        level2.tooltip.background.stroke = am4core.color("#213345");
        level2.tooltip.label.fill = am4core.color("#ffffff");
        level2.tooltip.label.fontSize = 12;

        level3.tooltip.getFillFromObject = false;
        level3.tooltip.background.fill = am4core.color("#213345");
        level3.tooltip.background.stroke = am4core.color("#213345");
        level3.tooltip.label.fill = am4core.color("#ffffff");
        level3.tooltip.label.fontSize = 12;


    }

    handleBreadcrumb = (evt) => {
        this.props.parentCallback2(evt)
    }

    render() {

        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;
        let graph_data = this.props.graph_data


        return (
            <Wrapper>
                <Breadcrumb>
                    {
                        this.props.Deep_Dive_BreadCrumb.map((item, index) => (
                            <Breadcrumb.Item key={index} onClick={() => { this.handleBreadcrumb(index + 1) }}>{item.toUpperCase()}</Breadcrumb.Item>
                        ))
                    }
                </Breadcrumb>

                <div id={chart_id} className={chart_class}></div>
            </Wrapper>
        )
    }
}
