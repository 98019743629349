import React, { Component } from 'react'
import Col from "react-bootstrap/esm/Col";
import Accordion from '../../shared/components/accordion/Accordion';
import IconClose from '../../../assets/images/icons/closeicon'
import Lable from 'react-bootstrap/FormLabel';
import IconFilter from '../../../assets/images/icons/filterIcon';


export default class RiFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            business_category_list: this.props.business_category,
            category_list: this.props.category,
            season_list: this.props.season,
            stock_list: this.props.stock,
            business_sub_dept_list: this.props.busi_sub_dept,
            gender_list: this.props.gender,
            product_list: this.props.product,
            brand_list: this.props.brand,
            colour_list: this.props.color_description,
            size_list: this.props.size_description,
            limit: 10,
            showMessage: false,
            searchTerm: '',
            searchUpdatedProductsList: [],
            businessCategoryCheckedList: {},
            categoryCheckedList: {},
            seasonCheckedList: {},
            stockTypeCheckedList: {},
            businessSubDepartmentCheckedList: {},
            genderCheckedList: {},
            productCheckedList: {},
            brandsCheckedList: {},
            colourCheckedList: {},
            sizeCheckedList: {}
        }

    }

    _showMessage = (bool) => {
        this.setState({
            showMessage: bool
        });
    }

    showViewMore() {
        this.setState({
            limit: this.state.limit + 200
        })
    }

    editSearchTerm = (search) => {
        // this.setState({ searchTerm: e.target.value })
        if (!search) return this.state.product_list;
        // return this.state.product_list.filter(lable => lable.toLowerCase().includes(this.state.search.toLowerCase()))

        return this.state.product_list.filter(label => {
            this.state.searchUpdatedProductsList = label.toLowerCase().includes(search.toLowerCase())
            // return (
            //     this.state.searchUpdatedProductsList = label.toLowerCase().includes(search.toLowerCase())
            // ),
        });

    }

    dynamicSearch = () => {
        return this.state.product_list.filter(lable => lable.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
    }

    onClickCategoryFilter = () => {
        //0
        const a = this.state.business_category_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.busi_category;
        });
        // const a=this.state.businessCategoryCheckedList;
        //1
        const b = this.state.category_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.category;
        });
        //  const b=this.state.categoryCheckedList;
        //2
        const c = this.state.season_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.season_new;
        });
        // const c=this.state.seasonCheckedList;
        //3
        const d = this.state.stock_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.stock_type_new;
        });
        // const d=this.state.stockTypeCheckedList;
        //4
        const e = this.state.business_sub_dept_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.stock_type_new;
        });
        // const e=this.state.businessSubDepartmentCheckedList;
        //5
        const f = this.state.gender_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.gender;
        });
        // const f=this.state.genderCheckedList;
        //6
        const g = this.state.product_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.product;
        });
        //  const g=this.state.productCheckedList;
        //7
        const h = this.state.brand_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.brand_new;
        });
        //  const h=this.state.brandsCheckedList;
        //8
        const i = this.state.colour_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.color_description;
        });
        //   const i=this.state.colourCheckedList;
        //9
        const j = this.state.size_list.filter(function (i) {
            return i.checked === true
        }).map(function (i) {
            return i.name.size_description;
        });
        //    const j=this.state.size_list;

        this.props.parentCallback([a, b, c, d, e, f, g, h, i, j]);
        this._showMessage(false);

    }

    onClickclearCategoryFilter = () => {
        this.state.business_category_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.category_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.season_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.stock_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.business_sub_dept_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.gender_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.product_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.brand_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.colour_list.map(function (i) {
            i.checked = false;
            return i;
        });
        this.state.size_list.map(function (i) {
            i.checked = false;
            return i;
        });

        this._showMessage(false);
        this.onClickCategoryFilter();
    }

    // handleCheckboxbusinessCategory_old = event => {
    //     let state = this.state;
    //     state.businessCategoryCheckedList[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }
    // handleCheckboxCategory_old = event => {
    //     let state = this.state;
    //     state.categoryCheckedList[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }
    // handleCheckboxSeason_old = event => {
    //     let state = this.state;
    //     state.seasonCheckedList[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }
    // handleCheckboxStockType_old = event => {
    //     let state = this.state;
    //     state.stockTypeCheckedList[event.target.value] = event.target.checked;
    //     this.setState(state);
    // }

    handleCheckboxbusinessCategory = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.business_category_list[id].checked = event.target.checked;
        //state.businessCategoryCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxCategory = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.category_list[id].checked = event.target.checked;
        // state.categoryCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }

    handleCheckboxSeason = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.season_list[id].checked = event.target.checked;
        //  state.seasonCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxStockType = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.stock_list[id].checked = event.target.checked;
        // state.stockTypeCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxBusinessSubDepartment = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.business_sub_dept_list[id].checked = event.target.checked;
        // state.businessSubDepartmentCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxGender = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.gender_list[id].checked = event.target.checked;
        //state.genderCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxProduct = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.product_list[id].checked = event.target.checked;
        //state.productCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxBrands = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.brand_list[id].checked = event.target.checked;
        //state.brandsCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxColour = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.colour_list[id].checked = event.target.checked;
        // state.colourCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }
    handleCheckboxSize = event => {
        let state = this.state;
        const id = event.target.getAttribute("dataid")
        state.size_list[id].checked = event.target.checked;
        //state.sizeCheckedList[event.target.value] = event.target.checked;
        this.setState(state);
    }

    render() {
        let product = this.state.product_list.slice(0, this.state.limit);
        let brand = this.state.brand_list.slice(0, this.state.limit);
        let colour = this.state.colour_list.slice(0, this.state.limit);
        let size_list = this.state.size_list.slice(0, this.state.limit);

        const color = ['#000000', '#f1a9c4', '#e8e6cf', '#e5c74a', '#9fa8ab', '#0074d9', '#ffffff', '#915039', '#d2b48c', '#5eb160', '#3c4477', '#ffe5b4', '#d34b56', '#b3b3b3', '#b03060', '#eadc32', '#cc9c33', '#ede6b9', '#f2f2f2', '#3d9970', '#800080'];


        return (
            <div>
                <button className="icz-btnfilter" onClick={this._showMessage.bind(null, true)}><IconFilter className="icz-rifiltericon" /></button>
                {this.state.showMessage && (
                    <div className="icz-rifiltercontainer">
                        <div className="icz-rifilterclose">
                            <h4 className="icz-rifiltertitle">Filters</h4>
                            <a onClick={this._showMessage.bind(null, false)}><IconClose className="icz-btnfilterclose" /></a>
                        </div>

                        <Col className="icz-rifilterdatacontainer">
                            <Accordion
                                className="accordion w-100"
                                selectedIndex={this.state.selectedIndex}
                            >
                                <div data-header="Business Category" className="accordion-item ">
                                    <div className="icz-rifilterdata">
                                        {this.state.business_category_list.map((item, businessCategoryListIndex) => (
                                            <Lable key={businessCategoryListIndex} className="icz-rifilterlabel">
                                                <input key={businessCategoryListIndex}
                                                    className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.busi_category}
                                                    onChange={this.handleCheckboxbusinessCategory}
                                                    checked={item.checked}
                                                    dataid={businessCategoryListIndex}
                                                /> {item.name.busi_category}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Category" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {this.state.category_list.map((item, categoryListIndex) => (
                                            <Lable key={categoryListIndex} className="icz-rifilterlabel">
                                                <input key={categoryListIndex}
                                                    className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.category}
                                                    onChange={this.handleCheckboxCategory}
                                                    checked={item.checked}
                                                    dataid={categoryListIndex}
                                                /> {item.name.category}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Season" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {this.state.season_list.map((item, seasonListIndex) => (
                                            <Lable key={seasonListIndex} className="icz-rifilterlabel">
                                                <input key={seasonListIndex}
                                                    className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.season_new}
                                                    onChange={this.handleCheckboxSeason}
                                                    checked={item.checked}
                                                    dataid={seasonListIndex}
                                                /> {item.name.season_new}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Stock Type" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {this.state.stock_list.map((item, stockListIndex) => (
                                            <Lable key={stockListIndex} className="icz-rifilterlabel">
                                                <input className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.stock_type_new}
                                                    onChange={this.handleCheckboxStockType}
                                                    checked={item.checked}
                                                    dataid={stockListIndex} /> {item.name.stock_type_new}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Business Sub Department" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {this.state.business_sub_dept_list.map((item, businessSubDeptListIndex) => (
                                            <Lable key={businessSubDeptListIndex} className="icz-rifilterlabel">
                                                <input className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.stock_type_new}
                                                    onChange={this.handleCheckboxBusinessSubDepartment}
                                                    checked={item.checked}
                                                    dataid={businessSubDeptListIndex} /> {item.name.stock_type_new}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Gender" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {this.state.gender_list.map((item, itemIndex) => (
                                            <Lable key={itemIndex} className="icz-rifilterlabel">
                                                <input className="icz-rifiltercheckbox" type="checkbox"
                                                    value={item.name.gender}
                                                    onChange={this.handleCheckboxGender}
                                                    checked={item.checked}
                                                    dataid={itemIndex}
                                                /> {item.name.gender}</Lable>
                                        ))}
                                    </div>
                                </div>

                                <div data-header="Product" className="accordion-item">

                                    <div className="icz-rifilterdata">
                                        {product.map((product, productIndex) => {
                                            return (
                                                <Lable key={productIndex} className="icz-rifilterlabel">
                                                    <input className="icz-rifiltercheckbox" type="checkbox"
                                                        value={product.name.product}
                                                        onChange={this.handleCheckboxProduct}
                                                        checked={product.checked}
                                                        dataid={productIndex}
                                                    /> {product.name.product} </Lable>
                                            )
                                        })}
                                        <a className="icz-link" onClick={this.showViewMore.bind(this)}>+ more</a>
                                    </div>
                                </div>

                                <div data-header="Brands" className="accordion-item">

                                    <div className="icz-rifilterdata">
                                        {brand.map((brand, brandIndex) => {
                                            return (
                                                <Lable key={brandIndex} className="icz-rifilterlabel">
                                                    <input
                                                        className="icz-rifiltercheckbox" type="checkbox"
                                                        value={brand.name.brand_new}
                                                        onChange={this.handleCheckboxBrands}
                                                        checked={brand.checked}
                                                        dataid={brandIndex} /> {brand.name.brand_new}</Lable>
                                            )
                                        })}
                                        <a className="icz-link" onClick={this.showViewMore.bind(this)}>+ more</a>
                                    </div>
                                </div>

                                <div data-header="Colour" className="accordion-item">

                                    <div className="icz-rifilterdata">
                                        {colour.map((colour, colourIndex) => {
                                            return (
                                                <Lable key={colourIndex} className="icz-rifilterlabel">
                                                    <input className="icz-rifiltercheckbox" type="checkbox"
                                                        value={colour.name.color_description}
                                                        onChange={this.handleCheckboxColour}
                                                        checked={colour.checked}
                                                        dataid={colourIndex}
                                                    /> <div className="icz-ricolordata"
                                                        style={{ backgroundColor: color[colourIndex] }} key={colourIndex} /> {colour.name.color_description}</Lable>
                                            )

                                        })}
                                        <a className="icz-link" onClick={this.showViewMore.bind(this)}>+ more</a>
                                    </div>
                                </div>

                                <div data-header="Size" className="accordion-item">
                                    <div className="icz-rifilterdata">
                                        {size_list.map((item, sizeListindex) => {
                                            return (
                                                <Lable key={sizeListindex} className="icz-rifilterlabel">
                                                    <input className="icz-rifiltercheckbox" type="checkbox"
                                                        value={item.name.size_description}
                                                        onChange={this.handleCheckboxSize}
                                                        checked={item.checked}
                                                        dataid={sizeListindex}
                                                    /> {item.name.size_description}</Lable>)
                                        })}
                                        <a className="icz-link" onClick={this.showViewMore.bind(this)}>+ more</a>

                                    </div>
                                </div>
                            </Accordion>
                        </Col>

                        <div className="icz-ribtncontainer">
                            <button className="icz-btnfilterclear" onClick={() => this.onClickclearCategoryFilter()}>Clear</button>
                            <button className="icz-btnfilterapply" onClick={() => this.onClickCategoryFilter()}>Apply</button>
                        </div>
                    </div>


                )}
            </div>
        )
    }
}
