import React, { Component } from 'react';
import { Tab } from "../../../shared/components/tabs/tab";
import { TabNav } from "../../../shared/components/tabs/tabnav";
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import IconGrowthdefault from '../../../../assets/images/icons/growthdefaultIcon';
import IconDeGrowthdefault from '../../../../assets/images/icons/degrowthdefaultIcon';
import Top5Movers from './Top5Movers';
import Bottom5Movers from './Bottom5Movers';

export default class TopMoversTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubNav: 'Top Movers',
      Top5Movers: this.props.Top5Movers,
      Bottom5Movers: this.props.Bottom5Movers,
    }
  }

  setSelectedSubNav = (tab) => {
    this.setState({ selectedSubNav: tab });
  }

  render() {
    return (
      <div className="icz-datacontainer">
        <div className="icz-sectiontabscontainer">
          <div className="icz-tabscontrols">
            <TabNav tabs={['Top Movers', 'Bottom Movers']} icon={[<IconGrowthdefault className="icz-tabicon" />, <IconDeGrowthdefault className="icz-tabicon" />]} selected={this.state.selectedSubNav} setSelected={this.setSelectedSubNav}>
              <Tab isSelected={this.state.selectedSubNav === 'Top Movers'}>
                <Top5Movers Top5Movers={this.state.Top5Movers} />
              </Tab>
              <Tab isSelected={this.state.selectedSubNav === 'Bottom Movers'}>
                <Bottom5Movers Bottom5Movers={this.state.Bottom5Movers} />
              </Tab>
            </TabNav>
          </div>
        </div>
      </div>
    );
  }
}
