import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");


export default class HeatMap extends Component {
    componentDidMount() {

        let info = this.props;
        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        /**
  * ---------------------------------------
  * This demo was created using amCharts 4.
  * 
  * For more information visit:
  * https://www.amcharts.com/
  * 
  * Documentation is available at:
  * https://www.amcharts.com/docs/v4/
  * ---------------------------------------
  */

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        var chart = am4core.create(chart_id, am4charts.XYChart);
        chart.maskBullets = false;

        chart.dataFields.name = "name";

        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "hour";
        yAxis.dataFields.category = "weekday";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 10;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 10;
        chart.fontSize = 12;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "hour";
        series.dataFields.categoryY = "weekday";
        series.dataFields.value = "value";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;

        var bgColor = new am4core.InterfaceColorSet().getFor("background");

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        columnTemplate.stroke = bgColor;
        columnTemplate.tooltipText = `Day: {weekday}[/]

        Hour: {hour}[/]
        
        
        {name}: {value.workingValue.formatNumber('#.')}[/]`;
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);

        // Tooltip 
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.stroke = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.minWidth = 150;
        series.tooltip.label.padding(10, 5, 10, 5);
        series.tooltip.label.fontSize = 12;
        series.tooltip.position = "pointer";

        
        series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color("#ffffff"),
            max: am4core.color("#038673")
        });

        // heat legend
        var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = series;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;

        // heat legend behavior
        series.columns.template.events.on("over", function (event) {
            handleHover(event.target);
        })

        series.columns.template.events.on("hit", function (event) {
            handleHover(event.target);
        })

        function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        series.columns.template.events.on("out", function (event) {
            heatLegend.valueAxis.hideTooltip();
        })

        chart.data = data;
        

    }


    render() {

        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class} ></div>
        )
    }
}
