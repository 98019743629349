import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl';
import './map.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiaWNvZ3oiLCJhIjoiY2w4bXcyMXpqMGdsMTNvdGhiMnQ3ejFkMCJ9.-SsLsOIq-P8laHo8YTzsFQ';

export default class CountryMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 95,
            lat: 37,
            zoom: 0.3
        };
        this.mapContainer = React.createRef();

    }
    componentDidMount() {
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                // {
                //     'type': 'Feature',
                //     'geometry': {
                //         'type': 'Point',
                //         'coordinates': [-0.118092, 51.509865]
                //     },
                //     'properties': {
                //         'title': 'United Kingdom',
                //         'subtitle': '25',
                //         'description': 'PTAT',
                //         'subtitle2': '5%',
                //         'description2': 'PTAT Share',
                //         'marker-color': '#db7823',
                //     }
                // },

                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [78.9629, 20.5937]
                    },
                    'properties': {
                        'title': 'India',
                        'subtitle': '30',
                        'description': 'PTAT',
                        'subtitle2': '90%',
                        'description2': 'PTAT Share',
                        'marker-color': '#db7823',
                    }
                },
                // {
                //     'type': 'Feature',
                //     'geometry': {
                //         'type': 'Point',
                //         'coordinates': [55.296249, 25.276987]
                //     },
                //     'properties': {
                //         'title': 'United Arab Emirates',
                //         'subtitle': '30',
                //         'description': 'PTAT',
                //         'subtitle2': '5%',
                //         'description2': 'PTAT Share',
                //         'marker-color': '#db7823',
                //     }
                // },


            ]
        };
        const zoomThreshold = 4;
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/icogz/clatinkb1009s14o6tj43b929',
            center: [lng, lat],
            zoom: this.state.zoom,
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            attributionControl: false,
        });
        for (const feature of geojson.features) {

            // create a HTML element for each feature
            const marker = new mapboxgl.Marker()
                .setLngLat(feature.geometry.coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(
                    `<div>
            <h3>${feature.properties.title}</h3>
            <p class="mapbox-content-highlight">${feature.properties.subtitle}</p>
            <p>${feature.properties.description}</p>
            <hr/>
            <p class="mapbox-content-highlight">${feature.properties.subtitle2}</p>
            <p>${feature.properties.description2}</p>
            </div>`
                ))

                .addTo(map);
            const markerDiv = marker.getElement();
            markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
            markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
        }

        map.addControl(
            new mapboxgl.NavigationControl(), 'top-right');
        map.scrollZoom.disable();
        map.doubleClickZoom.disable();
        map.dragRotate.disable();
    }
    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class} />
            </div>
        )
    }
}

