const EcomIcon = (props) => {
    return (

        <svg className={props.className} width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 31C14.5 31.2967 14.412 31.5867 14.2472 31.8334C14.0824 32.08 13.8481 32.2723 13.574 32.3858C13.2999 32.4993 12.9983 32.5291 12.7074 32.4712C12.4164 32.4133 12.1491 32.2704 11.9393 32.0607C11.7296 31.8509 11.5867 31.5836 11.5288 31.2926C11.4709 31.0017 11.5006 30.7001 11.6142 30.426C11.7277 30.1519 11.92 29.9176 12.1666 29.7528C12.4133 29.588 12.7033 29.5 13 29.5C13.3978 29.5 13.7794 29.658 14.0607 29.9393C14.342 30.2206 14.5 30.6022 14.5 31Z" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31 31C31 31.2967 30.912 31.5867 30.7472 31.8334C30.5824 32.08 30.3481 32.2723 30.074 32.3858C29.7999 32.4993 29.4983 32.5291 29.2074 32.4712C28.9164 32.4133 28.6491 32.2704 28.4393 32.0607C28.2296 31.8509 28.0867 31.5836 28.0288 31.2926C27.9709 31.0017 28.0007 30.7001 28.1142 30.426C28.2277 30.1519 28.42 29.9176 28.6666 29.7528C28.9133 29.588 29.2033 29.5 29.5 29.5C29.8978 29.5 30.2794 29.658 30.5607 29.9393C30.842 30.2206 31 30.6022 31 31Z" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1H7L11.02 21.085C11.1572 21.7756 11.5329 22.3959 12.0813 22.8374C12.6298 23.279 13.316 23.5135 14.02 23.5H28.6C29.304 23.5135 29.9902 23.279 30.5387 22.8374C31.0871 22.3959 31.4628 21.7756 31.6 21.085L34 8.5H8.5" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default EcomIcon;
