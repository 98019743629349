import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const TraceableSankeyChart = ({ chartId, chartClass, chartData }) => {

    useLayoutEffect(() => {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new(chartId);

        // to format numbers
        root.numberFormatter.setAll({
            numberFormat: "#.##a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
                { number: 1e3, suffix: "K" },
                { number: 1e6, suffix: "M" },
                { number: 1e9, suffix: "B" },
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: [],
        });

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        // Create series
        // https://www.amcharts.com/docs/v5/charts/flow-charts/
        var series = root.container.children.push(
            am5flow.Sankey.new(root, {
                sourceIdField: "from",
                targetIdField: "to",
                valueField: "value",
                paddingRight: 100,
                idField: "id"
            })
        );

        series.nodes
            .get("colors")
            .set("colors", [
                am5.color(0x11a1fd),
                am5.color(0x5a75f9),
                am5.color(0x07c180),
                am5.color(0xff9931),
                am5.color(0x7d9eb5),
                am5.color(0x085db6),
                am5.color(0x3247b5),
                am5.color(0x038673),
                am5.color(0xb45917),
                am5.color(0x486f88),
                am5.color(0x0c7dd9),
                am5.color(0x4259d4),
                am5.color(0x06a57d),
                am5.color(0xdb7823),
                am5.color(0xa7c1d2),
                am5.color(0x4cc3fd),
                am5.color(0x8298fb),
                am5.color(0x3cd795),
                am5.color(0xffb866),
                am5.color(0xcedfe8),
            ]);

        series.nodes.labels.template.setAll({
            fontSize: 14,
            oversizedBehavior: "wrap"
        });

        series.links.template.setAll({
            fillStyle: "solid",
            fillOpacity: 0.15,
            fill: am5.color(0x000000)
        });

        // highlight all links with the same id beginning
        series.links.template.events.on("pointerover", function (event) {
            let dataItem = event.target.dataItem;
            let id = dataItem.get("id").split("-")[0];

            am5.array.each(series.dataItems, function (dataItem) {
                if (dataItem.get("id").indexOf(id) != -1) {
                    dataItem.get("link").hover();
                }
            });
        });

        series.links.template.events.on("pointerout", function (event) {
            am5.array.each(series.dataItems, function (dataItem) {
                dataItem.get("link").unhover();
            });
        });







        // Set data
        // https://www.amcharts.com/docs/v5/charts/flow-charts/#Setting_data
        series.data.setAll(chartData);

        // Make stuff animate on load
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    );
};

TraceableSankeyChart.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    chartData: PropTypes.array,
};

export default TraceableSankeyChart;
