import React, { Component } from 'react';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { SalesCard } from '../../../shared/components/cards/risalescard/risalescard';
import RiSalesHBarChart from '../../../shared/components/charts/RiSalesHBarChart';
import RiSalesVBarChart from '../../../shared/components/charts/RiSalesVBarChart';
import PieChartwithLegends from '../../../shared/components/charts/RiPieChartwithLegends';


export class StoreSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store_sale_data: this.props.store_sale_data,
      salesdata: [{ "category": "<2.5k", "value": "3.2" }, { "category": "2.5-3k", "value": "2.2" }, { "category": "3-4k", "value": "4.4" }, { "category": ">4k", "value": "3.8" }],
      basketdata: [{ "category": "1", "value": "35" }, { "category": "2-3", "value": "58" }, { "category": "3-4", "value": "52" }, { "category": "4<", "value": "37" }],
      btfdata: [{ "name": "BTF%", "value": 55 }, { "name": "Non BTF%", "value": 45 }],

    }
  }

  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }

    //create daata
    let store_sale_data_ats_vs_bills = this.state.store_sale_data.ats_vs_bills;
    let store_sale_data_basketsize_vs_bills = this.state.store_sale_data.basketsize_vs_bills;
    let store_sale_data_markdown_vs_units_sold = this.state.store_sale_data.markdown_vs_units_sold;
    let store_sale_data_asp_vs_units_sold = this.state.store_sale_data.asp_vs_units_sold;

    let store_sale_data_btf_and_non_btf_members = this.state.store_sale_data.btf_and_non_btf_members;

    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={[
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="ATS Value"
              chart={
                <RiSalesHBarChart
                  chart_id="ats_value"
                  chart_class="icz-risalescardchart"
                  Ytitle={"(value in K)"}
                  metric_1={"No. of Bills"}
                  graph-data={store_sale_data_ats_vs_bills}
                  bar_color={"#FF9931"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Basket Size"
              chart={<RiSalesVBarChart
                chart_id="basket_size"
                chart_class="icz-risalescardchart"
                Ytitle={"(value in K)"}
                metric_1={"No. of Units"}
                graph-data={store_sale_data_basketsize_vs_bills}
                bar_color={"#11a1fd"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Markdown"
              chart={<RiSalesHBarChart
                chart_id="markdown"
                chart_class="icz-risalescardchart"
                Ytitle={"(value in K)"}
                metric_1={"No. of Bills"}
                graph-data={store_sale_data_markdown_vs_units_sold}
                bar_color={"#FF9931"} isnumber={'decimal_percentage'} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="BTF & NonBTF Sale"
              chart={<PieChartwithLegends
                card_id="btf_nonbtf"
                card_class="icz-risalescardchart"
                graph-data={store_sale_data_btf_and_non_btf_members}
                section1_color={"#5a75f9"}
                section2_color={"#07c180"} />}
            />,

            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="ASP"
              chart={<RiSalesHBarChart
                chart_id="asp"
                chart_class="icz-risalescardchart"
                Ytitle={"(value in K)"}
                metric_1={"No. of Bills"}
                graph-data={store_sale_data_asp_vs_units_sold}
                bar_color={"#11a1fd"} />}
            />
          ]}>
        </Carousel>

      </div>
    );
  }

}
