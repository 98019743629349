import { useContext, useEffect, useState, useMemo } from "react";

import { useSelector } from "react-redux";

import { format } from "date-fns";

import AuthContext from "../../store/authContext";

import axios from "../../components/helpers/axios";

// UI
import Wrapper from "../../components/helpers/Wrapper";
/* import Papa from "papaparse"; */

// LOADERS
import Loader from "../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";

/* import {
  OverallMetricCardsSampleData,
  CampaignObjectivewiseDistributionSampleData,
  CampaignObjectivewiseDistributionSampleDataLabels,
  PerformanceBySourceSampleData,
  PerformanceBySourceSampleDataLabels,
  PerformanceBySourceDetailsSampleData,
  CampaignOverviewSampleData,
  CampaignSummarySampleData,
  CampaignSummarySampleData2,
  CampaignSummarySampleData1,
  CampaignOverviewSampleData3,
  CampaignOverviewSampleData2,
  CampaignOverviewSampleData1,
  AllDataObj,
} from "../../data/mi/campaign/campaignSampleData"; */
import Dropdown from "../../components/ui/dropdown/Dropdown";
import ErrorBoundary from "../../utils/ErrorBoundary";
import Datatable from "../../components/ui/tables/Datatable";
import Button from "../../components/ui/Button";
import PerformanceIcon from "../../assets/icons/PerformanceIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import {
  TwitterLogo,
  InstagramLogo,
  YoutubeLogo,
  API_URL,
  INFLUENCERSSAMPLEDATA,
} from "../../constants/constants";
import { CSVLink, CSVDownload } from "react-csv";
import SearchIcon from "../../assets/icons/SearchIcon";

import { IAEvent_Dropdown_Visit } from "../../utils/IAEvents";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";

const Influencers = () => {
  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const campaignSummaryTableHeaders = [
    { name: "Profile Picture", field: "profile_pic", sortable: false },
    { name: "Full Name", field: "full_name" },
    { name: "Profile", field: "profile" },
    { name: "Score", field: "score" },
    { name: "Followers", field: "followers" },
    { name: "Avg Likes", field: "avg_likes" },
    { name: "Avg Views", field: "avg_views" },
    { name: "ER", field: "er" },
    { name: "Location", field: "location" },
    { name: "Categories", field: "categories", sortable: false },
    { name: "Profile Link", field: "profile_link", sortable: false },
  ];

  const campaignSummaryTwitterTableHeaders = [
    { name: "Profile Picture", field: "profile_pic", sortable: false },
    { name: "Full Name", field: "full_name" },
    { name: "Profile", field: "profile" },
    { name: "Score", field: "score" },
    { name: "Followers", field: "followers" },
    { name: "Avg Favourites", field: "avg_likes" },
    { name: "Avg Retweets", field: "avg_views" },
    { name: "ER", field: "er" },
    { name: "Location", field: "location" },
    { name: "Categories", field: "categories", sortable: false },
    { name: "Profile Link", field: "profile_link", sortable: false },
  ];

  const campaignSummaryYoutubeTableHeaders = [
    { name: "Profile Picture", field: "profile_pic", sortable: false },
    { name: "Full Name", field: "full_name" },
    { name: "Profile", field: "profile" },
    { name: "Score", field: "score" },
    { name: "Followers", field: "followers" },
    { name: "Avg Likes", field: "avg_likes" },
    { name: "Avg Views", field: "avg_views" },
    { name: "ER", field: "er" },
    { name: "Location", field: "location" },
    { name: "Categories", field: "categories", sortable: false },
    { name: "Profile Link", field: "profile_link", sortable: false },
  ];

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeadersData, setCampaignSummaryTableHeadersData] = useState(campaignSummaryTableHeaders);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const tableDataFileHeaders = [
    { name: "Profile Picture", field: "profile_pic", sortable: false },
    { name: "Full Name", field: "full_name" },
    { name: "Profile Handle", field: "user_handle" },
    { name: "Score", field: "score" },
    { name: "Followers", field: "followers" },
    { name: "Avg Likes", field: "avg_likes" },
    { name: "Avg Views", field: "avg_views" },
    { name: "ER", field: "er" },
    { name: "Location", field: "location" },
    { name: "Categories", field: "categories" },
    { name: "Profile", field: "profile_url" },
    { name: "Platform", field: "platform" },
  ];

  const [file, setFile] = useState([]);
  const [tableDataFile, setTableDataFile] = useState([]);
  const fileReader = new FileReader();
  const [toggle, setToggle] = useState(false);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("file", file);

    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    var tempArr = [];

    fetch(API_URL + "qoruz/upload-file/?", requestOptions)
      .then((res) => res.json())
      .then((res) => setTableValue(res))
      .catch((error) =>
        console.log("error", error)
      );
    // setToggle(!toggle);
  };

  // CAMPAIGN OVERVIEW DROPDOWN
  // CAMPAIGN OVERVIEW DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState({
    value: "instagram",
    label: "Instagram",
    icon: InstagramLogo,
  });

  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    {
      value: "instagram",
      label: "Instagram",
      icon: InstagramLogo,
    },
    {
      value: "twitter",
      label: "Twitter",
      icon: TwitterLogo,
    },
    {
      value: "youtube",
      label: "Youtube",
      icon: YoutubeLogo,
    },
  ];

  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Influencers", null, null, null, "Summary", e.label)

  };

  // const tableDataFileDropdown = []

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "instagram") {
      setCampaignSummary(campaignSummaryAllDropdownsData.twitter);
      setCampaignSummaryTableHeadersData(campaignSummaryTableHeaders);
    } else if (selectedCampaignSummaryDropdownOption.value === "twitter") {
      setCampaignSummary(campaignSummaryAllDropdownsData.twitter);
      setCampaignSummaryTableHeadersData(campaignSummaryTwitterTableHeaders);
    } else if (selectedCampaignSummaryDropdownOption.value === "youtube") {
      setCampaignSummary(campaignSummaryAllDropdownsData.youtube);
      setCampaignSummaryTableHeadersData(campaignSummaryYoutubeTableHeaders);
    } else {
      setCampaignSummary([]);
      setCampaignSummaryTableHeadersData([]);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const tableDataFileHandler = useMemo(() => {
    if (tableDataFile.length === 0) return campaignSummary;
    const newTableDataFileDropdown = tableDataFile.filter((platform) => {
      return platform.platform === selectedCampaignSummaryDropdownOption.value;
    });
    return newTableDataFileDropdown;
  }, [tableDataFile, selectedCampaignSummaryDropdownOption, campaignSummary]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [selectedDates, selectedCampaignSummaryDropdownOption]);

  useEffect(() => {
    // tableDataFileHandler();
    fetchCampaignSummaryHandler();
  }, [selectedDates, selectedCampaignSummaryDropdownOption, tableDataFile]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        platform: selectedCampaignSummaryDropdownOption.value,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/qoruz/search/", config);
      setCampaignSummary(res.data);
      //setCampaignSummary(res.data.twitter);
      setCampaignSummaryAllDropdownsData(res.data);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable =
    (<Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="section_card_header">
          <h2>&nbsp;</h2>
        </div>
        <div className="section_card_body position-relative">
          <Loader loaderType="smallTableLoader" />
          {/* <NoDataAvailableLoader chartType="tableChartType" /> */}
        </div>
      </div>
    </Wrapper >
    )
  let csvDownloadBtn;
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    csvDownloadBtn = (
      <CSVLink
        data={
          tableDataFileHandler.length === 0 ? campaignSummary : tableDataFileHandler
        }
        filename={"influencers.csv"}
        className="theme_button secondary_btn"
        target="_blank"
      >
        Export
      </CSVLink>
    );
  }
  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    if (NoDataAvailableChecker(tableDataFileHandler)) {
      campaignSummaryTable = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="section_card_header">
            <h2>&nbsp;</h2>
          </div>
          <div className="section_card_body position-relative">
            <NoDataAvailableLoader chartType="tableChartType" />
          </div>
        </div>
      </Wrapper>
    } else {


      campaignSummaryTable = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={""}
            hideBoxShadow={true}
            tableHeader={campaignSummaryTableHeadersData}
            tableData={tableDataFileHandler}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search here"}
            hrefTitle="View More"
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="section_card_header">
            <h2>&nbsp;</h2>
          </div>
          <div className="section_card_body position-relative">
            <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />;
          </div>
        </div>
      </Wrapper >
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="section_card_header">
            <h2>&nbsp;</h2>
          </div>
          <div className="section_card_body position-relative">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </Wrapper >
    )
  }

  const [searchUrl, setSearchUrl] = useState("");
  const [tableValue, setTableValue] = useState([]);

  const handleChange = (e) => {
    var result = e.target.value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[1];
    if (result.startsWith("@", 0)) {
      result = result.slice(1)
    } else {
      result = result
    }
    setSearchUrl(result);
  };

  const clearInput = () => {
    document.getElementById("qoruz_search_input").value = ''
    setSearchUrl("")
    fetchCampaignSummaryHandler()
  }

  const handleSubmit = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        platform: selectedCampaignSummaryDropdownOption.value,
        handle: searchUrl,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("qoruz/search/", config);
      setCampaignSummary(res.data);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  return (
    <div className="content">
      <div className="content_section">
        <Wrapper>
          <div className="page_header">
            <div className="grid grid_col_3 ">
              <h1 className="grid col_span_1">Influencers</h1>

              <div className="grid col_span_2 flex justify_end align_center">
                <div className="searchbar_wrap flex align_center">
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={campaignSummaryOptions}
                    className="form_dropdown section_dropdown"
                    value={selectedCampaignSummaryDropdownOption}
                    selectedOptions={selectedCampaignSummaryDropdownOption}
                    setStatedropdown={campaignSummaryDropdownSelectionHandler}
                  />
                  <input
                    id="qoruz_search_input"
                    className="form_control input focus_active"
                    placeholder="Search By URL"
                    onChange={handleChange}
                  />
                  <span
                    className="search"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <SearchIcon className={"search_icon"} />
                  </span>
                  {searchUrl.length !== 0 &&
                    <span className="clear_input" onClick={() => clearInput()}>X</span>
                  }
                </div>
                {/* <div className="flex justify_end file-button-section">
                  <Button
                    className="theme_button primary_btn upload-btn import-btn"
                    handleClick={() => setToggle(!toggle)}
                    icon={<UploadIcon className="btn_icon" />}
                  />

                  {csvDownloadBtn}
                </div> */}

                <div className="btn_wrapper width_fit_content influencerBt">
                  <Button
                    className="primary_btn"
                    handleClick={() => setToggle(!toggle)}
                    label="Import">
                    Import
                  </Button>
                </div>
                <div className="btn_wrapper width_fit_content influencerBt">
                  {csvDownloadBtn}
                  {/* <Button
                    className="secondary_btn"
                    handleClick={function noRefCheck() { }}
                    label="Export"
                  >
                    Export
                  </Button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="page_content">
            {toggle && (
              <div className="grid grid_cols_3 grid_margin_bottom toast_card_wrap">
                <div className="grid col_span_2">
                  {tableValue.length !== 0 && (
                    <div className="badge_list_wrapper flex">
                      <div className="badge_wrapper">
                        <span className="button_badge greenBadgeBg">
                          Valid Count : &nbsp;<strong>{tableValue.csv_data.length}</strong>
                        </span>
                      </div>

                      <div className="badge_wrapper">
                        <span className="button_badge redBadgeBg">
                          Invalid Count : &nbsp;<strong>{tableValue.invalid_csv_data.length}</strong> &nbsp; | &nbsp;
                          <CSVLink className="tertiary_btn" filename={"invalid_influencers_entries_" + format(new Date(), "yyyy-MM-dd-HH:mm:ss") + ".csv"} data={tableValue.invalid_csv_data}>
                            Download Invalid Entries
                          </CSVLink>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="grid col_span_4">
                  <div className="grid col_span_1 flex justify_center align_center">
                    <a href={INFLUENCERSSAMPLEDATA} className="tertiary_btn" download>
                      Download Sample
                    </a>
                  </div>
                  <form className="grid col_span_3 fileUpload flex">
                    <input
                      type={"file"}
                      id={"csvFileInput"}
                      accept={".csv"}
                      className=""
                      onChange={handleOnChange}
                    />
                    <button
                      className="theme_button secondary_btn button-upload"
                      onClick={(e) => {
                        handleOnSubmit(e);
                      }}
                    >
                      IMPORT CSV
                    </button>
                  </form>
                </div>
              </div>
            )}
            <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
              <div className="section_card_header">
                <h2>&nbsp;</h2>
              </div>
              <div className="section_card_body position-relative">
                {campaignSummaryTable}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default Influencers;
