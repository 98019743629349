import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Container, Image } from 'react-bootstrap';
import "../../ecom/ecom.scss";
import Converstion_funnel from '../../../shared/components/charts/Converstion_funnel';
import Piechart from '../../../shared/components/charts/Piechart';
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import { BarChart } from '../../../shared/components/charts/BarChart';
import { Carousel } from '../../../shared/components/carousel/carousel';

import '../../../shared/components/cards/card.scss'
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import { Link } from 'react-router-dom'
import { DailydataDropdown } from '../../../shared/components/dropdown/dailydatadropdown';
import Wrapper from '../../../helpers/wrapper';
import Treemap from '../../../shared/components/charts/TreeMap';
import { LocationBarChart } from '../../../shared/components/charts/LocationBarChart';
import SankeyChart from '../../../shared/components/charts/SankeyChart';
import DataTable from '../../../shared/components/Datatable/DataTable';
import DatePicker from '../../../shared/components/datepicker/datepicker';
import IconPdf from '../../../../assets/images/icons/pdfIcon';

import StickyLogo from '../../../../assets/images/icons/icon.png'
import { Loader } from '../../../shared/components/loader/loader';
import { API_URL_1, isMap } from '../../../helpers/constant';
import '../../../layout/pageheader/pageheader.scss'
import PageHeader from '../../../../../components/layout/pageHeader';
import { CountryCode } from '../../../../../constants/countryCurrency';
import ChartExport from '../../../shared/components/export/ChartExport';
import { DetailsCard } from '../../../shared/components/cards/detailscard/detailscard';


export class Sales extends Component {

    constructor(props) {
        super(props);
        this.state = {

            trafficeChannel: '',

            treegraph: [{ "name": "Home", "children": [{ "name": "Kitchen", "value": 150 }] }, { "name": "Men", "children": [{ "name": "Casual", "value": 350 }, { "name": "Western", "value": 400 }] }, { "name": "Women", "children": [{ "name": "Western", "children": [{ "name": "Jeans", "value": 240 }] }, { "name": "Casual", "value": 100 }] }, { "name": "Lifestyle", "value": 180 }],
            dropdown_options: [{ "value": "Channel", "label": "Channel" }, { "value": "User", "label": "User Type" }, { "value": "Device", "label": "Device" }],
            trafficpievalue: [{ "name": "Social", "value": 30 }, { "name": "Direct", "value": 30 }, { "name": "Website", "value": 50 }, { "name": "Email", "value": 40 }, { "name": "Organic", "value": 60 }],
            bargraphsession: [{ "name": "Jan", "points": 100 }, { "name": "Feb", "points": 200 }, { "name": "march", "points": 110 }, { "name": "April", "points": 100 }, { "name": "May", "points": 100 }, { "name": "June", "points": 100 }, { "name": "July", "points": 100 }, { "name": "Aug", "points": 100 }, { "name": "Sep", "points": 130 }, { "name": "Oct", "points": 140 }, { "name": "Nov", "points": 200 }, { "name": "Dec", "points": 180 }],
            linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
            locationdata: [{ "name": "Kids", "points": 100 }, { "name": "Women", "points": 100 }, { "name": "Men", "points": 100 }, { "name": "Home", "points": 130 }],
            scroll: '',
            funneldropdown_options: [{ "value": "Category", "label": "Category" }, { "value": "Category 1", "label": "Category 1" }, { "value": "Category 2", "label": "Category 2" }, { "value": "Category 3", "label": "Category 3" }, { "value": "Category 4", "label": "Category 4" }, { "value": "Category 5", "label": "Category 5" }],
            firstfunnelgraph: [{ "name": "The First", "value": 350 }, { "name": "The Secound", "value": 300 }, { "name": "The Third", "value": 150 }, { "name": "The Forth", "value": 120 }],
            sankeychart: [
                { from: "A", to: "D", value: 10 },
                { from: "B", to: "D", value: 8 },
                { from: "B", to: "E", value: 4 },
                { from: "C", to: "E", value: 3 },
                { from: "D", to: "G", value: 5 },
                { from: "D", to: "I", value: 2 },
                { from: "D", to: "H", value: 3 },
                { from: "E", to: "H", value: 6 },
                { from: "G", to: "J", value: 5 },
                { from: "I", to: "J", value: 1 },
                { from: "H", to: "J", value: 9 }
            ],
            table_header: [
                { 'name': 'Categories', 'field': "category", 'sortable': true },
                { 'name': `Revenue (${this.getCountryCurrency()})`, 'field': "revenue", 'sortable': true },
                { 'name': 'Quantity', 'field': "quantity", 'sortable': true },
                { 'name': 'Sales Mix %', 'field': "salesmix", 'sortable': true },
            ],
            table_data: [
                { category: 'Earphones', revenue: '26526506', quantity: '3615', salesmix: '3.51' },
                { category: 'Stationary', revenue: '5491563', quantity: '2345', salesmix: '0.7270687067' },
                { category: 'Accessories', revenue: '1321581', quantity: '1290', salesmix: '0.1749738988' },
                { category: 'Computers', revenue: '565649831', quantity: '984534', salesmix: '74.89057142' },
            ],

            is_scroll: false,

            first_start_date: props.start_date,
            first_end_date: props.end_date,
            second_start_date: props.previous_start_date,
            second_end_date: props.previous_end_date,
            isDatePickerOpen: false,

            // DELETE THESE STATES ONCE DATA LOADED FROM API
            is_trafficpievalue_loaded: true,
            is_linesparkgraph_loaded: true,
            is_treegraph_loaded: true,
            is_locationdata_loaded: true,
            is_firstfunnelgraph_loaded: true,
            is_sankeyChart_loaded: true,
            is_table_data_loaded: true,

            daily_metrics_name: "Channel",
            pie_click_data: "",
            funnel_dropdown_click: "",
            channel_data: [],
            last_table_data: [],
            barchartdata: [],
            sanky_data: [],
            category_sale_share_data_tree: [],
            // is_group_loaded:false,
            is_channel_loaded: false,
            is_user_loaded: false,
            is_device_loaded: false,

            is_graph_loaded: false,
            is_matrices_loaded: false,
            is_table_loaded: false,
            is_barchart_loaded: false,
            is_sanky_loaded: false,
            is_category_sale_share_tree_data_loaded: false,
            is_funnel_loaded: false,
            is_category_loaded: false,
            all_categories: '',
            formatMoney(n) {
                return (Math.round(n * 100) / 100).toLocaleString();
            }

        }


    }

    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            this.setState({
                first_start_date: nextProps.start_date,
                first_end_date: nextProps.end_date,
                second_start_date: nextProps.previous_start_date,
                second_end_date: nextProps.previous_end_date,
            });
        }
    }

    componentDidMount() {
        this.getCategorySaleShareData();
        this.getSalesGroup();
        this.getAllMetrices();
        this.getlinesparkgraph();
        this.getLastTable();
        this.getLinechart();
        this.getSankeyChartData();

        this.getCategories();
        this.getCategoryFunnel();
        this.getFunnel();



        this.forceUpdate();
        window.addEventListener("scroll", this.handleScroll);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.start_date !== this.props.start_date ||
            prevProps.end_date !== this.props.end_date ||
            prevProps.previous_start_date !== this.props.previous_start_date ||
            prevProps.previous_end_date !== this.props.previous_end_date
        ) {
            this.state.is_social_card_loaded = false;
            this.state.is_category_sale_share_tree_data_loaded = false;
            this.state.is_channel_loaded = false;
            this.state.is_matrices_loaded = false;
            this.state.is_graph_loaded = false;
            this.state.is_table_loaded = false;
            this.state.is_barchart_loaded = false;
            this.state.is_sanky_loaded = false;
            this.state.is_funnel_loaded = false;
            this.state.is_category_funnel_loaded = false;

            this.getCategorySaleShareData();
            this.getSalesGroup();
            this.getAllMetrices();
            this.getlinesparkgraph();
            this.getLastTable();
            this.getLinechart();
            this.getSankeyChartData();
            this.getCategories();
            this.getCategoryFunnel();
            this.getFunnel();
        }
    }

    isNull(object) {
        for (const [key, value] of Object.entries(object)) {
            if (typeof value === "object" && value !== null) {
                this.isNull(value);
            } else if (!value) {
                object[key] = 0;
            }
        }
        return object;
    }

    datepickerHandleCallback = (childData) => {

        this.state.first_start_date = childData[0];
        this.state.first_end_date = childData[1];
        this.state.second_start_date = childData[2];
        this.state.second_end_date = childData[3];

        this.state.is_social_card_loaded = false;

        this.state.is_category_sale_share_tree_data_loaded = false;
        this.state.is_channel_loaded = false;
        this.state.is_matrices_loaded = false;
        this.state.is_graph_loaded = false;
        this.state.is_table_loaded = false;
        this.state.is_barchart_loaded = false;
        this.state.is_sanky_loaded = false;
        this.state.is_funnel_loaded = false;
        this.state.is_category_funnel_loaded = false;


        this.getCategorySaleShareData();
        this.getFunnel();
        this.getSalesGroup();
        this.getAllMetrices();
        this.getlinesparkgraph();
        this.getLastTable();
        this.getLinechart();
        this.getSankeyChartData();
        this.getCategories();
        this.getCategoryFunnel();

        this.forceUpdate();
        this.datepickerHandleCallback2();
    };

    datepickerHandleCallback2 = () => {
        this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
    };

    handleScroll = () => {
        if (window.pageYOffset > 1) {
            if (!this.state.is_scroll) {
                this.setState({ is_scroll: true });
            }
        } else {
            if (this.state.is_scroll) {
                this.setState({ is_scroll: false });
            }
        }
    };

    handleCallback = (childData) => {
        this.state.daily_metrics_name = childData;
        this.state.trafficeChannel = "";
        this.setState({
            is_channel_loaded: false,
            is_user_loaded: false,
            is_device_loaded: false,
            is_sanky_loaded: false,
            is_funnel_loaded: false,
            is_category_funnel_loaded: false,



            // this.state.is_channel_loaded= false,
            is_category_sale_share_tree_data_loaded: false,
            is_matrices_loaded: false,
            is_graph_loaded: false,
            is_table_loaded: false,
            is_barchart_loaded: false,
            // is_sanky_loaded: false,
            // is_funnel_loaded:false,
        });

        if (this.state.daily_metrics_name == 'Channel') {

            this.getSalesGroup();
            this.state.pie_click_data = "";
            this.getSankeyChartData();

        } else if (this.state.daily_metrics_name == 'User') {
            this.getUserGroup();
            this.state.pie_click_data = "";
            this.getSankeyChartData();



        } else if (this.state.daily_metrics_name == 'Device') {
            this.getDeviceGroup();
            this.state.pie_click_data = "";
            this.getSankeyChartData();




        }
        this.getCategorySaleShareData();
        // this.getSalesGroup();
        this.getAllMetrices();
        this.getlinesparkgraph();
        this.getLastTable();
        this.getLinechart();
        this.getFunnel();
        this.getCategoryFunnel();

        // this.getSankeyChartData();  
        // this.this.getFunnel();  


    }
    handledatatableCallback = (childData) => {
        this.state.funnel_dropdown_click = childData;

        this.setState({
            funneldropdown_options: childData,
            is_datatable_word_data_loaded: false,
            is_category_funnel_loaded: false,

        });

        this.getCategoryFunnel();
    }

    handlePieclick = (childData) => {
        if (this.state.pie_click_data === childData) {
            this.state.pie_click_data = ""
        } else {
            this.state.pie_click_data = childData;
        }
        this.state.trafficeChannel = childData;
        this.setState({
            is_graph_loaded: false,
            is_matrices_loaded: false,
            is_table_loaded: false,
            is_barchart_loaded: false,
            // is_channel_loaded:false,
            is_category_sale_share_tree_data_loaded: false,
            is_sanky_loaded: false,
            is_funnel_loaded: false,
            is_category_funnel_loaded: false,

        });

        this.getAllMetrices();
        this.getlinesparkgraph();
        this.getLastTable();
        this.getLinechart();
        this.getSankeyChartData();
        this.getCategorySaleShareData();
        this.getFunnel();
        this.getCategoryFunnel();


    }

    getCategories() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;

        const url = API_URL_1 + "/api/ecom/sales-category-dropdown-funnel/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let categories = result['categories']

                let category_list = []

                categories.map((info) => {
                    category_list.push({
                        'value': info['category_1'],
                        'label': info['category_1']
                    })


                })
                this.setState({
                    is_category_loaded: true,
                    category_data: category_list,
                });
            }, (error) => {
                // 
            })

    }
    // Sales Funnel 
    getFunnel() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;


        const url = API_URL_1 + "/api/ecom/sales-funnel/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json()).then((result) => {
            let funnel_list = result

            const shopping_stage = ["ALL_VISITS", "ADD_TO_CART", "CHECKOUT_WITH_CART_ADDITION", "CHECKOUT_WITHOUT_CART_ADDITION", "TRANSACTION", "TRANSACTION_WITHOUT_CHECKOUT"]
            let funnel_graph_list = []

            let a = {
                "name": "session",
                "value": result['Sessions_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with category page view",
                "value": result['Sessions_with_category_page_view_list']
            }

            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with sub-category page view",
                "value": result['Sessions_with_sub_category_page_view_list']
            }
            funnel_graph_list.push(a);

            a = {
                "name": "Sessions with product view ",
                "value": result['Sessions_with_product_view_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with Proceed to Checkout",
                "value": result['Sessions_with_Proceed_to_Checkout_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with successful transactions",
                "value": result['Sessions_with_successful_transactions_list']
            }
            funnel_graph_list.push(a);

            // funnel_graph_list.sort(function (a, b) {
            //     return b.value - a.value;
            // });



            this.setState({
                is_funnel_loaded: true,
                funnelgraph: funnel_graph_list,


            });
        })

    }

    getCategoryFunnel() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let funnel_dropdown_click = this.state.funnel_dropdown_click;


        const url = API_URL_1 + "/api/ecom/sales-category-funnel/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&funnel_dropdown_click=" + funnel_dropdown_click +
                "&daily_metrics_name=" + this.state.daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json()).then((result) => {
            let funnel_list = result

            const shopping_stage = ["ALL_VISITS", "ADD_TO_CART", "CHECKOUT_WITH_CART_ADDITION", "CHECKOUT_WITHOUT_CART_ADDITION", "TRANSACTION", "TRANSACTION_WITHOUT_CHECKOUT"]
            let funnel_graph_list = []

            let a = {
                "name": "session",
                "value": result['Sessions_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with category page view",
                "value": result['Sessions_with_category_page_view_list']
            }

            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with sub-category page view",
                "value": result['Sessions_with_sub_category_page_view_list']
            }
            funnel_graph_list.push(a);

            a = {
                "name": "Sessions with product view ",
                "value": result['Sessions_with_product_view_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with Proceed to Checkout",
                "value": result['Sessions_with_Proceed_to_Checkout_list']
            }
            funnel_graph_list.push(a);
            a = {
                "name": "Sessions with successful transactions",
                "value": result['Sessions_with_successful_transactions_list']
            }
            funnel_graph_list.push(a);

            // funnel_graph_list.sort(function (a, b) {
            //     return b.value - a.value;
            // });



            this.setState({
                is_category_funnel_loaded: true,
                categoryfunnelgraph: funnel_graph_list,


            });
        })

    }

    getUserGroup() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;

        const url = API_URL_1 + "/api/ecom/sales-group/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date,

            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let users = result['users']

                let user_list = []

                users.map((info) => {
                    user_list.push({
                        'name': info['user_type'],
                        'value': info['total_sales']
                    })


                })
                this.setState({
                    is_channel_loaded: true,
                    channel_data: user_list,
                });
            }, (error) => {
                // 
            })

    }

    getDeviceGroup() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;

        const url = API_URL_1 + "/api/ecom/sales-group/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {

                let devices = result['devices']

                let device_list = []

                devices.map((info) => {
                    device_list.push({
                        'name': info['device_category'],
                        'value': info['total_sales']
                    })


                })
                this.setState({
                    is_channel_loaded: true,
                    channel_data: device_list,
                });
            }, (error) => {
                // 
            })

    }

    getAllMetrices() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let social_platform = 'FACEBOOK';
        let sales_by = this.state.dropdown_channel_name;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-matric/"

        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&social_platform=" + social_platform +
                "&sales_by=" + sales_by +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json()).then((result) => {
            // 
            let top_platform = result['top_platform_list'][0] ? result['top_platform_list'][0]['device_category'] : '';
            let top_platform_revenue = result['top_platform_list'][0] ? result['top_platform_list'][0]['transaction_revenue'] : '';
            let top_source = result['top_source_list'][0] ? result['top_source_list'][0]['source'] : '';
            let top_source_revenue = result['top_source_list'][0] ? result['top_source_list'][0]['transaction_revenue'] : '';

            // let top_platform =''
            // let top_source =''

            let current_item_quantity = result['current_average_basket_size_metrics'][0]['abs_item_quantity']
            // let current_transction_abs = result['current_average_basket_size_metrics'][0]['abs_transactions']
            let current_revenue = result['current_overall_metrics'][0]['transaction_revenue']
            let current_transactions = result['current_overall_metrics'][0]['transactions']
            let current_sessions = result['current_overall_metrics'][0]['sessions']

            let last_item_quantity = result['last_average_basket_size_metrics'][0]['abs_item_quantity']
            // let last_transction_abs = result['last_average_basket_size_metrics'][0]['abs_transactions']
            let last_revenue = result['last_overall_metrics'][0]['transaction_revenue']
            let last_transactions = result['last_overall_metrics'][0]['transactions']
            let last_sessions = result['last_overall_metrics'][0]['sessions']
            // Total Sales		revenue
            // Transactions		transactions
            // Conversion Rate		transactions/sessions
            // Average Order Value		revenue/transactions
            // Average Basket Size		item_quantity/transactions
            let current_conversion_rate = (current_transactions / current_sessions) * 100;
            let current_average_order_value = (current_revenue / current_transactions);
            let current_average_basket_size = (current_item_quantity / current_transactions);

            let last_conversion_rate = (last_transactions / last_sessions) * 100;
            let last_average_order_value = (last_revenue / last_transactions);
            let last_average_basket_size = (last_item_quantity / last_transactions);

            this.setState({
                is_matrices_loaded: true,

                top_platform: top_platform,
                top_platform_revenue: top_platform_revenue,

                top_source: top_source,
                top_source_revenue: top_source_revenue,

                current_total_sales: current_revenue,
                last_total_sales: last_revenue,
                current_transactions: current_transactions,
                last_transactions: last_transactions,
                current_conversion_rate: current_conversion_rate,
                current_average_order_value: current_average_order_value,
                current_average_basket_size: current_average_basket_size,
                last_conversion_rate: last_conversion_rate,
                last_average_order_value: last_average_order_value,
                last_average_basket_size: last_average_basket_size,


            });

        })

    }
    getlinesparkgraph() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let social_platform = 'FACEBOOK';
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-matric/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&social_platform=" + social_platform +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json()).then((result) => {

            let overall_metrics_list = result['overall_metrics_list']
            let average_basket_size_metrics_list = result['average_basket_size_metrics_list']

            let total_sales_list = [];
            let transction_list = [];
            let conversion_list = []
            let avg_order_value_list = [];
            let avg_basket_size_list = [];



            overall_metrics_list.map((info, index) => {
                try {
                    let a = {
                        "date": info['date'],
                        "value": info['transaction_revenue']
                    }
                    total_sales_list.push(a);

                    let b = {
                        "date": info['date'],
                        "value": info['transactions']

                    }
                    transction_list.push(b);

                    let c = {
                        "date": info['date'],
                        "value": (info['transactions'] / info['sessions'])
                    }

                    conversion_list.push(c);

                    let d = {
                        "date": info['date'],
                        "value": ((info['transaction_revenue'] / info['transactions']).toFixed(2))

                    }
                    avg_order_value_list.push(d);
                    let e = {

                        "date": info['date'],
                        "value": average_basket_size_metrics_list[index]['abs_item_quantity'] / info['transactions']

                    }
                    avg_basket_size_list.push(e);


                } catch (e) {

                }



            })
            // average_basket_size_metrics_list.map((info ,index) => {

            //     try{


            //     }catch{

            //     }

            // })


            this.setState({
                is_graph_loaded: true,
                total_sales_graph: total_sales_list,
                transction_graph: transction_list,
                conversion_graph: conversion_list,
                avg_order_value_graph: avg_order_value_list,
                avg_basket_size_graph: avg_basket_size_list,
            });


        })

    }

    getLastTable() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-table/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {

                let table_data = result['table_data']

                let table_list = []

                let sum = 0

                table_data.map((info) => {
                    sum += info['item_revenue']
                })

                table_data.map((info) => {
                    table_list.push({
                        'category': info['category_1'],
                        'revenue': this.state.formatMoney(info['item_revenue']),
                        'quantity': info['item_quantity'],
                        'salesmix': ((info['item_revenue'] / sum) * 100).toFixed(2),

                    })
                })

                this.setState({ is_table_loaded: true, last_table_data: table_list });
            }, (error) => {

            })

    }

    getLinechart() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-line-chart/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {

                let line_chart_list = result['line_chart_list']

                let line_list = []

                line_chart_list.map((info) => {
                    line_list.push({
                        'name': info['category_1'],
                        'points': info['unique_purchases'],


                    })


                })
                this.setState({
                    is_barchart_loaded: true,
                    barchartdata: line_list,
                });
            }, (error) => {

            })

    }

    getCategorySaleShare() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-category-sale-share/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {

                let category_sale_share_list = result['category_sale_share_list']

                let category_sale_list = []

                category_sale_share_list.map((info) => {
                    category_sale_list.push({
                        'name': info['category_1'],
                        'points': info['unique_purchases'],


                    })


                })
                this.setState({
                    is_barchart_loaded: true,
                    category_sale_share_data: category_sale_list,
                });
            }, (error) => {

            })

    }

    getSankeyChartData() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-sankey-chart/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {

                let sanky_chart_list = result['sanky_chart_list']

                let sanky_list = []

                sanky_chart_list.map((info) => {
                    sanky_list.push({
                        'from': info['column1'],
                        "to": info['column2'],
                        'value': info['item_revenue']


                    })


                })
                this.setState({
                    is_sanky_loaded: true,
                    sanky_data: sanky_list,
                });
            }, (error) => {

            })

    }
    getSalesGroup() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;

        const url = API_URL_1 + "/api/ecom/sales-group/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let channels = result['channels']

                let channel_list = []

                channels.map((info) => {
                    if (info['total_sales'] > 0) {
                        channel_list.push({
                            'name': info['channel_name'],
                            'value': info['total_sales']
                        })

                    }



                })
                this.setState({
                    is_channel_loaded: true,
                    channel_data: channel_list,
                });

            }, (error) => {

            })

    }

    getCategorySaleShareData() {
        let start_date = this.state.first_start_date;
        let end_date = this.state.first_end_date;
        let previous_start_date = this.state.second_start_date;
        let previous_end_date = this.state.second_end_date;
        let pie_click_data = this.state.pie_click_data;
        let daily_metrics_name = this.state.daily_metrics_name;

        const url = API_URL_1 + "/api/ecom/sales-category-sale-share/"
        fetch(url, {
            method: 'POST',
            body: "start_date=" + start_date +
                "&end_date=" + end_date +
                "&last_start_date=" + previous_start_date +
                "&last_end_date=" + previous_end_date +
                "&pie_click_data=" + pie_click_data +
                "&daily_metrics_name=" + daily_metrics_name,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let category_sales_share = result['category_sale_share_list']

                let list_category_1 = []

                category_sales_share.map((info) => {
                    if (!list_category_1.includes(info['category_1'])) {
                        list_category_1.push(info['category_1'])
                    }
                });

                let final_list = []
                list_category_1.map((category_1) => {
                    let children_category_1 = []
                    let list2 = []
                    category_sales_share.map((info) => {
                        if (info['category_1'] == category_1) {
                            let children_category_3 = []

                            if (!list2.includes(info["category_2"])) { // add if
                                list2.push(info["category_2"]); // add this also
                                category_sales_share.map((info3) => {
                                    if (info["category_2"] == info3['category_2']) {
                                        children_category_3.push({ name: info3["category_3"], value: info3["item_revenue"] })
                                    }
                                })
                                children_category_1.push({ name: info["category_2"], children: children_category_3 })
                            }
                        }
                    });

                    let d = { name: category_1, children: children_category_1 }
                    final_list.push(d)
                })
                this.setState({
                    is_category_sale_share_tree_data_loaded: true,
                    category_sale_share_data_tree: final_list,
                });

            }, (error) => {

            })

    }

    getCountryCurrency = () => {

        let userCountryCode = localStorage.getItem('static-country-code');
        var currValue = ''

        CountryCode.map((data) => {
            if (data.countryCode === userCountryCode) {
                currValue = data.currencySymbol
            }
        });

        return currValue;
    }



    render() {
        const CarouselSettings = {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            // initialSlide: 0,
            responsive: [

                {
                    breakpoint: 1202,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        slidesToScroll: 0,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        }
        return (
            <Wrapper>
                <div className="grid grid_cols_4 drawer_grid ri_summary_bsImport">
                    <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
                        <div className="icz-rimapsection bg-white">
                            <Col sm={12} className="icz-cardheader p-20 d-flex">
                                <Col className="icz-cardtitle">
                                    <div className="icz-title-wrap p-0">
                                        <div className="text-left">
                                            <h5 className="icz-cardtitle text-start mb-0">Sales By</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="icz-pagefilter">
                                    <div className="icz-page-dropdown justify-content-end p-0">
                                        <DailydataDropdown dropdown_options={this.state.dropdown_options} parentCallback={this.handleCallback} selected_options={this.state.dropdown_options[0]} />
                                    </div>
                                </Col>
                            </Col>
                            {this.state.is_channel_loaded == true ?
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <Piechart card_id="icz-traffic-piechart" card_class="icz-leftsectionchart" graph-data={this.state.channel_data} parentCallback={this.handlePieclick} />
                                </div>
                                : <Loader />}
                        </div>
                    </div>
                    <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer">
                        <div className="content">
                            <div className="content_section">
                                <PageHeader pageTitle={"Sales"} />
                                <div className="page_content">
                                    <div className="grid grid_cols_1 dashboard_home">
                                        <div className="grid col_span_2 icz-carouselcontainer">
                                            {this.state.is_matrices_loaded && this.state.is_graph_loaded ?
                                                <Carousel
                                                    Settings={CarouselSettings}
                                                    class={"icz-cardcontainer"}
                                                    SliderCards={[
                                                        <ChartCard
                                                            index="1"
                                                            CardClass="icz-cardwrapper"
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle={`Total Sales (${this.getCountryCurrency()})`}
                                                            RowOneCurrentValue={this.state.current_total_sales ? this.state.current_total_sales : 0}
                                                            RowOnePreviousValue={this.state.last_total_sales ? this.state.last_total_sales : 0}
                                                            RowOneTitle="sales"
                                                            IsNumber={'money'}
                                                            IsStandard={true}
                                                            chart={<Linegraph_sparkline card_id="icz-customer" card_class="icz-cardchart" graph-data={this.state.total_sales_graph} />}
                                                        />,

                                                        <ChartCard
                                                            index="2"
                                                            CardClass="icz-cardwrapper"
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle="Transactions"
                                                            RowOneCurrentValue={this.state.current_transactions ? this.state.current_transactions : 0}
                                                            RowOnePreviousValue={this.state.last_transactions ? this.state.last_transactions : 0}
                                                            RowOneTitle="sales"
                                                            IsNumber={'number'}
                                                            IsStandard={true}
                                                            chart={<Linegraph_sparkline card_id="icz-avg-basketsize" card_class="icz-cardchart" graph-data={this.state.transction_graph} />}
                                                        />,
                                                        <ChartCard
                                                            index="3"
                                                            CardClass="icz-cardwrapper"
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle="Conversion Rate"
                                                            RowOneCurrentValue={this.state.current_conversion_rate ? this.state.current_conversion_rate.toFixed(2) : 0}
                                                            RowOnePreviousValue={this.state.last_conversion_rate ? this.state.last_conversion_rate.toFixed(2) : 0}
                                                            RowOneTitle="sales"
                                                            IsNumber={'percentage'}
                                                            IsStandard={true}
                                                            chart={<Linegraph_sparkline card_id="icz-conversion-rate" isnumber={"percentage"} card_class="icz-cardchart" graph-data={this.state.conversion_graph} debug={true} />}
                                                        />,
                                                        <ChartCard
                                                            index="4"
                                                            CardClass="icz-cardwrapper "
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle={`Average Order Value (${this.getCountryCurrency()})`}
                                                            RowOneCurrentValue={this.state.current_average_order_value ? this.state.current_average_order_value.toFixed(2) : 0}
                                                            RowOnePreviousValue={this.state.last_average_order_value ? this.state.last_average_order_value.toFixed(2) : 0}
                                                            RowOneTitle="sales"
                                                            IsNumber={'number'}
                                                            IsStandard={true}
                                                            chart={<Linegraph_sparkline card_id="icz-coupon" card_class="icz-cardchart" graph-data={this.state.avg_order_value_graph} />}
                                                        />,
                                                        <ChartCard
                                                            index="5"
                                                            CardClass="icz-cardwrapper "
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle="Average Basket Size"
                                                            RowOneCurrentValue={this.state.current_average_basket_size ? this.state.current_average_basket_size.toFixed(2) : 0}
                                                            RowOnePreviousValue={this.state.last_average_basket_size ? this.state.last_average_basket_size.toFixed(2) : 0}
                                                            RowOneTitle="sales"
                                                            IsNumber={'number'}
                                                            IsStandard={true}
                                                            chart={<Linegraph_sparkline card_id="icz-avg-basket-size" card_class="icz-cardchart" graph-data={this.state.avg_basket_size_graph} />}
                                                        />,
                                                        <DetailsCard
                                                            index="7"
                                                            CardClass="icz-cardwrapper icz-wordbreak"
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle="Top Source"
                                                            RowOneCurrentValue={this.state.top_source}
                                                            RowOnePreviousValue={this.state.top_source_revenue}
                                                            RowOneTitle="Revenue"
                                                            IsNumber={true}
                                                            PercentMetric={false}

                                                        />,
                                                        <DetailsCard
                                                            index="8"
                                                            CardClass="icz-cardwrapper"
                                                            CardIcon={<IconWebsite className="icz-icon" />}
                                                            CardTitle="Top Platform"
                                                            RowOneCurrentValue={this.state.top_platform}
                                                            RowOnePreviousValue={this.state.top_platform_revenue}
                                                            RowOneTitle="Revenue"
                                                            IsNumber={true}
                                                            PercentMetric={false}
                                                        />
                                                    ]}>

                                                </Carousel>
                                                : <Loader />}
                                        </div>

                                        <div className="grid col_span_2 icz-row">
                                            <div className="grid col_span_2 icz-sectioncardwrapper">
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex justify_between">
                                                        <div className="icz-cardtitle">
                                                            Revenue by Category
                                                        </div>
                                                        <div className="icz-cardfilter icz-chartexport">
                                                            <ChartExport ChartId='revenuebyCategory' export_data={this.state.category_sale_share_data_tree} downloadName="Revenue by Category" />
                                                        </div>
                                                    </div>
                                                    <div className="">

                                                        {this.state.is_category_sale_share_tree_data_loaded ?
                                                            <Treemap card_id="revenuebyCategory" card_class="icz-sectionchart" graph-data={this.state.category_sale_share_data_tree} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid col_span_2 icz-sectioncardwrapper">
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex justify_between">
                                                        <div className="icz-cardtitle">
                                                            Transactions by Category
                                                        </div>
                                                        <div className="icz-cardfilter icz-chartexport">
                                                            <ChartExport ChartId='transactionsbyCategory' export_data={this.state.barchartdata} downloadName="Revenue by Category" />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {this.state.is_barchart_loaded ?
                                                            <LocationBarChart card_id="location-chart" card_class="icz-sectionchart" graph-data={this.state.barchartdata} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid col_span_2 icz-row">
                                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex justify_between">
                                                        <div className="icz-cardtitle">
                                                            Sales Funnel
                                                        </div>
                                                        <div className="icz-cardfilter icz-chartexport">
                                                            <ChartExport ChartId='salesFunnel' export_data={this.state.funnelgraph} downloadName="Sales Funnel" />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {this.state.is_funnel_loaded === true ?
                                                            <Converstion_funnel card_id="salesFunnel" card_class="icz-sectionchart" graph-data={this.state.funnelgraph} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>

                                        <div className="grid col_span_2 icz-row">
                                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                                <div className="icz-sectioncard">
                                                    <Col sm={12} className="icz-cardheader flex-wrap justify-content-between">
                                                        <Col lg={8} className="icz-cardtitle">
                                                            Category Wise Sales Funnel
                                                        </Col>
                                                        <Col lg={4} className="icz-cardfilter">
                                                            {this.state.is_category_loaded === true ?
                                                                <DailydataDropdown dropdown_options={this.state.category_data} parentCallback={this.handledatatableCallback} />
                                                                : <Loader />}
                                                            <ChartExport ChartId='categoryWisesalesFunnel' export_data={this.state.category_data} downloadName="Category Wise Sales Funnel" />
                                                        </Col>
                                                    </Col>

                                                    <div className="">
                                                        {this.state.is_category_funnel_loaded === true ?
                                                            <Converstion_funnel card_id="categoryWisesalesFunnel" card_class="icz-sectionchart" graph-data={this.state.categoryfunnelgraph} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>

                                        <div className="grid col_span_2 icz-row">
                                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex-wrap justify-content-between">
                                                        <Col className="icz-cardtitle">

                                                        </Col>
                                                        <Col lg={4} className="icz-cardfilter">
                                                            <ChartExport ChartId='icz_sankey' export_data={this.state.sanky_data} downloadName="sankey chart" />
                                                        </Col>
                                                    </div>
                                                    <div className="">
                                                        {this.state.is_sanky_loaded ?
                                                            <SankeyChart chart_id="icz_sankey" chart_class="icz-sectionchart" graph-data={this.state.sanky_data} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>

                                        <div className="grid col_span_2 icz-row">
                                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex-wrap justify-content-between">
                                                        <Col className="icz-cardtitle">

                                                        </Col>
                                                        <Col lg={4} className="icz-cardfilter">
                                                            <ChartExport ChartId='table' export_data={this.state.last_table_data} downloadName="table" />
                                                        </Col>
                                                    </div>
                                                    <div id="table" className="">
                                                        {this.state.is_table_loaded ?
                                                            <DataTable Headers={this.state.table_header} TableSize={5} TableData={this.state.last_table_data}
                                                            />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        )
    }
}

function ReduxStateToProps(state) {
    return {
        start_date: state.date.startDate,
        end_date: state.date.endDate,
        previous_start_date: state.date.prevStartDate,
        previous_end_date: state.date.prevEndDate,
    };
}

export default connect(ReduxStateToProps)(Sales)