// import { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { SwiperSlide } from "swiper/react";

// // STORAGE
// import AuthContext from "../../../../store/authContext";
// import FullPageExportContext from "../../../../store/fullPageExportContext";

// // COMPONENTS
// import axios from "../../../../components/helpers/axios";
// import Wrapper from "../../../../components/helpers/Wrapper";
// import Card from "../../../../components/ui/Card";
// import Slider from "../../../../components/ui/Slider";
// import Dropdown from "../../../../components/ui/dropdown/Dropdown";
// import Tab from "../../../../components/ui/Tab";
// import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
// import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
// import Datatable from "../../../../components/ui/tables/Datatable";

// // LOADERS
// import Loader from "../../../../components/ui/loaders/Loader";
// import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
// import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// // CHARTS
// import LineChart from "../../../../components/ui/charts/LineChart";
// import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
// import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
// import BarChart from "../../../../components/ui/charts/BarChart";

// // UTILS
// import NumberFormatter from "../../../../utils/NumberFormatter";
// import ErrorBoundary from "../../../../utils/ErrorBoundary";
// import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
// import { IAEvent_Dropdown_Visit, IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";
// import TextFormatter from "../../../../utils/TextFormatter";
// import { BlueColorStart500, COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";
// import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
// import ExpandIcon from "../../../../assets/icons/ExpandIcon";
// import Modal from "../../../../components/ui/Modal";


// const CampaignDv360Overall = ({ topCardData }) => {

//   // PAGE CONSTANTS
//   let pageModule = "Marketing Intelligence"
//   let pageSubModule = "Campaign"
//   let pagePlatform = "Dv360"
//   let pagePrimaryTab = "Overall"

//   const authCtx = useContext(AuthContext);
//   const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

//   const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user);
//   let companyID = userData?.company?.id;

//   // SELECTED BRANDS FROM DROPDOWN
//   const globalSelectedBrandIds = useSelector(
//     (state) => state.globalSelectedBrandIds.selectedBrandIds
//   );

//   let BRAND_ID = null;
//   if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
//     BRAND_ID = null;
//   } else {
//     BRAND_ID = globalSelectedBrandIds.join(",");
//   }

//   // GLOBAL CAMPAIGN DROPDOWN
//   const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
//   const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
//   const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
//   const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
//   const selectedCampaignIdandPlatform = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
//   );

//   let selectedIdandPlatform = null;
//   if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
//     selectedIdandPlatform = null;
//   } else {
//     selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
//   }

//   // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
//   let selectedPlatform = null;
//   if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
//     selectedPlatform = null;
//   } else {
//     selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
//   }

//   // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
//   let selectedObjectives = null;
//   if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
//     selectedObjectives = null;
//   } else {
//     selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
//   }

//   // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
//   let selectedCampaigns = null;
//   if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
//     selectedCampaigns = null;
//   } else {
//     selectedCampaigns = selectedCampaignsFromFilter.join(',');
//   }

//   // currentDate / endDate / prevCurrentDate / prevEndDate
//   const selectedDates = useSelector((state) => state.date);

//   // METRIC CARDS
//   const [metricCards, setMetricCards] = useState([]);
//   const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
//   const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
//   const [metricCardserror, setMetricCardsError] = useState(null);

//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
//   const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
//   const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
//   const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
//   const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
//   const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
//   const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
//   const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
//   const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
//   const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

//   // DEVICE DISTRIBUTION
//   const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
//   const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] = useState(false);
//   const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] = useState(false);
//   const [deviceWiseDistributionerror, setDeviceWiseDistributionError] = useState(null);

//   // CONTENT POSITION WISE DISTRIBUTION
//   const [contentPositionDistribution, setContentPositionDistribution] = useState([]);
//   const [isContentPositionDistributionLoading, setContentPositionDistributionIsLoading] = useState(false);
//   const [isContentPositionDistributionLoaded, setContentPositionDistributionIsLoaded] = useState(false);
//   const [contentPositionDistributionerror, setContentPositionDistributionError] = useState(null);

//   // APP URL DISTRIBUTION
//   const [appUrlDistribution, setAppUrlDistribution] = useState([]);
//   const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
//   const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] = useState(false);
//   const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] = useState(false);
//   const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

//   // APP URL TREND
//   const [appUrlTrend, setAppUrlTrend] = useState([]);
//   const [appUrlTrendLabels, setAppUrlTrendLabels] = useState([]);
//   const [isAppUrlTrendLoading, setAppUrlTrendIsLoading] = useState(false);
//   const [isAppUrlTrendLoaded, setAppUrlTrendIsLoaded] = useState(false);
//   const [appUrlTrenderror, setAppUrlTrendError] = useState(null);

//   //  STATE DATA
//   const [stateData, setStateData] = useState([]);
//   const [isStateDataLoading, setStateDataIsLoading] = useState(false);
//   const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
//   const [stateDataerror, setStateDataError] = useState(null);

//   const stateTableDataHeaders = [
//     { name: "State", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];

//   // CITY DATA
//   const [cityData, setCityData] = useState([]);
//   const [isCityDataLoading, setCityDataIsLoading] = useState(false);
//   const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
//   const [cityDataerror, setCityDataError] = useState(null);

//   const cityTableDataHeaders = [
//     { name: "City", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];

//   // COUNTRY DATA
//   const [countryData, setCountryData] = useState([]);
//   const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
//   const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
//   const [countryDataerror, setCountryDataError] = useState(null);

//   const countryTableDataHeaders = [
//     { name: "Country", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];


//   // CAMPAIGN SUMMARY
//   const [campaignSummary, setCampaignSummary] = useState([]);
//   const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
//   const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
//   const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
//   const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

//   // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS
//   const campaignSummaryOptions = [
//     { value: "campaign", label: "Campaign" },
//     { value: "line_item", label: "Line Item" },
//     { value: "creative", label: "Creative" },
//   ];

//   // CAMPAIGN SUMMARY TABLE DROPDOWN
//   // CAMPAIGN SUMMARY TABLE DROPDOWN DEFAULT STATE
//   const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

//   let campaignSummaryTableHeaders = [];

//   let defaultCampaignSummaryTableHeaders = [
//     { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "name" },
//     { name: "Media Type", field: "media_type" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Gmail Conversions", field: "gmail_conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "VTR", field: "vtr" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//     { name: "Complete View Rate", field: "complete_view_rate" },
//     { name: "CPCV", field: "cpcv" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Rich Media Engagement", field: "rich_media_engagement" },
//   ]

//   let haleonCampaignSummaryTableHeaders = [
//     { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "name" },
//     { name: "Media Type", field: "media_type" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Gmail Conversions", field: "gmail_conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "VTR", field: "vtr" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//     { name: "Complete View Rate", field: "complete_view_rate" },
//     { name: "CPCV", field: "cpcv" },
//     // { name: "Frequency", field: "frequency" },
//     // { name: "Reach Rate", field: "reach_rate" },
//     { name: "Rich Media Engagement", field: "rich_media_engagement" },
//   ]

//   if (companyID === HALEON_COMPANY_ID) {
//     campaignSummaryTableHeaders = haleonCampaignSummaryTableHeaders
//   } else {
//     campaignSummaryTableHeaders = defaultCampaignSummaryTableHeaders
//   }

//   // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS SELECTION HANDLER
//   const campaignSummaryDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedCampaignSummaryDropdownOption("");
//     } else {
//       setSelectedCampaignSummaryDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Dv360", "Overall", null, "Summary", e.label)

//   };

//   // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
//   const fetchCampaignSummaryDropdownHandler = async () => {
//     if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
//     } else if (selectedCampaignSummaryDropdownOption.value === "line_item") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.line_item);
//     } else if (selectedCampaignSummaryDropdownOption.value === "creative") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.creative);
//     } else {
//       setCampaignSummary([]);
//     }
//   };

//   // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
//   useEffect(() => {
//     fetchCampaignSummaryDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption]);


//   // MID SECTION DROPDOWN
//   const [midSectionDropdonwOptions, setMidSectionDropdonwOptions] = useState([])
//   const [midSectionDropdonwOptionsLoading, setMidSectionDropdonwOptionsLoading] = useState(true)

//   // DROPDOWN DEFAULT STATE
//   const [selectedMidSectionDropdownValue, setSelectedMidSectionDropdownDropdownValue] = useState('');
//   let selectedDropdownValue = selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.label

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const midSectionDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMidSectionDropdownDropdownValue("");
//     } else {
//       setSelectedMidSectionDropdownDropdownValue(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

//   };

//   const fetchMidSectionDropdownOptions = async () => {
//     setMidSectionDropdonwOptionsLoading(true)
//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/filter/", config);

//       setMidSectionDropdonwOptions(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       )
//       setSelectedMidSectionDropdownDropdownValue(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data[0] === undefined || res.data.data[0].length === 0 ? [] : res.data.data[0]
//       )
//       setMidSectionDropdonwOptionsLoading(false)

//     } catch (error) {
//       setMidSectionDropdonwOptions([])
//       setMidSectionDropdonwOptionsLoading(true)

//     }
//   }


//   // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
//   useEffect(() => {
//     fetchMetricCardsHandler();
//     fetchChildDonutCampaignHandler();
//     fetchCountryHandler();
//     fetchStateHandler();
//     fetchCityHandler();
//     fetchMidSectionDropdownOptions();
//     fetchCampaignSummaryHandler();
//     setChildDonutSliceToggle(null);
//     setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0])
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter]);

//   useEffect(() => {
//     fetchLineChartCampaignObjWiseHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, childDonutSliceToggle]);


//   useEffect(() => {
//     fetchContentPositionDistributionHandler()
//     fetchAppUrlDistributionHandler()
//     fetchAppUrlTrendHandler()
//   }, [BRAND_ID, , selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedMidSectionDropdownValue])

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // METRIC CARDS

//   // TO FETCH METRIC CARDS FROM API
//   const fetchMetricCardsHandler = async () => {
//     setMetricCardsIsLoading(true);
//     setMetricCardsIsLoaded(false);
//     setMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/metric-card/", config);
//       setMetricCards(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(true);
//       setMetricCardsError(null);
//     } catch (error) {
//       setMetricCards([]);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(false);
//       setMetricCardsError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let metricCardsSection =
//     <Wrapper>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div className="grid col_span_4 section_header">
//           <div className="section_info">  <h2 className="section_card_title">Summary
//           </h2></div>
//         </div>
//         <div className="grid col_span_4 grid_margin_bottom">
//           <Loader loaderType="cardLoader" />
//         </div>
//       </div>
//     </Wrapper>

//   // IF DATA IS LOADED
//   if (isMetricCardsLoaded && !isMetricCardsLoading) {
//     if (NoDataAvailableChecker(metricCards)) {
//       metricCardsSection = (
//         <Wrapper>
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_4 section_header">
//               <div className="section_info">  <h2 className="section_card_title">Summary
//               </h2></div>
//             </div>
//             <div className="grid col_span_4 grid_margin_bottom">
//               <NoDataAvailableLoader chartType="cardChartType" />
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       metricCardsSection = (
//         <Wrapper>
//           <ErrorBoundary>
//             <Slider sliderTitle={"Summary"}>
//               {metricCards.map((data, index) => (
//                 <SwiperSlide key={index}>
//                   <Card
//                     module="campaign"
//                     platform="dv360"
//                     section="overall"
//                     cardTitle={data.label}
//                     currentValue={data.currentValue}
//                     previousValue={data.previousValue}
//                     rowOneTitle={"Previous"}
//                     numberVariant={"number"}
//                   />
//                 </SwiperSlide>
//               ))}
//             </Slider>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (metricCardserror) {
//     metricCardsSection =
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <ServerErrorsLoader
//               chartType="cardChartType"
//               error={metricCardserror}
//             />
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // IF DATA LOADING
//   if (isMetricCardsLoading) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <Loader loaderType="cardLoader" />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN SUMMARY

//   // TO FETCH METRIC CARDS FROM API
//   const fetchCampaignSummaryHandler = async () => {
//     setCampaignSummaryIsLoading(true);
//     setCampaignSummaryIsLoaded(false);
//     setCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overallv2/", config);

//       setCampaignSummary(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.campaign === undefined || res.data.campaign.length === 0 ? [] :
//             res.data.campaign
//       );
//       setCampaignSummaryAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data
//       );
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(true);
//       setCampaignSummaryError(null);
//     } catch (error) {
//       setCampaignSummaryAllDropdownsData([]);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(false);
//       setCampaignSummaryError(error.code);
//     }
//   };

//   let campaignSummaryTable =
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Summary</h2>
//             <SectionDefinationsBadge
//               title="Summary"
//               module="campaign"
//               platform="Dv360"
//               section="overall"
//             />
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>

//   // IF DATA IS LOADED
//   if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
//     campaignSummaryTable = (
//       <ErrorBoundary>
//         <Datatable
//           tableTitle="Summary"
//           tableHeader={campaignSummaryTableHeaders}
//           tableData={campaignSummary}
//           tableLength={10}
//           isFooterShow={true}
//           searchPlaceHolder={"Search Campaign"}
//           tablePageSubModule={"campaign"}
//           tablePagePlatform={"Dv360"}
//           tablePagePrimaryTab={"overall"}
//           tableDropdown={
//             <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={campaignSummaryOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedCampaignSummaryDropdownOption}
//               selectedOptions={selectedCampaignSummaryDropdownOption}
//               setStatedropdown={campaignSummaryDropdownSelectionHandler}
//             />
//           }
//           tableID={"search_campaign_section"}
//           tableExportDropdown={
//             <ExportDropdown
//               moduleName={pageModule}
//               subModuleName={pageSubModule}
//               platform={pagePlatform}
//               tab={pagePrimaryTab}
//               sectionId={"search_campaign_section"}
//               sectionName={`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
//               sectionData={campaignSummary}
//             />
//           }
//         />
//       </ErrorBoundary>
//     );
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (campaignSummaryerror) {
//     campaignSummaryTable =
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary</h2>
//               <SectionDefinationsBadge
//                 title="Summary"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // IF DATA LOADING
//   if (isCampaignSummaryLoading) {
//     campaignSummaryTable =
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary</h2>
//               <SectionDefinationsBadge
//                 title="Summary"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

//   const fetchChildDonutCampaignHandler = async () => {
//     setChildDonutCampaignObjectiveLoading(true);
//     setChildDonutCampaignObjectiveLoaded(false);
//     setChildDonutCampaignObjectiveError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/campaign-distribution/",
//         config
//       );

//       setChildDonutCampaignObjective(res.data === undefined || res.data.length === 0 ? [] : res.data);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(true);
//       setChildDonutCampaignObjectiveError(null);
//     } catch (error) {
//       setChildDonutCampaignObjective([]);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(false);
//       setChildDonutCampaignObjectiveError(error.code);
//     }
//   };

//   let childDonutCampaignChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     childDonutCampaignObjectiveLoaded &&
//     !childDonutCampaignObjectiveLoading
//   ) {
//     if (
//       NoDataAvailableChecker(childDonutCampaignObjective)
//     ) {
//       childDonutCampaignChart = (
//         <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
//       );
//     } else {
//       childDonutCampaignChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="donutChartType">
//             <DonutPieChart
//               chartClass="section_card_chart"
//               chartData={childDonutCampaignObjective}
//               chartId="CampaignDonutChild"
//               onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (childDonutCampaignObjectiveError) {
//     childDonutCampaignChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={childDonutCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (childDonutCampaignObjectiveLoading) {
//     childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

//   let defaultLineChartCampaignDisabledvalues = [];

//   if (companyID === COKE_COMPANY_ID) {
//     defaultLineChartCampaignDisabledvalues = []
//   } else {
//     defaultLineChartCampaignDisabledvalues = ["Clicks", "Spends"]
//   }

//   const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

//   const fetchLineChartCampaignObjWiseHandler = async () => {
//     setLineChartCampaignObjectiveLoading(true);
//     setLineChartCampaignObjectiveLoaded(false);
//     setLineChartCampaignObjectiveError(null);

//     // var objectiveConfig = (function () {
//     //   if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
//     //     return { "media_type": null }
//     //   } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
//     //     return { "media_type": selectedObjectives }
//     //   } else {
//     //     return { "media_type": childDonutSliceToggle }
//     //   }
//     // })()

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//         media_type: childDonutSliceToggle
//         // ...objectiveConfig
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/campaign-distribution-linechart/",
//         config
//       );

//       setLineChartCampaignObjective(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setLineChartCampaignObjectiveLabel(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension
//       );
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(true);
//       setLineChartCampaignObjectiveError(null);
//     } catch (error) {
//       setLineChartCampaignObjective([]);
//       setLineChartCampaignObjectiveLabel([]);
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(false);
//       setLineChartCampaignObjectiveError(error.code);
//     }
//   };

//   let lineChartCampaignSection = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   let lineChartCampaignSection2 = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
//     if (
//       NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
//     ) {
//       lineChartCampaignSection = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );

//       lineChartCampaignSection2 = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//     } else {


//       if (isMultiLineCluster) {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impressions"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//         lineChartCampaignSection2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign_2"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 showLegend={true}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//                 showLabels={true}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//       } else {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impressions"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }

//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (lineChartCampaignObjectiveError) {
//     lineChartCampaignSection = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );

//     lineChartCampaignSection2 = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (lineChartCampaignObjectiveLoading) {
//     lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//     lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   //   DEVICE WISE DISTRIBUTION

//   let treemapLevel = 1;
//   const [treemapDeviceType, setTreemapDeviceType] = useState("");
//   const [treemapOS, setTreemapOS] = useState("");

//   const [treemapParams, setTreemapParams] = useState({
//     brand_id: BRAND_ID,
//     start_date: selectedDates.startDate,
//     end_date: selectedDates.endDate,
//     previous_start_date: selectedDates.prevStartDate,
//     previous_end_date: selectedDates.prevEndDate,
//     objective: selectedObjectives,
//     platform_campaign: selectedIdandPlatform,
//     platform: selectedPlatform,
//     campaign: selectedCampaigns,
//     campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//     level: 1,
//   });

//   const chartlevelHandler = (data) => {
//     if (data.level === "1") {
//       setTreemapParams({
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//         level: 2,
//         device_type: data.device_type,
//       });
//       setTreemapDeviceType(data.device_type);
//     } else if (data.level === "2") {
//       setTreemapParams({
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//         level: 3,
//         device_type: treemapDeviceType,
//         operating_system: data.operating_system,
//       });
//       setTreemapOS(data.operating_system);
//     } else {
//       alert("No further drill down available");
//     }
//   };

//   const fetchDeviceWiseDistributionHandler = async () => {
//     setDeviceWiseDistributionIsLoading(true);
//     setDeviceWiseDistributionIsLoaded(false);
//     setDeviceWiseDistributionError(null);

//     const config = {
//       params: treemapParams,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/device-wise-distribution/",
//         config
//       );

//       setDeviceWiseDistribution(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setDeviceWiseDistributionIsLoading(false);
//       setDeviceWiseDistributionIsLoaded(true);
//       setDeviceWiseDistributionError(null);
//     } catch (error) {
//       setDeviceWiseDistribution([]);
//       setDeviceWiseDistributionIsLoading(false);
//       setDeviceWiseDistributionIsLoaded(false);
//       setDeviceWiseDistributionError(error.code);
//     }
//   };

//   let deviceWiseDistributionSection = (
//     <Wrapper>
//       <div className="section_card_chart_wrapper flex column">
//         <Loader loaderType="treeChartLoader" />
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
//     if (
//       NoDataAvailableChecker(deviceWiseDistribution) ||
//       (deviceWiseDistribution.length === 1 &&
//         deviceWiseDistribution[0].value === 0)
//     ) {
//       deviceWiseDistributionSection = (
//         <Wrapper>
//           <div className="section_card_chart_wrapper flex column">
//             <NoDataAvailableLoader chartType="treeChartType" />
//           </div>
//         </Wrapper>
//       );
//     } else {
//       if (treemapLevel > 3) {
//         deviceWiseDistributionSection = (
//           <Wrapper>
//             <div className="section_card_chart_wrapper flex column">
//               <NoDataAvailableLoader chartType="treeChartType" />
//             </div>
//           </Wrapper>
//         );
//       } else {
//         deviceWiseDistributionSection = (
//           <Wrapper>
//             <div className="section_card_chart_wrapper flex column">
//               <ErrorBoundary chartType="treeChartType">
//                 <SimpleTreeMap
//                   chartId="simpleTreemaptest"
//                   chartClass="section_card_chart"
//                   chartData={deviceWiseDistribution}
//                   levelHandler={chartlevelHandler}
//                   chartLabels={[
//                     {
//                       name: "name",
//                       value: "value",
//                       toolTipvalue1: "value",
//                       toolTipvalue1Label: `${selectedDropdownValue}`,

//                     },
//                   ]}
//                 />
//               </ErrorBoundary>
//             </div>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (deviceWiseDistributionerror) {
//     deviceWiseDistributionSection = (
//       <Wrapper>
//         <div className="section_card_chart_wrapper flex column">
//           <ServerErrorsLoader chartType="treeChartType" error={deviceWiseDistributionerror} />
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isDeviceWiseDistributionLoading) {
//     deviceWiseDistributionSection = (
//       <Wrapper>
//         <div className="section_card_chart_wrapper flex column">
//           <Loader loaderType="treeChartLoader" />
//         </div>
//       </Wrapper>
//     );
//   }

//   // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
//   useEffect(() => {
//     fetchDeviceWiseDistributionHandler();
//   }, [treemapParams]);


//   useEffect(() => {
//     var temp = {
//       metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//       objective: selectedObjectives,
//       platform_campaign: selectedIdandPlatform,
//       platform: selectedPlatform,
//       campaign: selectedCampaigns,
//       campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),

//     }
//     setTreemapParams({ ...treemapParams, ...temp })
//   }, [BRAND_ID, selectedMidSectionDropdownValue, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])



//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CONTENT POSITION WISE DISTRIBUTION

//   // TO FETCH DATA FROM API
//   const fetchContentPositionDistributionHandler = async () => {
//     setContentPositionDistributionIsLoading(true);
//     setContentPositionDistributionIsLoaded(false);
//     setContentPositionDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/content-position-wise-distribution/",
//         config
//       );
//       setContentPositionDistribution(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );

//       setContentPositionDistributionIsLoading(false);
//       setContentPositionDistributionIsLoaded(true);
//       setContentPositionDistributionError(null);
//     } catch (error) {
//       setContentPositionDistribution([]);
//       setContentPositionDistributionIsLoading(false);
//       setContentPositionDistributionIsLoaded(false);
//       setContentPositionDistributionError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let contentPositionDistributionChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   let contentPositionDistributionDetails = (
//     <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
//   )

//   // IF DATA IS LOADED
//   if (
//     isContentPositionDistributionLoaded && !isContentPositionDistributionLoading) {
//     if (NoDataAvailableChecker(contentPositionDistribution)) {
//       contentPositionDistributionChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="donutChartType" />
//         </Wrapper>
//       );
//       contentPositionDistributionDetails = (
//         <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
//       )
//     } else {
//       contentPositionDistributionChart = (
//         <Wrapper>
//           <ErrorBoundary>

//             <DonutPieChart
//               chartId={"contentPositionDistribution"}
//               chartClass={"section_card_chart"}
//               chartData={contentPositionDistribution}
//               showLegend={true}
//               showLabels={true}
//               showVerticalLabels={true}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );

//       // const sum = contentPositionDistribution
//       //   .map(obj => obj.value)
//       //   .reduce((accumulator, current) => accumulator + current, 0);

//       contentPositionDistributionDetails = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <div className="chart_details_wrapper">
//               <div className="main_wrapper">
//                 <div className="body">
//                   {contentPositionDistribution && contentPositionDistribution.map(
//                     (details, postImpressionIndex) => (
//                       <Wrapper key={postImpressionIndex}>
//                         <div className="row">
//                           <div className="title_wrapper">
//                             {details.label}
//                           </div>
//                           <div className="value_wrapper">
//                             <ErrorBoundary>
//                               <NumberFormatter number={details.value} />
//                             </ErrorBoundary>
//                             {details.metric &&
//                               <div className="metrics_wrapper">
//                                 <div className="title_wrapper">
//                                   {details.metric[0].label + " : "}
//                                 </div>
//                                 <div className="value_wrapper">
//                                   <ErrorBoundary>
//                                     <NumberFormatter
//                                       number={details.metric[0].value}
//                                     />
//                                   </ErrorBoundary>
//                                 </div>
//                               </div>
//                             }
//                           </div>
//                         </div>
//                       </Wrapper>
//                     ))}
//                 </div>
//                 {/* <div className="footer">
//                   <div className="title_wrapper">
//                     Total {" " + selectedDropdownValue}
//                   </div>
//                   <div className="value_wrapper">

//                     <NumberFormatter number={sum} />
//                   </div>
//                 </div> */}
//               </div>

//             </div>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (contentPositionDistributionerror) {
//     contentPositionDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={contentPositionDistributionerror}
//         />
//       </Wrapper>
//     );
//     contentPositionDistributionDetails = (
//       <Wrapper><ServerErrorsLoader chartType="tableChartType" error={contentPositionDistributionerror} /></Wrapper>
//     )
//   }

//   // IF DATA LOADING
//   if (isContentPositionDistributionLoading) {
//     contentPositionDistributionChart = (
//       <Wrapper>
//         <Loader loaderType="donutChartLoader" />
//       </Wrapper>
//     );
//     contentPositionDistributionDetails = (
//       <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
//     )
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // APP URL DISTRIBUTION
//   const fetchAppUrlDistributionHandler = async () => {
//     setAppUrlDistributionIsLoading(true);
//     setAppUrlDistributionIsLoaded(false);
//     setAppUrlDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/app-url-wise-bar-chart-distribution/", config);

//       setAppUrlDistribution(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setAppUrlDistributionLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.label === undefined || res.data.label.length === 0 ? [] :
//             res.data.label
//       );
//       setAppUrlDistributionIsLoading(false);
//       setAppUrlDistributionIsLoaded(true);
//       setAppUrlDistributionError(null);
//     } catch (error) {
//       setAppUrlDistribution([]);
//       setAppUrlDistributionLabels([]);
//       setAppUrlDistributionIsLoading(false);
//       setAppUrlDistributionIsLoaded(false);
//       setAppUrlDistributionError(error.code);
//     }
//   };

//   let appUrlDistributionChart = (
//     <Wrapper>
//       <Loader loaderType={"barChartLoader"} />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
//     if (
//       NoDataAvailableChecker(appUrlDistribution) ||
//       NoDataAvailableChecker(appUrlDistributionLabels)
//     ) {
//       appUrlDistributionChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"barChartType"} />
//         </Wrapper>
//       );
//     } else {
//       appUrlDistributionChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="lineChartType">
//             <BarChart
//               chartId="social_twitter_profile_clickss"
//               chartClass="section_card_chart"
//               chartData={appUrlDistribution}
//               chartLabels={appUrlDistributionLabels}
//             />

//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (appUrlDistributionerror) {
//     appUrlDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"barChartType"} error={appUrlDistributionerror} />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isAppUrlDistributionLoading) {
//     appUrlDistributionChart = (
//       <Wrapper>
//         <Loader loaderType={"barChartLoader"} />
//       </Wrapper>
//     );
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // APP URL DISTRIBUTION
//   const [isAppUrlTrendOpen, setIsAppUrlTrendOpen] = useState(false)

//   let defaultAppUrlTrendDisabledvalues = [];

//   if (companyID === COKE_COMPANY_ID) {
//     defaultAppUrlTrendDisabledvalues = []
//   } else {
//     defaultAppUrlTrendDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
//   }

//   const [appUrlTrendDisabledvalues, setAppUrlTrendDisabledvalues] = useState(defaultAppUrlTrendDisabledvalues)

//   const fetchAppUrlTrendHandler = async () => {
//     setAppUrlTrendIsLoading(true);
//     setAppUrlTrendIsLoaded(false);
//     setAppUrlTrendError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/app-url-wise-distribution/", config);

//       setAppUrlTrend(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setAppUrlTrendLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.label === undefined || res.data.label.length === 0 ? [] :
//             res.data.label
//       );

//       setAppUrlTrendIsLoading(false);
//       setAppUrlTrendIsLoaded(true);
//       setAppUrlTrendError(null);
//     } catch (error) {
//       setAppUrlTrend([]);
//       setAppUrlTrendLabels([]);
//       setAppUrlTrendIsLoading(false);
//       setAppUrlTrendIsLoaded(false);
//       setAppUrlTrendError(error.code);
//     }
//   };

//   let appUrlTrendChart = (
//     <Wrapper>
//       <Loader loaderType={"singleLineChartLoader"} />
//     </Wrapper>
//   );

//   let appUrlTrendChart2 = (
//     <Wrapper>
//       <Loader loaderType={"singleLineChartLoader"} />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isAppUrlTrendLoaded && !isAppUrlTrendLoading) {
//     if (
//       NoDataAvailableChecker(appUrlTrend) ||
//       NoDataAvailableChecker(appUrlTrendLabels)
//     ) {
//       appUrlTrendChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"lineChartType"} />
//         </Wrapper>
//       );

//       appUrlTrendChart2 = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"lineChartType"} />
//         </Wrapper>
//       );
//     } else {

//       if (isMultiLineCluster) {
//         appUrlTrendChart = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartId="app_url_trend"
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartLabels={appUrlTrendLabels}
//                 disabledLegendArray={appUrlTrendDisabledvalues}
//                 setDisabledValues={setAppUrlTrendDisabledvalues}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//         appUrlTrendChart2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartId="app_url_trend_2"
//                 chartLabels={appUrlTrendLabels}
//                 showLegend={true}
//                 disabledLegendArray={appUrlTrendDisabledvalues}
//                 setDisabledValues={setAppUrlTrendDisabledvalues}
//                 showLabels={true}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//       } else {
//         appUrlTrendChart = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartId="app_url_trend"
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartLabels={appUrlTrendLabels}
//               />

//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }

//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (appUrlTrenderror) {
//     appUrlTrendChart = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
//       </Wrapper>
//     );

//     appUrlTrendChart2 = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isAppUrlTrendLoading) {
//     appUrlTrendChart = (
//       <Wrapper>
//         <Loader loaderType={"singleLineChartLoader"} />
//       </Wrapper>
//     );

//     appUrlTrendChart2 = (
//       <Wrapper>
//         <Loader loaderType={"singleLineChartLoader"} />
//       </Wrapper>
//     );
//   }



//   const tabData = ["By Country", "By State", "By City"];
//   const [selectedTab, setSelectedTab] = useState(tabData[0]);
//   const handleCallback = (event) => {
//     if (selectedTab !== event) {
//       setSelectedTab(event);
//     }
//     IAEvent_Secondary_Tab_Visit(
//       "Marketing Intelligence",
//       "Campaign",
//       "Dv360",
//       "Video",
//       event
//     );
//   };


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // STATE MAP

//   const fetchStateHandler = async () => {
//     setStateDataIsLoading(true);
//     setStateDataIsLoaded(false);
//     setStateDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/state-distribution/",
//         config
//       );

//       setStateData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(true);
//       setStateDataError(null);
//     } catch (error) {
//       setStateData([]);
//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(false);
//       setStateDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: STATE
//   let audienceDistributionState = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By State</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isStateDataLoaded && !isStateDataLoading) {
//     if (
//       NoDataAvailableChecker(stateData) ||
//       NoDataAvailableChecker(stateTableDataHeaders)
//     ) {
//       audienceDistributionState = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By State</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionState = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By State"}
//               isDynamicFlagVisible={true}
//               tableHeader={stateTableDataHeaders}
//               tableData={stateData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search State"}
//               tableID="audience_distribution_country_state_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_state_section"}
//                   sectionName={"StateTable_Audience Distribution By State"}
//                   sectionData={stateData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (stateDataerror) {
//     audienceDistributionState = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isStateDataLoading) {
//     audienceDistributionState = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CITY MAP

//   const fetchCityHandler = async () => {
//     setCityDataIsLoading(true);
//     setCityDataIsLoaded(false);
//     setCityDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/city-distribution/",
//         config
//       );
//       setCityData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(true);
//       setCityDataError(null);
//     } catch (error) {
//       setCityData([]);
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(false);
//       setCityDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: CITY
//   let audienceDistributionCity = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By City</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCityDataLoaded && !isCityDataLoading) {
//     if (
//       NoDataAvailableChecker(cityData) ||
//       NoDataAvailableChecker(cityTableDataHeaders)
//     ) {
//       audienceDistributionCity = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By City</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionCity = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By City"}
//               isDynamicFlagVisible={true}
//               tableHeader={cityTableDataHeaders}
//               tableData={cityData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search City"}
//               tableID="audience_distribution_country_city_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_city_section"}
//                   sectionName={"CityTable_Audience Distribution By City"}
//                   sectionData={cityData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (cityDataerror) {
//     audienceDistributionCity = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCityDataLoading) {
//     audienceDistributionCity = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // COUNTRY TABLE

//   const fetchCountryHandler = async () => {
//     setCountryDataIsLoading(true);
//     setCountryDataIsLoaded(false);
//     setCountryDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/country-distribution/",
//         config
//       );

//       setCountryData(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(true);
//       setCountryDataError(null);
//     } catch (error) {
//       setCountryData([]);
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(false);
//       setCountryDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: CITY
//   let audienceDistributionCountry = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By Country</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCountryDataLoaded && !isCountryDataLoading) {
//     if (
//       NoDataAvailableChecker(countryData) ||
//       NoDataAvailableChecker(countryTableDataHeaders)
//     ) {
//       audienceDistributionCountry = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By Country</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionCountry = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By Country"}
//               isCountryFlagVisible={true}
//               tableHeader={countryTableDataHeaders}
//               tableData={countryData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Country"}
//               tableID="audience_distribution_country_country_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_country_section"}
//                   sectionName={"CountryTable_Audience Distribution - Country"}
//                   sectionData={countryData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (countryDataerror) {
//     audienceDistributionCountry = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCountryDataLoading) {
//     audienceDistributionCountry = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }



//   var campaignObjectCSV = {
//     "CampaignTypeDonut_Campaign Type Wise Donut": childDonutCampaignObjective,
//     [`CampObjectiveDynaLabels_Campaign Type Wise Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
//   };

//   var allData = {
//     ...topCardData,
//     "SummaryCard_Summary Card": metricCards,
//     ...campaignObjectCSV,
//     [`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`]: deviceWiseDistribution,
//     [`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`]: contentPositionDistribution,
//     [`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`]: appUrlDistribution,
//     [`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`]: appUrlTrend,
//     "CountryTable_Audience Distribution - Country": countryData,
//     "StateTable_Audience Distribution - State": stateData,
//     "CityTable_Audience Distribution - City": cityData,
//     [`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]: campaignSummary,
//   };
//   useEffect(() => {
//     setFullPageExport(allData);
//   }, [
//     metricCards,
//     childDonutCampaignObjective,
//     lineChartCampaignObjective,
//     deviceWiseDistribution,
//     contentPositionDistribution,
//     appUrlDistribution,
//     appUrlTrend,
//     countryData,
//     stateData,
//     cityData,
//     campaignSummary,
//   ]);

//   useEffect(() => {
//     if (
//       isMetricCardsLoaded &&
//       childDonutCampaignObjectiveLoaded &&
//       lineChartCampaignObjectiveLoaded &&
//       isDeviceWiseDistributionLoaded &&
//       isContentPositionDistributionLoaded &&
//       isAppUrlDistributionLoaded &&
//       isAppUrlTrendLoaded &&
//       isCountryDataLoaded &&
//       isStateDataLoaded &&
//       isCityDataLoaded &&
//       isCampaignSummaryLoaded
//     ) {
//       setIsExportDisabled(false)
//     } else {
//       setIsExportDisabled(true)
//     }
//   }, [
//     isMetricCardsLoaded,
//     childDonutCampaignObjectiveLoaded,
//     lineChartCampaignObjectiveLoaded,
//     isDeviceWiseDistributionLoaded,
//     isContentPositionDistributionLoaded,
//     isAppUrlDistributionLoaded,
//     isAppUrlTrendLoaded,
//     isCountryDataLoaded,
//     isStateDataLoaded,
//     isCityDataLoaded,
//     isCampaignSummaryLoaded
//   ])


//   return (
//     <Wrapper>
//       {metricCardsSection}
//       <div id="campaign_objective_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
//               <SectionDefinationsBadge
//                 title={"Campaign Type Wise Distribution"}
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isLineChartCampaignOpen}
//               setOpen={setIsLineChartCampaignOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"linechartCamapign_2"}
//                   sectionName={"Campaign Type Wise Distribution"}
//                   sectionData={campaignObjectCSV}
//                 />}
//               parentId={"campaign_objective_section"}
//               title={`Campaign Type Wise Distribution`}
//               chart={lineChartCampaignSection2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"campaign_objective_section"}
//                 sectionName={"Campaign Type Wise Distribution"}
//                 sectionData={campaignObjectCSV}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_1 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {childDonutCampaignChart}
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_3 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {lineChartCampaignSection}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="section_card section_dropdown_header">
//         <div className="grid grid_cols_1">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Performance By KPI

//             </h2>
//             {midSectionDropdonwOptions.length !== 0 && <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={midSectionDropdonwOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedMidSectionDropdownValue}
//               selectedOptions={selectedMidSectionDropdownValue}
//               setStatedropdown={midSectionDropdownSelectionHandler}
//               dropdownLoading={midSectionDropdonwOptionsLoading}
//             />}
//           </div>
//         </div>
//       </div>

//       <div id="device_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header ">
//             <div className="flex column align_start">
//               <div className="section_info">   <h2 className="section_card_title">
//                 Device Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//               </h2> <SectionDefinationsBadge
//                   title="Device Wise Distribution"
//                   module="campaign"
//                   platform="Dv360"
//                   section="overall"
//                 />
//               </div>
//               {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
//                 <div className="treemap_breadcrumb">
//                   <span
//                     onClick={() => {
//                       setTreemapParams({
//                         brand_id: BRAND_ID,
//                         start_date: selectedDates.startDate,
//                         end_date: selectedDates.endDate,
//                         previous_start_date: selectedDates.prevStartDate,
//                         previous_end_date: selectedDates.prevEndDate,
//                         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//                         objective: selectedObjectives,
//                         platform_campaign: selectedIdandPlatform,
//                         platform: selectedPlatform,
//                         campaign: selectedCampaigns,
//                         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//                         level: 1,
//                       });
//                       setTreemapDeviceType("");
//                       setTreemapOS("");
//                     }}
//                   >
//                     Home
//                   </span>
//                   {treemapDeviceType && (
//                     <span
//                       onClick={() => {
//                         setTreemapParams({
//                           brand_id: BRAND_ID,
//                           start_date: selectedDates.startDate,
//                           end_date: selectedDates.endDate,
//                           previous_start_date: selectedDates.prevStartDate,
//                           previous_end_date: selectedDates.prevEndDate,
//                           metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//                           objective: selectedObjectives,
//                           platform_campaign: selectedIdandPlatform,
//                           platform: selectedPlatform,
//                           campaign: selectedCampaigns,
//                           campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//                           level: 2,
//                           device_type: treemapDeviceType,
//                         });
//                         setTreemapOS("");
//                       }}
//                     >
//                       {" / "}
//                       <TextFormatter string={treemapDeviceType} />
//                     </span>
//                   )}

//                   {treemapOS && (
//                     <span>
//                       {" / "}
//                       <TextFormatter string={treemapOS} />{" "}
//                     </span>
//                   )}
//                 </div>
//               )}
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"device_wise_distribution_section"}
//                 sectionName={`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`}
//                 sectionData={deviceWiseDistribution}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             {deviceWiseDistributionSection}
//           </div>
//         </div>
//       </div>

//       <div id="content_position_wise_distribution" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info " >
//               <h2 className="section_card_title">
//                 Content Position Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//               </h2> <SectionDefinationsBadge
//                 title={"Content Position Wise Distribution"}
//                 module="campaign"
//                 platform="Dv360"
//                 section="Overall"
//               />
//             </div>
//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"content_position_wise_distribution"}
//                 sectionName={`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`}
//                 sectionData={contentPositionDistribution}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="grid col_span_4 section_card_body">
//           <div className="grid col_span_3 section_card_chart_wrapper">
//             {contentPositionDistributionChart}
//           </div>
//           <div className="grid col_span_1 section_card_table_wrapper">
//             {contentPositionDistributionDetails}
//           </div>
//         </div>
//       </div>


//       <div id="app_url_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">  <h2 className="section_card_title">
//               App/Url Wise Distribution  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//             </h2> <SectionDefinationsBadge
//                 title="App/Url Wise Distribution"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"app_url_wise_distribution_section"}
//                 sectionName={`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`}
//                 sectionData={appUrlDistribution}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <div className="section_card_chart_wrapper">
//               {appUrlDistributionChart}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div id="app_url_wise_distribution_section_linechart" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">  <h2 className="section_card_title">
//               App/Url Wise Trend  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//             </h2> <SectionDefinationsBadge
//                 title="App/Url Wise Trend"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isAppUrlTrendOpen}
//               setOpen={setIsAppUrlTrendOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"app_url_trend_2"}
//                   sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
//                   sectionData={appUrlTrend}
//                 />}
//               parentId={"app_url_wise_distribution_section_linechart"}
//               title={`App/Url Wise Trend ${selectedMidSectionDropdownValue && ": " + selectedDropdownValue}`}
//               chart={appUrlTrendChart2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsAppUrlTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"app_url_wise_distribution_section_linechart"}
//                 sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
//                 sectionData={appUrlTrend}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <div className="section_card_chart_wrapper">
//               {appUrlTrendChart}
//             </div>
//           </div>
//         </div>
//       </div>


//       <div id="audience_distri_country_city_section">
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <div className="section_info">   <h2 className="section_card_title">
//                 Audience Distribution
//               </h2> <SectionDefinationsBadge
//                   title={"Audience Distribution"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />

//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="grid  grid_margin_bottom ">
//           <Tab
//             varient={"primary"}
//             tabData={tabData}
//             activeTab={selectedTab}
//             handleCallback={(event) => handleCallback(event)}
//           />
//         </div>

//         <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//           {selectedTab === "By Country" && (
//             <Wrapper>
//               {audienceDistributionCountry}
//             </Wrapper>
//           )}
//           {selectedTab === "By State" && (
//             <Wrapper>
//               {audienceDistributionState}
//             </Wrapper>
//           )}
//           {selectedTab === "By City" && (
//             <Wrapper>
//               {audienceDistributionCity}
//             </Wrapper>
//           )}

//         </div>
//       </div>

//       {campaignSummaryTable}
//     </Wrapper >
//   );
// };

// export const CampaignDv360OverallCoke = ({ topCardData }) => {

//   // PAGE CONSTANTS
//   let pageModule = "Marketing Intelligence"
//   let pageSubModule = "Campaign"
//   let pagePlatform = "Dv360"
//   let pagePrimaryTab = "Overall"

//   const authCtx = useContext(AuthContext);
//   const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user);
//   let companyID = userData?.company?.id;

//   // SELECTED BRANDS FROM DROPDOWN
//   const globalSelectedBrandIds = useSelector(
//     (state) => state.globalSelectedBrandIds.selectedBrandIds
//   );

//   const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)

//   let BRAND_ID = null;
//   if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
//     BRAND_ID = null;
//   } else {
//     BRAND_ID = globalSelectedBrandIds.join(",");
//   }

//   // GLOBAL CAMPAIGN DROPDOWN
//   const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
//   const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
//   const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
//   const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
//   const selectedCampaignIdandPlatform = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
//   );

//   let selectedIdandPlatform = null;
//   if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
//     selectedIdandPlatform = null;
//   } else {
//     selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
//   }
//   // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
//   let selectedPlatform = null;
//   if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
//     selectedPlatform = null;
//   } else {
//     selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
//   }

//   // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
//   let selectedObjectives = null;
//   if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
//     selectedObjectives = null;
//   } else {
//     selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
//   }

//   // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
//   let selectedCampaigns = null;
//   if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
//     selectedCampaigns = null;
//   } else {
//     selectedCampaigns = selectedCampaignsFromFilter.join(',');
//   }

//   // currentDate / endDate / prevCurrentDate / prevEndDate
//   const selectedDates = useSelector((state) => state.date);

//   // METRIC CARDS
//   const [metricCards, setMetricCards] = useState([]);
//   const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
//   const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
//   const [metricCardserror, setMetricCardsError] = useState(null);

//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
//   const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
//   const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
//   const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
//   const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
//   const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
//   const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
//   const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
//   const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
//   const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

//   // DEVICE DISTRIBUTION
//   const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
//   const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] = useState(false);
//   const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] = useState(false);
//   const [deviceWiseDistributionerror, setDeviceWiseDistributionError] = useState(null);

//   // CONTENT POSITION WISE DISTRIBUTION
//   const [contentPositionDistribution, setContentPositionDistribution] = useState([]);
//   const [isContentPositionDistributionLoading, setContentPositionDistributionIsLoading] = useState(false);
//   const [isContentPositionDistributionLoaded, setContentPositionDistributionIsLoaded] = useState(false);
//   const [contentPositionDistributionerror, setContentPositionDistributionError] = useState(null);

//   // APP URL DISTRIBUTION
//   const [appUrlDistribution, setAppUrlDistribution] = useState([]);
//   const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
//   const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] = useState(false);
//   const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] = useState(false);
//   const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

//   // APP URL TREND
//   const [appUrlTrend, setAppUrlTrend] = useState([]);
//   const [appUrlTrendLabels, setAppUrlTrendLabels] = useState([]);
//   const [isAppUrlTrendLoading, setAppUrlTrendIsLoading] = useState(false);
//   const [isAppUrlTrendLoaded, setAppUrlTrendIsLoaded] = useState(false);
//   const [appUrlTrenderror, setAppUrlTrendError] = useState(null);

//   //  STATE DATA
//   const [stateData, setStateData] = useState([]);
//   const [isStateDataLoading, setStateDataIsLoading] = useState(false);
//   const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
//   const [stateDataerror, setStateDataError] = useState(null);

//   const stateTableDataHeaders = [
//     { name: "State", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];

//   // CITY DATA
//   const [cityData, setCityData] = useState([]);
//   const [isCityDataLoading, setCityDataIsLoading] = useState(false);
//   const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
//   const [cityDataerror, setCityDataError] = useState(null);

//   const cityTableDataHeaders = [
//     { name: "City", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];

//   // COUNTRY DATA
//   const [countryData, setCountryData] = useState([]);
//   const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
//   const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
//   const [countryDataerror, setCountryDataError] = useState(null);

//   const countryTableDataHeaders = [
//     { name: "Country", field: "name" },
//     { name: "Impressions", field: "impression" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Spends", field: "spends" },
//     { name: "Views", field: "views" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//   ];


//   // CAMPAIGN SUMMARY
//   const [campaignSummary, setCampaignSummary] = useState([]);
//   const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
//   const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
//   const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
//   const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

//   // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS
//   const campaignSummaryOptions = [
//     { value: "campaign", label: "Campaign" },
//     { value: "line_item", label: "Line Item" },
//     { value: "creative", label: "Creative" },
//   ];

//   // CAMPAIGN SUMMARY TABLE DROPDOWN
//   // CAMPAIGN SUMMARY TABLE DROPDOWN DEFAULT STATE
//   const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

//   let campaignSummaryTableHeaders = [];

//   let defaultCampaignSummaryTableHeaders = [
//     { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "name" },
//     { name: "Media Type", field: "media_type" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Gmail Conversions", field: "gmail_conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "VTR", field: "vtr" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//     { name: "Complete View Rate", field: "complete_view_rate" },
//     { name: "CPCV", field: "cpcv" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Rich Media Engagement", field: "rich_media_engagement" },
//   ]

//   let haleonCampaignSummaryTableHeaders = [
//     { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "name" },
//     { name: "Media Type", field: "media_type" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Total Conversions", field: "total_conversions" },
//     { name: "Gmail Conversions", field: "gmail_conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "VTR", field: "vtr" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Complete Views (Video)", field: "complete_views_video" },
//     { name: "Complete View Rate", field: "complete_view_rate" },
//     { name: "CPCV", field: "cpcv" },
//     // { name: "Frequency", field: "frequency" },
//     // { name: "Reach Rate", field: "reach_rate" },
//     { name: "Rich Media Engagement", field: "rich_media_engagement" },
//   ]

//   if (companyID === HALEON_COMPANY_ID) {
//     campaignSummaryTableHeaders = haleonCampaignSummaryTableHeaders
//   } else {
//     campaignSummaryTableHeaders = defaultCampaignSummaryTableHeaders
//   }

//   // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS SELECTION HANDLER
//   const campaignSummaryDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedCampaignSummaryDropdownOption("");
//     } else {
//       setSelectedCampaignSummaryDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Dv360", "Overall", null, "Summary", e.label)

//   };

//   // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
//   const fetchCampaignSummaryDropdownHandler = async () => {
//     if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
//     } else if (selectedCampaignSummaryDropdownOption.value === "line_item") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.line_item);
//     } else if (selectedCampaignSummaryDropdownOption.value === "creative") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.creative);
//     } else {
//       setCampaignSummary([]);
//     }
//   };

//   // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
//   useEffect(() => {
//     fetchCampaignSummaryDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption]);


//   // MID SECTION DROPDOWN
//   const [midSectionDropdonwOptions, setMidSectionDropdonwOptions] = useState([])
//   const [midSectionDropdonwOptionsLoading, setMidSectionDropdonwOptionsLoading] = useState(true)

//   // DROPDOWN DEFAULT STATE
//   const [selectedMidSectionDropdownValue, setSelectedMidSectionDropdownDropdownValue] = useState('');
//   let selectedDropdownValue = selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.label

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const midSectionDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMidSectionDropdownDropdownValue("");
//     } else {
//       setSelectedMidSectionDropdownDropdownValue(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

//   };

//   const fetchMidSectionDropdownOptions = async () => {
//     setMidSectionDropdonwOptionsLoading(true)
//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/filter/", config);

//       setMidSectionDropdonwOptions(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       )
//       setSelectedMidSectionDropdownDropdownValue(
//         res.data === undefined || res.data.length === 0 ? "" :
//           res.data.data === undefined || res.data.data.length === 0 ? "" :
//             res.data.data[0] === undefined || res.data.data[0].length === 0 ? "" :
//               res.data.data[0]
//       )
//       setMidSectionDropdonwOptionsLoading(false)

//     } catch (error) {
//       setMidSectionDropdonwOptions([])
//       setMidSectionDropdonwOptionsLoading(true)

//     }
//   }


//   // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
//   useEffect(() => {
//     fetchMetricCardsHandler();
//     fetchChildDonutCampaignHandler();
//     fetchCountryHandler();
//     fetchStateHandler();
//     fetchCityHandler();
//     fetchMidSectionDropdownOptions();
//     fetchCampaignSummaryHandler();
//     setChildDonutSliceToggle(null);
//     setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0])
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter]);

//   useEffect(() => {
//     fetchLineChartCampaignObjWiseHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, childDonutSliceToggle]);


//   useEffect(() => {
//     fetchContentPositionDistributionHandler()
//     fetchAppUrlDistributionHandler()
//     fetchAppUrlTrendHandler()
//   }, [BRAND_ID, , selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedMidSectionDropdownValue])



//   // PLANNED VS DELIVERED TABLE

//   /*   const plannedVsDeliveredTableHeaders = [
//       { name: "Campaign Name", field: "campaign_names" },
//       { name: "Start date", field: "start_date" },
//       { name: "End date", field: "end_date" },

//       { name: "Market/TG", field: "type" },
//       { name: "Markets(State)", field: "region" }, 

//       { name: "Planned Reach", field: "planned_reach" },
//       { name: "Delivered Reach", field: "delivered_reach" },
//       { name: "% achieved", field: "achieved_reach" },

//       { name: "Planned Impressions", field: "planned_impressions" },
//       { name: "Delivered Impressions", field: "delivered_impressions" },
//       { name: "% achieved", field: "achieved_impressions" },

//       { name: "Planned Frequency", field: "planned_frequency" },
//       { name: "Delivered Frequency", field: "delivered_frequency" },
//       { name: "% achieved", field: "achieved_frequency" },

//       { name: "Planned CTR %", field: "planned_ctr" },
//       { name: "Delivered CTR %", field: "delivered_ctr" },
//       { name: "% achieved", field: "achieved_ctr" },

//       { name: "Planned VTR %", field: "planned_vtr" },
//       { name: "Delivered VTR %", field: "delivered_vtr" },
//       { name: "% achieved", field: "achieved_vtr" },

//       { name: "Planned Spends", field: "planned_spends" },
//       { name: "Delivered Spends", field: "delivered_vtr" },
//       { name: "% achieved", field: "achieved_spend" },
//     ];
//    */




//   // PLANNED VS DELIVERED METRICS DATA
//   const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
//     useState([]);
//   const [
//     plannedVsDeliveredMetricsDataLabels,
//     setPlannedVsDeliveredMetricsDataLabels,
//   ] = useState([]);

//   const [
//     isplannedVsDeliveredMetricsLoading,
//     setPlannedVsDeliveredMetricsIsLoading,
//   ] = useState(false);
//   const [
//     isplannedVsDeliveredMetricsLoaded,
//     setPlannedVsDeliveredMetricsIsLoaded,
//   ] = useState(false);
//   const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
//     useState(null);

//   const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] =
//     useState([]);
//   const [
//     plannedVsDeliveredTableDataHeaders,
//     setPlannedVsDeliveredTableDataHaeders,
//   ] = useState([]);
//   const [
//     isPlannedVsDeliveredTableDataLoading,
//     setPlannedVsDeliveredTableDataIsLoading,
//   ] = useState(false);
//   const [
//     isPlannedVsDeliveredTableDataLoaded,
//     setPlannedVsDeliveredTableDataIsLoaded,
//   ] = useState(false);
//   const [
//     plannedVsDeliveredTableDataerror,
//     setPlannedVsDeliveredTableDataError,
//   ] = useState(null);

//   let dropdownOptionsMarketTg = [
//     { value: "market", label: "Market" },
//     /*  { value: "tg", label: "TG" }, */
//   ];

//   // DROPDOWN STATE
//   const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
//     useState(dropdownOptionsMarketTg[0]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const dropdownMainSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMarketTgDropdownOption("");
//     } else {
//       setSelectedMarketTgDropdownOption(e);
//     }
//   };


//   const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
//     useState([]);
//   const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
//   const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
//     useState(true);
//   const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
//     useState(false);



//   const fetchRegionDropdownHandler = async () => {
//     setRegionDropdownOptionsIsLoading(true);
//     setRegionDropdownOptionsIsLoaded(false);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         //view_id: viewId,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/country-region-filter/",
//         config
//       );

//       setRegionDropdownOptions(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setRegionDropdownOptionsOnLoad(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setSelectedRegionDropdownOption(
//         res.data === undefined || res.data.length === 0 ? [] : res.data[0]
//       );
//       setRegionDropdownOptionsIsLoading(false);
//       setRegionDropdownOptionsIsLoaded(true);



//     } catch (error) {
//       setRegionDropdownOptions([]);
//       setRegionDropdownOptionsIsLoading(false);
//       setRegionDropdownOptionsIsLoaded(false);

//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(false);
//       setPlannedVsDeliveredMetricsError(error.code);

//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(false);
//       setPlannedVsDeliveredTableDataError(error.code);


//     }
//   };

//   useEffect(() => {
//     fetchRegionDropdownHandler();
//   }, []);

//   // DROPDOWN STATE
//   const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
//     useState([]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const dropdownRegionSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedRegionDropdownOption("");
//     } else {
//       setSelectedRegionDropdownOption(e);
//     }

//     IAEvent_Dropdown_Visit(
//       pageModule,
//       pageSubModule,
//       pagePlatform,
//       pagePrimaryTab,
//       null,
//       "PlannedVsDelivered",
//       e.label
//     );
//   };

//   // DROPDOWN STATE

//   // TO CHANGE DATA AS PER THE DROPDOWN VALUE
//   const fetchMarketTgDropdownHandler = () => {
//     if (selectedMarketTgDropdownOption.value === "market") {
//       setRegionDropdownOptionsOnLoad(regionDropdownOptions);
//       setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     } else if (selectedMarketTgDropdownOption.value === "tg") {
//       setRegionDropdownOptionsOnLoad([
//         { value: "all", label: "All" },
//         { value: "18-34 male", label: "18-34 Male" },
//       ]);
//       setSelectedRegionDropdownOption({ value: "all", label: "All" });
//     } else {
//       setRegionDropdownOptionsOnLoad([]);
//     }
//   };

//   // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
//   useEffect(() => {
//     fetchMarketTgDropdownHandler();
//   }, [
//     BRAND_ID,
//     selectedDates,
//     selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform,
//     selectedMarketTgDropdownOption

//     /* selectedRegionDropdownOption, */
//     /* dropdownOptionsMarketTg, */
//   ]);

//   useEffect(() => {
//     if (selectedMarketTgDropdownOption.value === "market") {
//       setRegionDropdownOptionsOnLoad(regionDropdownOptions);
//       setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     } else if (selectedMarketTgDropdownOption.value === "tg") {
//       setRegionDropdownOptionsOnLoad([
//         { value: "all", label: "All" },
//         { value: "18-34 male", label: "18-34 Male" },
//       ]);
//       setSelectedRegionDropdownOption({ value: "all", label: "All" });
//     }
//   }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);
//   useEffect(() => {
//     if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
//       fetchPlannedVsDeliveredMetricsHandler();
//       fetchPlannedVsDeliveredTableDataHandler();
//     }
//   }, [
//     regionDropdownOptionsIsLoaded,
//     regionDropdownOptionsIsLoading,
//     selectedRegionDropdownOption
//   ]);

//   useEffect(() => {
//     setSelectedMarketTgDropdownOption([]);
//     setSelectedRegionDropdownOption([]);
//     setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
//     setSelectedRegionDropdownOption(regionDropdownOptions[0]);

//   }, [BRAND_ID, selectedDates,

//   ]);


//   useEffect(() => {
//     setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
//     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     if (selectedMarketTgDropdownOption.value === "market" && selectedRegionDropdownOption.value === "India") {
//       fetchPlannedVsDeliveredMetricsHandler();
//       fetchPlannedVsDeliveredTableDataHandler();
//     }
//   }, [selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform]);





//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // METRIC CARDS

//   // TO FETCH METRIC CARDS FROM API
//   const fetchMetricCardsHandler = async () => {
//     setMetricCardsIsLoading(true);
//     setMetricCardsIsLoaded(false);
//     setMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/metric-card/", config);
//       setMetricCards(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(true);
//       setMetricCardsError(null);
//     } catch (error) {
//       setMetricCards([]);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(false);
//       setMetricCardsError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let metricCardsSection =
//     <Wrapper>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div className="grid col_span_4 section_header">
//           <div className="section_info">  <h2 className="section_card_title">Summary
//           </h2></div>
//         </div>
//         <div className="grid col_span_4 grid_margin_bottom">
//           <Loader loaderType="cardLoader" />
//         </div>
//       </div>
//     </Wrapper>

//   // IF DATA IS LOADED
//   if (isMetricCardsLoaded && !isMetricCardsLoading) {
//     if (NoDataAvailableChecker(metricCards)) {
//       metricCardsSection = (
//         <Wrapper>
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_4 section_header">
//               <div className="section_info">  <h2 className="section_card_title">Summary
//               </h2></div>
//             </div>
//             <div className="grid col_span_4 grid_margin_bottom">
//               <NoDataAvailableLoader chartType="cardChartType" />
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       metricCardsSection = (
//         <Wrapper>
//           <ErrorBoundary>
//             <Slider sliderTitle={"Summary"}>
//               {metricCards.map((data, index) => (
//                 <SwiperSlide key={index}>
//                   <Card
//                     module="campaign"
//                     platform="dv360"
//                     section="overall"
//                     cardTitle={data.label}
//                     currentValue={data.currentValue}
//                     previousValue={data.previousValue}
//                     rowOneTitle={"Previous"}
//                     numberVariant={"number"}
//                   />
//                 </SwiperSlide>
//               ))}
//             </Slider>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (metricCardserror) {
//     metricCardsSection =
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <ServerErrorsLoader
//               chartType="cardChartType"
//               error={metricCardserror}
//             />
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // IF DATA LOADING
//   if (isMetricCardsLoading) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <Loader loaderType="cardLoader" />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN SUMMARY

//   // TO FETCH METRIC CARDS FROM API
//   const fetchCampaignSummaryHandler = async () => {
//     setCampaignSummaryIsLoading(true);
//     setCampaignSummaryIsLoaded(false);
//     setCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/summary-table/", config);

//       setCampaignSummary(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.campaign === undefined || res.data.campaign.length === 0 ? [] :
//             res.data.campaign
//       );
//       setCampaignSummaryAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data
//       );
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(true);
//       setCampaignSummaryError(null);
//     } catch (error) {
//       setCampaignSummaryAllDropdownsData([]);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(false);
//       setCampaignSummaryError(error.code);
//     }
//   };

//   let campaignSummaryTable =
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Summary</h2>
//             <SectionDefinationsBadge
//               title="Summary"
//               module="campaign"
//               platform="Dv360"
//               section="overall"
//             />
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>

//   // IF DATA IS LOADED
//   if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
//     campaignSummaryTable = (
//       <ErrorBoundary>
//         <Datatable
//           tableTitle="Summary"
//           tableHeader={campaignSummaryTableHeaders}
//           tableData={campaignSummary}
//           tableLength={10}
//           isFooterShow={true}
//           searchPlaceHolder={"Search Campaign"}
//           tablePageSubModule={"campaign"}
//           tablePagePlatform={"Dv360"}
//           tablePagePrimaryTab={"overall"}
//           tableDropdown={
//             <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={campaignSummaryOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedCampaignSummaryDropdownOption}
//               selectedOptions={selectedCampaignSummaryDropdownOption}
//               setStatedropdown={campaignSummaryDropdownSelectionHandler}
//             />
//           }
//           tableID={"search_campaign_section"}
//           tableExportDropdown={
//             <ExportDropdown
//               moduleName={pageModule}
//               subModuleName={pageSubModule}
//               platform={pagePlatform}
//               tab={pagePrimaryTab}
//               sectionId={"search_campaign_section"}
//               sectionName={`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
//               sectionData={campaignSummary}
//             />
//           }
//         />
//       </ErrorBoundary>
//     );
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (campaignSummaryerror) {
//     campaignSummaryTable =
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary</h2>
//               <SectionDefinationsBadge
//                 title="Summary"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // IF DATA LOADING
//   if (isCampaignSummaryLoading) {
//     campaignSummaryTable =
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary</h2>
//               <SectionDefinationsBadge
//                 title="Summary"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

//   const fetchChildDonutCampaignHandler = async () => {
//     setChildDonutCampaignObjectiveLoading(true);
//     setChildDonutCampaignObjectiveLoaded(false);
//     setChildDonutCampaignObjectiveError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/campaign-distribution/",
//         config
//       );

//       setChildDonutCampaignObjective(res.data === undefined || res.data.length === 0 ? [] : res.data);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(true);
//       setChildDonutCampaignObjectiveError(null);
//     } catch (error) {
//       setChildDonutCampaignObjective([]);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(false);
//       setChildDonutCampaignObjectiveError(error.code);
//     }
//   };

//   let childDonutCampaignChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     childDonutCampaignObjectiveLoaded &&
//     !childDonutCampaignObjectiveLoading
//   ) {
//     if (
//       NoDataAvailableChecker(childDonutCampaignObjective)
//     ) {
//       childDonutCampaignChart = (
//         <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
//       );
//     } else {
//       childDonutCampaignChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="donutChartType">
//             <DonutPieChart
//               chartClass="section_card_chart"
//               chartData={childDonutCampaignObjective}
//               chartId="CampaignDonutChild"
//               onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (childDonutCampaignObjectiveError) {
//     childDonutCampaignChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={childDonutCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (childDonutCampaignObjectiveLoading) {
//     childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

//   let defaultLineChartCampaignDisabledvalues = [];

//   if (companyID === COKE_COMPANY_ID) {
//     defaultLineChartCampaignDisabledvalues = []
//   } else {
//     defaultLineChartCampaignDisabledvalues = ["Clicks", "Spends"]
//   }

//   const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

//   const fetchLineChartCampaignObjWiseHandler = async () => {
//     setLineChartCampaignObjectiveLoading(true);
//     setLineChartCampaignObjectiveLoaded(false);
//     setLineChartCampaignObjectiveError(null);

//     // var objectiveConfig = (function () {
//     //   if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
//     //     return { "media_type": null }
//     //   } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
//     //     return { "media_type": selectedObjectives }
//     //   } else {
//     //     return { "media_type": childDonutSliceToggle }
//     //   }
//     // })()

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//         media_type: childDonutSliceToggle
//         // ...objectiveConfig
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/campaign-distribution-linechart/",
//         config
//       );

//       setLineChartCampaignObjective(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setLineChartCampaignObjectiveLabel(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension
//       );
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(true);
//       setLineChartCampaignObjectiveError(null);
//     } catch (error) {
//       setLineChartCampaignObjective([]);
//       setLineChartCampaignObjectiveLabel([]);
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(false);
//       setLineChartCampaignObjectiveError(error.code);
//     }
//   };

//   let lineChartCampaignSection = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   let lineChartCampaignSection2 = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
//     if (
//       NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
//     ) {
//       lineChartCampaignSection = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );

//       lineChartCampaignSection2 = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//     } else {


//       if (isMultiLineCluster) {

//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impressions"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//         lineChartCampaignSection2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign_2"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 showLegend={true}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//                 showLabels={true}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//       } else {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="linechartCamapign"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impressions"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (lineChartCampaignObjectiveError) {
//     lineChartCampaignSection = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );

//     lineChartCampaignSection2 = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (lineChartCampaignObjectiveLoading) {
//     lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//     lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   //   DEVICE WISE DISTRIBUTION

//   let treemapLevel = 1;
//   const [treemapDeviceType, setTreemapDeviceType] = useState("");
//   const [treemapOS, setTreemapOS] = useState("");

//   const [treemapParams, setTreemapParams] = useState({
//     brand_id: BRAND_ID,
//     start_date: selectedDates.startDate,
//     end_date: selectedDates.endDate,
//     previous_start_date: selectedDates.prevStartDate,
//     previous_end_date: selectedDates.prevEndDate,
//     objective: selectedObjectives,
//     platform_campaign: selectedIdandPlatform,
//     platform: selectedPlatform,
//     campaign: selectedCampaigns,
//     campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//     level: 1,
//   });

//   const chartlevelHandler = (data) => {
//     if (data.level === "1") {
//       setTreemapParams({
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//         level: 2,
//         device_type: data.device_type,
//       });
//       setTreemapDeviceType(data.device_type);
//     } else if (data.level === "2") {
//       setTreemapParams({
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//         level: 3,
//         device_type: treemapDeviceType,
//         operating_system: data.operating_system,
//       });
//       setTreemapOS(data.operating_system);
//     } else {
//       alert("No further drill down available");
//     }
//   };

//   const fetchDeviceWiseDistributionHandler = async () => {
//     setDeviceWiseDistributionIsLoading(true);
//     setDeviceWiseDistributionIsLoaded(false);
//     setDeviceWiseDistributionError(null);

//     const config = {
//       params: treemapParams,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/device-wise-distribution/",
//         config
//       );

//       setDeviceWiseDistribution(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setDeviceWiseDistributionIsLoading(false);
//       setDeviceWiseDistributionIsLoaded(true);
//       setDeviceWiseDistributionError(null);
//     } catch (error) {
//       setDeviceWiseDistribution([]);
//       setDeviceWiseDistributionIsLoading(false);
//       setDeviceWiseDistributionIsLoaded(false);
//       setDeviceWiseDistributionError(error.code);
//     }
//   };

//   let deviceWiseDistributionSection = (
//     <Wrapper>
//       <div className="section_card_chart_wrapper flex column">
//         <Loader loaderType="treeChartLoader" />
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
//     if (
//       NoDataAvailableChecker(deviceWiseDistribution) || (deviceWiseDistribution.length === 1 && deviceWiseDistribution[0].value === 0)
//     ) {
//       deviceWiseDistributionSection = (
//         <Wrapper>
//           <div className="section_card_chart_wrapper flex column">
//             <NoDataAvailableLoader chartType="treeChartType" />
//           </div>
//         </Wrapper>
//       );
//     } else {
//       if (treemapLevel > 3) {
//         deviceWiseDistributionSection = (
//           <Wrapper>
//             <div className="section_card_chart_wrapper flex column">
//               <NoDataAvailableLoader chartType="treeChartType" />
//             </div>
//           </Wrapper>
//         );
//       } else {
//         deviceWiseDistributionSection = (
//           <Wrapper>
//             <div className="section_card_chart_wrapper flex column">
//               <ErrorBoundary chartType="treeChartType">
//                 <SimpleTreeMap
//                   chartId="simpleTreemaptest"
//                   chartClass="section_card_chart"
//                   chartData={deviceWiseDistribution}
//                   levelHandler={chartlevelHandler}
//                   chartLabels={[
//                     {
//                       name: "name",
//                       value: "value",
//                       toolTipvalue1: "value",
//                       toolTipvalue1Label: `${selectedDropdownValue}`,

//                     },
//                   ]}
//                 />
//               </ErrorBoundary>
//             </div>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (deviceWiseDistributionerror) {
//     deviceWiseDistributionSection = (
//       <Wrapper>
//         <div className="section_card_chart_wrapper flex column">
//           <ServerErrorsLoader chartType="treeChartType" error={deviceWiseDistributionerror} />
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isDeviceWiseDistributionLoading) {
//     deviceWiseDistributionSection = (
//       <Wrapper>
//         <div className="section_card_chart_wrapper flex column">
//           <Loader loaderType="treeChartLoader" />
//         </div>
//       </Wrapper>
//     );
//   }

//   // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
//   useEffect(() => {
//     fetchDeviceWiseDistributionHandler();
//   }, [treemapParams]);


//   useEffect(() => {
//     var temp = {
//       metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//       objective: selectedObjectives,
//       platform_campaign: selectedIdandPlatform,
//       platform: selectedPlatform,
//       campaign: selectedCampaigns,
//       campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),

//     }
//     setTreemapParams({ ...treemapParams, ...temp })
//   }, [BRAND_ID, selectedMidSectionDropdownValue, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])



//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CONTENT POSITION WISE DISTRIBUTION

//   // TO FETCH DATA FROM API
//   const fetchContentPositionDistributionHandler = async () => {
//     setContentPositionDistributionIsLoading(true);
//     setContentPositionDistributionIsLoaded(false);
//     setContentPositionDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/content-position-wise-distribution/",
//         config
//       );
//       setContentPositionDistribution(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );

//       setContentPositionDistributionIsLoading(false);
//       setContentPositionDistributionIsLoaded(true);
//       setContentPositionDistributionError(null);
//     } catch (error) {
//       setContentPositionDistribution([]);
//       setContentPositionDistributionIsLoading(false);
//       setContentPositionDistributionIsLoaded(false);
//       setContentPositionDistributionError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let contentPositionDistributionChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   let contentPositionDistributionDetails = (
//     <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
//   )

//   // IF DATA IS LOADED
//   if (isContentPositionDistributionLoaded && !isContentPositionDistributionLoading) {
//     if (NoDataAvailableChecker(contentPositionDistribution)) {
//       contentPositionDistributionChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="donutChartType" />
//         </Wrapper>
//       );
//       contentPositionDistributionDetails = (
//         <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
//       )
//     } else {
//       contentPositionDistributionChart = (
//         <Wrapper>
//           <ErrorBoundary>
//             <DonutPieChart
//               chartId={"contentPositionDistribution"}
//               chartClass={"section_card_chart"}
//               chartData={contentPositionDistribution}
//               showLegend={true}
//               showLabels={true}
//               showVerticalLabels={true}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );

//       // let sum = 0
//       // if (NoDataAvailableChecker(contentPositionDistribution)) {
//       //   sum = "undefined"
//       // } else {
//       //   sum = contentPositionDistribution.map(obj => obj.value).reduce((accumulator, current) => accumulator + current, 0);
//       // }

//       // if (sum === "undefined") {
//       //   contentPositionDistributionDetails = (
//       //     <Wrapper>
//       //       <NoDataAvailableLoader chartType="tableChartType" />
//       //     </Wrapper>
//       //   );
//       // } else {
//       contentPositionDistributionDetails = (
//         <Wrapper>
//           <ErrorBoundary>
//             <div className="chart_details_wrapper">
//               <div className="main_wrapper">
//                 <div className="body">
//                   {contentPositionDistribution.map(
//                     (details, postImpressionIndex) => (
//                       <Wrapper key={postImpressionIndex}>
//                         <div className="row">
//                           <div className="title_wrapper">
//                             {details.label}
//                           </div>
//                           <div className="value_wrapper">
//                             <ErrorBoundary>
//                               <NumberFormatter number={details.value} />
//                             </ErrorBoundary>
//                             {details.metric &&
//                               <div className="metrics_wrapper">
//                                 <div className="title_wrapper">
//                                   {details.metric[0].label + " : "}
//                                 </div>
//                                 <div className="value_wrapper">
//                                   <ErrorBoundary>
//                                     <NumberFormatter
//                                       number={details.metric[0].value}
//                                     />
//                                   </ErrorBoundary>
//                                 </div>
//                               </div>
//                             }
//                           </div>
//                         </div>
//                       </Wrapper>
//                     ))
//                   }
//                 </div>
//                 {/* <div className="footer">
//                     <div className="title_wrapper">
//                       Total {" " + selectedDropdownValue}
//                     </div>
//                     <div className="value_wrapper">
//                       <NumberFormatter number={sum} />
//                     </div>
//                   </div> */}
//               </div>
//             </div>
//           </ErrorBoundary>
//         </Wrapper>
//       )
//       // }

//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (contentPositionDistributionerror) {
//     contentPositionDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={contentPositionDistributionerror}
//         />
//       </Wrapper>
//     );
//     contentPositionDistributionDetails = (
//       <Wrapper><ServerErrorsLoader chartType="tableChartType" error={contentPositionDistributionerror} /></Wrapper>
//     )
//   }

//   // IF DATA LOADING
//   if (isContentPositionDistributionLoading) {
//     contentPositionDistributionChart = (
//       <Wrapper>
//         <Loader loaderType="donutChartLoader" />
//       </Wrapper>
//     );
//     contentPositionDistributionDetails = (
//       <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
//     )
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // APP URL DISTRIBUTION
//   const fetchAppUrlDistributionHandler = async () => {
//     setAppUrlDistributionIsLoading(true);
//     setAppUrlDistributionIsLoaded(false);
//     setAppUrlDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/app-url-wise-bar-chart-distribution/", config);

//       setAppUrlDistribution(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setAppUrlDistributionLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.label === undefined || res.data.label.length === 0 ? [] :
//             res.data.label
//       );
//       setAppUrlDistributionIsLoading(false);
//       setAppUrlDistributionIsLoaded(true);
//       setAppUrlDistributionError(null);
//     } catch (error) {
//       setAppUrlDistribution([]);
//       setAppUrlDistributionLabels([]);
//       setAppUrlDistributionIsLoading(false);
//       setAppUrlDistributionIsLoaded(false);
//       setAppUrlDistributionError(error.code);
//     }
//   };

//   let appUrlDistributionChart = (
//     <Wrapper>
//       <Loader loaderType={"barChartLoader"} />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
//     if (
//       NoDataAvailableChecker(appUrlDistribution) ||
//       NoDataAvailableChecker(appUrlDistributionLabels)
//     ) {
//       appUrlDistributionChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"barChartType"} />
//         </Wrapper>
//       );
//     } else {
//       appUrlDistributionChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="barChartType">
//             <BarChart
//               chartId="social_twitter_profile_clickss"
//               chartClass="section_card_chart"
//               chartData={appUrlDistribution}
//               chartLabels={appUrlDistributionLabels}
//             />

//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (appUrlDistributionerror) {
//     appUrlDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"barChartType"} error={appUrlDistributionerror} />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isAppUrlDistributionLoading) {
//     appUrlDistributionChart = (
//       <Wrapper>
//         <Loader loaderType={"barChartLoader"} />
//       </Wrapper>
//     );
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // APP URL DISTRIBUTION
//   const [isAppUrlTrendOpen, setIsAppUrlTrendOpen] = useState(false)

//   let defaultAppUrlTrendDisabledvalues = [];

//   if (companyID === COKE_COMPANY_ID) {
//     defaultAppUrlTrendDisabledvalues = []
//   } else {
//     defaultAppUrlTrendDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
//   }

//   const [appUrlTrendDisabledvalues, setAppUrlTrendDisabledvalues] = useState(defaultAppUrlTrendDisabledvalues)


//   const fetchAppUrlTrendHandler = async () => {
//     setAppUrlTrendIsLoading(true);
//     setAppUrlTrendIsLoaded(false);
//     setAppUrlTrendError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/dv360/overall/app-url-wise-distribution/", config);

//       setAppUrlTrend(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setAppUrlTrendLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.label === undefined || res.data.label.length === 0 ? [] :
//             res.data.label
//       );

//       setAppUrlTrendIsLoading(false);
//       setAppUrlTrendIsLoaded(true);
//       setAppUrlTrendError(null);
//     } catch (error) {
//       setAppUrlTrend([]);
//       setAppUrlTrendLabels([]);
//       setAppUrlTrendIsLoading(false);
//       setAppUrlTrendIsLoaded(false);
//       setAppUrlTrendError(error.code);
//     }
//   };

//   let appUrlTrendChart = (
//     <Wrapper>
//       <Loader loaderType={"singleLineChartLoader"} />
//     </Wrapper>
//   );

//   let appUrlTrendChart2 = (
//     <Wrapper>
//       <Loader loaderType={"singleLineChartLoader"} />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isAppUrlTrendLoaded && !isAppUrlTrendLoading) {
//     if (
//       NoDataAvailableChecker(appUrlTrend) ||
//       NoDataAvailableChecker(appUrlTrendLabels)
//     ) {
//       appUrlTrendChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"lineChartType"} />
//         </Wrapper>
//       );

//       appUrlTrendChart2 = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType={"lineChartType"} />
//         </Wrapper>
//       );
//     } else {


//       if (isMultiLineCluster) {
//         appUrlTrendChart = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartId="app_url_trend"
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartLabels={appUrlTrendLabels}
//                 disabledLegendArray={appUrlTrendDisabledvalues}
//                 setDisabledValues={setAppUrlTrendDisabledvalues}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//         appUrlTrendChart2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartId="app_url_trend_2"
//                 chartLabels={appUrlTrendLabels}
//                 showLegend={true}
//                 disabledLegendArray={appUrlTrendDisabledvalues}
//                 setDisabledValues={setAppUrlTrendDisabledvalues}
//                 showLabels={true}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );

//       } else {
//         appUrlTrendChart = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartId="app_url_trend"
//                 chartClass="section_card_chart"
//                 chartData={appUrlTrend}
//                 chartLabels={appUrlTrendLabels}
//               />

//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (appUrlTrenderror) {
//     appUrlTrendChart = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
//       </Wrapper>
//     );

//     appUrlTrendChart2 = (
//       <Wrapper>
//         <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isAppUrlTrendLoading) {
//     appUrlTrendChart = (
//       <Wrapper>
//         <Loader loaderType={"singleLineChartLoader"} />
//       </Wrapper>
//     );

//     appUrlTrendChart2 = (
//       <Wrapper>
//         <Loader loaderType={"singleLineChartLoader"} />
//       </Wrapper>
//     );
//   }



//   const tabData = ["By Country", "By State", "By City"];
//   const [selectedTab, setSelectedTab] = useState(tabData[0]);
//   const handleCallback = (event) => {
//     if (selectedTab !== event) {
//       setSelectedTab(event);
//     }
//     IAEvent_Secondary_Tab_Visit(
//       "Marketing Intelligence",
//       "Campaign",
//       "Dv360",
//       "Video",
//       event
//     );
//   };


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // STATE MAP

//   const fetchStateHandler = async () => {
//     setStateDataIsLoading(true);
//     setStateDataIsLoaded(false);
//     setStateDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/state-distribution/",
//         config
//       );

//       setStateData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );

//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(true);
//       setStateDataError(null);
//     } catch (error) {
//       setStateData([]);
//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(false);
//       setStateDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: STATE
//   let audienceDistributionState = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By State</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isStateDataLoaded && !isStateDataLoading) {
//     if (
//       NoDataAvailableChecker(stateData) ||
//       NoDataAvailableChecker(stateTableDataHeaders)
//     ) {
//       audienceDistributionState = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By State</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionState = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By State"}
//               isDynamicFlagVisible={true}
//               tableHeader={stateTableDataHeaders}
//               tableData={stateData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search State"}
//               tableID="audience_distribution_country_state_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_state_section"}
//                   sectionName={"StateTable_Audience Distribution By State"}
//                   sectionData={stateData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (stateDataerror) {
//     audienceDistributionState = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isStateDataLoading) {
//     audienceDistributionState = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CITY MAP

//   const fetchCityHandler = async () => {
//     setCityDataIsLoading(true);
//     setCityDataIsLoaded(false);
//     setCityDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/city-distribution/",
//         config
//       );
//       setCityData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(true);
//       setCityDataError(null);
//     } catch (error) {
//       setCityData([]);
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(false);
//       setCityDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: CITY
//   let audienceDistributionCity = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By City</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCityDataLoaded && !isCityDataLoading) {
//     if (
//       NoDataAvailableChecker(cityData) ||
//       NoDataAvailableChecker(cityTableDataHeaders)
//     ) {
//       audienceDistributionCity = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By City</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionCity = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By City"}
//               isDynamicFlagVisible={true}
//               tableHeader={cityTableDataHeaders}
//               tableData={cityData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search City"}
//               tableID="audience_distribution_country_city_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_city_section"}
//                   sectionName={"CityTable_Audience Distribution By City"}
//                   sectionData={cityData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (cityDataerror) {
//     audienceDistributionCity = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCityDataLoading) {
//     audienceDistributionCity = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // COUNTRY TABLE

//   const fetchCountryHandler = async () => {
//     setCountryDataIsLoading(true);
//     setCountryDataIsLoaded(false);
//     setCountryDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/country-distribution/",
//         config
//       );

//       setCountryData(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(true);
//       setCountryDataError(null);
//     } catch (error) {
//       setCountryData([]);
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(false);
//       setCountryDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: CITY
//   let audienceDistributionCountry = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By Country</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCountryDataLoaded && !isCountryDataLoading) {
//     if (
//       NoDataAvailableChecker(countryData) ||
//       NoDataAvailableChecker(countryTableDataHeaders)
//     ) {
//       audienceDistributionCountry = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By Country</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       audienceDistributionCountry = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle={"By Country"}
//               isCountryFlagVisible={true}
//               tableHeader={countryTableDataHeaders}
//               tableData={countryData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Country"}
//               tableID="audience_distribution_country_country_section"
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_country_country_section"}
//                   sectionName={"CountryTable_Audience Distribution - Country"}
//                   sectionData={countryData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (countryDataerror) {
//     audienceDistributionCountry = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCountryDataLoading) {
//     audienceDistributionCountry = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }




//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // PLANNED VS METRICS

//   // TO FETCH METRIC DATA FROM API
//   const fetchPlannedVsDeliveredMetricsHandler = async () => {
//     setPlannedVsDeliveredMetricsIsLoading(true);
//     setPlannedVsDeliveredMetricsIsLoaded(false);
//     setPlannedVsDeliveredMetricsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         drop_down_type:
//           selectedMarketTgDropdownOption === undefined ||
//             selectedMarketTgDropdownOption === null ||
//             selectedMarketTgDropdownOption === ""
//             ? ""
//             : selectedMarketTgDropdownOption.value.toLowerCase(),

//         drop_down_value:
//           selectedRegionDropdownOption === undefined ||
//             selectedRegionDropdownOption === null ||
//             selectedRegionDropdownOption === ""
//             ? ""
//             : selectedRegionDropdownOption.value.toLowerCase(),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/planned-delivered-wise-performance/v2/",
//         config
//       );

//       setPlannedVsDeliveredMetricsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setPlannedVsDeliveredMetricsDataLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.label === undefined || res.data.label.length === 0
//             ? []
//             : res.data.label
//       );

//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(true);
//       setPlannedVsDeliveredMetricsError(null);
//     } catch (error) {
//       setPlannedVsDeliveredMetricsData([]);
//       setPlannedVsDeliveredMetricsDataLabels([]);
//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(false);
//       setPlannedVsDeliveredMetricsError(error.code);
//     }
//   };

//   let plannedVsDeliveredMetricsBarChart = (
//     <Wrapper>
//       <Loader loaderType="barChartLoader" />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (
//     isplannedVsDeliveredMetricsLoaded &&
//     !isplannedVsDeliveredMetricsLoading
//   ) {
//     if (
//       NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
//       NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
//     ) {
//       plannedVsDeliveredMetricsBarChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="barChartType" />
//         </Wrapper>
//       );
//     } else {
//       plannedVsDeliveredMetricsBarChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="barChartType">
//             <BarChart
//               chartId="plannedDeliveredBarChart"
//               chartClass="section_card_chart"
//               chartData={plannedVsDeliveredMetricsData}
//               chartLabels={plannedVsDeliveredMetricsDataLabels}
//               chartColorSet={BlueColorStart500}
//               showLabels={true}
//               minimumGridDistance={0}
//               showLegend={false}
//               percentageTooltip={true}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (plannedVsDeliveredMetricsError) {
//     plannedVsDeliveredMetricsBarChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="barChartType"
//           error={plannedVsDeliveredMetricsError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isplannedVsDeliveredMetricsLoading) {
//     plannedVsDeliveredMetricsBarChart = (
//       <Wrapper>
//         <Loader loaderType="barChartLoader" />
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // TABLE

//   // TO FETCH METRIC  FROM API
//   const fetchPlannedVsDeliveredTableDataHandler = async () => {
//     setPlannedVsDeliveredTableDataIsLoading(true);
//     setPlannedVsDeliveredTableDataIsLoaded(false);
//     setPlannedVsDeliveredTableDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         drop_down_type:
//           selectedMarketTgDropdownOption === undefined ||
//             selectedMarketTgDropdownOption === null ||
//             selectedMarketTgDropdownOption === ""
//             ? ""
//             : selectedMarketTgDropdownOption.value.toLowerCase(),

//         drop_down_value:
//           selectedRegionDropdownOption === undefined ||
//             selectedRegionDropdownOption === null ||
//             selectedRegionDropdownOption === ""
//             ? ""
//             : selectedRegionDropdownOption.value.toLowerCase(),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };


//     try {
//       const res = await axios.get(
//         "performance/dv360/overall/planned-delivered-table/",
//         config
//       );
//       setPlannedVsDeliveredTableData(
//         Object.values(res.data).length === 0 ||
//           res.data === undefined ||
//           res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setPlannedVsDeliveredTableDataHaeders(
//         Object.values(res.data).length === 0 ||
//           res.data === undefined ||
//           res.data.length === 0
//           ? []
//           : res.data.label === undefined || res.data.label.length === 0
//             ? []
//             : res.data.label
//       );

//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(true);
//       setPlannedVsDeliveredTableDataError(null);
//     } catch (error) {
//       setPlannedVsDeliveredTableData([]);
//       setPlannedVsDeliveredTableDataHaeders([]);
//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(false);
//       setPlannedVsDeliveredTableDataError(error.code);
//     }
//   };

//   let plannedVsDeliveredTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 Campaign wise Planned v/s Delivered table
//                 {selectedMarketTgDropdownOption &&
//                   " : " + selectedMarketTgDropdownOption.label}
//                 {selectedRegionDropdownOption &&
//                   " : " + selectedRegionDropdownOption.label}

//               </h2>
//               <SectionDefinationsBadge
//                 title={"Campaign wise Planned v/s Delivered table"}
//                 module={pageSubModule}
//                 platform={pagePlatform}
//                 section={pagePrimaryTab}
//               />
//             </div>

//           </div>
//         </div>
//         <div className="grid col_span_4 section_card_body">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     isPlannedVsDeliveredTableDataLoaded &&
//     !isPlannedVsDeliveredTableDataLoading
//   ) {
//     if (
//       NoDataAvailableChecker(plannedVsDeliveredTableData) ||
//       NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
//     ) {
//       plannedVsDeliveredTable = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <div className="section_info">
//                   <h2 className="section_card_title">
//                     Campaign wise Planned v/s Delivered table
//                     {selectedMarketTgDropdownOption &&
//                       " : " + selectedMarketTgDropdownOption.label}
//                     {selectedRegionDropdownOption &&
//                       " : " + selectedRegionDropdownOption.label}

//                   </h2>
//                   <SectionDefinationsBadge
//                     title={"Campaign wise Planned v/s Delivered table"}
//                     module={pageSubModule}
//                     platform={pagePlatform}
//                     section={pagePrimaryTab}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="grid col_span_4 section_card_body">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       plannedVsDeliveredTable = (
//         <Wrapper>
//           <ErrorBoundary>
//             <Datatable
//               tableTitle={
//                 "Campaign wise Planned v/s Delivered table" +
//                 `${selectedMarketTgDropdownOption &&
//                 " : " + selectedMarketTgDropdownOption.label
//                 }
//             ${selectedRegionDropdownOption &&
//                 " : " + selectedRegionDropdownOption.label
//                 }`
//               }
//               tablePageSubModule={pageSubModule}
//               tablePagePlatform={pagePlatform}
//               tablePagePrimaryTab={pagePrimaryTab}
//               tableHeader={plannedVsDeliveredTableDataHeaders}
//               tableData={plannedVsDeliveredTableData}
//               tableLength={10}
//               isFooterShow={true}
//               searchPlaceHolder={"Search table"}
//               tableID="planned_vs_delivered_table"
//               tableExportDropdown={
//                 <ExportDropdown
//                   sectionId={"planned_vs_delivered_table"}
//                   sectionName={`TableDynaLabels_Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
//                     } ${selectedRegionDropdownOption !== undefined &&
//                       selectedRegionDropdownOption !== null &&
//                       selectedRegionDropdownOption !== ""
//                       ? `: ` + selectedRegionDropdownOption.label
//                       : ""
//                     }`}
//                   sectionData={plannedVsDeliveredTableData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (plannedVsDeliveredTableDataerror) {
//     plannedVsDeliveredTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   Campaign wise Planned v/s Delivered table
//                   {selectedMarketTgDropdownOption &&
//                     " : " + selectedMarketTgDropdownOption.label}
//                   {selectedRegionDropdownOption &&
//                     " : " + selectedRegionDropdownOption.label}

//                 </h2>
//                 <SectionDefinationsBadge
//                   title={"Campaign wise Planned v/s Delivered table"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_4 section_card_body">
//             <div className="section_card_body">
//               <ServerErrorsLoader
//                 error={plannedVsDeliveredTableDataerror}
//                 chartType="tableChartType"
//               />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isPlannedVsDeliveredTableDataLoading) {
//     plannedVsDeliveredTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   Campaign wise Planned v/s Delivered table
//                   {selectedMarketTgDropdownOption &&
//                     " : " + selectedMarketTgDropdownOption.label}
//                   {selectedRegionDropdownOption &&
//                     " : " + selectedRegionDropdownOption.label}

//                 </h2>
//                 <SectionDefinationsBadge
//                   title={"Campaign wise Planned v/s Delivered table"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_4 section_card_body">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }





//   var campaignObjectCSV = {
//     "CampaignTypeDonut_Campaign Type Wise Donut": childDonutCampaignObjective,
//     [`CampObjectiveDynaLabels_Campaign Type Wise Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
//   };

//   var barChartCSV = {
//     [`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
//       } ${selectedRegionDropdownOption !== undefined &&
//         selectedRegionDropdownOption !== null &&
//         selectedRegionDropdownOption !== ""
//         ? `: ` + selectedRegionDropdownOption.label
//         : ""
//       }`]: plannedVsDeliveredMetricsData,
//   };

//   var tableCSV = {
//     [`TableDynaLabels_PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
//       } ${selectedRegionDropdownOption !== undefined &&
//         selectedRegionDropdownOption !== null &&
//         selectedRegionDropdownOption !== ""
//         ? `: ` + selectedRegionDropdownOption.label
//         : ""
//       }`]: plannedVsDeliveredTableData,
//   };


//   var allData = {
//     ...topCardData,
//     "SummaryCard_Summary Card": metricCards,
//     ...campaignObjectCSV,
//     [`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`]: deviceWiseDistribution,
//     [`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`]: contentPositionDistribution,
//     [`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`]: appUrlDistribution,
//     [`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`]: appUrlTrend,
//     "CountryTable_Audience Distribution - Country": countryData,
//     "StateTable_Audience Distribution - State": stateData,
//     "CityTable_Audience Distribution - City": cityData,
//     [`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]: campaignSummary,
//     ...barChartCSV,
//     ...tableCSV
//   };
//   useEffect(() => {
//     setFullPageExport(allData);
//   }, [
//     metricCards,
//     childDonutCampaignObjective,
//     lineChartCampaignObjective,
//     deviceWiseDistribution,
//     contentPositionDistribution,
//     appUrlDistribution,
//     appUrlTrend,
//     countryData,
//     stateData,
//     cityData,
//     campaignSummary,
//     plannedVsDeliveredMetricsData,
//     plannedVsDeliveredTableData
//   ]);

//   useEffect(() => {
//     if (
//       isMetricCardsLoaded &&
//       childDonutCampaignObjectiveLoaded &&
//       lineChartCampaignObjectiveLoaded &&
//       isDeviceWiseDistributionLoaded &&
//       isContentPositionDistributionLoaded &&
//       isAppUrlDistributionLoaded &&
//       isAppUrlTrendLoaded &&
//       isCountryDataLoaded &&
//       isStateDataLoaded &&
//       isCityDataLoaded &&
//       isCampaignSummaryLoaded &&
//       isplannedVsDeliveredMetricsLoaded &&
//       isPlannedVsDeliveredTableDataLoaded
//     ) {
//       setIsExportDisabled(false)
//     } else {
//       setIsExportDisabled(true)
//     }
//   }, [
//     isMetricCardsLoaded,
//     childDonutCampaignObjectiveLoaded,
//     lineChartCampaignObjectiveLoaded,
//     isDeviceWiseDistributionLoaded,
//     isContentPositionDistributionLoaded,
//     isAppUrlDistributionLoaded,
//     isAppUrlTrendLoaded,
//     isCountryDataLoaded,
//     isStateDataLoaded,
//     isCityDataLoaded,
//     isCampaignSummaryLoaded,
//     isplannedVsDeliveredMetricsLoaded,
//     isPlannedVsDeliveredTableDataLoaded
//   ])


//   return (
//     <Wrapper>
//       {metricCardsSection}
//       <div id="campaign_objective_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
//               <SectionDefinationsBadge
//                 title={"Campaign Type Wise Distribution"}
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isLineChartCampaignOpen}
//               setOpen={setIsLineChartCampaignOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"linechartCamapign_2"}
//                   sectionName={"Campaign Type Wise Distribution"}
//                   sectionData={campaignObjectCSV}
//                 />}
//               parentId={"campaign_objective_section"}
//               title={`Campaign Type Wise Distribution`}
//               chart={lineChartCampaignSection2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"campaign_objective_section"}
//                 sectionName={"Campaign Type Wise Distribution"}
//                 sectionData={campaignObjectCSV}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_1 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {childDonutCampaignChart}
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_3 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {lineChartCampaignSection}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="section_card section_dropdown_header">
//         <div className="grid grid_cols_1">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Performance By KPI
//             </h2>
//             <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={midSectionDropdonwOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedMidSectionDropdownValue}
//               selectedOptions={selectedMidSectionDropdownValue}
//               setStatedropdown={midSectionDropdownSelectionHandler}
//               dropdownLoading={midSectionDropdonwOptionsLoading}
//             />
//           </div>
//         </div>
//       </div>

//       <div id="device_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header ">
//             <div className="flex column align_start">
//               <div className="section_info">   <h2 className="section_card_title">
//                 Device Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//               </h2> <SectionDefinationsBadge
//                   title="Device Wise Distribution"
//                   module="campaign"
//                   platform="Dv360"
//                   section="overall"
//                 />
//               </div>
//               {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
//                 <div className="treemap_breadcrumb">
//                   <span
//                     onClick={() => {
//                       setTreemapParams({
//                         brand_id: BRAND_ID,
//                         start_date: selectedDates.startDate,
//                         end_date: selectedDates.endDate,
//                         previous_start_date: selectedDates.prevStartDate,
//                         previous_end_date: selectedDates.prevEndDate,
//                         metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//                         objective: selectedObjectives,
//                         platform_campaign: selectedIdandPlatform,
//                         platform: selectedPlatform,
//                         campaign: selectedCampaigns,
//                         campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//                         level: 1,
//                       });
//                       setTreemapDeviceType("");
//                       setTreemapOS("");
//                     }}
//                   >
//                     Home
//                   </span>
//                   {treemapDeviceType && (
//                     <span
//                       onClick={() => {
//                         setTreemapParams({
//                           brand_id: BRAND_ID,
//                           start_date: selectedDates.startDate,
//                           end_date: selectedDates.endDate,
//                           previous_start_date: selectedDates.prevStartDate,
//                           previous_end_date: selectedDates.prevEndDate,
//                           metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
//                           objective: selectedObjectives,
//                           platform_campaign: selectedIdandPlatform,
//                           platform: selectedPlatform,
//                           campaign: selectedCampaigns,
//                           campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
//                           level: 2,
//                           device_type: treemapDeviceType,
//                         });
//                         setTreemapOS("");
//                       }}
//                     >
//                       {" / "}
//                       <TextFormatter string={treemapDeviceType} />
//                     </span>
//                   )}

//                   {treemapOS && (
//                     <span>
//                       {" / "}
//                       <TextFormatter string={treemapOS} />{" "}
//                     </span>
//                   )}
//                 </div>
//               )}
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"device_wise_distribution_section"}
//                 sectionName={`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`}
//                 sectionData={deviceWiseDistribution}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             {deviceWiseDistributionSection}
//           </div>
//         </div>
//       </div>

//       <div id="content_position_wise_distribution" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info " >
//               <h2 className="section_card_title">
//                 Content Position Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//               </h2> <SectionDefinationsBadge
//                 title={"Content Position Wise Distribution"}
//                 module="campaign"
//                 platform="Dv360"
//                 section="Overall"
//               />
//             </div>
//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"content_position_wise_distribution"}
//                 sectionName={`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`}
//                 sectionData={contentPositionDistribution}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="grid col_span_4 section_card_body">
//           <div className="grid col_span_3 section_card_chart_wrapper">
//             {contentPositionDistributionChart}
//           </div>
//           <div className="grid col_span_1 section_card_table_wrapper">
//             {contentPositionDistributionDetails}
//           </div>
//         </div>
//       </div>


//       <div id="app_url_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">  <h2 className="section_card_title">
//               App/Url Wise Distribution  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//             </h2> <SectionDefinationsBadge
//                 title="App/Url Wise Distribution"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"app_url_wise_distribution_section"}
//                 sectionName={`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`}
//                 sectionData={appUrlDistribution}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <div className="section_card_chart_wrapper">
//               {appUrlDistributionChart}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div id="app_url_wise_distribution_section_linechart" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">  <h2 className="section_card_title">
//               App/Url Wise Trend  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
//             </h2> <SectionDefinationsBadge
//                 title="App/Url Wise Trend"
//                 module="campaign"
//                 platform="Dv360"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isAppUrlTrendOpen}
//               setOpen={setIsAppUrlTrendOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"app_url_trend_2"}
//                   sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
//                   sectionData={appUrlTrend}
//                 />}
//               parentId={"app_url_wise_distribution_section_linechart"}
//               title={`App/Url Wise Trend ${selectedMidSectionDropdownValue && ": " + selectedDropdownValue}`}
//               chart={appUrlTrendChart2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsAppUrlTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"app_url_wise_distribution_section_linechart"}
//                 sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
//                 sectionData={appUrlTrend}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <div className="section_card_chart_wrapper">
//               {appUrlTrendChart}
//             </div>
//           </div>
//         </div>
//       </div>


//       <div id="audience_distri_country_city_section">
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <div className="section_info">   <h2 className="section_card_title">
//                 Audience Distribution
//               </h2> <SectionDefinationsBadge
//                   title={"Audience Distribution"}
//                   module={"campaign"}
//                   platform={"Dv360"}
//                   section={"overall"}
//                 />

//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="grid  grid_margin_bottom ">
//           <Tab
//             varient={"primary"}
//             tabData={tabData}
//             activeTab={selectedTab}
//             handleCallback={(event) => handleCallback(event)}
//           />
//         </div>

//         <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//           {selectedTab === "By Country" && (
//             <Wrapper>
//               {audienceDistributionCountry}
//             </Wrapper>
//           )}
//           {selectedTab === "By State" && (
//             <Wrapper>
//               {audienceDistributionState}
//             </Wrapper>
//           )}
//           {selectedTab === "By City" && (
//             <Wrapper>
//               {audienceDistributionCity}
//             </Wrapper>
//           )}

//         </div>
//       </div>

//       <div className="grid_margin_bottom">{campaignSummaryTable}</div>



//       <Wrapper>
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <h2 className="section_card_title">
//                 Planned Vs Delivered
//               </h2>
//               <div data-html2canvas-ignore={true}>
//                 <Dropdown
//                   ismulti={false}
//                   placeholder={"Select"}
//                   options={dropdownOptionsMarketTg}
//                   className="form_dropdown section_dropdown grid_margin_right"
//                   value={selectedMarketTgDropdownOption}
//                   selectedOptions={selectedMarketTgDropdownOption}
//                   setStatedropdown={dropdownMainSelectionHandler}

//                 />
//                 <Dropdown
//                   ismulti={false}
//                   dropdownLoading={regionDropdownOptionsIsLoading}
//                   placeholder={"Select"}
//                   options={regionDropdownOptionsOnLoad}
//                   className="form_dropdown section_dropdown"
//                   value={selectedRegionDropdownOption}
//                   selectedOptions={selectedRegionDropdownOption}
//                   setStatedropdown={dropdownRegionSelectionHandler}

//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
//           <div className="section_card_body">
//             <div id="planned_vs_delivered_metrics_section">
//               <div className="grid  section_card">
//                 <div className="section_card_header">
//                   <div className="section_info">
//                     <h2 className="section_card_title">
//                       Planned v/s Delivered Metrics Performance
//                       {selectedMarketTgDropdownOption &&
//                         " : " + selectedMarketTgDropdownOption.label}
//                       {selectedRegionDropdownOption &&
//                         " : " + selectedRegionDropdownOption.label}
//                     </h2>
//                     <SectionDefinationsBadge
//                       title={"Planned vs Delivered Metrics Performance"}
//                       module={"campaign"}
//                       platform={"Dv360"}
//                       section={"overall"}
//                     />
//                   </div>
//                   <div data-html2canvas-ignore={true}>
//                     <ExportDropdown
//                       moduleName={pageModule}
//                       subModuleName={pageSubModule}

//                       sectionId={"planned_vs_delivered_metrics_section"}
//                       sectionName={`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
//                         } ${selectedRegionDropdownOption !== undefined &&
//                           selectedRegionDropdownOption !== null &&
//                           selectedRegionDropdownOption !== ""
//                           ? `: ` + selectedRegionDropdownOption.label
//                           : ""
//                         }`}
//                       sectionData={barChartCSV}
//                     />
//                   </div>
//                 </div>
//                 <div className="grid grid_cols_1 section_card_body">
//                   <div className="section_card_chart_wrapper">
//                     {plannedVsDeliveredMetricsBarChart}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {plannedVsDeliveredTable}
//       </Wrapper>

//     </Wrapper >
//   );
// };
// export default CampaignDv360Overall;


import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Tab from "../../../../components/ui/Tab";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import Datatable from "../../../../components/ui/tables/Datatable";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import BarChart from "../../../../components/ui/charts/BarChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit, IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";
import TextFormatter from "../../../../utils/TextFormatter";
import { BlueColorStart500, COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { concateTotals, getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import PaginatedDatatable from "../../../../components/ui/tables/PaginatedDatatable";


const CampaignDv360Overall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Dv360"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
  const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
  const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
  const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

  // DEVICE DISTRIBUTION
  const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
  const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] = useState(false);
  const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] = useState(false);
  const [deviceWiseDistributionerror, setDeviceWiseDistributionError] = useState(null);

  // CONTENT POSITION WISE DISTRIBUTION
  const [contentPositionDistribution, setContentPositionDistribution] = useState([]);
  const [isContentPositionDistributionLoading, setContentPositionDistributionIsLoading] = useState(false);
  const [isContentPositionDistributionLoaded, setContentPositionDistributionIsLoaded] = useState(false);
  const [contentPositionDistributionerror, setContentPositionDistributionError] = useState(null);

  // APP URL DISTRIBUTION
  const [appUrlDistribution, setAppUrlDistribution] = useState([]);
  const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
  const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] = useState(false);
  const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] = useState(false);
  const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

  // APP URL TREND
  const [appUrlTrend, setAppUrlTrend] = useState([]);
  const [appUrlTrendLabels, setAppUrlTrendLabels] = useState([]);
  const [isAppUrlTrendLoading, setAppUrlTrendIsLoading] = useState(false);
  const [isAppUrlTrendLoaded, setAppUrlTrendIsLoaded] = useState(false);
  const [appUrlTrenderror, setAppUrlTrendError] = useState(null);

  //  STATE DATA
  const [stateData, setStateData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  const stateTableDataHeaders = [
    { name: "State", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];

  // CITY DATA
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const cityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];

  // COUNTRY DATA
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const countryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];


  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

  // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "line_item", label: "Line Item" },
    { value: "creative", label: "Creative" },
  ];
  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // CAMPAIGN SUMMARY TABLE DROPDOWN
  // CAMPAIGN SUMMARY TABLE DROPDOWN DEFAULT STATE
  const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);


  let defaultCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "campaign_name" },
    { name: "Media Type", field: "media_type" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Gmail Conversions", field: "gmail_conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
    { name: "Complete View Rate", field: "complete_view_rate" },
    { name: "CPCV", field: "cpcv" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Rich Media Engagement", field: "rich_media_engagement" },
  ]

  let haleonCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "campaign_name" },
    { name: "Media Type", field: "media_type" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Gmail Conversions", field: "gmail_conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
    { name: "Complete View Rate", field: "complete_view_rate" },
    { name: "CPCV", field: "cpcv" },
    // { name: "Frequency", field: "frequency" },
    // { name: "Reach Rate", field: "reach_rate" },
    { name: "Rich Media Engagement", field: "rich_media_engagement" },
  ]


  useEffect(() => {
    // if (companyID === HALEON_COMPANY_ID) {
    //   setCampaignSummaryTableHeaders(haleonCampaignSummaryTableHeaders)
    // } else {
    //   setCampaignSummaryTableHeaders(defaultCampaignSummaryTableHeaders)
    // }
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption])



  // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Dv360", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "line_item") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.line_item ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "creative") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.creative ?? []);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption]);


  // MID SECTION DROPDOWN
  const [midSectionDropdonwOptions, setMidSectionDropdonwOptions] = useState([])
  const [midSectionDropdonwOptionsLoading, setMidSectionDropdonwOptionsLoading] = useState(true)

  // DROPDOWN DEFAULT STATE
  const [selectedMidSectionDropdownValue, setSelectedMidSectionDropdownDropdownValue] = useState('');
  let selectedDropdownValue = selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.label

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownValue("");
    } else {
      setSelectedMidSectionDropdownDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

  };

  const fetchMidSectionDropdownOptions = async () => {
    setMidSectionDropdonwOptionsLoading(true)
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/filter/", config);

      setMidSectionDropdonwOptions(res?.data?.data ?? [])
      setSelectedMidSectionDropdownDropdownValue(res?.data?.data[0] ?? [])
      setMidSectionDropdonwOptionsLoading(false)

    } catch (error) {
      setMidSectionDropdonwOptions([])
      setMidSectionDropdonwOptionsLoading(true)

    }
  }


  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchChildDonutCampaignHandler();
    fetchCountryHandler();
    fetchStateHandler();
    fetchCityHandler();
    fetchMidSectionDropdownOptions();
    // fetchCampaignSummaryHandler();
    setChildDonutSliceToggle(null);
    setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0])
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter]);

  useEffect(() => {
    fetchLineChartCampaignObjWiseHandler();
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, childDonutSliceToggle]);


  useEffect(() => {
    fetchContentPositionDistributionHandler()
    fetchAppUrlDistributionHandler()
    fetchAppUrlTrendHandler()
  }, [BRAND_ID, , selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedMidSectionDropdownValue])

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/metric-card/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection =
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary>
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="dv360"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/dv360/overall/summary-table/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig])


  useEffect(() => {
    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/dv360/overall/summary-table/v2/", config);

        let allData = res.data.data.results;
        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})

        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler()

  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, campaignSummaryConfig])


  let campaignSummaryTable =
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
            <SectionDefinationsBadge
              title="Summary"
              module="campaign"
              platform="Dv360"
              section="overall"
            />
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <ErrorBoundary>
        <PaginatedDatatable
          tableTitle="Summary"
          tableHeader={campaignSummaryTableHeaders}
          tableData={campaignSummary}
          tableLength={campaignSummaryConfig?.page_size ?? 10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tablePageSubModule={"campaign"}
          tablePagePlatform={"Dv360"}
          tablePagePrimaryTab={"overall"}
          tableConfigs={campaignSummaryConfig}
          setTableConfigs={setCampaignSummaryConfig}
          tablePaginationData={campaignSummaryTablePageData}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"search_campaign_section"}
              sectionName={`TableDynaLabels_Summary Table _${": " + selectedCampaignSummaryDropdownOption.label}`}
              sectionData={campaignSummaryExportData}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable =
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title="Summary"
                  module="campaign"
                  platform="Dv360"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable =
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary</h2>
              <SectionDefinationsBadge
                title="Summary"
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/campaign-distribution/",
        config
      );

      setChildDonutCampaignObjective(res?.data ?? []);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (
      NoDataAvailableChecker(childDonutCampaignObjective)
    ) {
      childDonutCampaignChart = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      childDonutCampaignChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={childDonutCampaignObjective}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Clicks", "Spends"]
  }

  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

  const fetchLineChartCampaignObjWiseHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    // var objectiveConfig = (function () {
    //   if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
    //     return { "media_type": null }
    //   } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
    //     return { "media_type": selectedObjectives }
    //   } else {
    //     return { "media_type": childDonutSliceToggle }
    //   }
    // })()

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        media_type: childDonutSliceToggle
        // ...objectiveConfig
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/campaign-distribution-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.data ?? []);
      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  let lineChartCampaignSection2 = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      lineChartCampaignSection2 = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {


      if (isMultiLineCluster) {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impressions"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                showLegend={true}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impressions"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   DEVICE WISE DISTRIBUTION

  let treemapLevel = 1;
  const [treemapDeviceType, setTreemapDeviceType] = useState("");
  const [treemapOS, setTreemapOS] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    objective: selectedObjectives,
    platform_campaign: selectedIdandPlatform,
    platform: selectedPlatform,
    campaign: selectedCampaigns,
    campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === "1") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        level: 2,
        device_type: data.device_type,
      });
      setTreemapDeviceType(data.device_type);
    } else if (data.level === "2") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        level: 3,
        device_type: treemapDeviceType,
        operating_system: data.operating_system,
      });
      setTreemapOS(data.operating_system);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchDeviceWiseDistributionHandler = async () => {
    setDeviceWiseDistributionIsLoading(true);
    setDeviceWiseDistributionIsLoaded(false);
    setDeviceWiseDistributionError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/device-wise-distribution/",
        config
      );

      setDeviceWiseDistribution(res?.data ?? []);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(true);
      setDeviceWiseDistributionError(null);
    } catch (error) {
      setDeviceWiseDistribution([]);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(false);
      setDeviceWiseDistributionError(error.code);
    }
  };

  let deviceWiseDistributionSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
    if (
      NoDataAvailableChecker(deviceWiseDistribution) ||
      (deviceWiseDistribution.length === 1 &&
        deviceWiseDistribution[0].value === 0)
    ) {
      deviceWiseDistributionSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={deviceWiseDistribution}
                  levelHandler={chartlevelHandler}
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "value",
                      toolTipvalue1Label: `${selectedDropdownValue}`,

                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceWiseDistributionerror) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader chartType="treeChartType" error={deviceWiseDistributionerror} />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceWiseDistributionLoading) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchDeviceWiseDistributionHandler();
  }, [treemapParams]);


  useEffect(() => {
    var temp = {
      metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
      objective: selectedObjectives,
      platform_campaign: selectedIdandPlatform,
      platform: selectedPlatform,
      campaign: selectedCampaigns,
      campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),

    }
    setTreemapParams({ ...treemapParams, ...temp })
  }, [BRAND_ID, selectedMidSectionDropdownValue, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONTENT POSITION WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchContentPositionDistributionHandler = async () => {
    setContentPositionDistributionIsLoading(true);
    setContentPositionDistributionIsLoaded(false);
    setContentPositionDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/content-position-wise-distribution/",
        config
      );
      setContentPositionDistribution(res?.data ?? []);

      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(true);
      setContentPositionDistributionError(null);
    } catch (error) {
      setContentPositionDistribution([]);
      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(false);
      setContentPositionDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let contentPositionDistributionChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  let contentPositionDistributionDetails = (
    <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  )

  // IF DATA IS LOADED
  if (
    isContentPositionDistributionLoaded && !isContentPositionDistributionLoading) {
    if (NoDataAvailableChecker(contentPositionDistribution)) {
      contentPositionDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
      contentPositionDistributionDetails = (
        <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      )
    } else {
      contentPositionDistributionChart = (
        <Wrapper>
          <ErrorBoundary>

            <DonutPieChart
              chartId={"contentPositionDistribution"}
              chartClass={"section_card_chart"}
              chartData={contentPositionDistribution}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // const sum = contentPositionDistribution
      //   .map(obj => obj.value)
      //   .reduce((accumulator, current) => accumulator + current, 0);

      contentPositionDistributionDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {contentPositionDistribution && contentPositionDistribution.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                            {details.metric &&
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
                {/* <div className="footer">
                  <div className="title_wrapper">
                    Total {" " + selectedDropdownValue}
                  </div>
                  <div className="value_wrapper">

                    <NumberFormatter number={sum} />
                  </div>
                </div> */}
              </div>

            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (contentPositionDistributionerror) {
    contentPositionDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={contentPositionDistributionerror}
        />
      </Wrapper>
    );
    contentPositionDistributionDetails = (
      <Wrapper><ServerErrorsLoader chartType="tableChartType" error={contentPositionDistributionerror} /></Wrapper>
    )
  }

  // IF DATA LOADING
  if (isContentPositionDistributionLoading) {
    contentPositionDistributionChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
    contentPositionDistributionDetails = (
      <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
    )
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const fetchAppUrlDistributionHandler = async () => {
    setAppUrlDistributionIsLoading(true);
    setAppUrlDistributionIsLoaded(false);
    setAppUrlDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/app-url-wise-bar-chart-distribution/", config);

      setAppUrlDistribution(res?.data?.data ?? []);

      setAppUrlDistributionLabels(res?.data?.label ?? []);
      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(true);
      setAppUrlDistributionError(null);
    } catch (error) {
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(false);
      setAppUrlDistributionError(error.code);
    }
  };

  let appUrlDistributionChart = (
    <Wrapper>
      <Loader loaderType={"barChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
    if (
      NoDataAvailableChecker(appUrlDistribution) ||
      NoDataAvailableChecker(appUrlDistributionLabels)
    ) {
      appUrlDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"barChartType"} />
        </Wrapper>
      );
    } else {
      appUrlDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <BarChart
              chartId="social_twitter_profile_clickss"
              chartClass="section_card_chart"
              chartData={appUrlDistribution}
              chartLabels={appUrlDistributionLabels}
            />

          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlDistributionerror) {
    appUrlDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={"barChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlDistributionLoading) {
    appUrlDistributionChart = (
      <Wrapper>
        <Loader loaderType={"barChartLoader"} />
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const [isAppUrlTrendOpen, setIsAppUrlTrendOpen] = useState(false)

  let defaultAppUrlTrendDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultAppUrlTrendDisabledvalues = []
  } else {
    defaultAppUrlTrendDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [appUrlTrendDisabledvalues, setAppUrlTrendDisabledvalues] = useState(defaultAppUrlTrendDisabledvalues)

  const fetchAppUrlTrendHandler = async () => {
    setAppUrlTrendIsLoading(true);
    setAppUrlTrendIsLoaded(false);
    setAppUrlTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/app-url-wise-distribution/", config);

      setAppUrlTrend(res?.data?.data ?? []);
      setAppUrlTrendLabels(res?.data?.label ?? []);

      setAppUrlTrendIsLoading(false);
      setAppUrlTrendIsLoaded(true);
      setAppUrlTrendError(null);
    } catch (error) {
      setAppUrlTrend([]);
      setAppUrlTrendLabels([]);
      setAppUrlTrendIsLoading(false);
      setAppUrlTrendIsLoaded(false);
      setAppUrlTrendError(error.code);
    }
  };

  let appUrlTrendChart = (
    <Wrapper>
      <Loader loaderType={"singleLineChartLoader"} />
    </Wrapper>
  );

  let appUrlTrendChart2 = (
    <Wrapper>
      <Loader loaderType={"singleLineChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlTrendLoaded && !isAppUrlTrendLoading) {
    if (
      NoDataAvailableChecker(appUrlTrend) ||
      NoDataAvailableChecker(appUrlTrendLabels)
    ) {
      appUrlTrendChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"lineChartType"} />
        </Wrapper>
      );

      appUrlTrendChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"lineChartType"} />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        appUrlTrendChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="app_url_trend"
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartLabels={appUrlTrendLabels}
                disabledLegendArray={appUrlTrendDisabledvalues}
                setDisabledValues={setAppUrlTrendDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        appUrlTrendChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartId="app_url_trend_2"
                chartLabels={appUrlTrendLabels}
                showLegend={true}
                disabledLegendArray={appUrlTrendDisabledvalues}
                setDisabledValues={setAppUrlTrendDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        appUrlTrendChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="app_url_trend"
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartLabels={appUrlTrendLabels}
              />

            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlTrenderror) {
    appUrlTrendChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
      </Wrapper>
    );

    appUrlTrendChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlTrendLoading) {
    appUrlTrendChart = (
      <Wrapper>
        <Loader loaderType={"singleLineChartLoader"} />
      </Wrapper>
    );

    appUrlTrendChart2 = (
      <Wrapper>
        <Loader loaderType={"singleLineChartLoader"} />
      </Wrapper>
    );
  }



  const tabData = ["By Country", "By State", "By City"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Dv360",
      "Video",
      event
    );
  };


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE MAP

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/state-distribution/",
        config
      );

      setStateData(res?.data?.data ?? []);

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      setStateData([]);
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: STATE
  let audienceDistributionState = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(stateTableDataHeaders)
    ) {
      audienceDistributionState = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionState = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By State"}
              isDynamicFlagVisible={true}
              tableHeader={stateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID="audience_distribution_country_state_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_state_section"}
                  sectionName={"StateTable_Audience Distribution By State"}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/city-distribution/",
        config
      );
      setCityData(res?.data?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(cityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isDynamicFlagVisible={true}
              tableHeader={cityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
              tableID="audience_distribution_country_city_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_city_section"}
                  sectionName={"CityTable_Audience Distribution By City"}
                  sectionData={cityData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY TABLE

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/country-distribution/",
        config
      );

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(countryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isCountryFlagVisible={true}
              tableHeader={countryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="audience_distribution_country_country_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_country_section"}
                  sectionName={"CountryTable_Audience Distribution - Country"}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  var campaignObjectCSV = {
    "CampaignTypeDonut_Campaign Type Wise Donut": childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Type Wise Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    [`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`]: deviceWiseDistribution,
    [`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`]: contentPositionDistribution,
    [`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`]: appUrlDistribution,
    [`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`]: appUrlTrend,
    "CountryTable_Audience Distribution - Country": countryData,
    "StateTable_Audience Distribution - State": stateData,
    "CityTable_Audience Distribution - City": cityData,
    [`TableDynaLabels_Summary Table _${": " + selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    deviceWiseDistribution,
    contentPositionDistribution,
    appUrlDistribution,
    appUrlTrend,
    countryData,
    stateData,
    cityData,
    campaignSummary,
    campaignSummaryExportData,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (deviceWiseDistributionerror !== null ? true : isDeviceWiseDistributionLoaded && (isDeviceWiseDistributionLoading === false)) &&
      (contentPositionDistributionerror !== null ? true : isContentPositionDistributionLoaded && (isContentPositionDistributionLoading === false)) &&
      (appUrlDistributionerror !== null ? true : isAppUrlDistributionLoaded && (isAppUrlDistributionLoading === false)) &&
      (appUrlTrenderror !== null ? true : isAppUrlTrendLoaded && (isAppUrlTrendLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    deviceWiseDistributionerror, isDeviceWiseDistributionLoaded, isDeviceWiseDistributionLoading,
    contentPositionDistributionerror, isContentPositionDistributionLoaded, isContentPositionDistributionLoading,
    appUrlDistributionerror, isAppUrlDistributionLoaded, isAppUrlDistributionLoading,
    appUrlTrenderror, isAppUrlTrendLoaded, isAppUrlTrendLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div id="campaign_objective_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
              <SectionDefinationsBadge
                title={"Campaign Type Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"linechartCamapign_2"}
                  sectionName={"Campaign Type Wise Distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"campaign_objective_section"}
              title={`Campaign Type Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective_section"}
                sectionName={"Campaign Type Wise Distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI

            </h2>
            {midSectionDropdonwOptions.length !== 0 && <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownValue}
              selectedOptions={selectedMidSectionDropdownValue}
              setStatedropdown={midSectionDropdownSelectionHandler}
              dropdownLoading={midSectionDropdonwOptionsLoading}
            />}
          </div>
        </div>
      </div>

      <div id="device_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="flex column align_start">
              <div className="section_info">   <h2 className="section_card_title">
                Device Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
              </h2> <SectionDefinationsBadge
                  title="Device Wise Distribution"
                  module="campaign"
                  platform="Dv360"
                  section="overall"
                />
              </div>
              {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
                <div className="treemap_breadcrumb">
                  <span
                    onClick={() => {
                      setTreemapParams({
                        brand_id: BRAND_ID,
                        start_date: selectedDates.startDate,
                        end_date: selectedDates.endDate,
                        previous_start_date: selectedDates.prevStartDate,
                        previous_end_date: selectedDates.prevEndDate,
                        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
                        objective: selectedObjectives,
                        platform_campaign: selectedIdandPlatform,
                        platform: selectedPlatform,
                        campaign: selectedCampaigns,
                        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                        level: 1,
                      });
                      setTreemapDeviceType("");
                      setTreemapOS("");
                    }}
                  >
                    Home
                  </span>
                  {treemapDeviceType && (
                    <span
                      onClick={() => {
                        setTreemapParams({
                          brand_id: BRAND_ID,
                          start_date: selectedDates.startDate,
                          end_date: selectedDates.endDate,
                          previous_start_date: selectedDates.prevStartDate,
                          previous_end_date: selectedDates.prevEndDate,
                          metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
                          objective: selectedObjectives,
                          platform_campaign: selectedIdandPlatform,
                          platform: selectedPlatform,
                          campaign: selectedCampaigns,
                          campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                          level: 2,
                          device_type: treemapDeviceType,
                        });
                        setTreemapOS("");
                      }}
                    >
                      {" / "}
                      <TextFormatter string={treemapDeviceType} />
                    </span>
                  )}

                  {treemapOS && (
                    <span>
                      {" / "}
                      <TextFormatter string={treemapOS} />{" "}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_wise_distribution_section"}
                sectionName={`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`}
                sectionData={deviceWiseDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {deviceWiseDistributionSection}
          </div>
        </div>
      </div>

      <div id="content_position_wise_distribution" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info " >
              <h2 className="section_card_title">
                Content Position Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
              </h2> <SectionDefinationsBadge
                title={"Content Position Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="Overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"content_position_wise_distribution"}
                sectionName={`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`}
                sectionData={contentPositionDistribution}
              />
            </div>
          </div>
        </div>

        <div className="grid col_span_4 section_card_body">
          <div className="grid col_span_3 section_card_chart_wrapper">
            {contentPositionDistributionChart}
          </div>
          <div className="grid col_span_1 section_card_table_wrapper">
            {contentPositionDistributionDetails}
          </div>
        </div>
      </div>


      <div id="app_url_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Distribution  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Distribution"
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section"}
                sectionName={`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`}
                sectionData={appUrlDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div id="app_url_wise_distribution_section_linechart" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Trend  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Trend"
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <Modal
              open={isAppUrlTrendOpen}
              setOpen={setIsAppUrlTrendOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"app_url_trend_2"}
                  sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
                  sectionData={appUrlTrend}
                />}
              parentId={"app_url_wise_distribution_section_linechart"}
              title={`App/Url Wise Trend ${selectedMidSectionDropdownValue && ": " + selectedDropdownValue}`}
              chart={appUrlTrendChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsAppUrlTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section_linechart"}
                sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
                sectionData={appUrlTrend}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlTrendChart}
            </div>
          </div>
        </div>
      </div>


      <div id="audience_distri_country_city_section">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Audience Distribution
              </h2> <SectionDefinationsBadge
                  title={"Audience Distribution"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="grid  grid_margin_bottom ">
          <Tab
            varient={"primary"}
            tabData={tabData}
            activeTab={selectedTab}
            handleCallback={(event) => handleCallback(event)}
          />
        </div>

        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {selectedTab === "By Country" && (
            <Wrapper>
              {audienceDistributionCountry}
            </Wrapper>
          )}
          {selectedTab === "By State" && (
            <Wrapper>
              {audienceDistributionState}
            </Wrapper>
          )}
          {selectedTab === "By City" && (
            <Wrapper>
              {audienceDistributionCity}
            </Wrapper>
          )}

        </div>
      </div>

      {campaignSummaryTable}
    </Wrapper >
  );
};

export const CampaignDv360OverallCoke = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Dv360"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }
  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
  const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
  const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
  const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

  // DEVICE DISTRIBUTION
  const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
  const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] = useState(false);
  const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] = useState(false);
  const [deviceWiseDistributionerror, setDeviceWiseDistributionError] = useState(null);

  // CONTENT POSITION WISE DISTRIBUTION
  const [contentPositionDistribution, setContentPositionDistribution] = useState([]);
  const [isContentPositionDistributionLoading, setContentPositionDistributionIsLoading] = useState(false);
  const [isContentPositionDistributionLoaded, setContentPositionDistributionIsLoaded] = useState(false);
  const [contentPositionDistributionerror, setContentPositionDistributionError] = useState(null);

  // APP URL DISTRIBUTION
  const [appUrlDistribution, setAppUrlDistribution] = useState([]);
  const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
  const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] = useState(false);
  const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] = useState(false);
  const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

  // APP URL TREND
  const [appUrlTrend, setAppUrlTrend] = useState([]);
  const [appUrlTrendLabels, setAppUrlTrendLabels] = useState([]);
  const [isAppUrlTrendLoading, setAppUrlTrendIsLoading] = useState(false);
  const [isAppUrlTrendLoaded, setAppUrlTrendIsLoaded] = useState(false);
  const [appUrlTrenderror, setAppUrlTrendError] = useState(null);

  //  STATE DATA
  const [stateData, setStateData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  const stateTableDataHeaders = [
    { name: "State", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];

  // CITY DATA
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const cityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];

  // COUNTRY DATA
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const countryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Spends", field: "spends" },
    { name: "Views", field: "views" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
  ];


  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

  // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "line_item", label: "Line Item" },
    { value: "creative", label: "Creative" },
  ];
  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // CAMPAIGN SUMMARY TABLE DROPDOWN
  // CAMPAIGN SUMMARY TABLE DROPDOWN DEFAULT STATE
  const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

  // let campaignSummaryTableHeaders = [];

  let defaultCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "campaign_name" },
    { name: "Media Type", field: "media_type" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Gmail Conversions", field: "gmail_conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
    { name: "Complete View Rate", field: "complete_view_rate" },
    { name: "CPCV", field: "cpcv" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Rich Media Engagement", field: "rich_media_engagement" },
  ]

  let haleonCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label} Name`, field: "campaign_name" },
    { name: "Media Type", field: "media_type" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Total Conversions", field: "total_conversions" },
    { name: "Gmail Conversions", field: "gmail_conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Complete Views (Video)", field: "complete_views_video" },
    { name: "Complete View Rate", field: "complete_view_rate" },
    { name: "CPCV", field: "cpcv" },
    // { name: "Frequency", field: "frequency" },
    // { name: "Reach Rate", field: "reach_rate" },
    { name: "Rich Media Engagement", field: "rich_media_engagement" },
  ]


  useEffect(() => {
    // if (companyID === HALEON_COMPANY_ID) {
    //   setCampaignSummaryTableHeaders(haleonCampaignSummaryTableHeaders)
    // } else {
    //   setCampaignSummaryTableHeaders(defaultCampaignSummaryTableHeaders)
    // }
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption])

  // CAMPAIGN SUMMARY TABLE DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Dv360", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "line_item") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.line_item ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "creative") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.creative ?? []);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption]);


  // MID SECTION DROPDOWN
  const [midSectionDropdonwOptions, setMidSectionDropdonwOptions] = useState([])
  const [midSectionDropdonwOptionsLoading, setMidSectionDropdonwOptionsLoading] = useState(true)

  // DROPDOWN DEFAULT STATE
  const [selectedMidSectionDropdownValue, setSelectedMidSectionDropdownDropdownValue] = useState('');
  let selectedDropdownValue = selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.label

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownValue("");
    } else {
      setSelectedMidSectionDropdownDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

  };

  const fetchMidSectionDropdownOptions = async () => {
    setMidSectionDropdonwOptionsLoading(true)
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/filter/", config);

      setMidSectionDropdonwOptions(res?.data?.data ?? [])
      setSelectedMidSectionDropdownDropdownValue(res?.data?.data[0] ?? [])
      setMidSectionDropdonwOptionsLoading(false)

    } catch (error) {
      setMidSectionDropdonwOptions([])
      setMidSectionDropdonwOptionsLoading(true)

    }
  }


  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchChildDonutCampaignHandler();
    fetchCountryHandler();
    fetchStateHandler();
    fetchCityHandler();
    fetchMidSectionDropdownOptions();
    // fetchCampaignSummaryHandler();
    setChildDonutSliceToggle(null);
    setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0])
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter]);

  useEffect(() => {
    fetchLineChartCampaignObjWiseHandler();
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, childDonutSliceToggle]);


  useEffect(() => {
    fetchContentPositionDistributionHandler()
    fetchAppUrlDistributionHandler()
    fetchAppUrlTrendHandler()
  }, [BRAND_ID, , selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, selectedMidSectionDropdownValue])



  // PLANNED VS DELIVERED TABLE

  /*   const plannedVsDeliveredTableHeaders = [
      { name: "Campaign Name", field: "campaign_names" },
      { name: "Start date", field: "start_date" },
      { name: "End date", field: "end_date" },
  
      { name: "Market/TG", field: "type" },
      { name: "Markets(State)", field: "region" }, 
  
      { name: "Planned Reach", field: "planned_reach" },
      { name: "Delivered Reach", field: "delivered_reach" },
      { name: "% achieved", field: "achieved_reach" },
  
      { name: "Planned Impressions", field: "planned_impressions" },
      { name: "Delivered Impressions", field: "delivered_impressions" },
      { name: "% achieved", field: "achieved_impressions" },
  
      { name: "Planned Frequency", field: "planned_frequency" },
      { name: "Delivered Frequency", field: "delivered_frequency" },
      { name: "% achieved", field: "achieved_frequency" },
  
      { name: "Planned CTR %", field: "planned_ctr" },
      { name: "Delivered CTR %", field: "delivered_ctr" },
      { name: "% achieved", field: "achieved_ctr" },
  
      { name: "Planned VTR %", field: "planned_vtr" },
      { name: "Delivered VTR %", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_vtr" },
  
      { name: "Planned Spends", field: "planned_spends" },
      { name: "Delivered Spends", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_spend" },
    ];
   */




  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabels,
    setPlannedVsDeliveredMetricsDataLabels,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoading,
    setPlannedVsDeliveredMetricsIsLoading,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoaded,
    setPlannedVsDeliveredMetricsIsLoaded,
  ] = useState(false);
  const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
    useState(null);

  const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] = useState([]);
  const [plannedVsDeliveredTableDataTotal, setPlannedVsDeliveredTableDataTotal] = useState([]);
  const [plannedVsDeliveredTableDataHeaders, setPlannedVsDeliveredTableDataHaeders] = useState([]);
  const [isPlannedVsDeliveredTableDataLoading, setPlannedVsDeliveredTableDataIsLoading] = useState(false);
  const [isPlannedVsDeliveredTableDataLoaded, setPlannedVsDeliveredTableDataIsLoaded] = useState(false);
  const [plannedVsDeliveredTableDataerror, setPlannedVsDeliveredTableDataError] = useState(null);

  let dropdownOptionsMarketTg = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];

  let tgDropdownOptions = [
    { value: "18-34 male female", label: "18-34 Male / Female" },
    { value: "18-64 male female", label: "18-64 Male / Female" },

  ]

  // DROPDOWN STATE
  const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
    useState(dropdownOptionsMarketTg[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOption("");
    } else {
      setSelectedMarketTgDropdownOption(e);
    }
  };


  const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
    useState([]);
  const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
    useState(true);
  const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
    useState(false);


  const fetchRegionDropdownHandler = async () => {
    setRegionDropdownOptionsIsLoading(true);
    setRegionDropdownOptionsIsLoaded(false);

    let getDropdownType = selectedMarketTgDropdownOption.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOption.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))

      setRegionDropdownOptions(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoad(regionsDDOpt ?? []);
      setSelectedRegionDropdownOption(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(true);

    } catch (error) {
      setRegionDropdownOptions([]);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(false);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);

      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  useEffect(() => {
    fetchRegionDropdownHandler();
  }, [
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
    selectedMarketTgDropdownOption]);

  // DROPDOWN STATE
  const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandler = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOption("");
    } else {
      setSelectedRegionDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandler = () => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoad([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandler();
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform,
  //   selectedMarketTgDropdownOption
  // ]);

  // useEffect(() => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   }
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);

  useEffect(() => {
    if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
      fetchPlannedVsDeliveredMetricsHandler();
      fetchPlannedVsDeliveredTableDataHandler();
    }
  }, [
    regionDropdownOptionsIsLoaded,
    regionDropdownOptionsIsLoading,
    selectedRegionDropdownOption
  ]);

  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);

  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform
  // ]);


  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   if (selectedMarketTgDropdownOption.value === "market" && selectedRegionDropdownOption.value === "India") {
  //     fetchPlannedVsDeliveredMetricsHandler();
  //     fetchPlannedVsDeliveredTableDataHandler();
  //   }
  // }, [selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform]);





  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/metric-card/", config);

      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection =
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary>
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="dv360"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/dv360/overall/summary-table/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])


  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/dv360/overall/summary-table/v2/", config);

        let allData = res.data.data.results;
        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})
        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler()
  }, [BRAND_ID, selectedDates, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedBucketsFromFilter, campaignSummaryConfig])



  let campaignSummaryTable =
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
            <SectionDefinationsBadge
              title="Summary"
              module="campaign"
              platform="Dv360"
              section="overall"
            />
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <ErrorBoundary>
        <Datatable
          tableTitle="Summary"
          tableHeader={campaignSummaryTableHeaders}
          tableData={campaignSummary}
          tableLength={campaignSummaryConfig?.page_size ?? 10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tablePageSubModule={"campaign"}
          tablePagePlatform={"Dv360"}
          tablePagePrimaryTab={"overall"}
          tableConfigs={campaignSummaryConfig}
          setTableConfigs={setCampaignSummaryConfig}
          tablePaginationData={campaignSummaryTablePageData}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"search_campaign_section"}
              sectionName={`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`}
              sectionData={campaignSummaryExportData}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable =
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title="Summary"
                  module="campaign"
                  platform="Dv360"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable =
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title="Summary"
                  module="campaign"
                  platform="Dv360"
                  section="overall"
                />
              </h2>

            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/campaign-distribution/",
        config
      );

      setChildDonutCampaignObjective(res?.data ?? []);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (
      NoDataAvailableChecker(childDonutCampaignObjective)
    ) {
      childDonutCampaignChart = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      childDonutCampaignChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={childDonutCampaignObjective}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Clicks", "Spends"]
  }

  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

  const fetchLineChartCampaignObjWiseHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    // var objectiveConfig = (function () {
    //   if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
    //     return { "media_type": null }
    //   } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
    //     return { "media_type": selectedObjectives }
    //   } else {
    //     return { "media_type": childDonutSliceToggle }
    //   }
    // })()

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        media_type: childDonutSliceToggle
        // ...objectiveConfig
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/campaign-distribution-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.data ?? []);
      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  let lineChartCampaignSection2 = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      lineChartCampaignSection2 = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {


      if (isMultiLineCluster) {

        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impressions"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                showLegend={true}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="linechartCamapign"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impressions"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   DEVICE WISE DISTRIBUTION

  let treemapLevel = 1;
  const [treemapDeviceType, setTreemapDeviceType] = useState("");
  const [treemapOS, setTreemapOS] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    objective: selectedObjectives,
    platform_campaign: selectedIdandPlatform,
    platform: selectedPlatform,
    campaign: selectedCampaigns,
    campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === "1") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        level: 2,
        device_type: data.device_type,
      });
      setTreemapDeviceType(data.device_type);
    } else if (data.level === "2") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        level: 3,
        device_type: treemapDeviceType,
        operating_system: data.operating_system,
      });
      setTreemapOS(data.operating_system);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchDeviceWiseDistributionHandler = async () => {
    setDeviceWiseDistributionIsLoading(true);
    setDeviceWiseDistributionIsLoaded(false);
    setDeviceWiseDistributionError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/device-wise-distribution/",
        config
      );

      setDeviceWiseDistribution(res?.data ?? []);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(true);
      setDeviceWiseDistributionError(null);
    } catch (error) {
      setDeviceWiseDistribution([]);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(false);
      setDeviceWiseDistributionError(error.code);
    }
  };

  let deviceWiseDistributionSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
    if (
      NoDataAvailableChecker(deviceWiseDistribution) || (deviceWiseDistribution.length === 1 && deviceWiseDistribution[0].value === 0)
    ) {
      deviceWiseDistributionSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={deviceWiseDistribution}
                  levelHandler={chartlevelHandler}
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "value",
                      toolTipvalue1Label: `${selectedDropdownValue}`,

                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceWiseDistributionerror) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader chartType="treeChartType" error={deviceWiseDistributionerror} />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceWiseDistributionLoading) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchDeviceWiseDistributionHandler();
  }, [treemapParams]);


  useEffect(() => {
    var temp = {
      metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
      objective: selectedObjectives,
      platform_campaign: selectedIdandPlatform,
      platform: selectedPlatform,
      campaign: selectedCampaigns,
      campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),

    }
    setTreemapParams({ ...treemapParams, ...temp })
  }, [BRAND_ID, selectedMidSectionDropdownValue, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONTENT POSITION WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchContentPositionDistributionHandler = async () => {
    setContentPositionDistributionIsLoading(true);
    setContentPositionDistributionIsLoaded(false);
    setContentPositionDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/content-position-wise-distribution/",
        config
      );

      setContentPositionDistribution(res?.data ?? []);
      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(true);
      setContentPositionDistributionError(null);
    } catch (error) {
      setContentPositionDistribution([]);
      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(false);
      setContentPositionDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let contentPositionDistributionChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  let contentPositionDistributionDetails = (
    <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  )

  // IF DATA IS LOADED
  if (isContentPositionDistributionLoaded && !isContentPositionDistributionLoading) {
    if (NoDataAvailableChecker(contentPositionDistribution)) {
      contentPositionDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
      contentPositionDistributionDetails = (
        <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      )
    } else {
      contentPositionDistributionChart = (
        <Wrapper>
          <ErrorBoundary>
            <DonutPieChart
              chartId={"contentPositionDistribution"}
              chartClass={"section_card_chart"}
              chartData={contentPositionDistribution}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // let sum = 0
      // if (NoDataAvailableChecker(contentPositionDistribution)) {
      //   sum = "undefined"
      // } else {
      //   sum = contentPositionDistribution.map(obj => obj.value).reduce((accumulator, current) => accumulator + current, 0);
      // }

      // if (sum === "undefined") {
      //   contentPositionDistributionDetails = (
      //     <Wrapper>
      //       <NoDataAvailableLoader chartType="tableChartType" />
      //     </Wrapper>
      //   );
      // } else {
      contentPositionDistributionDetails = (
        <Wrapper>
          <ErrorBoundary>
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {contentPositionDistribution.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                            {details.metric &&
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Wrapper>
                    ))
                  }
                </div>
                {/* <div className="footer">
                    <div className="title_wrapper">
                      Total {" " + selectedDropdownValue}
                    </div>
                    <div className="value_wrapper">
                      <NumberFormatter number={sum} />
                    </div>
                  </div> */}
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      )
      // }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (contentPositionDistributionerror) {
    contentPositionDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={contentPositionDistributionerror}
        />
      </Wrapper>
    );
    contentPositionDistributionDetails = (
      <Wrapper><ServerErrorsLoader chartType="tableChartType" error={contentPositionDistributionerror} /></Wrapper>
    )
  }

  // IF DATA LOADING
  if (isContentPositionDistributionLoading) {
    contentPositionDistributionChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
    contentPositionDistributionDetails = (
      <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
    )
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const fetchAppUrlDistributionHandler = async () => {
    setAppUrlDistributionIsLoading(true);
    setAppUrlDistributionIsLoaded(false);
    setAppUrlDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/app-url-wise-bar-chart-distribution/", config);

      setAppUrlDistribution(res?.data?.data ?? []);
      setAppUrlDistributionLabels(res?.data?.label ?? []);

      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(true);
      setAppUrlDistributionError(null);
    } catch (error) {
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(false);
      setAppUrlDistributionError(error.code);
    }
  };

  let appUrlDistributionChart = (
    <Wrapper>
      <Loader loaderType={"barChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
    if (
      NoDataAvailableChecker(appUrlDistribution) ||
      NoDataAvailableChecker(appUrlDistributionLabels)
    ) {
      appUrlDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"barChartType"} />
        </Wrapper>
      );
    } else {
      appUrlDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="social_twitter_profile_clickss"
              chartClass="section_card_chart"
              chartData={appUrlDistribution}
              chartLabels={appUrlDistributionLabels}
            />

          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlDistributionerror) {
    appUrlDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={"barChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlDistributionLoading) {
    appUrlDistributionChart = (
      <Wrapper>
        <Loader loaderType={"barChartLoader"} />
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const [isAppUrlTrendOpen, setIsAppUrlTrendOpen] = useState(false)

  let defaultAppUrlTrendDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultAppUrlTrendDisabledvalues = []
  } else {
    defaultAppUrlTrendDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [appUrlTrendDisabledvalues, setAppUrlTrendDisabledvalues] = useState(defaultAppUrlTrendDisabledvalues)


  const fetchAppUrlTrendHandler = async () => {
    setAppUrlTrendIsLoading(true);
    setAppUrlTrendIsLoaded(false);
    setAppUrlTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/overall/app-url-wise-distribution/", config);

      setAppUrlTrend(res?.data?.data ?? []);
      setAppUrlTrendLabels(res?.data?.label ?? []);

      setAppUrlTrendIsLoading(false);
      setAppUrlTrendIsLoaded(true);
      setAppUrlTrendError(null);
    } catch (error) {
      setAppUrlTrend([]);
      setAppUrlTrendLabels([]);
      setAppUrlTrendIsLoading(false);
      setAppUrlTrendIsLoaded(false);
      setAppUrlTrendError(error.code);
    }
  };

  let appUrlTrendChart = (
    <Wrapper>
      <Loader loaderType={"singleLineChartLoader"} />
    </Wrapper>
  );

  let appUrlTrendChart2 = (
    <Wrapper>
      <Loader loaderType={"singleLineChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlTrendLoaded && !isAppUrlTrendLoading) {
    if (
      NoDataAvailableChecker(appUrlTrend) ||
      NoDataAvailableChecker(appUrlTrendLabels)
    ) {
      appUrlTrendChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"lineChartType"} />
        </Wrapper>
      );

      appUrlTrendChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType={"lineChartType"} />
        </Wrapper>
      );
    } else {


      if (isMultiLineCluster) {
        appUrlTrendChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="app_url_trend"
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartLabels={appUrlTrendLabels}
                disabledLegendArray={appUrlTrendDisabledvalues}
                setDisabledValues={setAppUrlTrendDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        appUrlTrendChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartId="app_url_trend_2"
                chartLabels={appUrlTrendLabels}
                showLegend={true}
                disabledLegendArray={appUrlTrendDisabledvalues}
                setDisabledValues={setAppUrlTrendDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        appUrlTrendChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="app_url_trend"
                chartClass="section_card_chart"
                chartData={appUrlTrend}
                chartLabels={appUrlTrendLabels}
              />

            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlTrenderror) {
    appUrlTrendChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
      </Wrapper>
    );

    appUrlTrendChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType={"lineChartType"} error={appUrlTrenderror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlTrendLoading) {
    appUrlTrendChart = (
      <Wrapper>
        <Loader loaderType={"singleLineChartLoader"} />
      </Wrapper>
    );

    appUrlTrendChart2 = (
      <Wrapper>
        <Loader loaderType={"singleLineChartLoader"} />
      </Wrapper>
    );
  }



  const tabData = ["By Country", "By State", "By City"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Dv360",
      "Video",
      event
    );
  };


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE MAP

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/state-distribution/",
        config
      );

      setStateData(res?.data?.data ?? []);

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      setStateData([]);
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: STATE
  let audienceDistributionState = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(stateTableDataHeaders)
    ) {
      audienceDistributionState = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionState = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By State"}
              isDynamicFlagVisible={true}
              tableHeader={stateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID="audience_distribution_country_state_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_state_section"}
                  sectionName={"StateTable_Audience Distribution By State"}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/city-distribution/",
        config
      );
      setCityData(res?.data?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(cityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isDynamicFlagVisible={true}
              tableHeader={cityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
              tableID="audience_distribution_country_city_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_city_section"}
                  sectionName={"CityTable_Audience Distribution By City"}
                  sectionData={cityData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY TABLE

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/overall/country-distribution/",
        config
      );

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(countryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isCountryFlagVisible={true}
              tableHeader={countryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="audience_distribution_country_country_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_country_section"}
                  sectionName={"CountryTable_Audience Distribution - Country"}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandler = async () => {
    setPlannedVsDeliveredMetricsIsLoading(true);
    setPlannedVsDeliveredMetricsIsLoaded(false);
    setPlannedVsDeliveredMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-chart/v3/dv360",
        config
      );

      setPlannedVsDeliveredMetricsData(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabels(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(true);
      setPlannedVsDeliveredMetricsError(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsData([]);
      setPlannedVsDeliveredMetricsDataLabels([]);
      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoaded &&
    !isplannedVsDeliveredMetricsLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
    ) {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsData}
              chartLabels={plannedVsDeliveredMetricsDataLabels}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsError) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoading) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandler = async () => {
    setPlannedVsDeliveredTableDataIsLoading(true);
    setPlannedVsDeliveredTableDataIsLoaded(false);
    setPlannedVsDeliveredTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };


    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/dv360",
        config
      );
      setPlannedVsDeliveredTableData(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaeders(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotal(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(true);
      setPlannedVsDeliveredTableDataError(null);
    } catch (error) {
      setPlannedVsDeliveredTableData([]);
      setPlannedVsDeliveredTableDataHaeders([]);
      setPlannedVsDeliveredTableDataTotal([])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  let plannedVsDeliveredTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}

              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>

          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoaded &&
    !isPlannedVsDeliveredTableDataLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableData) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
    ) {
      plannedVsDeliveredTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}

                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOption &&
                " : " + selectedMarketTgDropdownOption.label
                }
            ${selectedRegionDropdownOption &&
                " : " + selectedRegionDropdownOption.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeaders}
              tableData={plannedVsDeliveredTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotal}
              showFixedSpend={true}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOption !== undefined &&
                      selectedRegionDropdownOption !== null &&
                      selectedRegionDropdownOption !== ""
                      ? `: ` + selectedRegionDropdownOption.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerror) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoading) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }





  var campaignObjectCSV = {
    "CampaignTypeDonut_Campaign Type Wise Donut": childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Type Wise Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
  };

  var barChartCSV = {
    [`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: plannedVsDeliveredMetricsData,
  };

  var tableCSV = {
    [`TableDynaLabels_PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal),
  };


  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    [`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`]: deviceWiseDistribution,
    [`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`]: contentPositionDistribution,
    [`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`]: appUrlDistribution,
    [`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`]: appUrlTrend,
    "CountryTable_Audience Distribution - Country": countryData,
    "StateTable_Audience Distribution - State": stateData,
    "CityTable_Audience Distribution - City": cityData,
    [`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
    ...barChartCSV,
    ...tableCSV
  };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    deviceWiseDistribution,
    contentPositionDistribution,
    appUrlDistribution,
    appUrlTrend,
    countryData,
    stateData,
    cityData,
    campaignSummary,
    plannedVsDeliveredMetricsData,
    plannedVsDeliveredTableData,
    campaignSummaryExportData,
    plannedVsDeliveredTableDataTotal
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (deviceWiseDistributionerror !== null ? true : isDeviceWiseDistributionLoaded && (isDeviceWiseDistributionLoading === false)) &&
      (contentPositionDistributionerror !== null ? true : isContentPositionDistributionLoaded && (isContentPositionDistributionLoading === false)) &&
      (appUrlDistributionerror !== null ? true : isAppUrlDistributionLoaded && (isAppUrlDistributionLoading === false)) &&
      (appUrlTrenderror !== null ? true : isAppUrlTrendLoaded && (isAppUrlTrendLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
      (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
      (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    deviceWiseDistributionerror, isDeviceWiseDistributionLoaded, isDeviceWiseDistributionLoading,
    contentPositionDistributionerror, isContentPositionDistributionLoaded, isContentPositionDistributionLoading,
    appUrlDistributionerror, isAppUrlDistributionLoaded, isAppUrlDistributionLoading,
    appUrlTrenderror, isAppUrlTrendLoaded, isAppUrlTrendLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div id="campaign_objective_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
              <SectionDefinationsBadge
                title={"Campaign Type Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"linechartCamapign_2"}
                  sectionName={"Campaign Type Wise Distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"campaign_objective_section"}
              title={`Campaign Type Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective_section"}
                sectionName={"Campaign Type Wise Distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI
            </h2>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownValue}
              selectedOptions={selectedMidSectionDropdownValue}
              setStatedropdown={midSectionDropdownSelectionHandler}
              dropdownLoading={midSectionDropdonwOptionsLoading}
            />
          </div>
        </div>
      </div>

      <div id="device_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="flex column align_start">
              <div className="section_info">   <h2 className="section_card_title">
                Device Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
              </h2> <SectionDefinationsBadge
                  title="Device Wise Distribution"
                  module="campaign"
                  platform="Dv360"
                  section="overall"
                />
              </div>
              {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
                <div className="treemap_breadcrumb">
                  <span
                    onClick={() => {
                      setTreemapParams({
                        brand_id: BRAND_ID,
                        start_date: selectedDates.startDate,
                        end_date: selectedDates.endDate,
                        previous_start_date: selectedDates.prevStartDate,
                        previous_end_date: selectedDates.prevEndDate,
                        metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
                        objective: selectedObjectives,
                        platform_campaign: selectedIdandPlatform,
                        platform: selectedPlatform,
                        campaign: selectedCampaigns,
                        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                        level: 1,
                      });
                      setTreemapDeviceType("");
                      setTreemapOS("");
                    }}
                  >
                    Home
                  </span>
                  {treemapDeviceType && (
                    <span
                      onClick={() => {
                        setTreemapParams({
                          brand_id: BRAND_ID,
                          start_date: selectedDates.startDate,
                          end_date: selectedDates.endDate,
                          previous_start_date: selectedDates.prevStartDate,
                          previous_end_date: selectedDates.prevEndDate,
                          metric_type: selectedMidSectionDropdownValue === undefined || selectedMidSectionDropdownValue === null || selectedMidSectionDropdownValue === "" ? "" : selectedMidSectionDropdownValue.value,
                          objective: selectedObjectives,
                          platform_campaign: selectedIdandPlatform,
                          platform: selectedPlatform,
                          campaign: selectedCampaigns,
                          campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                          level: 2,
                          device_type: treemapDeviceType,
                        });
                        setTreemapOS("");
                      }}
                    >
                      {" / "}
                      <TextFormatter string={treemapDeviceType} />
                    </span>
                  )}

                  {treemapOS && (
                    <span>
                      {" / "}
                      <TextFormatter string={treemapOS} />{" "}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_wise_distribution_section"}
                sectionName={`DvOverallTreemap_Device Wise Distribution_${selectedDropdownValue}`}
                sectionData={deviceWiseDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {deviceWiseDistributionSection}
          </div>
        </div>
      </div>

      <div id="content_position_wise_distribution" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info " >
              <h2 className="section_card_title">
                Content Position Wise Distribution {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
              </h2> <SectionDefinationsBadge
                title={"Content Position Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="Overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"content_position_wise_distribution"}
                sectionName={`DvOverallContentPos_Content Position Wise Dist_${selectedDropdownValue}`}
                sectionData={contentPositionDistribution}
              />
            </div>
          </div>
        </div>

        <div className="grid col_span_4 section_card_body">
          <div className="grid col_span_3 section_card_chart_wrapper">
            {contentPositionDistributionChart}
          </div>
          <div className="grid col_span_1 section_card_table_wrapper">
            {contentPositionDistributionDetails}
          </div>
        </div>
      </div>


      <div id="app_url_wise_distribution_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Distribution  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Distribution"
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section"}
                sectionName={`DvOverallAppUrl_App - Url Wise Dist_${selectedDropdownValue}`}
                sectionData={appUrlDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div id="app_url_wise_distribution_section_linechart" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Trend  {selectedMidSectionDropdownValue && ": " + selectedDropdownValue}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Trend"
                module="campaign"
                platform="Dv360"
                section="overall"
              />
            </div>
            <Modal
              open={isAppUrlTrendOpen}
              setOpen={setIsAppUrlTrendOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"app_url_trend_2"}
                  sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
                  sectionData={appUrlTrend}
                />}
              parentId={"app_url_wise_distribution_section_linechart"}
              title={`App/Url Wise Trend ${selectedMidSectionDropdownValue && ": " + selectedDropdownValue}`}
              chart={appUrlTrendChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsAppUrlTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section_linechart"}
                sectionName={`DateFirstActiveDropdownLabels_App - Url Wise Trend_${selectedDropdownValue}`}
                sectionData={appUrlTrend}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlTrendChart}
            </div>
          </div>
        </div>
      </div>


      <div id="audience_distri_country_city_section">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Audience Distribution
              </h2> <SectionDefinationsBadge
                  title={"Audience Distribution"}
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"overall"}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="grid  grid_margin_bottom ">
          <Tab
            varient={"primary"}
            tabData={tabData}
            activeTab={selectedTab}
            handleCallback={(event) => handleCallback(event)}
          />
        </div>

        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {selectedTab === "By Country" && (
            <Wrapper>
              {audienceDistributionCountry}
            </Wrapper>
          )}
          {selectedTab === "By State" && (
            <Wrapper>
              {audienceDistributionState}
            </Wrapper>
          )}
          {selectedTab === "By City" && (
            <Wrapper>
              {audienceDistributionCity}
            </Wrapper>
          )}

        </div>
      </div>

      <div className="grid_margin_bottom">{campaignSummaryTable}</div>



      <Wrapper>
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Planned Vs Delivered
              </h2>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={dropdownOptionsMarketTg}
                  className="form_dropdown section_dropdown grid_margin_right"
                  value={selectedMarketTgDropdownOption}
                  selectedOptions={selectedMarketTgDropdownOption}
                  setStatedropdown={dropdownMainSelectionHandler}

                />
                <Dropdown
                  ismulti={false}
                  dropdownLoading={regionDropdownOptionsIsLoading}
                  placeholder={"Select"}
                  options={regionDropdownOptionsOnLoad}
                  className="form_dropdown section_dropdown"
                  value={selectedRegionDropdownOption}
                  selectedOptions={selectedRegionDropdownOption}
                  setStatedropdown={dropdownRegionSelectionHandler}

                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
          <div className="section_card_body">
            <div id="planned_vs_delivered_metrics_section">
              <div className="grid  section_card">
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Planned v/s Delivered Metrics Performance
                      {selectedMarketTgDropdownOption &&
                        " : " + selectedMarketTgDropdownOption.label}
                      {selectedRegionDropdownOption &&
                        " : " + selectedRegionDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Planned vs Delivered Metrics Performance"}
                      module={"campaign"}
                      platform={"Dv360"}
                      section={"overall"}
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}

                      sectionId={"planned_vs_delivered_metrics_section"}
                      sectionName={`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
                        } ${selectedRegionDropdownOption !== undefined &&
                          selectedRegionDropdownOption !== null &&
                          selectedRegionDropdownOption !== ""
                          ? `: ` + selectedRegionDropdownOption.label
                          : ""
                        }`}
                      sectionData={barChartCSV}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {plannedVsDeliveredMetricsBarChart}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {plannedVsDeliveredTable}
      </Wrapper>

    </Wrapper >
  );
};
export default CampaignDv360Overall;
