import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import Wrapper from "../helpers/Wrapper";


const Tab = ({ varient, activeTab, tabData, handleCallback, icon }) => {
    const [selectedTab, setSelectedTab] = useState(activeTab || "");

    const activeTabHandler = (tab) => {
        if (selectedTab !== tab) {
            setSelectedTab(tab);
        }
        handleCallback(tab)
    };
    useEffect(() => {
        activeTabHandler(activeTab)
    }, [activeTab]);

    // TO SET THE TAB VARIENT
    let tabClass = ""
    if (varient === "primary") {
        tabClass = "primary_navigation"
    } else if (varient === "secondary") {
        tabClass = "secondary_navigation"
    } else {
        tabClass = "primary_navigation"
    }

    return (
        <Wrapper>
            {/* <div className="grid grid_margin_bottom"> */}
            <div className="tab">
                <ul className={tabClass}>
                    {tabData.map((tab, tabIndex) => {
                        return (
                            <li
                                key={tabIndex}
                                onClick={() => activeTabHandler(tab)}
                                className={
                                    selectedTab === tab ? "tab_item active" : "tab_item"
                                }
                            >
                                <div>{icon ? icon[tabIndex] ? icon[tabIndex] : '' : ''}</div>
                                <div> {tab}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {/* </div> */}
        </Wrapper>
    );
};

Tab.propTypes = {
    varient: PropTypes.string,
    activeTab: PropTypes.string,
    tabData: PropTypes.array,
    handleCallback: PropTypes.func,
}

export default Tab;
