import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class MultipartGaugeChart extends Component {
  componentDidMount() {
    this.createChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps['graph-data'] !== this.props['graph-data']) {
      this.updateChartData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = () => {
    let { chart_id, 'graph-data': data } = this.props;

    // Create chart instance
    this.chart = am4core.create(chart_id, am4charts.GaugeChart);
    this.chart.exporting.menu = new am4core.ExportMenu();
    this.chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    this.chart.startAngle = 0;
    this.chart.endAngle = 360;
    this.chart.fontSize = 11;

    this.axis1 = this.createAxis(0, 100, -85, -5, "#5a75f9");
    this.axis2 = this.createAxis(0, 100, 5, 85, "#ff9931");
    this.axis3 = this.createAxis(0, 100, 95, 175, "#07c180");
    this.axis4 = this.createAxis(0, 100, 185, 265, "#11a1fd");

    this.hand1 = this.createHand(this.axis1);
    this.hand2 = this.createHand(this.axis2);
    this.hand3 = this.createHand(this.axis3);
    this.hand4 = this.createHand(this.axis4);

    this.updateChartData();
  }

  createAxis = (min, max, start, end, color) => {
    let axis = this.chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = min;
    axis.max = max;
    axis.strictMinMax = true;
    axis.renderer.useChartAngles = false;
    axis.renderer.startAngle = start;
    axis.renderer.endAngle = end;
    axis.renderer.minGridDistance = 100;

    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.line.strokeWidth = 6;
    axis.renderer.line.stroke = am4core.color(color);

    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.stroke = am4core.color(color);
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.ticks.template.length = 10;

    return axis;
  }

  createHand = (axis) => {
    let hand = this.chart.hands.push(new am4charts.ClockHand());
    hand.fill = axis.renderer.line.stroke;
    hand.stroke = axis.renderer.line.stroke;
    hand.axis = axis;
    hand.pin.disabled = true;
    hand.startWidth = 10;
    hand.endWidth = 0;
    hand.radius = am4core.percent(90);
    hand.innerRadius = am4core.percent(70);
    hand.value = 0;
    return hand;
  }

  updateChartData = () => {
    let { 'graph-data': data } = this.props;
    this.hand1.showValue(data[0]?.hand1 || 0, 1000, am4core.ease.cubicOut);
    this.hand2.showValue(data[1]?.hand2 || 0, 1000, am4core.ease.cubicOut);
    this.hand3.showValue(data[2]?.hand3 || 0, 1000, am4core.ease.cubicOut);
    this.hand4.showValue(data[3]?.hand4 || 0, 1000, am4core.ease.cubicOut);

    this.updateTooltip();
  }

  updateTooltip = () => {
    let tootltiphand1 = this.hand1.value ? this.hand1.value + '%' : 'n/a';
    let tootltiphand2 = this.hand2.value ? this.hand2.value + '%' : 'n/a';
    let tootltiphand3 = this.hand3.value ? this.hand3.value + '%' : 'n/a';
    let tootltiphand4 = this.hand4.value ? this.hand4.value + '%' : 'n/a';
    var tooltipHTML =
      `
      <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
        <div class="text-left d-flex flex-column justify-start" style="width: 150px; padding:10px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand1 + ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Overall</span>
            </div>
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand2 + `</span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Zone</span>
            </div>
          </div>
          <hr style="margin: 4px 0"/>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand3 + ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">City</span>
            </div>
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand4 + ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Store</span>
            </div>
          </div>
        </div>
      </div>  
      `;

    this.chart.tooltip.getFillFromObject = false;
    this.chart.tooltip.background.fill = am4core.color("#213345");
    this.chart.tooltip.background.stroke = am4core.color("#213345");
    this.chart.tooltip.background.cornerRadius = 5;
    this.chart.tooltip.label.minWidth = 150;
    this.chart.tooltip.label.padding(0, 0, 0, 0);
    this.chart.tooltip.label.fontSize = 12;
    this.chart.tooltip.position = "pointer";
    this.chart.tooltip.pointerOrientation = "vertical";
    this.chart.tooltipHTML = tooltipHTML;
    var shadow = this.chart.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");
  }

  render() {
    let { chart_class, chart_id } = this.props;

    return (
      <div>
        <div id={chart_id} className={chart_class}></div>
      </div>
    )
  }
}
