const SocialListeningIcon = (props) => {
  return (
      <svg className={props.className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 21.3V17.1C3 9.8 8.8 4 16 4C23.2 4 29 9.8 29 17.1V21.3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M24 17V27C26.8 27 29 24.8 29 22C29 19.2 26.8 17 24 17Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 27V17C5.2 17 3 19.2 3 22C3 24.8 5.2 27 8 27Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10 21H13L15 17L19 26L20 21H22" strokeWidth="1.5" />
      </svg>

  );
};

export default SocialListeningIcon;
