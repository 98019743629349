import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class Hours_Linechart extends Component {
    componentDidMount() {
        am4core.useTheme(am4themes_animated);
        let info = this.props;

        let card_id = info['card_id'];


        // Create chart instance
        var chart = am4core.create(card_id, am4charts.XYChart);

        // Add data
        chart.data = [
            { date: new Date(2018, 0, 1, 10, 0), value: 4500 },
            { date: new Date(2018, 0, 1, 11, 0), value: 2690 },
            { date: new Date(2018, 0, 1, 12, 0), value: 7000 },


        ];
        // chart.dateFormatter.inputDateFormat = "yyyyMMdd mm"
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0.5;
        dateAxis.renderer.minGridDistance = 50;
        // dateAxis.dateFormats.setKey("mm", "mm");
        dateAxis.periodChangeDateFormats.setKey("mm", "d.M.yyyy\nHH:mm");
        dateAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text + "Mins";
        });
        dateAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
        dateAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
        dateAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        dateAxis.tooltip.disabled = true;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        // dateAxis.baseUnit = "min";
        // dateAxis.gridIntervals.setAll([
        //     { timeUnit: "minutes", count: 1 },
        //     { timeUnit: "minutes", count: 60 }
        // ]);

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
        valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
        valueAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS

        chart.colors.list = [
            am4core.color("#11A1FD"),
            am4core.color("#FF9931"),
        ];

        // Create series
        function createSeries(field, name) {
            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipDateFormat = "yyyy.M.d\nHH:mm";
            series.tooltipText = "{dateX.formatDate('HH:mm')}mins: [b]{valueY}[/]";
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("#213345");
            series.tooltip.background.cornerRadius = 5;
            series.tooltip.label.padding(10, 10, 10, 10);
            series.tooltip.label.fontSize = 12;
            series.tooltip.pointerOrientation = "vertical";
            series.tooltip.label.minWidth = 150;
            series.fill = am4core.color("#11A1FD");
            series.stroke = am4core.color("#11A1FD");
            series.fillOpacity = 0.1;
            series.tensionX = 0.77;
        }

        createSeries("value", "Series #1");

        chart.cursor = new am4charts.XYCursor();
    }
    render() {

        let card_class = this.props.card_class;
        let card_id = this.props.card_id;

        return (
            <div id={card_id} className={card_class}></div>
        )
    }
}
