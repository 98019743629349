import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import NumberFormatter from "../../../../utils/NumberFormatter";
import { DEMO_US_USER_ID } from "../../../../constants/constants";


const SocialLinkedinPostOrganic = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Linkedin"
  let pagePrimaryTab = "Post"
  let pageSecondaryTab = "Organic"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled, setExportPageName } =
    useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Organic";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // IMPRESSIONS & REACH
  const [impressionsReach, setImpressionsReach] = useState([]);
  const [impressionsReachLabels, setImpressionsReachLabels] = useState([]);
  const [isimpressionsReachLoading, setImpressionsReachIsLoading] =
    useState(false);
  const [isimpressionsReachLoaded, setImpressionsReachIsLoaded] =
    useState(false);
  const [impressionsReachError, setImpressionsReachError] = useState(null);

  // OBJECT TYPE
  const [objectType, setObjectType] = useState([]);
  const [isobjectTypeLoading, setObjectTypeIsLoading] = useState(false);
  const [isobjectTypeLoaded, setObjectTypeIsLoaded] = useState(false);
  const [objectTypeError, setObjectTypeError] = useState(null);

  // INTERACTIONS
  const [interactions, setInteractions] = useState([]);
  const [isinteractionsLoading, setInteractionsIsLoading] = useState(false);
  const [isinteractionsLoaded, setInteractionsIsLoaded] = useState(false);
  const [interactionsError, setInteractionsError] = useState(null);

  // TOTAL INTERACTIONS
  const [totalInteractions, setTotalInteractions] = useState([]);
  const [totalInteractionsLabels, setTotalInteractionsLabels] = useState([]);
  const [istotalInteractionsLoading, setTotalInteractionsIsLoading] =
    useState(false);
  const [istotalInteractionsLoaded, setTotalInteractionsIsLoaded] =
    useState(false);
  const [totalInteractionsError, setTotalInteractionsError] = useState(null);

  // REACTIONS
  const [reactions, setReactions] = useState([]);
  const [isreactionsLoading, setReactionsIsLoading] = useState(false);
  const [isreactionsLoaded, setReactionsIsLoaded] = useState(false);
  const [reactionsError, setReactionsError] = useState(null);

  // COMMENTS TYPE
  const [commentsType, setCommentsType] = useState([]);
  const [commentsTypeLabels, setCommentsTypeLabels] = useState([]);
  const [iscommentsTypeLoading, setCommentsTypeIsLoading] = useState(false);
  const [iscommentsTypeLoaded, setCommentsTypeIsLoaded] = useState(false);
  const [commentsTypeError, setCommentsTypeError] = useState(null);

  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPostImpressionsHandler();
    fetchObjectTypeHandler();
    fetchInteractionsHandler();
    fetchTotalInteractionsHandler();
    userData.id !== DEMO_US_USER_ID && fetchReactionsHandler();
    userData.id !== DEMO_US_USER_ID && fetchCommentsTypeHandler();
    fetchTopPostsCompareHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/metric-card/",
        config
      );
      setMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="linkedin"
                    section="organic"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // IMPRESSIONS & REACH

  // TO FETCH DATA FROM API
  const fetchPostImpressionsHandler = async () => {
    setImpressionsReachIsLoading(true);
    setImpressionsReachIsLoaded(false);
    setImpressionsReachError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/page-view-trend/",
        config
      );
      setImpressionsReach(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setImpressionsReachLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label_list === undefined || res.data.label_list.length === 0 ? [] :
            res.data.label_list
      );
      setImpressionsReachIsLoading(false);
      setImpressionsReachIsLoaded(true);
      setImpressionsReachError(null);
    } catch (error) {
      setImpressionsReach([]);
      setImpressionsReachLabels([]);
      setImpressionsReachIsLoading(false);
      setImpressionsReachIsLoaded(false);
      setImpressionsReachError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let impressionsAndReachLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isimpressionsReachLoaded && !isimpressionsReachLoading) {
    if (
      NoDataAvailableChecker(impressionsReach) ||
      NoDataAvailableChecker(impressionsReachLabels)
    ) {
      impressionsAndReachLineChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      impressionsAndReachLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_impressions"
              chartClass="section_card_chart"
              chartData={impressionsReach}
              chartLabels={impressionsReachLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (impressionsReachError) {
    impressionsAndReachLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={impressionsReachError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isimpressionsReachLoading) {
    impressionsAndReachLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // OBJECT TYPE

  // TO FETCH DATA FROM API
  const fetchObjectTypeHandler = async () => {
    setObjectTypeIsLoading(true);
    setObjectTypeIsLoaded(false);
    setObjectTypeError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/object-type-count/",
        config
      );

      setObjectType(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metric === undefined || res.data.metric.length === 0 ? [] :
            res.data.metric
      );
      setObjectTypeIsLoading(false);
      setObjectTypeIsLoaded(true);
      setObjectTypeError(null);
    } catch (error) {
      setObjectType([]);
      setObjectTypeIsLoading(false);
      setObjectTypeIsLoaded(false);
      setObjectTypeError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let objectTypePieChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let objectTypeDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isobjectTypeLoaded && !isobjectTypeLoading) {
    if (NoDataAvailableChecker(objectType)) {
      objectTypePieChart = <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>;
      objectTypeDetails = <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      objectTypePieChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"object_type"}
              chartClass={"section_card_chart"}
              chartData={objectType}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      objectTypeDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {objectType && objectType.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (objectTypeError) {
    objectTypePieChart = (
      <Wrapper>  <ServerErrorsLoader chartType="donutChartType" error={objectTypeError} /></Wrapper>
    );
    objectTypeDetails = (
      <Wrapper> <ServerErrorsLoader chartType="tableChartType" error={objectTypeError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isobjectTypeLoading) {
    objectTypePieChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    objectTypeDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INTERACTIONS

  // TO FETCH DATA FROM API
  const fetchInteractionsHandler = async () => {
    setInteractionsIsLoading(true);
    setInteractionsIsLoaded(false);
    setInteractionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/interaction-count/",
        config
      );

      setInteractions(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metric === undefined || res.data.metric.length === 0 ? [] :
            res.data.metric
      );
      setInteractionsIsLoading(false);
      setInteractionsIsLoaded(true);
      setInteractionsError(null);
    } catch (error) {
      setInteractions([]);
      setInteractionsIsLoading(false);
      setInteractionsIsLoaded(false);
      setInteractionsError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let interactionsPieChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let interactionsDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isinteractionsLoaded && !isinteractionsLoading) {
    if (NoDataAvailableChecker(interactions)) {
      interactionsPieChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      interactionsDetails = (
        <Wrapper>  <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      interactionsPieChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"interactions"}
              chartClass={"section_card_chart"}
              chartData={interactions}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      interactionsDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {interactions && interactions.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (interactionsError) {
    interactionsPieChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={interactionsError}
          chartType="donutChartType"
        />
      </Wrapper>
    );
    interactionsDetails = (
      <Wrapper>
        <ServerErrorsLoader
          error={interactionsError}
          chartType="tableChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isinteractionsLoading) {
    interactionsPieChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    interactionsDetails = <Wrapper>  <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOTAL INTERACTIONS

  // TO FETCH DATA FROM API
  const fetchTotalInteractionsHandler = async () => {
    setTotalInteractionsIsLoading(true);
    setTotalInteractionsIsLoaded(false);
    setTotalInteractionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/interaction-line-chart/",
        config
      );

      setTotalInteractions(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setTotalInteractionsLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label_list === undefined || res.data.label_list.length === 0 ? [] :
            res.data.label_list
      );
      setTotalInteractionsIsLoading(false);
      setTotalInteractionsIsLoaded(true);
      setTotalInteractionsError(null);
    } catch (error) {
      setTotalInteractions([]);
      setTotalInteractionsLabels([]);
      setTotalInteractionsIsLoading(false);
      setTotalInteractionsIsLoaded(false);
      setTotalInteractionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let totalInteractionsLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (istotalInteractionsLoaded && !istotalInteractionsLoading) {
    if (NoDataAvailableChecker(totalInteractions) || NoDataAvailableChecker(totalInteractionsLabels)) {
      totalInteractionsLineChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      totalInteractionsLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="total_interactions"
              chartClass="section_card_chart"
              chartData={totalInteractions}
              chartLabels={totalInteractionsLabels}
              showLabels={true}
              showLegend={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (totalInteractionsError) {
    totalInteractionsLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={totalInteractionsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (istotalInteractionsLoading) {
    totalInteractionsLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS

  // TO FETCH DATA FROM API
  const fetchReactionsHandler = async () => {
    setReactionsIsLoading(true);
    setReactionsIsLoaded(false);
    setReactionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/interaction-semi-chart/",
        config
      );

      setReactions(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metric === undefined || res.data.metric.length === 0 ? [] :
            res.data.metric
      );
      setReactionsIsLoading(false);
      setReactionsIsLoaded(true);
      setReactionsError(null);
    } catch (error) {
      setReactions([]);
      setReactionsIsLoading(false);
      setReactionsIsLoaded(false);
      setReactionsError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let reactionsHalfDonutChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let reactionsDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isreactionsLoaded && !isreactionsLoading) {
    if (NoDataAvailableChecker(reactions)) {
      reactionsHalfDonutChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      reactionsDetails = <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      reactionsHalfDonutChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"reactions"}
              chartClass={"section_card_chart"}
              chartData={reactions}
              varient={"Semi-Circle"}
              showLegend={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      reactionsDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {reactions && reactions.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper >
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (reactionsError) {
    reactionsHalfDonutChart = (
      <Wrapper>  <ServerErrorsLoader chartType="donutChartType" error={reactionsError} /></Wrapper>
    );
    reactionsDetails = (
      <Wrapper>  <ServerErrorsLoader chartType="tableChartType" error={reactionsError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isreactionsLoading) {
    reactionsHalfDonutChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    reactionsDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENTS TYPE

  // TO FETCH DATA FROM API
  const fetchCommentsTypeHandler = async () => {
    setCommentsTypeIsLoading(true);
    setCommentsTypeIsLoaded(false);
    setCommentsTypeError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/first-second-level-comment/",
        config
      );

      setCommentsType(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metric === undefined || res.data.metric.length === 0 ? [] :
            res.data.metric
      );
      setCommentsTypeLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label_list === undefined || res.data.label_list.length === 0 ? [] :
            res.data.label_list
      );

      setCommentsTypeIsLoading(false);
      setCommentsTypeIsLoaded(true);
      setCommentsTypeError(null);
    } catch (error) {
      setCommentsType([]);
      setCommentsTypeLabels([]);

      setCommentsTypeIsLoading(false);
      setCommentsTypeIsLoaded(false);
      setCommentsTypeError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let commentsTypeBarChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (iscommentsTypeLoaded && !iscommentsTypeLoading) {
    if (
      NoDataAvailableChecker(commentsType) ||
      NoDataAvailableChecker(commentsTypeLabels)
    ) {
      commentsTypeBarChart = <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
      reactionsDetails = <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      commentsTypeBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="comments_type"
              chartClass="section_card_chart"
              chartData={commentsType}
              chartLabels={commentsTypeLabels}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={false}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentsTypeError) {
    commentsTypeBarChart = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={commentsTypeError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (iscommentsTypeLoading) {
    commentsTypeBarChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/organic/top-5-post/",
        config
      );

      setTopPostsCompare(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat =
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 grid_margin_bottom section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Top 5 Post
          </h2> <SectionDefinationsBadge
              title={"Top 5 Post"}
              module="social"
              platform="linkedin"
              section="organic"
            />
          </div>
        </div>
        <div className="grid col_span_4 ">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (NoDataAvailableChecker(topPostsCompare)) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 grid_margin_bottom section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Top 5 Post
              </h2> <SectionDefinationsBadge
                  title={"Top 5 Post"}
                  module="social"
                  platform="linkedin"
                  section="organic"
                />
              </div>
            </div>
            <div className="grid col_span_4 ">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              social_platform="Linkedin"
              module_section="organic"
              Title={"Post"}
              products={topPostsCompare}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Top 5 Post
            </h2> <SectionDefinationsBadge
                title={"Top 5 Post"}
                module="social"
                platform="linkedin"
                section="organic"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topPostsCompareerror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 grid_margin_bottom section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Top 5 Posts
          </h2> <SectionDefinationsBadge
              title={"Top 5 Post"}
              module="social"
              platform="linkedin"
              section="organic"
            />
          </div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  }

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "Impressions & Reach": impressionsReach,
    "SocialLKDObjectType_Object Type": objectType,
    "SocialFBContentType_Interactions": interactions,
    "Total Interactions": totalInteractions,
    ...(userData.id !== DEMO_US_USER_ID ? { "SocialReaction_Reactions": reactions } : {}),
    ...(userData.id !== DEMO_US_USER_ID ? { "Comment Type ": commentsType } : {}),
    "Compare_Top 5 Post": topPostsCompare,
  };

  useEffect(() => {
    setExportPageName("Social Linkedin Post Organic");
    setFullPageExport(allData);

  }, [
    metricCards,
    impressionsReach,
    objectType,
    interactions,
    totalInteractions,
    reactions,
    commentsType,
    topPostsCompare,
  ]);

  useEffect(() => {
    if (
      isMetricCardsLoaded &&
      isimpressionsReachLoaded &&
      isobjectTypeLoaded &&
      isinteractionsLoaded &&
      istotalInteractionsLoaded &&
      isreactionsLoaded &&
      iscommentsTypeLoaded &&
      isTopPostsCompareLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMetricCardsLoaded,
    isimpressionsReachLoaded,
    isobjectTypeLoaded,
    isinteractionsLoaded,
    istotalInteractionsLoaded,
    isreactionsLoaded,
    iscommentsTypeLoaded,
    isTopPostsCompareLoaded
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="impression_and_reachs_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Impressions & Reach
            </h2><SectionDefinationsBadge
                title={"Impressions & Reach"}
                module="social"
                platform="linkedin"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"impression_and_reachs_section"}
                sectionName={"Impressions & Reach"}
                sectionData={impressionsReach}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {impressionsAndReachLineChart}
            </div>
          </div>
        </div>
        <div id="object_type_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">     <h2 className="section_card_title">Object Type
            </h2> <SectionDefinationsBadge
                title={"Object Type"}
                module="social"
                platform="linkedin"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"object_type_section"}
                sectionName={"SocialLKDObjectType_Object Type"}
                sectionData={objectType}
              />
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {objectTypePieChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              {objectTypeDetails}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="interactions_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Interactions
            </h2> <SectionDefinationsBadge
                title={"Interactions"}
                module="social"
                platform="linkedin"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"interactions_section"}
                sectionName={"SocialFBContentType_Interactions"}
                sectionData={interactions}
              />
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {interactionsPieChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              {interactionsDetails}
            </div>
          </div>
        </div>
        <div
          id="total_interactions_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Total Interactions
            </h2> <SectionDefinationsBadge
                title={"Total Interactions"}
                module="social"
                platform="linkedin"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"total_interactions_section"}
                sectionName={"Total Interactions"}
                sectionData={totalInteractions}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {totalInteractionsLineChart}
            </div>
          </div>
        </div>
      </div>
      {userData.id !== DEMO_US_USER_ID &&
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div id="reaction_section" className="grid col_span_2 section_card">
              <div className="section_card_header">
                <div className="section_info">   <h2 className="section_card_title">Reactions
                </h2><SectionDefinationsBadge
                    title={"Reactions"}
                    module="social"
                    platform="linkedin"
                    section="organic"
                  />
                </div>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    subTab={pageSecondaryTab}
                    sectionId={"reaction_section"}
                    sectionName={"SocialReaction_Reaction"}
                    sectionData={reactions}
                  />
                </div>
              </div>
              <div className="grid col_span_4 section_card_body">
                <div className="section_card_chart_wrapper grid col_span_2">
                  {reactionsHalfDonutChart}
                </div>
                <div className="section_card_table_wrapper grid col_span_2">
                  {reactionsDetails}
                </div>
              </div>
            </div>
            <div
              id="comment_type_first_level_second_level_section"
              className="grid col_span_2 section_card"
            >
              <div className="section_card_header">
                <div className="section_info">   <h2 className="section_card_title">
                  Comment type (First Level / Second Level)
                </h2> <SectionDefinationsBadge
                    title={"Comment type"}
                    module="social"
                    platform="linkedin"
                    section="organic"
                  />
                </div>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    subTab={pageSecondaryTab}
                    sectionId={"comment_type_first_level_second_level_section"}
                    sectionName={"Comment type"}
                    sectionData={commentsType}
                  />
                </div>
              </div>
              <div className="grid grid_cols_1 section_card_body">
                <div className="section_card_chart_wrapper">
                  {commentsTypeBarChart}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      }
      {topPostsCompareFeat}
    </Wrapper>
  );
};

export default SocialLinkedinPostOrganic;
