import { CURRENCY_METRICS, PERCENTAGE_METRIC, TONNES_METRIC } from "../constants/constants";
import { headerFormatter } from "../constants/headerFormatter";

export const SumOfArrayChilds = (obj) => {
    var sum = 0;

    obj.map((data) => {
        sum += parseFloat(data.value)
    })

    return sum;
}

let labelsArr = [
    {
        label: "Monthly Sales Vol. %",
        value: "total_monthly_sales_vol_percent",
    },
    {
        label: "Total Spends %",
        value: "total_spend_percent"
    },
    {
        label: "TV Spends",
        value: "tv_spends"
    },
    {
        label: "TV GRPs",
        value: "tv_grp"
    },
    {
        label: "TV Digital Impressions",
        value: "tv_digital_impressions"
    },
    {
        label: "TV Digital Spends",
        value: "tv_digital_spends"
    },
    {
        label: "All Digital Impressions",
        value: "all_digital_impressions"
    },
    {
        label: "All Digital Spends",
        value: "all_digital_spends"
    },
    {
        label: "Digital Meta Spends",
        value: "digital_meta_spends"
    },
    {
        label: "Digital Meta Impressions",
        value: "digital_meta_impressions"
    },
    {
        label: "Digital DV360 Spends",
        value: "digital_dv360_spends"
    },
    {
        label: "Digital DV360 Impressions",
        value: "digital_dv360_impressions"
    }
];

const labels = (key) => {
    const labelObj = labelsArr.find((data) => data.value === key);
    return labelObj ? labelObj.label : "";
};

export const BubbleChartSizeShapeDynamicLabels = (data) => {
    if (data === undefined || data.length === 0) return [];

    let dataArr = [];
    let defaultKeys = [
        "value",
        "valuex",
        "valuey",
        "valuexLabel",
        "valueyLabel",
        "market",
        "growth",
        "color",
    ];
    let children = [];
    Object.keys(data).forEach((key) => {
        if (!defaultKeys.includes(key)) {
            children.push({ label: labels(key), value: key });
        }
    });
    data["children"] = children;
    dataArr.push(data);
    return dataArr;
};

export const tooltipNumberFormatting = (label, number, country, platform) => {
    if (typeof label !== "string") return "";
    if (number === null || number === undefined) return 0

    if (number !== undefined || null !== null) {
        let formattedNumber = null;

        formattedNumber = number.toLocaleString("en-US", { maximumFractionDigits: 2 });

        let currency = addCurrencySymbols(label, platform, country)
        let metric = addMetricsSymbols(label)
        let tempNumber = formattedNumber;

        if (currency !== null) {

            if (country.countryCode === "US") {
                tempNumber = currency + formattedNumber
            } else {
                formattedNumber = number.toLocaleString('en-IN', { maximumFractionDigits: 2 });
                tempNumber = currency + formattedNumber
            }
        }

        if (metric !== null) {
            tempNumber = formattedNumber + metric
        }

        return tempNumber

    } else {
        var emptyNumLabel = addMetricsSymbols(label);
        var emptyNum = 0 + emptyNumLabel
        return emptyNum;
    }

}


// ADD CURENCY SYMBOLS AS PER THE USER COUNTRY
export const addCurrencySymbols = (label, platform, country) => {
    if (platform === undefined) {
        platform = "default";
    }

    if (label === undefined || label === null) {
        return null
    }

    if (country === undefined) return null;

    if (CURRENCY_METRICS.includes(label.toLowerCase())) {
        if (platform === "Twitter") {
            return country.alternateCurrencySymbol;
        } else {
            return country.currencySymbol;
        }
    } else {
        return null;
    }
}

export const addMetricsSymbols = (label) => {
    if (label === undefined) return null

    if (TONNES_METRIC.includes(label.toLowerCase())) {
        return "T"
    } else if (PERCENTAGE_METRIC.includes(label.toLowerCase())) {
        return "%"
    } else {
        return null
    }
}


export default function getValueFromHeaderFormatter(labelToMatch) {
    const headerObj = headerFormatter.find(
        (obj) => obj.label === labelToMatch
    );
    return headerObj ? headerObj.value : labelToMatch;
}

// GET UNIQUE VALUES FROM THE ARRAY OF OBJECTS
export const getUniqueValuesFromArrofObj = (rawData, labelKeyToRemove) => {
    // Get all keys from the objects
    const allKeys = rawData.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => keys.add(key));
        return keys;
    }, new Set());

    let allUniqueKeysArr = [...allKeys]

    // Remove "labelKeyToRemove" from the array
    const filteredKeys = allUniqueKeysArr.filter(key => key !== labelKeyToRemove);

    return filteredKeys
}


export const getHeaderKeysWithLabels = (key) => {
    if (key === undefined || key === null) return null

    let headerData = [
        {
            label: "Campaign Name",
            value: "campaign_name",
        },
        {
            label: "Objective",
            value: "objective",
        },
        {
            label: "Impressions",
            value: "impressions",
        },
        {
            label: "Clicks",
            value: "clicks",
        },
        {
            label: "Conversions",
            value: "conversions",
        },
        {
            label: "Spends",
            value: "spends",
        },
        {
            label: "CTR",
            value: "ctr",
        },
        {
            label: "Average CPC",
            value: "average_cpc",
        },
        {
            label: "Average CPM",
            value: "average_cpm",
        },
        {
            label: "Conversion Rate",
            value: "conversion_rate",
        },
        {
            label: "Cost Per Conversion",
            value: "cost_per_conversion",
        },
        {
            label: "Top Impression Rate",
            value: "top_impression_rate",
        },
        {
            label: "ABS Top Impression Rate",
            value: "abs_top_impression_rate",
        },
        {
            label: "View Through Rate",
            value: "view_through_rate",
        },
        {
            label: "Ad Group Name",
            value: "ad_group_name",
        },
        {
            label: "Keyword",
            value: "keyword",
        },
        {
            label: "Campaign Type",
            value: "campaign_type",
        },
        {
            label: "Ad Name",
            value: "ad_name",
        },
        {
            label: "Ad ID",
            value: "ad_id",
        },
        {
            label: "Ad Name",
            value: "ad_name",
        },
        {
            label: "Country",
            value: "country",
        },
        {
            label: "City",
            value: "city",
        },
        {
            label: "State",
            value: "state",
        },
        {
            label: "Average CPC",
            value: "avg_cpc",
        },
        {
            label: "Average CPM",
            value: "avg_cpm",
        },
        {
            label: "Search Term",
            value: "search_term",
        },
        {
            label: "Bid Strategy Type",
            value: "bid_strategy_type",
        },
    ]

    const matchedData = headerFormatter.find(data => data.label === key);

    return matchedData ? matchedData.value : '';

}

export const concateTotals = (mainArr, totalArr) => {

    if (mainArr === undefined || mainArr === null || mainArr.length === 0) return [];
    if (totalArr === undefined || totalArr === null || totalArr.length === 0) return mainArr;

    const addTotalString = addStringToFirstValue(totalArr, 'Total')
    const mergedArray = mainArr.concat(addTotalString)
    return mergedArray;
}

export function addStringToFirstValue(arr, str) {
    if (Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'object') {
        const firstObj = arr[0];
        const keys = Object.keys(firstObj);
        if (keys.length > 0) {
            const firstKey = keys[0];
            if (typeof firstObj[firstKey] === 'string' || typeof firstObj[firstKey] === 'number') {
                // Check if the value contains the keyword "Total"
                if (!String(firstObj[firstKey]).includes("Total")) {
                    firstObj[firstKey] = str + firstObj[firstKey];
                }
            }
        }
    }
    return arr;
}

export const sumTotals = (arr) => {
    return arr.reduce((totals, obj) => {
        for (const key in obj) {
            if (totals[key] === undefined) {
                totals[key] = 0;
            }
            if (typeof obj[key] === 'number') {
                totals[key] += obj[key];
            } else {
                totals[key] = ' ';
            }
        }
        return totals;
    }, {});
};
