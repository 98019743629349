import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "../map/map.scss";
import "mapbox-gl/dist/mapbox-gl.css"; // ADDED TO SOLVED THE ISSUE OF MAPBOX GENERATING TWO MAPS
import { USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaWNvZ3oiLCJhIjoiY2w1MHM4bTVpM20ycDNicXp3Y2Y2cWNneiJ9._TK3kEhvF6n1KUc1079_vg";
const IndiaMap = ({
  geoJson,
  mapLevel,
  northPoint,
  southPoint,
  centerPoint,
  zoomLevel,
  onChange,
  toolTipValues,
  toolTipLabels,
  growthDegrowth
}) => {


  let markarr = [];
  let centerpt = centerPoint[0].split(",");
  let northpt = northPoint[0].split(",");
  let southpt = southPoint[0].split(",");

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  const MapNumberFormatter = (number, title) => {

    if (number === "N/A") {
      return number;
    }

    let numberValue = Number(number);

    if (numberValue === 0) {
      numberValue = 0;
    }

    if (isNaN(numberValue)) {
      numberValue = 0;
    }

    if (!isFinite(numberValue)) {
      numberValue = 0;
    }

    const tonnesMetrics = ["Monthly Sales Vol.", "Sales Vol."];
    const percentageMetrics = ["Market Share", "TV SOV%"]
    const liquidMetrics = ["Sales Volume"]
    const moneyMetrics = ["Spends", "Digital Spends", "TV Spends"]
    let variant = "number"

    if (moneyMetrics.includes(title)) {
      variant = "money"
    } else if (liquidMetrics.includes(title)) {
      variant = "liquid"
    } else if (percentageMetrics.includes(title)) {
      variant = "percentage"
    } else if (tonnesMetrics.includes(title)) {
      variant = "tonnes"
    } else {
      variant = "number"
    }

    if (USER_COUNTRY === "India") {
      if (variant === "money") {
        if (numberValue >= 10000000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 10000000).toFixed(2).replace(/\.0$/, "") + "Cr";
        } else if (numberValue >= 100000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 100000).toFixed(2).replace(/\.0$/, "") + "L";
        } else if (numberValue >= 1000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 1000).toFixed(2).replace(/\.0$/, "") + "K";
        } else {
          numberValue = country.currencySymbol + +Math.abs(numberValue).toFixed(2);
        }
      } else if (variant === "percentage") {
        numberValue = +Math.abs(numberValue).toFixed(2) + "%";
      } else if (variant === "time") {
        numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
      }
      else if (variant === "tonnes") {
        if (numberValue >= 1000000000) {
          numberValue = +Math.abs(numberValue / 1000000000).toFixed(2).replace(/\.0$/, "") + "B T";
        } else if (numberValue >= 1000000) {
          numberValue = +Math.abs(numberValue / 1000000).toFixed(2).replace(/\.0$/, "") + "M T";
        } else if (numberValue >= 1000) {
          numberValue = +Math.abs(numberValue / 1000).toFixed(2).replace(/\.0$/, "") + "K T";
        } else {
          numberValue = +Math.abs(numberValue.toFixed(2)) + " T";
        }
      }
      else if (variant === "liquid") {
        if (numberValue >= 1000000000) {
          numberValue =
            +Math.abs(numberValue / 1000000000)
              .toFixed(2)
              .replace(/\.0$/, "") + "B";
        } else if (numberValue >= 1000000) {
          numberValue =
            +Math.abs(numberValue / 1000000)
              .toFixed(2)
              .replace(/\.0$/, "") + "M";
        } else if (numberValue >= 1000) {
          numberValue =
            +Math.abs(numberValue / 1000)
              .toFixed(2)
              .replace(/\.0$/, "") + "K";
        } else {
          numberValue = +Math.abs(numberValue).toFixed(2)
        }
      } else {
        if (numberValue >= 1000000000) {
          numberValue = +Math.abs(numberValue / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
        } else if (numberValue >= 1000000) {
          numberValue =
            +Math.abs(numberValue / 1000000)
              .toFixed(2)
              .replace(/\.0$/, "") + "M";
        } else if (numberValue >= 1000) {
          numberValue =
            +Math.abs(numberValue / 1000)
              .toFixed(2)
              .replace(/\.0$/, "") + "K";
        } else {
          numberValue = +Math.abs(numberValue).toFixed(2)
        }
      }
    } else {
      if (variant === "money") {
        if (numberValue >= 1000000000) {
          numberValue =
            country.currencySymbol +
            +Math.abs(numberValue / 1000000000)
              .toFixed(2)
              .replace(/\.0$/, "") +
            "B";
        } else if (numberValue >= 1000000) {
          numberValue =
            country.currencySymbol +
            +Math.abs(numberValue / 1000000)
              .toFixed(2)
              .replace(/\.0$/, "") +
            "M";
        } else if (numberValue >= 1000) {
          numberValue =
            country.currencySymbol +
            +Math.abs(numberValue / 1000)
              .toFixed(2)
              .replace(/\.0$/, "") +
            "K";
        } else {
          numberValue = country.currencySymbol + numberValue;
        }
      } else if (variant === "percentage") {
        numberValue = +Math.abs(numberValue).toFixed(2) + "%";
      } else if (variant === "time") {
        numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
      }
      else if (variant === "tonnes") {
        if (numberValue >= 1000000000) {
          numberValue = +Math.abs(numberValue / 1000000000).toFixed(2).replace(/\.0$/, "") + "B T";
        } else if (numberValue >= 1000000) {
          numberValue = +Math.abs(numberValue / 1000000).toFixed(2).replace(/\.0$/, "") + "M T";
        } else if (numberValue >= 1000) {
          numberValue = +Math.abs(numberValue / 1000).toFixed(2).replace(/\.0$/, "") + "K T";
        } else {
          numberValue = +Math.abs(numberValue.toFixed(2)) + " T";
        }
      }
      else {
        if (numberValue >= 1000000000) {
          numberValue =
            +Math.abs(numberValue / 1000000000)
              .toFixed(2)
              .replace(/\.0$/, "") + "B";
        } else if (numberValue >= 1000000) {
          numberValue =
            +Math.abs(numberValue / 1000000)
              .toFixed(2)
              .replace(/\.0$/, "") + "M";
        } else if (numberValue >= 1000) {
          numberValue =
            +Math.abs(numberValue / 1000)
              .toFixed(2)
              .replace(/\.0$/, "") + "K";
        } else {
          numberValue = +Math.abs(numberValue).toFixed(2)
        }
      }
    }

    return numberValue;

  }

  const mapContainerRef = useRef(null);

  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/icogz/clatinkb1009s14o6tj43b929",
      center: { lng: centerpt[0], lat: centerpt[1] },
      zoom: zoomLevel,
      preserveDrawingBuffer: true
    });

    map.fitBounds(
      [
        [northpt[0], northpt[1]],
        [southpt[0], southpt[1]],
      ],
      { duration: 0 }
    );
    // Clean up on unmount
    // return () => map.remove();
  }, []);

  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/icogz/clatinkb1009s14o6tj43b929",
      center: { lng: centerpt[0], lat: centerpt[1] },
      zoom: zoomLevel,
    });

    map.fitBounds(
      [
        [northpt[0], northpt[1]],
        [southpt[0], southpt[1]],
      ],
      { duration: 0 }
    );

    if (geoJson != null) {
      geoJson.features.forEach((e, index) => {
        let coordinatespt = e.geometry.coordinates[0].split(",");
        let state_code = "";
        let state = "";
        let mcolor = "#038673"; //By Default green //"#d51f30"; //#038673
        let market_code = "";
        let market_name = "";


        if (e.properties.previous != null) {
          if (e.properties.current[growthDegrowth] < e.properties.previous[growthDegrowth]) {
            mcolor = "#d51f30";
          }
        }

        markarr["z" + index] = new mapboxgl.Marker({
          color: mcolor,
        })
          .setLngLat({ lon: coordinatespt[0], lat: coordinatespt[1] })
          .addTo(map);
        if (mapLevel == 0 || mapLevel == 1) {
          market_code = e.properties.current.market_code
          market_name = e.properties.current.market_name

          if (mapLevel == 1) {
            state_code = e.properties.current.state_code;
            state = e.properties.current.state;
          }
          markarr["z" + index].getElement().addEventListener("click", () => {
            onChange([mapLevel, e.geometry.coordinates,
              e.geometry.northcoordinates,
              e.geometry.southcoordinates,
              e.properties.current.zone,
              state,
              state_code,
              market_code,
              market_name
            ]);
          });
        }

        // CREATE DYNAMIC ARRAY OF LABEL FOR TOOLTIP
        let toolTipValueArray = []
        for (let toolTipValuesIndex = 0; toolTipValuesIndex < toolTipValues.length; toolTipValuesIndex++) {
          toolTipValueArray.push({
            key: toolTipValues[toolTipValuesIndex],
            value: ""
          })
        }

        // CREATE DYNAMIC ARRAY OF VALUES FOR TOOLTIP
        toolTipValues.map((value, valueIndex) => (
          toolTipValueArray[valueIndex].value = MapNumberFormatter(e.properties.current[value], toolTipLabels[valueIndex])
        ))

        // APPEND DYNAMIC HTML TO THE TOOLTIP OF THE MAP
        var toolTipAppendedHTML = '<div>';
        for (let index = 0; index < toolTipValueArray.length; index++) {
          toolTipAppendedHTML += '<div>' + toolTipLabels[index] + ': ' + toolTipValueArray[index].value + '</div>';
        }
        toolTipAppendedHTML += '</div>';

        let popup = new mapboxgl.Popup().setHTML(e.properties.current.title + toolTipAppendedHTML);

        const elm = markarr["z" + index].getElement();
        elm.id = markarr["z" + index];
        elm.addEventListener("mouseover", () => popup.addTo(map));
        elm.addEventListener("mouseleave", () => popup.remove());
        markarr["z" + index].setPopup(popup);
        markarr["z" + index].addTo(map);
        // markarr["z" + index].remove();
      });
    }

    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => {
      map.remove();
    }
  }, [geoJson]);

  return <div className="map_section" ref={mapContainerRef} />;
};

export default IndiaMap;
