import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH258896422");
am4core.useTheme(am4themes_animated);

export default class RiStoreBubbleChart extends Component {
    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        let chart_id = info['chart_id'];
        let data = info['graph-data'];


        // let data = JSON.parse(info['graph-data']);

        // Dynamic calculation of y and x axis
        var yValue = []; for (let i = 0; i < data.length; i++) {
            if (data[i].y != undefined) { yValue.push(data[i].y) };
            if (data[i].y1 != undefined) { yValue.push(data[i].y1) };
            if (data[i].y2 != undefined) { yValue.push(data[i].y2) };
        }
        let maxFootfall = Math.max.apply(Math, yValue);

        var xValue = []; for (let i = 0; i < data.length; i++) {
            if (data[i].x != undefined) { xValue.push(data[i].x) };
            if (data[i].x1 != undefined) { xValue.push(data[i].x1) };
            if (data[i].x2 != undefined) { xValue.push(data[i].x2) };
        }
        let minNSV = Math.min.apply(Math, xValue);
        let maxNSV = Math.max.apply(Math, xValue);



        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.exporting.menu = new am4core.ExportMenu();


        chart.data = data;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 11;//TO CHANGE FONT SIZE OF CHART LABELS

        chart.colors.list = [
            am4core.color("#038673"),  //green
            am4core.color("#db7823"),  //yellow
            am4core.color("#d51f30"),  //red
        ];

        chart.dataFields.color = "color";

        // FOR FORMATTING LARGE NUMBERS
        if (this.props.isnumber == 'money') {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+5, "suffix": "L" },
                { "number": 1e+7, "suffix": "Cr" }
            ];
        } else if (this.props.isnumber == 'number') {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+6, "suffix": "M" },
                { "number": 1e+9, "suffix": "G" }
            ];
        }

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        // xAxis.renderer.minGridDistance = 50;  //To manage the gap/space for X axis
        xAxis.title.text = titleX;
        xAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        xAxis.renderer.grid.template.disabled = true;
        xAxis.min = minNSV
        xAxis.max = maxNSV;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // yAxis.renderer.minGridDistance = 20;
        yAxis.title.text = titleY;
        yAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        yAxis.renderer.grid.template.disabled = true;
        yAxis.max = maxFootfall;

        // Create series #1
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "y";
        series.dataFields.valueX = "x";
        series.dataFields.value = "value1";
        // series.dataFields.color = "color";
        series.strokeOpacity = 0;
        series.name = title1;


        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.strokeOpacity = 0.5;
        bullet.fillOpacity = 0.5;

        // Create a hover state
        var hoverStateCircle = bullet.states.create("hover");
        hoverStateCircle.properties.fillOpacity = 1;

        bullet.nonScalingStroke = true;

        bullet.tooltipText = `
        [bold]{name}[/]
        ---
        ` + titleY + `: [bold]{valueY}[/]
        ` + titleX + `: [bold]{valueX}[/]
        NSV Mix: [bold]{value1}%[/]
        `
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.padding(10, 10, 10, 10);
        series.tooltip.label.fontSize = 12;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 80;

        var shadow = series.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series.heatRules.push({
            target: bullet.circle,
            min: 10,
            max: 20,
            property: "radius"
        });

        // Create series #2
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "y2";
        series2.dataFields.valueX = "x2";
        series2.dataFields.value = "value2";
        series2.strokeOpacity = 0;
        series2.fillOpacity = 0;
        series2.name = title2;

        let bullet2 = series2.bullets.push(new am4charts.Bullet());
        bullet2.tooltipText = `
        [bold]{name}[/]
        ---
        ` + titleY + `: [bold]{valueY}[/]
        ` + titleX + `: [bold]{valueX}[/]
        NSV Mix: [bold]{value2}%[/]
        `
        // bullet2.tooltipText = "{name} - Units Sold: {valueX} ASP: {valueY}, NSV: {value}";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#213345");
        series2.tooltip.background.cornerRadius = 5;
        series2.tooltip.label.padding(10, 10, 10, 10);
        series2.tooltip.label.fontSize = 12;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 80;
        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        bullet2.layout = "absolute";

        let rectangle = bullet2.createChild(am4core.Rectangle);
        rectangle.verticalCenter = "middle";
        rectangle.horizontalCenter = "middle";
        rectangle.width = 10;
        rectangle.height = 10;
        rectangle.fillOpacity = 0.5;
        rectangle.strokeOpacity = 0;
        rectangle.nonScalingStroke = true;

        // Create a hover state
        var hoverStateRectangle = rectangle.states.create("hover");
        hoverStateRectangle.properties.fillOpacity = 1;

        rectangle.verticalCenter = "middle";
        rectangle.horizontalCenter = "middle";

        series2.heatRules.push({
            target: rectangle,
            min: 1,
            max: 4,
            property: "scale"
        });

        // Create series #3
        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "y3";
        series3.dataFields.valueX = "x3";
        series3.dataFields.value = "value3";
        series3.strokeOpacity = 0;
        series3.name = title3;

        let bullet3 = series3.bullets.push(new am4charts.Bullet());
        // bullet3.tooltipText = "{name} - Footfalls: {valueY} NSV: {valueX}, NSVMix: {value}";
        bullet3.tooltipText = `
        [bold]{name}[/]
        ---
        ` + titleY + `: [bold]{valueY}[/]
        ` + titleX + `: [bold]{valueX}[/]
        NSV Mix: [bold]{value3}%[/]
        `
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4core.color("#213345");
        series3.tooltip.background.cornerRadius = 5;
        series3.tooltip.label.padding(10, 10, 10, 10);
        series3.tooltip.label.fontSize = 12;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 80;
        var shadow = series3.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        bullet3.layout = "absolute";

        let triangle = bullet3.createChild(am4core.Triangle);
        triangle.verticalCenter = "middle";
        triangle.horizontalCenter = "middle";
        triangle.width = 10;
        triangle.height = 10;
        triangle.fillOpacity = 0.5;
        triangle.strokeOpacity = 0.5;
        triangle.nonScalingStroke = true;

        // Create a hover state
        var hoverState = triangle.states.create("hover");
        hoverState.properties.fillOpacity = 1;

        triangle.verticalCenter = "middle";
        triangle.horizontalCenter = "middle";

        series3.heatRules.push({
            target: triangle,
            min: 1,
            max: 4,
            property: "scale"
        });

        let middleLine = chart.plotContainer.createChild(am4core.Line);
        middleLine.strokeOpacity = 1;
        middleLine.stroke = am4core.color("#757575");
        middleLine.strokeDasharray = "1,1";
        middleLine.align = "center";
        middleLine.zIndex = 1;
        middleLine.adapter.add("y2", function (y2, target) {
            return target.parent.pixelHeight;
        })

        yAxis.renderer.gridContainer.zIndex = 1;

        let axisRange = yAxis.axisRanges.create();
        axisRange.value = 2000;
        axisRange.grid.strokeOpacity = 0.1;
        axisRange.label.verticalCenter = "bottom";
        axisRange.label.fillOpacity = 0.8;

        // Scrollbars
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();
        //chart.dataFields.color = "color";



    }


    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
        )
    }
}
