const IconAts = (props) => {
    return (
        <svg className={props.className} width="55.5" height="54.75" viewBox="0 0 55.5 54.75" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
              <g id="Group_717" data-name="Group 717" transform="translate(-8361.25 431.25)">
    <g id="Group_716" data-name="Group 716" transform="translate(8362 -431.5)">
      <path id="Line_139" data-name="Line 139" d="M.457,21.051l-.914-1.19L26.183-.595,27.1.595Z" transform="translate(0 10.08)" fill="#486f88"/>
      <path id="Line_140" data-name="Line 140" d="M12.859,7.67a.747.747,0,0,1-.355-.09L-.355.66A.75.75,0,0,1-.66-.355.75.75,0,0,1,.355-.66L13.215,6.26a.75.75,0,0,1-.356,1.411Z" transform="translate(26.641 10.08)" fill="#486f88"/>
      <path id="Line_141" data-name="Line 141" d="M0,15.75a.747.747,0,0,1-.512-.2.75.75,0,0,1-.037-1.06l14-15a.75.75,0,0,1,1.06-.037.75.75,0,0,1,.037,1.06l-14,15A.748.748,0,0,1,0,15.75Z" transform="translate(39.5 2)" fill="#486f88"/>
      <path id="Line_144" data-name="Line 144" d="M10.08.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H10.08a.75.75,0,0,1,.75.75A.75.75,0,0,1,10.08.75Z" transform="translate(43.92 1)" fill="#486f88"/>
      <path id="Line_145" data-name="Line 145" d="M0,10.83a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V10.08A.75.75,0,0,1,0,10.83Z" transform="translate(54 1)" fill="#486f88"/>
      <path id="Line_146" data-name="Line 146" d="M.75,9H-.75V0H.75Z" transform="translate(0 46)" fill="#486f88"/>
      <path id="Path_302" data-name="Path 302" d="M.75,15.75H-.75V0H.75Z" transform="translate(13.722 39.25)" fill="#486f88"/>
      <path id="Line_148" data-name="Line 148" d="M.75,22.972H-.75V0H.75Z" transform="translate(26.303 32.028)" fill="#486f88"/>
      <path id="Line_149" data-name="Line 149" d="M.75,21.828H-.75V0H.75Z" transform="translate(40.023 33.172)" fill="#486f88"/>
      <path id="Line_150" data-name="Line 150" d="M.75,34.408H-.75V0H.75Z" transform="translate(53.603 20.592)" fill="#486f88"/>
    </g>
  </g>
        </svg>
    )
}

export default IconAts;