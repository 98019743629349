import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Card from "../../../components/ui/Card";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";
import Slider from "../../../components/ui/Slider";

// LOADERS
import Loader from "../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../../components/ui/charts/BarChart";
import CombinedBarLineMultipleAxesChart from "../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import PieofaPieChart from "../../../components/ui/charts/PieofaPieChart";

// UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import {
  IAEvent_Platform_Visit,
  IAEvent_Hashtag_Visit,
  IAEvent_Primary_Tab_Visit,
  IAEvent_Dropdown_Visit,
} from "../../../utils/IAEvents";
import NumberFormatter from "../../../utils/NumberFormatter";
import Tab from "../../../components/ui/Tab";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import TagCloud from "../../../components/ui/charts/TagCloud";
import CategoryCombinedBarLineMultipleAxesChart from "../../../components/ui/charts/CategoryCombinedBarLineMultipleAxesChart";

const Orm = () => {
  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setExportPageName,
    exportSummary,
    setexportSummary,
    setIsExportDisabled
  } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const [selectedPlatform, setSelectedPlatform] = useState("Facebook");

  const [searchTerm, setSeachTerm] = useState("");

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const selectedBrands = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrands
  );

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Social Listening";
  let pagePlatform = selectedPlatform;

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // HASHTAG LIST
  const [topicList, setTopicList] = useState([]);
  const [isTopicListLoading, setTopicListIsLoading] = useState(false);
  const [isTopicListLoaded, setTopicListIsLoaded] = useState(false);
  const [topicListerror, setTopicListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [isSelectedTopic, setisSelectedTopic] = useState("");

  // SUMMARY METRIC CARDS
  const [summaryCards, setSummaryCards] = useState([]);
  const [isSummaryCardsLoading, setSummaryCardsIsLoading] = useState(false);
  const [isSummaryCardsLoaded, setSummaryCardsIsLoaded] = useState(false);
  const [summaryCardserror, setSummaryCardsError] = useState(null);

  // OVERALL CONVERSATIONS
  const [overallConversations, setOverallConversations] = useState([]);
  const [overallConversationsLabels, setOverallConversationsLabels] = useState([]);

  const [isOverallConversationsLoading, setOverallConversationsIsLoading] = useState(false);
  const [isOverallConversationsLoaded, setOverallConversationsIsLoaded] = useState(false);
  const [overallConversationserror, setOverallConversationsError] = useState(null);

  // POST ENGAGEMENTS DETAILS
  const [conversationTable, setConversationTable] = useState([]);
  const [isConversationTableLoading, setConversationTableIsLoading] = useState(false);
  const [isConversationTableLoaded, setConversationTableIsLoaded] = useState(false);
  const [conversationTableerror, setConversationTableError] = useState(null);

  // TOP HASHTAG BAR CHART DATA
  // const [currentSelectedChartPlatform, setCurrentSelectedChartPlatform] =
  //   useState("");
  const [volumeSource, setVolumeSource] = useState([]);
  const [volumeSourceLabels, setVolumeSourceLabels] = useState([]);
  const [isVolumeSourceLoading, setVolumeSourceIsLoading] = useState(false);
  const [isVolumeSourceLoaded, setVolumeSourceIsLoaded] = useState(false);
  const [volumeSourceError, setVolumeSourceError] = useState(null);
  // const [noOfBarsToShow, setNoOfBarsToShow] = useState(0);

  // VOLUMETRENDLINE
  const [volumeTrendline, setVolumeTrendline] = useState([]);
  const [volumeTrendlineDataLabels, setVolumeTrendlineDataLabels] = useState([]);
  const [isvolumeTrendlineLoading, setVolumeTrendlineIsLoading] = useState(false);
  const [isvolumeTrendlineLoaded, setVolumeTrendlineIsLoaded] = useState(false);
  const [volumeTrendlineerror, setVolumeTrendlineError] = useState(null);

  // VOLUME DAY
  const [volumeDay, setVolumeDay] = useState([]);
  const [volumeDayDataLabels, setVolumeDayDataLabels] = useState([]);
  const [isvolumeDayLoading, setVolumeDayIsLoading] = useState(false);
  const [isvolumeDayLoaded, setVolumeDayIsLoaded] = useState(false);
  const [volumeDayerror, setVolumeDayError] = useState(null);

  const [volumeTime, setTopVolumeTime] = useState([]);
  const [volumeTimeLabels, setTopVolumeTimeLabels] = useState([]);
  const [isVolumeTimeLoading, setVolumeTimeIsLoading] = useState(false);
  const [isVolumeTimeLoaded, setVolumeTimeIsLoaded] = useState(false);
  const [volumeTimeError, setVolumeTimeError] = useState(null);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  useEffect(() => {
    // TO FETCH METRIC CARDS FROM API
    const fetchMetricCardsHandler = async () => {
      setMetricCardsIsLoading(true);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get(
          "orm/sprinklr/overall-metric-card",
          config
        );
        setMetricCards(
          res.data === undefined || res.data.length === 0 ? [] : res.data
        );
        setMetricCardsIsLoading(false);
        setMetricCardsIsLoaded(true);
        setMetricCardsError(null);
      } catch (error) {
        setMetricCards([]);
        setMetricCardsIsLoading(false);
        setMetricCardsIsLoaded(false);
        setMetricCardsError(error.code);
      }
    };

    fetchMetricCardsHandler();
  }, [authCtx, BRAND_ID, selectedDates]);

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <Loader loaderType="topicLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_margin_bottom">
            <div className="card_wrapper">
              <ErrorBoundary chartType="cardChartType">
                <Slider
                  sliderWrapperClass={"tab_card_slider"}
                  showSliderHeader={false}
                  showNavigation={false}
                  sliderTitle={"Summary" + " : " + isSelectedTopic + " "
                  }
                >
                  {metricCards.map((data, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => activePlatform(data.cardTitle.toLowerCase())}
                    >
                      <Card
                        cardClass={selectedPlatform.toLowerCase() === data.cardTitle.toLowerCase() ? "tabCard active" : "tabCard "}
                        variant={"compare"}
                        cardTitle={data.cardTitle}
                        currentValue={data.currentValue}
                        previousValue={data.previousValue}
                        rowOneTitle={data.rowOneTitle}
                        second_currentValue={data.second_currentValue}
                        second_previousValue={data.second_previousValue}
                        rowTwoTitle={data.rowTwoTitle}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </ErrorBoundary>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={metricCardserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="topicLoader" />
      </Wrapper>
    );
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  // topics
  useEffect(() => {
    // TO FETCH TRENDING HASHTAG LIST FROM API
    const fetchTopicListHandler = async () => {
      setTopicListIsLoading(true);
      setTopicListIsLoaded(false);
      setTopicListError(null);
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/topic-list", config);


        setTopicList(
          res.data === undefined || res.data.length === 0 ? [] : res.data
        );
        setTopicListIsLoading(false);
        setTopicListIsLoaded(true);
        setTopicListError(null);
      } catch (error) {
        setTopicListIsLoading(false);
        setTopicListIsLoaded(false);
        setTopicListError(error.code);
      }
    };

    fetchTopicListHandler();
    setisSelectedTopic("");
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredTopicList = useMemo(() => {
    if (searchTerm === "") return topicList;
    if (topicList === "") return topicList;

    const filteredList = topicList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, topicList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (isSelectedTopic === "") {
      setisSelectedTopic(badge.hashtag);
    } else if (isSelectedTopic === badge.hashtag) {
      setisSelectedTopic("");
    } else {
      setisSelectedTopic(badge.hashtag);
    }
    IAEvent_Hashtag_Visit(
      "Social Listening",
      "Social Listening",
      selectedPlatform,
      null,
      null,
      badge.hashtag
    );
  };

  let topTrendingTopicList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isTopicListLoaded && !isTopicListLoading) {
    if (NoDataAvailableChecker(filteredTopicList)) {
      topTrendingTopicList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingTopicList = filteredTopicList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  isSelectedTopic === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topicListerror) {
    topTrendingTopicList = (
      <Wrapper>
        <ServerErrorsLoader chartType="hashChartType" error={topicListerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopicListLoading) {
    topTrendingTopicList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // SUMMARY CARDS

  useEffect(() => {
    // TO FETCH METRIC CARDS FROM API
    const fetchSummaryCardsHandler = async () => {
      setSummaryCardsIsLoading(true);
      setSummaryCardsIsLoaded(false);
      setSummaryCardsError(null);
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/metric-card", config);


        setSummaryCards(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.data === undefined || res.data.data.length === 0
              ? []
              : res.data.data
        );
        setSummaryCardsIsLoading(false);
        setSummaryCardsIsLoaded(true);
        setSummaryCardsError(null);
      } catch (error) {
        setSummaryCards([]);
        setSummaryCardsIsLoading(false);
        setSummaryCardsIsLoaded(false);
        setSummaryCardsError(error.code);
      }
    };

    fetchSummaryCardsHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let summaryCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">
              {/*  {selectedPlatform && selectedPlatform + " "}  */}
              Summary {isSelectedTopic && " : " + isSelectedTopic + " "}
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isSummaryCardsLoaded && !isSummaryCardsLoading) {
    if (NoDataAvailableChecker(summaryCards)) {
      summaryCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Summary {isSelectedTopic && " : " + isSelectedTopic + " "}
                </h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      summaryCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={
                "Summary" + (isSelectedTopic && " : " + isSelectedTopic + " ")
              }
            >
              {/*  Summary {isSelectedTopic && " : " + isSelectedTopic + " "} */}

              {summaryCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="overall"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (summaryCardserror) {
    summaryCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Summary {isSelectedTopic && " : " + isSelectedTopic + " "}
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={summaryCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSummaryCardsLoading) {
    summaryCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Summary {isSelectedTopic && " : " + isSelectedTopic + " "}
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // OVERALL CONVERSATION
  useEffect(() => {
    const fetchOverallConversationsHandler = async () => {
      setOverallConversationsIsLoading(true);
      setOverallConversationsIsLoaded(false);
      setOverallConversationsError(null);
      setConversationTableIsLoading(true);
      setConversationTableIsLoaded(false);
      setConversationTableError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/conversation", config);

        setOverallConversations(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.chart_data === undefined || res.data.chart_data.length === 0 ? [] :
              res.data.chart_data.data === undefined || res.data.chart_data.data.length === 0 ? [] :
                res.data.chart_data.data
        );
        setOverallConversationsLabels(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.chart_data === undefined || res.data.chart_data.length === 0 ? [] :
              res.data.chart_data.label === undefined || res.data.chart_data.label.length === 0 ? [] :
                res.data.chart_data.label
        );

        setOverallConversationsIsLoading(false);
        setOverallConversationsIsLoaded(true);
        setOverallConversationsError(null);

        setConversationTable(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.table_data === undefined || res.data.table_data.length === 0 ? [] :
              res.data.table_data
        );
        setConversationTableIsLoading(false);
        setConversationTableIsLoaded(true);
        setConversationTableError(null);
      } catch (error) {
        setOverallConversations([]);
        setOverallConversationsLabels([])
        setOverallConversationsIsLoading(false);
        setOverallConversationsIsLoaded(false);
        setOverallConversationsError(error.code);
        setConversationTable([]);
        setConversationTableIsLoading(false);
        setConversationTableIsLoaded(false);
        setConversationTableError(error.code);
      }
    };

    fetchOverallConversationsHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);


  let overallConversationsChart = (<Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>);

  // IF DATA IS AVAILABLE
  if (isOverallConversationsLoaded && !isOverallConversationsLoading) {
    if (NoDataAvailableChecker(overallConversations) || NoDataAvailableChecker(overallConversationsLabels)) {
      overallConversationsChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      overallConversationsChart = (
        <Wrapper>
          <ErrorBoundary>
            <PieofaPieChart
              chartClass="section_card_chart"
              chartId="overall_conversations"
              chartLabels={overallConversationsLabels}
              chartData={overallConversations}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (overallConversationserror) {
    overallConversationsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={overallConversationserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isOverallConversationsLoading) {
    overallConversationsChart = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONVERSATION TABLE

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let conversationTableData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isConversationTableLoaded && !isConversationTableLoading) {
    if (NoDataAvailableChecker(conversationTable)) {
      conversationTableData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      conversationTableData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {conversationTable &&
                    conversationTable.map((details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">{details.label}</div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (conversationTableerror) {
    conversationTableData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={conversationTableerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isConversationTableLoading) {
    conversationTableData = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // WORD CLOUD
  const [wordCloud, setWordCloud] = useState([]);
  const [isWordCloudLoading, setWordCloudIsLoading] = useState(false);
  const [isWordCloudLoaded, setWordCloudIsLoaded] = useState(false);
  const [wordClouderror, setWordCloudError] = useState(null);

  const [wordCloudAllData, setWordCloudAllData] = useState([]);

  // DROPDOWN OPTIONS
  const wordCloudOptions = [
    { value: "overall", label: "Overall" },
    { value: "positive", label: "Positive" },
    { value: "neutral", label: "Neutral" },
    { value: "negative", label: "Negative" },
  ];

  //DROPDOWN STATE
  const [wordCloudDropdownOption, setWordCloudDropdownOption] = useState(
    wordCloudOptions[0]
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const wordCloudSelectionHandler = (e) => {
    if (e === null) {
      setWordCloudDropdownOption("");
    } else {
      setWordCloudDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Social Listening",
      "Social Listening",
      "Summary",
      null,
      "Top Things Mention",
      e.label
    );
  };
  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
    const fetchWordCloudDropdownHandler = async () => {
      if (wordCloudDropdownOption.value === "overall") {
        setWordCloud(wordCloudAllData.overall);
      } else if (wordCloudDropdownOption.value === "positive") {
        setWordCloud(wordCloudAllData.positive);
      } else if (wordCloudDropdownOption.value === "neutral") {
        setWordCloud(wordCloudAllData.neutral);
      } else if (wordCloudDropdownOption.value === "negative") {
        setWordCloud(wordCloudAllData.negative);
      } else {
        setWordCloud(wordCloudAllData.overall);
      }
    };

    fetchWordCloudDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedPlatform,
    isSelectedTopic,
    wordCloudDropdownOption,
  ]);

  useEffect(() => {
    setWordCloudDropdownOption({ value: "overall", label: "Overall" });
  }, [BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // --------------------

  // WORD CLOUD
  useEffect(() => {
    const fetchWordCloudHandler = async () => {
      setWordCloudIsLoading(true);
      setWordCloudIsLoaded(false);
      setWordCloudError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get(
          "orm/sprinklr/top-things-mentions",
          config
        );

        setWordCloud(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.overall === undefined || res.data.overall.length === 0 ? [] :
              res.data.overall
        );
        setWordCloudAllData(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data
        );

        setWordCloudIsLoading(false);
        setWordCloudIsLoaded(true);
        setWordCloudError(null);
      } catch (error) {
        setWordCloud([]);
        setWordCloudAllData([]);
        setWordCloudIsLoading(false);
        setWordCloudIsLoaded(false);
        setWordCloudError(error.code);
      }
    };

    fetchWordCloudHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  let wordCloudChart = (
    <Wrapper>
      <Loader loaderType="wordCloudLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isWordCloudLoaded && !isWordCloudLoading) {
    if (NoDataAvailableChecker(wordCloud)) {
      wordCloudChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="wordCloudChartType" />
        </Wrapper>
      );
    } else {
      wordCloudChart = (
        <Wrapper>
          <TagCloud
            chartClass="section_card_chart"
            chartData={wordCloud.slice(0, 100)}
            chartId="section_chart_word_cloud"
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (wordClouderror) {
    wordCloudChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="wordCloudChartType"
          error={wordClouderror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isWordCloudLoading) {
    wordCloudChart = (
      <Wrapper>
        <Loader loaderType="wordCloudLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VOLUME TRENDLINE

  useEffect(() => {
    // TO FETCH DATA FROM API
    const fetchvolumeTrendlineChartHandler = async () => {
      setVolumeTrendlineIsLoading(true);
      setVolumeTrendlineIsLoaded(false);
      setVolumeTrendlineError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/volume-trendline", config);

        setVolumeTrendline(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data
        );

        setVolumeTrendlineDataLabels(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
              res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
                res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                  res.data.Dimensions
        );

        setVolumeTrendlineIsLoading(false);
        setVolumeTrendlineIsLoaded(true);
        setVolumeTrendlineError(null);
      } catch (error) {
        setVolumeTrendline([]);
        setVolumeTrendlineDataLabels([]);
        setVolumeTrendlineIsLoading(false);
        setVolumeTrendlineIsLoaded(false);
        setVolumeTrendlineError(error.code);
      }
    };

    fetchvolumeTrendlineChartHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  let volumeTrendlineChart = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isvolumeTrendlineLoaded && !isvolumeTrendlineLoading) {
    if (NoDataAvailableChecker(volumeTrendline) || NoDataAvailableChecker(volumeTrendlineDataLabels)) {
      volumeTrendlineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {
      volumeTrendlineChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="combinedbarline"
              chartClass="section_card_chart"
              chartData={volumeTrendline}
              chartLabels={volumeTrendlineDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (volumeTrendlineerror) {
    volumeTrendlineChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={volumeTrendlineerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isvolumeTrendlineLoading) {
    volumeTrendlineChart = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //  VOLUME SOURCE BAR CHART

  useEffect(() => {
    // TO FETCH HASHTAG CHART DATA FROM API
    const fetchVolumeSourceHandler = async () => {
      setVolumeSourceIsLoading(true);
      setVolumeSourceIsLoaded(false);
      setVolumeSourceError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/volume-by-source", config);

        setVolumeSource(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data
        );
        setVolumeSourceLabels(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
              res.data.Dimensions
        );
        setVolumeSourceIsLoaded(true);
        setVolumeSourceIsLoading(false);
        setVolumeSourceError(null);
      } catch (error) {
        setVolumeSourceIsLoading(false);
        setVolumeSourceIsLoaded(false);
        setVolumeSourceError(error.code);
      }
    };

    fetchVolumeSourceHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  let volumeSourceBarChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isVolumeSourceLoaded && !isVolumeSourceLoading) {
    if (NoDataAvailableChecker(volumeSource)) {
      volumeSourceBarChart = (<Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>);
    } else {
      volumeSourceBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="hashtagVolumeSourceBarChart"
              chartClass="section_card_chart"
              chartData={volumeSource}
              chartLabels={volumeSourceLabels}
              showLabels={true}
              showVerticalLabels={true}
              minimumGridDistance={20}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (volumeSourceError) {
    volumeSourceBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={volumeSourceError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVolumeSourceLoading) {
    volumeSourceBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VOLUME DAY

  useEffect(() => {
    // TO FETCH DATA FROM API
    const fetchvolumeDayChartHandler = async () => {
      setVolumeDayIsLoading(true);
      setVolumeDayIsLoaded(false);
      setVolumeDayError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("orm/sprinklr/volume-by-day-of-week", config);

        setVolumeDay(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data
        );
        setVolumeDayDataLabels(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
              res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
                res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                  res.data.Dimensions
        );

        setVolumeDayIsLoading(false);
        setVolumeDayIsLoaded(true);
        setVolumeDayError(null);
      } catch (error) {
        setVolumeDay([]);
        setVolumeDayDataLabels([]);
        setVolumeDayIsLoading(false);
        setVolumeDayIsLoaded(false);
        setVolumeDayError(error.code);
      }
    };

    fetchvolumeDayChartHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let volumeDayChart = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isvolumeDayLoaded && !isvolumeDayLoading) {
    if (NoDataAvailableChecker(volumeDay) || NoDataAvailableChecker(volumeDayDataLabels)) {
      volumeDayChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {
      volumeDayChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CategoryCombinedBarLineMultipleAxesChart
              chartId="social_facebook_current_period"
              chartClass="section_card_chart"
              chartData={volumeDay}
              chartLabels={volumeDayDataLabels}
              categoryFormat={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (volumeDayerror) {
    volumeDayChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={volumeDayerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isvolumeDayLoading) {
    volumeDayChart = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VOLUME TIME BARCHART 

  useEffect(() => {
    // TO FETCH HASHTAG CHART DATA FROM API
    const fetchVolumeTimeHandler = async () => {
      setVolumeTimeIsLoading(true);
      setVolumeTimeIsLoaded(false);
      setVolumeTimeError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          platform: selectedPlatform === "" ? null : selectedPlatform,
          topic: isSelectedTopic === "" ? null : isSelectedTopic,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get(
          "orm/sprinklr/volume-by-time-of-day",
          config
        );

        setTopVolumeTime(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data
        );
        setTopVolumeTimeLabels(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
              res.data.dimension
        );
        setVolumeTimeIsLoaded(true);
        setVolumeTimeIsLoading(false);
        setVolumeTimeError(null);
      } catch (error) {
        setVolumeTimeIsLoading(false);
        setVolumeTimeIsLoaded(false);
        setVolumeTimeError(error.code);
      }
    };

    fetchVolumeTimeHandler();
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform, isSelectedTopic]);

  let volumeTimeBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isVolumeTimeLoaded && !isVolumeTimeLoading) {
    if (NoDataAvailableChecker(volumeTime)) {
      volumeTimeBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      volumeTimeBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="hashtagVolumeTimeBarChart"
              chartClass="section_card_chart"
              chartData={volumeTime}
              chartLabels={volumeTimeLabels}
              showLabels={true}
              showVerticalLabels={true}
              minimumGridDistance={5}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (volumeTimeError) {
    volumeTimeBarChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={volumeTimeError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVolumeTimeLoading) {
    volumeTimeBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  const activePlatform = (platform) => {
    // setisSelectedTopic();
    if (selectedPlatform !== platform) {
      setSelectedPlatform(platform.toLowerCase());
    }
    //  else if (selectedPlatform === platform) {
    //   setSelectedPlatform("");
    // } else {
    //   setSelectedPlatform(platform.toLowerCase());
    // }
    IAEvent_Platform_Visit(
      "Marketing Intelligence",
      "Social Listening",
      platform
    );
  };

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);
          return { ...item, label: date.toLocaleDateString("en-GB") };
        });
    return temp;
  };

  var brandsExport = (function () {
    if (selectedBrands === undefined || selectedBrands.length === 0) {
      return { "": "" }
    } else {
      var obj = {};
      for (var i = 0; i < selectedBrands.length; i++) {
        if (selectedBrands.length === 1) {
          obj["Brand"] = selectedBrands[i].label
        } else {
          obj["Brand " + (i + 1)] = selectedBrands[i].label
        }
      }
      return obj;
    }
  })()


  useEffect(() => {
    setexportSummary([selectedDates, brandsExport]);
  }, [selectedDates, selectedBrands]);


  var overallConversationCircleTableCSV = {
    [`PieOfPieDynaLabels_Overall Conversation_${isSelectedTopic && " : " + isSelectedTopic}`]: overallConversations,
    [`OverallConverseTable_Overall Conversation Table_${isSelectedTopic && " : " + isSelectedTopic}`]: conversationTable,
  };
  var mentionedThingsCSV = { [`WordCloudFormat_Top Things Mentioned_${(wordCloudDropdownOption.label) + (isSelectedTopic && " - " + isSelectedTopic)}`]: wordCloud }
  var allData = {
    "Summary": exportSummary,
    "PlatformCards_Platform Summary": metricCards,
    [`SummaryCardDynaLabels_Summary Card_${isSelectedTopic && " : " + isSelectedTopic}`]: summaryCards,
    ...overallConversationCircleTableCSV,
    ...mentionedThingsCSV,
    [`OrmVolumeSource_Mentions Volume By Source_${isSelectedTopic && " : " + isSelectedTopic}`]: volumeSource,
    [`DateFirstNonActiveDropdownLabels_Mentions Volume Trendline_${isSelectedTopic && " : " + isSelectedTopic}`]: combinedLineChartCSV(volumeTrendline),
    [`OrmVolumeDayWeek_Mentions Volume By Day of Week_${isSelectedTopic && " : " + isSelectedTopic}`]: volumeDay,
    [`OrmVolumeTimeDay_Mentions Volume By Time of Day_${isSelectedTopic && " : " + isSelectedTopic}`]: volumeTime,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    topicList,
    summaryCards,
    overallConversations,
    conversationTable,
    wordCloud,
    volumeSource,
    volumeTrendline,
    volumeDay,
    volumeTime,
  ]);

  useEffect(() => {
    if (
      isMetricCardsLoaded &&
      isTopicListLoaded &&
      isSummaryCardsLoaded &&
      isOverallConversationsLoaded &&
      isConversationTableLoaded &&
      isWordCloudLoaded &&
      isVolumeSourceLoaded &&
      isvolumeTrendlineLoaded &&
      isvolumeDayLoaded &&
      isVolumeTimeLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMetricCardsLoaded,
    isTopicListLoaded,
    isSummaryCardsLoaded,
    isOverallConversationsLoaded,
    isConversationTableLoaded,
    isWordCloudLoaded,
    isVolumeSourceLoaded,
    isvolumeTrendlineLoaded,
    isvolumeDayLoaded,
    isVolumeTimeLoaded
  ])

  let tabData = ["Summary"];

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  useEffect(() => {
    setSelectedTab("Summary");
  }, [authCtx, BRAND_ID, selectedDates, selectedPlatform]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("MI", "Social Listening", "Summary", event);
  };

  useEffect(() => {
    setExportPageName("Social Listening " + selectedPlatform + " " + selectedTab);
  }, [selectedTab, selectedPlatform]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Social Listening"} />
          <div className="page_content">
            <div className="dashboard_home">
              {metricCardsSection}
              {isMetricCardsLoaded && (
                <Wrapper>
                  <div className="grid grid_margin_bottom">
                    <Tab
                      varient={"primary"}
                      tabData={tabData}
                      activeTab={selectedTab}
                      handleCallback={(event) => handleCallback(event)}
                    />
                  </div>
                  {selectedTab.toLowerCase() === "summary" && (
                    <Wrapper>
                      <div className="section_card fullpage_section_card grid grid_margin_bottom">
                        <div className="section_card_header section_card_header_orm">
                          <h2 className="section_card_title">
                            Topics
                          </h2>
                          <div className="filters_wrapper">
                            <div className="filter form_group flex justify_end">
                              <input
                                className="form_control input"
                                type="text"
                                autoComplete="off"
                                placeholder="Search topic"
                                onChange={(event) => {
                                  setSeachTerm(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="section_card_body badges_list_wrapper_body">
                          <div className="badges_list_wrapper">
                            {topTrendingTopicList}
                          </div>
                        </div>
                      </div>
                      {summaryCardsSection}
                      <div id="Overall_Conversations" className="grid grid_cols_4 grid_margin_bottom section_card">
                        <div className="section_card_header section_card_header_orm">
                          <div className="section_info">
                            <h2 className="section_card_title">
                              Overall Conversations
                              {isSelectedTopic && " : " + isSelectedTopic + " "}
                            </h2>
                          </div>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              platform={pagePlatform}
                              sectionId={"Overall_Conversations"}
                              sectionName={"Overall Conversations"}
                              sectionData={overallConversationCircleTableCSV}
                            />
                          </div>
                        </div>
                        <div className="grid grid col_span_4 section_card_body">
                          <div className="grid col_span_3">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper  p_t_0 p_b_0">
                                {overallConversationsChart}
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_1">
                            {conversationTableData}
                          </div>
                        </div>
                      </div>

                      <div id="world_cloud_section" className="grid grid_cols_4 grid_margin_bottom section_card">
                        <div className="section_card_header">
                          <div className="section_info">
                            <h2 className="section_card_title">
                              Top Things Mentioned
                              {wordCloudDropdownOption !== "" && " : " + wordCloudDropdownOption.label + " "}
                              {isSelectedTopic && " : " + isSelectedTopic + " "}
                            </h2>
                          </div>
                          <div data-html2canvas-ignore={true}>
                            <Dropdown
                              ismulti={false}
                              placeholder={"Select"}
                              options={wordCloudOptions}
                              className="form_dropdown section_dropdown"
                              value={wordCloudDropdownOption}
                              selectedOptions={wordCloudDropdownOption}
                              setStatedropdown={wordCloudSelectionHandler}
                            />
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              platform={pagePlatform}
                              sectionId={"world_cloud_section"}
                              sectionName={`WordCloudFormat_Top Things Mentioned_${(wordCloudDropdownOption.label) + (isSelectedTopic && " - " + isSelectedTopic)}`}
                              sectionData={wordCloud}
                            />
                          </div>
                        </div>
                        <div className="grid grid_cols_1 section_card_body">
                          <div className="section_card_chart_wrapper">
                            {wordCloudChart}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid_cols_4 grid_margin_bottom">
                        <div id="mentions_volume_by_source" className="grid col_span_2 section_card">
                          <div className="section_card_header section_card_header_orm">
                            <h2 className="section_card_title">
                              Mentions Volume By Source
                              {isSelectedTopic && " : " + isSelectedTopic + " "}
                            </h2>
                            <div data-html2canvas-ignore={true}>
                              <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                platform={pagePlatform}
                                sectionId={"mentions_volume_by_source"}
                                sectionName={`OrmVolumeSource_Mentions Volume By Source_${isSelectedTopic && " : " + isSelectedTopic}`}
                                sectionData={volumeSource}
                              />
                            </div>
                          </div>
                          <div className="section_card_body">
                            <div className="section_card_chart_wrapper">
                              {volumeSourceBarChart}
                            </div>
                          </div>
                        </div>

                        <div id="mentions_volume_trendline" className="grid col_span_2 section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header section_card_header_orm">
                              <div className="section_info">
                                <h2 className="section_card_title">
                                  Mentions Volume Trendline
                                  {isSelectedTopic && " : " + isSelectedTopic + " "}
                                </h2>
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}
                                  platform={pagePlatform}
                                  sectionId={"mentions_volume_trendline"}
                                  sectionName={`DateFirstNonActiveDropdownLabels_Mentions Volume Trendline_${isSelectedTopic && " : " + isSelectedTopic}`}
                                  sectionData={combinedLineChartCSV(volumeTrendline)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {volumeTrendlineChart}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid_cols_4">
                        <div id="Mentions_Volume_By_Day_of_Week" className="grid col_span_2 section_card">
                          <div className="section_card_header section_card_header_orm">
                            <h2 className="section_card_title">
                              Mentions Volume By Day of Week
                              {isSelectedTopic && " : " + isSelectedTopic + " "}
                            </h2>
                            <div data-html2canvas-ignore={true}>
                              <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                platform={pagePlatform}
                                sectionId={"Mentions_Volume_By_Day_of_Week"}
                                sectionName={`OrmVolumeDayWeek_Mentions Volume By Day of Week_${isSelectedTopic && " : " + isSelectedTopic}`}
                                sectionData={volumeDay}
                              />
                            </div>
                          </div>
                          <div className="section_card_body">
                            <div className="section_card_chart_wrapper">
                              {volumeDayChart}
                            </div>
                          </div>
                        </div>
                        <div id="Mentions_Volume_By_Time_of_the_Day" className="grid col_span_2 section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header section_card_header_orm">
                              <div className="section_info">
                                <h2 className="section_card_title">
                                  Mentions Volume By Time of the Day
                                  {isSelectedTopic && " : " + isSelectedTopic + " "}
                                </h2>
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  moduleName={pageModule}
                                  subModuleName={pageSubModule}
                                  platform={pagePlatform}
                                  sectionId={"Mentions_Volume_By_Time_of_the_Day"}
                                  sectionName={`OrmVolumeTimeDay_Mentions Volume By Time of Day_${isSelectedTopic && " : " + isSelectedTopic}`}
                                  sectionData={volumeTime}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {volumeTimeBarChart}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                  )}
                  {selectedTab.toLowerCase() === "content" && (
                    <Wrapper>
                      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card flex justify_center align_center ">
                        <h3 className="coming_soon">Coming Soon...</h3>
                      </div>
                    </Wrapper>
                  )}
                  {selectedTab.toLowerCase() === "audience demographics" && (
                    <Wrapper>
                      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card flex justify_center align_center ">
                        <h3 className="coming_soon">Coming Soon...</h3>
                      </div>
                    </Wrapper>
                  )}
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div >
    </Wrapper >
  );
};
export default Orm;
