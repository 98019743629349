// import { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { SwiperSlide } from "swiper/react";

// // STORAGE
// import AuthContext from "../../../../store/authContext";
// import FullPageExportContext from "../../../../store/fullPageExportContext";

// // LOADERS
// import Loader from "../../../../components/ui/loaders/Loader";
// import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
// import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// // COMPONENTS
// import axios from "../../../../components/helpers/axios";
// import Wrapper from "../../../../components/helpers/Wrapper";
// import Card from "../../../../components/ui/Card";
// import Slider from "../../../../components/ui/Slider";
// import Dropdown from "../../../../components/ui/dropdown/Dropdown";
// import Datatable from "../../../../components/ui/tables/Datatable";
// import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
// import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// // CHARTS
// import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
// import LineChart from "../../../../components/ui/charts/LineChart";
// import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// // UTILS
// import ErrorBoundary from "../../../../utils/ErrorBoundary";
// import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
// import NumberFormatter from "../../../../utils/NumberFormatter";
// import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
// import { COKE_COMPANY_ID } from "../../../../constants/constants";

// const CampaignLinkedinOverall = ({ topCardData }) => {

//   // PAGE CONSTANTS
//   let pageModule = "Marketing Intelligence"
//   let pageSubModule = "Campaign"
//   let pagePlatform = "Linkedin"
//   let pagePrimaryTab = "Overall"

//   const authCtx = useContext(AuthContext);
//   const { setFullPageExport, setFullPageExportHeader } = useContext(
//     FullPageExportContext
//   );

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user);
//   let companyID = userData?.company?.id;

//   // SELECTED BRANDS FROM DROPDOWN
//   const globalSelectedBrandIds = useSelector(
//     (state) => state.globalSelectedBrandIds.selectedBrandIds
//   );

//   let BRAND_ID = null;
//   if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
//     BRAND_ID = null;
//   } else {
//     BRAND_ID = globalSelectedBrandIds.join(",");
//   }

//   // GLOBAL CAMPAIGN DROPDOWN
//   const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
//   const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
//   const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
//   const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
//   const selectedCampaignIdandPlatform = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
//   );

//   let selectedIdandPlatform = null;
//   if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
//     selectedIdandPlatform = null;
//   } else {
//     selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
//   }

//   // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
//   let selectedPlatform = null;
//   if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
//     selectedPlatform = null;
//   } else {
//     selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
//   }

//   // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
//   let selectedObjectives = null;
//   if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
//     selectedObjectives = null;
//   } else {
//     selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
//   }

//   // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
//   let selectedCampaigns = null;
//   if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
//     selectedCampaigns = null;
//   } else {
//     selectedCampaigns = selectedCampaignsFromFilter.join(',');
//   }

//   // currentDate / endDate / prevCurrentDate / prevEndDate
//   const selectedDates = useSelector((state) => state.date);

//   // METRIC CARDS
//   const [metricCards, setMetricCards] = useState([]);
//   const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
//   const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
//   const [metricCardserror, setMetricCardsError] = useState(null);

//   // // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
//   // const [
//   //   campaignObjectivewiseDistribution,
//   //   setCampaignObjectivewiseDistribution,
//   // ] = useState([]);
//   // const [
//   //   campaignObjectivewiseDistributionDataLabels,
//   //   setCampaignObjectivewiseDistributionDataLabels,
//   // ] = useState([]);
//   // const [
//   //   isCampaignObjectivewiseDistributionisLoaing,
//   //   setCampaignObjectivewiseDistributionIsLoading,
//   // ] = useState(false);
//   // const [
//   //   isCampaignObjectivewiseDistributionisLoaded,
//   //   setCampaignObjectivewiseDistributionIsLoaded,
//   // ] = useState(false);
//   // const [
//   //   campaignObjectivewiseDistributionerror,
//   //   setCampaignObjectivewiseDistributionError,
//   // ] = useState(null);

//   // MID SECTION DROPDOWN

//   // DROPDOWN OPTIONS
//   const midSectionDropdonwOptions = [
//     { value: "impressions", label: "Impressions" },
//     { value: "clicks", label: "Clicks" },
//     { value: "conversions", label: "Conversions" },
//     { value: "engagements", label: "Engagements" },
//     { value: "spends", label: "Spends" },
//   ];
//   // DROPDOWN DEFAULT STATE
//   const [
//     selectedMidSectionDropdownOption,
//     setSelectedMidSectionDropdownDropdownOption,
//   ] = useState(midSectionDropdonwOptions[0]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const midSectionDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMidSectionDropdownDropdownOption("");
//     } else {
//       setSelectedMidSectionDropdownDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Audience Distribution", e.label)

//   };

//   // PERFORMANCE
//   const [performance, setPerformance] = useState([]);
//   const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
//     useState([]);
//   const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
//   const [
//     performanceAllDropdownsDataLabels,
//     setPerformanceAllDropdownsDataLabels,
//   ] = useState([]);
//   const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
//   const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
//   const [performanceerror, setPerformanceError] = useState(null);

//   // PERFORMANCE BY SOURCE DETAILS
//   const [performanceDetails, setPerformanceDetails] = useState([]);

//   // COUNTRY DATA
//   const [countryData, setCountryData] = useState([]);
//   const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
//   const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
//   const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
//   const [countryDataerror, setCountryDataError] = useState(null);

//   // COUNTRY DATA TABLE HEADERS
//   const CountryTableDataHeaders = [
//     { name: "Country", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" },
//   ];

//   // CITY
//   const [cityData, setCityData] = useState([]);
//   const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
//   const [isCityDataLoading, setCityDataIsLoading] = useState(false);
//   const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
//   const [cityDataerror, setCityDataError] = useState(null);

//   // CITY DATA TABLE HEADERS
//   const CityTableDataHeaders = [
//     { name: "City", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" },
//   ];

//   // CAMPAIGN SUMMARY
//   const [campaignSummary, setCampaignSummary] = useState([]);
//   const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
//     useState([]);
//   const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
//     useState(false);
//   const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
//   const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

//   // TABLE HEADERS FOR COKE
//   let cokeSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "View Rate", field: "view_rate" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Engagement Rate", field: "engagement_rate" }
//   ]

//   let defaultTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "Engagement Rate", field: "engagement_rate" }
//   ]

//   // CAMPAIGN SUMMARY TABLE HEADERS
//   const campaignSummaryTableHeaders = companyID === COKE_COMPANY_ID ? cokeSummaryTableHeaders : defaultTableHeaders;

//   // DEVICE DISTRIBUTION DROPDOWN
//   // DEVICE DISTRIBUTION DROPDOWN OPTIONS
//   const campaignSummaryOptions = [
//     { value: "campaign", label: "Campaign" },
//     { value: "campaign_group", label: "Campaign Group" },
//     { value: "ad", label: "Ad" },
//   ];
//   // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
//   const [
//     selectedCampaignSummaryDropdownOption,
//     setSelectedCampaignSummaryDropdownOption,
//   ] = useState(campaignSummaryOptions[0]);


//   // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
//   const campaignSummaryDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedCampaignSummaryDropdownOption("");
//     } else {
//       setSelectedCampaignSummaryDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Summary", e.label)

//   };

//   // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
//   const fetchCampaignSummaryDropdownHandler = async () => {
//     if (selectedCampaignSummaryDropdownOption.value === "ad") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.ad);
//     } else if (
//       selectedCampaignSummaryDropdownOption.value === "campaign_group"
//     ) {
//       setCampaignSummary(campaignSummaryAllDropdownsData.campaign_group);
//     } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
//     } else {
//       setCampaignSummary([]);
//     }
//   };

//   // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchCampaignSummaryDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
//   useEffect(() => {
//     fetchMetricCardsHandler();
//     // fetchCampaignObjectivewiseDistributionHandler();
//     fetchPerformanceHandler();
//     fetchCountryHandler();
//     fetchCityHandler();
//     fetchCampaignSummaryHandler();
//     fetchChildDonutCampaignHandler();
//   }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
//   const fetchMidSectionDropdownHandler = async () => {
//     if (selectedMidSectionDropdownOption.value === "clicks") {
//       setPerformance(
//         performanceAllDropdownsData.length === 0
//           ? []
//           : performanceAllDropdownsData.clicks
//       );
//       setPerformanceDataLabels(
//         performanceAllDropdownsDataLabels.length === 0
//           ? []
//           : performanceAllDropdownsDataLabels.clicks
//       );
//       setCountryData(
//         countryAllDropdownsData.length === 0
//           ? []
//           : countryAllDropdownsData.clicks
//       );
//       setCityData(
//         cityAllDropdownsData.length === 0 ? [] : cityAllDropdownsData.clicks
//       );
//     } else if (selectedMidSectionDropdownOption.value === "conversions") {
//       setPerformance(
//         performanceAllDropdownsData.length === 0
//           ? []
//           : performanceAllDropdownsData.conversions
//       );
//       setPerformanceDataLabels(
//         performanceAllDropdownsDataLabels.length === 0
//           ? []
//           : performanceAllDropdownsDataLabels.conversions
//       );
//       setCountryData(
//         countryAllDropdownsData.length === 0
//           ? []
//           : countryAllDropdownsData.conversions
//       );
//       setCityData(
//         cityAllDropdownsData.length === 0
//           ? []
//           : cityAllDropdownsData.conversions
//       );
//     } else if (selectedMidSectionDropdownOption.value === "engagements") {
//       setPerformance(
//         performanceAllDropdownsData.length === 0
//           ? []
//           : performanceAllDropdownsData.engagement
//       );
//       setPerformanceDataLabels(
//         performanceAllDropdownsDataLabels.length === 0
//           ? []
//           : performanceAllDropdownsDataLabels.engagement
//       );
//       setCountryData(
//         countryAllDropdownsData.length === 0
//           ? []
//           : countryAllDropdownsData.engagements
//       );
//       setCityData(
//         cityAllDropdownsData.length === 0
//           ? []
//           : cityAllDropdownsData.engagements
//       );
//     } else if (selectedMidSectionDropdownOption.value === "impressions") {
//       setPerformance(
//         performanceAllDropdownsData.length === 0
//           ? []
//           : performanceAllDropdownsData.impressions
//       );
//       setPerformanceDataLabels(
//         performanceAllDropdownsDataLabels.length === 0
//           ? []
//           : performanceAllDropdownsDataLabels.impressions
//       );
//       setCountryData(
//         countryAllDropdownsData.length === 0
//           ? []
//           : countryAllDropdownsData.impressions
//       );
//       setCityData(
//         cityAllDropdownsData.length === 0
//           ? []
//           : cityAllDropdownsData.impressions
//       );
//     } else if (selectedMidSectionDropdownOption.value === "spends") {
//       setPerformance(
//         performanceAllDropdownsData.length === 0
//           ? []
//           : performanceAllDropdownsData.spends
//       );
//       setPerformanceDataLabels(
//         performanceAllDropdownsDataLabels.length === 0
//           ? []
//           : performanceAllDropdownsDataLabels.spends
//       );
//       setCountryData(
//         countryAllDropdownsData.length === 0
//           ? []
//           : countryAllDropdownsData.spends
//       );
//       setCityData(
//         cityAllDropdownsData.length === 0 ? [] : cityAllDropdownsData.spends
//       );
//     } else {
//       setPerformance([]);
//       setPerformanceDataLabels([]);
//       setCountryData([]);
//       setCityData([]);
//     }
//   };

//   // CHNAGE  DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchMidSectionDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // METRIC CARDS

//   // TO FETCH METRIC CARDS FROM API
//   const fetchMetricCardsHandler = async () => {
//     setMetricCardsIsLoading(true);
//     setMetricCardsIsLoaded(false);
//     setMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/linkedin/ad-metric/", config);
//       setMetricCards(res.data === undefined || res.data.length === 0 ? [] :
//         res.data.data === undefined || res.data.data.length === 0 ? [] : res.data.data);

//       /*  setMetricCards(res.data === undefined  ? [] : res.data.data); */
//       // setMetricCards(LinkedinMetricCardsSampleData);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(true);
//       setMetricCardsError(null);
//     } catch (error) {
//       setMetricCards([]);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(false);
//       setMetricCardsError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let metricCardsSection = (
//     <Wrapper>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div className="grid col_span_4 section_header">
//           <div className="section_info">  <h2 className="section_card_title">Summary
//           </h2></div>
//         </div>
//         <div className="grid col_span_4 grid_margin_bottom">
//           <Loader loaderType="cardLoader" />
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isMetricCardsLoaded && !isMetricCardsLoading) {
//     if (NoDataAvailableChecker(metricCards)) {
//       metricCardsSection = <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <NoDataAvailableLoader chartType="cardChartType" />
//           </div>
//         </div>
//       </Wrapper>;
//     } else {
//       metricCardsSection = (
//         <Wrapper>
//           <ErrorBoundary chartType="cardChartType">
//             <Slider sliderTitle={"Summary"}>
//               {metricCards.map((data, index) => (
//                 <SwiperSlide key={index}>
//                   <Card
//                     module="campaign"
//                     platform="linkedin"
//                     section="overall"
//                     cardTitle={data.label}
//                     currentValue={data.currentValue}
//                     previousValue={data.previousValue}
//                     rowOneTitle={"Previous"}
//                     numberVariant={"number"}
//                   />
//                 </SwiperSlide>
//               ))}
//             </Slider>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (metricCardserror) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <ServerErrorsLoader
//               chartType="cardChartType"
//               error={metricCardserror}
//             />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isMetricCardsLoading) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <Loader loaderType="cardLoader" />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

//   // // TO FETCH DATA FROM API
//   // const fetchCampaignObjectivewiseDistributionHandler = async () => {
//   //   setCampaignObjectivewiseDistributionIsLoading(true);
//   //   setCampaignObjectivewiseDistributionIsLoaded(false);
//   //   setCampaignObjectivewiseDistributionError(null);

//   //   const config = {
//   //     params: {
//   //       brand_id: BRAND_ID,
//   //       start_date: selectedDates.startDate,
//   //       end_date: selectedDates.endDate,
//   //       previous_start_date: selectedDates.prevStartDate,
//   //       previous_end_date: selectedDates.prevEndDate,
//   //       objective: selectedObjectives,
//   //       platform_campaign:selectedIdandPlatform
//   //       platform: selectedPlatform,
//   //       campaign: selectedCampaigns,
//   //         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//   //     },
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //       Authorization: "Bearer " + authCtx.token,
//   //     },
//   //   };

//   //   try {
//   //     const res = await axios.get(
//   //       "performance/linkedin/objective-distribution/",
//   //       config
//   //     );

//   //     setCampaignObjectivewiseDistribution(
//   //       res.data === undefined || res.data.length === 0 ? [] :
//   //         res.data[0] === undefined || res.data[0].length === 0 ? [] :
//   //           res.data[0].impressions === undefined || res.data[0].impressions.length === 0 ? [] :
//   //             res.data[0].impressions.data === undefined || res.data[0].impressions.data.length === 0 ? [] :
//   //               res.data[0].impressions.data
//   //     );


//   //     /*  setCampaignObjectivewiseDistribution(
//   //        res.data === undefined  ? [] : res.data[0].impressions.data
//   //      ); */

//   //     setCampaignObjectivewiseDistributionDataLabels(
//   //       res.data === undefined || res.data.length === 0 ? [] :
//   //         res.data[0] === undefined || res.data[0].length === 0 ? [] :
//   //           res.data[0].impressions === undefined || res.data[0].impressions.length === 0 ? [] :
//   //             res.data[0].impressions.label === undefined || res.data[0].impressions.label.length === 0 ? [] :
//   //               res.data[0].impressions.label
//   //     );
//   //     /*  setCampaignObjectivewiseDistributionDataLabels(
//   //        res.data === undefined  ? [] : res.data[0].impressions.label
//   //      ); */

//   //     // setCampaignObjectivewiseDistribution(
//   //     //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
//   //     // );
//   //     // setCampaignObjectivewiseDistributionDataLabels(
//   //     //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
//   //     // );
//   //     setCampaignObjectivewiseDistributionIsLoading(false);
//   //     setCampaignObjectivewiseDistributionIsLoaded(true);
//   //     setCampaignObjectivewiseDistributionError(null);
//   //   } catch (error) {
//   //     setCampaignObjectivewiseDistribution([]);
//   //     setCampaignObjectivewiseDistributionDataLabels([]);
//   //     setCampaignObjectivewiseDistributionIsLoading(false);
//   //     setCampaignObjectivewiseDistributionIsLoaded(false);
//   //     setCampaignObjectivewiseDistributionError(error.code);
//   //   }
//   // };

//   // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   // let campaignObjectivewiseDistributionPie = (
//   //   <Wrapper>
//   //     <Loader loaderType="pieChartLoader" />
//   //   </Wrapper>
//   // );

//   // // IF DATA IS LOADED
//   // if (isCampaignObjectivewiseDistributionisLoaded && !isCampaignObjectivewiseDistributionisLoaing) {
//   //   if (
//   //     NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
//   //     NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
//   //   ) {
//   //     campaignObjectivewiseDistributionPie = (
//   //       <Wrapper>
//   //         <NoDataAvailableLoader chartType="pieChartType" />
//   //       </Wrapper>
//   //     );
//   //   } else {
//   //     campaignObjectivewiseDistributionPie = (
//   //       <Wrapper>
//   //         <ErrorBoundary chartType="pieChartType">
//   //           <PieofaPieChart
//   //             chartId="content_analysis"
//   //             chartClass="section_card_chart"
//   //             chartData={campaignObjectivewiseDistribution}
//   //             chartLabels={campaignObjectivewiseDistributionDataLabels}
//   //           />
//   //         </ErrorBoundary>
//   //       </Wrapper>
//   //     );
//   //   }
//   // }

//   // // IF THERE IS SOME ERROR FROM SERVER
//   // if (campaignObjectivewiseDistributionerror) {
//   //   campaignObjectivewiseDistributionPie = (
//   //     <Wrapper>
//   //       <ServerErrorsLoader
//   //         chartType="pieChartType"
//   //         error={campaignObjectivewiseDistributionerror}
//   //       />
//   //     </Wrapper>
//   //   );
//   // }

//   // // IF DATA LOADING
//   // if (isCampaignObjectivewiseDistributionisLoaing) {
//   //   campaignObjectivewiseDistributionPie = <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>;
//   // }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // PERFORMANCE

//   // TO FETCH DATA FROM API
//   const fetchPerformanceHandler = async () => {
//     setPerformanceIsLoading(true);
//     setPerformanceIsLoaded(false);
//     setPerformanceError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/linkedin/metric-by-date/",
//         config
//       );
//       setPerformance(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
//             res.data.metric_list.data === undefined || res.data.metric_list.data.length === 0 ? [] :
//               res.data.metric_list.data.impressions === undefined || res.data.metric_list.data.impressions.length === 0 ? [] :
//                 res.data.metric_list.data.impressions
//       );
//       /*  setPerformance(
//          res.data === undefined  ? [] : res.data.metric_list.data.impressions
//        ); */


//       setPerformanceDataLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
//             res.data.metric_list.label === undefined || res.data.metric_list.label.length === 0 ? [] :
//               res.data.metric_list.label.impressions === undefined || res.data.metric_list.label.impressions.length === 0 ? [] :
//                 res.data.metric_list.label.impressions
//       );

//       /*   setPerformanceDataLabels(
//           res.data === undefined  ? [] : res.data.metric_list.label.impressions
//         ); */


//       setPerformanceAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
//             res.data.metric_list.data === undefined || res.data.metric_list.data.length === 0 ? [] :
//               res.data.metric_list.data
//       );
//       /*   setPerformanceAllDropdownsData(
//           res.data === undefined  ? [] : res.data.metric_list.data
//         ); */

//       setPerformanceAllDropdownsDataLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
//             res.data.metric_list.label === undefined || res.data.metric_list.label.length === 0 ? [] :
//               res.data.metric_list.label
//       );

//       /*   setPerformanceAllDropdownsDataLabels(
//           res.data === undefined  ? [] : res.data.metric_list.label
//         ); */

//       setPerformanceDetails(res.data === undefined || res.data.length === 0 ? [] :
//         res.data.metrics === undefined || res.data.metrics.length === 0 ? [] : res.data.metrics);

//       /*  setPerformanceDetails(res.data === undefined  ? [] : res.data.metrics);  */

//       //   setPerformanceAllDropdownsData(LiknedinPerformanceSampleData);
//       //   setPerformanceAllDropdownsDataLabels(LiknedinPerformanceSampleDataLabels);
//       setPerformanceIsLoading(false);
//       setPerformanceIsLoaded(true);
//       setPerformanceError(null);
//     } catch (error) {
//       setPerformanceAllDropdownsData([]);
//       setPerformanceAllDropdownsDataLabels([]);
//       setPerformanceDetails([]);
//       setPerformanceIsLoading(false);
//       setPerformanceIsLoaded(false);
//       setPerformanceError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let performanceChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

//   // IF DATA IS LOADED
//   if (isPerformanceLoaded && !isPerformanceLoading) {
//     if (
//       NoDataAvailableChecker(performance) ||
//       NoDataAvailableChecker(performanceDataLabels)
//     ) {
//       performanceChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
//     } else {
//       performanceChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="lineChartType">
//             <LineChart
//               chartId="performance_by_source"
//               chartClass="section_card_chart"
//               chartData={performance}
//               chartLabels={performanceDataLabels}
//               showLegend={true}
//               showLabels={true}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (performanceerror) {
//     performanceChart = (
//       <Wrapper>  <ServerErrorsLoader chartType="lineChartType" error={performanceerror} /></Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isPerformanceLoading) {
//     performanceChart = <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>;
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // PERFORMANCE DETAILS

//   // DATA HANDLING
//   let performanceDetailsData = (
//     <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isPerformanceLoaded && !isPerformanceLoading) {
//     if (
//       NoDataAvailableChecker(performanceDetails)
//     ) {
//       performanceDetailsData = (
//         <Wrapper>   <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
//       );
//     } else {
//       performanceDetailsData = (
//         <Wrapper>
//           <table className="table table_bordered">
//             <tbody>
//               {performanceDetails.map((details, postImpressionIndex) => {
//                 return (
//                   <Wrapper key={postImpressionIndex}>
//                     <tr>
//                       <td>{details.label}</td>
//                       <td className="bold">
//                         <NumberFormatter number={details.value} />
//                       </td>
//                     </tr>
//                   </Wrapper>
//                 );
//               })}
//             </tbody>
//           </table>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (performanceerror) {
//     performanceDetailsData = (
//       <Wrapper> <ServerErrorsLoader chartType="tableChartType" error={performanceerror} /></Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isPerformanceLoading) {
//     performanceDetailsData = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // COUNTRY MAP

//   const fetchCountryHandler = async () => {
//     setCountryDataIsLoading(true);
//     setCountryDataIsLoaded(false);
//     setCountryDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/linkedin/country/", config);

//       setCountryData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data.impressions === undefined ||
//               res.data.data.impressions.length === 0
//               ? []
//               : res.data.data.impressions
//       );

//       setCountryAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(true);
//       setCountryDataError(null);
//     } catch (error) {
//       setCountryAllDropdownsData([]);
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(false);
//       setCountryDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let countryMap = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card ">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By Country</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCountryDataLoaded && !isCountryDataLoading) {
//     if (
//       NoDataAvailableChecker(countryData) ||
//       NoDataAvailableChecker(CountryTableDataHeaders)
//     ) {
//       countryMap = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By Country</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       countryMap = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By Country"
//               isHalfSection={true}
//               isCountryFlagVisible={true}
//               tableHeader={CountryTableDataHeaders}
//               tableData={countryData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Country"}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (countryDataerror) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCountryDataLoading) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CITY MAP

//   const fetchCityHandler = async () => {
//     setCityDataIsLoading(true);
//     setCityDataIsLoaded(false);
//     setCityDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/linkedin/region/", config);

//       setCityData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data.impressions === undefined ||
//               res.data.data.impressions.length === 0
//               ? []
//               : res.data.data.impressions
//       );
//       setCityAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(true);
//       setCityDataError(null);
//     } catch (error) {
//       setCityAllDropdownsData([]);
//       setCityDataIsLoading(false);
//       setCityDataIsLoaded(false);
//       setCityDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let cityMap = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card ">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By City</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCityDataLoaded && !isCityDataLoading) {
//     if (
//       NoDataAvailableChecker(cityData) ||
//       NoDataAvailableChecker(CityTableDataHeaders)
//     ) {
//       cityMap = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By City</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       cityMap = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By City"
//               isHalfSection={true}
//               isDynamicFlagVisible={true}
//               tableHeader={CityTableDataHeaders}
//               tableData={cityData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search City"}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (cityDataerror) {
//     cityMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCityDataLoading) {
//     cityMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By City</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN SUMMARY

//   // TO FETCH METRIC CARDS FROM API
//   const fetchCampaignSummaryHandler = async () => {
//     setCampaignSummaryIsLoading(true);
//     setCampaignSummaryIsLoaded(false);
//     setCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/linkedin/summary-datatable/",
//         config
//       );


//       setCampaignSummary(res.data === undefined || res.data.length === 0 ? [] :
//         res.data.campaign === undefined || res.data.campaign.length === 0 ? [] : res.data.campaign);

//       /* setCampaignSummary(res.data === undefined  ? [] : res.data.campaign); */


//       setCampaignSummaryAllDropdownsData(res.data === undefined || res.data.length === 0 ? [] : res.data);


//       /* setCampaignSummaryAllDropdownsData(res.data === undefined  ? [] : res.data); */

//       //   setCampaignSummaryAllDropdownsData(LinkedinCampaignSummarySampleData);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(true);
//       setCampaignSummaryError(null);
//     } catch (error) {
//       setCampaignSummaryAllDropdownsData([]);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(false);
//       setCampaignSummaryError(error.code);
//     }
//   };

//   let campaignSummaryTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Summary
//               <SectionDefinationsBadge
//                 title={"Summary"}
//                 module={pageSubModule}
//                 platform={pagePlatform}
//                 section={pagePrimaryTab}
//               />
//             </h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   )
//   // IF DATA IS LOADED
//   if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
//     if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
//       campaignSummaryTable =
//         (
//           <Wrapper>
//             <div className="grid grid_cols_4 fullpage_section_card section_card">
//               <div className="grid col_span_4">
//                 <div className="section_card_header">
//                   <h2 className="section_card_title">Summary
//                     <SectionDefinationsBadge
//                       title={"Summary"}
//                       module={pageSubModule}
//                       platform={pagePlatform}
//                       section={pagePrimaryTab}
//                     />
//                   </h2>
//                 </div>
//               </div>
//               <div className="grid col_span_4">
//                 <div className="section_card_body">
//                   <NoDataAvailableLoader chartType="tableChartType" />
//                 </div>
//               </div>
//             </div>
//           </Wrapper>
//         )

//     } else {
//       campaignSummaryTable = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="Summary"
//               tablePagePlatform={pagePlatform}
//               tablePageSubModule={pageSubModule}
//               tablePagePrimaryTab={pagePrimaryTab}
//               tableHeader={campaignSummaryTableHeaders}
//               tableData={campaignSummary}
//               tableLength={10}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Campaign"}
//               tableDropdown={
//                 <Dropdown
//                   ismulti={false}
//                   placeholder={"Select"}
//                   options={campaignSummaryOptions}
//                   className="form_dropdown section_dropdown"
//                   value={selectedCampaignSummaryDropdownOption}
//                   selectedOptions={selectedCampaignSummaryDropdownOption}
//                   setStatedropdown={campaignSummaryDropdownSelectionHandler}
//                 />
//               }
//               tableID={"search_campaign_section"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"search_campaign_section"}
//                   sectionName={`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
//                   sectionData={campaignSummary}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (campaignSummaryerror) {
//     campaignSummaryTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary
//                 <SectionDefinationsBadge
//                   title={"Summary"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCampaignSummaryLoading) {
//     campaignSummaryTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary
//                 <SectionDefinationsBadge
//                   title={"Summary"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     )

//   }

//   // CHILD DONUT CAMPAIGN OBJECTIVE

//   const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
//     useState([]);
//   const [childDonutSliceToggle, setChildDonutSliceToggle] = useState("");

//   const [
//     childDonutCampaignObjectiveLoading,
//     setChildDonutCampaignObjectiveLoading,
//   ] = useState([]);
//   const [
//     childDonutCampaignObjectiveLoaded,
//     setChildDonutCampaignObjectiveLoaded,
//   ] = useState([]);
//   const [
//     childDonutCampaignObjectiveError,
//     setChildDonutCampaignObjectiveError,
//   ] = useState([]);

//   const fetchChildDonutCampaignHandler = async () => {
//     setChildDonutCampaignObjectiveLoading(true);
//     setChildDonutCampaignObjectiveLoaded(false);
//     setChildDonutCampaignObjectiveError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/linkedin/objective-distribution/",
//         config
//       );
//       setChildDonutCampaignObjective(res.data === undefined || res.data.length === 0 ? [] :
//         res.data);

//       /*  setChildDonutCampaignObjective(res.data.length === 0 ? [] : res.data);*/

//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(true);
//       setChildDonutCampaignObjectiveError(null);
//     } catch (error) {
//       setChildDonutCampaignObjective([]);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(false);
//       setChildDonutCampaignObjectiveError(error.code);
//     }
//   };

//   let childDonutCampaignChart = (
//     <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     childDonutCampaignObjectiveLoaded &&
//     !childDonutCampaignObjectiveLoading
//   ) {
//     if (NoDataAvailableChecker(childDonutCampaignObjective)) {
//       childDonutCampaignChart = (
//         <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
//       );
//     } else {
//       childDonutCampaignChart = (
//         <Wrapper>  <ErrorBoundary chartType="donutChartType">
//           <DonutPieChart
//             chartClass="section_card_chart"
//             chartData={childDonutCampaignObjective}
//             chartId="CampaignDonutChild"
//             onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
//           />
//         </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (childDonutCampaignObjectiveError) {
//     childDonutCampaignChart = (
//       <Wrapper>   <ServerErrorsLoader
//         chartType="donutChartType"
//         error={childDonutCampaignObjectiveError}
//       />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (childDonutCampaignObjectiveLoading) {
//     childDonutCampaignChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
//   }

//   // CHILD DONUT CAMPAIGN OBJECTIVE

//   const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
//     []
//   );
//   const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
//     useState([]);
//   const [
//     lineChartCampaignObjectiveLoading,
//     setLineChartCampaignObjectiveLoading,
//   ] = useState([]);
//   const [
//     lineChartCampaignObjectiveLoaded,
//     setLineChartCampaignObjectiveLoaded,
//   ] = useState([]);
//   const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
//     useState([]);

//   const fetchLineChartCampaignHandler = async () => {
//     setLineChartCampaignObjectiveLoading(true);
//     setLineChartCampaignObjectiveLoaded(false);
//     setLineChartCampaignObjectiveError(null);

//     var objectiveConfig = (function () {
//       if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
//         return { "objective": null }
//       } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
//         return { "objective": selectedObjectives }
//       } else {
//         return { "objective": childDonutSliceToggle }
//       }
//     })()

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         // objective: childDonutSliceToggle,
//         // objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//         ...objectiveConfig
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/linkedin/objective-linechart/",
//         config
//       );

//       setLineChartCampaignObjective(res.data === undefined || res.data.length === 0 ? [] :
//         res.data.data === undefined || res.data.data.length === 0 ? [] : res.data.data);

//       /* setLineChartCampaignObjective(res.data === undefined  ? [] : res.data.data);
//  */
//       setLineChartCampaignObjectiveLabel(res.data === undefined || res.data.length === 0 ? [] :
//         res.data.dimension === undefined || res.data.dimension.length === 0 ? [] : res.data.dimension);


//       /*   setLineChartCampaignObjectiveLabel(
//           res.data.length === 0 ? [] : res.data.dimension
//         );
//    */

//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(true);
//       setLineChartCampaignObjectiveError(null);
//     } catch (error) {
//       setLineChartCampaignObjective([]);
//       setLineChartCampaignObjectiveLabel([]);
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(false);
//       setLineChartCampaignObjectiveError(error.code);
//     }
//   };

//   let lineChartCampaignSection = (
//     <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
//     if (
//       NoDataAvailableChecker(lineChartCampaignObjective) ||
//       NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
//     ) {
//       lineChartCampaignSection = (
//         <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//     } else {
//       lineChartCampaignSection = (
//         <Wrapper>   <ErrorBoundary chartType="lineChartType">
//           <LineChart
//             chartClass="section_card_chart"
//             chartData={lineChartCampaignObjective}
//             chartId="multiLineChart"
//             chartLabels={lineChartCampaignObjectiveLabel}
//             // onBulletsClick={function noRefCheck() { }}
//             showLabels
//             showLegend
//           />
//         </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (lineChartCampaignObjectiveError) {
//     lineChartCampaignSection = (
//       <Wrapper> <ServerErrorsLoader
//         chartType="lineChartType"
//         error={lineChartCampaignObjectiveError}
//       />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (lineChartCampaignObjectiveLoading) {
//     lineChartCampaignSection = <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>;
//   }

//   useEffect(() => {
//     fetchLineChartCampaignHandler();
//   }, [BRAND_ID, childDonutSliceToggle, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   useEffect(() => {
//     setChildDonutSliceToggle(null)
//   }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   var campaignObjectCSV = {
//     "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
//     [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
//   };

//   var cityCountryData = {
//     [`CityChartDynaLabels_Audience Distribution - City_${selectedMidSectionDropdownOption.value}`]: NoDataAvailableChecker(cityData) ? [] : cityData,
//     [`CountryChartDynaLabels_Audience Distribution - Country_${selectedMidSectionDropdownOption.value}`]: NoDataAvailableChecker(countryData) ? [] : countryData,
//   };

//   var allData = {
//     ...topCardData,
//     "SummaryCard_Summary Card": metricCards,
//     ...campaignObjectCSV,
//     ...cityCountryData,
//     [`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]: campaignSummary,
//   };

//   useEffect(() => {
//     setFullPageExport(allData);

//   }, [
//     metricCards,
//     childDonutCampaignObjective,
//     lineChartCampaignObjective,
//     cityData,
//     countryData,
//     campaignSummary,
//   ]);

//   return (
//     <Wrapper>
//       {metricCardsSection}
//       <div
//         id="campaign_objective"
//         className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
//       >
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">   <h2 className="section_card_title">Objective Wise Distribution
//             </h2>  <SectionDefinationsBadge
//                 title={"Objective Wise Distribution"}
//                 module="campaign"
//                 platform="linkedin"
//                 section="overall"
//               />
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"campaign_objective"}
//                 sectionName={" Campaign Objective Wise distribution"}
//                 sectionData={campaignObjectCSV}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_1 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {childDonutCampaignChart}
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_3 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {lineChartCampaignSection}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         id="audience_distribution_place"
//         className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
//       >
//         <div className="grid grid_cols_1">
//           <div className="section_card_header">
//             <div className="section_info">  <h2 className="section_card_title">
//               Audience Distribution : {selectedMidSectionDropdownOption.label}
//             </h2> <SectionDefinationsBadge
//                 title={"Audience Distribution"}
//                 module="campaign"
//                 platform="linkedin"
//                 section="overall"
//               />
//             </div>
//             <div data-html2canvas-ignore={true}>
//               <Dropdown
//                 ismulti={false}
//                 placeholder={"Select"}
//                 options={midSectionDropdonwOptions}
//                 className="form_dropdown section_dropdown"
//                 value={selectedMidSectionDropdownOption}
//                 selectedOptions={selectedMidSectionDropdownOption}
//                 setStatedropdown={midSectionDropdownSelectionHandler}
//               />

//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"audience_distribution_place"}
//                 sectionName={"Audience Distribution"}
//                 sectionData={cityCountryData}

//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_2 card_border_right flex column">
//             {/* <div className="section_card_header">
//               <h2 className="section_card_title">By Country

//               </h2>
//             </div> */}
//             {/* <div className="grid grid_cols_1 section_card_body"> */}
//             {countryMap}
//             {/* </div> */}
//           </div>
//           <div className="grid col_span_2 flex column">
//             {/* <div className="section_card_header">
//               <h2 className="section_card_title">By City

//               </h2>
//             </div> */}
//             {/* <div className="grid grid_cols_1 section_card_body"> */}
//             {cityMap}
//             {/* </div> */}
//           </div>
//         </div>
//       </div>
//       {/* <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//           <div className="section_info">   <h2 className="section_card_title">Summary
//              </h2>  <SectionDefinationsBadge
//                 title={"Summary"}
//                 module="campaign"
//                 platform="linkedin"
//                 section="overall"
//               />
//            </div>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body"> */}
//       {campaignSummaryTable}
//       {/* </div>
//         </div>
//       </div> */}
//     </Wrapper>
//   );
// };

// export default CampaignLinkedinOverall;

import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// CHARTS
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import NumberFormatter from "../../../../utils/NumberFormatter";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import { API_URL, COKE_COMPANY_ID, IMPRIVATA_COMPANY_ID } from "../../../../constants/constants";
import { getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import PaginatedDatatable from "../../../../components/ui/tables/PaginatedDatatable";

const CampaignLinkedinOverall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Linkedin"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  // const [
  //   campaignObjectivewiseDistribution,
  //   setCampaignObjectivewiseDistribution,
  // ] = useState([]);
  // const [
  //   campaignObjectivewiseDistributionDataLabels,
  //   setCampaignObjectivewiseDistributionDataLabels,
  // ] = useState([]);
  // const [
  //   isCampaignObjectivewiseDistributionisLoaing,
  //   setCampaignObjectivewiseDistributionIsLoading,
  // ] = useState(false);
  // const [
  //   isCampaignObjectivewiseDistributionisLoaded,
  //   setCampaignObjectivewiseDistributionIsLoaded,
  // ] = useState(false);
  // const [
  //   campaignObjectivewiseDistributionerror,
  //   setCampaignObjectivewiseDistributionError,
  // ] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    // { value: "conversions", label: "Conversions" },
    // { value: "engagements", label: "Engagements" },
    // { value: "spends", label: "Spends" },
  ];
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Audience Distribution", e.label)

  };

  // PERFORMANCE
  const [performance, setPerformance] = useState([]);
  const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
    useState([]);
  const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  const [
    performanceAllDropdownsDataLabels,
    setPerformanceAllDropdownsDataLabels,
  ] = useState([]);
  const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  const [performanceerror, setPerformanceError] = useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceDetails, setPerformanceDetails] = useState([]);

  // COUNTRY DATA
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  // COUNTRY DATA TABLE HEADERS
  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  // CITY DATA TABLE HEADERS
  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);

  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})


  // TABLE HEADERS FOR COKE
  let cokeSummaryTableHeaders = [
    { name: "Name", field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" }
  ]




  // DEVICE DISTRIBUTION DROPDOWN
  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "campaign_group", label: "Campaign Group" },
    { value: "ad", label: "Ad" },
  ];


  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);

  let defaultTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: `${selectedCampaignSummaryDropdownOption.value === 'campaign_group' ? 'campaign_group_name' : selectedCampaignSummaryDropdownOption.value === 'ad' ? 'ad_name' : "campaign_name"}` },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "Engagement Rate", field: "engagement_rate" }
  ]


  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Summary", e.label)

  };

  useEffect(() => {
    // if (companyID === COKE_COMPANY_ID) {
    //   setCampaignSummaryTableHeaders(cokeSummaryTableHeaders)
    // } else {
    //   setCampaignSummaryTableHeaders(defaultTableHeaders)
    // }
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])



  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad);
    } else if (
      selectedCampaignSummaryDropdownOption.value === "campaign_group"
    ) {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign_group);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
    } else {
      setCampaignSummary([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    // fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceHandler();
    fetchCountryHandler();
    fetchCityHandler();
    // fetchCampaignSummaryHandler();
    fetchChildDonutCampaignHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {

      setPerformance(performanceAllDropdownsData?.clicks ?? []);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels?.clicks ?? []);
      setCountryData(countryAllDropdownsData?.clicks ?? []);
      setCityData(cityAllDropdownsData?.clicks ?? []);

    } else if (selectedMidSectionDropdownOption.value === "conversions") {

      setPerformance(performanceAllDropdownsData?.conversions ?? []);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels?.conversions ?? []);
      setCountryData(countryAllDropdownsData?.conversions ?? []);
      setCityData(cityAllDropdownsData?.conversions ?? []);

    } else if (selectedMidSectionDropdownOption.value === "engagements") {

      setPerformance(performanceAllDropdownsData?.engagement ?? []);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels?.engagement ?? []);
      setCountryData(countryAllDropdownsData?.engagements ?? []);
      setCityData(cityAllDropdownsData?.engagements ?? []);

    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setPerformance(performanceAllDropdownsData?.impressions ?? []);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels?.impressions ?? []);
      setCountryData(countryAllDropdownsData?.impressions ?? []);
      setCityData(cityAllDropdownsData?.impressions ?? []);

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setPerformance(performanceAllDropdownsData?.spends ?? []);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels?.spends ?? []);
      setCountryData(countryAllDropdownsData?.spends ?? []);
      setCityData(cityAllDropdownsData?.spends ?? []);

    } else {
      setPerformance([]);
      setPerformanceDataLabels([]);
      setCountryData([]);
      setCityData([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/ad-metric/", config);
      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="linkedin"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // // TO FETCH DATA FROM API
  // const fetchCampaignObjectivewiseDistributionHandler = async () => {
  //   setCampaignObjectivewiseDistributionIsLoading(true);
  //   setCampaignObjectivewiseDistributionIsLoaded(false);
  //   setCampaignObjectivewiseDistributionError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       objective: selectedObjectives,
  //       platform_campaign:selectedIdandPlatform
  //       platform: selectedPlatform,
  //       campaign: selectedCampaigns,
  //         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "performance/linkedin/objective-distribution/",
  //       config
  //     );

  //     setCampaignObjectivewiseDistribution(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data[0] === undefined || res.data[0].length === 0 ? [] :
  //           res.data[0].impressions === undefined || res.data[0].impressions.length === 0 ? [] :
  //             res.data[0].impressions.data === undefined || res.data[0].impressions.data.length === 0 ? [] :
  //               res.data[0].impressions.data
  //     );


  //     /*  setCampaignObjectivewiseDistribution(
  //        res.data === undefined  ? [] : res.data[0].impressions.data
  //      ); */

  //     setCampaignObjectivewiseDistributionDataLabels(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data[0] === undefined || res.data[0].length === 0 ? [] :
  //           res.data[0].impressions === undefined || res.data[0].impressions.length === 0 ? [] :
  //             res.data[0].impressions.label === undefined || res.data[0].impressions.label.length === 0 ? [] :
  //               res.data[0].impressions.label
  //     );
  //     /*  setCampaignObjectivewiseDistributionDataLabels(
  //        res.data === undefined  ? [] : res.data[0].impressions.label
  //      ); */

  //     // setCampaignObjectivewiseDistribution(
  //     //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
  //     // );
  //     // setCampaignObjectivewiseDistributionDataLabels(
  //     //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
  //     // );
  //     setCampaignObjectivewiseDistributionIsLoading(false);
  //     setCampaignObjectivewiseDistributionIsLoaded(true);
  //     setCampaignObjectivewiseDistributionError(null);
  //   } catch (error) {
  //     setCampaignObjectivewiseDistribution([]);
  //     setCampaignObjectivewiseDistributionDataLabels([]);
  //     setCampaignObjectivewiseDistributionIsLoading(false);
  //     setCampaignObjectivewiseDistributionIsLoaded(false);
  //     setCampaignObjectivewiseDistributionError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let campaignObjectivewiseDistributionPie = (
  //   <Wrapper>
  //     <Loader loaderType="pieChartLoader" />
  //   </Wrapper>
  // );

  // // IF DATA IS LOADED
  // if (isCampaignObjectivewiseDistributionisLoaded && !isCampaignObjectivewiseDistributionisLoaing) {
  //   if (
  //     NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
  //     NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
  //   ) {
  //     campaignObjectivewiseDistributionPie = (
  //       <Wrapper>
  //         <NoDataAvailableLoader chartType="pieChartType" />
  //       </Wrapper>
  //     );
  //   } else {
  //     campaignObjectivewiseDistributionPie = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="pieChartType">
  //           <PieofaPieChart
  //             chartId="content_analysis"
  //             chartClass="section_card_chart"
  //             chartData={campaignObjectivewiseDistribution}
  //             chartLabels={campaignObjectivewiseDistributionDataLabels}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (campaignObjectivewiseDistributionerror) {
  //   campaignObjectivewiseDistributionPie = (
  //     <Wrapper>
  //       <ServerErrorsLoader
  //         chartType="pieChartType"
  //         error={campaignObjectivewiseDistributionerror}
  //       />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isCampaignObjectivewiseDistributionisLoaing) {
  //   campaignObjectivewiseDistributionPie = <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE

  // TO FETCH DATA FROM API
  const fetchPerformanceHandler = async () => {
    setPerformanceIsLoading(true);
    setPerformanceIsLoaded(false);
    setPerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/metric-by-date/",
        config
      );
      setPerformance(res?.data?.metric_list?.data?.impressions ?? []);
      setPerformanceDataLabels(res?.data?.metric_list?.label?.impressions ?? []);

      setPerformanceAllDropdownsData(res?.data?.metric_list?.data ?? []);
      setPerformanceAllDropdownsDataLabels(res?.data?.metric_list?.label ?? []);

      setPerformanceDetails(res?.data?.metrics ?? []);

      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(true);
      setPerformanceError(null);
    } catch (error) {
      setPerformanceAllDropdownsData([]);
      setPerformanceAllDropdownsDataLabels([]);
      setPerformanceDetails([]);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(false);
      setPerformanceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      NoDataAvailableChecker(performance) ||
      NoDataAvailableChecker(performanceDataLabels)
    ) {
      performanceChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
    } else {
      performanceChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performance}
              chartLabels={performanceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceChart = (
      <Wrapper>  <ServerErrorsLoader chartType="lineChartType" error={performanceerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceChart = <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE DETAILS

  // DATA HANDLING
  let performanceDetailsData = (
    <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      NoDataAvailableChecker(performanceDetails)
    ) {
      performanceDetailsData = (
        <Wrapper>   <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      performanceDetailsData = (
        <Wrapper>
          <table className="table table_striped table_bordered">
            <tbody>
              {performanceDetails.map((details, postImpressionIndex) => {
                return (
                  <Wrapper key={postImpressionIndex}>
                    <tr>
                      <td>{details.label}</td>
                      <td className="bold">
                        <NumberFormatter number={details.value} />
                      </td>
                    </tr>
                  </Wrapper>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceDetailsData = (
      <Wrapper> <ServerErrorsLoader chartType="tableChartType" error={performanceerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceDetailsData = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/country/", config);

      setCountryData(res?.data?.data?.impressions ?? []);

      setCountryAllDropdownsData(res?.data?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              isHalfSection={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/region/", config);

      setCityData(res?.data?.data?.impressions ?? []);
      setCityAllDropdownsData(res?.data?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By City"
              isHalfSection={true}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/linkedin/summary-datatable/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])


  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get(
          "performance/linkedin/summary-datatable/v2/",
          config
        );

        let allData = res.data.data.results;

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})

        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };

    fetchCampaignSummaryHandler()

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, campaignSummaryConfig])



  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  )
  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <PaginatedDatatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`CampaignTableImprivata_Summary Table _${selectedCampaignSummaryDropdownOption.value}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    )

  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
    useState([]);
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState("");

  const [
    childDonutCampaignObjectiveLoading,
    setChildDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveLoaded,
    setChildDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveError,
    setChildDonutCampaignObjectiveError,
  ] = useState([]);

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/objective-distribution/",
        config
      );
      setChildDonutCampaignObjective(res?.data ?? []);

      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (NoDataAvailableChecker(childDonutCampaignObjective)) {
      childDonutCampaignChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      childDonutCampaignChart = (
        <Wrapper>  <ErrorBoundary chartType="donutChartType">
          <DonutPieChart
            chartClass="section_card_chart"
            chartData={childDonutCampaignObjective}
            chartId="CampaignDonutChild"
            onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
          />
        </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>   <ServerErrorsLoader
        chartType="donutChartType"
        error={childDonutCampaignObjectiveError}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [
    lineChartCampaignObjectiveLoading,
    setLineChartCampaignObjectiveLoading,
  ] = useState([]);
  const [
    lineChartCampaignObjectiveLoaded,
    setLineChartCampaignObjectiveLoaded,
  ] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
    useState([]);

  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    var objectiveConfig = (function () {
      if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
        return { "objective": null }
      } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
        return { "objective": selectedObjectives }
      } else {
        return { "objective": childDonutSliceToggle }
      }
    })()

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // objective: childDonutSliceToggle,
        // objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        ...objectiveConfig
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/objective-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.data ?? []);
      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) ||
      NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      lineChartCampaignSection = (
        <Wrapper>   <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartClass="section_card_chart"
            chartData={lineChartCampaignObjective}
            chartId="multiLineChart"
            chartLabels={lineChartCampaignObjectiveLabel}
            // onBulletsClick={function noRefCheck() { }}
            showLabels
            showLegend
          />
        </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper> <ServerErrorsLoader
        chartType="lineChartType"
        error={lineChartCampaignObjectiveError}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [BRAND_ID, childDonutSliceToggle, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  useEffect(() => {
    setChildDonutSliceToggle(null)
  }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  var campaignObjectCSV = {
    "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
  };

  var cityCountryData = {
    [`CityChartDynaLabels_Audience Distribution - City_${selectedMidSectionDropdownOption.value} `]: NoDataAvailableChecker(cityData) ? [] : cityData,
    [`CountryChartDynaLabels_Audience Distribution - Country_${selectedMidSectionDropdownOption.value} `]: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    ...cityCountryData,
    [`CampaignTableImprivata_Summary Table _${":" + selectedCampaignSummaryDropdownOption.value} `]: campaignSummaryExportData,
  };

  useEffect(() => {
    setFullPageExport(allData);

  }, [
    metricCards,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    cityData,
    countryData,
    campaignSummary,
    campaignSummaryExportData
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded,
    cityDataerror, isCityDataLoaded,
    countryDataerror, isCountryDataLoaded,
    campaignSummaryerror, isCampaignSummaryLoaded,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Objective Wise Distribution
            </h2>  <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module="campaign"
                platform="linkedin"
                section="overall"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective"}
                sectionName={" Campaign Objective Wise distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Wrapper>
        <div
          id="audience_distribution_place"
          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
        >
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">  <h2 className="section_card_title">
                Audience Distribution : {selectedMidSectionDropdownOption.label}
              </h2> <SectionDefinationsBadge
                  title={"Audience Distribution"}
                  module="campaign"
                  platform="linkedin"
                  section="overall"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={midSectionDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedMidSectionDropdownOption}
                  selectedOptions={selectedMidSectionDropdownOption}
                  setStatedropdown={midSectionDropdownSelectionHandler}
                />

                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_place"}
                  sectionName={"Audience Distribution"}
                  sectionData={cityCountryData}

                />
              </div>
            </div>
          </div>
          <div className="grid grid_cols_4">
            <div className="grid col_span_2 card_border_right flex column">
              {/* <div className="section_card_header">
              <h2 className="section_card_title">By Country

              </h2>
            </div> */}
              {/* <div className="grid grid_cols_1 section_card_body"> */}
              {countryMap}
              {/* </div> */}
            </div>
            <div className="grid col_span_2 flex column">
              {/* <div className="section_card_header">
              <h2 className="section_card_title">By City

              </h2>
            </div> */}
              {/* <div className="grid grid_cols_1 section_card_body"> */}
              {cityMap}
              {/* </div> */}
            </div>
          </div>
        </div>
      </Wrapper>

      {/* <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Summary
             </h2>  <SectionDefinationsBadge
                title={"Summary"}
                module="campaign"
                platform="linkedin"
                section="overall"
              />
           </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body"> */}
      {campaignSummaryTable}
      {/* </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

export default CampaignLinkedinOverall;
