const IconBusinesCategory = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_718" data-name="Group 718" transform="translate(-6752 -356.333)">
                <path id="Path_299" data-name="Path 299" d="M.5,54.833h-1v-54h1Z" transform="translate(6778.914 355.5)" fill="#486f88" />
                <path id="Rectangle_241" data-name="Rectangle 241" d="M1,1V17H17V1H1M0,0H18V18H0Z" transform="translate(6752 378.082)" fill="#486f88" />
                <path id="Rectangle_242" data-name="Rectangle 242" d="M1,1V17H17V1H1M0,0H18V18H0Z" transform="translate(6787.998 360.083)" fill="#486f88" />
                <path id="Rectangle_243" data-name="Rectangle 243" d="M1,1V17H17V1H1M0,0H18V18H0Z" transform="translate(6787.998 391.582)" fill="#486f88" />
                <path id="Line_155" data-name="Line 155" d="M9.42.5H0v-1H9.42Z" transform="translate(6769.156 386.725)" fill="#486f88" />
                <path id="Line_156" data-name="Line 156" d="M9.42.5H0v-1H9.42Z" transform="translate(6778.924 368.221)" fill="#486f88" />
                <path id="Line_157" data-name="Line 157" d="M9.42.5H0v-1H9.42Z" transform="translate(6778.924 400.518)" fill="#486f88" />
            </g>
        </svg>
    )
}

export default IconBusinesCategory;