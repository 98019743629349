import React, { Component } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Wrapper from '../../../helpers/wrapper';
import { Link } from 'react-router-dom'
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import DualAxisCompareLineChart from '../../../shared/components/charts/DualAxisCompareLineChart';
import { Container, Image } from "react-bootstrap";
import StickyLogo from "../../../../assets/images/icons/icon.png";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import IconPdf from "../../../../assets/images/icons/pdfIcon";

import { API_URL } from '../../../helpers/constant';

import '../../../shared/components/loader/loader.scss';
import { Loader } from '../../../shared/components/loader/loader';
import ChartExport from "../../../shared/components/export/ChartExport";

export default class SellSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // stock: [{ "date": "2019-5-12", "value1": "50", "value2": "48", "previousdate": "2019-5-5" }, { "date": "2019-5-13", "value1": "53", "value2": "51", "previousdate": "2019-5-6" }, { "date": "2019-5-14", "value1": "56", "value2": "58", "previousdate": "2019-5-7" }, { "date": "2019-5-15", "value1": "52", "value2": "53", "previousdate": "2019-5-8" }, { "date": "2019-5-16", "value1": "48", "value2": "44", "previousdate": "2019-5-9" }, { "date": "2019-5-17", "value1": "47", "value2": "42", "previousdate": "2019-5-10" }, { "date": "2019-5-18", "value1": "59", "value2": "55", "previousdate": "2019-5-11" }],
      linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],

      stocktrendstatic:
        [{ "date": "2019-5-12", "value1": "50", "value2": "48" },
        { "date": "2019-5-13", "value1": "53", "value2": "51", },
        { "date": "2019-5-14", "value1": "56", "value2": "58", },
        { "date": "2019-5-15", "value1": "52", "value2": "53", },
        { "date": "2019-5-16", "value1": "48", "value2": "44", },
        { "date": "2019-5-17", "value1": "47", "value2": "42", },
        { "date": "2019-5-18", "value1": "59", "value2": "55", }],

      is_sellthrough_metrices_loaded: false,
      is_sellthrough_sparkline_loaded: false,
      is_stocktrend_multiline_loaded: false,
      is_getfilter_loaded: false,

    }
  }


  componentDidMount() {
    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.handleCallback();
    this.forceUpdate();
  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof (value) === "object" && value !== null) {
        this.isNull(value)
      } else if (!value) {
        object[key] = 0
      }
    }
    return object
  }

  // MARKETING METRICES
  getSellThroughMetrices() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;


    const url = API_URL + "/api/ri/sales_sellthrough_metrices/"

    fetch(url, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result) => {


        // METRICS CARD
        let current_asp;
        let last_asp;
        let current_sell_through;
        let last_sell_through;
        // let current_ros;
        // let last_ros;
        let current_basket_size;
        let last_basket_size;


        let current_nsv = result['current_sellthrough_metrices_list'][0]['nsv']

        let last_nsv = result['last_sellthrough_metrices_list'][0]['nsv']


        let current_units_sold = result['current_sellthrough_metrices_list'][0]['units_sold']
        let last_units_sold = result['last_sellthrough_metrices_list'][0]['units_sold']

        let current_soh = result['current_sellthrough_metrices_list'][0]['soh']
        let last_soh = result['last_sellthrough_metrices_list'][0]['soh']

        let current_wof = result['current_sellthrough_metrices_list'][0]['wof']
        let last_wof = result['last_sellthrough_metrices_list'][0]['wof']

        let curent_bills = result['current_sellthrough_metrices_list'][0]['bills']
        let last_bills = result['last_sellthrough_metrices_list'][0]['bills']


        current_asp = (current_nsv / current_units_sold);
        last_asp = (last_nsv / last_units_sold);

        current_sell_through = ((current_units_sold / current_soh) * 100).toFixed(2);
        last_sell_through = ((last_units_sold / last_soh) * 100).toFixed(2);

        let current_ros = result['current_sellthrough_metrices_list'][0]['ros']
        let last_ros = result['last_sellthrough_metrices_list'][0]['ros']

        current_basket_size = current_units_sold / curent_bills
        last_basket_size = last_units_sold / last_bills



        this.setState({
          is_sellthrough_metrices_loaded: true,
          current_nsv: current_nsv,


          last_nsv: last_nsv,
          current_units_sold: current_units_sold,
          last_units_sold: last_units_sold,
          current_soh: current_soh,
          last_soh: last_soh,
          current_wof: current_wof,
          last_wof: last_wof,
          curent_bills: curent_bills,
          last_bills: last_bills,
          current_asp: current_asp,
          last_asp: last_asp,
          current_sell_through: current_sell_through,
          last_sell_through: last_sell_through,
          current_ros: current_ros,
          last_ros: last_ros,
          current_basket_size: current_basket_size,
          last_basket_size: last_basket_size,

        });

      },
        (error) => {
        }
      )
  }

  //SPARK LINE FOR TRANS
  getSellThroughSparklineGraph() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;

    const url = API_URL + "/api/ri/sales_sellthrough_metrices/"
    fetch(url, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    }).then(res => res.json()).then((result) => {

      let sellthrough_sparkline_list = result['sellthrough_metrices_graph_list']

      let soh_spark_list = [];
      let units_sold_spark_list = [];
      let asp_spark_list = [];
      let sell_through_spark_list = [];
      let ros_spark_list = [];
      let basket_size_list = [];


      sellthrough_sparkline_list.map((info, index) => {
        try {
          let a = {
            "date": info['datetime'],
            "value": info['soh']
          }
          soh_spark_list.push(a);


          let b = {
            "date": info['datetime'],
            "value": info['units_sold']

          }
          units_sold_spark_list.push(b);

          let c = {
            "date": info['datetime'],
            "value": (((info['nsv'])) / info['units_sold']).toFixed(2)

          }
          asp_spark_list.push(c);

          let d = {
            "date": info['datetime'],
            "value": parseFloat(((info['units_sold'] / info['soh']) * 100).toFixed(2))

          }
          sell_through_spark_list.push(d);

          let e = {
            "date": info['datetime'],
            "value": (info['ros'])
          }
          ros_spark_list.push(e);

          let f = {
            "date": info['datetime'],
            "value": ((info['units_sold'] / info['bills'])).toFixed(2)

          }
          basket_size_list.push(f);

        } catch (e) {

        }



      })


      this.setState({
        is_sellthrough_sparkline_loaded: true,
        soh_spark_graph: soh_spark_list,
        units_sold_spark_graph: units_sold_spark_list,
        asp_spark_graph: asp_spark_list,
        sell_through_spark_graph: sell_through_spark_list,
        ros_spark_graph: ros_spark_list,
        basket_size_graph: basket_size_list,


      });
    })

  }

  // STOCK TREND MULTILINE
  getStockTrendMultilineGraph() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;

    const url = API_URL + "/api/ri/sales_stocktrend_multiline/"

    fetch(url, {

      method: 'POST',
      // body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {

        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result) => {

        let stock_trend_list = result['current_stock_trend_multiline_list']

        let stock_trend_multiline_list = [];
        //let cost_per_click_spark_list = [];

        stock_trend_list.map((info, index) => {



          try {

            let a = {

              "date": info['datetime'],
              "value1": parseInt(info['soh']),
              "value2": parseInt(info['units_sold']),


            }
            stock_trend_multiline_list.push(a);
          }
          catch (e) {

          }

        })

        this.setState({

          is_stocktrend_multiline_loaded: true,
          stocktrend: stock_trend_multiline_list,
        });
      })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.handleCallback();
    }
    if (prevProps.mapStoreCode !== this.props.mapStoreCode) {
      this.handleRISalesSummaryMapCallBack();
    }
    if (prevProps.mapStoreCategory !== this.props.mapStoreCategory || prevProps.mapStoreType !== this.props.mapStoreType || prevProps.mapStoreSize !== this.props.mapStoreSize) {
      this.handleRiMapFilterCallback();
    }
  }

  handleCallback = () => {

    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.forceUpdate();
  }

  handleRISalesSummaryMapCallBack = () => {
    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.forceUpdate();
  }

  handleRiMapFilterCallback = () => {
    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.forceUpdate();
  }


  render() {

    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }

    return (
      <Wrapper>

        <div className="icz-carouselcontainer">
          {this.state.is_sellthrough_metrices_loaded === true && this.state.is_sellthrough_sparkline_loaded === true ?
            <Carousel
              Settings={CarouselSettings}
              class={"icz-cardcontainer"}
              SliderCards={[
                <ChartCard
                  index="1"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="SOH"
                  RowOneCurrentValue={this.state.current_soh}
                  RowOnePreviousValue={this.state.last_soh}
                  RowOneTitle="overall"
                  IsNumber={'number'}
                  chart={<Linegraph_sparkline card_id="icz-soh" card_class="icz-cardchart" isnumber={'number'} graph-data={this.state.soh_spark_graph} />}
                />,

                <ChartCard
                  index="2"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Units Sold"
                  RowOneCurrentValue={this.state.current_units_sold}
                  RowOnePreviousValue={this.state.last_units_sold}
                  RowOneTitle="overall"
                  IsNumber={'number'}
                  chart={<Linegraph_sparkline card_id="icz-units" card_class="icz-cardchart" isnumber={'number'} graph-data={this.state.units_sold_spark_graph} />}
                />,
                <ChartCard
                  index="3"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="ASP"
                  RowOneCurrentValue={parseFloat(this.state.current_asp).toFixed(2)}
                  RowOnePreviousValue={parseFloat(this.state.last_asp).toFixed(2)}
                  RowOneTitle="overall"
                  IsNumber={'money'}
                  chart={<Linegraph_sparkline card_id="icz-asp" card_class="icz-cardchart" isnumber={'money'} graph-data={this.state.asp_spark_graph} />}
                />,
                <ChartCard
                  index="4"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Sell Through%"
                  RowOneCurrentValue={parseFloat(this.state.current_sell_through)}
                  RowOnePreviousValue={parseFloat(this.state.last_sell_through)}
                  RowOneTitle="overall"
                  IsNumber={'percentage'}
                  chart={<Linegraph_sparkline
                    card_id="icz-sell" card_class="icz-cardchart"
                    isnumber={'percentage'}
                    graph-data={this.state.sell_through_spark_graph} />}

                />,

                <ChartCard
                  index="5"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="ROS%"
                  RowOneCurrentValue={parseFloat(this.state.current_ros)}
                  RowOnePreviousValue={parseFloat(this.state.last_ros)}
                  RowOneTitle="overall"
                  IsNumber={'percentage'}
                  chart={<Linegraph_sparkline card_id="icz-ros" card_class="icz-cardchart" isnumber={'decimal_percentage'} graph-data={this.state.ros_spark_graph} />}
                />,
                <ChartCard
                  index="6"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Basket Size"
                  RowOneCurrentValue={parseFloat(this.state.current_basket_size).toFixed(2)}
                  RowOnePreviousValue={parseFloat(this.state.last_basket_size).toFixed(2)}
                  RowOneTitle="overall"
                  IsNumber={'number'}
                  chart={<Linegraph_sparkline card_id="icz-basket" card_class="icz-cardchart" isnumber={'number'} graph-data={this.state.basket_size_graph} />}
                />
              ]}>

            </Carousel>
            :
            <Loader />
          }
        </div>

        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Stock Trend
                </Col>
                <Col className="icz-cardfilter">
                  <div className="icz-cardbtnwrapper">
                    <ChartExport ChartId='stock_trend' exportName="Stock-Trend"
                      export_href={this.state.export_href} downloadName="Stock-Trend.csv"
                    />
                  </div>
                </Col>
              </Col>
              <div className="">
                {this.state.is_stocktrend_multiline_loaded === true ?
                  <DualAxisCompareLineChart isnumber={'money'} chart_id="stock_trend" chart_class="icz-sectionchart" Xtitle={""} Ytitle={"SOH"} Ytitle2={"Units Sold"} graph-data={this.state.stocktrend} metric_1={"SOH"} metric_2={"Units Sold"} />
                  :
                  // LOADER
                  <Loader />
                }
              </div>
            </div>
          </div>
        </div>

        <div className="icz-riknwbttnwrapper" >
          <Link to='/roi/sellthrough' className="btn icz-riknowmore"> Know more</Link>
        </div>
      </Wrapper>
    );
  }
}