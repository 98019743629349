import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

//STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

//LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Hashtag_Visit, IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";


const SocialTwitterPostPaid = ({ pageDropdownObject, topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Post"
  let pageSecondaryTab = "Paid"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled, setExportPageName } =
    useContext(FullPageExportContext);

  let tweetTypeDropdown = pageDropdownObject.length === 0 ? "" : " : " + pageDropdownObject.label
  let pageDropdown = pageDropdownObject.length === 0 ? null : pageDropdownObject.value

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // const PostType = "Paid";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();
  // const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // VIDEO VIEWS QUARTILES
  const [videoViewQuartiles, setVideoViewQuartiles] = useState([]);
  const [videoViewQuartilesDataLabels, setVideoViewQuartilesDataLabels] =
    useState([]);
  const [isVideoViewQuartilesLoading, setVideoViewQuartilesIsLoading] =
    useState(false);
  const [isVideoViewQuartilesLoaded, setVideoViewQuartilesIsLoaded] =
    useState(false);
  const [videoViewQuartileserror, setVideoViewQuartilesError] = useState(null);

  // POLL VOTES
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesDataLabels, setPollVotesDataLabels] = useState([]);
  const [isPollVotesLoading, setPollVotesIsLoading] = useState(false);
  const [isPollVotesLoaded, setPollVotesIsLoaded] = useState(false);
  const [pollVoteserror, setPollVotesError] = useState(null);

  // TWEET ENGAGEMENT
  const [tweetEngagement, setTweetEngagement] = useState([]);
  const [tweetEngagementDataLabels, setTweetEngagementDataLabels] = useState(
    []
  );
  const [isTweetEngagementLoading, setTweetEngagementIsLoading] =
    useState(false);
  const [isTweetEngagementLoaded, setTweetEngagementIsLoaded] = useState(false);
  const [tweetEngagementerror, setTweetEngagementError] = useState(null);

  const [tweetEngagementAllData, setTweetEngagementAllData] = useState([]);
  const [tweetEngagementAllLabels, setTweetEngagementAllLabels] = useState([]);

  // DROPDOWN OPTIONS
  const tweetEngagementOptions = [
    { value: "typewise", label: "Typewise" },
    { value: "total_engagement", label: "Total" },
  ];
  //DROPDOWN STATE
  const [tweetEngagementDropdownOption, setTweetEngagementDropdownOption] =
    useState(tweetEngagementOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const tweetEngagementSelectionHandler = (e) => {
    if (e === null) {
      setTweetEngagementDropdownOption("");
    } else {
      setTweetEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Paid", "Tweet Engagement", e.label)

  };

  const FetchTweetEngagementDropdownHandler = () => {
    if (tweetEngagementDropdownOption.value === "typewise") {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    } else if (tweetEngagementDropdownOption.value === "total_engagement") {
      setTweetEngagement(tweetEngagementAllData.total_engagement);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.total_engagement);
    } else {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    }
  };

  useEffect(() => {
    FetchTweetEngagementDropdownHandler();
  }, [BRAND_ID, tweetEngagementDropdownOption, selectedDates]);

  // USER PROFILE CLICKS

  const [userProfileClick, setUserProfileClick] = useState([]);
  const [userProfileClickLabel, setUserProfileClickLabel] = useState([]);
  const [userProfileClickLoading, setUserProfileClickLoading] = useState(false);
  const [userProfileClickLoaded, setUserProfileClickloaded] = useState(false);
  const [userProfileClickerror, setUserProfileClickError] = useState(null);

  // User Top Tweets

  const [topTweets, setTopTweets] = useState([]);
  // const [topTweetsLabel, setTopTweetsLabel] = useState([]);
  const [topTweetsLoading, settopTweetsLoading] = useState(false);
  const [topTweetsLoaded, setTopTweetsloaded] = useState(false);
  const [topTweetserror, setTopTweetsError] = useState(null);


  const [topTweetsAllData, setTopTweetsAllData] = useState([]);

  // DROPDOWN OPTIONS
  const topTweetsDropdownOptions = [
    { value: "recency", label: "Recency" },
    { value: "engagement", label: "Engagements" },
  ];
  // Top Tweets dropdown
  const [topTweetsDropdown, setTopTweetsDropdown] = useState(topTweetsDropdownOptions[0]);
  const topTweetsDropdownSelectionHandler = (e) => {
    if (e === null) {
      setTopTweetsDropdown("");
    } else {
      setTopTweetsDropdown(e);
    }
  };

  const fetchTopTweetsDataHandler = () => {
    if (topTweetsDropdown.value === "recency") {
      setTopTweets(topTweetsAllData.recency);
    } else if (topTweetsDropdown.value === "engagement") {
      setTopTweets(topTweetsAllData.engagement);
    } else {
      setTopTweets(topTweetsAllData.recency);
    }
  };

  // COMPARE ENGAGEMENT IMPRESSIONS
  const [compareEngagementImpression, setCompareEngagementImpression] =
    useState([]);
  const [
    compareEngagementImpressionDataLabels,
    setCompareEngagementImpressionDataLabels,
  ] = useState([]);
  const [
    isCompareEngagementImpressionLoading,
    setCompareEngagementImpressionIsLoading,
  ] = useState(false);
  const [
    isCompareEngagementImpressionLoaded,
    setCompareEngagementImpressionIsLoaded,
  ] = useState(false);
  const [
    compareEngagementImpressionerror,
    setCompareEngagementImpressionError,
  ] = useState(null);

  // DROPDOWN OPTIONS
  const compareEngagementImpressionOptions = [
    { value: "impression", label: "impression" },
    { value: "tweetLength", label: "Tweet Length" },
  ];

  // Engagement vs Impression dropdown
  const [compareEngagementImpressionDropdown, setcompareEngagementImpression] =
    useState(compareEngagementImpressionOptions[0]);

  const compareEngagementImpressionSelectionHandler = (e) => {
    if (e === null) {
      setcompareEngagementImpression("");
    } else {
      setcompareEngagementImpression(e);
    }
  };

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    // fetchHashtagListHandler();
    fetchMetricCardsHandler();
    fetchPostImpressionsChartHandler();
    fetchPollVotesHandler();
    fetchTweetEngagementHandler();
    // fetchCompareEngagementImpressionHandler();
    fetchUserProfileClickHandler();
    fetchTopTweetsHandler();
    fetchVideoViewsChartHandler();
    fetchVideoViewQuartilesHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, pageDropdown]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, pageDropdown]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/hashtag/", config);
      setHashtagList(res.data === undefined || res.data.length === 0 ? [] : res.data);

      /*  setHashtagList(res.data); */
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Paid", badge.hashtag)

  };

  let topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(hashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper> <NoDataAvailableLoader chartType="hashChartType" /></Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper> <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/overview/", config);

      setMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );

      /* setMetricCards(res.data.length === 0 ? [] : res.data.data); */
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">
              Summary {tweetTypeDropdown}
            </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Summary {tweetTypeDropdown}
                </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="paid"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Summary {tweetTypeDropdown}
              </h2></div>  </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Summary {tweetTypeDropdown}
              </h2></div> </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsChartHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/impressions/", config);

      setPostImpressions(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );

      /*       setPostImpressions(res.data.length === 0 ? [] : res.data.data); */

      setPostImpressionsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions
      );

      /*      setPostImpressionsDataLabels(
             res.data.length === 0 ? [] : res.data.Dimensions
           );
      */

      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (
      NoDataAvailableChecker(postImpressions) ||
      NoDataAvailableChecker(postImpressionsDataLabels)
    ) {
      postImpressionsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      postImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_impressions"
              chartClass="section_card_chart"
              chartData={postImpressions}
              chartLabels={postImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={postImpressionserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/video-views/", config);

      setVideoViews(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      /*  setVideoViews(res.data.length === 0 ? [] : res.data.data);
     */
      setVideoViewsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
              res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                res.data.Dimensions
      );

      /* setVideoViewsDataLabels(res.data.length === 0 ? [] : res.data.Dimensions);
      */
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_video_views"
              chartClass="section_card_chart"
              chartData={videoViews}
              chartLabels={videoViewsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video View Quartiles

  const fetchVideoViewQuartilesHandler = async () => {
    setVideoViewQuartilesIsLoading(true);
    setVideoViewQuartilesIsLoaded(false);
    setVideoViewQuartilesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/paid/total-video-quartiles/",
        config
      );
      setVideoViewQuartiles(res.data === undefined || res.data.length === 0 ? [] : res.data.data === undefined || res.data.data.length === 0 ? [] : res.data.data);


      /*  setVideoViewQuartiles(res.data.length === 0 ? [] : res.data.data);
        */
      setVideoViewQuartilesDataLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.label_list === undefined || res.data.label_list.length === 0 ? [] : res.data.label_list
      );


      /*  setVideoViewQuartilesDataLabels(
         res.data.length === 0 ? [] : res.data.label_list
       ); */
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(true);
      setVideoViewQuartilesError(null);
    } catch (error) {
      setVideoViewQuartiles([]);
      setVideoViewQuartilesDataLabels([]);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(false);
      setVideoViewQuartilesError(error.code);
    }
  };

  let videoViewQuartilesChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoViewQuartilesLoaded && !isVideoViewQuartilesLoading) {
    if (
      NoDataAvailableChecker(videoViewQuartiles) ||
      NoDataAvailableChecker(videoViewQuartilesDataLabels)
    ) {
      videoViewQuartilesChart = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      videoViewQuartilesChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_quartiles"
              chartClass="section_card_chart"
              chartData={videoViewQuartiles}
              chartLabels={videoViewQuartilesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewQuartileserror) {
    videoViewQuartilesChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewQuartileserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewQuartilesLoading) {
    videoViewQuartilesChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POLL VOTES

  const fetchPollVotesHandler = async () => {
    setPollVotesIsLoading(true);
    setPollVotesIsLoaded(false);
    setPollVotesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/poll/", config);

      setPollVotes(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );

      /*  setPollVotes(res.data.length === 0 ? [] : res.data.data); */


      setPollVotesDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );


      /* setPollVotesDataLabels(res.data.length === 0 ? [] : res.data.dimension); */
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(true);
      setPollVotesError(null);
    } catch (error) {
      setPollVotes([]);
      setPollVotesDataLabels([]);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(false);
      setPollVotesError(error.code);
    }
  };

  let pollVotesChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPollVotesLoaded && !isPollVotesLoading) {
    if (
      NoDataAvailableChecker(pollVotes) ||
      NoDataAvailableChecker(pollVotesDataLabels)
    ) {
      pollVotesChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      pollVotesChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={pollVotes}
              chartLabels={pollVotesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pollVoteserror) {
    pollVotesChart = (
      <Wrapper>  <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={pollVoteserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPollVotesLoading) {
    pollVotesChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ENGAGEMENT

  const fetchTweetEngagementHandler = async () => {
    setTweetEngagementIsLoading(true);
    setTweetEngagementIsLoaded(false);
    setTweetEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/engagement/", config);

      setTweetEngagement(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.typewise === undefined || res.data.data.typewise.length === 0 ? [] :
              res.data.data.typewise
      )
      /*    setTweetEngagement(res.data.length === 0 ? [] : res.data.data.typewise);
         
          */
      setTweetEngagementDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.dimension.typewise === undefined || res.data.dimension.typewise.length === 0 ? [] :
              res.data.dimension.typewise
      )
      /*   setTweetEngagementDataLabels(
          res.data.length === 0 ? [] : res.data.dimension.typewise
        );
   */
      setTweetEngagementAllData(res.data.data);
      setTweetEngagementAllLabels(res.data.dimension);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(true);
      setTweetEngagementError(null);
    } catch (error) {
      setTweetEngagementAllData([]);
      setTweetEngagementAllLabels([]);
      setTweetEngagement([]);
      setTweetEngagementDataLabels([]);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(false);
      setTweetEngagementError(error.code);
    }
  };

  let tweetEngagementChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isTweetEngagementLoaded && !isTweetEngagementLoading) {
    if (
      NoDataAvailableChecker(tweetEngagement) ||
      NoDataAvailableChecker(tweetEngagementDataLabels)
    ) {
      tweetEngagementChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      tweetEngagementChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_engagement_tweet"
              chartClass="section_card_chart"
              chartData={tweetEngagement}
              chartLabels={tweetEngagementDataLabels}
              // onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetEngagementerror) {
    tweetEngagementChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={tweetEngagementerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetEngagementLoading) {

    tweetEngagementChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // PROFILE CLICK

  const fetchUserProfileClickHandler = async () => {
    setUserProfileClickLoading(true);
    setUserProfileClickloaded(false);
    setUserProfileClickError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/profile-click/", config);


      setUserProfileClick(res.data === undefined || res.data.length === 0 ? [] : res.data.data === undefined || res.data.data.length === 0 ? [] : res.data.data);

      /*  setUserProfileClick(res.data.length === 0 ? [] : res.data.data); */

      setUserProfileClickLabel(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );

      /* setUserProfileClickLabel(res.data.length === 0 ? [] : res.data.dimension); */
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(true);
      setUserProfileClickError(null);
    } catch (error) {
      setUserProfileClick([]);
      setUserProfileClickLabel([]);
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(false);
      setUserProfileClickError(error.code);
    }
  };

  // User Profile clicks
  let userProfileClickChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (userProfileClickLoaded && !userProfileClickLoading) {
    if (
      NoDataAvailableChecker(userProfileClick) ||
      NoDataAvailableChecker(userProfileClickLabel)
    ) {
      userProfileClickChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      userProfileClickChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="profile_clicks"
              chartClass="section_card_chart"
              chartData={userProfileClick}
              chartLabels={userProfileClickLabel}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (userProfileClickerror) {
    userProfileClickChart = (
      <Wrapper>  <ServerErrorsLoader
        chartType="lineChartType"
        error={userProfileClickerror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (userProfileClickLoading) {
    userProfileClickChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP TWEETS

  const fetchTopTweetsHandler = async () => {
    settopTweetsLoading(true);
    setTopTweetsloaded(false);
    setTopTweetsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/paid/top-tweets/", config);

      setTopTweets(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.recency === undefined || res.data.recency.length === 0 ? [] :
            res.data.recency
      );
      setTopTweetsAllData(res.data);
      // setTopTweetsLabel(res.data.typewise.dimension);
      settopTweetsLoading(false);
      setTopTweetsloaded(true);
      setTopTweetsError(null);
    } catch (error) {
      setTopTweets([]);
      // setTopTweetsLabel([]);
      settopTweetsLoading(false);
      setTopTweetsloaded(false);
      setTopTweetsError(error.code);
    }
  };

  // Top Tweets Chart
  let topTweetsChart = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 grid_margin_bottom section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
          </h2> <SectionDefinationsBadge
              title="Top 5 Tweets"
              module="social"
              platform="twitter"
              section="paid"
            />
          </div>
        </div>
        <div className="grid col_span_4 ">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topTweetsLoaded && !topTweetsLoading) {
    if (NoDataAvailableChecker(topTweets)) {
      topTweetsChart = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 grid_margin_bottom section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
              </h2> <SectionDefinationsBadge
                  title="Top 5 Tweets"
                  module="social"
                  platform="twitter"
                  section="paid"
                />
              </div>
            </div>
            <div className="grid col_span_4 ">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topTweetsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              social_platform="Twitter"
              module_section="paid"
              isPaid={true}
              Title="Tweets"
              products={topTweets}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topTweetserror) {
    topTweetsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
            </h2><SectionDefinationsBadge
                title="Top 5 Tweets"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topTweetserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topTweetsLoading) {
    topTweetsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">      <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
            </h2> <SectionDefinationsBadge
                title="Top 5 Tweets"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMPARE ENGAGEMENT IMPRESSION

  // const fetchCompareEngagementImpressionHandler = async () => {
  //   setCompareEngagementImpressionIsLoading(true);
  //   setCompareEngagementImpressionIsLoaded(false);
  //   setCompareEngagementImpressionError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       media_type: pageDropdown,
  //       hashtag: selectedHashtag,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("social/twitter/paid/engagement/", config);

  //     setCompareEngagementImpression(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.data === undefined || res.data.data.length === 0 ? [] :
  //           res.data.data
  //     );
  //     setCompareEngagementImpressionDataLabels(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
  //           res.data.dimension
  //     );
  //     setCompareEngagementImpressionIsLoading(false);
  //     setCompareEngagementImpressionIsLoaded(true);
  //     setCompareEngagementImpressionError(null);
  //   } catch (error) {
  //     setCompareEngagementImpression([]);
  //     setCompareEngagementImpressionDataLabels([]);
  //     setCompareEngagementImpressionIsLoading(false);
  //     setCompareEngagementImpressionIsLoaded(false);
  //     setCompareEngagementImpressionError(error.code);
  //   }
  // };

  // let compareEngagementImpressionChart = <Wrapper> <Loader loaderType="heatChartLoader" /></Wrapper>;

  // // IF DATA IS LOADED
  // if (
  //   isCompareEngagementImpressionLoaded &&
  //   !isCompareEngagementImpressionLoading
  // ) {
  //   if (
  //     NoDataAvailableChecker(compareEngagementImpression) ||
  //     NoDataAvailableChecker(compareEngagementImpression) ||
  //     NoDataAvailableChecker(compareEngagementImpression)
  //   ) {
  //     compareEngagementImpressionChart = (
  //       <NoDataAvailableLoader chartType="heatChartType" />
  //     );
  //   } else {
  //     compareEngagementImpressionChart = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="heatChartType">
  //           <HeatMap
  //             chartClass="section_card_chart"
  //             varient="Bubble"
  //             chartData={tweetEngagement}
  //             chartId="placeholder"
  //             chartXaxisLabels={tweetEngagement}
  //             chartYaxisLabels={tweetEngagement}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (compareEngagementImpressionerror) {
  //   compareEngagementImpressionChart = (
  //     <Wrapper>
  //       <ServerErrorsLoader
  //         chartType="cardChartType"
  //         error={compareEngagementImpressionerror}
  //       />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isCompareEngagementImpressionLoading) {
  //   compareEngagementImpressionChart = <Wrapper> <Loader loaderType="heatChartLoader" /></Wrapper>;
  // }

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var tweetTypeExport = pageDropdownObject === [] || pageDropdownObject === "" ? "" : pageDropdownObject.label === undefined ? "" : ": " + pageDropdownObject.label;

  var allData = {
    ...topCardData,
    [`SummaryCardDynaLabels_Summary Card_${tweetTypeExport}`]: metricCards,
    [`DateFirstNonActiveDropdownLabels_Post Impressions_${tweetTypeExport}`]: postImpressions,
    [`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`]: combineCSV(videoViews),
    "Poll Votes": pollVotes,
    [`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`]: tweetEngagement,
    [`DateFirstNonActiveDropdownLabels_User Profile Clicks_${tweetTypeExport}`]: userProfileClick,
    "Compare_Top Tweets": topTweets,
  };

  useEffect(() => {
    setExportPageName("Social Twitter Post Paid");
    setFullPageExport(allData);
  }, [
    metricCards,
    postImpressions,
    videoViews,
    pollVotes,
    tweetEngagement,
    userProfileClick,
    topTweets,
  ]);
  useEffect(() => {
    if (
      isMetricCardsLoaded &&
      isPostImpressionsLoaded &&
      isVideoViewsLoaded &&
      isPollVotesLoaded &&
      isTweetEngagementLoaded &&
      userProfileClickLoaded &&
      topTweetsLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMetricCardsLoaded,
    isPostImpressionsLoaded,
    isVideoViewsLoaded,
    isPollVotesLoaded,
    isTweetEngagementLoaded,
    userProfileClickLoaded,
    topTweetsLoaded
  ])



  return (
    <Wrapper>
      {/* <div className="flex row justify_end grid grid_margin_bottom">
        <Dropdown
          ismulti={false}
          placeholder={"Select"}
          options={pageDropdownOptions}
          className="form_dropdown section_dropdown"
          value={pageDropdown}
          selectedOptions={pageDropdown}
          setStatedropdown={pageDropdownSelectionHandler}
        />
      </div> */}
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Trending Hashtags {tweetTypeDropdown}
          </h2> <SectionDefinationsBadge
              title="Trending Hashtags"
              module="social"
              platform="twitter"
              section="paid"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="post_impression"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Post Impressions {tweetTypeDropdown}
              {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Post Impressions"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"post_impression"}
                sectionName={`DateFirstNonActiveDropdownLabels_Post Impressions_${tweetTypeExport}`}
                sectionData={postImpressions}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postImpressionsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="video_views" className="grid col_span_4 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Views {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Video views"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views"}
                sectionName={`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`}
                sectionData={combineCSV(videoViews)}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart} </div>
          </div>
        </div>
        {/* <div className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views (Quartiles) {selectedHashtagSectionHeader}
            </h2>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewQuartilesChart}
            </div>
          </div>
        </div> */}
      </div>
      <div
        id="poll_votes"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Poll Votes {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Poll Votes"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"poll_votes"}
                sectionName={"Poll Votes"}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">{pollVotesChart}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_engagements"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Tweet Engagement {tweetTypeDropdown} {" : " + tweetEngagementDropdownOption.label} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Tweet Engagement"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetEngagementOptions}
                className="form_dropdown section_dropdown"
                value={tweetEngagementDropdownOption}
                selectedOptions={tweetEngagementDropdownOption}
                setStatedropdown={tweetEngagementSelectionHandler}
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"tweet_engagements"}
                sectionName={`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`}
                sectionData={tweetEngagement}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetEngagementChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="user_profile_clicks"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              User Profile Clicks {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="User profile clicks"
                module="social"
                platform="twitter"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"user_profile_clicks"}
                sectionName={`DateFirstNonActiveDropdownLabels_User Profile Clicks_${tweetTypeExport}`}
                sectionData={userProfileClick}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {userProfileClickChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      {topTweetsChart}

    </Wrapper>
  );
};

export default SocialTwitterPostPaid;
