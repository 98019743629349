import React, { useState } from "react";
import IconSearch from "../../../../assets/images/icons/searchIcon";

const TableSearch = ({ onSearch }) => {
    const [search, setSearch] = useState('');

    const onInputChange = (value) => {
        setSearch(value);
        onSearch(value);
    }

    return (
        <div className="icz-searchSection">
            <input
                type="text"
                className="form-control icz-input"
                style={{ width: "240px" }}
                placeholder="Search here ..."
                value={search}
                onChange={(e) => onInputChange(e.target.value)}
            />
            <div className="icz-searchIconSection">
                <IconSearch className="icz-searchIcon"/>
            </div>
        </div>
    )
}

export default TableSearch;