import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart500, USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";
import zoomarrows from "../../../assets/images/zoomarrows.jpg";
am5.addLicense("AM5C258896422");

const PartitionedMultiAxisBarChart = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showBullets,
  onBulletsClick,
  chartColorSet,
  disabledLegendArray,
  setDisabledValues,
}) => {

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);

    // TO CHANGE COLOR OF THE CHART
    if (chartColorSet === undefined) {
      var colorSet = BlueColorStart500
    } else {
      var colorSet = chartColorSet
    }

    /* let colorSet = [0x11a1fd, 0xff9931, 0x5a75f9, 0x86a873]; */

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    if (!disabledLegendArray) {
      root.setThemes([am5themes_Animated.new(root)]);
    }
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    var data = chartData

    let xRenderer = am5xy.AxisRendererX.new(root, {
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    })

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    })

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400"
    });

    xAxis.data.setAll(data);

    function createSeries(name, field, childLabel, childValue, index) {
      var yRenderer = am5xy.AxisRendererY.new(root, {
        /*  opposite: index % 2 !== 0 ? true : false, */
        opposite: false,
        stroke: am5.color(colorSet[index]),
        strokeWidth: 0.3,
        strokeOpacity: 1,
      });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // logarithmic: true,
          treatZeroAs: 0.000001,
          extraMax: 0.1,
          maxPrecision: 0,
          // tooltipNumberFormat: "#.00",
          renderer: yRenderer
        })
      );

      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: am5.color(colorSet[index]),
        fontWeight: "400",
      });

      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      }


      // to format numbers
      root.numberFormatter.setAll({
        numberFormat: "#.##a",

        // Group only into M (millions), and B (billions)
        bigNumberPrefixes: [
          { number: 1e3, suffix: "K" },
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],

        // Do not use small number prefixes at all
        smallNumberPrefixes: [],
      });

      // Add legend
      var legend = chart.children.push(
        am5.Legend.new(root, {
          // background: am5.Rectangle.new(root, {
          //     fill: am5.color(0xff5599),
          //     fillOpacity: 0.2
          // }), // to add the backgrounD to the legends
          position: "relative", // options: "absolute" / "relative"
          // width: am5.percent(100),
          // height: chartLabels.length >= 6 ? am5.percent(20) : null,
          x: am5.percent(50),
          centerX: am5.percent(50),
          // clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
          marginTop: 15,
          // marginBottom: 15,
          layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
          layout: am5.GridLayout.new(root, {
            ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
              maxColumns: 10,
              fixedWidthGrid: true,
            } : {
              maxColumns: 15,
            }),



          }),
          verticalScrollbar: am5.Scrollbar.new(root, {
            ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
              orientation: "vertical",
            } : {}),
          }),
        })
      );

      legend.markers.template.setup = function (marker) {
        var check = am5.Graphics.new(root, {
          width: 10,
          height: 10,
          layer: 50,
          fill: am5.color(0xffffff),
          draw: function (display) {
            display.drawRect(4, 4, 6, 6);
          },
        });

        check.states.create("disabled", {
          fillOpacity: 1,
        });

        marker.children.push(check);
      };

      legend.markers.template.setAll({
        width: 14,
        height: 14,
      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
      });

      var globalName;
      legend.itemContainers.template.events.on("click", function (ev) {
        var temp = ev.target.dataItem.dataContext;

        chart.series.values.filter((i, index) => {
          if (temp.get("name") === i._settings.name) {
            var series = chart.series.getIndex(index);

            series.chart.yAxes.values.filter((x, y) => {
              if (index === y) {
                if (x.get("visible")) {
                  setTimeout(() => {
                    x.set("visible", false);
                  }, 1050);

                  /*   setDisabledValues((prev) => {
                      var tempArr = [...prev, temp.get("name")];
                      var fooBar = [...new Set(tempArr)];
                      return fooBar;
                    }); */
                } else {
                  /*   setDisabledValues((prev) => {
                      var tempArrRmv = prev.filter((i) => i !== temp.get("name"));
                      return tempArrRmv;
                    }); */
                  x.set("visible", true);
                }
              }
            });
          }
        });
      });

      /*    if (flatLegends === true) {
           legend.markers.template.setAll({
             width: 13,
             height: 1,
           });
           legend.markerRectangles.template.setAll({
             cornerRadiusTL: 0,
             cornerRadiusTR: 0,
             cornerRadiusBL: 0,
             cornerRadiusBR: 0,
           });
         } else {
           // legend.markers.template.setAll({
           //   width: 13,
           //   height: 13,
           // });
           // legend.markerRectangles.template.setAll({
           //   cornerRadiusTL: 10,
           //   cornerRadiusTR: 10,
           //   cornerRadiusBL: 10,
           //   cornerRadiusBR: 10,
           // });
         } */
      legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: "400",
        fill: "#213345",
      });

      legend.labels.template.setAll({
        // maxWidth: 150,
        //centerY: 0, // if we want labels to be top-aligned
        oversizedBehavior: "wrap",
      });

      yAxis.axisHeader.set("paddingTop", 10);

      yAxis.axisHeader.children.push(am5.Label.new(root, {
        text: name,
        fontWeight: "500",
        fontSize: "12",
        /* position:"absolute",
        left:"100",
        top: "20", */

      }));

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "label",
          childLabel: childLabel,
          childValue: childValue,
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
          clustered: false,
        })
      );

      series.columns.template.setAll({
        // tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        // tooltipY: 0
        cornerRadiusTL: 7,
        cornerRadiusTR: 7,
        width: 30,
      });

      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {

          var tooltipDataItem = series?.get("tooltipDataItem");
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
          }

        });
        return text;
      });
      chart.leftAxesContainer.set("layout", root.verticalLayout);
      series.data.setAll(data);
      series.appear(1000);

      chart.series.values.length === chartLabels.length && legend.data.setAll(chart.series.values);
    }


    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        label.childLabel,
        label.childValue,
        index
      );
    });


    // createAxisAndSeries(100, false);
    // createAxisAndSeries(1000, true);
    //createAxisAndSeries(8000, true);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.zoomOutButton.set("forceHidden", true);
    /* 
        const scrollbar = am5.Scrollbar.new(root, {
          marginTop: 20,
          orientation: "horizontal",
        });
    
        chart.set("scrollbarX", scrollbar);
    
        chart.bottomAxesContainer.children.push(scrollbar);
    
        const color = "#e1e1e1";
    
        // this is the filled bar
        scrollbar.thumb.setAll({
          fill: "#dfdada",
    
          opacity: 1,
        });
    
        scrollbar.get("background").setAll({
          fill: "#dddddd",
          opacity: 0.5,
        });
    
        scrollbar.startGrip.setAll({
          crisp: true,
          strokeWidth: 0,
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          visible: true,
          stroke: 0,
          icon: am5.Picture.new(root, {
            interactive: true,
            src: zoomarrows,
            cursorOverStyle: "pointer",
            width: 20,
            height: 20,
          }),
        });
    
        scrollbar.endGrip.setAll({
          dx: -10,
          crisp: true,
          strokeWidth: 0,
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          visible: true,
          stroke: 0,
          icon: am5.Picture.new(root, {
            interactive: true,
            src: zoomarrows,
            cursorOverStyle: "pointer",
            width: 20,
            height: 20,
          }),
        }); */

    chart.appear(1000, 100);

    if (
      disabledLegendArray !== undefined ||
      disabledLegendArray !== null ||
      disabledLegendArray.length !== 0
    ) {
      function toggle(item) {
        if (item === undefined || item === null) return;
        var storedItem = item;
        chart.series.values.filter((i, index) => {
          if (storedItem.includes(i._settings.name)) {
            var series = chart.series.getIndex(index);

            series.chart.yAxes.values.filter((x, y) => {
              if (index === y) {
                x.set("visible", false);
              }
            });

            toggleSeries(series);
          }
        });
      }

      function toggleSeries(series) {
        if (series.get("visible")) {
          series.hide();
        } else {
          series.show();
        }
      }

      setTimeout(() => {
        toggle(disabledLegendArray);
      }, 10);
    }

    const div = document.getElementById(chartId); // Replace chartId with your actual div's ID

    if (chartLabels.length - disabledLegendArray.length === 1) {
      div.style.height = "400px";
    } else if (chartLabels.length - disabledLegendArray.length <= 2) {
      div.style.height = "400px";
    } else {
      const additionalHeight =
        (chartLabels.length - disabledLegendArray.length - 2) * 200; // Calculate additional height

      div.style.height = `${250 + additionalHeight}px`;
      div.style.minHeight = "350px";
    }
    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass} data-length={chartLabels.length} ></div>
    </Wrapper>
  );
};

PartitionedMultiAxisBarChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showBullets: PropTypes.bool,
  onBulletsClick: PropTypes.func,
  chartColorSet: PropTypes.array,
  disabledLegendArray: PropTypes.array,
};

export default PartitionedMultiAxisBarChart;
