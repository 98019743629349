const EngagementIcon = (props) => {
    return (

        <svg className={props.className} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.819 17.6708V15.8138C15.819 14.8325 15.4292 13.8914 14.7353 13.1975C14.0414 12.5036 13.1003 12.1138 12.119 12.1138H4.7C3.7187 12.1138 2.77759 12.5036 2.0837 13.1975C1.38982 13.8914 1 14.8325 1 15.8138V17.6658" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.1139 4.7C12.1139 5.43179 11.8969 6.14715 11.4903 6.75561C11.0837 7.36407 10.5059 7.83831 9.8298 8.11835C9.15371 8.3984 8.40976 8.47167 7.69203 8.32891C6.9743 8.18614 6.31503 7.83375 5.79757 7.31629C5.28012 6.79884 4.92773 6.13956 4.78496 5.42183C4.6422 4.7041 4.71547 3.96016 4.99551 3.28407C5.27556 2.60798 5.7498 2.03012 6.35826 1.62356C6.96672 1.217 7.68208 1 8.41387 1C9.39517 1 10.3363 1.38982 11.0302 2.0837C11.724 2.77759 12.1139 3.7187 12.1139 4.7Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.6188 35.6708V33.8138C35.6188 32.8325 35.229 31.8914 34.5351 31.1975C33.8412 30.5036 32.9001 30.1138 31.9188 30.1138H24.4998C23.5185 30.1138 22.5774 30.5036 21.8835 31.1975C21.1896 31.8914 20.7998 32.8325 20.7998 33.8138V35.6658" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.9137 22.7C31.9137 23.4318 31.6967 24.1471 31.2901 24.7556C30.8835 25.3641 30.3057 25.8383 29.6296 26.1184C28.9535 26.3984 28.2096 26.4717 27.4918 26.3289C26.7741 26.1861 26.1148 25.8337 25.5974 25.3163C25.0799 24.7988 24.7275 24.1396 24.5848 23.4218C24.442 22.7041 24.5153 21.9602 24.7953 21.2841C25.0754 20.608 25.5496 20.0301 26.1581 19.6236C26.7665 19.217 27.4819 19 28.2137 19C29.195 19 30.1361 19.3898 30.83 20.0837C31.5239 20.7776 31.9137 21.7187 31.9137 22.7Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.7998 4.41895H25.7168C26.4277 4.39928 27.1349 4.52812 27.7932 4.79724C28.4515 5.06637 29.0463 5.46987 29.5398 5.98194C30.2053 6.96486 30.4766 8.16217 30.2998 9.33594V14.2529" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M14.8188 33.2509H8.90184C8.19102 33.2707 7.48394 33.1421 6.82567 32.8731C6.1674 32.6042 5.57243 32.2009 5.07884 31.689C4.41338 30.706 4.14207 29.5087 4.31883 28.335V23.418" strokeWidth="1.5" strokeLinecap="round" />
        </svg>

    );
};

export default EngagementIcon;
