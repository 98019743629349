import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// PAGES
import SocialFacebookAudience from "./SocialFacebookAudience";
import SocialFacebookPage from "./SocialFacebookPage";
import SocialFacebookPost from "./SocialFacebookPost";

// UTILS
import Tab from "../../../../components/ui/Tab";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";


const SocialFacebook = ({ platformData }) => {
  const navigate = useNavigate();
  const params = useParams();

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData === undefined || userData === [] ? "" :
    userData.company === undefined || userData.company === [] ? "" :
      userData.company.id === undefined || userData.company.id === null ? "" :
        userData.company.id;

  let tabData = [];

  if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
    tabData = ["Page", "Post"];
  } else {
    tabData = ["Page", "Audience", "Post"];
  }

  const [selectedPrimaryTab, setSelectedTab] = useState(params.primaryTab === undefined ? tabData[0] : params.primaryTab);
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);


  const handleCallback = (tab) => {
    if (selectedPrimaryTab !== tab) {
      setSelectedTab(tab);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Facebook", tab)
  };

  useEffect(() => {
    setExportPageName("Social Facebook " + selectedPrimaryTab)
  }, [selectedPrimaryTab])


  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedPrimaryTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedPrimaryTab.toLowerCase() === "page" && <SocialFacebookPage topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
      {selectedPrimaryTab.toLowerCase() === "audience" && <SocialFacebookAudience topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
      {selectedPrimaryTab.toLowerCase() === "post" && <SocialFacebookPost topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
    </Wrapper>
  );
};

export default SocialFacebook;
