import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/esm/Col";
import { Dropdown } from "../../../shared/components/dropdown/dropdown"
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import IndiaMap from '../../../shared/components/map/indiamap/indiamap'
import { ProgressBar } from 'react-bootstrap';
import BusinessOverview from './RiBusinessOverview';
import RiMapFilter from "../ri-mapfilter/RiMapFilter";

export default class RIBusinessOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown_options: [{ "value": "storetype", "label": "Store Type" }, { "value": "location", "label": "Location" }]
        }
    }
    render() {
        const progress1 = 65; const progress2 = 25; const progress3 = 10;
        return (
            <Container className="p-0" fluid>
                <div className="d-flex flex-column">
                    <Col lg={3} sm={12}>
                        <div className="icz-rimapsection">
                            <Col lg={12} className="d-flex">
                                <Col lg={4} className="icz-rimaptitle">
                                    India
                                </Col>
                                <Col lg={8} className="icz-rimapfilter">
                                    <RiMapFilter />
                                    <div className="icz-ribtnwrapper">
                                        <button className="icz-rithemebtn">
                                            <IconDownload className="icz-riicon" /> </button>
                                    </div>
                                </Col>
                            </Col>

                            <Col lg={12} className="icz-rimapcontainer">
                                <IndiaMap />
                            </Col>

                            <div className="icz-rimapfooter">
                                <Col lg={12}>
                                    <ul className="icz-rilist-inline">
                                        <li><div className="icz-rilist-bullet1" /> North</li>
                                        <li><div className="icz-rilist-bullet2" /> West</li>
                                        <li><div className="icz-rilist-bullet3" /> South</li>
                                        <li><div className="icz-rilist-bullet4" /> East</li>
                                        <li><div className="icz-rilist-bullet5" /> APTS</li>
                                    </ul>
                                </Col>
                                <Col lg={12} className="icz-rimapprogressbar">
                                    <h4 className="icz-riprogresstitle">NSV Achievement Percentage</h4>
                                    <ProgressBar>
                                        <ProgressBar className="range1" variant="range1" now={progress1} key={1} label="0-69" />
                                        <ProgressBar className="range2" variant="range2" now={progress2} key={2} label="70-94" />
                                        <ProgressBar className="range3" variant="range3" now={progress3} key={3} label="95+" />
                                    </ProgressBar>
                                </Col>
                            </div>
                        </div>

                    </Col>


                    <Col lg={{ span: 9, offset: 3 }} sm={12} className="icz-ridatacontainer">
                        <BusinessOverview />
                    </Col>
                </div>
            </Container>
        );
    }
}
