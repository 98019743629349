const BusinessOverviewIcon = (props) => {
    return (
        <svg className={props.className} width="45" height="31" viewBox="0 0 45 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.21191 1C7.10911 1 6.21191 1.8972 6.21191 3V22C6.21191 23.1028 7.10911 24 8.21191 24H32.2119C33.3147 24 34.2119 23.1028 34.2119 22V3C34.2119 1.8972 33.3147 1 32.2119 1H8.21191ZM8.21191 0H32.2119C33.8688 0 35.2119 1.34314 35.2119 3V22C35.2119 23.6569 33.8688 25 32.2119 25H8.21191C6.55505 25 5.21191 23.6569 5.21191 22V3C5.21191 1.34314 6.55505 0 8.21191 0Z" fill="#486F88" />
            <path d="M13.2119 27.5H27.2119C27.6097 27.5 27.9913 27.658 28.2726 27.9393C28.5539 28.2206 28.7119 28.6022 28.7119 29C28.7119 29.1326 28.6592 29.2598 28.5655 29.3536C28.4717 29.4473 28.3445 29.5 28.2119 29.5H12.2119C12.0793 29.5 11.9521 29.4473 11.8584 29.3536C11.7646 29.2598 11.7119 29.1326 11.7119 29C11.7119 28.6022 11.8699 28.2206 12.1513 27.9393C12.4326 27.658 12.8141 27.5 13.2119 27.5Z" />
            <path d="M5.71191 18.0298H34.7119" />
            <path d="M20.2119 22.5C21.0403 22.5 21.7119 21.8284 21.7119 21C21.7119 20.1716 21.0403 19.5 20.2119 19.5C19.3835 19.5 18.7119 20.1716 18.7119 21C18.7119 21.8284 19.3835 22.5 20.2119 22.5Z" />
            <path d="M15.712 24.5L14.584 27.555" />
            <path d="M24.7119 24.5L25.8399 27.555" />
            <path d="M1.977 20.2319H6.861C7.12012 20.2319 7.36862 20.3349 7.55184 20.5181C7.73507 20.7013 7.838 20.9498 7.838 21.2089V29.0229C7.838 29.1512 7.81273 29.2783 7.76363 29.3968C7.71453 29.5154 7.64257 29.6231 7.55184 29.7138C7.46112 29.8045 7.35342 29.8765 7.23488 29.9256C7.11635 29.9747 6.9893 29.9999 6.861 29.9999H1.977C1.8487 29.9999 1.72165 29.9747 1.60312 29.9256C1.48458 29.8765 1.37688 29.8045 1.28616 29.7138C1.19543 29.6231 1.12347 29.5154 1.07437 29.3968C1.02527 29.2783 1 29.1512 1 29.0229V21.2089C1 20.9498 1.10293 20.7013 1.28616 20.5181C1.46938 20.3349 1.71788 20.2319 1.977 20.2319Z" fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.2119 13H34.2119C33.1073 13 32.2119 13.8954 32.2119 15V28C32.2119 29.1046 33.1073 30 34.2119 30H42.2119C43.3165 30 44.2119 29.1046 44.2119 28V15C44.2119 13.8954 43.3165 13 42.2119 13Z" fill="none" />
            <path d="M42.2119 13.5H34.2119C33.3835 13.5 32.7119 14.1716 32.7119 15V28C32.7119 28.8284 33.3835 29.5 34.2119 29.5H42.2119C43.0403 29.5 43.7119 28.8284 43.7119 28V15C43.7119 14.1716 43.0403 13.5 42.2119 13.5Z" />
            <path d="M32.7119 15.9932H43.9819" />
            <path d="M32.7119 26.0972H43.9819" />
        </svg>
    );
};

export default BusinessOverviewIcon;
