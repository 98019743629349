import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default class SankeyChart extends Component {
  componentDidMount() {

    let info = this.props
    let chart_id = info['chart_id'];
    let data = info['graph-data'];
    let chart = am4core.create(chart_id, am4charts.SankeyDiagram);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = data;

    let hoverState = chart.links.template.states.create("hover");
    hoverState.properties.fillOpacity = 0.6;

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    chart.colors.list = [
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
      am4core.color("#07c180"),
    ];

    chart.tooltip.getFillFromObject = false;
    chart.tooltip.background.fill = am4core.color("#213345");
    chart.tooltip.background.stroke = am4core.color("#213345");
    chart.tooltip.background.cornerRadius = 5;
    chart.tooltip.label.padding(10, 10, 10, 10);
    chart.tooltip.label.fontSize = 13;
    chart.tooltip.autoTextColor = false;
    chart.tooltip.label.fill = am4core.color("#fff");
    chart.tooltip.pointerOrientation = "vertical";
    chart.tooltip.label.minWidth = 150;
    chart.fontSize = 12;

    // chart.links.template.colorMode = "solid";
    // chart.links.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    // chart.links.template.fillOpacity = 0.1;
    // chart.links.template.tooltipText = "";
    // for right-most label to fit


    // chart.links.template.events.on("out", function (event) {
    //   chart.links.each(function (link) {
    //     link.isHover = false;
    //   })
    // })
    chart.paddingRight = 90;
    chart.paddingTop = 10;
    chart.paddingBottom = 10;

    // make nodes draggable
    var nodeTemplate = chart.nodes.template;
    nodeTemplate.inert = true;
    nodeTemplate.showSystemTooltip = true;
    nodeTemplate.width = 30;

    // make nodes draggable
    // var nodeTemplate = chart.nodes.template;
    // nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
    // nodeTemplate.showSystemTooltip = true;
    // nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer
  }

  render() {

    let chart_class = this.props.chart_class;
    let chart_id = this.props.chart_id;

    return (
      <div>
        <div id={chart_id} className={chart_class}></div>
      </div>
    )
  }
}
