import React, { Component } from 'react'
import Col from 'react-bootstrap/esm/Col';
import Wrapper from '../../../helpers/wrapper';

import IconContribution from '../../../../assets/images/icons/contributionIcon';
import IconImpressions from '../../../../assets/images/icons/impressionsIcon';
import IconSmiley from '../../../../assets/images/icons/smileyIcon';
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import IconUserGroup from "../../../../assets/images/icons/usergroupIcon";
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import { Carousel } from '../../../shared/components/carousel/carousel';
import { Dropdown } from "../../../shared/components/dropdown/dropdown"
import LayeredColumnChart from '../../../shared/components/charts/LayeredColumnChart';
import RiTreegraph from '../../../shared/components/charts/RiTreegraph';
import CohortGraph from "react-cohort-graph";

import { Tab } from "../../../shared/components/tabs/tab";
import { TabNav } from "../../../shared/components/tabs/tabnav";
import RiCustomerProduct from './RiCustomerProduct';
import RiCustomerBrand from './RiCustomerBrand';
import RiCustomerCampaigns from './RiCustomerCampaigns';
import { SalesCard } from '../../../shared/components/cards/risalescard/risalescard';
import RiSalesHBarChart from '../../../shared/components/charts/RiSalesHBarChart';
import RiSalesVBarChart from '../../../shared/components/charts/RiSalesVBarChart';
import PieChartwithLegends from '../../../shared/components/charts/RiPieChartwithLegends';
import ColumnLineDualAxisChart from '../../../shared/components/charts/ColumnLineDualAxisChart';
import ChartExport from '../../../shared/components/export/ChartExport';

export class CustomerStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_options: [{ "value": "popular", "label": "Popular" }, { "value": "nsv", "label": "NSV" }, { "value": "units-sold", "label": "Units Sold" }, { "value": "mix", "label": "Mix%" }, { "value": "nm", "label": "NM%" }, { "value": "asp", "label": "ASP" }, { "value": "sell-thru", "label": "Sell Thru" }],
      asp_dropdown_options: [{ "value": "asp", "label": "ASP" }, { "value": "nsv", "label": "NSV" }, { "value": "ats", "label": "ATS" }, { "value": "bills", "label": "Bills" }, { "value": "nm", "label": "NM%" }, { "value": "carpet-area", "label": "Carpet Area" }, { "value": "sell-thru", "label": "Sell Thru Rate%" }, { "value": "recovery", "label": "Recovery" }, { "value": "margin", "label": "Margin" }],
      units_dropdown_options: [{ "value": "asp", "label": "ASP" }, { "value": "nsv", "label": "NSV" }, { "value": "ats", "label": "ATS" }, { "value": "bills", "label": "Bills" }, { "value": "nm", "label": "NM%" }, { "value": "carpet-area", "label": "Carpet Area" }, { "value": "sell-thru", "label": "Sell Thru Rate%" }, { "value": "recovery", "label": "Recovery" }, { "value": "margin", "label": "Margin" }],
      customer: [{ "category": "Big Wallets", "value": 43, "value2": 53 }, { "category": "Bargain Hunters", "value": 75, "value2": 63 }, { "category": "Potential Loyalists", "value": 55, "value2": 83 }, { "category": "Active Loyalists", "value": 20, "value2": 25 }, { "category": "Hibernating", "value": 36, "value2": 39 }, { "category": "Need Attention", "value": 37, "value2": 39 }, { "category": "Lost", "value": 73, "value2": 64 }],
      linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
      treegraph: [{ "name": "APPARELS", "nsv": 249868959, "value": 811, "asp": 810.61, "units_sold": 308247, "store_nsv": 1119151444, "total_nsv": 1119151444, "nsv_mix": 85.18, "color": "#038673" }, { "name": "LIFESTYLE", "nsv": 42912209, "value": 868, "asp": 868.25, "units_sold": 49424, "store_nsv": 1119151444, "total_nsv": 1119151444, "nsv_mix": 14.63, "color": "#038673" }, { "name": "NSM & OTHERS", "nsv": 553437, "value": 3, "asp": 2.98, "units_sold": 185639, "store_nsv": 1119151444, "total_nsv": 1119151444, "nsv_mix": 0.19, "color": "#d51f30" }],
      selectedSubNav: 'Product',
      agedata: [{ "category": ">45", "value": "3.2" }, { "category": "35-45", "value": "2.2" }, { "category": "25-35", "value": "4.4" }, { "category": "18-25", "value": "3.8" }, { "category": ">18", "value": "1.8" }],
      basketdata: [{ "category": "1", "value": "35" }, { "category": "2-3", "value": "58" }, { "category": "3-4", "value": "52" }, { "category": "4<", "value": "37" }],
      btfdata: [{ "name": "BTF", "value": 54 }, { "name": "Non BTF", "value": 46 }],
      customerdata: [{ "name": "Exciting", "value": 60 }, { "name": "New", "value": 40 }],
      customer_status: [{ "name": "Active", "value": 50 }, { "name": "Lapsers", "value": 20 }, { "name": "Non Active", "value": 30 }],
      genderdata: [{ "name": "Male", "value": 55 }, { "name": "Female", "value": 20 }, { "name": "Others", "value": 25 }],
      frequencydata: [{ "category": "1", "value": "35" }, { "category": "2-3", "value": "58" }, { "category": "3-4", "value": "52" }, { "category": "4<", "value": "37" }],
      atsdata: [{ "category": ">4k", "value": "3.2" }, { "category": "3-4k", "value": "2.2" }, { "category": "2.5-3k", "value": "4.4" }, { "category": "<2.5k", "value": "3.8" }],
      customerltv: [{ "category": "40k<", "value": "3.2" }, { "category": "25-40k", "value": "2.2" }, { "category": "15-25k", "value": "4.4" }, { "category": ">15k", "value": "3.8" }],
      basketdata: [{ "category": "1", "value": "35" }, { "category": "2-3", "value": "58" }, { "category": "3-4", "value": "52" }, { "category": "4<", "value": "37" }],
      md_data: [{ "category": "50k", "value": "3.2" }, { "category": "35k", "value": "4.4" }, { "category": "15k", "value": "3.8" }, { "category": "5k", "value": "3.8" }],
      coupondata: [{ "name": "Coupon", "value": 54 }, { "name": "Non Coupon", "value": 46 }],
      customer_markdown: '[{"range":"10","count":4000,"type":40000000},{"range":"20","count":11000,"type":40000000},{"range":"30","count":18000,"type":55000000},{"range":"40","count":20000,"type":60000000},{"range":"50","count":16000,"type":30000000},{"range":"60","count":32000,"type":20000000}, {"range":"70","count":6000,"type":12000000}]',

      Deep_Dive_level: 0,
      Deep_Dive_Name: [],
      category_dropdown_value: "nsv",
    }
  }

  setSelectedSubNav = (tab) => {
    this.setState({ selectedSubNav: tab });
  }

  handleNSVdeepDiveCallback = (childData) => {
  }

  handleNSVdeepDiveCallback2 = (childData) => {
  }

  render() {
    const CarouselSettings = {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [

        {
          breakpoint: 1202,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 800,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        }
      ]
    }

    return (
      <Wrapper>
        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <Col lg={12} className="icz-sectioncard d-flex flex-row">
              <Col className="icz-chartsection" lg={9} sm={12} md={12}>
                <div className="icz-cardheader">
                  <Col className="icz-cardtitle">
                    Customer Parameters
                  </Col>
                  <Col className="icz-cardfilter">
                    <div className="icz-cardbtnwrapper">
                      <ChartExport
                        ChartId='customer_parameters'
                        downloadName="Customer Parameters"
                        export_data={this.state.customer}
                      />
                    </div>
                  </Col>
                </div>
                <div className="">
                  <LayeredColumnChart chart_id="customer_parameters" chart_class="icz-sectionchart" graph-data={this.state.customer} Ytitle={""} metric_1={"Achived %"} metric_2={"Target"} bar1_color={"#4cc3fd"} bar2_color={"#11a1fd"} />
                </div>
              </Col>
              <Col lg={3} sm={12} md={12} className="icz-chartdetailssection">
                <div className="icz-detailsdatasection  icz-detailscroll">
                  <div className="icz-detailsdatatab">
                    <div className="icz-iconwrapper">
                      <IconImpressions className="icz-icon" />
                    </div>
                    <div className="icz-titlewrapper">
                      <div className="icz-subtitle">
                        <b>BIG WALEETS</b>
                      </div>
                    </div>
                  </div>
                  <div className="icz-detailsdatatab">
                    <div className="icz-iconwrapper">
                      <IconSmiley className="icz-icon" />
                    </div>
                    <div className="icz-titlewrapper">
                      <div className="icz-subtitle">
                        <b>Persona Influence</b>
                      </div>
                      <div className="icz-subtitle">
                        Store ATS, High ASP products purchase
                      </div>
                    </div>
                  </div>
                  <div className="icz-detailsdatatab">
                    <div className="icz-iconwrapper">
                      <IconContribution className="icz-icon" />
                    </div>
                    <div className="icz-titlewrapper">
                      <div className="icz-subtitle">
                        <b>Scores</b>
                      </div>
                      <div className="icz-subtitle">
                        R- High
                        F- High<br />
                        M- High
                        U- Med
                      </div>
                    </div>
                  </div>
                  <div className="icz-detailsdatatab">
                    <div className="icz-iconwrapper">
                      <IconImpressions className="icz-icon" />
                    </div>
                    <div className="icz-titlewrapper">
                      <div className="icz-subtitle">
                        <b>Benchmark</b>
                      </div>
                      <div className="icz-subtitle">
                        Target: 40% <br />
                        Ach: 30%
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </div>
        </div>

        <div className="grid grid_cols_1">
          <div className="grid col_span_4 icz-carouselcontainer">
            <Carousel
              Settings={CarouselSettings}
              class={"icz-cardcontainer"}
              SliderCards={[
                <ChartCard
                  index="1"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="FF"
                  RowOneCurrentValue="250000"
                  RowOnePreviousValue="200000"
                  IsNumber={'money'}
                  chart={<Linegraph_sparkline card_id="icz-ff" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,

                <ChartCard
                  index="2"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="No.Of Customers"
                  RowOneCurrentValue="60000"
                  RowOnePreviousValue="70000"
                  IsNumber={'number'}
                  chart={<Linegraph_sparkline card_id="icz-customer" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="3"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="NSV"
                  RowOneCurrentValue="250000000"
                  RowOnePreviousValue="200000000"
                  RowOneTitle="engagement"
                  IsNumber={'money'}
                  chart={<Linegraph_sparkline card_id="icz-nsv" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="4"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Bills"
                  RowOneCurrentValue="100000"
                  RowOnePreviousValue="119900"
                  IsNumber={'money'}
                  chart={<Linegraph_sparkline card_id="icz-bills" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="5"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Conv Rate%"
                  RowOneCurrentValue="40"
                  RowOnePreviousValue="50"
                  IsNumber={"percentage"}
                  chart={<Linegraph_sparkline card_id="icz-convrate" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="6"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="ATS"
                  RowOneCurrentValue="2500"
                  RowOnePreviousValue="1667"
                  RowOneTitle="engagement"
                  IsNumber={"number"}
                  chart={<Linegraph_sparkline card_id="icz-ats" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="7"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="LTV"
                  RowOneCurrentValue="8200"
                  RowOnePreviousValue="10600"
                  IsNumber={"number"}
                  chart={<Linegraph_sparkline card_id="icz-ltv" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="8"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Frequency"
                  RowOneCurrentValue="1598"
                  RowOnePreviousValue="1689"
                  IsNumber={"number"}
                  chart={<Linegraph_sparkline card_id="icz-frequency" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="9"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Basket Size"
                  RowOneCurrentValue="3330"
                  RowOnePreviousValue="2149"
                  IsNumber={"number"}
                  chart={<Linegraph_sparkline card_id="icz-basketsize" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />,
                <ChartCard
                  index="10"
                  CardClass="icz-cardwrapper"
                  CardIcon={<IconWebsite className="icz-icon" />}
                  CardTitle="Returning rate%"
                  RowOneCurrentValue="40"
                  RowOnePreviousValue="58.33"
                  IsNumber={"percentage"}
                  chart={<Linegraph_sparkline card_id="icz-returningrate" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                />
              ]}>

            </Carousel>
          </div>
        </div>

        <div className="icz-sectiontabscontainer">
          <div className="icz-tabscontrols">
            <TabNav tabs={['Product', 'Brand', 'Campaigns']} icon={[<IconUserGroup className="icz-tabicon" />, <IconWebsite className="icz-tabicon" />, <IconWebsite className="icz-tabicon" />]} selected={this.state.selectedSubNav} setSelected={this.setSelectedSubNav}>
              <Tab isSelected={this.state.selectedSubNav === 'Product'}>
                <RiCustomerProduct />
              </Tab>
              <Tab isSelected={this.state.selectedSubNav === 'Brand'}>
                <RiCustomerBrand />
              </Tab>
              <Tab isSelected={this.state.selectedSubNav === 'Campaigns'}>
                <RiCustomerCampaigns />
              </Tab>
            </TabNav>
          </div>
        </div>

        <div className="grid grid_cols_1">
          <Col lg={12} className="icz-ricardheader">
            <Col lg={12} className="icz-ricardtitle">
              Customer Profiling
            </Col>

          </Col>
        </div>

        <div className="grid grid_cols_1">
          <div className="grid col_span_4 icz-carouselcontainer">
            <Carousel
              Settings={CarouselSettings}
              class={"icz-cardcontainer"}
              SliderCards={[
                <SalesCard
                  index="1"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Members"
                  chart={<PieChartwithLegends card_id="menmers" card_class="icz-risalescardchart" graph-data={this.state.btfdata} section1_color={"#5a75f9"} section2_color={"#07c180"} />}
                />,
                <SalesCard
                  index="2"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Customer Type"
                  chart={<PieChartwithLegends card_id="customer" card_class="icz-risalescardchart" graph-data={this.state.customerdata} section1_color={"#11a1fd"} section2_color={"#ff9931"} />}
                />,
                <SalesCard
                  index="3"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Customer Status"
                  chart={<PieChartwithLegends card_id="customer_status" card_class="icz-risalescardchart" graph-data={this.state.customer_status} section1_color={"#11a1fd"} section2_color={"#ff9931"} section3_color={"#07c180"} />}
                />,
                <SalesCard
                  index="4"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Audience Age"
                  chart={<RiSalesHBarChart chart_id="audience_age" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"Age Group"} graph-data={this.state.agedata} bar_color={"#11a1fd"} />}
                />,
                <SalesCard
                  index="5"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Audience Gender"
                  chart={<PieChartwithLegends card_id="audience_gender" card_class="icz-risalescardchart" graph-data={this.state.genderdata} section1_color={"#ff9931"} section2_color={"#07c180"} section3_color={"#5a75f9"} />}
                />
              ]}>

            </Carousel>
          </div>
        </div>

        <div className="grid grid_cols_1">
          <Col lg={12} className="icz-ricardheader">
            <Col lg={12} className="icz-ricardtitle">
              Customer Bill deep-dive
            </Col>
          </Col>
        </div>

        <div className="grid grid_cols_1">
          <div className="grid col_span_4 icz-carouselcontainer">
            <Carousel
              Settings={CarouselSettings}
              class={"icz-cardcontainer"}
              SliderCards={[
                <SalesCard
                  index="1"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Frequency"
                  chart={<RiSalesVBarChart chart_id="frequency" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"No. of Visits"} graph-data={this.state.frequencydata} bar_color={"#11a1fd"} />}
                />,
                <SalesCard
                  index="2"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="ATS Value"
                  chart={<RiSalesHBarChart chart_id="ats_value" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"No. of Bills"} graph-data={this.state.atsdata} bar_color={"#ff9931"} />}
                />,
                <SalesCard
                  index="3"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Customer LTV"
                  chart={<RiSalesHBarChart chart_id="customer_ltv" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"No. of Customers"} graph-data={this.state.customerltv} bar_color={"#ff9931"} />}
                />,
                <SalesCard
                  index="4"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Basket Size"
                  chart={<RiSalesVBarChart chart_id="basket_size" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"No. of Bills"} graph-data={this.state.basketdata} bar_color={"#11a1fd"} />}
                />,
                <SalesCard
                  index="5"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="MD"
                  chart={<RiSalesHBarChart chart_id="md" chart_class="icz-risalescardchart" Ytitle={""} metric_1={"No."} graph-data={this.state.md_data} bar_color={"#ff9931"} />}
                />,
                <SalesCard
                  index="6"
                  CardClass="icz-rislaescardwrapper"
                  CardTitle="Coupon/Non Coupon Bills"
                  chart={<PieChartwithLegends card_id="coupons" card_class="icz-risalescardchart" graph-data={this.state.coupondata} section1_color={"#5a75f9"} section2_color={"#07c180"} />}
                />
              ]}>

            </Carousel>
          </div>
        </div>

        <div className="grid grid_cols_1 icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <div className="icz-cardheader flex-wrap justify-content-between">
                <div className="icz-cardtitle">
                  Cohort Analysis
                </div>
                <div className="icz-cardfilter" style={{ flexWrap: "nowrap" }}>
                  <Dropdown dropdown_options={this.state.asp_dropdown_options} dropdown_placeholder="Monthly" parentCallback={this.handleCallback} />
                  <Dropdown dropdown_options={this.state.units_dropdown_options} dropdown_placeholder="Returning Rate" parentCallback={this.handleCallback} />
                  <ChartExport
                    ChartId='cohortAnalysis'
                    downloadName=" Cohort Analysis"
                    export_data={[]}
                  />
                </div>
              </div>
              {/* <div className="icz-cardheader flex-wrap">
                <div className="icz-cardtitle">
                  Cohort Analysis
                </div>
                <div className="icz-cardfilter">
                  <div className="icz-cardbtnwrapper">
                    <Dropdown dropdown_options={this.state.asp_dropdown_options} dropdown_placeholder="Monthly" parentCallback={this.handleCallback} />
                    <Dropdown dropdown_options={this.state.units_dropdown_options} dropdown_placeholder="Returning Rate" parentCallback={this.handleCallback} />
                    <ChartExport
                      ChartId='cohortAnalysis'
                      downloadName=" Cohort Analysis"
                      export_data={[]}
                    />
                  </div>
                </div>
              </div> */}
              <div className="icz-cohortgraph ">
                <CohortGraph className="icz-cohort"
                  data={{
                    months: {
                      "June": [11567, 331, 135, 116, 90, 48],
                      "May": [11132, 334, 154, 78, 65, 13],
                      "Apr": [11497, 340, 111, 66, 20],
                      "March": [11593, 247, 87, 39],
                      "Feb": [8710, 206, 38],
                      "Jan": [7067, 89]
                    }
                  }}
                  defaultValueType={"value"}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="grid grid_cols_1 icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Customer Purchase Behaviour
                </Col>
                <Col className="icz-cardfilter">
                  <Dropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="Popular" parentCallback={this.handleCallback} />
                  <ChartExport
                    ChartId='customerPurchaseBehaviour'
                    downloadName="Customer Purchase Behaviour"
                    export_data={this.state.treegraph}
                  />
                </Col>
              </Col>
              <div className="">
                <RiTreegraph card_id="customerPurchaseBehaviour" card_class="icz-sectionchart" graph-data={this.state.treegraph}
                  // graph-data={this.state.treegraph1}
                  parentCallback={this.handleNSVdeepDiveCallback}
                  parentCallback2={this.handleNSVdeepDiveCallback2}
                  Level_Counter={this.state.Deep_Dive_level}
                  Deep_Dive_BreadCrumb={this.state.Deep_Dive_Name}
                  isnumber={'money'}
                  dropdownValue={this.state.category_dropdown_value}
                />
              </div>
              <div>
                <Col lg={12} className="icz-ridatabullet">
                  <ul className="icz-ridatalist-inline">
                    <li>Area = Units</li>
                    <li><div className="icz-ridatalist-bullet5" /> High ASP</li>
                    <li><div className="icz-ridatalist-bullet2" /> Med ASP</li>
                    <li><div className="icz-ridatalist-bullet6" /> Low ASP</li>

                  </ul>
                </Col>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid_cols_1 icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Customer Markdown Association
                </Col>
                <Col className="icz-cardfilter">
                  <ChartExport
                    ChartId='customerMarkdownAssociation'
                    downloadName="Customer Markdown Association"
                    export_data={this.state.customer_markdown}
                  />
                </Col>
              </Col>
              <div className="">
                <ColumnLineDualAxisChart chart_id="customerMarkdownAssociation" chart_class="icz-sectionchart" metric_1={""} metric_2={""} Xtitle={"Markdown"} Ytitle={"Units"} Ytitle2={"NSV"} graph-data={this.state.customer_markdown} />
              </div>
              <div>
                <Col lg={12} className="icz-ridatabullet">
                  <ul className="icz-ridatalist-inline">
                    <li><div className="icz-ridatalist-bullet5" /> High units per bill</li>
                    <li><div className="icz-ridatalist-bullet2" /> Avg units per bill</li>
                    <li><div className="icz-ridatalist-bullet6" /> Low units per bill</li>

                  </ul>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}
