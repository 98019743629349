import { useState } from "react";
import Wrapper from "../components/helpers/Wrapper";
import PageHeader from "../components/layout/pageHeader";
// import PieofaPieChart from "../components/ui/charts/PieofaPieChart";
// import PieofaPieChartTest from "../components/ui/charts/PieofaPieChartTest";
// import TextFormatter from "../utils/TextFormatter";
// import { SumOfArrayChilds } from "../utils/Utils";
// import StackedClusteredBarChart from "../components/ui/charts/StackedClusteredBarChart";
// import SimpleTreeMap from "../components/ui/charts/SimpleTreeMap";
// import Dropdown from "../components/ui/dropdown/Dropdown";
import CreatableSelect from 'react-select/creatable';
import Datatable from "../components/ui/tables/Datatable";
import { CampaignAdminData, CampaignAdminHeaderData } from "../data/tableDataSamples";
import { useEffect } from "react";


export const DashboardTest = () => {

	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState(null);
	const [options, setOptions] = useState([
		{ label: 'one', value: '1' },
		{ label: 'two', value: '2' },
		{ label: 'three', value: '3' },
		{ label: 'four', value: '4' },
	]);

	const [selectedObject, setSelectedObject] = useState({})

	const handleCreate = (inputValue) => {
		setIsLoading(true);
		setTimeout(() => {
			const newOption = createOption(inputValue);
			setIsLoading(false);
			setOptions((prev) => [...prev, newOption]);
			setValue(newOption);
		}, 1000);
	};

	const createOption = (label) => ({
		label,
		value: label.toLowerCase().replace(/\W/g, ''),
	});




	return (
		<Wrapper>
			<div className="content">
				<div className="content_section">
					<PageHeader pageTitle={"Test"} />
					<div className="page_content">
						<div className="dashboard_home">
							<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
								<div className="grid col_span_4">
									<div className="section_card_header">
										<h2 className="section_card_title">Table Test</h2>
									</div>
								</div>


								<div className="grid grid_cols_4 ">
									<div className="grid col_span_3"></div>
									<div className="grid col_span_1">

										<div className="section_card_body">

										</div>
									</div>
								</div>
								<div className="grid grid_cols_4 ">
									<div className="grid col_span_4">

										<div className="table_wrapper p_t_0 p_b_0">
											<Datatable

												tableTitle={"Summary"}
												tableHeader={CampaignAdminHeaderData}
												tableData={CampaignAdminData}
												tableLength={10}
												isFooterShow={true}
												searchPlaceHolder={"Search Campaign"}
												tableID={"summary_table"}
											// createableDd={
											// 	<CreatableSelect
											// 		isClearable
											// 		isMulti
											// 		options={options}
											// 		isDisabled={isLoading}
											// 		isLoading={isLoading}
											// 		onChange={(newValue) => setValue(newValue)}
											// 		onCreateOption={handleCreate}
											// 		value={value}
											// 		formatCreateLabel={(el) => 'Create New Bucket "' + el + '"'}
											// 	/>
											// }
											// createableOptions={options}
											// createableSetValue={setValue}
											/>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</Wrapper >
	)
}
export default DashboardTest;