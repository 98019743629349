const RetailIntelligenceIcon = (props) => {
    return (
        <svg className={props.className} width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.35205 13.6729V29.4999" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M35.9688 13.6729V29.4999" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M22.1689 15H10.1689C9.61666 15 9.16895 15.4477 9.16895 16V24C9.16895 24.5523 9.61666 25 10.1689 25H22.1689C22.7212 25 23.1689 24.5523 23.1689 24V16C23.1689 15.4477 22.7212 15 22.1689 15Z" fill="none" />
            <path d="M22.1689 15.75H10.1689C10.0309 15.75 9.91895 15.8619 9.91895 16V24C9.91895 24.1381 10.0309 24.25 10.1689 24.25H22.1689C22.307 24.25 22.4189 24.1381 22.4189 24V16C22.4189 15.8619 22.307 15.75 22.1689 15.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M26.1689 15H32.1689C32.4342 15 32.6885 15.1054 32.8761 15.2929C33.0636 15.4804 33.1689 15.7348 33.1689 16V30H25.1689V16C25.1689 15.7348 25.2743 15.4804 25.4618 15.2929C25.6494 15.1054 25.9037 15 26.1689 15Z" fill="none" />
            <path d="M26.1689 15.75H32.1689C32.2352 15.75 32.2988 15.7763 32.3457 15.8232C32.3926 15.8701 32.4189 15.9337 32.4189 16V28.5C32.4189 28.6989 32.3399 28.8897 32.1993 29.0303C32.0586 29.171 31.8679 29.25 31.6689 29.25H26.6689C26.47 29.25 26.2793 29.171 26.1386 29.0303C25.998 28.8897 25.9189 28.6989 25.9189 28.5V16C25.9189 15.9337 25.9453 15.8701 25.9922 15.8232C26.0391 15.7763 26.1026 15.75 26.1689 15.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M5.96523 4.16992L2.53219 10.4385H38.518L35.435 4.16992H5.96523ZM5.07651 2.66992H36.3689L40.9273 11.9385H0.000488281L5.07651 2.66992Z" fill="none" />
            <path d="M38.1689 29.75H3.16895C2.47859 29.75 1.91895 30.3096 1.91895 31V33C1.91895 33.6904 2.47859 34.25 3.16895 34.25H38.1689C38.8593 34.25 39.4189 33.6904 39.4189 33V31C39.4189 30.3096 38.8593 29.75 38.1689 29.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M36.1689 0.75H5.16895C4.47859 0.75 3.91895 1.30964 3.91895 2C3.91895 2.69036 4.47859 3.25 5.16895 3.25H36.1689C36.8593 3.25 37.4189 2.69036 37.4189 2C37.4189 1.30964 36.8593 0.75 36.1689 0.75Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M13.966 3.14893L9.479 11.2809" stroke="#486F88" strokeWidth="1.5" />
            <path d="M27.3579 3.14893L31.8449 11.2809" stroke="#486F88" strokeWidth="1.5" />
            <path d="M20.6602 3.38818V11.0422" stroke="#486F88" strokeWidth="1.5" />
        </svg>
    )
}

export default RetailIntelligenceIcon;
