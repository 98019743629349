import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Container, Image } from 'react-bootstrap';
import "../../ecom/ecom.scss";
import Piechart from '../../../shared/components/charts/Piechart';
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import { Carousel } from '../../../shared/components/carousel/carousel';
import '../../../shared/components/cards/card.scss';
import Wrapper from '../../../helpers/wrapper';
import IconWebsite from '../.././../../assets/images/icons/websiteIcon';
import { Link } from 'react-router-dom'
import { DailydataDropdown } from '../../../shared/components/dropdown/dailydatadropdown';
import IconFacebook from '../../../../assets/images/icons/facebookIcon';
import IconTwitter from '../../../../assets/images/icons/twitterIcon'
import IconInstagram from '../../../../assets/images/icons/instagramIcon';
import IconImpressions from '../../../../assets/images/icons/impressionsIcon';
import IconGoogle from '../../../../assets/images/icons/googleIcon'
import DataTable from '../../../shared/components/Datatable/DataTable';

import { NumberFormatter } from "../../../shared/utilities/numberformatter";
import MultiLine_LineChart from '../../../shared/components/charts/multiLineChart';
import Sunbrust_chart from '../../../shared/components/charts/Sunbrust_chart';
import { LocationBarChart } from '../../../shared/components/charts/LocationBarChart';
import DatePicker from '../../../shared/components/datepicker/datepicker';
import IconPdf from '../../../../assets/images/icons/pdfIcon';

import StickyLogo from '../../../../assets/images/icons/icon.png';
import { Loader } from '../../../shared/components/loader/loader';
import '../../../layout/pageheader/pageheader.scss'

import { API_URL_1, isMap } from '../../../helpers/constant';
import PageHeader from '../../../../../components/layout/pageHeader';
import { CountryCode } from '../../../../../constants/countryCurrency';
import ChartExport from '../../../shared/components/export/ChartExport';


export class Paid extends Component {
    constructor(props) {
        super(props);
        this.state = {

            trafficeChannel: '',

            // dropdown_options: [{ "value": "impression", "label": "Impression" }, { "value": "clicks", "label": "Clicks" }, { "value": "spends", "label": "Spends" },],
            datatabledropdown_options: [
                { value: '', label: 'All' },
                { value: 'Display', label: 'Display' },
                { value: 'yahoo', label: 'yahoo' },
                { value: 'Search', label: 'Search' },
                { value: 'Facebook', label: 'Facebook' },
                { value: 'apsense.com', label: 'apsense.com' },
                { value: 'toysrus.aceturtle.in', label: 'toysrus.aceturtle.in' },
                { value: 'accounts.google.com', label: 'accounts.google.com' },
                { value: 'instagram.com', label: 'instagram.com' },
                { value: 'baidu', label: 'baidu' },
                { value: 'urlopener.net', label: 'urlopener.net' },
                { value: 't.co', label: 't.co' },
                { value: 'en.m.wikipedia.org', label: 'en.m.wikipedia.org' },
                { value: 'lee.com', label: 'lee.com' },
                { value: 'lm.facebook.com', label: 'lm.facebook.com' },
                { value: 'aceturtle.com', label: 'aceturtle.com' },
                { value: 'HS_carouselcards', label: 'HS_carouselcards' },
                { value: 'YT_21sec', label: 'YT_21sec' },
                { value: 'zestmoney.in', label: 'zestmoney.in' },
                { value: 'l.facebook.com', label: 'l.facebook.com' },
                { value: 'accuweather.com', label: 'accuweather.com' },
                { value: 'bing', label: 'bing' },
                { value: 'google', label: 'google' },
                { value: 'facebook', label: 'facebook' },
                { value: 'dealspotr.com', label: 'dealspotr.com' },
                { value: 'l.instagram.com', label: 'l.instagram.com' },
                { value: 'm.indiacustomercare.com', label: 'm.indiacustomercare.com' },
                { value: 'localhost:63342', label: 'localhost:63342' },
                { value: 'OneNative', label: 'OneNative' },
                { value: 'Email', label: 'Email' },
                { value: 'Discovery', label: 'Discovery' },
                { value: '(direct)', label: '(direct)' },
                { value: 'in.search.yahoo.com', label: 'in.search.yahoo.com' },
                { value: 'en.wikipedia.org', label: 'en.wikipedia.org' },
                { value: 'duckduckgo', label: 'duckduckgo' },
                { value: 'mail.google.com', label: 'mail.google.com' },
                { value: 'googleadservices.com', label: 'googleadservices.com' },
                { value: 'semrush.com', label: 'semrush.com' },
                { value: 'quora.com', label: 'quora.com' },
                { value: 'SMS', label: 'SMS' },
                { value: 'web.skype.com', label: 'web.skype.com' },
                { value: 'babiesrus.aceturtle.in', label: 'babiesrus.aceturtle.in' },
                { value: 'Instagram', label: 'Instagram' },
                { value: 'zestmoney', label: 'zestmoney' },
                { value: 'YT_40video', label: 'YT_40video' },
                { value: 'facebook.com', label: 'facebook.com' },
                { value: 'ecosia.org', label: 'ecosia.org' },
                { value: 'linkedin.com', label: 'linkedin.com' },
                { value: 'snapchat.com', label: 'snapchat.com' },
                { value: 'accounts.google.co.in', label: 'accounts.google.co.in' },
                { value: 'silverpush_affiliate', label: 'silverpush_affiliate' },
                { value: 'api.razorpay.com', label: 'api.razorpay.com' },
                { value: 'Ace Turtle', label: 'Ace Turtle' },
                { value: 'petalsearch.com', label: 'petalsearch.com' },
                { value: 'mercury-t2.phonepe.com', label: 'mercury-t2.phonepe.com' },
                { value: 'industryfreak.com', label: 'industryfreak.com' },
                { value: 'imasdk.googleapis.com', label: 'imasdk.googleapis.com' },
                { value: 'leejeans-ap.com', label: 'leejeans-ap.com' },
                { value: 'analytics.google.com', label: 'analytics.google.com' },
                { value: 'm.facebook.com', label: 'm.facebook.com' },
                { value: 'HS_21video', label: 'HS_21video' },
                { value: 'Facebook_Ads ig', label: 'Facebook_Ads ig' }],
            trafficdropdown_options: [{ "value": "traffic_channels", "label": "Traffic Channels" }, { "value": "Category", "label": "Category" }],
            sunbrustdropdown_options: [{ "value": "Sessions", "label": "Sessions" }, { "value": "Transactions", "label": "Transactions" }],
            // paidsocial: [{ facebook: 3035, google: 2680, instagram: 158, linkedin: 3216, twitter: 78, year: "2021-03-01" }, { facebook: 1035, google: 2680, instagram: 2158, linkedin: 2216, twitter: 2078, year: "2021-03-02" }, { facebook: 1035, google: 1680, instagram: 1158, linkedin: 1216, twitter: 1078, year: "2021-03-03" }, { facebook: 8035, google: 8680, instagram: 5158, linkedin: 5216, twitter: 7078, year: "2021-03-04" }],
            paidsocial: [],
            trafficpievalue: [{ "name": "Social", "value": 30 }, { "name": "Direct", "value": 30 }, { "name": "Website", "value": 50 }, { "name": "Email", "value": 40 }, { "name": "Organic", "value": 60 }],
            linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
            // locationdata: [{ "name": "Chrome", "points": 100 }, { "name": "Safari", "points": 200 }, { "name": "Firefox", "points": 110 }, { "name": "Samsung", "points": 100 }, { "name": "Brave", "points": 100 }, { "name": "Edge", "points": 100 }],
            browser_list: [],
            scroll: '',
            visibility: false,
            setShow: '',
            isActive: false,
            show: false,

            source_dropdown_options: [{ "value": "impression", "label": "Impression" }, { "value": "click", "label": "Clicks" }, { "value": "spends", "label": "Spends" }],
            source_metrices_dropdown_value: "impression",
            icon_class: {
                "Google": <IconGoogle className="icz-icon" />,
                "Facebook": <IconFacebook className="icz-icon" />,
                "Instagram": <IconInstagram className="icz-icon" />,
                "Linkedin": <IconInstagram className="icz-icon" />,
                "Twitter": <IconTwitter className="icz-icon" />
            },

            source_bar_dropdown_options: [{ "value": "sessions", "label": "Sessions" }, { "value": "transactions", "label": "Transactions" }],
            source_bar_dropdown_value: "sessions",

            action_short_code: {
                "impression": "CTR",
                "click": "CPC",
                "spends": "Conversions"
            },
            total_source: 0,
            final_calc: 0,

            source_column_name: {
                "impression": "impressions",
                "click": "clicks",
                "spends": "spends"

            },

            //piedata: [{ "name": "Top-10", "points": 100 }, { "name": "10-20", "points": 200 }, { "name": "20-30", "points": 110 }, { "name": "30-40", "points": 100 }, { "name": "40-50", "points": 100 }, { "name": "50-100", "points": 100 }],
            // sunbrustchart: [{"name": "Mobile", "value": 100 }, {"name" : "Desktop", "value" : 200}, {"name" : "Tablet", "value" :170}],            
            // [{ "name": "Mobile", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Desktop", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Tablet", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 160 }] }],

            sunbrustchart: [],
            // sunbrustchart: [{ "name": "Mobile", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Desktop", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Tablet", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 160 }] }],
            events: [{ name: "Channel Grouping", field: "Channel Grouping", sortable: "false" }, { name: "Event Category", field: "Event Category", sortable: "false" }, { name: "Event Action", filed: "Event Action", sort: true }, { name: "Event Label", filed: "Event Label", sort: true }, { name: "Sessions", filed: "Sessions", sort: true }, { name: "Total Events", filed: "Total Events", sort: true }, { name: "Unique Events", filed: "Unique Events", sort: true }, { name: "Events / Session with Event", filed: "Events / Session with Event", sort: true }, { name: "Transactions", filed: "Transactions", sort: true }, { name: "Revenue", filed: "Revenue", sort: true }, { name: "Average Order Value", filed: "Average Order Value", sort: true }],
            search: [{ name: "Channel Grouping", field: "Channel Grouping", sortable: "false" }, { name: "Event Category", field: "Event Category", sortable: "false" }, { name: "Event Action", filed: "Event Action", sort: true }, { name: "Event Label", filed: "Event Label", sort: true }, { name: "Sessions", filed: "Sessions", sort: true }, { name: "Total Events", filed: "Total Events", sort: true }, { name: "Unique Events", filed: "Unique Events", sort: true }, { name: "Events / Session with Event", filed: "Events / Session with Event", sort: true }, { name: "Transactions", filed: "Transactions", sort: true }, { name: "Revenue", filed: "Revenue", sort: true }, { name: "Average Order Value", filed: "Average Order Value", sort: true }],
            landingpage: [{ name: "Impressions", field: "Impressions", sortable: "false" }, { name: "Clicks/Sessions", field: "Clicks/Sessions", sortable: "false" }, { name: "Top Landing", filed: "Top Landing", sort: true }, { name: "Bounce Rate", filed: "Bounce Rates", sort: true }, { name: "Conversion Rate", filed: "Conversion Rate", sort: true }, { name: "No of Orders", filed: "No of Orders", sort: true }, { name: "Sales", filed: "Sales", sort: true }, { name: "NSU", filed: "NSU", sort: true }, { name: "ATS", filed: "ATS", sort: true }, { name: "ROI", filed: "ROI", sort: true }],
            comments: '',
            table_header: [
                { 'name': 'Campaign', 'field': "campaign", 'sortable': true },
                { 'name': 'Users', 'field': "users", 'sortable': true },
                { 'name': 'New Users', 'field': "newusers", 'sortable': true },
                { 'name': 'Sessions', 'field': "sessions", 'sortable': true },
                { 'name': 'Bounce Rate (%)', 'field': "bouncerate", 'sortable': true },
                { 'name': 'Pages/Session', 'field': "page", 'sortable': true },
                { 'name': 'Avg.Session Duration', 'field': "sessionduration", 'sortable': true },
                { 'name': 'Conversion Rate (%)', 'field': "conversionrate", 'sortable': true },
                { 'name': 'Transactions', 'field': "transctions", 'sortable': true },
                { 'name': `Revenue (${this.getCountryCurrency()})`, 'field': "revenue", 'sortable': true },

            ],
            table_data: [
                { campaign: "WM_Perf_Broad_11th jan_Stand_Tall__Est.No_125178", users: '3,742(21.92%)', newusers: '2,892(22.38%)', sessions: '4,137(21.90%)', bouncerate: '1.14%', page: '4.42', sessionduration: '00:00:49', conversionrate: '0.31%', transctions: '13(11.30%)', revenue: '365246' },
                { campaign: "WM_lee_Perf_Interets+LAL_Est_No_123517 / 123520 - Daily budget", users: '2,505(14.67%)', newusers: '2,142(16.58%)', sessions: '2,659(14.07%)', bouncerate: '1.58%', page: '5.13', sessionduration: '00:00:59', conversionrate: '0.79%', transctions: '21(18.26%)', revenue: '69528' },
                { campaign: "WM_lee_Performance_Retargeting_Est.No_128399", users: '2,285(13.38%)', newusers: '1,281(9.91%)', sessions: '2,540(13.44%)', bouncerate: '4.45%', page: '4.55', sessionduration: '00:00:55', conversionrate: '0.43%', transctions: '11(9.57%)', revenue: '548846' },
            ],

            is_scroll: false,

            first_start_date: props.start_date,
            first_end_date: props.end_date,
            second_start_date: props.previous_start_date,
            second_end_date: props.previous_end_date,
            isDatePickerOpen: false,

            is_traffic_loaded: false,
            is_linesparkgraph_loaded: false,
            is_paidsocial_loaded: false,
            is_device_sunburst_loaded: false,
            is_locationdata_loaded: false,
            is_table_data_loaded: false,
            is_campaign_list_loaded: false,


            // Spends Section
            is_spends_loaded: false,

            current_spends: 0,
            last_spends: 0,
            spend_list: [],

            current_revenue: 0,
            last_revenue: 0,
            revenue_list: [],

            current_roi: 0,
            last_roi: 0,
            roi_list: [],

            current_conversion: 0,
            last_conversion: 0,
            conversion_list: [],

            // User Section
            is_user_loaded: false,

            current_session: [],
            last_session: [],
            session_list: [],

            current_user: 0,
            last_user: 0,
            user_list: [],

            current_new_user: 0,
            last_new_user: 0,
            new_user_list: [],

            current_mobile_user: 0,
            last_mobile_user: 0,
            mobile_user_list: [],

            channel_name: "",
            device_data: [],
            device_metrics: "session",

            is_browser_loaded: false,
            browser_metrics: "session",
            browser_data: [],

            is_social_metrix_loaded: false,
            social_metrics: "impression",
            social_data: [],

            social_metric_facebook: "facebook",
            social_metric_google: "google",

            facebook_metric_value: 0,
            google_metric_value: 0,
            facebook_subtitle: 0,
            google_subtitle: 0,
            source_name: '',
            formatMoney(n) {
                return (Math.round(n * 100) / 100).toLocaleString();
            }

        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            this.setState({
                first_start_date: nextProps.start_date,
                first_end_date: nextProps.end_date,
                second_start_date: nextProps.previous_start_date,
                second_end_date: nextProps.previous_end_date,
            });
        }
    }

    componentDidMount() {
        this.state.is_table_data_loaded = true;
        this.state.is_paidsocial_loaded = true

        this.getPaidChannelGroup()
        this.getUserMetrics()
        this.getSpendsMetrics()
        this.getDeviceMetrices()
        this.getBrowserMetrices()
        this.getSocialMetrices()
        this.getCampaignDatatable()
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.start_date !== this.props.start_date ||
            prevProps.end_date !== this.props.end_date ||
            prevProps.previous_start_date !== this.props.previous_start_date ||
            prevProps.previous_end_date !== this.props.previous_end_date
        ) {
            this.setState({
                is_browser_loaded: false,
                is_device_sunburst_loaded: false,
                is_spends_loaded: false,
                is_user_loaded: false,
                is_campaign_list_loaded: false,
                is_traffic_loaded: false,
                is_social_metrix_loaded: false,
            });

            this.getPaidChannelGroup()
            this.getUserMetrics()
            this.getSpendsMetrics()
            this.getDeviceMetrices()
            this.getBrowserMetrices()
            this.getCampaignDatatable()

        }
    }

    handleCallback = (childData) => {
        this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });
    }

    handleChannelGroup = (childData) => {
        this.setState({
            trafficeChannel: childData,
            channel_name: childData,
            is_browser_loaded: false,
            is_device_sunburst_loaded: false,
            is_spends_loaded: false,
            is_user_loaded: false,
            is_campaign_list_loaded: false
        });
        this.getUserMetrics()
        this.getSpendsMetrics()
        this.getDeviceMetrices()
        this.getBrowserMetrices()
        this.getCampaignDatatable()
    }
    formatMoney(n) {
        return (Math.round(n * 100) / 100).toLocaleString();
    }
    handleCampaignCallback = (childData) => {
        this.setState({ source_name: childData, is_campaign_list_loaded: false });
        this.getCampaignDatatable()
    }


    handleperformanceCallback = (childData) => {
        this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });

    }

    handleSunbrustCallback = (childData) => {
        this.setState({ is_device_sunburst_loaded: false, device_metrics: childData });
        this.getDeviceMetrices()
    }

    handleBrowserCallback = (childData) => {
        this.setState({ is_browser_loaded: false, browser_metrics: childData });
        this.getBrowserMetrices()

    }

    handleSocialPerformance = (childData) => {
        this.setState({ is_social_metrix_loaded: false, social_metrics: childData });
        this.getSocialMetrices()
    }

    getPaidChannelGroup() {
        let param = {
            // 'start_date': '2021-05-01',
            // 'end_date': '2021-05-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date
        }

        const url = API_URL_1 + "/api/ecom/paid-channel-group/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let channels = result['channels']
                let data = []
                channels.map((info) => {
                    data.push({ 'name': info['channel_name'], 'value': info['total_session'] })
                    this.setState({ is_traffic_loaded: true, trafficpievalue: data });
                })
            }, (error) => {
            })
    }

    getUserMetrics() {
        let param = {
            // 'start_date': '2021-05-01',
            // 'end_date': '2021-05-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name
        }

        const url = API_URL_1 + "/api/ecom/paid-user-metrics/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let current_metrics = result['current_metrics']
                let last_metrics = result['last_metrics']

                let current_mobile_metrics = result['current_mobile_user_metrics']
                let last_mobile_metrics = result['last_mobile_user_metrics']

                let current_new_user_metrics = result['current_new_user_metrics']
                let last_new_user_metrics = result['last_new_user_metrics']

                let new_user_list = result['new_user_list']
                let metrics_list = result['user_metrics_list']
                let mobile_list = result['mobile_user_list']

                let session_list = []
                let user_list = []

                metrics_list.map((info, index) => {
                    session_list.push({ 'date': info['date'], 'value': info['total_session'] })
                    user_list.push({ 'date': info['date'], 'value': info['total_users'] })
                })

                let mobile_user_list = []
                mobile_list.map((info, index) => {
                    mobile_user_list.push({ 'date': info['date'], 'value': info['total_users'] })
                })

                let new_users = []
                new_user_list.map((info, index) => {
                    new_users.push({ 'date': info['date'], 'value': info['total_users'] })
                })


                this.setState({
                    is_user_loaded: true,

                    current_session: current_metrics[0]['total_session'],
                    last_session: last_metrics[0]['total_session'],
                    session_list: session_list,

                    current_user: current_metrics[0]['total_users'],
                    last_user: last_metrics[0]['total_users'],
                    user_list: user_list,

                    current_new_user: current_new_user_metrics[0]['total_users'],
                    last_new_user: last_new_user_metrics[0]['total_users'],
                    new_user_list: new_users,

                    current_mobile_user: current_mobile_metrics[0]['total_users'],
                    last_mobile_user: last_mobile_metrics[0]['total_users'],
                    mobile_user_list: mobile_user_list,
                })
            }, (error) => {
            })
    }

    getSpendsMetrics() {
        let param = {
            // 'start_date': '2021-05-01',
            // 'end_date': '2021-05-30',

            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name
        }

        const url = API_URL_1 + "/api/ecom/paid-spends-metrics/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let current_metrics = result['current_metrics']
                let last_metrics = result['last_metrics']
                let metrics_list = result['metrics_list']
                let current_spend_metrics = result['current_spend_metrics']

                let last_spend_metrics = result['last_spend_metrics']
                let spend_list = result['spend_list']

                let spends = []
                spend_list.map((info) => {
                    spends.push({ 'date': info['ad_date'], 'value': info['total_spends'] })
                })

                let revenue_list = []
                let roi_list = []
                let conversion_list = []

                metrics_list.map((info, index) => {




                    revenue_list.push({ 'date': info['date'], 'value': info['total_revenue'] })
                    let r = 0
                    try {
                        r = (info['total_revenue'] / spend_list[index]['total_spends']) * 100

                    } catch {
                        r = 0
                    }

                    // if (isNaN(r)) { r = 0 }
                    roi_list.push({ 'date': info['date'], 'value': r != 0 ? r.toFixed(2) : r })

                    let c = (info['total_transaction'] / info['total_session']) * 100
                    // if (isNaN(c)) { c = 0 }
                    conversion_list.push({ 'date': info['date'], 'value': c != 0 ? c.toFixed(2) : c })
                })

                let current_conversion = (current_metrics[0]['total_transaction'] / current_metrics[0]['total_session']) * 100
                let last_conversion = (last_metrics[0]['total_transaction'] / last_metrics[0]['total_session']) * 100

                if (isNaN(current_conversion)) { current_conversion = 0 }
                if (isNaN(last_conversion)) { last_conversion = 0 }

                let current_roi = (current_metrics[0]['total_revenue'] / current_spend_metrics[0]['total_spends']) * 100
                let last_roi = (last_metrics[0]['total_revenue'] / last_spend_metrics[0]['total_spends']) * 100

                if (isNaN(current_roi)) { current_roi = 0 }
                if (isNaN(last_roi)) { last_roi = 0 }

                this.setState({
                    is_spends_loaded: true,
                    current_spends: current_spend_metrics[0]['total_spends'],
                    last_spends: last_spend_metrics[0]['total_spends'],
                    spend_list: spends,

                    current_revenue: current_metrics[0]['total_revenue'],
                    last_revenue: last_metrics[0]['total_revenue'],
                    revenue_list: revenue_list,

                    current_roi: current_roi != 0 ? current_roi.toFixed(2) : current_roi,
                    last_roi: last_roi != 0 ? last_roi.toFixed(2) : last_roi,
                    roi_list: roi_list,

                    current_conversion: current_conversion != 0 ? current_conversion.toFixed(2) : current_conversion,
                    last_conversion: last_conversion != 0 ? last_conversion.toFixed(2) : last_conversion,
                    conversion_list: conversion_list,
                })

            }, (error) => {
            })
    }

    getDeviceMetrices() {
        let param = {
            // 'start_date': '2021-05-01',
            // 'end_date': '2021-05-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name
        }


        const url = API_URL_1 + "/api/ecom/device-list/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let device_list = result['device_list']
                let devices = []
                device_list.map((info) => {
                    if (!devices.includes(info['device'])) { devices.push(info['device']) }
                })

                let metrics = this.state.device_metrics == "Transactions" ? "total_transaction" : "total_session"

                let os_list = []
                devices.map((device_type) => {
                    let childrens = []
                    device_list.map((info) => {
                        if (device_type == info['device']) {
                            childrens.push({ "name": info['operating_system'].charAt(0).toUpperCase() + info['operating_system'].slice(1).toLowerCase(), "value": info[metrics] })
                        }
                    })
                    let d = { "name": device_type.charAt(0).toUpperCase() + device_type.slice(1).toLowerCase(), "children": childrens }
                    os_list.push(d)
                })

                this.setState({ is_device_sunburst_loaded: true, sunbrustchart: os_list })
            }, (error) => {
            })
    }

    getBrowserMetrices() {
        let param = {
            // 'start_date': '2021-05-01',
            // 'end_date': '2021-05-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name,
            'filter_type': this.state.browser_metrics
        }


        const url = API_URL_1 + "/api/ecom/browser-list/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let browsers = result['browser_list']
                let data = []
                let metrics = this.state.browser_metrics == "transactions" ? "total_transaction" : "total_session"
                browsers.map((info) => {
                    data.push({ "name": info['browser'], "points": info[metrics] })
                })

                this.setState({ is_browser_loaded: true, browser_list: data })
            }, (error) => {
            })
    }

    getSocialMetrices() {
        let param = {
            // 'start_date': '2022-01-20',
            // 'end_date': '2022-01-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name
        }


        const url = API_URL_1 + "/api/ecom/social-metrics-list/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let sources = result['source_list']
                let source_metrices = result['source_metrics']
                let google_value = 0
                let facebook_value = 0
                let metric = 'total_impression'
                let facebook_subtitle = 'CTR: 0'
                let google_subtitle = 'CTR: 0'

                if (this.state.social_metrics == "click") {
                    metric = 'total_click'
                } else if (this.state.social_metrics == "spends") {
                    metric = 'total_spends'
                } else if (this.state.social_metrics == "impression") {
                    metric = 'total_impression'
                }

                source_metrices.map((info) => {
                    if (info['source'] == "Facebook") {
                        facebook_value = info[metric]
                        if (this.state.social_metrics == "click") {
                            facebook_subtitle = "CPC: " + (info['total_spends'] / info['total_click']).toFixed(2)
                        } else if (this.state.social_metrics == "spends") {
                            facebook_subtitle = ""
                        } else if (this.state.social_metrics == "impression") {
                            facebook_subtitle = "CTR: " + ((info['total_click'] / info['total_impression']) * 100).toFixed(2)
                        }
                    } else if (info['source'] == "Google") {
                        google_value = info[metric]
                        if (this.state.social_metrics == "click") {
                            google_subtitle = "CPC: " + (info['total_spends'] / info['total_click']).toFixed(2)
                        } else if (this.state.social_metrics == "spends") {
                            google_subtitle = ""
                        } else if (this.state.social_metrics == "impression") {
                            google_subtitle = "CTR: " + ((info['total_click'] / info['total_impression']) * 100).toFixed(2)
                        }
                    }
                })

                let source_list = []
                let facebook_metric = 'facebook_impression'
                let google_metric = 'google_impression'

                if (this.state.social_metrics == "click") {
                    facebook_metric = 'facebook_total_click'
                    google_metric = 'google_total_click'
                } else if (this.state.social_metrics == "spends") {
                    facebook_metric = 'facebook_total_spends'
                    google_metric = 'google_total_spends'
                } else if (this.state.social_metrics == "impression") {
                    facebook_metric = 'facebook_impression'
                    google_metric = 'google_impression'
                }

                sources.map((info) => {
                    if (info[facebook_metric] == null || info[facebook_metric] == undefined) {
                        info[facebook_metric] = 0
                    }

                    if (info[google_metric] == null || info[google_metric] == undefined) {
                        info[google_metric] = 0
                    }

                    // click spends impression
                    source_list.push({
                        facebook: info[facebook_metric],
                        google: info[google_metric],
                        year: info['ad_date']
                    })
                })

                this.setState({
                    is_social_metrix_loaded: true,
                    paidsocial: source_list,
                    facebook_metric_value: facebook_value,
                    google_metric_value: google_value,
                    facebook_subtitle: facebook_subtitle,
                    google_subtitle: google_subtitle
                })
            }, (error) => {
            })
    }

    // paid-campaign-list/
    getCampaignDatatable() {
        let param = {
            // 'start_date': '2022-01-20',
            // 'end_date': '2022-01-30',
            'start_date': this.state.first_start_date,
            'end_date': this.state.first_end_date,
            'last_start_date': this.state.second_start_date,
            'last_end_date': this.state.second_end_date,
            'channel_name': this.state.channel_name,
            'source_name': this.state.source_name
        }


        const url = API_URL_1 + "/api/ecom/paid-campaign-list/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ' Bearer ' + localStorage.getItem("token")
            },
        }).then(res => res.json())
            .then((result) => {
                let campaign_list = result['campaign_list']
                // let new_user_campaign_list = result['new_user_campaign_list']
                let source_list = result['source_list']

                let new_user_dict = {}
                // new_user_campaign_list.map((info) => {
                //     new_user_dict[info['campaign_name']] = info['total_users']
                // });

                //  TODO Check later
                // let options = [{"value": "", "label": "All"}]
                // source_list.map((info) => {
                //     options.push({"value": info['source'], "label": info['source']})
                // });


                let campaign_rows = []
                campaign_list.map((info) => {
                    let new_user = 0
                    try {
                        new_user = new_user_dict[info['campaign_name']]
                    } catch {
                        new_user = 0
                    }

                    if (new_user == "" || new_user == "undefined" || new_user == null) {
                        new_user = 0
                    }

                    campaign_rows.push({
                        campaign: info['campaign_name'],
                        users: info['total_users'],
                        newusers: new_user,
                        sessions: info['total_session'],
                        bouncerate: ((info['total_bounce'] / info['total_session']) * 100).toFixed(2),
                        page: ((info['total_page_view'] / info['total_session'])).toFixed(2),
                        sessionduration: (info['total_session_duration'] / info['total_session']).toFixed(2),
                        conversionrate: ((info['total_transaction'] / info['total_session']) * 100).toFixed(2),
                        transctions: info['total_transaction'],
                        revenue: this.state.formatMoney(info['total_transaction_revenue'])
                    })
                })

                this.setState({
                    is_campaign_list_loaded: true,
                    table_data: campaign_rows,
                    // datatabledropdown_options: options
                })
            }, (error) => {
            })
    }


    eventShow = () => {
        this.setState({
            isActive: true
        });
        this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    }

    eventHide = () => {
        this.setState({
            isActive: false
        })
    }

    searchShow = () => {
        this.setState({
            show: true
        });
        this.scrollSearch.current.scrollIntoView({ behavior: "smooth" });
    }

    searchHide = () => {
        this.setState({
            show: false
        })
    }

    datepickerHandleCallback = (childData) => {
        this.state.first_start_date = childData[0];
        this.state.first_end_date = childData[1];
        this.state.second_start_date = childData[2];
        this.state.second_end_date = childData[3];

        this.forceUpdate();
        this.datepickerHandleCallback2();

        this.setState({
            is_browser_loaded: false,
            is_device_sunburst_loaded: false,
            is_spends_loaded: false,
            is_user_loaded: false,
            is_campaign_list_loaded: false,
            is_traffic_loaded: false,
            is_social_metrix_loaded: false,
            browser_list: []
        });

        this.getPaidChannelGroup()
        this.getUserMetrics()
        this.getSpendsMetrics()
        this.getDeviceMetrices()
        this.getBrowserMetrices()
        this.getSocialMetrices()
        this.getCampaignDatatable()
    };

    datepickerHandleCallback2 = () => {
        this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
    };

    handleScroll = () => {
        if (window.pageYOffset > 1) {
            if (!this.state.is_scroll) {
                this.setState({ is_scroll: true });
            }
        } else {
            if (this.state.is_scroll) {
                this.setState({ is_scroll: false });
            }
        }
    };

    getCountryCurrency = () => {

        let userCountryCode = localStorage.getItem('static-country-code');
        var currValue = ''

        CountryCode.map((data) => {
            if (data.countryCode === userCountryCode) {
                currValue = data.currencySymbol
            }
        });

        return currValue;
    }

    render() {


        const CarouselSettings = {
            arrows: true,
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [

                {
                    breakpoint: 1202,
                    settings: {
                        arrows: true,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        }

        return (
            <Wrapper>
                <div className="grid grid_cols_4 drawer_grid ri_summary_bsImport">
                    <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
                        <div className="icz-rimapsection bg-white">
                            <Col sm={12} className="icz-cardheader p-20 d-flex">
                                <Col className="icz-cardtitle">
                                    <div className="icz-title-wrap p-0">
                                        <div className="text-left">
                                            <h5 className="icz-cardtitle text-start mb-0">Traffic By Channel</h5>
                                        </div>
                                    </div>
                                </Col>
                            </Col>
                            {this.state.is_traffic_loaded == true ?
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <Piechart parentCallback={this.handleChannelGroup} card_id="icz-traffic-piechart" card_class="icz-leftsectionchart" graph-data={this.state.trafficpievalue} />
                                </div>
                                : <Loader />}
                        </div>
                    </div>
                    <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer">
                        <div className="content">
                            <div className="content_section">
                                <PageHeader pageTitle={"Paid"} />
                                <div className="page_content">
                                    <div className="grid grid_cols_1 dashboard_home">
                                        <div className="grid grid_cols_1">
                                            <div className="grid col_span_4 icz-carouselcontainer">
                                                {this.state.is_spends_loaded && this.state.is_user_loaded ?
                                                    <Carousel
                                                        Settings={CarouselSettings}
                                                        class={"icz-cardcontainer"}
                                                        SliderCards={[

                                                            <ChartCard
                                                                index="1"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle={`Spends (${this.getCountryCurrency()})`}
                                                                RowOneCurrentValue={this.state.current_spends}
                                                                RowOnePreviousValue={this.state.last_spends}
                                                                RowOneTitle="sales"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-sales" card_class="icz-cardchart" graph-data={this.state.spend_list} />}
                                                            />,

                                                            <ChartCard
                                                                index="2"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle={`Revenue (${this.getCountryCurrency()})`}
                                                                RowOneCurrentValue={this.state.current_revenue}
                                                                RowOnePreviousValue={this.state.last_revenue}
                                                                RowOneTitle="sales"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-total-orders" card_class="icz-cardchart" graph-data={this.state.revenue_list} />}
                                                            />,
                                                            <ChartCard
                                                                index="3"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="Return On Investment"
                                                                RowOneCurrentValue={this.state.current_roi}
                                                                RowOnePreviousValue={this.state.last_roi}
                                                                RowOneTitle="sales "
                                                                IsNumber={'percentage'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-avgorder-value" isnumber={"decimal_percentage"} card_class="icz-cardchart" graph-data={this.state.roi_list} />}
                                                            />,
                                                            <ChartCard
                                                                index="4"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="Conversion Rate"
                                                                RowOneCurrentValue={this.state.current_conversion}
                                                                RowOnePreviousValue={this.state.last_conversion}
                                                                RowOneTitle="sales"
                                                                IsNumber={'percentage'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-cancellations" isnumber={"decimal_percentage"} card_class="icz-cardchart" graph-data={this.state.conversion_list} debug={true} />}
                                                            />,
                                                            <ChartCard
                                                                index="1"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="Sessions"
                                                                RowOneCurrentValue={this.state.current_session}
                                                                RowOnePreviousValue={this.state.last_session}
                                                                RowOneTitle="Traffic"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-session" card_class="icz-cardchart" graph-data={this.state.session_list} />}
                                                            />,

                                                            <ChartCard
                                                                index="2"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="Users"
                                                                RowOneCurrentValue={this.state.current_user}
                                                                RowOnePreviousValue={this.state.last_user}
                                                                RowOneTitle="Traffic"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-user" card_class="icz-cardchart" graph-data={this.state.user_list} />}
                                                            />,
                                                            <ChartCard
                                                                index="3"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="New Users"
                                                                RowOneCurrentValue={this.state.current_new_user}
                                                                RowOnePreviousValue={this.state.last_new_user}
                                                                RowOneTitle="Traffic"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-new-users" card_class="icz-cardchart" graph-data={this.state.new_user_list} />}
                                                            />,
                                                            <ChartCard
                                                                index="4"
                                                                CardClass="icz-cardwrapper"
                                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                                CardTitle="Mobile Users"
                                                                RowOneCurrentValue={this.state.current_mobile_user}
                                                                RowOnePreviousValue={this.state.last_mobile_user}
                                                                RowOneTitle="Traffic"
                                                                IsNumber={'number'}
                                                                IsStandard={true}
                                                                chart={<Linegraph_sparkline card_id="icz-mobile-user" card_class="icz-cardchart" graph-data={this.state.mobile_user_list} />}
                                                            />,


                                                        ]}>

                                                    </Carousel>
                                                    : <Loader />}

                                            </div>
                                        </div>

                                        <div className="icz-row">

                                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                                <div className="icz-sectioncard">
                                                    <Col sm={12} className="icz-cardheader flex-wrap justify-content-between">
                                                        <Col lg={6} className="icz-cardtitle">
                                                            Performance
                                                        </Col>
                                                        <Col lg={6} className="icz-cardfilter">
                                                            <DailydataDropdown dropdown_options={this.state.source_dropdown_options} dropdown_placeholder="Impressions" parentCallback={this.handleSocialPerformance} />
                                                            <ChartExport
                                                                ChartId='paid_social'
                                                                export_data={this.state.paidsocial}
                                                                downloadName="Performance"
                                                            />
                                                        </Col>
                                                    </Col>


                                                    <div className="icz-dchartsection d-flex">
                                                        {this.state.is_social_metrix_loaded === true ?
                                                            <Wrapper>
                                                                <Col className="" sm={12} lg={8}>
                                                                    <MultiLine_LineChart chart_id="paid_social" chart_class="icz-sectionchart" graph-data={this.state.paidsocial} metric_1={"Facebook"} metric_2={"Google"} metric_3={"Twitter"} metric_4={"Linkedin"} metric_5={"Instagram"} value1={this.state.social_metric_facebook} value2={this.state.social_metric_google} value3={"instagram"} value4={"linkedin"} value5={"twitter"} />
                                                                </Col>

                                                                {this.state.is_social_metrix_loaded ?
                                                                    <Col className="icz-chartdetailssection" sm={12} lg={4}>
                                                                        <div className="icz-detailsdatasection icz-detailscroll w-100">

                                                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="icz-iconwrapper">
                                                                                        <IconGoogle className="icz-icon" />
                                                                                    </div>
                                                                                    <div className="icz-titlewrapper">
                                                                                        <div className="icz-title">
                                                                                            Google
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="icz-titlewrapper">
                                                                                    <div className="icz-title">
                                                                                        <NumberFormatter Number={this.state.google_metric_value} IsNumber={'number'} />
                                                                                    </div>
                                                                                    <div className="icz-subtitle">
                                                                                        {this.state.google_subtitle}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="icz-iconwrapper">
                                                                                        <IconFacebook className="icz-icon" />
                                                                                    </div>
                                                                                    <div className="icz-titlewrapper">
                                                                                        <div className="icz-title">
                                                                                            Facebook
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="icz-titlewrapper">
                                                                                    <div className="icz-title">
                                                                                        <NumberFormatter Number={this.state.facebook_metric_value} IsNumber={'number'} />
                                                                                    </div>
                                                                                    <div className="icz-subtitle">
                                                                                        {this.state.facebook_subtitle}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    : ""}

                                                            </Wrapper>
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>

                                        <div className="grid grid_cols_4 icz-row">
                                            <div className="grid col_span_2 icz-sectioncardwrapper">
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex-wrap justify-content-between">
                                                        <div className="icz-cardtitle">
                                                            Performance by Device
                                                        </div>

                                                        <div className="icz-cardfilter">
                                                            <DailydataDropdown dropdown_options={this.state.sunbrustdropdown_options} dropdown_placeholder="Sessions" parentCallback={this.handleSunbrustCallback} />
                                                            <ChartExport
                                                                ChartId='performancebyDevice'
                                                                export_data={this.state.sunbrustchart}
                                                                downloadName="Performance by Device"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {this.state.is_device_sunburst_loaded ?
                                                            <Sunbrust_chart card_id="performancebyDevice" card_class="icz-sectionchart" graph-data={this.state.sunbrustchart} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="grid col_span_2 icz-sectioncardwrapper">
                                                <div className="icz-sectioncard">
                                                    <div className="icz-cardheader flex-wrap justify-content-between">
                                                        <div className="icz-cardtitle">
                                                            Performance by Browser
                                                        </div>
                                                        <div className="icz-cardfilter">
                                                            <DailydataDropdown dropdown_options={this.state.source_bar_dropdown_options} dropdown_placeholder="Sessions" parentCallback={this.handleBrowserCallback} />
                                                            <ChartExport
                                                                ChartId='performancebyBrowser'
                                                                export_data={this.state.browser_list}
                                                                downloadName="Performance by Browser"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {this.state.is_browser_loaded ?
                                                            <LocationBarChart rotateLabel={true} is_truncated={'wrap'} card_id="performancebyBrowser" card_class="icz-sectionchart" graph-data={this.state.browser_list} />
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="grid grid_cols_1 icz-sectioncardwrapper">
                                            <div className="icz-sectioncard">
                                                <Col sm={12} className="icz-cardheader flex-wrap justify-content-between">
                                                    <Col lg={6} className="icz-cardtitle">
                                                        Campaign Performance
                                                    </Col>
                                                    <Col lg={6} className="icz-cardfilter">
                                                        <DailydataDropdown dropdown_options={this.state.datatabledropdown_options} dropdown_placeholder="All" parentCallback={this.handleCampaignCallback} />
                                                    </Col>
                                                </Col>
                                                {this.state.is_campaign_list_loaded ?
                                                    <div className="icz-table">
                                                        <DataTable isPageInfoEnable={true} Headers={this.state.table_header} TableData={this.state.table_data} TableSize={5} />
                                                    </div>
                                                    : <Loader />}
                                            </div>
                                        </div> */}


                                        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                                            <div className="grid col_span_4">
                                                <div className="section_card_header">
                                                    <h2 className="section_card_title">
                                                        Campaign Performance
                                                    </h2>
                                                    <div className='icz-cardfilter' style={{ "width": "300px" }}>
                                                        <DailydataDropdown dropdown_options={this.state.datatabledropdown_options} dropdown_placeholder="All" parentCallback={this.handleCampaignCallback} />
                                                        <ChartExport
                                                            ChartId='campaignPerformance'
                                                            export_data={this.state.table_data}
                                                            downloadName="Campaign Performance"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid col_span_4">
                                                <div className="section_card_body">
                                                    <div className="section_card_chart_wrapper" style={{ "height": "auto" }}>
                                                        {this.state.is_campaign_list_loaded ?
                                                            <div id='campaignPerformance' className="icz-table">
                                                                <DataTable isPageInfoEnable={true} Headers={this.state.table_header} TableData={this.state.table_data} TableSize={5} />
                                                            </div>
                                                            : <Loader />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Wrapper>
        )
    }
}

function ReduxStateToProps(state) {
    return {
        start_date: state.date.startDate,
        end_date: state.date.endDate,
        previous_start_date: state.date.prevStartDate,
        previous_end_date: state.date.prevEndDate,
    };
}

export default connect(ReduxStateToProps)(Paid)
