import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// CONSTANTS
import { USER_COUNTRY } from "../constants/constants";
import { CountryCode, CountryCurrency } from "../constants/countryCurrency";

const NumberSeparator = ({ title, number, platform }) => {
  if (title === undefined || title === null) {
    title = "default"
  }

  if (title === undefined || title === null) {
    title = "default"
  }

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  if (number === "N/A") {
    return number
  }

  let numberValue = Number(number);

  if (!title) {
    title = "";
  }

  let variant = "number";

  const tonnesMetrics = ["monthly sales vol."]
  if (tonnesMetrics.includes(title.toLowerCase())) {
    variant = "tonnes";
  }

  // PERCENTAGE METRICS ARRAY
  const percentageMetrics = [
    "hsm share",
    "prime time share",
    "engagement rate",
    "cr",
    "ctr",
    "market share",
    "bounce rate (%)",
    "bounce rate",
    "goal conversion rate",
    "view rate",
    "sales vol. change",
    "sales val. change",
    "change",
    "reach rate",
    "vtr",
    "cvtr",
    "conversion rate",
    "complete view rate",
    "visits % change",
    "% total visit",
    "% of tv spends",
    "% of tv impressions",
    "% of all digital spends",
    "% of all digital impressions",
    "% of digital meta spends",
    "% of digital meta impressions",
    "% of digital google spends",
    "% of digital google impressions",
    "% of tv + digital spends",
    "% of tv + digital impressions",
    "premium share"
  ];
  if (percentageMetrics.includes(title.toLowerCase())) {
    variant = "percentage";
  }

  // CURRENCY METRICS ARRAY
  const currencyMetrics = [
    "spends",
    "spend",
    "cpc",
    "cpm",
    "cprp",
    "clicks / conversion",
    "sales value",
    "sales",
    "tv spends",
    "all digital spends",
    "digital meta spends",
    "digital google spends",
    "tv + digital spends",
  ];
  if (currencyMetrics.includes(title.toLowerCase())) {
    variant = "money";
  }

  // TIME METRICS ARRAY
  const timeMetrics = ["Avg Duration"];
  if (timeMetrics.includes(title.toLowerCase())) {
    variant = "time";
  }

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  if (numberValue === 0) {
    numberValue = 0;
  }

  if (isNaN(numberValue)) {
    numberValue = 0;
  }

  if (!isFinite(numberValue)) {
    numberValue = 0;
  }


  if (variant === "money") {
    if (platform === "Twitter") {
      numberValue =
        country.alternateCurrencySymbol +
        (+Math.abs(numberValue.toFixed(2)))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      numberValue =
        country.currencySymbol +
        (+Math.abs(numberValue.toFixed(2)))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else if (variant === "percentage") {
    numberValue = numberValue.toFixed(2) + "%";
  } else if (variant === "time") {
    numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
  } else if (variant === "tonnes") {
    numberValue = +Math.abs(numberValue.toFixed(2)) + " T";
  } else if (variant === "liquid") {
    numberValue =
      (+Math.abs(numberValue.toFixed(2)))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "Lit";
  } else {
    numberValue = (+Math.abs(numberValue.toFixed(2)))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return numberValue;
};

NumberSeparator.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  platform: PropTypes.string,
};
export default NumberSeparator;
