const EarnedIcon = (props) => {
    return (

        <svg className={props.className} width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_303_27" maskUnits="userSpaceOnUse" x="0" y="0" width="31" height="31">
                <path d="M30.667 0.333984H0V30.997H30.667V0.333984Z" fill="white" />
            </mask>
            <g mask="url(#mask0_303_27)">
                <path d="M30.66 3.01298C30.6702 2.74266 30.6364 2.47248 30.56 2.21298C30.3784 1.67217 30.0335 1.20101 29.5728 0.864472C29.1121 0.527932 28.5584 0.342585 27.988 0.333984C19.5453 0.343984 11.1027 0.346318 2.66 0.340984C2.2906 0.322229 1.92198 0.390746 1.584 0.540984C1.16116 0.724513 0.793033 1.01439 0.515453 1.38239C0.237873 1.75039 0.0602943 2.18399 0 2.64098L0 28.724C0.081851 29.2978 0.34767 29.8296 0.757533 30.2395C1.1674 30.6493 1.69917 30.9151 2.273 30.997H28.239C28.4941 30.9656 28.7465 30.9151 28.994 30.846C29.4865 30.6347 29.9069 30.2847 30.204 29.8387C30.501 29.3927 30.6619 28.8699 30.667 28.334C30.6557 19.8947 30.6533 11.4543 30.66 3.01298ZM1.391 5.61198C1.409 4.83498 1.391 4.05698 1.399 3.27998C1.38176 3.07789 1.40863 2.87446 1.47777 2.68377C1.5469 2.49309 1.65664 2.3197 1.7994 2.17562C1.94215 2.03153 2.11451 1.92018 2.30455 1.84928C2.49458 1.77838 2.69775 1.74962 2.9 1.76498H3.02C11.2133 1.76498 19.407 1.76498 27.601 1.76498C27.8992 1.72968 28.201 1.78621 28.4662 1.92702C28.7313 2.06783 28.9472 2.28621 29.085 2.55298C29.1953 2.79801 29.2463 3.06556 29.234 3.33398C29.234 4.10098 29.223 4.86898 29.242 5.63398C29.249 5.89398 29.165 5.93398 28.926 5.93398C24.38 5.92932 19.8343 5.92932 15.289 5.93398C10.763 5.93398 6.23767 5.93598 1.713 5.93998C1.444 5.93998 1.384 5.87098 1.391 5.61198ZM29.235 27.989C29.2789 28.3234 29.2025 28.6626 29.0193 28.9459C28.8361 29.2291 28.5581 29.4379 28.235 29.535C28.0321 29.586 27.8231 29.6083 27.614 29.601H3.031C2.80948 29.6344 2.58323 29.6153 2.3704 29.5454C2.15757 29.4755 1.96409 29.3567 1.80549 29.1985C1.64689 29.0403 1.5276 28.8471 1.45718 28.6344C1.38676 28.4218 1.36717 28.1956 1.4 27.974C1.4 21.214 1.397 14.455 1.391 7.69698C1.391 7.41398 1.45 7.33298 1.745 7.33298C6.271 7.34298 10.7973 7.34532 15.324 7.33998C19.8507 7.33465 24.376 7.33265 28.9 7.33398C29.143 7.33398 29.241 7.36398 29.24 7.64998C29.23 14.4287 29.2273 21.2073 29.232 27.986" fill="#486F88" />
                <path d="M3.47523 4.54987C3.33709 4.54849 3.20244 4.50626 3.08825 4.42851C2.97406 4.35076 2.88543 4.24095 2.83352 4.11293C2.78161 3.98491 2.76875 3.84439 2.79654 3.70906C2.82434 3.57374 2.89155 3.44967 2.98972 3.35247C3.08789 3.25527 3.21262 3.1893 3.34822 3.16285C3.48381 3.1364 3.6242 3.15067 3.7517 3.20385C3.8792 3.25703 3.98811 3.34675 4.06472 3.46171C4.14133 3.57667 4.18222 3.71172 4.18223 3.84987C4.1745 4.03424 4.09732 4.20887 3.96619 4.3387C3.83505 4.46854 3.65967 4.54397 3.47523 4.54987Z" fill="#486F88" />
                <path d="M6.95989 3.8659C6.95115 4.05037 6.87165 4.22438 6.73794 4.35176C6.60422 4.47915 6.42657 4.55011 6.24189 4.5499C6.06288 4.53695 5.89496 4.45833 5.77038 4.32913C5.64579 4.19993 5.57333 4.02927 5.56689 3.8499C5.57152 3.6645 5.64771 3.48809 5.7795 3.3576C5.91128 3.22712 6.08845 3.15269 6.27389 3.1499C6.4593 3.1554 6.63513 3.23354 6.76346 3.36748C6.89179 3.50142 6.96233 3.68042 6.95989 3.8659Z" fill="#486F88" />
                <path d="M8.35693 3.84893C8.36002 3.66424 8.43476 3.48798 8.56538 3.35736C8.69599 3.22675 8.87225 3.15201 9.05694 3.14893C9.24259 3.14893 9.42063 3.22268 9.55191 3.35395C9.68319 3.48523 9.75694 3.66328 9.75694 3.84893C9.75694 4.03458 9.68319 4.21262 9.55191 4.3439C9.42063 4.47517 9.24259 4.54892 9.05694 4.54892C8.87241 4.54534 8.69643 4.47044 8.56593 4.33994C8.43542 4.20943 8.36052 4.03346 8.35693 3.84893Z" fill="#486F88" />
            </g>
            <path d="M19.6851 14.6827H18.2901C18.1236 14.6887 17.9617 14.6283 17.8398 14.5149C17.7179 14.4014 17.646 14.2442 17.6401 14.0777C17.6341 13.9113 17.6945 13.7493 17.8079 13.6274C17.9214 13.5055 18.0786 13.4337 18.2451 13.4277C19.2417 13.4277 20.2384 13.4277 21.2351 13.4277C21.391 13.4318 21.5394 13.4954 21.6499 13.6055C21.7604 13.7156 21.8245 13.8638 21.8291 14.0197C21.8291 15.0164 21.8291 16.0131 21.8291 17.0097C21.82 17.1694 21.7502 17.3195 21.634 17.4293C21.5178 17.5392 21.364 17.6004 21.2041 17.6004C21.0442 17.6004 20.8903 17.5392 20.7741 17.4293C20.6579 17.3195 20.5881 17.1694 20.5791 17.0097C20.5791 16.5747 20.5791 16.1397 20.5791 15.7037C20.5814 15.6611 20.5713 15.6187 20.5501 15.5817C20.5181 15.6197 20.4901 15.6617 20.4501 15.6967L16.7141 19.4377C16.6538 19.5204 16.5749 19.5876 16.4838 19.634C16.3926 19.6803 16.2918 19.7045 16.1896 19.7045C16.0873 19.7045 15.9865 19.6803 15.8953 19.634C15.8042 19.5876 15.7253 19.5204 15.6651 19.4377C15.2431 19.0157 14.8201 18.5967 14.4021 18.1717C14.3311 18.0997 14.2941 18.0967 14.2211 18.1717C13.1004 19.2944 11.9784 20.4171 10.8551 21.5397C10.7739 21.6399 10.6653 21.714 10.5425 21.7531C10.4197 21.7922 10.2881 21.7945 10.1641 21.7597C10.0658 21.7311 9.97609 21.6788 9.90279 21.6074C9.82949 21.536 9.77483 21.4477 9.74363 21.3503C9.71243 21.2528 9.70563 21.1492 9.72384 21.0485C9.74205 20.9478 9.7847 20.8531 9.84805 20.7727C9.88669 20.7253 9.92809 20.6802 9.97205 20.6377L13.7931 16.8167C13.8532 16.7355 13.9316 16.6694 14.0219 16.6239C14.1122 16.5784 14.2119 16.5547 14.3131 16.5547C14.4142 16.5547 14.5139 16.5784 14.6042 16.6239C14.6945 16.6694 14.7729 16.7355 14.8331 16.8167C15.2611 17.2441 15.6884 17.6721 16.1151 18.1007C16.1641 18.1497 16.1931 18.1767 16.2581 18.1117C17.3911 16.9737 18.5271 15.8387 19.6861 14.6797" fill="#486F88" />
            <path d="M15.773 24.2916H10.344C10.2029 24.2925 10.0657 24.2458 9.95441 24.1591C9.84315 24.0724 9.7644 23.9506 9.73086 23.8136C9.69733 23.6766 9.71098 23.5323 9.76961 23.404C9.82825 23.2757 9.92843 23.1709 10.054 23.1066C10.1387 23.0619 10.2331 23.0385 10.329 23.0386H21.214C21.2962 23.0393 21.3774 23.0562 21.4531 23.0883C21.5288 23.1204 21.5974 23.1672 21.655 23.2258C21.7126 23.2844 21.7581 23.3539 21.7889 23.4301C21.8197 23.5063 21.8352 23.5879 21.8345 23.6701C21.8337 23.7523 21.8168 23.8335 21.7847 23.9092C21.7526 23.9849 21.7059 24.0535 21.6472 24.1111C21.5886 24.1687 21.5192 24.2142 21.4429 24.245C21.3667 24.2758 21.2852 24.2913 21.203 24.2906H15.773" fill="#486F88" />
        </svg>

    );
};

export default EarnedIcon;
