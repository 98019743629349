import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
am5.addLicense("AM5C258896422");

const CategoryCombinedBarLineMultipleAxesChart = ({
    chartId,
    chartClass,
    showLabels,
    showBullets,
    chartData,
    chartLabels,
    showSecondY,
}) => {
    // USER DATA
    const userData = useSelector((state) => state.userData.user);

    let userCountryCode = userData?.country_code ?? "IN"

    let country = {}

    CountryCode.map((data) => {
        if (data.countryCode === userCountryCode) {
            country = data
        }
    })

    const convertPrefixes = (name) => {
        const liquidMetrics = ["Sales Volume"];
        const percentageMetrics = ["Video Completion Rate", "Audience Retention Rate", "Engagement Rate"];

        if (liquidMetrics.includes(name)) {
            return "-L"
        }
        else if (percentageMetrics.includes(name)) {
            return "%"
        }
        else {
            return ""
        }
    }

    const addCurrencySymbols = (data) => {
        const currencyMetrics = ["Spends", "Spend", "Sales Value"];

        if (currencyMetrics.includes(data)) {
            return country.currencySymbol
        }
        else {
            return ""
        }
    }

    useLayoutEffect(() => {

        var root = am5.Root.new(chartId);

        let colorSet = [0x11a1fd, 0x5a75f9, 0xff9931, 0x5a75f9];

        root.setThemes([am5themes_Animated.new(root)]);
        root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines


        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                layout: root.verticalLayout,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 0,
                paddingLeft: 0,
                maxTooltipDistance: -1,
            })
        );
        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "zoomX",
            })
        );
        cursor.lineY.set("visible", false);
        cursor.lineX.set("visible", false);

        var data = chartData

        var xRenderer = am5xy.AxisRendererX.new(root, {
            //minGridDistance: minimumGridDistance,
            stroke: am5.color(0x757575),
            strokeWidth: 0.3,
            strokeOpacity: 1,
        });
        var xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "label",
                renderer: xRenderer,
            })
        );
        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400"
        });

        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {

                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0.1,
                    stroke: am5.color(0x757575),
                    strokeWidth: 0.3,
                    strokeOpacity: 1,
                }),
                min: 0,
                extraMax: 0.04,
                strictMinMaxSelection: true,
            })
        );

        yAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400",
            fill: am5.color(colorSet[0]),
        });

        var yAxis1 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {

                syncWithAxis: yAxis,
                renderer: am5xy.AxisRendererY.new(root, {

                    opposite: true,
                    strokeOpacity: 0.1,
                    stroke: am5.color(0x757575),
                    strokeWidth: 0.3,
                    strokeOpacity: 1,
                }),
                min: 0,
                extraMax: 0.04,
                strictMinMaxSelection: true,
            })
        );

        yAxis1.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400",
            fill: am5.color(colorSet[1]),
        });

        // Add legend
        var legend = chart.children.push(
            am5.Legend.new(root, {
                // background: am5.Rectangle.new(root, {
                //     fill: am5.color(0xff5599),
                //     fillOpacity: 0.2
                // }), // to add the backgrounD to the legends
                position: "relative", // options: "absolute" / "relative"
                // width: am5.percent(100),
                height: chartLabels.length >= 6 ? am5.percent(30) : null,
                x: am5.percent(50),
                centerX: am5.percent(50),
                // clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical",
                }),
            })
        );

        legend.markers.template.setAll({
            width: 13,
            height: 13,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
        });

        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
        });

        legend.labels.template.setAll({
            // maxWidth: 150,
            //centerY: 0, // if we want labels to be top-aligned
            oversizedBehavior: "wrap",
        });

        let variabledBaseName = 'series';

        const createSeries = (label, index) => {
            // TO GET DATA FROM THE DATALABELS OBJECT
            Object.keys(label).forEach(function (key, index) {
                if (key === "linechartLabels") {
                    // LINECHART
                    label.linechartLabels.map((lineChartData, lineChartIndex) => {
                        window[variabledBaseName + key + lineChartIndex] = chart.series.push(
                            am5xy.SmoothedXLineSeries.new(root, {
                                name: lineChartData.label,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                valueYField: lineChartData.value,
                                categoryXField: "label",
                            })
                        );

                        // for adding gradient to the line series
                        window[variabledBaseName + key + lineChartIndex].fills.template.set(
                            "fillGradient",
                            am5.LinearGradient.new(root, {
                                stops: [
                                    {
                                        opacity: 0.3,
                                    },
                                    {
                                        opacity: 0,
                                    },
                                ],
                                rotation: 90,
                            })
                        );



                        window[variabledBaseName + key + lineChartIndex].strokes.template.setAll({
                            strokeWidth: 2,
                        });

                        window[variabledBaseName + key + lineChartIndex].fills.template.setAll({
                            fillOpacity: 0.5,
                            visible: true,
                        });

                        // Add bullets


                        var tooltip = window[variabledBaseName + key + (0)].set(
                            "tooltip",
                            am5.Tooltip.new(root, {
                                getFillFromSprite: false,
                                getStrokeFromSprite: false,
                                autoTextColor: false,
                                pointerOrientation: "horizontal",
                                position: "pointer",
                            })
                        );

                        tooltip.get("background").setAll({
                            fill: am5.color(0x213345),
                            maxWidth: "auto",
                            oversizedBehavior: "truncate",
                            cornerRadius: 5,
                        });

                        tooltip.label.setAll({
                            text: "[bold]{categoryX}[/]",
                            fill: am5.color(0xffffff),
                            fontSize: 13,
                        });

                        tooltip.label.adapters.add("text", function (text, target) {
                            chart.series.each(function (series) {
                                let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
                                let CurrencySymbol = addCurrencySymbols(series.get("name"));

                                text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
                                if (series.get("childLabel") != undefined) {
                                    text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]{" + series.get("childValue") + "}[/]";
                                }
                            });
                            return text;
                        });



                        window[variabledBaseName + key + lineChartIndex].data.setAll(data);
                        legend.data.push(window[variabledBaseName + key + lineChartIndex]);
                        window[variabledBaseName + key + lineChartIndex].appear();
                    })
                } else {
                    // BARCHART
                    label.barchartLabels.map((barChartData, barChartIndex) => {
                        window[variabledBaseName + key + barChartIndex] = chart.series.push(
                            am5xy.ColumnSeries.new(root, {
                                name: barChartData.label,
                                xAxis: xAxis,
                                yAxis: yAxis1,
                                valueYField: barChartData.value,
                                categoryXField: "label",
                            })
                        );

                        window[variabledBaseName + key + barChartIndex].columns.template.setAll({
                            cornerRadiusTL: 7,
                            cornerRadiusTR: 7,
                            width: 10,
                        });

                        window[variabledBaseName + key + barChartIndex].data.setAll(data);
                        legend.data.push(window[variabledBaseName + key + barChartIndex]);
                        window[variabledBaseName + key + barChartIndex].appear();
                    })
                }

            });
        }

        chartLabels.map((label, index) => {
            createSeries(
                label,
                index
            );
        });
        chart.zoomOutButton.set("forceHidden", true);
        return () => {
            root.dispose();
        };
    }, [chartData, chartLabels]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    );
};

CategoryCombinedBarLineMultipleAxesChart.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    showLabels: PropTypes.bool,
    chartData: PropTypes.array,
    chartLabels: PropTypes.array,
    showSecondY: PropTypes.bool,
    showBullets: PropTypes.bool,
};

export default CategoryCombinedBarLineMultipleAxesChart;