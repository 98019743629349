import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import StackedColumnChart from "../../../../components/ui/charts/StackedColumnChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { COKE_COMPANY_ID, FMCG_CATEGORY_ID } from "../../../../constants/constants";
import { DataFormatters } from "../../../../utils/Dataformatters";


const SocialFacebookPage = ({ topCardData }) => {

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)


  const userData = useSelector((state) => state.userData.user);

  let companyID = userData?.company?.id ?? null;

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Page"

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PAGE VIEWS
  const [pageViews, setPageViews] = useState([]);
  const [pageViewsLabels, setPageViewsLabels] = useState([]);
  const [isPageViewsLoading, setPageViewsIsLoading] = useState(false);
  const [isPageViewsLoaded, setPageViewsIsLoaded] = useState(false);
  const [pageViewserror, setPageViewsError] = useState(null);

  // PAGE GROWTH
  const [pageGrowth, setPageGrowth] = useState([]);
  const [pageGrowthAllDropdownData, setPageGrowthAllDropdownData] = useState(
    []
  );
  const [pageGrowthLabels, setPageGrowthLabels] = useState([]);
  const [pageGrowthAllDropdownDataLabels, setPageGrowthAllDropdownDataLabels] =
    useState([]);
  const [isPageGrowthLoading, setPageGrowthIsLoading] = useState(false);
  const [isPageGrowthLoaded, setPageGrowthIsLoaded] = useState(false);
  const [pageGrowtherror, setPageGrowthError] = useState(null);

  // PAGE GROWTH DROPDOWN
  // DROPDOWN OPTIONS
  const pageGrowthOptions = [
    { value: "likes", label: "Likes" },
    { value: "followers", label: "Followers" },
  ];

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(pageGrowthOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(pageModule, pageSubModule, pagePlatform, pagePrimaryTab, null, "Page Growth", e.label)
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const fetchPageGrowthDropdownHandler = async () => {
    if (selectedDropdownOption.value === "likes") {
      setPageGrowth(pageGrowthAllDropdownData.likes);
      setPageGrowthLabels(pageGrowthAllDropdownDataLabels.likes);
    } else if (selectedDropdownOption.value === "followers") {
      setPageGrowth(pageGrowthAllDropdownData.followers);
      setPageGrowthLabels(pageGrowthAllDropdownDataLabels.followers);
    } else {
      setPageGrowth([]);
      setPageGrowthLabels([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchPageGrowthDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // PAGE REACH 
  const [pageReach, setPageReach] = useState([])
  const [pageReachLabels, setPageReachLabels] = useState([]);
  const [isPageReachLoading, setPageReachIsLoading] = useState(false);
  const [isPageReachLoaded, setPageReachIsLoaded] = useState(false);
  const [pageReacherror, setPageReachError] = useState(null);

  // DROPDOWN OPTIONS
  const pageReachOptions = [
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
  ];

  // DROPDOWN STATE
  const [pageReachDropdownValue, setPageReachDropdownValue] = useState(pageReachOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageReachSelectionHandler = (e) => {
    if (e === null) {
      setPageReachDropdownValue("");
    } else {
      setPageReachDropdownValue(e);
    }

    IAEvent_Dropdown_Visit(pageModule, pageSubModule, pagePlatform, pagePrimaryTab, null, "Page Reach", e?.label ?? '')
  };




  // CURRENT PERIOD
  const [currentPeriod, setCurrentPeriod] = useState([]);
  const [currentPeriodLabels, setCurrentPeriodLabels] = useState([]);
  const [isCurrentPeriodLoading, setCurrentPeriodIsLoading] = useState(false);
  const [isCurrentPeriodLoaded, setCurrentPeriodIsLoaded] = useState(false);
  const [currentPerioderror, setCurrentPeriodError] = useState(null);

  // PREVIOUS PERIOD
  const [previousPeriod, setPreviousPeriod] = useState([]);
  const [previousPeriodLabels, setPreviousPeriodLabels] = useState([]);
  const [isPreviousPeriodLoading, setPreviousPeriodIsLoading] = useState(false);
  const [isPreviousPeriodLoaded, setPreviousPeriodIsLoaded] = useState(false);
  const [previousPerioderror, setPreviousPeriodError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPageViewsHandler();
    fetchPageGrowthHandler();
    fetchCurrentPreviousHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS
  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/metric-card/", config);

      setMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metric_card === undefined || res.data.metric_card.length === 0 ? [] :
            res.data.metric_card
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType={"cardChartType"} />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="facebook"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS
  const [isPageViewsOpen, setIsPageViewsOpen] = useState(false)

  let defaultPageViewsDisabledvalues = [];

  if (companyID === COKE_COMPANY_ID) {
    defaultPageViewsDisabledvalues = []
  } else {
    defaultPageViewsDisabledvalues = ["Previous"]
  }

  const [pageViewsDisabledvalues, setPageViewsDisabledvalues] = useState(defaultPageViewsDisabledvalues)

  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchPageViewsHandler = async () => {
    setPageViewsIsLoading(true);
    setPageViewsIsLoaded(false);
    setPageViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/pageview-trend/", config);

      setPageViews(
        res.data === undefined || res.data.length === 0 ? [] : res.data.page_view_list === undefined || res.data.page_view_list.length === 0 ? [] :
          res.data.page_view_list
      );
      setPageViewsLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
          res.data.dimension
      );
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(true);
      setPageViewsError(null);
    } catch (error) {
      setPageViews([]);
      setPageViewsLabels([]);
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(false);
      setPageViewsError(error.code);
    }
  };

  let pageViewsLineChart = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>
  let pageViewsLineChart2 = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isPageViewsLoaded && !isPageViewsLoading) {
    if (
      NoDataAvailableChecker(pageViews) ||
      NoDataAvailableChecker(pageViewsLabels)
    ) {
      pageViewsLineChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      pageViewsLineChart2 = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      if (isMultiLineCluster) {

        pageViewsLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_views"
              chartClass="section_card_chart"
              chartData={pageViews}
              chartLabels={pageViewsLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageViewsDisabledvalues}
              setDisabledValues={setPageViewsDisabledvalues}
            />
          </ErrorBoundary>
        );

        pageViewsLineChart2 = (
          <ErrorBoundary chartType="lineChartType">
            <PartitionedLineChart
              chartId="social_facebook_page_views_2"
              chartClass="section_card_chart"
              chartData={pageViews}
              chartLabels={pageViewsLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageViewsDisabledvalues}
              setDisabledValues={setPageViewsDisabledvalues}
            />
          </ErrorBoundary>
        );

      } else {
        pageViewsLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_views"
              chartClass="section_card_chart"
              chartData={pageViews}
              chartLabels={pageViewsLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageViewserror) {
    pageViewsLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageViewserror} />
      </Wrapper>
    );

    pageViewsLineChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageViewserror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageViewsLoading) {
    pageViewsLineChart = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
    pageViewsLineChart2 = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE GROWTH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchPageGrowthHandler = async () => {
    setPageGrowthIsLoading(true);
    setPageGrowthIsLoaded(false);
    setPageGrowthError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/page-growth-trend/", config);

      let likesResponse = res.data === undefined || res.data.length === 0 ? [] :
        res.data.data === undefined || res.data.data.length === 0 ? [] :
          res.data.data.likes === undefined || res.data.data.likes.length === 0 ? [] :
            res.data.data.likes

      let followsResponse = res.data === undefined || res.data.length === 0 ? [] :
        res.data.data === undefined || res.data.data.length === 0 ? [] :
          res.data.data.followers === undefined || res.data.data.followers.length === 0 ? [] :
            res.data.data.followers

      let likes = [];
      likesResponse.map((data) => {
        try {
          let a = {
            label: data.label,
            likes: data.likes,
            unlikes: -data.unlikes,
          };
          likes.push(a);
        } catch (error) {
          console.log(error);
        }
      });

      let follow = [];
      followsResponse.map((data) => {
        try {
          let a = {
            label: data.label,
            follow: data.follow,
            unfollow: -data.unfollow,
          };
          follow.push(a);
        } catch (error) {
          console.log(error);
        }
      });

      let allData = [];

      try {
        let a = {
          likes: likes,
          followers: follow,
        };
        allData.push(a);
      } catch (error) {
        console.log(error);
      }

      setPageGrowthAllDropdownData(
        allData === undefined || allData.length === 0 ? [] :
          allData[0] === undefined || allData[0].length === 0 ? [] :
            allData[0]
      );

      setPageGrowthAllDropdownDataLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
          res.data.dimension
      );

      setPageGrowth(likes === undefined || likes.length === 0 ? [] : likes);

      setPageGrowthLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension.likes === undefined || res.data.dimension.likes.length === 0 ? [] :
              res.data.dimension.likes
      );

      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(true);
      setPageGrowthError(null);
    } catch (error) {
      setPageGrowth([]);
      setPageGrowthLabels([]);
      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(false);
      setPageGrowthError(error.code);
    }
  };

  let pageGrowthBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isPageGrowthLoaded && !isPageGrowthLoading) {
    if (
      NoDataAvailableChecker(pageGrowth) ||
      NoDataAvailableChecker(pageGrowthLabels)
    ) {
      pageGrowthBarChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
    } else {
      pageGrowthBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="page_growth_chart"
              chartClass="section_card_chart"
              chartData={pageGrowth}
              chartLabels={pageGrowthLabels}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageGrowtherror) {
    pageGrowthBarChart = (
      <Wrapper><ServerErrorsLoader chartType="barChartType" error={pageGrowtherror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageGrowthLoading) {
    pageGrowthBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE REACH
  const [isPageReachOpen, setIsPageReachOpen] = useState(false)

  let defaultPageReachDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPageReachDisabledvalues = []
  } else {
    defaultPageReachDisabledvalues = []
  }

  const [pageReachDisabledvalues, setPageReachDisabledvalues] = useState(defaultPageReachDisabledvalues)

  // TO FETCH PAGE Reach DATA FROM API

  useEffect(() => {
    const fetchPageReachHandler = async () => {
      setPageReachIsLoading(true);
      setPageReachIsLoaded(false);
      setPageReachError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          time_period: pageReachDropdownValue?.value ?? null
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("social/facebook/page/lifetime-reach/", config);

        const allData = res?.data?.data ?? [];

        allData.sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year;
          }
          return a[pageReachDropdownValue.value] - b[pageReachDropdownValue.value];
        });


        var monthData = allData.map(({ month, year, total_reach }) => ({
          month: `${month + '/' + year}`,
          reach: total_reach
        }))

        var weekData = allData.map(({ week, year, total_reach }) => ({
          week: `${year + " Week " + week}`,
          reach: total_reach
        }))


        const dataLabel = pageReachDropdownValue.label === 'Monthly' ? 'month' : 'week';
        const unwantedValues = ['week', 'month', 'quarter', 'year', 'label']
        const dropdownArray = []

        let formattedData = DataFormatters("linechart", pageReachDropdownValue.label === 'Monthly' ? monthData : weekData, dataLabel, dropdownArray, unwantedValues)


        setPageReach(formattedData?.data ?? []);
        setPageReachLabels(formattedData?.dimension ?? []);
        setPageReachIsLoading(false);
        setPageReachIsLoaded(true);
        setPageReachError(null);
      } catch (error) {
        setPageReach([]);
        setPageReachLabels([]);
        setPageReachIsLoading(false);
        setPageReachIsLoaded(false);
        setPageReachError(error);
      }
    };

    fetchPageReachHandler()
  }, [BRAND_ID, selectedDates, pageReachDropdownValue])

  let pageReachLineChart = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>
  let pageReachLineChart2 = <Wrapper> <Loader loaderType="multiLineChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isPageReachLoaded && !isPageReachLoading) {
    if (NoDataAvailableChecker(pageReach) || NoDataAvailableChecker(pageReachLabels)) {
      pageReachLineChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      pageReachLineChart2 = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      if (isMultiLineCluster) {

        pageReachLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_Reach"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageReachDisabledvalues}
              setDisabledValues={setPageReachDisabledvalues}
            />
          </ErrorBoundary>
        );

        pageReachLineChart2 = (
          <ErrorBoundary chartType="lineChartType">
            <PartitionedLineChart
              chartId="social_facebook_page_Reach_2"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
              disabledLegendArray={pageReachDisabledvalues}
              setDisabledValues={setPageReachDisabledvalues}
            />
          </ErrorBoundary>
        );

      } else {
        pageReachLineChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_facebook_page_Reach"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageReacherror) {
    pageReachLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
      </Wrapper>
    );

    pageReachLineChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageReachLoading) {
    pageReachLineChart = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>
    pageReachLineChart2 = <Wrapper><Loader loaderType="multiLineChartLoader" /> </Wrapper>

  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CURRENT PREVIOUS

  // TO FETCH CURRENT PREVIOUS DATA FROM API
  const fetchCurrentPreviousHandler = async () => {
    setCurrentPeriodIsLoading(true);
    setCurrentPeriodIsLoaded(false);
    setCurrentPeriodError(null);

    setPreviousPeriodIsLoading(true);
    setPreviousPeriodIsLoaded(false);
    setPreviousPeriodError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/like-unlike-trend/", config);

      setCurrentPeriod(
        res.data === undefined || res.data.length === 0 ? [] : res.data.current_data === undefined || res.data.current_data.length === 0 ? [] :
          res.data.current_data
      );

      setCurrentPeriodLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.current_label === undefined || res.data.current_label.length === 0 ? [] :
          res.data.current_label
      );

      setPreviousPeriod(
        res.data === undefined || res.data.length === 0 ? [] : res.data.previous_data === undefined || res.data.previous_data.length === 0 ? [] :
          res.data.previous_data
      );

      setPreviousPeriodLabels(
        res.data === undefined || res.data.length === 0 ? [] : res.data.previous_label === undefined || res.data.previous_label.length === 0 ? [] :
          res.data.previous_label
      );

      setCurrentPeriodIsLoading(false);
      setCurrentPeriodIsLoaded(true);
      setCurrentPeriodError(null);
      setPreviousPeriodIsLoading(false);
      setPreviousPeriodIsLoaded(true);
      setPreviousPeriodError(null);
    } catch (error) {
      setCurrentPeriod([]);
      setCurrentPeriodLabels([]);
      setCurrentPeriodIsLoading(false);
      setCurrentPeriodIsLoaded(false);
      setCurrentPeriodError(error.code);

      setPreviousPeriod([]);
      setPreviousPeriodLabels([]);
      setPreviousPeriodIsLoading(false);
      setPreviousPeriodIsLoaded(false);
      setPreviousPeriodError(error.code);
    }
  };

  // CURRENT PERIOD
  let currentPeriodStackChart = <Wrapper><Loader loaderType="stackedColumnChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isCurrentPeriodLoaded && !isCurrentPeriodLoading) {
    if (
      NoDataAvailableChecker(currentPeriod) ||
      NoDataAvailableChecker(currentPeriodLabels)
    ) {
      currentPeriodStackChart = (
        <Wrapper> <NoDataAvailableLoader chartType="stackedColumnChartType" /></Wrapper>
      );
    } else {
      currentPeriodStackChart = (
        <Wrapper>
          <ErrorBoundary chartType="stackedColumnChartType">
            <StackedColumnChart
              chartId="current_period"
              chartClass="section_card_chart"
              chartData={currentPeriod}
              chartLabels={currentPeriodLabels}
              showLegend={true}
              showLabels={true}
              tooltipMaxWidth={"auto"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (currentPerioderror) {
    currentPeriodStackChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="stackedColumnChartType"
          error={currentPerioderror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCurrentPeriodLoading) {
    currentPeriodStackChart = <Wrapper><Loader loaderType="stackedColumnChartLoader" /></Wrapper>
  }

  // PREVIOUS PERIOD
  let previousPeriodStackChart = <Wrapper><Loader loaderType="stackedColumnChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isPreviousPeriodLoaded && !isPreviousPeriodLoading) {
    if (
      NoDataAvailableChecker(previousPeriod) ||
      NoDataAvailableChecker(previousPeriodLabels)
    ) {
      previousPeriodStackChart = (
        <Wrapper> <NoDataAvailableLoader chartType="stackedColumnChartType" /></Wrapper>
      );
    } else {
      previousPeriodStackChart = (
        <Wrapper>
          <ErrorBoundary chartType="stackedColumnChartType">
            <StackedColumnChart
              chartId="previous_period"
              chartClass="section_card_chart"
              chartData={previousPeriod}
              chartLabels={previousPeriodLabels}
              showLegend={true}
              showLabels={true}
              tooltipMaxWidth={"auto"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (previousPerioderror) {
    previousPeriodStackChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="stackedColumnChartType"
          error={previousPerioderror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPreviousPeriodLoading) {
    previousPeriodStackChart = <Wrapper><Loader loaderType="stackedColumnChartLoader" /></Wrapper>
  }

  var periodSourceCSV = {
    "Current Period": currentPeriod,
    "Previous Period": previousPeriod,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Cards": metricCards,
    "SocialPageViews_Page Views": pageViews,
    "Page Growth": pageGrowth,
    [`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`]: pageReach,
    ...periodSourceCSV,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [metricCards, pageViews, pageGrowth, currentPeriod, pageReach, previousPeriod]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (pageViewserror !== null ? true : isPageViewsLoaded && (isPageViewsLoading === false)) &&
      (pageGrowtherror !== null ? true : isPageGrowthLoaded && (isPageGrowthLoading === false)) &&
      (pageReacherror !== null ? true : isPageReachLoaded && (isPageReachLoading === false)) &&
      (previousPerioderror !== null ? true : isPreviousPeriodLoaded && (isPreviousPeriodLoading === false)) &&
      (previousPerioderror !== null ? true : isPreviousPeriodLoaded && (isPreviousPeriodLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    pageViewserror, isPageViewsLoaded, isPageViewsLoading,
    pageGrowtherror, isPageGrowthLoaded, isPageGrowthLoading,
    pageReacherror, isPageReachLoaded, isPageReachLoading,
    previousPerioderror, isPreviousPeriodLoaded, isPreviousPeriodLoading,
    previousPerioderror, isPreviousPeriodLoaded, isPreviousPeriodLoading,

  ])

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="page_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Page Views
              </h2>
              <SectionDefinationsBadge
                title="Page Views"
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <Modal
              open={isPageViewsOpen}
              setOpen={setIsPageViewsOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"social_facebook_page_views_2"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"SocialPageViews_Page Views"}
                  sectionData={pageViews}
                />}
              parentId={"page_views_section"}
              title={`Page Views`}
              chart={pageViewsLineChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPageViewsOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                sectionId={"page_views_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={"SocialPageViews_Page Views"}
                sectionData={pageViews}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageViewsLineChart}
            </div>
          </div>
        </div>
        <div id="page_growth_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Page Growth: {selectedDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title="Page Growth"
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={pageGrowthOptions}
                className="form_dropdown section_dropdown"
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />
              <ExportDropdown
                sectionId={"page_growth_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={"Page Growth"}
                sectionData={pageGrowth}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageGrowthBarChart}
            </div>
          </div>
        </div>
      </div>

      {(companyID === FMCG_CATEGORY_ID || companyID === COKE_COMPANY_ID) && <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="page_Reach_section" className="grid col_span_4 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Page Reach
              </h2>
              <SectionDefinationsBadge
                title="Page Reach"
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <Modal
              open={isPageReachOpen}
              setOpen={setIsPageReachOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"social_facebook_page_views_2"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`}
                  sectionData={pageReach}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={pageReachOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={pageReachDropdownValue}
                  selectedOptions={pageReachDropdownValue}
                  setStatedropdown={pageReachSelectionHandler}
                />
              }
              parentId={"page_Reach_section"}
              title={`Page Reach`}
              chart={pageReachLineChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPageReachOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={pageReachOptions}
                className="form_dropdown section_dropdown ml_20"
                value={pageReachDropdownValue}
                selectedOptions={pageReachDropdownValue}
                setStatedropdown={pageReachSelectionHandler}
              />
              <ExportDropdown
                sectionId={"page_Reach_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={`SocialPageReach_Page Reach_${pageReachDropdownValue.value}`}
                sectionData={pageReach}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageReachLineChart}
            </div>
          </div>
        </div>

      </div>}
      <div
        id="period_source_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <div className="section_info">    <h2 className="section_card_title">Current Period Source
              </h2> <SectionDefinationsBadge
                  title="Current Period Source"
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {currentPeriodStackChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Previous Period Source
              </h2> <SectionDefinationsBadge
                  title="Previous Period Source"
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"period_source_section"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"Current and Previous Period Source"}
                  sectionData={periodSourceCSV}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {previousPeriodStackChart}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialFacebookPage;
