import React, { Component } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Wrapper from '../../../helpers/wrapper';
import { Link } from 'react-router-dom'
import RiStoreBubbleChart from '../../../shared/components/charts/RiStoreBubbleChart';
import { Container, Image } from "react-bootstrap";
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import { API_URL } from '../../../helpers/constant';
import StickyLogo from "../../../../assets/images/icons/icon.png";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import IconPdf from "../../../../assets/images/icons/pdfIcon";
import { Loader } from '../../../shared/components/loader/loader';
import ChartExport from "../../../shared/components/export/ChartExport";

export default class StoreHealthSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {

      user: [{ "date": "2019-5-12", "value1": "50", "value2": "48", "previousdate": "2019-5-5" }, { "date": "2019-5-13", "value1": "53", "value2": "51", "previousdate": "2019-5-6" }, { "date": "2019-5-14", "value1": "56", "value2": "58", "previousdate": "2019-5-7" }, { "date": "2019-5-15", "value1": "52", "value2": "53", "previousdate": "2019-5-8" }, { "date": "2019-5-16", "value1": "48", "value2": "44", "previousdate": "2019-5-9" }, { "date": "2019-5-17", "value1": "47", "value2": "42", "previousdate": "2019-5-10" }, { "date": "2019-5-18", "value1": "59", "value2": "55", "previousdate": "2019-5-11" }],
      linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
      // storedata: [{ "name": "Store1", "y": 470, "x": 10, "value": 20, "y2": 550, "x2": 18, "value2": 20, "y3": 1350, "x3": 8, "value3": 24 }, { "name": "Store2", "y": 675, "x": 20, "value": 20, "y2": 810, "x2": 14, "value2": 23, "y3": 320, "x3": 23, "value3": 20 }, { "name": "Store3", "y": 590, "x": 28, "value": 20, "y2": 1800, "x2": 35, "value2": 25, "y3": 960, "x3": 41, "value3": 22 }, { "name": "Store4", "y": 676, "x": 51, "value": 25, "y2": 245, "x2": 76, "value2": 28, "y3": 654, "x3": 88, "value3": 24 }, { "name": "Store5", "y": 2305, "x": 86, "value": 22, "y2": 710, "x2": 92, "value2": 22, "y3": 676, "x3": 93, "value3": 24 }, { "name": "Store6", "y": 853, "x": 99, "value": 22, "y2": 702, "x2": 75, "value2": 21, "y3": 564, "x3": 60, "value3": 24 }, { "name": "Store7", "y": 3001, "x": 36, "value": 20, "y2": 1725, "x2": 43, "value2": 24, "y3": 644, "x3": 76, "value3": 20 }],
      storedata: [],
      is_store_attribution_chart_loaded: false,
    };
  }

  componentDidMount() {
    this.state.is_store_attribution_chart_loaded = false;
    this.getStoreAttributionChart();
    this.forceUpdate();
    window.addEventListener("scroll", this.handleScroll);
  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }


  //STORE ATTRIBUTION CHART
  getStoreAttributionChart() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;

    // const data = {
    //     start_date: "2022-01-24",
    //     end_date: "2022-01-30",
    //   };
    const url = API_URL + "/api/ri/sales_storeattribution/";

    fetch(url, {
      method: "POST",
      //body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let store_attribution_bubble_list = result['store_attribution_list'];

        let store_attribution_bubble_chart_list = [];

        store_attribution_bubble_list.map((info, index) => {
          // (info["nsv"] / info["unit_sold"]) *100,
          // storedata: '[{"name":"Store2","y":544,"x":58,"value":14},{"name":"Store7","y2":1725,"x2":43,"value2":24},{"name":"Store4","y3":644,"x3":76,"value3":20}]',
          try {
            let ytemp = "y" + index.toString();
            let xtemp = "x" + index.toString();
            let valuetemp = "value" + index.toString();
            var b = "y" + (0).toString();

            let storeColor = "";
            storeColor =
              (((info["nsv"])) / ((info["target_nsv"]))) * 100 < 70 ? "#d51f30" : ((info["nsv"]) / (info["target_nsv"])) * 100 < 94 ? "#db7823" : "#038673";

            // am4core.color("#038673"),  //green
            // am4core.color("#db7823"),  //yellow
            // am4core.color("#d51f30"),  //red
            // y = circle - mature_store ,  y2 = square new Store  , y3 = triangle LTL Store
            if (info['store_type'] == 'NEW') {
              let a = {
                name: info['site_desc'],
                y2: (info['footfalls']),
                x2: info['nsv'],
                value2: info['nsv_mix'],
                color: storeColor,
              };
              store_attribution_bubble_chart_list.push(a);
            } else if (info["store_type"] == "MATURE") {
              let a = {
                name: info["site_desc"],
                y: (info["footfalls"]),
                x: info['nsv'],
                value1: info['nsv_mix'],
                color: storeColor,
              };
              store_attribution_bubble_chart_list.push(a);
            } else {
              let a = {
                name: info["site_desc"],
                y3: (info["footfalls"]),
                x3: info['nsv'],
                value3: info['nsv_mix'],
                color: storeColor,
              };
              store_attribution_bubble_chart_list.push(a);
            }

          } catch (e) { }
        });


        this.setState({
          is_store_attribution_chart_loaded: true,
          storedata: store_attribution_bubble_chart_list,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.handleCallback();
    }
    if (prevProps.mapStoreCode !== this.props.mapStoreCode) {
      this.handleRISalesSummaryMapCallBack();
    }
    if (prevProps.mapStoreCategory !== this.props.mapStoreCategory || prevProps.mapStoreType !== this.props.mapStoreType || prevProps.mapStoreSize !== this.props.mapStoreSize) {
      this.handleRiMapFilterCallback();
    }
  }


  handleCallback = (childData) => {
    this.state.is_store_attribution_chart_loaded = false;

    this.getStoreAttributionChart();
    this.forceUpdate();
  };

  handleRISalesSummaryMapCallBack = () => {
    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.forceUpdate();
  }

  handleRiMapFilterCallback = () => {
    this.state.is_sellthrough_metrices_loaded = false;
    this.state.is_sellthrough_sparkline_loaded = false;
    this.state.is_stocktrend_multiline_loaded = false;

    this.getSellThroughMetrices();
    this.getSellThroughSparklineGraph();
    this.getStockTrendMultilineGraph();

    this.forceUpdate();
  }


  render() {
    // if (!this.state.is_store_attribution_chart_loaded


    // ) {
    //   return "Loading, please wait"
    // }



    return (
      <Wrapper>


        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Store Attribution
                </Col>
                <Col className="icz-cardfilter">
                  <div className="icz-cardbtnwrapper">
                    <ChartExport ChartId='store-data' exportName="Store Attribution"
                      export_href={this.state.export_href} downloadName="Store-Attribution.csv"
                    />
                  </div>
                </Col>
              </Col>

              {this.state.is_store_attribution_chart_loaded === true ?
                <>
                  <div className="">
                    <RiStoreBubbleChart
                      chart_id="store-data"
                      chart_class="icz-sectionchart large-chart"
                      Xtitle={"NSV"}
                      Ytitle={"Footfalls"}
                      metric_1={"Mature Store"} metric_2={"New Store"} metric_3={"LTL Store"}
                      graph-data={this.state.storedata}
                      isnumber={'money1'} />
                  </div>
                  <div className="">
                    <Col lg={12} className="d-flex">
                      <Col lg={6} className="icz-ridatabullet">
                        <ul className="icz-ridatalist-inline">
                          <li><div className="icz-ridatalist-bullet5" /> High NSV%</li>
                          <li><div className="icz-ridatalist-bullet2" /> Med NSV%</li>
                          <li><div className="icz-ridatalist-bullet6" /> Low NSV%</li>
                        </ul>
                      </Col>
                      <Col lg={6} className="icz-ridatabullet">
                        <ul className="icz-ridatalist-inline">
                          <li><div className="icz-ridatalist-bullet7" /> New Store</li>
                          <li><div className="icz-ridatalist-bullet8" /> Mature Store</li>
                          <li><div className="icz-ridatalist-bullet9" /> LTL Store</li>
                        </ul>
                      </Col>

                    </Col>
                  </div>
                </>
                :
                // LOADER
                <Loader />
              }
            </div>
          </div>
        </div>

        <div className="icz-riknwbttnwrapper">
          <Link to='/roi/storehealth' className="btn icz-riknowmore"> Know more</Link>
        </div>
      </Wrapper>
    );
  }
}
