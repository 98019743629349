import { useSelector } from "react-redux";
import { CountryCode } from "../../../../constants/countryCurrency";

export const NumberFormatter = ({ Number, IsNumber }) => {

    const userCountry = useSelector((state) => state.userData.user.country_code);

    let numberValue = 0

    if (Number === null || Number === undefined || Number === "NaN" || isNaN(Number) === true || isFinite(Number) === false) {
        return Number = 0
    }

    if (IsNumber === 'percentage') {
        numberValue = `${Number}%`
    } else if (IsNumber === 'salespersqft') {
        numberValue = `${Number} SPSF`
    }

    const country = CountryCode.find((country) => {
        return country.countryCode === userCountry;
    });


    if (IsNumber === "money") {
        if (country.country_code === "IN") {
            if (Number >= 10000000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 10000000).toFixed(2).replace(/\.0$/, "")}CR`
            } else if (Number >= 100000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 100000).toFixed(2).replace(/\.0$/, "")}L`
            } else if (Number >= 1000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 1000).toFixed(2).replace(/\.0$/, "")}K`
            } else {
                numberValue = `${country.currencySymbol}${+Math.abs(Number).toFixed(2)}`
            }
        } else {
            if (Number >= 1000000000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 1000000000).toFixed(2).replace(/\.0$/, "")}B`
            } else if (Number >= 1000000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 1000000).toFixed(2).replace(/\.0$/, "")}M`
            } else if (Number >= 1000) {
                numberValue = `${country.currencySymbol}${+Math.abs(Number / 1000).toFixed(2).replace(/\.0$/, "")}K`
            } else {
                numberValue = `${country.currencySymbol}${+Math.abs(Number).toFixed(2)}`
            }
        }
    } else {
        if (Number >= 1000000000) {
            numberValue = +Math.abs(Number / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
        } else if (Number >= 1000000) {
            numberValue =
                +Math.abs(Number / 1000000)
                    .toFixed(2)
                    .replace(/\.0$/, "") + "M";
        } else if (Number >= 1000) {
            numberValue =
                +Math.abs(Number / 1000)
                    .toFixed(2)
                    .replace(/\.0$/, "") + "K";
        } else {
            numberValue = +Math.abs(Number).toFixed(2)
        }
    }

    return numberValue
}
