import React from 'react'

export default function BottomMoversIcon(props) {
  return (
    <svg className={props.className} width="35" height="20" viewBox="0 0 20 20" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9901 11.366C15.2131 11.366 14.4831 11.366 13.7541 11.366C13.4889 11.3563 13.2307 11.4524 13.0363 11.6331C12.8419 11.8138 12.7273 12.0643 12.7176 12.3295C12.7079 12.5947 12.804 12.8529 12.9847 13.0473C13.1654 13.2417 13.4159 13.3563 13.6811 13.366C15.2811 13.3734 16.8784 13.3734 18.4731 13.366C18.723 13.3602 18.9611 13.2588 19.1385 13.0826C19.3158 12.9064 19.4187 12.6689 19.4261 12.419C19.4314 10.8244 19.4314 9.22969 19.4261 7.63502C19.4261 7.3698 19.3207 7.11545 19.1332 6.92791C18.9456 6.74038 18.6913 6.63502 18.4261 6.63502C18.1609 6.63502 17.9065 6.74038 17.719 6.92791C17.5314 7.11545 17.4261 7.3698 17.4261 7.63502C17.4191 8.33502 17.4261 9.02802 17.4261 9.72402C17.4296 9.7922 17.4133 9.85993 17.3791 9.91902C17.3281 9.85802 17.2791 9.79202 17.2261 9.73602C15.2261 7.74135 13.2287 5.74702 11.2341 3.75302C11.1373 3.62076 11.0108 3.51319 10.8646 3.43903C10.7185 3.36488 10.5569 3.32623 10.3931 3.32623C10.2292 3.32623 10.0677 3.36488 9.92153 3.43903C9.7754 3.51319 9.64883 3.62076 9.55208 3.75302C8.86908 4.43702 8.19108 5.10902 7.52108 5.78902C7.40808 5.90402 7.34908 5.90802 7.23108 5.78902C5.43508 3.98902 3.63608 2.19235 1.83408 0.39902C1.70441 0.238159 1.53051 0.118744 1.33381 0.0554878C1.13711 -0.00776814 0.926208 -0.012102 0.727078 0.0430202C0.569635 0.0885942 0.425808 0.17217 0.308255 0.286391C0.190703 0.400613 0.103028 0.541978 0.0529481 0.698046C0.00286811 0.854114 -0.00808197 1.0201 0.0210616 1.18139C0.0502052 1.34269 0.118549 1.49435 0.220078 1.62302C0.282441 1.69857 0.349225 1.77037 0.420078 1.83802L6.54408 7.95302C6.64111 8.08277 6.76717 8.18801 6.91216 8.26031C7.05716 8.33261 7.21707 8.36996 7.37909 8.36938C7.54111 8.3688 7.70074 8.33029 7.84521 8.25695C7.98969 8.18361 8.11498 8.07747 8.21108 7.94702C8.89708 7.26302 9.58208 6.57835 10.2661 5.89302C10.3441 5.81402 10.3901 5.77102 10.4951 5.87602C12.3111 7.69602 14.1321 9.51202 15.9901 11.367" fill="#486F88" />
      <path d="M9.72008 16.996H1.02008C0.798047 17.0056 0.579261 16.9401 0.399077 16.81C0.218893 16.6799 0.0878373 16.4928 0.0270845 16.279C-0.0147469 16.0768 0.0151847 15.8664 0.111731 15.6839C0.208278 15.5014 0.365404 15.3582 0.556084 15.279C0.693513 15.2135 0.843814 15.1793 0.996084 15.179H18.4461C18.5723 15.1685 18.6993 15.1844 18.819 15.2258C18.9387 15.2671 19.0485 15.333 19.1413 15.4192C19.234 15.5054 19.3078 15.6101 19.3578 15.7264C19.4079 15.8428 19.433 15.9683 19.4318 16.0949C19.4305 16.2216 19.4029 16.3465 19.3505 16.4619C19.2982 16.5772 19.2224 16.6803 19.1279 16.7647C19.0334 16.849 18.9224 16.9127 18.8019 16.9517C18.6814 16.9907 18.5541 17.0041 18.4281 16.991C15.5268 16.991 12.6268 16.991 9.72808 16.991" fill="#486F88" />
    </svg>


  )
}
