import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl';
import './map.scss';
import 'mapbox-gl/dist/mapbox-gl.css'

export default class IndiaMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            lng: 82,
            lat: 22,
            zoom: 2.8,

        };
        this.mapContainer = React.createRef();
    };


    // onCreateIndiaMap = (e) => {
    //     this.props.parentCallback(e);
    // }

    // onGetIndiaMapCity = (e) => {
    //     this.props.parentCallback(e);
    // }
    componentDidMount() {
        const { lng, lat, zoom } = this.state;


        let info = this.props;

        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/icogz/clatinkb1009s14o6tj43b929',
            center: [lng, lat],
            zoom: 2.8,
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            attributionControl: false,
        });

        map.fitBounds([[
            info.North_point[0], info.North_point[1]
        ],
        [
            info.South_point[0], info.South_point[1]
        ]], { duration: 0 });

        map.on('load', () => {
            var markarr = [];
            //Rupesh logic map 
            this.state.data.forEach(function (element, index) {

                let center = [0, 0];
                let north = [0, 0];
                let south = [0, 0];
                let store_latitude = 0;
                let store_longitude = 0;
                let India_StoreCode = '';
                let India_StoreName = '';
                if (info.map_level != 2) {
                    center = element.center.split(',');
                    north = element.north_corner.split(',');
                    south = element.south_corner.split(',');
                } else {
                    center = info.Center_point;
                    north = info.North_point;
                    south = info.South_point;
                    store_latitude = element.latitude;
                    store_longitude = element.longitude;
                    India_StoreCode = element.store_code;
                    India_StoreName = element.store_name;

                }

                let India_Zone = element.zone;

                let India_city = '';

                if (info.map_level != 0) {
                    India_city = element.city;
                }
                let map_pointer_color = '#eee';

                if (info.rangetype == "store_score") {
                    if (element.score >= 71) {
                        map_pointer_color = '#038673';
                    } else if (element.score >= 29 && element.score <= 70) {
                        map_pointer_color = '#DB7823';
                    } else {
                        map_pointer_color = '#D51F30';
                    }
                }
                else {
                    if (element.nsv_achieved >= 95) {
                        map_pointer_color = '#038673';
                    } else if (element.nsv_achieved >= 70 && element.nsv_achieved <= 94) {
                        map_pointer_color = '#DB7823';
                    } else {
                        map_pointer_color = '#D51F30';
                    }
                }

                let toolTipName = '';
                if (info.map_level == 2) { toolTipName = element.store_name; }
                else if (info.map_level == 1) { toolTipName = element.city; }
                else { toolTipName = element.zone; }

                if (element.nsv >= 10000000) {
                    element.nsv = (element.nsv / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
                }
                if (element.nsv >= 100000) {
                    element.nsv = (element.nsv / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
                }
                if (element.nsv >= 1000) {
                    element.nsv = (element.nsv / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }

                element.nsv_mix = element.nsv_mix + `%`;

                element.nsv_achieved = element.nsv_achieved + `%`;

                if (info.map_level != 2) {
                    markarr['zone' + index] = new mapboxgl.Marker({ color: map_pointer_color })
                        .setLngLat([center[0], center[1]])
                        .addTo(map);

                    // var popup = new mapboxgl.Popup().setHTML(
                    //     'NAME : '+toolTipName+'</br> NSV : '+element.nsv+'</br> NSV MIX : '+element.nsv_mix+'</br> NSV ACHIEVED : '+element.nsv_achieved
                    // );
                    if (info.rangetype == "store_score") {
                        var popup = new mapboxgl.Popup(
                        ).setHTML(
                            'NAME : ' + toolTipName + '</br> NSV : ' + element.nsv + '</br> NSV MIX : ' + element.nsv_mix + '</br> SCORE : ' + element.score + '</br> ATS : ' + element.ats
                        );
                    }
                    else {
                        var popup = new mapboxgl.Popup(
                        ).setHTML(
                            'NAME : ' + toolTipName + '</br> NSV : ' + element.nsv + '</br> NSV MIX : ' + element.nsv_mix + '</br> NSV ACHIEVED : ' + element.nsv_achieved
                        );
                    }
                    markarr['zone' + index].getElement().addEventListener('click', () => {
                        //  markarr['zone'+index].style.visibility='hidden';
                        map.fitBounds([[north[0], north[1]], [south[0], south[1]]]);
                        info.parentCallback([north, south, center, India_Zone, India_city, India_StoreCode, India_StoreName]);
                    });

                    const element123 = markarr['zone' + index].getElement();
                    element123.id = markarr['zone' + index];
                    element123.addEventListener('mouseover', () => popup.addTo(map));
                    element123.addEventListener('mouseleave', () => popup.remove());
                    if (info.isTooltipEnabled === true) {
                        markarr['zone' + index].setPopup(popup);
                    } else {
                        // markarr['zone' + index].setPopup(popup);
                    }

                    markarr['zone' + index].addTo(map);
                }
                else {
                    markarr['zone' + index] = new mapboxgl.Marker({ color: map_pointer_color })
                        .setLngLat([store_longitude, store_latitude])
                        .addTo(map);

                    if (info.rangetype == "store_score") {
                        var popup = new mapboxgl.Popup(
                        ).setHTML(
                            'NAME : ' + toolTipName + '</br> NSV : ' + element.nsv + '</br> NSV MIX : ' + element.nsv_mix + '</br> SCORE : ' + element.score + '</br> ATS : ' + element.ats
                        );
                    }
                    else {
                        var popup = new mapboxgl.Popup(
                        ).setHTML(
                            'NAME : ' + toolTipName + '</br> NSV : ' + element.nsv + '</br> NSV MIX : ' + element.nsv_mix + '</br> NSV ACHIEVED : ' + element.nsv_achieved
                        );
                    }
                    markarr['zone' + index].getElement().addEventListener('click', () => {
                        //  markarr['zone'+index].style.visibility='hidden';
                        map.fitBounds(
                            [
                                [north[0], north[1]], [south[0], south[1]]
                            ]
                        );
                        info.parentCallback([north, south, center, India_Zone, India_city, India_StoreCode, India_StoreName]);

                    });
                    const element123 = markarr['zone' + index].getElement();
                    element123.id = markarr['zone' + index];
                    element123.addEventListener('mouseover', () => popup.addTo(map));
                    element123.addEventListener('mouseleave', () => popup.remove());
                    markarr['zone' + index].setPopup(popup);
                    markarr['zone' + index].addTo(map);
                }

            });

        });


        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    }

    render() {
        let chart_class = 'map-container ' + this.props.chart_class;
        return (
            <div id="mapmain">
                <div id="map" className="map-container" />
            </div>
        )
    }
}

