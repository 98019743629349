import React, { Component } from "react"

import '../card.scss';
import Image from 'react-bootstrap/esm/Image';
import PercentMetricChange from "../../percentmetricchange/percentmetricchange";
import { NumberFormatter } from "../../../utilities/numberformatter";
import Wrapper from "../../../../helpers/wrapper";


export class ChartCard extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let previousValueData = this.props.CardTitle;
        if (this.props.CardTitle == 'PSFPD' || this.props.CardTitle == 'Recovery%' || this.props.CardTitle == 'MBQ%') {
            previousValueData = this.props.RowOneCurrentValue;
        }
        else {
            previousValueData = this.props.RowOnePreviousValue;
        }

       

        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-card">
                    <div className="icz-cardheader">
                        <div className="icz-iconwrapper">
                            {this.props.CardIcon}
                        </div>
                        <div className="icz-titlewrapper">
                            <h3 className="icz-title">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-cardbody">
                        <div className="icz-detailsrow">
                            <div className="icz-valuesection">
                                <div className="icz-currentvalue">
                                    <NumberFormatter Number={this.props.RowOneCurrentValue} IsNumber={this.props.IsNumber} />
                                </div>
                                {this.props.RowOnePreviousValue ?
                                    <Wrapper>
                                        <span className="icz-valuedivider">/</span>
                                        <div className="icz-previousvalue">
                                            <NumberFormatter Number={this.props.RowOnePreviousValue} IsNumber={this.props.IsNumber} />
                                        </div>
                                    </Wrapper>
                                    : ''}
                            </div>
                            {this.props.RowOnePreviousValue ?
                                <div className="icz-cardgrowthsection">
                                    <PercentMetricChange IsStandard={this.props.IsStandard} CurrentValue={this.props.RowOneCurrentValue}
                                        PreviousValue={previousValueData}
                                    // PreviousValue={this.props.CardTitle = 'PSFPD' ? this.props.RowOneCurrentValue : this.props.RowOnePreviousValue }
                                    />
                                </div>
                                : ''}
                        </div>
                        <div className="icz-divider">
                        </div>
                        {this.props.chart}
                    </div>
                </div>
            </div>
        )
    }
}
