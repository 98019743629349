import { headerFormatter } from "../constants/headerFormatter";
import NoDataAvailableChecker from "./NoDataAvailableChecker";
import getValueFromHeaderFormatter from "./Utils";

export const DataFormatters = (
  chart,
  rawData,
  dataLabel,
  dropdownArray,
  unwantedData
) =>{

  if(NoDataAvailableChecker(rawData)) {
    return []
  };

  if(chart === "barchart"){
    
    let empArr = {
      dimension: {},
      data: {}
    }
    
    if(dropdownArray.length !== 0){
      // DROPDOWN DATA FORMATTER
      const uniqueKeys = Object.keys(rawData[0])

      dropdownArray.map((item, index) => {
        empArr.dimension[item] = []
        empArr.data[item] = []
      })
    
      // ALL DATA ALL DROPDOWNS
      Object.keys(empArr.data).map((data, dataInd) => {
        rawData.map((rawData, rawDataIndex) => {
          empArr.data[data].push({
            "label": rawData[dataLabel],
            [data]: rawData[data]
          })
        })
      })
    
      Object.keys(empArr.data).map((data, dataInd) => {
        empArr.dimension[data].push({
          label: getValueFromHeaderFormatter(data),
          value: data
        })
      })

      return empArr
    } else{

    }

  } else  if(chart === "linechart"){

    let empArr = {
      dimension: {},
      data: {}
    }
    
    if(dropdownArray.length !== 0){
      // DROPDOWN DATA FORMATTER
      const uniqueKeys = Object.keys(rawData[0])

      dropdownArray.map((item, index) => {
        empArr.dimension[item] = []
        empArr.data[item] = []

        uniqueKeys.map((x, y) => {
            if(x !== dataLabel) {
            empArr.dimension[item].push({
              label: getValueFromHeaderFormatter(x),
              value: x
            })
          }
        })

        var storeArr = [];

        rawData.map((x,y) => {

          var newDataItem = {};

          Object.values(x).map((i,j) => {
            newDataItem['label'] = x[dataLabel] 
          
            if(Object.keys(x)[j] !== dataLabel){
              newDataItem[Object.keys(x)[j]] = i
            }
          })
          storeArr.push(newDataItem)

        })

        empArr.data[item].push(...storeArr)

      })

      return empArr
    } else{
    try {

      const selectedMetrics = Object.keys(rawData[0]).filter((x) => !unwantedData.includes(x))

      
      const dimension = selectedMetrics.map((field, index) => {
        const dimensionObj = {
          label: getValueFromHeaderFormatter(selectedMetrics[index]),
          value: field,
         };

        return dimensionObj;
      });

      const data = rawData.map((item) => {
        const newDataItem = {
          label: item[dataLabel], // Use the dynamic label as the label
        };

        selectedMetrics.forEach((field) => {
          if (field !== dataLabel) {
            newDataItem[field] = parseFloat(item[field]);
          }
        });

        // extraFields.forEach((extraField) => {
        //   newDataItem[extraField] = item[extraField];
        // });

        return newDataItem;
      });

      empArr.dimension = dimension;
      empArr.data = data

      return empArr

    }
    catch (error) {
      return { data: [], dimension: [] };
    }

    }

  } else  if(chart === "donutchart"){

    let empArr = {}
    
    if(dropdownArray.length !== 0){
      // DROPDOWN DATA FORMATTER
      const uniqueKeys = Object.keys(rawData[0])

      dropdownArray.map((item, index) => {
        empArr[item] = []

        rawData.map((x,y) => {

          var newDataItem = {};
          
          Object.values(x).map((i,j) => {
            newDataItem['label'] = x[dataLabel] 
          
            if(Object.keys(x)[j] !== dataLabel){
              newDataItem[Object.keys(x)[j]] = i
            }

            // if(Object.keys(x)[j] !== dataLabel && Object.keys(x)[j] === item){
            // newDataItem['value'] = i
            // }


          })
          empArr[item].push(newDataItem)
        })

      })

      return empArr
    } else{

    }

  } else  if(chart === "metricCards"){

    let empArr = {
      data: []
    }
 
      var currentData = rawData.current[0];
      var previousData = rawData.previous[0];

      for(var i = 0; i < Object.values(currentData).length ; i++){
        var newDataItem = {}

        newDataItem['label'] = getValueFromHeaderFormatter(Object.keys(currentData)[i]);
        newDataItem['currentValue'] = Object.values(currentData)[i];
        newDataItem['previousValue'] = Object.values(previousData)[i];


        empArr.data.push(newDataItem)
        
      }

    return empArr

  }  else  if(chart === "maleFemaleCount"){

    let empArr = {}
    const getGenders = rawData.map(x => x[dataLabel]);

    getGenders.map((item, index) => {
      empArr[item] = {};

      rawData.map((x,y) => {

        if(x[dataLabel] === item){
        Object.keys(x).map((i,j) => {

          if(i !== dataLabel){
            empArr[item][i] = x[i]
          }
        })
        }
      })
    })

    return empArr;

  }  else  if(chart === "countryTable"){


    // rawData.map((x,y) => {
    // })

    // var empArr = {}
    
    // rawData.map((x,y) => {

    //   if(x[dataLabel] === item){
    //   Object.keys(x).map((i,j) => {

    //     if(i !== dataLabel){
    //       empArr[i] = x[i]
    //     }
    //   })
    //   }
    // })



  } 



}

export const DataFormatterExtraFields = () =>{
  return null
}

// export const DataFormatterExtraFields = (
//   originalData,
//   selectedLabel,
//   selectedMetrics,
//   extraFields,
//   typeOfChart,
//   dropdownMetrics
// ) => {
//   // Function to get the value from chartLabelFormatter based on label match
//   function getValueFromHeaderFormatter(labelToMatch) {
//     const headerObj = headerFormatter.find(
//       (obj) => obj.label === labelToMatch
//     );
//     return headerObj ? headerObj.value : labelToMatch;
//   }


//   if (typeOfChart === "barChart" || typeOfChart === "lineChart" || typeOfChart === "stackedColumnChart") {
//     try {
//       const dimension = selectedMetrics.map((field, index) => {
//         const dimensionObj = {
//           label: getValueFromHeaderFormatter(selectedMetrics[index]),
//           value: field,
//           children: extraFields.map((extraField, childIndex) => ({
//             childLabel: getValueFromHeaderFormatter(
//               extraFields[childIndex]
//             ),
//             childValue: extraField,
//           })),
//         };

//         return dimensionObj;
//       });

//       const data = originalData.data.map((item) => {
//         const newDataItem = {
//           label: item[selectedLabel], // Use the dynamic label as the label
//         };

//         selectedMetrics.forEach((field) => {
//           if (field !== selectedLabel) {
//             newDataItem[field] = item[field];
//           }
//         });

//         extraFields.forEach((extraField) => {
//           newDataItem[extraField] = item[extraField];
//         });

//         return newDataItem;
//       });
//       return { dimension, data };
//     }
//     catch (error) {
//       return { data: [], dimension: [] };
//     }

//   }

//   if (typeOfChart === "barChartTest") {
//     try {

//       var obj = {};

//       const dimension = dropdownMetrics !== undefined ?
//         dropdownMetrics.map((item, i) => {

//           obj[item] = [{
//             label: getValueFromHeaderFormatter(item),
//             value: item,
//             children: extraFields.map((extraField, childIndex) => ({
//               childLabel: getValueFromHeaderFormatter(
//                 extraFields[childIndex]
//               ),
//               childValue: extraField,
//             })),
//           }]

//           return obj;

//         }) :
//         selectedMetrics.map((field, index) => {

//           const dimensionObj = {
//             label: getValueFromHeaderFormatter(selectedMetrics[index]),
//             value: field,
//             children: extraFields.map((extraField, childIndex) => ({
//               childLabel: getValueFromHeaderFormatter(
//                 extraFields[childIndex]
//               ),
//               childValue: extraField,
//             })),
//           };

//           return dimensionObj;
//         });

//       var newObj = {};

//       const data =
//         //  dropdownMetrics !== undefined ? dropdownMetrics.map((item, i) => {
//         //   newObj[item] = []



//         // }) :

//         originalData.data.map((item) => {
//           const newDataItem = {
//             label: item[selectedLabel], // Use the dynamic label as the label
//           };

//           selectedMetrics.forEach((field) => {
//             if (field !== selectedLabel) {
//               newDataItem[field] = item[field];
//             }
//           });

//           extraFields.forEach((extraField) => {
//             newDataItem[extraField] = item[extraField];
//           });

//           return newDataItem;
//         });


//       return { dimension, data };
//     }
//     catch (error) {
//       return { data: [], dimension: [] };
//     }

//   }

//   if (typeOfChart === "metricCard") {
//     try {
//       const dimension = [
//         {
//           "label": "Current",
//           "value": "currentValue"
//         },
//         {
//           "label": "Previous",
//           "value": "previousValue"
//         }
//       ]

//       const data = Object.values(originalData.data).map((item, index) => {

//         const newDataItem = {
//           label: item[0][selectedLabel], // Use the dynamic label as the label
//           currentValue: null,
//           previousValue: null,
//         };



//         // var (item[0]).map((field, j) => {
//         // })
//         // 


//         // selectedMetrics.forEach((field) => {
//         //   if (field !== selectedLabel) {
//         //     newDataItem[field] = item[field];
//         //   }
//         // });

//         // extraFields.forEach((extraField) => {
//         //   newDataItem[extraField] = item[extraField];
//         // });

//         return newDataItem;
//       });
//       return { dimension, data };
//     }
//     catch (error) {
//       return { data: [], dimension: [] };
//     }

//   }

//   if (typeOfChart === "funnelChart") {
//     try {

//       const data = originalData.data.map((item) => {
//         const newDataItem = {
//           category: item[selectedLabel],
//           value: item[selectedMetrics],// Use the dynamic label as the label
//         };

//         return newDataItem;
//       });
//       return { data };
//     }
//     catch (error) {
//       return { data: [] };
//     }
//   }

//   if (typeOfChart === "donutPieChart") {
//     try {

//       const data = originalData.data.map((item) => {
//         const newDataItem = {
//           label: item[selectedLabel],
//           value: item[selectedMetrics],// Use the dynamic label as the label
//         };

//         return newDataItem;
//       });
//       return { data };
//     }
//     catch (error) {
//       return { data: [] };
//     }

//   }
  
//   else if (typeOfChart === "pieOfPieChart") {
//     try {
//       const dimension = extraFields.map((field) => ({
//         label: getValueFromHeaderFormatter(field),
//         value: 0,
//       }));

//       const data = originalData.data.map((item) => ({
//         label: item[selectedLabel],
//         value: item[selectedMetrics],
//         subData: extraFields.map((extraField) => ({
//           label: getValueFromHeaderFormatter(extraField),
//           value: item[extraField],
//         })),
//       }));

//       return { data, dimension };
//     }
//     catch (error) {
//       return { data: [], dimension: [] };
//     }

//   }

//   else if (typeOfChart === "combineBarLineChart") {
//     try {


//       // Create the 'dimension' array with the specified structure
//       const dimension = [
//         {
//           linechartLabels: selectedMetrics[0].map(metric => ({ label: getValueFromHeaderFormatter(metric), value: metric })),
//           barchartLabels: selectedMetrics[1].map(metric => ({ label: getValueFromHeaderFormatter(metric), value: metric }))

//         }
//       ];

//       const data = originalData.data.map((item) => {
//         const dataItem = {
//           label: item[selectedLabel],
//         };

//         selectedMetrics.forEach((metricGroup) => {
//           metricGroup.forEach((metric) => {
//             dataItem[metric] = item[metric];
//           });
//         });

//         return dataItem;
//       });


//       return { data, dimension };
//     }
//     catch (error) {
//       return { data: [], dimension: [] };
//     }

//   }
//   else if (typeOfChart === "heatMapChart") {
//     try {

//       const data = originalData.data.map((item) => {
//         const dataItem = {
//           [selectedLabel]: item[selectedLabel],
//         };

//         selectedMetrics.forEach((metric) => {
//           dataItem[metric] = item[metric];
//         });

//         // Calculate the day_of_week based on yaxis_label
//         const dayOfWeekMap = {
//           Sunday: "0",
//           Monday: "1",
//           Tuesday: "2",
//           Wednesday: "3",
//           Thursday: "4",
//           Friday: "5",
//           Saturday: "6",
//         };
//         dataItem.day_of_week = dayOfWeekMap[item[selectedMetrics[1]]];

//         return dataItem;
//       });

//       // Create xaxis_labels and yaxis_labels arrays
//       const xaxis_labels = Array.from(new Set(data.map((item) => item.xaxis_label))).map(
//         (xaxis_label) => ({ xaxis_label })
//       );
//       const yaxis_labels = Array.from(new Set(data.map((item) => item.yaxis_label))).map(
//         (yaxis_label) => ({ yaxis_label })
//       );

//       return {
//         data,
//         xaxis_labels,
//         yaxis_labels,
//       };


//     }
//     catch (error) {
//       return { data: [], xaxis_labels: [], yaxis_labels: [] };
//     }

//   }
//   else {
//     return { data: [], dimension: [] };
//   }
// };
