const RealTimeIcon = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="29.627" height="29.627" viewBox="0 0 29.627 29.627">
            <g id="Group_108" data-name="Group 108" transform="translate(-1090.573 -466.585)">
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="14.063" cy="14.063" r="14.063" transform="translate(1091.323 467.335)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Path_126" data-name="Path 126" d="M613.213,96.086v8.438l5.625,2.813" transform="translate(492.174 376.874)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    );
};

export default RealTimeIcon;
