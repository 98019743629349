import React, { Component } from "react"

import Button from 'react-bootstrap/Button'
import IconSearch from "../../../../assets/images/icons/searchIcon";

import Wrapper from "../../../helpers/wrapper";
import axios from 'axios'

import './searchfilter.scss';

export default class SearchFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredData: "",
            searchFilterData: this.props.filterData,
        }
    }

    onSearch = (e) => {
        this.setState({ filteredData: e.target.value });


        const { filteredData, searchFilterData } = this.state;

        const filteredDataUpdated = searchFilterData.filter(index => {
            return (
                index.toLowerCase().includes(filteredData.toLowerCase())
            );
        });

        this.props.parentCallback(filteredDataUpdated)
    }


    render() {

        return (
            <Wrapper>
                <div className="icz-inputwrapper">
                    <input
                        type="text"
                        className="form-control icz-input"
                        placeholder={this.props.Placeholder}
                        onChange={this.onSearch}
                    />
                    <Button className="icz-searchbtn"><IconSearch className="icz-inputicon" /></Button>
                </div>
            </Wrapper>
        )
    }
}

