import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const Progress_bar = (props) => {

  let isnumber = props.isnumber;
  let num = parseFloat(props.targetHover);

  if (num == null) {
    num = 0;
  }

  if (isnumber === 'money') {
    if (num >= 10000000) {
      num = (num / 10000000).toFixed(2).replace(/\.0$/, '') + 'Cr';
    }
    if (num >= 100000) {
      num = (num / 100000).toFixed(2).replace(/\.0$/, '') + 'L';
    }
    if (num >= 1000) {
      num = (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
  };

  if (isnumber === 'number') {
    if (num >= 1000000000) {
      num = (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      num = (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      num = (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
  };

  if (isnumber === 'percentage') {
    num = num + `%`;
  };

  let bgcolor = props.progress < 70 ? '#d51f30' : (props.progress > 69.99 && props.progress < 95 ? '#db7823' : ('#038673'))

  let tempProgress = props.progress > 100 ? 100 : props.progress;

  let numberForm = num;
  let targetValue = `Targeted : ` + numberForm;
  let achievedValue = `Achieved : ` + props.progress + `%`;

  const Parentdiv = {
    height: 5,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
  }

  const Childdiv = {
    height: '100%',
    width: `${tempProgress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
  }

  const renderTooltip = props => (
    <Tooltip {...props}>{targetValue}<br />{achievedValue}</Tooltip>
  );


  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      <div style={Parentdiv}>
        <div style={Childdiv}>
          {/* <div className="icz-bardivider"></div> */}
        </div>
      </div>
    </OverlayTrigger >
  )
}

export default Progress_bar;
