const SummaryIcon = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="22.002" height="27.127" viewBox="0 0 22.002 27.127">
            <g id="Group_101" data-name="Group 101" transform="translate(-451.198 -589.238)">
                <path id="Path_129" data-name="Path 129" d="M161.389,179.3H151.139a2.562,2.562,0,0,0-2.563,2.563v20.5a2.562,2.562,0,0,0,2.563,2.563h15.376a2.562,2.562,0,0,0,2.563-2.563V186.988Z" transform="translate(303.372 410.688)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Path_130" data-name="Path 130" d="M158.576,179.3v7.688h7.688" transform="translate(306.186 410.688)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_28" data-name="Line 28" x1="10.251" transform="translate(457.074 604.083)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_29" data-name="Line 29" x1="10.251" transform="translate(457.074 609.208)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_30" data-name="Line 30" x1="2.563" transform="translate(457.074 598.957)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>

    );
};

export default SummaryIcon;
