import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class PieChartwithLegends extends Component {

    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let title4 = info['metric_4']

        let card_id = info['card_id'];
        let data = info['graph-data'];
        let section1_color = info['section1_color'];
        let section2_color = info['section2_color'];
        let section3_color = info['section3_color'];
        let section4_color = info['section4_color'];
        let section5_color = info['section5_color'];

        let chart = am4core.create(card_id, am4charts.PieChart);

        // Add data
        chart.data = data

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "name";


        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.ticks.template.disabled = true;

        // remove label
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.radius = am4core.percent(-40);
        pieSeries.labels.template.fill = am4core.color("white");
        pieSeries.labels.template.fontSize = 11;

        pieSeries.alignLabels = false;
        pieSeries.labels.template.propertyFields.disabled = "disabled";
        pieSeries.ticks.template.propertyFields.disabled = "disabled";
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.background.fill = am4core.color("#213345");
        pieSeries.tooltip.background.stroke = am4core.color("#213345");
        pieSeries.tooltip.background.cornerRadius = 5;
        pieSeries.tooltip.label.fontSize = 12;
        pieSeries.tooltip.position = "pointer";
        var shadow = pieSeries.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        // Remove padding
        chart.paddingBottom = 0;
        chart.paddingTop = 0;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;

        pieSeries.colors.list = [
            am4core.color(section1_color),
            am4core.color(section2_color),
            am4core.color(section3_color),
            am4core.color(section4_color),
            am4core.color(section4_color),
            am4core.color(section5_color)
        ];

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.fontSize = 10;
        chart.legend.labels.template.fontFamily = "Ubuntu";
        chart.legend.labels.template.text = "{name}";
        chart.legend.labels.template.fill = "#213345";
        chart.legend.fill = "rgba(112, 112, 112, 0.1)";
        chart.legend.opacity = 1;
        var markerTemplate = chart.legend.markers.template;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.legend.valueLabels.template.disabled = true; //value label hide
    }

    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;

        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}

