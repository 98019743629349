import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH258896422");

export default class DatedbasedBubbleChart extends Component {
    componentDidMount() {
        let info = this.props

        let card_id = info['card_id'];
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let data = info['graph-data'];



        let chart = am4core.create(card_id, am4charts.XYChart);

        // Add data
        chart.data = data;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.dataFields.category = "category";
        xAxis.renderer.grid.template.location = 0;
        xAxis.title.text = title1;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true; //vline
        yAxis.renderer.grid.template.disabled = true; //hline

        xAxis.cursorTooltipEnabled = false;
        yAxis.cursorTooltipEnabled = false;
        yAxis.title.text = title2;
        // Create series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "ay";
        series1.dataFields.dateX = "date";
        series1.dataFields.value = "aValue";
        series1.strokeOpacity = 0;
        series1.cursorTooltipEnabled = false;


        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.tooltipText = "{date} \n Transactions:{valueY}";
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#213345");
        series1.tooltip.background.stroke = am4core.color("#213345");
        series1.tooltip.background.cornerRadius = 5;
        series1.tooltip.label.minWidth = 150;
        series1.tooltip.label.padding(10, 10, 10, 10);
        series1.tooltip.label.fontSize = 12;
        // bullet1.tooltip.position = "pointer";
        series1.heatRules.push({
            target: bullet1.circle,
            min: 10,
            max: 20,
            property: "radius"
        });


        // Add scrollbars
        // chart.scrollbarX = new am4core.Scrollbar();
        // chart.scrollbarY = new am4core.Scrollbar();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";

    }
    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}
