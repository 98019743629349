import React, { Component } from 'react'
import './filterbadge.scss'
import IconClose from '../../../../assets/images/icons/closeicon';

export default class FilterBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_button: true
    };
  }
  disable_button() {
    this.props.parentCallback([this.props.id, this.props.filterbadgeid]);
    this.setState({ show_button: !this.state.show_button });
  }

  render() {
    return (
      <div>
        {this.state.show_button && (
          <div id="button_div" dataid={this.props.id} className="btn icz-ricustftrbadge" >
            {this.props.badgename}
            <button id="close_button" checkboxid={this.props.id} dataid={this.props.filterbadgeid} className="icz-riftrclose" onClick={() => { this.disable_button(); }}><IconClose className="icz-riftrclosebttn" /></button>
          </div>
        )}
      </div>
    );
  }

  removeToCollection(key, e) {
  }
}

