import { useLocation, useMatch } from "react-router-dom";

import Wrapper from "../helpers/Wrapper";

import Dropdown from "../ui/dropdown/Dropdown";
import DatePicker from "../ui/Datepicker";
import { useContext, useEffect, useState } from "react";

import TvBrandsDropdown from "../ui/dropdown/TvBrandsDropdown";
import ErrorBoundary from "../../utils/ErrorBoundary";
import SalesMarketDropdown from "../ui/dropdown/SalesMarketDropdown";
import { useDispatch } from "react-redux";
import { setSelectedBrandIds, setSelectedBrands } from "../../store/globalBrandsDropdownSlice";
import { BRAND_ID } from "../../constants/constants";

import { useSelector } from "react-redux";
import AuthContext from "../../store/authContext";
import ExportDropdown from "../ui/dropdown/ExportDropdown";
import CamapaignDropdown from "../ui/dropdown/CampaignDropdown";
import FullPageExportContext from "../../store/fullPageExportContext";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

const Header = ({ identity, name, data, headers }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  // const { fullPageExport } = useContext(FullPageExportContext)


  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // BRANDS LIST FROM DROPDOWN
  const brandsDropdownOptionsList = useSelector(
    (state) => state.globalSelectedBrandIds.brandOptions
  );

  // SELECTED BRANDS
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

  const dashboardURLMatch = useMatch("/");
  const camapignURLMatch = useMatch("/mi/campaigns");
  const socialURLMatch = useMatch("/mi/social");
  const videoURLMatch = useMatch("/mi/video");
  const hashtagURLMatch = useMatch("/mi/hashtag");
  const compareURLMatch = useMatch("/mi/compare");
  const websiteURLMatch = useMatch("/mi/website");
  const influencersURLMatch = useMatch("/mi/influencers");
  const miTvURLMatch = useMatch("/mi/tv");

  const riSalesURLMatch = useMatch("/ri/sales");

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [activePage, setActivePage] = useState("");

  // BRAND DROPDOWN
  // BRAND DROPDOWN STATE
  const [selectedBrandDropdownOption, setSelectedBrandDropdownOption] = useState(selectedBrands);

  // BRAND GROWTH DROPDOWN OPTIONS SAMPLE
  const brandsDropdownOptions = [
    {
      value: BRAND_ID,
      label: "Hafele",
      icon: (
        <img
          src="https://www.hafeleindia.com/hap-live/static/WFS/Haefele-HIN-Site/-/-/en_IN/images/favicons/android-chrome-512x512.png"
          style={{ width: "30px", height: "30px", borderRadius: "100%" }} alt="img"
        />
      ),
    }
  ];

  // BRAND DROPDOWN OPTIONS SELECTION HANDLER
  const brandDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedBrandDropdownOption("");
    } else {
      setSelectedBrandDropdownOption(e);
      dispatch(setSelectedBrandIds([...new Set(e.map(item => item.value))]))
      dispatch(setSelectedBrands(e))
    }
  };

  const handleCallback2 = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
  const [showCamapaignDropdown, setShowCamapaignDropdown] = useState(false);
  const [showTVBrandsDropdown, setShowTVBrandsDropdown] = useState(false);
  const [showSalesBrandsDropdown, setShowSalesBrandsDropdown] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/campaigns") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(true);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/social") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/video") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/hashtag") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/compare") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/website") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/influencers") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/tv" || location.pathname === "/ri/tv") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(true);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ri/sales") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(true);
    } else if (location.pathname === "/ri/market") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/social-listening") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/summary") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/sales-summary") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/business-overview") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/sellthrough") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/storehealth") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/customer") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/targetgroup") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/roi/branchscore") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/summary") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/customer") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/engagement") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/traffic") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/earned") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/paid") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/sales") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ecom/realtime") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/admin") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    }
  }, [location])


  return (
    <Wrapper>
      <div className="dashboard_navbar">
        <a href="#" className="icogz_logo_link">
          {/* ICOGZ LOGO */}
          <img src={IcogzLogo} alt="icogz logo" className="icogz_logo" />
        </a>
        <div className="mobile_navbar_filter">
          {/* <div className=""> filters</div> */}
        </div>
        <div className="flex justify_between" id="top_nav_bar">
          <div className="left">
            {activePage && <div className="top_nav_page_title">{activePage}</div>}
          </div>
          <div className="right">
            <div className="flex justify_end align_center">
              {
                showCamapaignDropdown &&
                <CamapaignDropdown />
              }
              {showBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>
                    <Dropdown
                      className={"top_nav_dropdown"}
                      placeholder={"Select Brand"}
                      options={brandsDropdownOptionsList}
                      ismulti={true}
                      value={selectedBrandDropdownOption}
                      selectedOptions={selectedBrandDropdownOption}
                      setStatedropdown={brandDropdownSelectionHandler}
                    />
                  </ErrorBoundary>
                </div>
              )}

              {showTVBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>
                    <TvBrandsDropdown />
                  </ErrorBoundary>
                </div>
              )}

              {showSalesBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>
                    <SalesMarketDropdown />
                  </ErrorBoundary>
                </div>
              )}

              <div className="flex center top_nav_options">
                <ErrorBoundary>
                  <DatePicker
                    open={isDatePickerOpen}
                    setOpen={handleCallback2}
                  />
                </ErrorBoundary>
              </div>

              <div>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown sectionId={identity} sectionName={name} sectionData={data} sectionDataHeaders={headers} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
